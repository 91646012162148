/**
 * Displays an hierarchical overview of all customers.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var log         = rise.logging.getLogger('pages.customer.manager');
    var nav         = rise.Navigation;
    var addIconButton = app.widgets.addIconButton;
    var toggleTreeButton = app.widgets.textButton;
    var commandBox = app.widgets.commandBox;

    /** DAOs **/
    var Customer    = app.model.Customer;
    var customerDao = app.dao.customerDao;

    /*** GLOBALS ***/
    var pages         = app.pages;

    /*** Export Page ***/
    pages.customer.manager = app.pagesByPath['/customer/manager'] = CustomerManager;
    /*** Set permission ***/
    CustomerManager.permissions = [
        app.model.PermissionTypes.CUSTOMER_MODIFY,
        app.model.PermissionTypes.CUSTOMER_VIEW_SUPERVISOR,
        app.model.PermissionTypes.CUSTOMER_VIEW_CUSTOMER
    ];

    function CustomerManager() {
        var self = this;

        this.name = "/customer/manager";


        this.customers = [];
        this.tree = null;
        this.detailsPage = null;

        this.treeElementById = {};

        this.currentActiveNode = null;

        this.create = function($parent) {
            return customerDao.findAll()
                .done(function(customers) {
                    self.customers = customers;
                    renderContent($parent);

                    // show first customer
                    //selectNavigationTreeItem("customer", self.customers[0]);
                });
        };

        this.attach = function($parent) {
            return customerDao.findAll()
                .done(function(customers) {
                    var oldTreeElementById = self.treeElementById;
                    self.treeElementById = {};
                    _.map(customers, _mapCustomerToTreeFormat);

                    if(!_.isEqual(oldTreeElementById, self.treeElementById))  {
                        $parent.empty();

                        self.customers = customers;
                        renderContent($parent);

                        self.tree.activateKey(self.currentActiveNode.data.key);

                    } else {
                        onActivateNavigationTreeItem(self.currentActiveNode);
                    }
                });
        };

        this.detach = function() {
            if (self.tree) {
                self.currentActiveNode = self.tree.getActiveNode();
            }
        };

        function renderContent(parent) {

            // render basic content
            parent.appendElement([
                commandBox({title:"Kundenmanager"},[
                    app.session.getSessionInfo().hasPermission(app.model.PermissionTypes.CUSTOMER_MODIFY)
                        ? addIconButton({id: 'add-customer',
                            click: addNewCustomer
                          }, 'Neuer Kunde')
                        : undefined
                ]),
                div({ id:"customer-overview-whiteboard", "class":"clearfix" }, [
                    createNavigationTree(),
                    div({ id:"customer-overview-details", "class":"content-with-sidebar"})
                ]),
                div({"class":"toolbar"}, [
                    toggleTreeButton({id:"toggle-customer-list", "class":"sidebar-toggle",
                        click: function() {
                            $("#customer-overview-whiteboard").toggleClass("sidebar-show");
                            var showTree = $("#customer-overview-whiteboard").hasClass("sidebar-show");
                            if(showTree) {
                                $("#toggle-customer-text").html("Kundenliste ausblenden");
                            } else {
                                $("#toggle-customer-text").html("Kundenliste einblenden");
                            }
                        }
                    }, [span({id:"toggle-customer-text"},"Kundenliste einblenden")])
                ])
            ]);

            self.$details = parent.find("#customer-overview-details");

            // preselect the first customer
            if (self.customers.length > 0) {
                self.tree.activateKey("customer-" + self.customers.sort(function(a, b) {
                    return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                })[0].id);
            }
        }

        function createNavigationTree() {
            return div({ id:"customer-navigation-tree",
                         "class": "customer-tree-view sidebar",
                         create:function(e) {
                var $target = $(e.target),
                    customersInTreeFormat = _.map(self.customers, _mapCustomerToTreeFormat);

                self.$tree = $target;

                self.tree = $target.dynatree({
                    fx: { height: "toggle", duration: 200 },
                    generateIds: true,
                    idPrefix: "tree-item",
                    keyboard: true,
                    children: customersInTreeFormat,
                    onActivate: onActivateNavigationTreeItem
                }).dynatree("getTree");

                // sort children
                self.tree.getRoot().sortChildren(undefined, true);
            }});
        }

        function addNewCustomer () {
            if (!self.treeElementById["customer-"]) {
                self.tree.getRoot().addChild({ key: "customer-", title: "&lt;Neu&gt;" });
                self.treeElementById["customer-"] = {};
            }
            self.tree.activateKey("customer-");
        }

        function onActivateNavigationTreeItem(node) {
            if (!node) {
                return;
            }

            var key = node.data.key,
                type_id = key.split("-");

            // remove new but unsaved elements
            if (type_id[1]) {
                cleanUpTree();
            }

            selectNavigationTreeItem(type_id[0], self.treeElementById[key],
                function onChange (newObj) {
                    var additions = !newObj.active ? " (Inaktiv)" : "";
                    if (type_id.length > 1 && !type_id[1]) {
                        node.data.key = type_id[0] + '-' + newObj.id;
                    }
                    node.data.title = newObj.name + additions;
                    delete self.treeElementById[type_id[0] + "-"];
                    $("#tree-item" + type_id[0] + "-").attr("id", "tree-item" + type_id[0] + "-" + newObj.id);
                    self.treeElementById[type_id[0] + '-' + newObj.id] = newObj;
                    self.tree.getRoot().sortChildren(undefined, true);
                    self.tree.redraw();
                },
                function onNavigate (type, obj) {
                    if (!obj) {
                        obj = _.find(self.treeElementById, function(ele, key) { return key.indexOf(type) === 0; });
                    }

                    // remove new but unsaved elements
                    cleanUpTree();

                    self.tree.activateKey(type + "-" + obj.id);
                },
                function onAddChild (child) {
                    var childType = {
                        'customer': 'location',
                        'location': 'object',
                        'object': 'workplace'
                    }[type_id[0]];

                    if (!childType) {
                        log.error("Tried to add unknown child type");
                    }

                    // remove new but unsaved elements
                    cleanUpTree();

                    node.addChild({ key: childType + "-", title: "&lt;Neu&gt;" });
                    self.treeElementById[childType + "-"] = {};
                    self.tree.activateKey(childType + "-");
                },
                function getParentId () {
                    return node.getParent().data.key.split("-")[1];
                },
                function getNodeData() {
                    return self.treeElementById[key]
                },
                function getCustomerData() {
                    var currentNode = node, customerId;

                    while(currentNode.data.key.indexOf('customer') === -1) {
                        currentNode = currentNode.getParent();
                    }

                    customerId = parseInt(currentNode.data.key.split("-")[1]);
                    return _.findWhere(self.customers, {id: customerId});
                }
            );
        }

        function cleanUpTree() {
            // clean up tree
            _.each(['customer', 'location', 'object', 'workplace'], function(nodeType) {
                if (self.treeElementById[nodeType + "-"]) {
                    delete self.treeElementById[nodeType + "-"];
                    var node = self.tree.getNodeByKey(nodeType + "-");
                    if (node) {
                        node.remove();
                    }
                }
            });
        }

        function selectNavigationTreeItem(type, obj, onUpdateDetails, onNavigate, onAddChild, getParentId, getNodeData, getCustomerData) {
            var $parent = self.$details;

            createDetailsPage($parent, type, obj, onUpdateDetails, onNavigate, onAddChild, getParentId, getNodeData, getCustomerData);
        }

        function _mapCustomerToTreeFormat(customer) {
            var additions = !customer.active ? " (Inaktiv)" : "",
                id = "customer-" + customer.id;

            self.treeElementById[id] = customer;

            return {
                key: id,
                title: customer.name + additions,
                children: _.map(customer.locations || [], _mapLocationToTreeFormat)
            }
        }

        function _mapLocationToTreeFormat(location) {
            var additions = !location.active ? " (Inaktiv)" : "",
                id = "location-" + location.id;

            self.treeElementById[id] = location;

            return {
                key: id,
                title: location.name + additions,
                children: _.map(location.objects || [], _mapObjectToTreeFormat)
            }
        }

        function _mapObjectToTreeFormat(object) {
            var additions = !object.active ? " (Inaktiv)" : "",
                id = "object-" + object.id;

            self.treeElementById[id] = object;

            return {
                key: id,
                title: object.name + additions,
                children: _.map(object.workplaces || [], _mapWorkplaceToTreeFormat)
            }
        }

        function _mapWorkplaceToTreeFormat(workplace) {
            var additions = !workplace.active ? " (Inaktiv)" : "",
                id = "workplace-" + workplace.id;

            self.treeElementById[id] = workplace;

            return {
                key: id,
                title: workplace.name + additions
            }
        }
    }

    function createDetailsPage($parent, type, obj, onUpdate, onNavigate, onAddChild, getParentId, getNodeData, getCustomerData) {
        var Page = {
            "customer"  : pages.customer.detail,
            "location"  : pages.location.detail,
            "object"    : pages.object.detail,
            "workplace" : pages.workplace.detail
        }[type];

        if (self.detailsPage && self.detailsPage.destroy) {
            self.detailsPage.destroy();
            $parent.empty();
        }

        self.detailsPage = new Page();
        self.detailsPage.create($parent, obj.id, onUpdate, onNavigate, onAddChild, getParentId, getNodeData, getCustomerData);
    }

})(window.rise, window.app);
