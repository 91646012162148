/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.Location = function Location(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id =               obj.id != null ? parseInt(obj.id) : null;
        this.customer_id =      obj.customer_id != null ? parseInt(obj.customer_id) : null;

        this.name =             obj.name != null ? obj.name : null;
        this.active =           (obj.active === true || obj.active === false) ? obj.active : null;
        this.address =          obj.address != null ? obj.address : null;
        this.zip =              obj.zip != null ? obj.zip : null;
        this.city =             obj.city != null ? obj.city : null;
        this.country =          obj.country != null ? obj.country : null;

        this.event_types = obj.event_types || [];

        // map location instances
        this.objects = _.map(obj.objects || [], function(object) {
            return new app.model.Object(object);
        });
    };

}(window.app));
