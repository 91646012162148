/* eslint-disable camelcase */
import { ReportSchedule } from 'app/report-schedule/ReportSchedule';

export class ReportScheduleDetailsRequestDto extends ReportSchedule {
    constructor(reportSchedule: ReportSchedule) {
        super(reportSchedule);

        // convert mail_text to base64 and prevent double conversion
        if (!(reportSchedule instanceof ReportScheduleDetailsRequestDto)) {
            this.mail_text = btoa(this.mail_text ?? '<p />');
        }
    }
}
