import { TourReport } from 'app/tour-report/model/TourReport';

export class TourReportDay extends TourReport {
    day: string;

    constructor(data) {
        super(data);

        this.day = data.day;
    }
}
