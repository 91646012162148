/**
 * Data Access Object for the tour endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;

    /*** Import ***/
    var Tour = app.model.Tour;
    var Tag = app.model.Tag;
    var TourDetailsDto = app.dto.TourDetailsDto;
    var TourListDto = app.dto.TourListDto;
    var CalendarDto = app.dto.CalendarDto;

    /*** Export Dao ***/
    app.dao.tourDao = new TourDao();

    /**
     * @constructor
     */
    function TourDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.Tour, new Rpc('/rpc/tour'));
    }

    // extend the parent Dao prototype
    $.extend(TourDao.prototype, Object.create(rise.Dao.prototype));

    TourDao.prototype.setActiveState = function(id, active) {
        var funcName = active ? "activate" : "deactivate";
        var deferred = new $.Deferred();

        this.rpc.invoke(funcName, {'id': id})
            .done(function(tour) {
                deferred.resolve(tour);
            })
            .fail(function (error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TourDao.prototype.insert = function(tour, startTag, endTag, checkpointTags, assignments, timeCs) {
        var deferred = new $.Deferred();
        var self = this;
        var params = {tour: tour,
            checkpoint_tags: checkpointTags,
            start_tag: startTag,
            end_tag: endTag,
            assignments: assignments,
            time_constraints: timeCs};

        self.rpc.invoke('insert', params)
            .done(function(result) {
                deferred.resolve(new TourDetailsDto(result));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TourDao.prototype.update = function(tour, startTag, endTag, checkpointTags, assignments, timecs) {
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke('update', {tour: tour,
            checkpoint_tags: checkpointTags,
            start_tag: startTag,
            end_tag: endTag,
            assignments: assignments,
            time_constraints: timecs})
            .done(function(result) {
                deferred.resolve(new TourDetailsDto(result));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TourDao.prototype.getTourDetails = function(tour_id) {
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke('get_tour_details', { tour_id: tour_id })
            .done(function(result) {
                deferred.resolve(new TourDetailsDto(result));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Get all direct (or transitive) tours for the specified customer, location,
     * object or workplace.
     * @param {Object} params Parameters to fetch the tour.
     * @param {Object} include Include parameters.
     */
    TourDao.prototype.findTourDetails = function(params, include) {
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke('find_tour_details', {
            filter_params: params,
            include_tour_tags: !!include.tourTags,
            include_timecs: !!include.timecs,
            include_assignments: !!include.assignments,
            include_assignable_tags: !!include.assignableTags
        }).done(function(result) {
            deferred.resolve(_.map(result.rows, function(row) {
                return new TourDetailsDto(row);
            }));
        }).fail(function(error) {
            deferred.reject(error);
        });

        return deferred.promise();
    };

    TourDao.prototype.getAssignableTags = function(assignments, tourId) {
        var deferred = new $.Deferred();
        var self = this;

        var params = {
            assignments: assignments,
            tour_id: tourId === undefined ? null : tourId
        };

        self.rpc.invoke('get_assignable_tags', params)
            .done(function(result) {
                var assignable = {};
                assignable.checkpointTags = _.map(result.checkpoint_tags, function(e) {return new Tag(e);});
                assignable.startTags = _.map(result.start_tags, function(e) {return new Tag(e);});
                assignable.endTags = _.map(result.end_tags, function(e) {return new Tag(e);});

                deferred.resolve(assignable);
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TourDao.prototype.getToursContainingTag = function(tagId) {
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke('get_tours_containing_tag', {tag_id: tagId})
            .done(function(result) {
                deferred.resolve(_.map(result, function(e) {return new TourListDto(e);}));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TourDao.prototype.findByCustomer = function(customer_id) {
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke('find_by_customer', {customer_id: customer_id})
            .done(function(result) {
                deferred.resolve(_.map(result, function(e) {return new Tour(e);}));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TourDao.prototype.getCalendars = function() {
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke('get_calendars')
            .done(function(result) {
                deferred.resolve(_.map(result, function(c) {return new CalendarDto(c);}));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TourDao.prototype.findAll = function(filter) {
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke('find_all', {filter_params: filter})
            .done(function(result) {
                var list = {
                    'totalCount': result.total_count,
                    'filteredCount': result.filtered_count,
                    'rows': _.map(result.rows, function(e) {return new TourListDto(e);})
                };
                deferred.resolve(list);
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

})(window.rise, window.app);
