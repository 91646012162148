/**
 * RPC error handler (callback).
 *
 * @module app.jsonrpc_handler
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app, rise) {
    "use strict";

    var NotificationManager = new window.notification.NotificationManager();

    /*** EXPORTS ***/
    var rpc_handler = app.rpc_handler = {};

    /*** IMPORTS ***/
    var log = rise.logging.getLogger('rpc');

    var runningCalls = 0;

    rpc_handler.WARNING_CODE = -207;

    rpc_handler.before = function() {
        app.removeErrorMessages();
        app.removeWarningMessages();
        app.removeValidationErrors();

        if(runningCalls === 0) {
            NotificationManager.showIndicator({
                icon: "spinner",
                autoClose: false,
                position: "right"
            });
        }
        runningCalls++;
    };

    rpc_handler.always = function() {
        runningCalls--;

        if(runningCalls === 0) {
            NotificationManager.closeAll();
        }
    };

    /**
     * Call back method for the failed RPC calls.
     * @param {*} errorObj Object with properties `code`, `message` and `data`.
     */
    rpc_handler.fail =  function(errorObj) {
        var rpcMessage = app.i18n.rpcMessages[errorObj.code];

        if (errorObj.code === -403) {
            log.info('Got session timeout, logging out (%s)', errorObj.code);
            // logout without releasing the session-id on server-side
            app.session.logout(true);
            app.showErrorMessage('For security reasons you were logged out. Your session has expired');

            return;
        }

        if (!rpcMessage) {
            log.warn('No message defined for errorObj code %s', errorObj.code);
            rpcMessage = {
                title: app.i18n.rpcMessages[-1].message,
                message: errorObj.code
            }
        }

        var message = (rpcMessage.title ? rpcMessage.title + ': ' : '') + rpcMessage.message;

        if (errorObj.data) {
            message = [
                message,
                ul({}, _.map(_.isArray(errorObj.data) ? errorObj.data : [errorObj.data], function (message_id) {
                    var msg = rpcMessage.validation_messages && rpcMessage.validation_messages[message_id] ?
                        rpcMessage.validation_messages[message_id]
                        : message_id;

                    return li(msg);
                }))
            ];
        }

        if (errorObj.code !== app.rpc_handler.WARNING_CODE) {
            app.showErrorMessage(message);
        } else {
            app.showWarningMessage(message);
        }
    }

})(window.app, window.rise);
