/**
 * Displays a list of all tour-runs visible for the current user.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function (rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav = rise.Navigation;
    var form = app.widgets.form;
    var clowFilter = app.widgets.clowFilter;

    /** DAOs **/
    var TourRunDao = app.dao.tourRunDao;
    var CustomerDao = app.dao.customerDao;

    /*** GLOBALS ***/
    var pages = app.pages;

    /*** CONSTANTS ***/
    var FILTER_DATE_STARTED = 14;

    var filter = {
        started: null,
        stopped: null,
        only_incorrect: false,
        filterExpression: null,
        user_id: null
    };

    /*** Export Pages ***/
    pages.tour_run.list = pages.tour_run.list || {};
    pages.tour_run.list.finished = app.pagesByPath['/tour-run/list/finished'] = TourRunListFinished;

    /*** Set permission ***/
    TourRunListFinished.permissions = [
        app.model.PermissionTypes.TOUR_MODIFY,
        app.model.PermissionTypes.TOUR_VIEW_CUSTOMER,
        app.model.PermissionTypes.TOUR_VIEW_SUPERVISOR,
        app.model.PermissionTypes.TOUR_VIEW_EMPLOYEE
    ];

    function TourRunListFinished() {
        var isEmployee =
            app.session.hasPermission(app.model.PermissionTypes.TOUR_VIEW_EMPLOYEE)
            && !app.session.hasPermission(app.model.PermissionTypes.TOUR_VIEW_SUPERVISOR)
            && !app.session.hasPermission(app.model.PermissionTypes.TOUR_VIEW_CUSTOMER)
            && !app.session.hasPermission(app.model.PermissionTypes.TOUR_MODIFY);

        if(!app.session.getSessionInfo().shift && isEmployee) {
            app.showErrorMessage('Keine Schicht gestartet');
            return;
        }

        // specify lookup date span
        if (!filter.stopped) {
            filter.stopped = new Date();
            filter.started = new Date();
            filter.started.setDate(filter.started.getDate() - FILTER_DATE_STARTED);

            filter.stopped = new Date(new JustDate().toDate().getTime() + 86399000);
        }

        var self = app.widgets.ListPage.call(this, {
            pageName: '/tour-run/list/finished',
            rpc: function () {
                return [
                    TourRunDao.getCustomerList(),
                    TourRunDao.getUserFilterList()
                ];
            },
            labels: {
                pageHeader: "Beendete Rundgänge"
            },
            "export": {
                method: "export_finished_tour_runs",
                criteriaFn: function () {
                    return {
                        filter_params: getFilterCriteria()
                    };
                }
            },
            "print":  {
                click: function() {
                    printTourRuns();
                }
            },
            "refresh": true
        });

        this.prepareTable = function ($parent, customers, users) {
            var columns = getTableColumns();
            var settings = getTableSettings();

            this.renderTable(null, columns, settings);

            filter.customers = customers;
            filter.users = users;
            $("#placeholder-filters").prependElement(
                renderFilter($parent, filter, self.datatable));

            $parent.activateValidators();
        };

        this.savePageState = function () { };

        this.loadPageState = function ($parent, customers) {
            if ($('#tour-run-filter-customer').hasClass('ui-autocomplete-input')) {
                filter.customers = customers;
                $('#tour-run-filter-customer').combobox('option', 'source', filter.customers);
            }

            this.updateTableServerData();
        };

        this.showDetailsPage = function (tourRun) {
            showDetailsPage(tourRun);
        };

        /**
         * Prints the current selected tour runs (with the selected filter and sorting)
         * to the print preview. This function uses the tour run paging service to iterate
         * through all filtered data.
         */
        function printTourRuns() {
            var selectedCustomer = filter.customer_id
                    ? _.findWhere(filter.customers, {id: filter.customer_id})
                    : null,
                selectedLocation = filter.location_id
                    ? _.findWhere((selectedCustomer && selectedCustomer.locations) || [], {id: filter.location_id})
                    : null,
                selectedObject = filter.object_id
                    ? _.findWhere((selectedLocation && selectedLocation.objects) || [], {id: filter.object_id})
                    : null,
                columns = getTableColumns(),
                sorting = self.datatable.fnSettings().aaSorting[0],
                tableFilter = $.extend({
                    sort_column: sorting[0],
                    sort_direction: sorting[1]
                }, getFilterCriteria());

            function formatDateTime(date) {
                return date.toFormatString("DDD " + app.settings.dateFormat + " HH:mm");
            }

            function renderReportHeaderAdditions($parent) {
                // render selected date range
                $parent.appendElement([
                    div({"class": "filter-info"}, [
                        div({"class": "date-range"},
                            sprintf("Auswerte-Zeitraum: %s bis %s",
                                formatDateTime(filter.started), formatDateTime(filter.stopped))
                        ),
                        div({"class": "customer-info"},
                            sprintf("Kunde: %s, Standort: %s, Objekt: %s",
                                ((selectedCustomer && selectedCustomer.name) || "Alle"),
                                ((selectedLocation && selectedLocation.name) || "Alle"),
                                ((selectedObject && selectedObject.name) || "Alle")
                            )
                        )
                    ])
                ]);
            }

            function getAndAppendData($parent, offset, limit) {
                var deferred = new $.Deferred();

                TourRunDao.findFinishedTourRuns($.extend({
                    offset: offset,
                    limit: limit
                }, tableFilter))
                    .done(function (data) {
                        var $tbody = $parent.find("#print-preview-tbody");
                        _.each(data.rows, function(row) {
                            $tbody.appendElement(tr(
                                _.map(columns, function(column) {
                                    return column.mRender && td(column.mRender(row[column.sAttrName], "display", row)) ||
                                        td(row[column.sAttrName]);
                                })
                            ));
                        });

                        if (offset < data.filteredCount) {
                            getAndAppendData($parent, offset + limit, limit)
                                .done(function() {
                                    deferred.resolve();
                                })
                                .fail(function() {
                                    deferred.reject();
                                });
                        } else {
                            deferred.resolve();
                        }
                    })
                    .fail(function() {
                        deferred.reject();
                    });

                return deferred.promise();
            }



            function renderReportBody($parent) {
                $parent.appendElement([
                    div({"class": "dataTables_wrapper"},
                        table({id:"print-preview-table"}, [
                            thead({id:"print-preview-thead"},
                                tr(_.map(columns, function(column) {
                                    return th(column.sTitle);
                                })
                                )),
                            tbody({id:"print-preview-tbody"})
                        ])
                    )
                ]);

                // fetch data in parts of 200 records
                return getAndAppendData($parent, 0, 200);
            }

            app.printpreview.openPrintPreview({
                title: "Beendete Rundgänge",
                fnRenderHeaderAdditions: renderReportHeaderAdditions,
                fnRenderBody: renderReportBody
            });
        }

        function getFilterCriteria() {
            return {
                begin: filter.started,
                end: filter.stopped,
                customer_id: filter.customer_id,
                location_id: filter.location_id,
                object_id: filter.object_id,
                workplace_id: filter.workplace_id,
                user_id: filter.user_id,
                filter_expression: filter.filterExpression,
                correct_filter: filter.only_incorrect ? 'ONLY_INCORRECT' : 'ALL'
            };
        }

        function getTableSettings() {
            return {
                aaSorting: [
                    [ 2, "desc" ]
                ],
                bServerSide: true,
                fnServerData: function (sSource, aoData, fnCallback) {
                    aoData = _.object(_.map(aoData, function (oData) {
                        return [oData.name, oData.value];
                    }));

                    TourRunDao.findFinishedTourRuns($.extend({
                        offset: aoData.iDisplayStart,
                        limit: aoData.iDisplayLength,
                        sort_column: aoData.iSortCol_0,
                        sort_direction: aoData.sSortDir_0
                    }, getFilterCriteria())).done(function (data) {
                        fnCallback({
                            aaData: data.rows,
                            iTotalRecords: data.totalCount,
                            iTotalDisplayRecords: data.filteredCount,
                            sEcho: aoData.sEcho
                        });
                    });
                }
            };
        }
    }

    function showDetailsPage(tourRun) {
        nav.goToPage(pages.tour_run.detail, [ tourRun, '/tour-run/list/finished' ]);
    }

    function getTableColumns() {
        return [
            {
                sTitle: "Rundgang",
                sAttrName: "tour_description",
                mRender: function (mData, type, obj) {
                    return obj.tour_name + (obj.tour_description ? (" (" + obj.tour_description + ")") : "");
                }
            },
            {
                sTitle: "Gestartet am",
                sAttrName: "start_time",
                mRender: function (mData, type, obj) {
                    return obj.start_time.toFormatString(app.settings.dateTimeFormat);
                }
            },
            {
                sTitle: "Beendet am",
                sAttrName: "end_time",
                mRender: function (mData, type, obj) {
                    return obj.end_time.toFormatString(app.settings.dateTimeFormat);
                }
            },
            {
                sTitle: "Dauer",
                sAttrName: "duration"
            },
            {
                sTitle: "Erfolgreich",
                sAttrName: "success",
                mRender: function (mData, type, obj) {
                    return obj.success ? 'Ja' : 'Nein';
                }
            },
            {
                sTitle: "Fortschritt",
                sAttrName: "progress",
                mRender: function(mData, type, obj) {
                    return obj.progress + '%';
                }
            },
            {
                sTitle: "Benutzer",
                sAttrName: "user_name"
            },
            {
                sTitle: "Revier",
                sAttrName: "tour_areas",
                bVisible: false
            },
            {
                sTitle: "Kunde",
                sAttrName: "customers"
            },
            {
                sTitle: "Standort",
                sAttrName: "locations"
            },
            {
                sTitle: "Objekt",
                sAttrName: "objects"
            },
            {
                sTitle: "Arbeitsplatz",
                sAttrName: "workplaces"
            }
        ];
    }

    function renderFilter($parent, filter, datatable) {
        /* jshint indent: false */

        function scrollDayInterval(next) {
            var $targetFrom = $("#tour-run-filter-start-datetime"),
                $targetTo = $("#tour-run-filter-end-datetime"),
                dateFrom = $targetFrom.datepicker('getDate'),
                dateTo = $targetTo.datepicker('getDate');

            if (next) {
                dateFrom.setDate(dateFrom.getDate() + 1);
                dateTo.setDate(dateTo.getDate() + 1);
            } else {
                dateFrom.setDate(dateFrom.getDate() - 1);
                dateTo.setDate(dateTo.getDate() - 1);
            }

            $targetFrom.datepicker('setDate', dateFrom);
            $targetTo.datepicker('setDate', dateTo);
            filter.started = dateFrom;
            filter.stopped = dateTo;

            filterTourRunEntries($parent, datatable);
        }

        return div({ id: 'tour-run-filter-form', 'class': 'whiteboard' }, [
            legend({}),
            ul({ "class": "inline-list title-row-highlight" }, [
                form.formItemText({id: 'tour-run-filter-search', label: 'Suche', required: false,
                    style: "width: 250px",
                    name: "filter_expression",
                    keyup: function () {
                        filter.filterExpression = $(this).val();
                    },
                    keypress: function (e) {
                        var keyCode = e.which;
                        if (keyCode === 13) {
                            filterTourRunEntries($parent, datatable);
                            return false;
                        }
                    },
                    change: function () {
                        filterTourRunEntries($parent, datatable);
                    }
                }),
                app.widgets.intervalPreviousIconButton({
                    'click': function() {
                        scrollDayInterval(false);
                    }

                }),
                form.formItemDateTime({
                    id: 'tour-run-filter-start-datetime', label: 'Von',
                    required: true,
                    datetimepickerParams: {
                        appendText: "",
                        showButtonPanel: true,
                        onClose: function () {
                            filter.started = $(this).datepicker('getDate');
                            filterTourRunEntries($parent, datatable);
                        }
                    },
                    renderDayScroller: true,
                    date: filter.started,
                    name: "begin",
                    keypress: function(e) {
                        var keyCode = e.which;
                        if (keyCode === 13) {
                            filter.started = $(this).datepicker('getDate');
                            filterTourRunEntries($parent, datatable);
                        }

                        app.helper.keyPressEnter(e);
                    }
                }),
                form.formItemDateTime({
                    id: 'tour-run-filter-end-datetime', label: 'Bis',
                    required: true,
                    datetimepickerParams: {
                        appendText: "",
                        showButtonPanel: true,
                        onClose: function () {
                            filter.stopped = $(this).datepicker('getDate');
                            filterTourRunEntries($parent, datatable);
                        }
                    },
                    renderDayScroller: true,
                    date: filter.stopped,
                    name: "end",
                    keypress: function(e) {
                        var keyCode = e.which;
                        if (keyCode === 13) {
                            filter.stopped = $(this).datepicker('getDate');
                            filterTourRunEntries($parent, datatable);
                        }

                        app.helper.keyPressEnter(e);
                    }
                }),
                app.widgets.intervalNextIconButton({
                    'click': function() {
                        scrollDayInterval(true);
                    }
                }),
                function($parent) {
                    clowFilter({
                        customers: filter.customers,
                        $parent: $parent,
                        change: function (selection) {
                            filter.customer_id = selection.customer === -1 ? null : selection.customer;
                            filter.location_id = selection.location === -1 ? null : selection.location;
                            filter.object_id = selection.object === -1 ? null : selection.object;
                            filter.workplace_id = selection.workplace === -1 ? null : selection.workplace;

                            filterTourRunEntries($parent, datatable);
                        },
                        attributeNameWorkplaces: 'assigned_workplaces'
                    });
                },
                createUserSelector($parent, datatable, filter),
                form.formItemCheckbox({id: 'tour-run-only-incorrect', label: 'Fehlerhafte Rundgänge',
                    name: "only_incorrect",
                    value: filter.only_incorrect,
                    change: function () {
                        filter.only_incorrect = $(this).prop("checked");
                        filterTourRunEntries($parent, datatable);
                    }
                })
            ])
        ]);
    }

    function createUserSelector($parent, datatable, filter) {
        var all = {'id': null, 'username': 'Alle'};

        return form.formItemSelect({id: 'filter-user', label: 'Benutzer', required: false,
            source: filter.users,
            valueProp: 'username',
            value: all,
            firstElem: all,
            sort: true,
            selected: function (e, user) {
                if (filter.user_id !== user.id) {
                    filter.user_id = user.id;
                    filterTourRunEntries($parent, datatable);
                }
            }
        });
    }

    function filterTourRunEntries($parent, datatable) {
        if ($parent.validate()) {
            datatable.fnDraw();
        }
    }

})(window.rise, window.app);
