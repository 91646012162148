import { Dao as LegacyDao } from 'lib/rise/dao';
import { Rpc } from 'lib/rise/rpc';

export type Newable<T> = { new (...args: any[]): T };

export class Dao<REQUEST, RESPONSE> extends LegacyDao<REQUEST, RESPONSE> {
    constructor(requestModelClass: Newable<REQUEST>, responseModelClass: Newable<RESPONSE>, rpc: typeof Rpc) {
        super(responseModelClass, rpc, requestModelClass);
    }
}
