/**
 * This file contains all model used by the ticket RPC endpoint
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.Ticket = function Ticket(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id =           obj.id != null ? parseInt(obj.id) : null;
        this.title =        obj.title ? obj.title : null;
        // TODO introduce enum
        this.type =         obj.type ? obj.type : null;
        this.description =  obj.description ? obj.description : null;
        this.occurred =     obj.occurred ?
            typeof obj.occurred === 'string' ?
                JustDate.fromISO(obj.occurred)
                : obj.occurred
            : null;
        this.creation =     obj.creation ?
            typeof obj.creation === 'string' ?
                Date.fromISO(obj.creation)
                : obj.creation
            : null;
    };

}(window.app));
