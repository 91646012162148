/**
 * Displays a list of all business_documents visible for the current user.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav         = rise.Navigation;

    /** DAOs **/
    var roleDao     = app.dao.roleDao;

    /*** GLOBALS ***/
    var pages         = app.pages;
    var data;

    /*** CONSTANTS ***/
    var ROLE_NAME_COLUMN_INDEX = 0;

    /*** Export Page ***/
    pages.role.list = app.pagesByPath['/role/list'] = RoleList;
    /*** Set permission ***/
    RoleList.permissions = [app.model.PermissionTypes.GROUPS_MODIFY];

    function RoleList() {

        app.widgets.ListPage.call(this, {
            pageName: '/role/list',
            prefix: "role",
            rpc: function () {
                return [ roleDao.findRoles() ];
            },
            labels: {
                pageHeader: "Gruppen",
                buttonNew: "Neue Gruppe",
                filterAll: "Alle Gruppen"
            },
            filters: {
                type: {
                    icons: false,
                    labels: {
                        on: "Kunden",
                        off: "Mitarbeiter"
                    },
                    values: {
                        on: "Kunde",
                        off: "Mitarbeiter"
                    }
                }
            }
        });

        this.prepareTable = function($parent, dataRoles) {
            data = dataRoles;

            var columns = getTableColumns();
            var settings = getTableSettings();

            this.renderTable(dataRoles, columns, settings);
        };

        this.savePageState = function() {};

        this.loadPageState = function($parent, dataRoles) {
            this.renderTableUpdate(dataRoles);
        };

        this.showDetailsPage = function(role) {
            if(role) {
                nav.goToPage(pages.role.detail, [role]);
            } else {
                nav.goToPage(pages.role.detail, [ null, data ]);
            }
        };
    }

    function getTableColumns() {
        return [
            {
                sTitle: "Name",
                sAttrName: "label"
            },
            {
                sTitle: "Typ",
                sAttrName: "type",
                sWidth: "130px",
                mRender: function(mData, type, obj) {
                    /* jshint indent: false */

                    switch (obj.type) {
                        case app.model.UserType.CUSTOMER:
                            return "Kunde";
                        case app.model.UserType.EMPLOYEE:
                            return "Mitarbeiter";
                    }
                    return "";
                }
            }
        ];
    }

    function getTableSettings() {
        return {
            aaSorting: [[ ROLE_NAME_COLUMN_INDEX, "asc" ]]
        };
    }

})(window.rise, window.app);
