/**
 * Displays a list of all tour areas
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav         = rise.Navigation;
    var form        = app.widgets.form;

    /** DAOs **/
    var tourAreaDao = app.dao.tourAreaDao;

    /*** GLOBALS ***/
    var pages = app.pages;
    var filter = {
        filter_expression: ''
    };
    let self;

    /*** CONSTANTS ***/
    var TOUR_AREA_ID_COLUMN_INDEX = 1;

    /*** Export Pages ***/
    pages.tour_area.list = app.pagesByPath['/tour-area/list'] = TourAreaList;

    /*** Set permission ***/
    TourAreaList.permissions = [app.model.PermissionTypes.TOUR_MODIFY];

    function TourAreaList() {

        self = app.widgets.ListPage.call(this, {
            pageName: '/tour-area/list',
            rpc: function () {
                return [ tourAreaDao.findAll({filter: filter}) ];
            },
            prefix: "tour_area",
            labels: {
                pageHeader: "Reviere",
                buttonNew: "Neues Revier"
            },
            filterSearchFieldInactive: true
        });

        this.prepareTable = function($parent, tourAreas) {
            var columns = getTableColumns();
            var settings = getTableSettings();

            this.renderTable(tourAreas, columns, settings);
            $("#placeholder-filters").prependElement(renderFilter($parent, filter));
        };

        this.savePageState = function() { };

        this.loadPageState = function($parent, tourAreas) {
            this.renderTableUpdate(tourAreas);
        };

        this.showDetailsPage = function(tourAreas) {
            showDetailsPage(tourAreas);
        };
    }

    function showDetailsPage(tourArea) {
        nav.goToPage(pages.tour_area.detail, tourArea ? [ tourArea ] : undefined);
    }

    function getTableColumns() {
        return [
            {
                sTitle: "Erstellt am",
                sAttrName: "created_at",
                sWidth: "120px",
                mRender: function(mData, type, obj) {
                    return obj.created_at.toFormatString(app.settings.dateTimeFormat);
                }
            },
            {
                sTitle: "Name",
                sAttrName: "name"
            }
        ];
    }

    function getTableSettings() {
        return {
            aaSorting: [[ TOUR_AREA_ID_COLUMN_INDEX, "asc" ]]
        };
    }

    function renderFilter($parent, filter) {
        /* jshint indent: false */

        return div({ id: 'tour-area-filter-form', 'class': 'whiteboard' }, [
            legend({}),
            ul({ "class":"inline-list title-row-highlight" }, [
                form.formItemText({id: 'tour-area-filter-search', label: 'Suche', required: false,
                    name: "filter_expression",
                    keyup: function () {
                        filter.filter_expression = $(this).val();
                    },
                    keypress: function (e) {
                        var keyCode = e.which;
                        if (keyCode === 13) {
                            filterTourAreaEntries(filter);
                            return false;
                        }
                    },
                    change: function () {
                        filterTourAreaEntries(filter);
                    }
                })
            ])
        ]);
    }

    function filterTourAreaEntries(filter) {
        tourAreaDao.findAll({filter: filter})
            .done(function(tourAreas) {
                self.renderTableUpdate(tourAreas);
            });
    }

})(window.rise, window.app);
