/**
 * This is the dto for the workplace
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    app.dto.WorkplaceListDto = function WorkplaceListDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id ? parseInt(obj.id) : null;
        this.workplace_name = obj.workplace_name ? obj.workplace_name : null;
        this.object_name = obj.object_name ? obj.object_name : null;
        this.location_name = obj.location_name ? obj.location_name : null;
        this.customer_name = obj.customer_name ? obj.customer_name : null;
    };

    app.dto.WorkplaceDetailsDto = function WorkplaceDetailsDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.workplace = obj.workplace != null
            ? new app.model.Workplace(obj.workplace)
            : null;

        // map event types
        this.assigned_event_types = obj.assigned_event_types
            ? _.map(obj.assigned_event_types, function (j) { return new app.dto.EventTypeWithAssignmentInfoDto(j); })
            : [];

        // map business_documents
        this.assigned_business_documents = obj.assigned_business_documents
            ? _.map(obj.assigned_business_documents, function (j) { return new app.dto.BusinessDocumentWithAssignmentInfoDto(j); })
            : [];

        // map assigned employees
        this.assigned_employees = obj.assigned_employees
            ? _.map(obj.assigned_employees, function (j) { return new app.dto.UserListDto(j); })
            : [];

        // map assigned employees
        this.assigned_tasks = obj.assigned_tasks
            ? _.map(obj.assigned_tasks, function (j) { return new app.dto.TaskListDto(j); })
            : [];

        // map assigned employees
        this.observed_objects = obj.observed_objects
            ? _.map(obj.observed_objects, function (j) { return new app.model.Object(j); })
            : [];

        // map tours
        this.assigned_tours = obj.assigned_tours
            ? _.map(obj.assigned_tours, function (j) { return new app.model.Tour(j); })
            : [];

        // map dms_intervals
        this.dms_intervals = obj.dms_intervals
            ? _.map(obj.dms_intervals, function (j) { return new app.model.DMSInterval(j); })
            : [];
    };

}(window.app));
