/**
 * This is the dto for the Session entity.
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    app.dto.SessionDto = function SessionDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.session_id       = obj.session_id ? obj.session_id : null;
        this.created_at       = obj.created_at ? new Date(obj.created_at * 1000) : null;
        this.last_accessed_at = obj.last_accessed_at ? new Date(obj.last_accessed_at * 1000) : null;
        this.shift            = obj.shift != null ? new app.model.Shift(obj.shift) : null;
        this.type             = app.model.SessionType.valueOf(obj.type);
        this.user             = new app.model.User(obj.user);
        this.type             = app.model.SessionType.valueOf(obj.type);
    };

}(window.app));
