import {
    DATE_FORMAT,
    DATE_FORMAT_HUMAN,
    DATE_TIME_FORMAT,
    DATE_TIME_FORMAT_HUMAN,
    TIME_FORMAT,
    TIME_FORMAT_HUMAN
} from "app/Constants";

/**
 * Application settings.
 *
 * @module app.settings
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
export const settings = (function(rise, app) {
    "use strict";

    /**
     * Set application logging defaults.
     */
    rise.logging.setDefaults({
        root: {
            level: "DEBUG",
            appender: "ConsoleAppender"
        },
        dao: {
            level: "INFO"
        },
        rpc: {
            level: "INFO"
        }
    });

    /**
     * Menu settings.
     * @type {Object}
     */
    app.settings.menu = {
        interval: 0,   // delay for mouse-over
        timeout: 0,    // delay for mouse-out
        sensitivity: 7
    };

    /**
     * Turn off compatibility mode of BoxWorld.
     */
    if($.box) {
        $.box.settings.compatibility = false;
    }

    /**
     * Set date formats globally
     */
    app.settings.dateFormat = DATE_FORMAT;
    app.settings.dateFormatHuman = DATE_FORMAT_HUMAN;
    app.settings.timeFormat = TIME_FORMAT;
    app.settings.timeFormatHuman = TIME_FORMAT_HUMAN;
    app.settings.dateTimeFormat = DATE_TIME_FORMAT;
    app.settings.dateTimeFormatHuman = DATE_TIME_FORMAT_HUMAN;

    /**
     * Method to print a date with time
     */
    Date.prototype.toDateTimeString = function() {
        return this.toFormatString(app.settings.dateTimeFormat);
    };

    JustDate.prototype.toDateString = function() {
        return this.toDate().toFormatString(app.settings.dateFormat);
    };

    /**
     * Datepicker defaults.
     */
    if ($.datepicker) {
        $.datepicker.setDefaults({
            dateFormat: rise.Date.convertFormatToJqUi(app.settings.dateFormat),
            appendText: sprintf(' (%s)', app.settings.dateFormatHuman),
            showOn: "button",
            showButtonPanel: true,
            buttonImage: "style/images/content/calendar-day.png",
            buttonImageOnly: true,
            changeMonth: true,
            changeYear: true,
            minDate: new Date(1900, 0, 1),
            maxDate: new Date(2100, 0, 1),
            yearRange: "1900:2100",
            beforeShow: function(){
                $("body").toggleClass("datepicker-is-open");
            },
            onClose: function(){
                $("body").toggleClass("datepicker-is-open");
            }
        });

        $.datepicker.setDefaults($.datepicker.regional.de || {});
    }

    $.timepicker && $.timepicker.setDefaults({
        timeFormat: app.settings.timeFormat,
        appendText: sprintf(' (%s)', app.settings.dateTimeFormatHuman),
        controlType: 'select',
        showButtonPanel: false,
        showSecond: false,
        showMillisec: false,
        showMicrosec: false,
        showTimezone: false,
        showTime: false
    });

    return app.settings;

}(window.rise, window.app));
