/**
 * This is the dto for the object
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    app.dto.ObjectDetailsDto = function ObjectDetailsDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.object = obj.object != null
            ? new app.model.Object(obj.object)
            : null;

        // map event types
        this.assigned_event_types = obj.assigned_event_types
            ? _.map(obj.assigned_event_types, function (j) { return new app.dto.EventTypeWithAssignmentInfoDto(j); })
            : [];

        // map business_documents
        this.assigned_business_documents = obj.assigned_business_documents
            ? _.map(obj.assigned_business_documents, function (j) { return new app.dto.BusinessDocumentWithAssignmentInfoDto(j); })
            : [];

        // map tags
        this.assigned_tags = obj.assigned_tags
            ? _.map(obj.assigned_tags, function (j) { return new app.model.Tag(j); })
            : [];

        // map tags
        this.assigned_tours = obj.assigned_tours
            ? _.map(obj.assigned_tours, function (j) { return new app.model.Tour(j); })
            : [];
    };

    app.dto.ObjectAssignmentsDto = function ObjectAssignmentsDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        // map event types
        this.assigned_event_types = obj.assigned_event_types
            ? _.map(obj.assigned_event_types, function (j) { return new app.dto.EventTypeWithAssignmentInfoDto(j); })
            : [];

        // map business_documents
        this.assigned_business_documents = obj.assigned_business_documents
            ? _.map(obj.assigned_business_documents, function (j) { return new app.dto.BusinessDocumentWithAssignmentInfoDto(j); })
            : [];
    };

}(window.app));
