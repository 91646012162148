/**
 * Data Access Object for the Ticket model.
 * Extends the generic rise.Dao function.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.ticket");

    /*** Export Dao ***/
    app.dao.ticket = new TicketDao();

    /**
     * @constructor
     */
    function TicketDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.Ticket, new Rpc('/rpc/ticket'));

        // optional: set additional variables, e.g.:
        this.primary_key_column = 'id';
        this.find_all_method = 'find_all';
    }

    // extend the parent Dao prototype
    $.extend(TicketDao.prototype, Object.create(rise.Dao.prototype));

    /**
     * Find all tickets with the given title
     *
     * @param {String} title
     * @return {Ticket|Array}
     */
    TicketDao.prototype.findByTitle = function(title) {
        log.info('Finding tickets by title');
        var deferred = new $.Deferred();

        this.rpc.invoke('find_tickets_by_title', {'title': title})
            .done(function(tickets) {
                if (tickets.length === 0) {
                    log.info('No tickets found');
                    deferred.resolve(null);
                } else {
                    log.info('Received %d tickets', tickets.length);
                    var result = _.map(tickets, function(ticket) {
                        return new app.model.Ticket(ticket);
                    });
                    deferred.resolve(result.length == 1 ? result [0] : result);
                }
            })
            .fail(function (error) {
                log.error('Requesting tickets failed. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

})(window.rise, window.app);
