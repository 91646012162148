/**
 * Tag detail page.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav = rise.Navigation;
    var pages = app.pages;
    var form = app.widgets.form;

    var editIconButton = app.widgets.editIconButton;
    var cancelIconButton = app.widgets.cancelIconButton;
    var saveIconButton = app.widgets.saveIconButton;
    var deleteIconButton = app.widgets.deleteIconButton;
    var commandBox = app.widgets.commandBox;
    var scrollTableSection = app.widgets.scrollTableSection;
    var ConfirmationDialog = app.widgets.ConfirmationDialog;
    var Map = app.widgets.Map;

    /** DAOs **/
    var Tag = app.model.Tag;
    var tagDao = app.dao.tagDao;
    var tourDao = app.dao.tourDao;
    var customerDao  = app.dao.customerDao;

    /** CONSTANTS **/


    /** GLOBALS **/
    var PageType = {
        VIEW: 1,
        EDIT: 2,
        NEW: 3
    };

    var TagTypes = [
        {id: 'CHECKPOINT', name: 'Kontrollpunkt'},
        {id: 'START', name: 'Start'},
        {id: 'END', name: 'Ende'}
    ];

    var TagTypeLabels = {
        CHECKPOINT: 'Kontrollpunkt',
        START: 'Start',
        END: 'Ende'
    };

    /* map visualization */
    var map = null;
    var defaultLocation = {lat: 48.21, lng: 16.370000000000005};


    /*** Export page ***/
    pages.tag.detail = app.pagesByPath['/tag/detail'] = function TagDetail() {
        var self = this;
        this.name = "/tag/detail";
        this.type = PageType.VIEW;
        this.goToPage = null;

        this.create = function ($parent, tag, goToPage) {
            this.type = tag ? PageType.VIEW : PageType.NEW;
            this.goToPage = app.pagesByPath[goToPage];

            var customerRpc = customerDao.findAll();

            if (tag) {
                this.tag = tag;
                return $.when(
                    customerRpc,
                    tagDao.get(tag.id),
                    tourDao.getToursContainingTag(tag.id)
                ).done(function(customers, tag, tours) {
                    self.customers = customers;
                    self.tag = tag;
                    self.tours = tours;
                    renderTag($parent);
                });

            } else {
                self.tag = new Tag();
                return customerRpc.done(function(customers) {
                    self.customers = customers;
                    renderTag($parent);
                });
            }
        };

        this.destroy = function() {
            // called after page has been destroyed
        };

        function renderTag($parent) {

            $parent.appendElement([
                renderCommandBox($parent),
                renderTagForm($parent, self.tag),
                renderAssignmentObject(self.tag, self.customers),
                self.type !== PageType.NEW ? renderAssignedTours() : undefined,
                renderGeoCoordinates(self.tag)
            ]);

            renderMap(self.tag);
            $parent.activateValidators();
        }

        function reRender($parent) {
            $parent.empty();
            renderTag($parent);
        }

        function renderCommandBox($parent) {
            var savedTag = new Tag(self.tag);

            function saveButton() {
                return saveIconButton({id: 'save-tag',
                    click: function () {
                        if ($parent.validate()) {
                            saveTag($parent);
                        }
                    }
                });
            }

            function cancelButton(backToOverview) {
                return cancelIconButton({id: 'cancel-edit-tag',
                    click: function () {
                        if (backToOverview) {
                            nav.goToPage(pages.tag.list);
                        } else {
                            $.validation.reset();
                            $parent.empty();

                            self.tag = savedTag;
                            self.type = PageType.VIEW;
                            renderTag($parent);
                        }
                    }
                });
            }

            function deleteProgramButton() {
                if (self.tag.last_programmed_at !== null) {
                    return deleteIconButton({id: 'delete-program-tag',
                        click: function(e) {
                            new ConfirmationDialog({
                                parent: $(e.target),
                                title: "Programm löschen",
                                text: "Wollen Sie das Programm wirklich vom Tag löschen?",
                                click: function() {
                                    deleteProgram($parent);
                                }
                            }).open();
                        }
                    }, 'Programm löschen');
                }
            }

            function deactivatetButton() {
                if (self.tag.active) {
                    return deleteIconButton({id: 'deactivate-tag',
                        click: function(e) {
                            new ConfirmationDialog({
                                parent: $(e.target),
                                title: "Tag löschen",
                                text: "Wollen Sie den Tag wirklich löschen?",
                                click: function() {
                                    deactivate();
                                }
                            }).open();
                        }
                    }, 'Tag löschen');
                }
            }

            function editTagButton() {
                return editIconButton({id: 'edit-tag',
                    click: function () {
                        self.type = PageType.EDIT;
                        $parent.empty();
                        renderTag($parent);
                    }
                }, 'Tag bearbeiten');
            }

            function getHeader () {
                /* jshint indent:false */
                switch (self.type) {
                    case PageType.NEW:  return 'Neue Kontrollstelle';
                    case PageType.EDIT: return 'Kontrollstelle bearbeiten';
                    case PageType.VIEW: return 'Kontrollstelle: ' + self.tag.name;
                }
                return '';
            }

            function getCommandButtons() {
                /* jshint indent:false */
                switch (self.type) {
                    case PageType.VIEW:
                        return [
                            deactivatetButton(),
                            deleteProgramButton(),
                            editTagButton()
                        ];
                    case PageType.EDIT:
                        return [
                            saveButton(),
                            cancelButton()
                        ];

                    case PageType.NEW:
                        return [
                            saveButton(),
                            cancelButton(true)
                        ];
                }

                return [];
            }

            function backAction() {
                if(self.goToPage) {
                    return self.goToPage;
                } else {
                    return pages.tag.list;
                }
            }

            return commandBox({backAction:backAction(), title: getHeader()},getCommandButtons());

        }

        function saveTag($parent) {
            if (self.type === PageType.EDIT) {
                //update the tag
                tagDao.update(self.tag).done(updateDoneMethod);
            } else if (self.type === PageType.NEW) {
                tagDao.insert(self.tag).done(insertDoneMethod);
            }

            function updateDoneMethod() {
                $parent.empty();
                self.type = PageType.VIEW;

                renderTag($parent);
                app.showSuccessMessage('Tag aktualisiert');
            }

            function insertDoneMethod(result) {
                self.tag = result;
                self.type = PageType.VIEW;
                $parent.empty();

                renderTag($parent);
                app.showSuccessMessage('Tag gespeichert');
            }
        }

        function deleteProgram($parent) {
            tagDao.deleteProgram(self.tag.id).done(deleteProgramDone);

            function deleteProgramDone (result) {
                self.tag = result;
                self.type = PageType.VIEW;
                $parent.empty();

                renderTag($parent);
                app.showSuccessMessage('Programm wurde gelöscht');
            }
        }

        function deactivate() {
            tagDao.deactivate(self.tag.id).done(deactivateDone);

            function deactivateDone (result) {
                app.showSuccessMessage('Tag wurde deaktiviert');
                nav.goToPage(pages.tag.list);
            }
        }

        function renderTagForm($parent, tag) {
            if (self.type === PageType.NEW) {
                return fieldset({id: 'tag-new'}, [
                    ul({'class': 'fieldset-content'}, [
                        form.formItemText({id: 'tag-id', label: 'Name', required: true,
                            value: tag.name,
                            change: function () {
                                tag.name = $(this).val();
                            }
                        }, false),
                        form.formItemText({id: 'tag-description', label: 'Beschreibung',
                            value: tag.description,
                            change: function () {
                                tag.description = $(this).val();
                            }
                        }, false),
                        form.formItemSelect({id: 'tag-type-select', label: 'Typ', required: true,
                            source: TagTypes,
                            valueProp: "name",
                            value: TagTypeLabels[tag.tag_type],
                            selected: function(e, selection) {
                                if (selection.id !== undefined && tag.tag_type !== selection.id) {
                                    tag.tag_type = selection.id;
                                    reRender($parent);
                                }
                            }
                        }, false)
                    ])
                ]);

            } else {
                return fieldset({id: 'tag-edit'}, [
                    ul({'class': 'fieldset-content'}, [
                        form.formItemText({id: 'tag-id', label: 'Name', required: true,
                            value: tag.name,
                            change: function () {
                                tag.name = $(this).val();
                            }
                        }, self.type === PageType.VIEW),
                        form.formItemText({id: 'tag-nfc-serial', label: 'Seriennummer', disabled: true,
                            value: tag.nfc_serial
                        }, true),
                        form.formItemText({id: 'tag-last-programmed', label: 'Programmiert am', disabled: true,
                            value: tag.last_programmed_at !== null ?
                                   tag.last_programmed_at.toFormatString(app.settings.dateTimeFormat) :
                                   null
                        }, true),
                        form.formItemText({id: 'tag-description', label: 'Beschreibung',
                            value: tag.description,
                            change: function () {
                                tag.description = $(this).val();
                            }
                        }, self.type === PageType.VIEW),
                        self.type === PageType.EDIT && tag.is_fully_editable ?
                            form.formItemSelect({id: 'tag-type-select', label: 'Typ', required: true,
                                source: TagTypes,
                                value: _.object(_.map(TagTypes, function(value) { return [value.id, value.name]; }))[tag.tag_type],
                                valueProp: "name",
                                selected: function(e, selection) {
                                    self.tag.tag_type = selection.id;
                                }
                            }, false) :
                            form.formItemText({id: 'tag-type', label: 'Typ', required: true,
                                value: TagTypeLabels[tag.tag_type]
                            }, true)
                    ])
                ]);
            }
        }

        function renderAssignmentObject(tag, customers) {
            var editable = self.type === PageType.NEW  ||
                (self.type === PageType.EDIT && tag.is_fully_editable);

            var selection = self.tag.customer_id ? {
                customers: [self.tag.customer_id],
                locations: [self.tag.location_id],
                objects: [self.tag.object_id]
            } : undefined;

            return fieldset({id: 'tag-assignment-edit'}, [
                legend({}, "Zuständigkeiten"),
                ul({'class':'fieldset-content'},
                    editable ? [
                        form.formItemWorkplaceSelect({
                            id: "select-workplace",
                            customers: customers,
                            depth: 3,
                            required: true,
                            selected: selection,
                            change: function(level, instance) {
                                if (level === 2) {
                                    tag.object_id = instance;
                                }
                            }
                        }, false)] : [
                        form.formItemText({id: 'tag-assignment-object', label: 'Objekt',
                            value: tag.object_name
                        }, true)]
                )
            ]);
        }

        function renderGeoCoordinates(tag) {
            if (self.type === PageType.VIEW &&
                tag.longitude === null && tag.latitude === null) {
                return undefined;
            }

            return fieldset({id: 'geo-edit'}, [
                legend({}, "Geo-Koordinaten"),
                    ul({'class': 'fieldset-content'}, [
                        form.formItemText({id: 'tag-longitude', label: 'Längengrad', value: tag.longitude,
                            validation: 'valid-float',
                            change: function () {
                                tag.longitude = $(this).val() ? parseFloat($(this).val()) : null;
                                updateMapMarker(tag);
                            }
                        }, self.type === PageType.VIEW),
                        form.formItemText({id: 'tag-latitude', label: 'Breitengrad', value: tag.latitude,
                            validation: 'valid-float',
                            change: function () {
                                tag.latitude = $(this).val() ? parseFloat($(this).val()) : null;
                                updateMapMarker(tag);
                            }
                        }, self.type === PageType.VIEW),
                        div({id: 'map-outer-div', 'class': 'map-outer-div'})
                    ]
                )
            ]);
        }

        function renderMap(tag) {
            var location = defaultLocation,
                mapHeight = 400,
                $mapOuterDiv = $("#map-outer-div");

            if (tag.longitude !== null && tag.latitude !== null) {
                location = {
                    'lat': tag.latitude,
                    'lng': tag.longitude
                };
            }

            function onClick(map, event) {
                if (self.type !== PageType.VIEW) {
                    map.removeAllMarkers();
                    map.setMarker(event.latLng);

                    tag.latitude = event.latLng.lat();
                    $("#tag-latitude").val(tag.latitude);

                    tag.longitude = event.latLng.lng();
                    $("#tag-longitude").val(tag.longitude);
                }

                map.setMapOptions({scrollwheel: true});
            }

            function onMouseOut(map, event) {
                map.setMapOptions({scrollwheel: false});
            }

            map = new Map($mapOuterDiv, {
                'location': location,
                'zoom': 17,
                'scrollwheel': false,
                'onClick': onClick,
                'onMouseOut': onMouseOut,
                'showPrintButton': true
            });

            map.render("height: " + mapHeight + "px").done(function () {
                map.markMapCenter(location);
                map.update();
            });
        }

        function updateMapMarker(tag) {
            if (tag.longitude !== null && tag.latitude !== null) {
                var location = {
                    'lat': tag.latitude,
                    'lng': tag.longitude
                };

                map.removeAllMarkers();
                map.markMapCenter(location);
            }
        }

        function renderAssignedTours() {
            return scrollTableSection({
                filter: true,
                id: "assigned-tours",
                title: 'Zugewiesene Rundgänge',
                elements: self.tours,
                paging: true,
                columns: [
                    {
                        sTitle: "Erstellt am",
                        sAttrName: "created_at",
                        mRender: function(mData, type, obj) {
                            return obj.created_at.toFormatString(app.settings.dateTimeFormat);
                        }
                    },
                    {
                        sTitle: "Name",
                        sAttrName: "name"
                    },
                    {
                        sTitle: "Beschreibung",
                        sAttrName: "description"
                    },
                    {
                        sTitle: "Kunden",
                        sAttrName: "customers"
                    },
                    {
                        sTitle: "Standorte",
                        sAttrName: "locations"
                    },
                    {
                        sTitle: "Objekte",
                        sAttrName: "objects"
                    },
                    {
                        sTitle: "Arbeitsplätze",
                        sAttrName: "workplaces"
                    }
                ],
                height: "100%"
            });
        }
    };

}) (window.rise, window.app);
