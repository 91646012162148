import React, { useCallback, useEffect, useState } from 'react';
import { tourReportDao } from 'app/tour-report/dao/TourReportDao';
import { DataTable } from 'app/legacy/widgets/table';
import $ from 'jquery';
import { iconButton } from 'app/legacy/widgets/button';
import { formatDate } from 'app/tour-report/TourReportConstants';
import { TourReportState } from 'app/tour-report/model/TourReportState';
import { Box } from 'app/BoxWorld';

type Props = Omit<TourReportState['dayListTable'], 'tourState'> & {
    handleSelect: (oData) => void;
    handleBackButton: () => Promise<void>;
};

const table = new DataTable({
    prefix: 'time-constraint',
    options: {
        noWhiteboard: false,
    },
    keyboardNavigationEnabled: false,
});

export function TimeConstraintTable(props: Props) {
    const [data, setData] = useState<any>({});
    const { dateFrom, dateTo, customerId, locationId, objectId, handleSelect, handleBackButton } = props;

    table.options.onSelect = (oData) => handleSelect(oData.tour);

    useEffect(() => {
        (async () => {
            const result = await tourReportDao.getTimecViolatingTours(
                dateFrom,
                dateTo,
                customerId < 0 ? null : customerId,
                locationId < 0 ? null : locationId,
                objectId < 0 ? null : objectId,
            );

            setData(result);
        })();
    }, [dateFrom, dateTo, customerId, locationId, objectId]);

    const renderTable = useCallback(async (parent) => {
        if (!parent) {
            return;
        }

        parent.replaceChildren();

        table.create($(parent));
        table.renderTable(
            data.rows,
            [
                {
                    sTitle: 'Name',
                    sAttrName: 'tour.name',
                },
                {
                    sTitle: 'Beschreibung',
                    sAttrName: 'tour.description',
                },
                {
                    sTitle: 'Kunden',
                    sAttrName: 'tour.customers',
                },
                {
                    sTitle: 'Standorte',
                    sAttrName: 'tour.locations',
                },
                {
                    sTitle: 'Objekte',
                    sAttrName: 'tour.objects',
                },
                {
                    sTitle: 'Arbeitsplätze',
                    sAttrName: 'tour.workplaces',
                },
                {
                    sTitle: 'Anzahl unzureichender Wiederholungen',
                    sAttrName: 'timec_violations',
                },
            ],
            {
                bAutoWidth: false,
                iDisplayLength: 25,
                sPaginationType: 'full_numbers',
                aaSorting: [
                    [2, 'desc'],
                ],
                oSearch: {
                    bSmart: false,
                },
            },
        );
    }, [data.rows]);

    const backButton = iconButton({
        id: 'back-button',
        class: 'button-back',
        click: handleBackButton,
    }, 'Zurück zur Tagesansicht');

    return (
        <>
            <h2>
                { `Rundgänge mit unzureichenden Wiederholungen per ${formatDate(dateFrom)}` }
            </h2>
            <div id="report-whiteboard" className="whiteboard">
                <Box>{backButton}</Box>
                { data.rows
                    ? <div style={{ marginTop: '10px' }} ref={renderTable} />
                    : <div className="loading" /> }
            </div>
        </>

    );
}
