/**
 * Base class for all list pages
 *
 * @module app.widgets
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    /*** IMPORTS ***/
    var addIconButton       = app.widgets.addIconButton;
    var refreshIconButton   = app.widgets.refreshIconButton;
    var printIconButton     = app.widgets.printIconButton;
    var commandBox          = app.widgets.commandBox;
    var csvButton           = app.widgets.csvButton;
    var excelButton         = app.widgets.excelButton;
    var DataTable           = app.widgets.DataTable;
    var toggleTreeButton    = app.widgets.textButton;

    /*** GLOBALS ***/
    app.widgets.ListPage = ListPage;

    function ListPage (options) {
        var self;

        self = this;
        self.datatable = undefined;

        var defaults = {
            prefix: "prefix",
            pageName: "pageName",
            rpc: function() {},
            "export": null,
            excel: null,
            print: null,
            refresh: null,
            labels: {
                pageHeader: "<pageHeader>",
                buttonNew: "<buttonNew>",
                filterAll: "<filterAll>"
            },
            options: {
                showCommandBox: true,
                showFilterBar: true
            },
            showLeftContainer: false
        };

        // extend this with all defaults and overwrite with options
        _.extend(this, defaults, options);

        if(!this.pageName) {
            throw "pageName undefined!";
        }

        this.name = this.pageName;

        this.create = function ($parent) {
            self.$parent = $parent;

            /* jshint indent: false */
            return $.when.apply(self, self.rpc()).done(function() {
                renderContent($parent, arguments);

                // init datatable
                if (!options.options) {
                    options.options = {};
                }

                if (!options.options.onSelect) {
                    options.options.onSelect = function() {
                        self.showDetailsPage.apply(self, arguments);
                    };
                }

                self.dataTableWidget = new DataTable(options);
                self.dataTableWidget.create($parent.find('.list-view'));

                // call renderContent function of child page
                self.prepareTable.apply(self, [ $parent ].concat(Array.prototype.slice.call(arguments)));
            });
        };

        this.attach = function($parent) {
            // enable add button --> fix bug after creation
            $(".button-add").removeClass("disabled");

            if (self.dataTableWidget && self.dataTableWidget.onAttach) {
                self.dataTableWidget.onAttach($parent);
            }

            return $.when.apply(self, self.rpc()).done(function() {
                self.loadPageState.apply(self, [ $parent ].concat(Array.prototype.slice.call(arguments)));
            });
        };

        this.detach = function() {
            if (self.dataTableWidget && self.dataTableWidget.onDetach) {
                self.dataTableWidget.onDetach();
            }
        };

        this.renderTable = function(data, columns, settings) {
            this.datatable = this.dataTableWidget.renderTable(data, columns, settings);
            return this.datatable;
        };

        this.renderTableUpdate = function(data) {
            this.dataTableWidget.renderTableUpdate(data);
        };

        this.updateTableServerData = function() {
            this.dataTableWidget.updateTableServerData();
        };

        function renderContent($parent, args) {
            // render basic content
            $parent.appendElement([
                self.options.showCommandBox
                    ? renderCommandBox(self.labels && self.labels.csvExportButton,
                                       self.labels && self.labels.xlsxExportButton)
                    : undefined]);
            if (self.showLeftContainer) {
                $parent.appendElement([
                    div({id: "business-document-container", "class":"clearfix"}, [
                        div({id: "leftContainer", "class":"sidebar"}, self.showLeftContainer.apply(self, Array.prototype.slice.call(args))),
                        div({id: "rightContainer", "class":"content-with-sidebar"}, [
                            self.options.showFilterBar
                                ? div({ id:"placeholder-filters" })
                                : undefined,

                            div({ "class": "list-view" })
                        ]),
                        div({"class":"toolbar"},[
                            toggleTreeButton({id:"toggle-category-list", "class":"sidebar-toggle",
                                click: function() {
                                    $("#business-document-container").toggleClass("sidebar-show");
                                    var showCategories = $("#business-document-container").hasClass("sidebar-show");
                                    if(showCategories) {
                                        $("#toggle-category-text").html("Kategorien ausblenden");
                                    } else {
                                        $("#toggle-category-text").html("Kategorien einblenden");
                                    }
                                }
                            }, [span({id:"toggle-category-text"},"Kategorien einblenden")])
                        ])
                    ])
                ]);
            } else {
                $parent.appendElement([
                    self.options.showFilterBar
                        ? div({ id:"placeholder-filters" })
                        : undefined,

                    div({ "class": "list-view" })
                ]);
            }
        }

        function renderCommandBox(csvExportLabel, xlsxExportLabel) {
            var buttonNewId = "new-" + self.prefix;
            var buttonRefreshId = "refresh" + self.prefix;

            return commandBox({ title: self.labels.pageHeader }, [
                self.refresh
                    ? refreshIconButton({id: buttonRefreshId, click: function () {
                    if (_.isFunction(self.refresh)) {
                        self.refresh();
                    } else {
                        self.updateTableServerData();
                    }
                }}, "Aktualisieren")
                    : undefined,
                self.labels.buttonNew
                    ? addIconButton({id: buttonNewId, click: function () {
                    self.showDetailsPage();
                }}, self.labels.buttonNew)
                    : undefined,
                self["export"]
                    ? csvButton(self["export"], csvExportLabel)
                    : undefined,
                self.excel
                    ? excelButton(self.excel, xlsxExportLabel)
                    : undefined,
                self.print
                    ? printIconButton({
                    click: self.print.click
                }, 'Drucken')
                    : undefined
            ]);
        }

        return this;
    }

    return ListPage;

})(window.app);

export const ListPage = app.widgets.ListPage;
