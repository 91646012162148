/**
 * User Tag detail page.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav = rise.Navigation;
    var pages = app.pages;
    var form = app.widgets.form;

    var editIconButton = app.widgets.editIconButton;
    var cancelIconButton = app.widgets.cancelIconButton;
    var saveIconButton = app.widgets.saveIconButton;
    var deleteIconButton = app.widgets.deleteIconButton;
    var commandBox = app.widgets.commandBox;
    var ConfirmationDialog = app.widgets.ConfirmationDialog;

    /** DAOs **/
    var UserTag = app.model.UserTag;
    var userTagDao = app.dao.userTagDao;
    var employeeDao = app.dao.employeeDao;

    /** CONSTANTS **/


    /** GLOBALS **/
    var PageType = {
        VIEW: 1,
        EDIT: 2,
        NEW: 3
    };


    /*** Export page ***/
    pages.user_tag.detail = app.pagesByPath['/user-tag/detail'] = function UserTagDetail() {
        var self = this;
        this.name = "/user-tag/detail";
        this.type = PageType.VIEW;
        this.goToPage = null;

        this.create = function ($parent, userTag, goToPage) {
            this.type = userTag ? PageType.VIEW : PageType.NEW;
            this.goToPage = app.pagesByPath[goToPage];

            if (userTag) {
                return $.when(
                        userTagDao.get(userTag.id),
                        employeeDao.getAllWithoutUserTags()
                    ).done(function(userTag, employees) {
                        self.userTag = userTag;
                        self.employees = employees;
                        renderUserTag($parent);
                    });
            } else {
                return $.when(
                        employeeDao.getAllWithoutUserTags()
                    ).done(function(employees) {
                        self.userTag = new UserTag();
                        self.employees = employees;
                    renderUserTag($parent);
                    });
            }
        };

        this.destroy = function() {
            // called after page has been destroyed
        };

        function renderUserTag($parent) {

            var _render = function() {

                $parent.appendElement([
                    renderCommandBox($parent),
                    renderUserTagForm()
                ]);

                $parent.activateValidators();
            };

            _render();
        }

        function renderCommandBox($parent) {
            var savedUserTag = new UserTag(self.userTag);

            function saveButton() {
                return saveIconButton({id: 'save-user-tag',
                    click: function () {
                        if ($parent.validate()) {
                            saveTag($parent);
                        }
                    }
                });
            }

            function cancelButton(backToOverview) {
                return cancelIconButton({id: 'cancel-edit-user-tag',
                    click: function () {
                        if (backToOverview) {
                            nav.goToPage(pages.user_tag.list);
                        } else {
                            $.validation.reset();
                            $parent.empty();

                            self.userTag = savedUserTag;
                            self.type = PageType.VIEW;
                            renderUserTag($parent);
                        }
                    }
                });
            }

            function deleteProgramButton() {
                if (self.userTag.last_programmed_at !== null) {
                    return deleteIconButton({id: 'delete-program-user-tag',
                        click: function (e) {
                            new ConfirmationDialog({
                                parent: $(e.target),
                                title: "Programm löschen",
                                text: "Wollen Sie das Programm wirklich von der Mitarbeiterkarte löschen?",
                                click: function() {
                                    deleteProgram($parent);
                                }
                            }).open();
                        }
                    }, 'Programm löschen');
                }
            }

            function deleteButton() {
                return deleteIconButton({id: 'delete-tag',
                    click: function(e) {
                        new ConfirmationDialog({
                            parent: $(e.target),
                            title: "Mitarbeiterkarte löschen",
                            text: "Wollen Sie die Mitarbeiterkarte wirklich löschen?",
                            click: function() {
                                deleteUserTag();
                            }
                        }).open();
                    }
                }, 'Karte löschen');
            }

            function editTagButton() {
                return editIconButton({id: 'edit-user-tag',
                    click: function () {
                        self.type = PageType.EDIT;
                        $parent.empty();
                        renderUserTag($parent);
                    }
                }, 'Mitarbeiterkarte bearbeiten');
            }

            function getHeader () {
                /* jshint indent:false */
                switch (self.type) {
                    case PageType.NEW:  return 'Neue Mitarbeiterkarte';
                    case PageType.EDIT: return 'Mitarbeiterkarte bearbeiten';
                    case PageType.VIEW: return 'Mitarbeiterkarte: ' + self.userTag.employee_number;
                }
                return '';
            }

            function getCommandButtons() {
                /* jshint indent:false */
                switch (self.type) {
                    case PageType.VIEW:
                        return [
                            deleteButton(),
                            deleteProgramButton(),
                            editTagButton()
                        ];
                    case PageType.EDIT:
                        return [
                            saveButton(),
                            cancelButton()
                        ];

                    case PageType.NEW:
                        return [
                            saveButton(),
                            cancelButton(true)
                        ];
                }

                return [];
            }

            function backAction() {
                if(self.goToPage) {
                    return self.goToPage;
                } else {
                    return pages.user_tag.list;
                }
            }

            return commandBox({backAction:backAction(), title: getHeader()},getCommandButtons());

        }

        function saveTag($parent) {
            if (self.type == PageType.EDIT) {
                //update the tag
                userTagDao.update(self.userTag).done(updateDoneMethod);
            } else if (self.type == PageType.NEW) {
                userTagDao.insert(self.userTag).done(insertDoneMethod);
            }

            function updateDoneMethod(result) {
                $parent.empty();
                self.userTag = new UserTag(result);
                self.type = PageType.VIEW;

                renderUserTag($parent);
                app.showSuccessMessage('Mitarbeiterkarte aktualisiert');
            }

            function insertDoneMethod(result) {
                $parent.empty();
                self.userTag = new UserTag(result);
                self.type = PageType.VIEW;

                renderUserTag($parent);
                app.showSuccessMessage('Mitarbeiterkarte gespeichert');
            }
        }

        function deleteProgram($parent) {
            userTagDao.deleteProgram(self.userTag.id).done(deleteProgramDone);

            function deleteProgramDone (result) {
                self.userTag = result;
                self.type = PageType.VIEW;
                $parent.empty();

                renderUserTag($parent);
                app.showSuccessMessage('Programm wurde gelöscht');
            }
        }

        function deleteUserTag($parent) {
            userTagDao.deleteUserTag(self.userTag.id).done(deleteTagDone);

            function deleteTagDone () {
                app.showSuccessMessage('Karte wurde gelöscht');
                nav.goToPage(pages.user_tag.list);
            }
        }

        function renderUserTagForm() {

            //TODO: cleanup the many page-type checks
            return fieldset({id: 'user-tag-new'}, [
                ul({'class': 'fieldset-content'}, [
                    self.type !== PageType.VIEW ?
                        form.formItemSelect({id: 'user-tag-employee-number-select', label: 'Mitarbeiternummer', required: true,
                            source: _.map(self.employees,
                                function(e) {
                                    return {number: e.number,
                                            value: e.number + ' - ' + e.first_name + ' ' + e.last_name};
                                }),
                            value: _.object(_.map(self.employees,
                                function(value) {
                                    return [value.number,
                                            value.number + ' - ' + value.first_name + ' ' + value.last_name];
                                }))[self.userTag.employee_number],
                            valueProp: "value",
                            selected: function(e, selection) {
                                self.userTag.employee_number = selection.number;
                            }
                        }, false) :
                        form.formItemText({id: 'user-tag-employee-number', label: 'Mitarbeiternummer', disabled: true,
                            value: self.userTag.employee_number
                        }, true),
                    self.type === PageType.VIEW ?
                        form.formItemText({id: 'user-tag-employee-name', label: 'Mitarbeitername', disabled: true,
                            value: self.userTag.employee_name
                        }, true) : undefined,
                    self.type !== PageType.NEW ?
                        form.formItemText({id: 'user-tag-nfc-serial', label: 'Seriennummer', disabled: true,
                            value: self.userTag.nfc_serial
                        }, true) : undefined,
                    self.type !== PageType.NEW ?
                        form.formItemText({id: 'user-last-programmed', label: 'Programmiert am', disabled: true,
                            value: self.userTag.last_programmed_at != null ?
                                self.userTag.last_programmed_at.toFormatString(app.settings.dateTimeFormat) :
                                null
                        }, true) : undefined
                ])
            ]);

        }

    };

}) (window.rise, window.app);
