import JSONCrush from "jsoncrush";
import React from 'react';
import sprintf from 'sprintf-js';
import { createRoot } from "react-dom/client";
import { PermissionDeniedPage } from "app/PermissionDeniedPage";

export const Navigation = (function(rise) {
    "use strict";

    /*** EXPORT ***/
    var Navigation = rise.Navigation = {};

    /**
     * Reference to the last rendered page instance.
     * @type {*}
     */
    var currentPage;

    /**
     * List of detached pages.
     * @type {{}}
     */
    var detached = {};

    /**
     * Callbacks fired when the container element is empty
     * (between page transitions)
     * @type {jQuery.Callbacks}
     */
    var pageChangeCallbacks = $.Callbacks();

    /**
     * Place to hold the jQuery object for the container.
     * @type {jQuery}
     */
    var cont = null;

    /**
     * Internal variable to prevent infinite loops during navigation.
     * @type {boolean}
     */
    var preventNavigationHashChange = false;

    var pageLoadDeferred;

    let reactRoot;

    /**
     * Initializes the navigation and makes sure all is set up correctly
     * @param {jQuery} container jQuery DOM element which should house the page
     * @param {Boolean} [confirmLeave] Controls if a confirmation should be shown, when the user leaves the page
     */
    Navigation.initialize = function initialize(container, confirmLeave) {
        if (!container) {
            throw "Navigation initialization failure: container is undefined!";
        }

        cont = container;
        window.onhashchange = Navigation.onHashChange;

        if (confirmLeave) {
            Navigation.addPageLeaveConfirmation();
        }
    };

    /**
     * Navigate to the given page by cleaning the parent container
     * and rendering the given page.
     * Set the page name and parameters as window hash.
     *
     * @param {function} Page one of window.app.pages*
     * @param {Array} [args] additional constructor arguments for page
     */
    Navigation.goToPage = function (Page, args) {

        if (!cont) {
            throw "Navigation Service is uninitialized!";
        }

        if (!Page) {
            throw "Page parameter is undefined!";
        }

        if (Page.permissions) {
            if (!_.any(_.map(Page.permissions, function(permission) { return window.app.session.hasPermission(permission); }))) {
                Navigation.goToPage(PermissionDeniedPage);
                return;
            }
        }

        if (!!Page.prototype.isReactComponent) {
            goToReactPage(Page, args);
            return;
        }

        reactRoot?.unmount();
        reactRoot = null;

        var instPage = new Page();
        if (!instPage || !instPage.create) {
            Navigation.goToPage(PermissionDeniedPage);
            console.error(`Cannot go to page ${Page.name}.`);
            return;
        }

        if (!args) {
            args = [];
        }

        var inner = cont.children();

        if (currentPage && currentPage.destroy) {
            currentPage.destroy(inner);
            currentPage = undefined;
        } else if (currentPage && currentPage.detach) {
            currentPage.detach(inner);
            detached[currentPage.name] = {
                dom: inner.detach(),
                page: currentPage
            };
        }

        // make sure the hash is set before the create function
        // because it can trigger the navigation again
        setHash(instPage.name, args);

        // always cleanup container
        cont.empty();

        // fire page change callbacks
        pageChangeCallbacks.fire(Page, args);

        if (detached[instPage.name]) {
            // restore detached DOM element
            inner = detached[instPage.name].dom;
            instPage = detached[instPage.name].page;
            cont.append(inner);
            currentPage = instPage;

            if (instPage.attach) {
                inner.hide();

                pageLoadDeferred = instPage.attach.apply(null, [inner].concat(args));

                if (pageLoadDeferred && _.isFunction(pageLoadDeferred.promise)) {
                    pageLoadDeferred.always(function() {
                        inner.show();
                    });
                } else {
                    inner.show();
                }
            }
        } else {
            // append new inner element and create page
            inner = cont
                .append($.box.html(div()))
                .children()
                .first();
            currentPage = instPage;

            return instPage.create.apply(instPage, [inner].concat(args));
        }

        return instPage;
    };

    /**
     * Navigate to the given React page
     * Set the page name and parameters as window hash.
     *
     * @param {function} Page React.Component
     * @param {Array} [args] additional Props for Page
     */
    function goToReactPage(Page, args) {
        if(!Page.prototype.isReactComponent) {
            throw 'Not a valid React Component';
        }

        currentPage = null;

        if(!reactRoot) {
            reactRoot = createRoot(cont[0]);
        }
        const ReactPage = React.createElement(Page, {
            parent: cont[0],
            ref: (instPage) => {
                if(instPage) {
                    currentPage = instPage;
                    setHash(instPage.name, args);
                }
            },
            args
        });
        reactRoot.render(ReactPage);

        // fire page change callbacks (e.g. update Menu)
        pageChangeCallbacks.fire(Page, args);

        // activate input validators
        cont.activateValidators();
    }

    /**
     * Listener for onhashchange event.
     * Parses the hash for a page name and parameters
     * and navigates to the page.
     */
    Navigation.onHashChange = function() {
        if (preventNavigationHashChange) {
            preventNavigationHashChange = false;
            return;
        }
        var hashParams = parseHash(window.location.hash);

        if ('page' in hashParams) {
            var pagePath = hashParams.page.split('.');
            var page = window.app.pagesByPath[pagePath];

            if (page) {
                Navigation.goToPage(page, hashParams.params_array);
                preventNavigationHashChange = false;
            }
        }
    };

    /**
     * Enables a confirmation message when the user tries to navigate away
     */
    Navigation.addPageLeaveConfirmation = function() {
        window.addEventListener("beforeunload", onPageUnload);
    };

    /**
     * Disables the confirmation message when the user tries to navigate away
     */
    Navigation.removePageLeaveConfirmation = function() {
        window.removeEventListener("beforeunload", onPageUnload);
    };

    Navigation.parseHash = parseHash;

    /**
     * Parse the given string and return an object with the parsed parameters.
     *
     * Because the order of keys in a JS object is not guaranteed, in addition to
     * the params object, it also returns a params_array which contains parameters
     * in the same order as in the hash.
     *
     * If a parameter occurs multiple times, the params object will merge all values
     * into an array. The param_array will simply list all values.
     *
     * The hash must be of the type #path.to.page?param1="text"&param2=12345&param3={"json":"json"}&param2=54321
     *
     * The result is an object {
     *      page: 'path.to.page',
     *      params: {
     *          param1: 'text',
     *          param2: [12345, 54321],
     *          param3: {
     *              json: 'json'
     *          }
     *      },
     *      params_array: [
     *          'text',
     *          12345,
     *          {
     *              json: 'json'
     *          },
     *          54321
     *      ]
     * }
     * @param {String} hash
     * @returns {Object}
     */
    function parseHash(hash) {
        if (!hash) {
            return {};
        }

        if (hash.indexOf('#') === 0) {
            hash = hash.slice(1);
        }

        var tmp = hash.split('?');
        var result = {
            page: tmp[0]
        };

        var separatorIndex = hash.indexOf('?');
        if (separatorIndex > -1) {
            var params = tmp[1].split('&');
            result.params = {};
            result.params_array = [];

            for (var i = 0; i < params.length; i++) {
                var keyValuePair = params[i].split("=");
                var key = keyValuePair[0];
                var value = JSONCrush.uncrush(decodeURIComponent(keyValuePair[1]));

                try {
                    value = JSON.parse(value);
                } catch (parseError) {
                    // not a valid json, ignore
                }
                result.params_array.push(value);

                if (typeof result.params[key] === "undefined") {
                    result.params[key] = value;
                } else if (typeof result.params[key] === "string" || typeof result.params[key] === "number") {
                    // if the key already exists, merge the values into an array
                    result.params[key] = [result.params[key], value];
                } else {
                    result.params[key].push(value);
                }
            }
        }
        return result;
    }

    /**
     * A function, or array of functions, that are to be added to the callback list.
     * @param fun
     */
    Navigation.addPageChangeCallback = function(fun) {
        pageChangeCallbacks.add(fun);
    };

    /**
     * A function, or array of functions, that are to be removed from the callback list.
     * @param fun
     */
    Navigation.removePageChangeCallback = function(fun) {
        pageChangeCallbacks.remove(fun);
    };

    Navigation.clearPageCache = function() {
        detached = {};
    };

    Navigation.reset = () => {
        window.location.hash = '';
    }

    /**
     * Internal function to set the window hash based on the
     * given page instance and arguments.
     *
     * Prevents the triggering on the Navigation.onHashChange function.
     *
     * @param {String} pageName
     * @param {Object[]} [args]
     */
    function setHash(pageName, args) {
        var hash = pageName;

        if (!args) {
            args = [];
        }

        if (args.length > 0) {
            hash += '?';
        }

        for (var i = 0; i < args.length; i++) {
            if (i > 0) {
                hash += '&';
            }
            hash += 'p' + i + '=' + encodeURIComponent(JSONCrush.crush(JSON.stringify(args[i] ?? null)));
        }

        if (window.location.hash !== hash) {
            preventNavigationHashChange = true;
            window.location.hash = hash;
        }
    }

    function onPageUnload(e) {
        var confirmationMessage = "you will be logged out";

        (e || window.event).returnValue = confirmationMessage;     //Gecko + IE
        return confirmationMessage;                                //Gecko + Webkit, Safari, Chrome etc.
    }

    return Navigation;

})(window.rise);
