/**
 * jQuery Validation Plug-in
 *
 * Requirements:
 *      jQuery              1.8.2   http://code.jquery.com/jquery-1.8.2.js
 *      Underscore.string   2.3.0   http://epeli.github.com/underscore.string/lib/underscore.string.js
 *
 * Recommended:
 *      Underscore.js       1.3.3   http://underscorejs.org/underscore.js
 *      jQuery Box          0.x     https://dev.box-world.org/trac/boxdev/browser/dist/v0.x/jquery.box.js
 *
 * @author Conrad Wandl
 * @author Christoph Mayerhofer
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 * ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function($, _) {
    "use strict";

    // holds Validation object for private functions
    var self;

    /**
     * Constructor for ValidationError objects holding relevant error data
     *
     * @class   ValidationError
     * @constructor
     * @param   $element error related jQuery object
     * @param   message error message
     * @param   cause validator causing the error
     */
    function ValidationError($element, message, cause) {
        this.$element = $element;
        this.message = message;
        this.cause = cause;
    }

    /**
     * @class   Validation
     * @constructor
     * @param   {Object}    settings    Initial settings object
     */
    function Validation (settings) {
        self = this;

        /**
         * Plugin default settings
         *
         * @property    {Object}    defaults
         */
        this.defaults = $.extend(true, {
            /**
             *  marks fields containing errors
             *
             *  @attribute  {String}    errorClass
             */
            errorClass:                 "validation-error",
            /**
             * prevent validation
             *
             * @attribute   {String}    disableValidation
             */
            disableValidation:          "disable-validation",
            /**
             * suppress error message
             *
             * @attribute   {String}    disableMessage
             */
            disableMessage:             "disable-message",
            /**
             * custom event used to trigger validation
             *
             * @attribute   {String}    eventType
             */
            eventType:                  "validate",
            /**
             * html5 data-message replaces validators default message
             *
             * @attribute   {String}    messageAttribute
             */
            messageAttribute:           "data-message",
            /**
             * html5 data-name sets an elements name for validation messages
             *
             * @attribute   {String}    nameAttribute
             */
            nameAttribute:              "data-name",
            /**
             * $.data key to retrieve the Error object
             *
             * @attribute   {String}    domDataKey
             */
            domDataKey:                 "error",
            /**
             * jQuery selector to find fields containing validators
             */
            selector:                   "[class*='valid']",
            /**
             * function called for every dom element after all it's validators are executed
             *
             * <pre>
             * function($element, error) {
             *     if(error) {
             *         doSomething();
             *     }
             * }
             * </pre>
             *
             * @attribute   {Function}  onProgress
             */
            onProgress:                 undefined,
            /**
             * function called after all validators for all fields are executed
             *
             * <pre>
             * function(isValid) {
             *
             * }
             * </pre>
             *
             * @attribute   {Function}  onComplete
             */
            onComplete:                 undefined
        }, settings);

        /**
         * Contains all validators. Use '$.validation.addValidators' function to add validators.
         *
         * format of a validator:
         *
         * <pre>
         *
         * {
         *     ".validValidatorName": [    // styleclass selector, MUST start with "valid"
         *         function(e, value [, value2, ...]) {    // function to call for validation, must return true or false
         *             ...
         *         },
         *         "error message",        // messsage to show on error
         *         "blur"                  // additional event to call validator on
         *     ]
         * }
         * </pre>
         *
         * @property   {Object}    validators
         */
        this.validators = { };

        /**
         * Contains custom arguments for validators. Use '$.validation.addArguments' function to add arguments.
         *
         * @property    {Object}    validatorArguments
         */
        this.validatorArguments = { };

        /**
         * Holds the ValidationError objects for the latest validation
         *
         * @type        {Object}    errors
         */
        this.errors = { };
    }

/*******************************************************************************************************
 Public functions
 *******************************************************************************************************/

    $.extend(Validation.prototype, {

        /**
         * Allows to extend the default settings
         *
         * @method  setDefaults
         * @param   {Object}    settings    Object containing the desired settings
         */
        setDefaults : function(settings) {
            $.extend(true, this.defaults, settings);
        },

        /**
         * Add validators to be used with this plugin.
         *
         * @method  addValidators
         * @param   {Object}    validators  Object containing validators
         */
        addValidators: function(validators) {
            $.extend(true, this.validators, validators);
        },
        /**
         * Add arguments for a specific validator and element.
         *
         * Every argument is used only once and deleted afterward.
         *
         * @method  addArguments
         * @param   {String}    clazz       name of the validator (e.g. ".valid-date")
         * @param   {String}    elementId   id of dom element (e.g. "input-date")
         */
        addArguments: function(clazz, elementId/*, arg1 [, arg2, ...]*/) {

            if(!this.validators[clazz]) {
                throw "Validation.prototype.addArguments: can't add arguments for non-existing validator "+clazz;
            }

            if(!this.validatorArguments[clazz]) {
                this.validatorArguments[clazz] = {};
            }

            this.validatorArguments[clazz][elementId] = $.makeArray(arguments).slice(2);
        },

        /**
         * Resets all validation errors
         *
         * @method  reset
         */
        reset : function() {
            this.errors = {};
        },

        /**
         * transform errors-object to array containing only messages
         *
         * @method  getMessages
         * @return  {Array}     containing all error messages of the latest validation
         */
        getMessages: function() {
            return _.chain(this.errors)
                .pluck("message")
                .reject(_.isUndefined)
                .value();
        }
    });

/*******************************************************************************************************
 Private functions
 *******************************************************************************************************/

    /**
     * Does the actual validation
     *
     * @method  validate
     * @param   {jQuery}            $parent     parent to search the label for the tested element
     * @param   {Event}             validationEvent           event which triggered the validation
     * @param   {Regex|Function}    regex       regex or function to use for validation
     * @param   {String}            message     message provided by the validator
     * @param   {String}            clazz       styleclass of the validator
     * @param   {Object}            options     options of validation class
     */
    function validate($parent, validationEvent, regex, message, clazz, options) {
        var label, match, $element, elementId, result, error, value, validatorCount;

        $element = $(validationEvent.target);
        elementId = $element.attr("id");

        // stop if element doesn't have an id
        if(elementId === undefined) {
            throw "Validation.prototype.validate: every element with an attached validator must have an id attribute";
        }

        // do not validate element if class 'options.disableValidation' is set
        if($element.hasClass(options.disableValidation)) {
            return;
        }

        if($element.hasClass(options.disableMessage)) {
            message = undefined;
        } else {
            // search elements name at 'option.nameAttribute'-attribute first
            label = $element.attr(options.nameAttribute);
            message = $element.attr(options.messageAttribute) || message;

            if(!label) {
                // search label with 'for'-attribute relation the elements 'id'-attribute
                label = $parent.find("label[for='" + $element.attr("id") + "']");
                if (label.length !== 0) {
                    // remove ':' and '*' from labels
                    label = label.html().replace(new RegExp("[:*]", "g"), "");
                } else {
                    // every element with an attached validator must have a 'data-name' attribute or a related 'label'
                    throw "Validation.prototype.validate: '"+options.nameAttribute+"' or  '<label>' is compulsory";
                }
            }

            // replace generic part of the message with label of the element
            message = sprintf(message, label);
        }

        if (regex.constructor === Function){
            match = matchFunc;
        } else if (regex.constructor === RegExp){
            match = matchRegEx;
        }

        if(self.validatorArguments[clazz] && self.validatorArguments[clazz][elementId]) {
            value = self.validatorArguments[clazz] && self.validatorArguments[clazz][elementId];
            delete self.validatorArguments[clazz][elementId];
        } else {
            value = $element.val();
        }

        result = match(regex, validationEvent, value);

        if (result) {
            delete self.errors[elementId];
            error = undefined;

            // remove errorClass and ValidationError of validated element
            $element
                .removeClass(options.errorClass)
                .removeAttr("aria-invalid")
                // set data to undefined instead of removeData (see http://api.jquery.com/removeData/)
                .data(options.domDataKey, undefined);
        } else {
            error = new ValidationError($element, message, clazz);
            self.errors[elementId] = error;

            // mark validated field with errorClass and add ValidationError object with $.data
            $element
                .addClass(options.errorClass)
                .attr("aria-invalid", "true")
                .data(options.domDataKey, error);

            // prevent all other event handlers
            validationEvent.stopImmediatePropagation();
        }

        validatorCount = ($element.attr("class") ? $element.attr("class").match(/valid/g) : []).length;

        // check if an onProgress function is provided, call only once for every DOM element
        if (self.defaults.onProgress) {
            self.defaults.onProgress($element, error);
        }

        // if all validators are done
        if(validationEvent.validationCount === validatorCount) {
            // if validationEvent is not <eventType> (it is e.g. change, blur, ...) the validation is complete
            if (validationEvent.type !== self.defaults.eventType && self.defaults.onComplete) {
                self.defaults.onComplete(_.isEmpty(self.errors));
            }
        }
    }

    /**
     * Checks the child elements of a given root for input errors. If at least one error
     * has been detected, an error summary is presented. Otherwise, no action is
     * performed.
     *
     * @method  validation
     * @param   {jQuery}    validationBox   root element
     * @return  {Boolean}                   false on error, true otherwise.
     */
    function validation(validationBox) {
        var options, validationElems, i, errorElements;

        options = self.defaults;

        // collects all elements which have at least one validation styleclass set (unique)
        validationElems = validationBox.find(options.selector).add(validationBox.filter(options.selector));

        _.each(validationElems, function(elem, idx) {
            validateInOrder(elem);
        });

        return _.isEmpty(self.errors);
    }

    function validateInOrder(target, e) {
        var $target, handlers, validationEvent;

        $target = $(target);
        handlers = $target.attr("class").match(/valid\S+/g);

        validationEvent = e || new $.Event(self.defaults.eventType, {
            target: target
        });
        validationEvent.validationCount = 0;

        // triggers "validate" (without namespace) event to support jQuery Box "validate"-event
        // does not trigger "validate.validator-name" event handlers
        $target.trigger(self.defaults.eventType);

        _.each(handlers, function(handler, idx) {
            // trigger "validate.validator-name" event handler
            $target.trigger(self.defaults.eventType+"."+handler, validationEvent);
        });
    }

    /**
     * Tries to match 'val' with 'regex'
     *
     * @method  matchRegEx
     * @param   {String}    val     Value to test
     * @param   {RegExp}    regex   Regular Expression
     * @return  {Boolean}           Return whether 'val' matches 'regex' or not
     */
    function matchRegEx(regex, e, val) {
        var i;

        if(!(val instanceof Array)) {
            val = [val];
        }

        for(i=0; i < val.length; i++) {
            if(!val[i].match(regex)) {
                return false;
            }
        }

        return true;
    }

    /**
     * Calls 'func' to test validity of 'val'
     *
     * @method  matchFunc
     * @param   {String}    val     Value to test
     * @param   {Function}  func    Validity check
     * @param   {Event}     e       Event which triggers the validation
     * @return  {Boolean}           Returns result of 'func'
     */
    function matchFunc(func, e, val) {
        return func.apply(self, [e].concat(val));
    }

/*******************************************************************************************************
 jQuery Plugin definitions
 *******************************************************************************************************/

    /**
     * Activates the validation for every selected root element.
     *
     * @method  activateValidators
     * @for     Validation.jQuery
     * @param   {Object}    settings    additional settings
     */
    $.fn.activateValidators = function(settings) {
        var options;

        options = $.extend(true, self.defaults, settings);

        /**
         * binds event handlers for fields annotated with validation classes
         * @param $parent
         * @param validatorClass style class to trigger the given validator
         * @param validator array containing validation logic
         */
        function registerEvents($parent, validatorClass, validator) {

            if(validator.length < 2) {
                throw "$.fn.activateValidators: definition of '"+validatorClass+"' is malformed";
            }

            if(validatorClass.indexOf(".valid") !== 0) {
                throw "$.fn.activateValidators: validator name '"+validatorClass+"' must start with '.valid'";
            }

            // the function which actually does the validation
            var validationFunc = validator[0],
            // validation message
                validatorMessage = validator[1],
            // additional event to trigger the validation on
                additionalEvent = validator[2],
                message = "";

            // bind events of type <eventType> to elements with class 'validatorClass'
            // and add the eventhandler function
            $parent.eventOn(options.eventType+validatorClass, validatorClass, validationEventHandler);

            // bind an additional event to trigger the activation
            if(additionalEvent) {
                // remove events of additionalEvent first to prevent e.g. multiple blur.validate events
                $parent.eventOff(additionalEvent+"."+self.defaults.eventType, options.selector);
                $parent.eventOn(additionalEvent+"."+self.defaults.eventType, options.selector, additionalEventHandler);
            }

            function validationEventHandler(e, validationEvent) {
                var allowedEvent = self.validators[validatorClass][2];

                // prevent event from bubbling up the DOM tree
                e.stopPropagation();

                // validationEvent.type === options.eventType                         -> validation was triggered by calling validate function, not by an additional event
                // (validationEvent.type === allowedEvent)                            -> validate only if the validator supports this event
                // (allowedEvent === "blur" && validationEvent.type === "focusout")   -> sometimes blur is reported as focusout
                if( validationEvent && (
                        validationEvent.type === options.eventType ||
                        validationEvent.type === allowedEvent ||
                        (allowedEvent === "blur" && validationEvent.type === "focusout"))) {

                    // save count of executed validators to event, this is used to determine if all
                    // validators for a single dom element are already executed
                    validationEvent.validationCount++;

                    if(!validationEvent.isImmediatePropagationStopped()) {
                        // perform the validation
                        validate($parent, validationEvent, validationFunc, validatorMessage, validatorClass, options);
                    }
                }
            }

            function additionalEventHandler(e) {
                if($(e.target).is(options.selector)) {
                    validateInOrder(e.target, e);
                }
            }
        }

        self.reset();

        _.each(this, function(parent, idx) {
            var $parent = $(parent),
                events = "."+self.defaults.eventType+" "+self.defaults.eventType;

            // remove all event handlers attached by this plugin on $parent
            $parent.eventOff(events);

            // call registerEvents for all types of validators
            _.each(self.validators, function(validator, validatorClass) {
                registerEvents($parent, validatorClass, validator);
            });
        });
    };

    /**
     * Checks the selected elements and it's children for input errors.
     *
     * @method  validate
     * @for     Validation.jQuery
     * @return  {Boolean}   false on input errors, true otherwise
     */
    $.fn.validate = function() {
        var isValid = true;

        self.reset();
        _.each(this, function(parent, idx) {
            isValid = isValid && validation($(parent));
        });

        if (self.defaults.onComplete) {
            self.defaults.onComplete(isValid);
        }

        return isValid;
    };

    /**
     * Attach an event handler function for one or more events to the selected elements.
     *
     * Ensures backwards compatibility for jQuery < 1.7
     *
     * @for     Validation.jQuery
     * @method  eventOn
     * @param   {String}    events      One or more space-separated event types and optional namespaces, such as "click" or "keydown.myPlugin".
     * @param   {String}    selector    A selector string to filter the descendants of the selected elements that trigger the event. If the selector is null or omitted, the event is always triggered when it reaches the selected element.
     * @param   {Function}  handler     A function to execute when the event is triggered. The value false is also allowed as a shorthand for a function that simply does return false.
     */
    $.fn.eventOn = function(events, selector, handler) {
        if($.fn.on) {
            this.on(events, selector, handler);
        } else {
            this.delegate(selector, events, handler);
        }
    };

    /**
     * Remove an event handler.
     *
     * Ensures backwards compatibility for jQuery < 1.7
     *
     * @for     Validation.jQuery
     * @method  eventOff
     * @param   {String}    events      One or more space-separated event types and optional namespaces, or just namespaces, such as "click", "keydown.myPlugin", or ".myPlugin".
     * @param   {String}    selector    A selector which should match the one originally passed to .on() when attaching event handlers.
     */
    $.fn.eventOff = function(events, selector) {
        if($.fn.off) {
            this.off(events, selector);
        } else {
            this.undelegate(selector, events);
        }
    };

/*******************************************************************************************************
 Create Validation instance and expose as $.validation
 *******************************************************************************************************/

    $.validation = new Validation();

/*******************************************************************************************************
 jQuery Box extensions
 *******************************************************************************************************/

    // add "validate" event to jQuery Box coreevents
    if($.box) {
        $.box.settings.coreevents.push(self.defaults.eventType);
    }

}(jQuery, window._));
/* @license end of code */
