/**
 * This is a "base" class for the logbook entry. There are different types which share
 * these information
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    /**
     * Is the base class for LogbookEntry. Base means: if a `entry_type` is set
     * this model will create a SUBTYPE and return it.
     *
     * e.g. if `entry_type` == SHIFT, a ShiftLogbookEntry is created and returned
     * NOT a LogbookEntry
     *
     * @param json
     * @returns {app.model.ShiftLogbookEntry | app.model.EventLogbookEntry | app.model.LogbookEntry}
     * @constructor
     */
    app.model.LogbookEntry = function LogbookEntry(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        if (this instanceof app.model.LogbookEntry) {
            switch (obj.entry_type) {
                case app.model.LogbookEntryType.SHIFT:
                    return new app.model.ShiftLogbookEntry(json);
                case app.model.LogbookEntryType.EVENT:
                    return new app.model.EventLogbookEntry(json);
                case app.model.LogbookEntryType.TASK:
                    return new app.model.TaskLogbookEntry(json);

            }
        }

        this.id =           obj.id != null ? parseInt(obj.id) : null;
        this.user_id =  obj.user_id != null ? parseInt(obj.user_id) : null;
        this.entry_type =   app.model.LogbookEntryType.valueOf(obj.entry_type);

        this.time_logged =  obj.time_logged ?
            typeof obj.time_logged === 'string' ?
                Date.fromISO(obj.time_logged)
                : obj.time_logged
            : null;

        this.time_entered = obj.time_entered ?
            typeof obj.time_entered === 'string' ?
                Date.fromISO(obj.time_entered)
                : obj.time_entered
            : null;

        this.client_ip =    obj.client_ip != null ? obj.client_ip : null;
        this.description =  obj.description != null ? obj.description : null;

        // map logbook entry note instances
        this.notes = _.map(obj.notes || [], function(note) {
            return new app.model.LogbookEntryNote(note);
        });
    };

}(window.app));
