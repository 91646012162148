/**
 * Displays a list of all business_documents visible for the current user.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav         = rise.Navigation;
    var clowFilter  = app.widgets.clowFilter;
    var permissions = app.model.PermissionTypes;
    var form        = app.widgets.form;
    var addIconButton = app.widgets.addIconButton;
    var editIconButton = app.widgets.editIconButton;
    var deleteIconButton = app.widgets.deleteIconButton;
    var ConfirmationDialog = app.widgets.ConfirmationDialog;
    var EditDialog = app.widgets.EditDialog;

    /** DAOs **/
    var businessDocumentDao = app.dao.businessDocumentDao;
    var businessDocumentCategoryDao = app.dao.businessDocumentCategoryDao;
    /*** GLOBALS ***/
    var pages   = app.pages;

    /*** CONSTANTS ***/
    var BUSINESS_DOCUMENT_NAME_COLUMN_INDEX_ADMIN = 2;
    var BUSINESS_DOCUMENT_NAME_COLUMN_INDEX_USER = 1;

    /*** Export Pages ***/
    pages.business_document.list = app.pagesByPath['/business-document/list'] = BusinessDocumentList;
    pages.business_document.listall = app.pagesByPath['/business-document/listall'] = BusinessDocumentListAll;

    /*** Set permission ***/
    BusinessDocumentListAll.permissions = [permissions.BUSINESS_DOCUMENT_MODIFY];
    BusinessDocumentList.permissions = [
        permissions.BUSINESS_DOCUMENT_VIEW_CUSTOMER,
        permissions.BUSINESS_DOCUMENT_VIEW_SUPERVISOR,
        permissions.BUSINESS_DOCUMENT_VIEW_EMPLOYEE
    ];

    var defaultSettings = {
        prefix: "business_document",
        labels: {
            pageHeader: "Dokumente"
        }
    };

    function BusinessDocumentList() {
        var self = this;
        self.filter = {};

        self.filterTable = function() {

            businessDocumentDao.listByUser(self.filter)
                .done(function(business_documents) {
                    self.renderTableUpdate(business_documents);
                });
        };

        app.widgets.ListPage.call(this, $.extend(true, {}, defaultSettings, {
            pageName: '/business-document/list',
            rpc: function() {
                return [
                    businessDocumentDao.listByUser({}),
                    undefined,
                    businessDocumentCategoryDao.findWithStats()
                ];
            },
            showLeftContainer: renderLeftContainer
        }));

        this.create = (function(create) {
            return function() {
                if (create && create.apply) {
                    create.apply(self, arguments);
                }
            };
        }(this.create));

        this.prepareTable = function($parent, dataBusinessDocuments) {
            var columns = getListTableColumns();
            var settings = getTableSettings();

            columns.push({
                sTitle: "Gelesen",
                sAttrName: "read_confirmed_at",
                bSortable: true,
                sWidth: "50px",
                mRender: function (mData, type, obj) {
                    return obj && obj.read_confirmed_at ? 'Ja' : 'Nein';
                }
            });

            app.widgets.notificationWidget.refresh();

            this.renderTable(dataBusinessDocuments, columns, settings);
        };

        this.savePageState = function() { };

        this.loadPageState = function($parent, dataBusinessDocuments, _, categories) {
            app.widgets.notificationWidget.refresh();

            this.renderTableUpdate(dataBusinessDocuments);
            updateCategories.apply(this, [categories])
        };

        this.showDetailsPage = function(business_document) {
            showDetailsPage(business_document, false);
        };
    }

    function BusinessDocumentListAll() {
        var self = this;
        self.filterTable = function() {

            businessDocumentDao.listAll(self.filter)
                .done(function(business_documents) {
                    self.renderTableUpdate(business_documents);
                });
        };
        // define default filter
        self.filter = {
            customer_id: null,
            location_id: null,
            object_id: null,
            workplace_id: null,
            active: null
        };

        app.widgets.ListPage.call(this, $.extend(true, {}, defaultSettings, {
            prefix: "business_document-all",
            pageName: '/business-document/listall',
            rpc: function() {
                return [
                    undefined,
                    businessDocumentDao.getCustomerList(),
                    businessDocumentCategoryDao.findWithStats()
                ];
            },
            labels : {
                buttonNew: "Neues Dokument",
                filterAll: "Alle Dokumente"
            },
            showLeftContainer: renderLeftContainer,
            active: {
                dao: businessDocumentDao,
                name: "Das Dokument"
            }
        }));

        this.prepareTable = function($parent, dataBusinessDocuments, customers, categories) {
            var columns = getListAllTableColumns();
            var settings = getTableSettings();

            columns.push({
                sTitle: "Gesichtet",
                sAttrName: "read_first_count",
                bSortable: true,
                sWidth: "50px"
            });

            columns.push({
                sTitle: "Gelesen",
                sAttrName: "read_confirmed_count",
                bSortable: true,
                sWidth: "50px"
            });

            this.renderTable(dataBusinessDocuments, columns, settings);

            if (customers) {
                $("#placeholder-filters").prependElement([
                    div({ id: 'tour-filter-form', 'class': 'whiteboard' }, [
                        legend({}),
                        ul({ "class":"inline-list title-row-highlight" }, [
                            form.formItemText({id: 'tour-filter-search', label: 'Suche', required: false,
                                style: "width: 250px",
                                name: "filter_expression",
                                keyup: function () {
                                    self.filter.filter_expression = $(this).val();
                                },
                                keypress: function (e) {
                                    var keyCode = e.which;
                                    if (keyCode === 13){
                                        self.filterTable();
                                        return false;
                                    }
                                },
                                change: function () {
                                    self.filterTable();
                                }
                            }),
                            function($parent) {
                                return clowFilter({
                                    customers: customers,
                                    $parent: $parent,
                                    change: $.proxy(self.onFilterChange, this)
                                })
                            },
                            createStateSelector($parent)
                        ])
                    ])
                ]);

                $("#placeholder-filters").afterElement(div({ "class": "whiteboard mobile-content" }, [
                    "Kategorie: ",
                    span({ "class": "selected-category" }, "Alle")
                ]));
            }
        };

        this.savePageState = function() { };

        this.loadPageState = function($parent, dataBusinessDocuments, customers, categories) {
            clowFilter({
                customers: customers,
                refresh: true,
                change: $.proxy(this.onFilterChange, this),
                attributeNameWorkplaces: 'assigned_workplaces'
            });
            updateCategories.apply(this, [categories])
        };

        this.showDetailsPage = function(business_document) {
            showDetailsPage(business_document, true);
        };

        this.onFilterChange = function(selection) {
            var oldFilter = _.extend({}, self.filter);

            self.filter.customer_id = selection.customer !== -1 ? selection.customer : null;
            self.filter.location_id = selection.location !== -1 ? selection.location : null;
            self.filter.object_id = selection.object !== -1 ? selection.object : null;
            self.filter.workplace_id = selection.workplace !== -1 ? selection.workplace : null;

            if (!_.isMatch(oldFilter, self.filter)) {
                self.filterTable();
            }
        };

        function createStateSelector($parent) {
            var stateTypes = [
                { id: 'ALL', name: 'Alle', value: undefined },
                { id: 'ACTIVATED', name: 'Aktiviert', value: true },
                { id: 'NOT_ACTIVATED', name: 'Nicht Aktiviert', value: false }
            ];

            return form.formItemSelect({id: 'filter-activated', label: 'Zustand', required: false,
                source: stateTypes, valueProp: 'name',
                value: stateTypes[0],
                sort: false,
                selected: function (e, stateType) {
                    if (self.filter.active !== stateType.value) {
                        self.filter.active = stateType.value;
                        self.filterTable();
                    }
                }
            });
        }
    }

    function renderLeftContainer(_a, _b, categories){
        var self = this;
        var isAdmin = app.session.hasPermission(app.model.PermissionTypes.BUSINESS_DOCUMENT_MODIFY);
        return div({"class": "whiteboard categories"}, [
            div({"class": "clearfix category-header"}, [
                h2({"class": ""}, "Kategorien"),
                isAdmin ? addIconButton({id: 'add-category',
                    click: function(e){
                        new EditDialog({
                            parent: $(e.target),
                            model: {name: ""},
                            click: function(c) {
                                businessDocumentCategoryDao.create(c).done(function(){
                                    updateCategories.apply(self, []);
                                });
                            }
                        }).open();
                    }
                }, 'Neu'): undefined,
                isAdmin ? editIconButton({id: 'edit-category',
                    click: function(e){
                        var cat = $(".categories .list-group-item.active").data("model");
                        if (cat && cat.id !== null) {
                            new EditDialog({
                                parent: $(e.target),
                                model: cat,
                                click: function(c) {
                                    businessDocumentCategoryDao.update(c).done(function(){
                                        updateCategories.apply(self, []);
                                    });
                                }
                            }).open();
                        }
                    }
                }, 'Bearbeiten'): undefined,
                isAdmin ? deleteIconButton({id: 'delete-category',
                    click: function(e){
                        var cat = $(".categories .list-group-item.active").data("model");
                        if (cat && cat.id !== null) {
                            new ConfirmationDialog({
                                parent: $(e.target),
                                title: "Kategorie löschen",
                                text: "Wollen Sie die Kategorie \""+ cat.name + "\" wirklich löschen?",
                                click: function() {
                                    businessDocumentCategoryDao.delete(cat.id).done(function(){
                                        updateCategories.apply(self, []);
                                    });
                                }
                            }).open();
                        }
                    }
                }, 'Löschen') : undefined
            ]),
            div({"class": "category-body"},
                ul({"class": "list-group"},
                    _.map([{id: null, name: "Alle", assigned_documents: _.reduce(categories, function(memo, c){
                        return memo + c.assigned_documents
                    }, 0)}].concat(categories), function(cat) {
                        var styleClass = (cat.id === null) ? "list-group-item active" : "list-group-item";
                        return createCategoryItem.apply(self, [cat, styleClass]);
                    })
                )
            )
        ])
    }

    function updateCategories(cats) {
        var self = this;
        var c = $(".categories .list-group-item.active").data("model") || {};

        function update(categories) {
            $(".category-body .list-group").children().detach();
            $(".category-body .list-group").appendElement(_.map([{id: null, name: "Alle", assigned_documents: _.reduce(categories, function(memo, c){
                return memo + c.assigned_documents
            }, 0)}].concat(categories), function(cat) {
                var styleClass = (cat.id === c.id) ? "list-group-item active" : "list-group-item";
                return createCategoryItem.apply(self, [cat, styleClass]);
            }));
            if (!$(".category-body .list-group .list-group-item.active").length) {
                $(".category-body .list-group .list-group-item a").first().click();
            }
        }

        if (cats) {
            update(cats);
        } else {
            businessDocumentCategoryDao.findWithStats().done(update);
        }


    }

    function createCategoryItem(cat, styleClass) {
        var self = this;
        return li({"class": styleClass, create: function(e){$(e.target).data("model", cat)}, click: function(e){
            $(e.currentTarget).parent().find(".active").removeClass("active");
            $(e.currentTarget).addClass("active");
            self.filter.category_id = cat.id;
            $(".selected-category").text(cat.name);
            self.filterTable();
        }}, [
            a({}, cat.name),
            span({"class": "category-document-count"}, ""+cat.assigned_documents)
        ])
    }

    function showDetailsPage(business_document, adminView) {
        nav.goToPage(pages.business_document.detail, [business_document ? business_document.id : null, adminView]);
    }

    function getListAllTableColumns() {
        return [
            {
                sTitle: "ID",
                sAttrName: "id"
            },
            {
                sTitle: "Erstellt am",
                sAttrName: "created_at",
                sWidth: "120px",
                mRender: function(mData, type, obj) {
                    if (type === "display") {
                        return obj.created_at.toFormatString(app.settings.dateTimeFormat);
                    } else {
                        return obj.created_at;
                    }
                }
            },
            {
                sTitle: "Name",
                sAttrName: "name"
            }
        ];
    }

    function getListTableColumns() {
        return [
            {
                sTitle: "Erstellt am",
                sAttrName: "created_at",
                sWidth: "120px",
                mRender: function(mData, type, obj) {
                    if (type === "display") {
                        return obj.created_at.toFormatString(app.settings.dateTimeFormat);
                    } else {
                        return obj.created_at;
                    }
                }
            },
            {
                sTitle: "Name",
                sAttrName: "name"
            }
        ];
    }

    function getTableSettings() {
        var isAdmin = app.session.hasPermission(app.model.PermissionTypes.BUSINESS_DOCUMENT_MODIFY);
        return {
            aaSorting: [[ isAdmin ? BUSINESS_DOCUMENT_NAME_COLUMN_INDEX_ADMIN : BUSINESS_DOCUMENT_NAME_COLUMN_INDEX_USER, "asc" ]]
        };
    }

})(window.rise, window.app);
