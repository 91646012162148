import React from 'react';
import $ from 'jquery';

import { formItemCheckbox, formItemSelect, formItemText } from 'app/legacy/widgets/form';

import { ReportSchedule } from 'app/report-schedule/ReportSchedule';
import { PageType, ReportTypeLabels } from 'app/report-schedule/ReportScheduleConstants';

import { RichTextEditor } from 'app/report-schedule/pages/detail-page/components/RichTextEditor';
import keycode from 'keycode';
import { Box } from 'app/BoxWorld';

type Props = {
    pageType: PageType;
    active: ReportSchedule['active'];
    mailText: ReportSchedule['mail_text'];
    recipients: ReportSchedule['recipients'];
    type: ReportSchedule['type'];
    reportAttachedToMail: ReportSchedule['report_attached_to_mail'];
    noEmptyReportMail: ReportSchedule['no_empty_report_mail'];
    onChange: (ReportSchedule) => void;
};

const compareArray = (a, b) => a.length === b.length && a.every((e, i) => e === b[i]);

export function ReportScheduleDetails(props: Props) {
    const { pageType, type, recipients, mailText, reportAttachedToMail, noEmptyReportMail, active, onChange } = props;

    const readonly = pageType === PageType.VIEW;
    const reportScheduleTypes = Object.entries(ReportTypeLabels).map(([k, v]) => ({
        id: k,
        name: v,
        value: k,
    }));

    const topElements = [
        formItemSelect({
            id: 'type-select',
            label: 'Typ',
            required: true,
            source: reportScheduleTypes,
            valueProp: 'name',
            value: reportScheduleTypes.filter((rt) => rt.value === type)[0],
            sort: false,
            selected: (e, reportType) => {
                if (reportType && reportType.value && type !== reportType.value) {
                    onChange({
                        type: reportType.value,
                    });
                }
            },
        }, readonly),
        formItemText({
            id: 'report-schedule-mail-recipients',
            required: true,
            label: 'E-Mail Empfänger',
            value: readonly ? recipients?.join(', ') : recipients?.join(';'),
            create: (e) => {
                if (e.type === 'destroy') {
                    $(e.target).tokenlist('destroy');
                    return;
                }

                $(e.target).tokenlist({
                    tokenDelimiter: [keycode('space'), keycode(',')],
                    split: /\s*;\s*/,
                    join: ';',
                    acceptCustomInput: true,
                    validate(labelValue) {
                        const val = labelValue.value;
                        if (val !== undefined) {
                            const pattern = /^([a-zA-Z0-9._\\+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})?$/;
                            return pattern.test(val);
                        }

                        return true;
                    },
                    changed(items) {
                        const changedRecipients = items;
                        if (Array.isArray(changedRecipients) && changedRecipients.length
                            && !compareArray(recipients ?? [], changedRecipients)) {
                            onChange({
                                recipients: changedRecipients,
                            });
                        }
                    },
                });
            },
        }, readonly),
    ];

    const bottomElements = [
        formItemCheckbox({
            id: 'report-attached-to-mail',
            label: 'Bericht als Mail-Attachment',
            checked: !!reportAttachedToMail,
            change: (e) => {
                const checked = !e.target.checked;
                if (reportAttachedToMail !== checked) {
                    onChange({
                        report_attached_to_mail: checked,
                    });
                }
            },
        }, readonly),
        formItemCheckbox({
            id: 'no-empty-report-mail',
            label: 'Leere Berichtsdokumente nicht versenden',
            checked: !!noEmptyReportMail,
            change: (e) => {
                const checked = !e.target.checked;
                if (noEmptyReportMail !== checked) {
                    onChange({
                        no_empty_report_mail: checked,
                    });
                }
            },
        }, readonly),
        formItemCheckbox({
            id: 'report-schedule-active',
            label: 'Aktiv',
            checked: !!active,
            change: (e) => {
                const checked = !e.target.checked;
                if (active !== checked) {
                    onChange({
                        active: checked,
                    });
                }
            },
        }, readonly),
    ];

    return (
        <fieldset id="report-schedule-edit">
            <ul className="fieldset-content">
                <Box>{topElements}</Box>
                <RichTextEditor
                    text={mailText}
                    onChange={onChange}
                    readonly={readonly}
                />
                <Box>{bottomElements}</Box>
            </ul>
        </fieldset>
    );
}
