import keycode from "keycode";

/**
 * Task detail page.
 *
 * @author Martin Reiterer (martin.reiterer@rise-world.com)
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav         = rise.Navigation;
    var pages       = app.pages;
    var form        = app.widgets.form;

    var editIconButton      = app.widgets.editIconButton;
    var cancelIconButton    = app.widgets.cancelIconButton;
    var saveIconButton      = app.widgets.saveIconButton;
    var commandBox          = app.widgets.commandBox;

    /** DAOs **/
    var EventType       = app.model.EventType;
    var eventTypeDao    = app.dao.eventTypeDao;
    var EventFieldType  = app.model.EventFieldType;
    var EventFieldTypeSelectOption = app.model.EventFieldTypeSelectOption;

    /** CONSTANTS **/
    var MAX_LENGTH_LABEL = 200;
    var MAX_LENGTH_SELECT_VALUE = 200;

    /** GLOBALS **/
    var PageType = {
        VIEW: 1,
        EDIT: 2,
        NEW: 3
    };

    var fieldTypes = [
        { value:'SINGLESELECT', label:"Auswahl (einfach)" },
        { value:'MULTISELECT', label:"Auswahl (mehrfach)" },
        { value:'CHECKBOX', label:"Checkbox" },
        { value:'DATE', label:"Datum" },
        { value:'DATETIME', label:"Datum und Uhrzeit" },
        { value:'MULTILINE', label:"Textfeld (mehrzeilig)" },
        { value:'RADIO', label:"Ja/Nein" },
        { value:'SIGNATURE', label:"Unterschrift"},
        { value:'SINGLELINE', label:"Textfeld (einzeilig)" },
        { value:'TIME', label:"Uhrzeit" }
    ];

    /*** Export page ***/
    pages.event_type.detail = app.pagesByPath['/event-type/detail'] = function EventTypeDetail() {
        var self = this;
        this.name = "/event-type/detail";

        this.type = PageType.VIEW;
        this.eventType = null;
        this.assignments = null;
        this.customers = null;
        this.finalized = false;

        this.goToPage = null;

        this.create = function ($parent, eventType, goToPage) {

            this.type = eventType ? PageType.VIEW : PageType.NEW;
            this.goToPage = app.pagesByPath[goToPage];

            var customerRpc = eventTypeDao.getCustomerList();

            if (eventType) {
                this.eventType = eventType;
                return $.when(
                    customerRpc,
                    eventTypeDao.get(eventType.id),
                    this.type === PageType.NEW ? undefined : eventTypeDao.getAssignments(eventType.id)
                ).done(function(customers, eventType, assignments) {
                    self.customers = customers;
                    self.eventType = eventType;
                    self.assignments = assignments;

                    renderEventType($parent, self.type);
                });
            } else {
                this.eventType = new EventType();
                return customerRpc.done(function(customers) {
                    self.customers = customers;

                    renderEventType($parent, self.type);
                });
            }
        };

        this.destroy = function() {
            // called after page has been destroyed
        };

        function reorderEventFieldTypeNumbers() {
            _.each(self.eventType.event_field_types, function(field, idx) {
                field.order_number = idx;
            });
        }

        function renderEventType($parent, type) {
            self.type = type;
            self.finalized = self.eventType.finalized;

            var _render = function() {

                $parent.appendElement([
                    renderCommandBox($parent),
                    renderEventTypeForm(self.type === PageType.VIEW, self.eventType),
                    renderEventTypeFieldsForm(self.type === PageType.VIEW, self.finalized, self.eventType),

                    self.type !== PageType.NEW
                        ? renderEventTypeAssignmentForm(self.finalized ? PageType.VIEW : self.type === PageType.VIEW)
                        : undefined
                ]);

                if(self.finalized ? false : self.type !== PageType.VIEW) {
                    $( "#event-type-fields-table-body" ).sortable({
                        scroll: false,
                        placeholder: "ui-state-highlight",
                        sort: function(event, ui) {
                            ui.helper.css({'top' : ui.position.top + $(window).scrollTop() + 'px'});
                        },
                        start: function(event, ui) {
                            var start_pos = ui.item.index();
                            ui.item.data('start_pos', start_pos);
                        },
                        update: function(event, ui) {
                            var eventTypeFields = self.eventType.event_field_types = _.sortBy(self.eventType.event_field_types, 'order_number');
                            var start_pos = ui.item.data('start_pos');
                            var end_pos = ui.item.index();

                            var elem = eventTypeFields.splice(start_pos, 1);
                            eventTypeFields.splice(end_pos, 0, elem[0]);

                            reorderEventFieldTypeNumbers();
                        }
                    });
                }

                $.validation.addValidators({
                    '.valid-event-type-email-not-empty': [
                        function(e) {
                            if(!$('#event-type-alarm').prop("checked")) {
                                return true;
                            } else {
                                return $(e.target).tokenlist("value") !== '';
                            }
                        },
                        'Email-Adresse erforderlich, wenn Alarmierung    aktiv',
                        'change'
                    ]
                });

                $parent.activateValidators();
            };

            _render();
        }

        function renderCommandBox($parent) {
            var savedEventType = new EventType(self.eventType);

            function saveButton() {
                return saveIconButton({id: 'savebusiness-document',
                    click: function () {
                        if ($parent.validate()) {
                            saveBusinessDocument($parent);
                        }
                    }
                });
            }

            function cancelButton(backToOverview) {
                return cancelIconButton({id: 'cancel-edit-event-type',
                    click: function () {
                        if (backToOverview) {
                            nav.goToPage(pages.event_type.list);
                        } else {
                            $.validation.reset();
                            $parent.empty();

                            self.eventType = savedEventType;
                            renderEventType($parent, PageType.VIEW);
                        }
                    }
                });
            }

            function editEventTypeButton() {
                return editIconButton({id: 'edit-event-type',
                    click: function () {
                        $parent.empty();
                        renderEventType($parent, PageType.EDIT);
                    }
                }, 'Ereignistyp bearbeiten'); //todo for consistency button label only "Bearbeiten"?
            }

            function getHeader () {
                /* jshint indent:false */
                switch (self.type) {
                    case PageType.NEW:  return 'Neuer Ereignistyp';
                    case PageType.EDIT: return 'Ereignistyp bearbeiten';
                    case PageType.VIEW: return 'Ereignistyp: ' + self.eventType.label;
                }
                return '';
            }

            function getCommandButtons() {
                /* jshint indent:false */
                switch (self.type) {
                    case PageType.VIEW:
                        return [
                            (app.session.hasPermission(app.model.PermissionTypes.EVENT_TYPE_MODIFY)) ?
                                editEventTypeButton() :
                                undefined
                        ];
                    case PageType.EDIT:
                        return [
                            saveButton(),
                            cancelButton()
                        ];

                    case PageType.NEW:
                        return [
                            saveButton(),
                            cancelButton(true)
                        ];
                }

                return [];
            }

            function backAction() {
                if(self.goToPage) {
                    return self.goToPage;
                } else {
                    return pages.event_type.list;
                }
            }

            return commandBox({backAction:backAction(), title: getHeader()},getCommandButtons());

        }

        function saveBusinessDocument($parent) {
            self.eventType.send_emails = $("#event-type-email").tokenlist("value") || null;

            if(self.eventType.id) {
                eventTypeDao.updateEventTypeWithAssignments(self.eventType, self.assignments, self.finalized)
                    .done(updateDoneMethod);
            } else {
                // set active flag
                self.eventType.active = true;

                eventTypeDao.insert(self.eventType).done(insertDoneMethod);
            }

            function insertDoneMethod(result) {
                self.eventType = result;
                $parent.empty();

                self.assignments = { customers:[], objects:[], locations:[], workplaces:[] };
                renderEventType ($parent, PageType.VIEW);
                app.showSuccessMessage('Gespeichert');
            }

            function updateDoneMethod(result) {
                self.assignments = result.assignments || self.assignments;
                self.eventType = new EventType(result.event_type);
                $parent.empty();

                renderEventType ($parent, PageType.VIEW);
                app.showSuccessMessage('Ereignistyp aktualisiert');
            }
        }

        function renderEventTypeForm(view, eventType) {
            return fieldset({id: 'event-type-edit', 'class': ''}, [
                ul({"class":"fieldset-content"}, [
                    form.formItemText({id: 'event-type-name', label: 'Ereignisname', required: true,
                        value: eventType.label,
                        change: function () {
                            eventType.label = $(this).val();
                        }
                    }, view),
                    form.formItemText({id: 'event-type-email', label: 'Email-Adresse(n)',
                        value: view ? eventType.send_emails && eventType.send_emails.split(';').join('; ') : eventType.send_emails,
                        validation: 'valid-event-type-email-not-empty',
                        create: function() {
                            $(this).tokenlist({
                                tokenDelimiter: [keycode('space'), keycode(',')],
                                split: /\s*;\s*/,
                                join: ';',
                                acceptCustomInput: true,
                                validate: function(labelValue) {
                                    var val = labelValue.value;
                                    if(val !== undefined) {
                                        var pattern = new RegExp(/^([a-zA-Z0-9._\\+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})?$/);
                                        return pattern.test(val);
                                    }

                                    return true;
                                }
                            });
                        }
                    }, view),
                    eventType.id
                        ? form.formItemCheckbox({id: 'event-type-finalized', label: 'Abgeschlossen', disabled: self.finalized,
                            checked: eventType.finalized,
                            change: function () {
                                eventType.finalized = $(this).prop("checked");
                            }
                          }, view)
                        : undefined,
                    form.formItemCheckbox({id: 'event-type-alarm', label: 'Alarmierung',
                        checked: eventType.alarm_email,
                        change: function () {
                            eventType.alarm_email = $(this).prop("checked");
                        }
                    }, view),
                    form.formItemCheckbox({id: 'event-type-active', label: 'Aktiv',
                        checked: eventType.active,
                        change: function () {
                            eventType.active = $(this).prop("checked");
                        }
                    }, view)
                ])
            ]);
        }

        function renderEventTypeFieldsForm(view, finalized, eventType) {
            var idxCounter = 0;

            var finalizedOrView = self.finalized ? PageType.VIEW : self.type === PageType.VIEW;

            function createRow(field, idx) {

                return tr([
                    td(span({ "class": finalizedOrView ? "" : "ui-icon ui-icon-arrowthick-2-n-s" })),
                    td(form.formItemSelect({ id: 'event-type-field-type-'+idx, label: "Feldtyp",
                        required: true,
                        source: fieldTypes,
                        value: _.findWhere(fieldTypes, {value: field.field_type}),
                        valueProp: "label",
                        selected: function(e, selection) {
                            field.field_type = selection.value;
                            $(this).parents("tr")
                                .find(".col-field-options")
                                .empty()
                                .appendElement(renderEventTypeFieldOptions(field));
                        }
                    }, finalizedOrView, true)),
                    td(form.formItemCheckbox({ id: 'event-type-required-'+idx, label: "Pflichtfeld",
                        checked: field.required,
                        change: function () {
                            field.required = $(this).prop("checked");
                        }
                    }, finalizedOrView, true)),
                    td(form.formItemRadio({ id: 'event-type-desc-field-'+idx, label: "Beschreibung",
                        checked: field.desc_field,
                        name: "description_group",
                        click: function() {
                            // iterate all radio buttons to set correct value
                            $("input[name='description_group']").change();
                        },
                        change: function () {
                            field.desc_field = $(this).prop("checked");
                        }
                    }, !view && finalized ? false : view, true)),
                    td(form.formItemText({ id: 'event-type-label-'+idx, label: "Bezeichnung",
                        maxlength: MAX_LENGTH_LABEL,
                        required: true,
                        value: field.label,
                        keyup: function () {
                            field.label = $(this).val();
                        }
                    }, finalizedOrView, true)),
                    td({ "class": "col-field-options" }, renderEventTypeFieldOptions(field)),
                    td(finalizedOrView ? undefined :
                        a({
                                title:"Ereignisfeld löschen",
                                "class":"button delete image-only",
                                "click": $.proxy(removeEventTypeField, self, field, idx) },
                            img({ src:"style/images/content/cross.png" })
                        )
                    )
                ]);

            }

            function renderEventTypeFieldOptions(field) {
                if (field.field_type === "MULTISELECT" || field.field_type === "SINGLESELECT") {
                    return renderEventTypeFieldOptionsForSelectField(field);
                } else {
                    return "(keine Optionen)"
                }
            }

            function addEventTypeField(e, $elem) {
                var field = new EventFieldType();
                self.eventType.event_field_types.push(field);
                reorderEventFieldTypeNumbers();

                $elem = $elem ? $elem : $("#event-type-fields-table-body");
                $elem.appendElement(createRow(field, idxCounter++));
                unHideDeleteButtons($elem);

                $elem.activateValidators();
            }

            function removeEventTypeField(field, idx, e) {
                if($(e.currentTarget).hasClass("disabled")) {
                    return;
                }

                var eventTypeFields = self.eventType.event_field_types;
                var fieldIdx = _.indexOf(eventTypeFields, field);

                if(fieldIdx >= 0) {
                    eventTypeFields.splice(fieldIdx, 1);
                    reorderEventFieldTypeNumbers();

                    var $tr = $(e.target).closest("tr");
                    var descriptionField = $tr.find("input[type='radio']").first().prop("checked");
                    var $parent = $tr.parent();

                    $tr.remove();

                    if(descriptionField) {
                        $parent.find("input[type='radio']").first().prop("checked", true).change();
                    }

                }

                unHideDeleteButtons();
            }

            function unHideDeleteButtons($parent) {
                var buttons = $parent ? $parent.find(".button.delete") : $(".button.delete");

                if(buttons.length === 1) {
                    buttons.addClass("disabled");
                } else {
                    buttons.removeClass("disabled");
                }
            }

            return fieldset({id: 'event-type-fields', 'class':'sort-table'}, [
                /* jshint indent:false */
                legend({}, "Ereignisfelder"),
                div({ "class":"whiteboard" }, [
                    div({ "class":"dataTables_wrapper" },
                        table({ "class":"display dataTable" }, [
                            thead([
                                tr([
                                    th(""),
                                    th({ "class":"required" }, "Feldtyp"),
                                    th("Pflichtfeld"),
                                    th({ "class":"required" }, "Beschreibungsfeld"),
                                    th({ "class":"required" }, "Bezeichnung"),
                                    th({ "class": "" }, "Optionen"),
                                    th("")
                                ])
                            ]),
                            tbody({ id:"event-type-fields-table-body", "class":"event-type-table-body",
                                    create: function(e) {
                                        if(eventType.event_field_types.length === 0) {
                                            var $this = $(this);

                                            addEventTypeField(e, $this);
                                            $this.find("input[type='radio']").first().prop("checked", true).change();
                                        }
                                        unHideDeleteButtons($(this));
                                    }
                                },
                                _.map(_.sortBy(eventType.event_field_types, 'order_number'), createRow)
                            )
                        ])
                    ),
                    finalizedOrView ? undefined :
                        a({ title:"Ereignisfeld hinzufügen", "class":"button add", "click": addEventTypeField },
                            img({ src:"style/images/content/plus.png" })
                        )
                ])
            ]);
        }

        function renderEventTypeAssignmentForm(view) {
            return fieldset({id: 'event-type-assignment-edit'}, [
                legend({}, "Gültigkeitsbereich festlegen"),
                ul({'class':'fieldset-content'}, [
                    form.formHierarchicalWorkplaceSelect({
                        id: "customer-select",
                        customers: self.customers,
                        assignments: self.assignments,
                        change: function(level, instances) {
                            self.assignments[{
                                0: 'customers',
                                1: 'locations',
                                2: 'objects',
                                3: 'workplaces'
                            }[level]] = instances;
                        }
                    }, view)
                ])
            ]);
        }

        function renderEventTypeFieldOptionsForSelectField(field) {
            var finalizedOrView = self.finalized ? PageType.VIEW : self.type === PageType.VIEW;

            function createOptionRow(option) {
                return div({ "class": "option-row" }, [
                    ul({ "class": "option-row-value" }, [
                        form.formItemText({
                            id:  _.uniqueId("option-row-"),
                            label: "Auswahlwert",
                            maxlength: MAX_LENGTH_SELECT_VALUE,
                            required: true,
                            value: option.value,
                            keyup: function () {
                                option.value = $(this).val();
                            }
                        }, finalizedOrView, true),
                        finalizedOrView ? undefined :
                            li(a({
                                    title:"Auswahlwert löschen",
                                    "class":"button delete image-only",
                                    "click": $.proxy(removeOptionRow, self, option) },
                                img({ src:"style/images/content/cross.png" })
                            ))
                    ])
                ]);
            }

            function addSelectOption($parent) {
                var selectOption = new EventFieldTypeSelectOption();
                field.options.selectOptions.push(selectOption);

                var $elem = $parent;
                $elem.appendElement(createOptionRow(selectOption));
                unHideDeleteButtons($elem);

                $parent.activateValidators();
            }

            function removeOptionRow(option, e) {
                if($(e.currentTarget).hasClass("disabled")) {
                    return;
                }

                var optionIndex = _.indexOf(field.options.selectOptions, option);
                var $parent = $(e.target).parents(".event-field-options");

                if(optionIndex >= 0) {
                    field.options.selectOptions.splice(optionIndex, 1);

                    var $optionRow = $(e.target).closest(".option-row");
                    $optionRow.remove();
                }

                unHideDeleteButtons($parent);
            }

            function unHideDeleteButtons($parent) {
                var buttons = $parent.find(".button.delete");

                if(buttons.length === 1) {
                    buttons.addClass("disabled");
                } else {
                    buttons.removeClass("disabled");
                }
            }

            return div({ "class": "event-field-options-wrapper" }, [
                h4("Auswahlwerte*"),
                div({
                    "class": "event-field-options",
                    create: function(e) {
                        if(field.options.selectOptions.length === 0) {
                            var $this = $(this);
                            addSelectOption($this);
                        }
                        unHideDeleteButtons($(this));
                    }
                }, _.map(field.options.selectOptions, createOptionRow)),
                finalizedOrView ? undefined :
                    a({
                        title:"Auswahlwert hinzufügen",
                        "class":"button add",
                        "click": function() {
                            addSelectOption($(this).parents("td").find(".event-field-options"));
                        }}, [
                            img({ src:"style/images/content/plus.png" }),
                            " Auswahlwert hinzufügen"
                        ]
                    )
            ]);

        }

    };

}) (window.rise, window.app);
