/**
 * Functions for rendering buttons.
 *
 * @module app.widgets
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
const widgets = (function(app) {
    "use strict";

    /*** IMPORTS ***/
    var extend = app.widgets.tools.extend;

    app.widgets.textButton = function(params, children) {
        var def = {
            'class': `button${params.disabled ? ' disabled' : ''}`
        };

        if (params.click) {
            params.click = managedButtonListener(params.click);
        }

        if (params.icon) {
            children = span({'class': 'button-content'}, [
                children
            ]);
            delete params.icon;
        }

        delete params.disabled;

        return a(extend(params, def), children);
    };

    app.widgets.iconButton = function(params, children) {
        var def = {
            'class': 'button'
        };

        if (params.click) {
            params.click = managedButtonListener(params.click);
        }

        children = span({'class': 'button-content'}, [
            span({'class': 'button-icon' }),
            children
        ]);

        return a(extend(params, def), children);
    };

    app.widgets.iconLinkButton = function(params, children) {
        if (!params.icon) {
            throw "'icon' needs to be defined in params!";
        }

        if (params.click) {
            params.click = managedButtonListener(params.click);
        }

        children = [
            span({'class': 'img ' + params.icon}),
            children
        ];
        delete params.icon;

        if (!params['class']) {
            params['class'] = '';
        }
        params['class'] += ' no-underline';

        return a(params, children);
    };

    app.widgets.csvButton = function(params, children) {
        return app.widgets.iconButton({
            id: "export-csv",
            "class": "button-export",
            click: function () {

                var rpc = new window.rise.Rpc('/csvgen');
                rpc.invoke(params.method, params.criteriaFn())
                    .done(function(result) {
                        var $form = $("body").appendElement(form({ method:"POST", action:"/csv" }, [
                            input({ id:"csv-method", type:"hidden", name:"method", value:"get_csv" }),
                            input({ id:"csv-x-session-id", type:"hidden", name:"X-Session-Id", value:window.app.session.getSessionId() }),
                            input({ id:"csv-csvid", type:"hidden", name:"csv_id", value:result.csv_id })
                        ]));

                        $form.submit();
                        $form.remove();
                    });
            }
        }, children || 'Diese Ansicht exportieren');
    };

    app.widgets.excelButton = function(params, children) {
        return app.widgets.iconButton({
            id: "export-excel",
            "class": "button-excel",
            click: function () {

                var rpc = new window.rise.Rpc('/excelgen');
                rpc.invoke(params.method, params.criteriaFn())
                    .done(function(result) {
                        var $form = $("body").appendElement(form({ method:"POST", action:"/excel" }, [
                            input({ id:"export-method", type:"hidden", name:"method", value:"get_excel" }),
                            input({ id:"export-x-session-id", type:"hidden", name:"X-Session-Id", value:window.app.session.getSessionId() }),
                            input({ id:"export-id", type:"hidden", name:"export_id", value:result.export_id })
                        ]));

                        $form.submit();
                        $form.remove();
                    });
            }
        }, children || 'Diese Ansicht exportieren');
    };

// button wrappers ----------------------------------------

    app.widgets.addIconButton = function(params,children) {
        var options = extend({
            'class': 'button-add'
        }, params);

        return app.widgets.iconButton(options,children);
    };

    app.widgets.deleteIconButton = function(params, children) {
        var options = extend({
            'class': 'button-delete'
        }, params);

        return app.widgets.iconButton(options,children);
    };

    app.widgets.editIconButton = function(params, children) {
        var options = extend({
            'class': 'button-edit'
        }, params);

        var _children = children !== undefined ? children : "Bearbeiten";

        return app.widgets.iconButton(options, _children);
    };

    app.widgets.cancelIconButton = function(params, children) {
        var options = extend({
            'class': 'button-cancel'
        }, params);

        var _children = children !== undefined ? children : "Abbrechen";

        return app.widgets.iconButton(options,_children);
    };

    app.widgets.saveIconButton = function(params, children) {
        var options = extend({
            'class': 'button-save'
        }, params);

        var _children = children !== undefined ? children : "Speichern";

        return app.widgets.iconButton(options,_children);
    };

    app.widgets.printIconButton = function(params,children) {
        var options = extend({
            'class': 'button-print'
        }, params);

        return app.widgets.iconButton(options,children);
    };

    app.widgets.playIconButton = function(params,children) {
        var options = extend({
            'class': 'button-play'
        }, params);

        return app.widgets.iconButton(options,children);
    };

    app.widgets.pauseIconButton = function(params,children) {
        var options = extend({
            'class': 'button-pause'
        }, params);

        return app.widgets.iconButton(options,children);
    };

    app.widgets.stopIconButton = function(params,children) {
        var options = extend({
            'class': 'button-stop'
        }, params);

        return app.widgets.iconButton(options,children);
    };

    app.widgets.fastForwardIconButton = function(params,children) {
        var options = extend({
            'class': 'button-ff'
        }, params);

        return app.widgets.iconButton(options,children);
    };

    app.widgets.rewindIconButton = function(params,children) {
        var options = extend({
            'class': 'button-rw'
        }, params);

        return app.widgets.iconButton(options,children);
    };

    app.widgets.refreshIconButton = function(params,children) {
        var options = extend({
            'class': 'button-refresh'
        }, params);

        return app.widgets.iconButton(options,children);
    };

    app.widgets.intervalPreviousIconButton = function(params) {
        var options = extend({
            'title': 'Zeitspanne um einen Tag zurückverschrieben',
            'class': 'datepicker-intervalscroller-previous'
        }, params);

        return a(options);
    };

    app.widgets.intervalNextIconButton = function(params) {
        var options = extend({
            'title': 'Zeitspanne um einen Tag vorverschrieben',
            'class': 'datepicker-intervalscroller-next'
        }, params);

        return a(options);
    };


    function managedButtonListener(listener) {
        return function (e) {
            var $target = $(e.target),
                listenerResult,
                $button = $target.hasClass("button") ? $target : $target.parents(".button").first();

            function setActiveState(active) {
                if ($.contains(document.documentElement, $button[0])) {
                    if (active) {
                        $button.removeClass("disabled");
                    } else {
                        $button.addClass("disabled");
                    }
                }
            }

            try {
                if ($button.hasClass("disabled")) {
                    return;
                } else {
                    setActiveState(false);
                }

                listenerResult = listener(e);

                // check if the listener result is a deferred object
                if (listenerResult && _.isFunction(listenerResult.promise)) {
                    listenerResult.always($.proxy(setActiveState, this, true));
                } else {
                    setActiveState(true);
                    return listenerResult;
                }

            } catch(exception) {
                setActiveState(true);
                throw exception;
            }
        };
    }

    return app.widgets;

})(window.app);

export const textButton = widgets.textButton;
export const iconButton = widgets.iconButton;
export const iconLinkButton = widgets.iconLinkButton;
export const csvButton = widgets.csvButton;
export const excelButton = widgets.excelButton;
export const addIconButton = widgets.addIconButton;
export const deleteIconButton = widgets.deleteIconButton;
export const editIconButton = widgets.editIconButton;
export const cancelIconButton = widgets.cancelIconButton;
export const saveIconButton = widgets.saveIconButton;
export const printIconButton = widgets.printIconButton;
export const playIconButton = widgets.playIconButton;
export const pauseIconButton = widgets.pauseIconButton;
export const stopIconButton = widgets.stopIconButton;
export const fastForwardIconButton = widgets.fastForwardIconButton;
export const rewindIconButton = widgets.rewindIconButton;
export const refreshIconButton = widgets.refreshIconButton;
export const intervalPreviousIconButton = widgets.intervalPreviousIconButton;
export const intervalNextIconButton = widgets.intervalNextIconButton;
