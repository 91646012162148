/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.TaskLogbookEntry = function TaskLogbookEntry(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        // set entry type
        obj.entry_type  = app.model.LogbookEntryType.TASK;

        this.task_id    = obj.task_id != null ? parseInt(obj.task_id, 10) : null;
        this.object_id  = obj.object_id != null ? parseInt(obj.object_id, 10) : null;
        this.comment    = obj.comment != null ? obj.comment : null;

        this.done = obj.done != null ? obj.done === true : null;

        // if model was invoked directly, add also add the LogbookEntry fields
        app.model.LogbookEntry.apply(this, [obj]);
    };

}(window.app));
