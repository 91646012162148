/**
 * Page for starting a shift
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var log         = rise.logging.getLogger('pages.settings.password');
    var nav         = rise.Navigation;

    var textButton  = app.widgets.textButton;
    var commandBox = app.widgets.commandBox;

    /** DAOs **/
    var userDao = app.dao.userDao;

    /*** GLOBALS ***/
    var pages   = app.pages;
    var form    = app.widgets.form;

    /*** Export page ***/
    pages.settings.password = app.pagesByPath['/settings/password'] = function SettingsPassword() {
        var obj = this;
        this.name = "/settings/password";

        this.pwds = {
            "old": '',
            "new": '',
            "new_conf": ''
        };

        this.create = function ($parent) {
            renderPasswordChange($parent);
        };

        this.destroy = function() {
            // called after page has been destroyed
        };

        function renderPasswordChange($parent) {

            function changePassword(pwds) {
                // remove previous error message
                app.removeErrorMessages();

                if (pwds['new'] !== pwds.newConf) {
                    $('#password-change-new').addClass('error');
                    $('#password-change-new-confirm').addClass('error');
                    app.showErrorMessage("Passwörter stimmen nicht überein!");
                    return;
                }

                userDao.changePassword($.sha256(pwds.old), $.sha256(pwds['new']))
                    .done(function() {
                        app.showSuccessMessage("Passwort wurde erfolgreich geändert!");
                        renderPasswordChange($parent.empty());

                        // adapt reset password flag
                        var session = app.session.getSessionInfo();
                        if (session.password_reset_required) {
                            session.password_reset_required = false;
                            app.session.saveSessionInfo(session);
                            window.location.reload();
                        }

                    });
            }

            $parent.appendElement([
                commandBox({title:'Passwort ändern'}),
                renderCalloutResetPasswordRequired(),
                renderPasswordChangeForm(obj.pwds),
                textButton({id: 'password-change-button', click: function() {
                    if ($parent.validate()) {
                        changePassword(obj.pwds);
                    }
                }}, 'Passwort ändern')
            ]);

            $parent.activateValidators();
        }
    };

    function renderCalloutResetPasswordRequired() {
        var session = app.session.getSessionInfo();

        if (!session.password_reset_required) {
            return;
        }

        return div({ "class": "callout warn" }, [
            h2("Bitte ändern Sie Ihr Passwort"),
            p("Sie verwenden derzeit ein vom System vergebenes Passwort. Um auf die Funktionen des elektronischen " +
                "Wachbuchs zugreifen zu können, müssen Sie zuerst ein neues Passwort vergeben. Verwenden Sie hierfür " +
                "bitte das unten angeführte Formular.")
        ]);
    }

    function renderPasswordChangeForm(pwds) {
        return fieldset({id: 'password-change'}, [
            legend(),
            ul({'class': 'fieldset-content'}, [
                form.formItemText({id: 'password-change1-old', label: 'Altes Passwort', required: true,
                    validation: 'valid-no-spaces',
                    type: 'password',
                    change: function() {
                        pwds.old = $(this).val();
                    }
                }),
                form.formItemText({id: 'password-change-new', label: 'Neues Passwort', required: true,
                    validation: 'valid-password valid-no-spaces valid-password-length', type: 'password',
                    change: function() {
                        pwds['new'] = $(this).val();
                    }
                }),
                form.formItemText({id: 'password-change-new-confirm', label: 'Passwort bestätigen', required: true,
                    validation: 'valid-password valid-no-spaces valid-password-length', type: 'password',
                    change: function() {
                        pwds.newConf = $(this).val();
                    }
                })
            ])
        ]);
    }

})(window.rise, window.app);
