/**
 * Data Access Object for the EventType model.
 * Extends the generic rise.Dao function.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.eventType");

    /*** Imports ***/
    var EventType        = app.model.EventType;

    /*** Export Dao ***/
    app.dao.eventTypeDao = new EventTypeDao();

    /**
     * @constructor
     */
    function EventTypeDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.EventType, new Rpc('/rpc/event-type'));

        // optional: set additional variables, e.g.:
        this.primary_key_column = 'id';
        this.find_all_method = 'find_all';
    }

    // extend the parent Dao prototype
    $.extend(EventTypeDao.prototype, Object.create(rise.Dao.prototype));

    EventTypeDao.prototype.listAll = function(params) {
        log.info('List all EventTypes');
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke('list_all', params)
            .done(function(data) {
                if (data.length === 0) {
                    log.info('No EventType data sets found');
                    deferred.resolve([]);
                } else {
                    log.info('Received %s EventType(s)', data.length);
                    deferred.resolve(data.map(function(d) {
                        return new EventType(d);
                    }));
                }
            })
            .fail(function(error) {
                log.error('Fetching all EventTypes failed. %s', error.message);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    EventTypeDao.prototype.setActiveState = function(id, active) {
        var funcName = active ? "activate" : "deactivate";
        var deferred = new $.Deferred();

        log.info('Set active state');

        this.rpc.invoke(funcName, {'id': id})
            .done(function(eventType) {
                log.info('event_type with id ' + id + " has been " + funcName + "d");
                deferred.resolve(eventType);
            })
            .fail(function (error) {
                log.error(funcName + ' failed. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

    EventTypeDao.prototype.findEventTypes = function(customerId, objectId, workplaceId,
                                                     locationId) {
        var deferred = new $.Deferred();

        log.info(sprintf('Search event types that are visible to a particular workplace(id=%s) and object(id=%s)',
            workplaceId, objectId));

        this.rpc.invoke('find_event_types', {
                workplace_id: workplaceId,
                object_id: objectId,
                customer_id: customerId,
                location_id: locationId
            })
            .done(function(eventTypes) {
                deferred.resolve(_.map(eventTypes, function(json) {
                    return new EventType(json);
                }));
            })
            .fail(function (error) {
                log.error('List of event types cannot be retrieved. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

    EventTypeDao.prototype.getAssignments = function(id) {
        return this.rpc.invoke("get_assignments", { event_type_id:id} )
    };

    EventTypeDao.prototype.updateEventTypeWithAssignments = function(eventType, assignments, finalized) {
        var deferred = new $.Deferred();

        var data = {
            event_type_assignments: {
                event_type: $.extend(true, {}, eventType),
                assignments: {
                    'customers':  assignments.customers,
                    'locations':  assignments.locations,
                    'objects':    assignments.objects,
                    'workplaces': assignments.workplaces
                }
            }
        };

        if(finalized) {
            delete data.event_type_assignments.assignments;
        }

        log.info('Update assignments for event_type with id ' + eventType.id + " as read");

        this.rpc.invoke('update_event_type_with_assignments', data)
            .done(function(a) {
                deferred.resolve(a);
            })
            .fail(function (error) {
                log.error('Error during updating event_type assignments. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

    EventTypeDao.prototype.getCustomerList = function () {
        var deferred = new $.Deferred();

        this.rpc.invoke('get_customer_list')
            .done(function(rows) {
                deferred.resolve(_.map(rows, function(e) {return new app.model.Customer(e);}));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

})(window.rise, window.app);
