/**
 * Ticket list (overview) page.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var log         = rise.logging.getLogger('pages.ticket.list');
    var nav         = rise.Navigation;

    var initColumns = app.widgets.initColumns;
    var textButton  = app.widgets.textButton;
    var iconLinkButton  = app.widgets.iconLinkButton;

    /** DAOs **/
    var TicketDao      = app.dao.ticket;

    /*** GLOBALS ***/
    var pages         = app.pages;
    var tableSelector = "#ticket-table";

    /*** Export Page ***/
    pages.ticket.list = app.pagesByPath['/ticket/list'] = function TicketList() {
        this.name = "/ticket/list";

        this.create = function (parent) {
            $.when(
                TicketDao.findAll()
            ).done(function (data) {
                renderContent(parent, data);
            });
        };

        this.destroy = function() {
            // called after page has been destroyed
        };
    };

    /**
     * Opens the member detail page.
     * @param {Number} id The member id.
     */
    function openTicketDetailsPage(id) {
        // remove row highlighting
        $(".highlight-row").removeClass("highlight-row");

        nav.goToPage(pages.ticket.detail, [id]);
    }

    function renderContent(parent, data) {

        var columns = initColumns([
            {
                sTitle: "Id",
                sAttrName: "id"
            },
            {
                sTitle: "Title",
                sAttrName: "title"
            },
            {
                sTitle: "Description",
                sAttrName: "description"
            },
            {
                sAttrName: "detail",
                bSortable: false,
                bSearchable: false,
                mData: null,
                mRender: function (mData, type, obj) {
                    var data = obj.aData;
                    return $.box.html(
                        iconLinkButton({ title: 'Ticket Details', icon: 'img-arrow-grey-right' })
                    );
                },
                fnCreatedCell: function(nTd, sData, oData, iRow, iCol) {
                    var $nTd = $(nTd);
                    $nTd.find('a').click(function() {
                        openTicketDetailsPage(oData.id);
                    });
                }
            }
        ]);

        // render basic content
        parent.appendElement([
            h1({}, "List Tickets"),
            div({'class': 'command-box top'},
                ul({}, [
                    li(textButton({id: 'new-ticket', icon: 'img-plus',
                        click: function () {
                            nav.goToPage(pages.ticket.detail);
                        }
                    }, 'Add New Ticket'))
                ])
            ),
            div({ id:"ticket-whiteboard", "class": "whiteboard" }, [
                table({ id:"ticket-table" })
            ])
        ]);

        // initialize datatable
        var dataTable = $(tableSelector).dataTable({

            /* DATATABLE GENERAL OPTIONS */

            /* specify where to inject controls in DOM elements */
            "sDom": 'rtCp<"clearfix">i',

            /* columns of the datatable */
            "aoColumns": columns.aoColumns,

            "aaData": data,

            /* number of rows to display on a single page */
            "iDisplayLength": 25,

            "sPaginationType": "full_numbers",

            "fnDrawCallback": function(oSettings) {
                $(oSettings.nTable).find("td").mouseenter(function() {
                    $(this).parent()
                        .children()
                        .each(function() {
                            $(this).toggleClass('highlight-row');
                        });
                }).mouseleave(function() {
                    $(this).parent()
                        .children()
                        .each(function() {
                            $(this).toggleClass('highlight-row');
                        });
                }).not('.detail').click(function(e) {
                    var oTable = oSettings.oInstance;
                    var aPos = oTable.fnGetPosition(this);

                    // get the data array for this row
                    var oData = oTable.fnGetData(aPos[0]);

                    // open member details page
                    openTicketDetailsPage(oData.id);
                });
            }
        });
    }

})(window.rise, window.app);
