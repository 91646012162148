/**
 * Main entry point of the application. Renders bodies of the different page types. In the course of this all
 * needed runtime configurations like menu, navigation is set up.
 *
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
export function initLegacyApplication (rise, app) {
    "use strict";

    /** EXPORTS **/
    app.body.renderErrorBody = renderErrorBody;
    app.body.renderLoginBody = renderLoginBody;
    app.body.renderFullBody  = renderMainBody;

    /**
     * Set initial page.
     */
    $(document).ready(function() {
        var error;

        // setup automatic error reporting
        rise.Rpc.setConfig({
            beforeCallback: app.rpc_handler.before,
            failCallback: app.rpc_handler.fail,
            alwaysCallback: app.rpc_handler.always
        });

        app.session.setConfig({
            loginCallback: renderMainBody,
            logoutCallback: renderLoginBody
        });

        if (error = app.helper.detectBrowserFeatures()) {
            renderErrorBody(error);

        } else if (!app.session.hasActiveSession()) {
            renderLoginBody();

        } else {
            renderMainBody();
        }
    });

    /**
     * Renders the error page with the specified error message.
     * @param {*} error BoxWorld error element.
     */
    function renderErrorBody(error) {
        var $body = $("body");
        var $container = $("#container");

        // render page structure and error message
        $body.fadeOut("fast", function() {
            $body.addClass("single-page");

            $container
                .children()
                .not('.global-messages')
                .remove();
            $container
                .prependElement(app.widgets.page.errorPage);

            $body.find("#error-text")
                .appendElement(error);

            $body.fadeIn("fast");
        });
    }

    /**
     * Renders the login page.
     */
    function renderLoginBody() {
        var $body = $("body");
        var $container = $("#container");

        // render login page
        $body.fadeOut("fast", function() {
            $body.addClass("single-page");

            $container
                .children()
                .not('.global-messages')
                .remove();
            $container
                .prependElement(app.widgets.page.loginPage);

            function login() {
                app.session.login($("#username").val(), $("#password").val());
            }

            $body.find("#login-button")
                .click(function() {
                    login();
                });

            $container.find('#content').on('keydown', 'input', function(e) {
                if (e.keyCode === 13) {
                    login();
                }
            });

            $body.fadeIn("fast", function() {
                $('#username').focus();
            });
        });
    }

    /**
     * Renders the main (content) page.
     */
    function renderMainBody() {
        var $body = $("body");
        var $container = $("#container");

        // render page structure
        $body.fadeOut("fast", function() {
            $body.removeClass("single-page");

            $container
                .children()
                .not('.global-messages')
                .remove();
            $container
                .prependElement(app.widgets.page.mainPage);

            // set session-id header in Rpc config
            app.session.setRpcHeader();

            // set navigation container element
            rise.Navigation.initialize( $('#content-inner') );

            // add navigation callback
            rise.Navigation.addPageChangeCallback(function() {
                app.removeErrorMessages();
                app.removeValidationErrors();
                app.removeWarningMessages();
            });

            // render navigation
            $container.find("#nav-primary")
                .appendElement(app.menu.main());

            // show "Schicht" or "Dokumente" page
            var startPage;

            if (_has_page_permission(app.pages.shift.check)) {
                startPage = app.pages.shift.check;
            } else if (_has_page_permission(app.pages.business_document.list)) {
                startPage = app.pages.business_document.list;
            } else if (_has_page_permission(app.pages.business_document.listall)) {
                startPage = app.pages.business_document.listall;
            } else if (_has_page_permission(app.pages.logbook.entries.list)) {
                startPage = app.pages.logbook.entries.list;
            } else {
                startPage = app.pages.settings.password;
            }

            // render initial page
            if (window.location.hash && window.location.hash !== '#') {
                const parsedHash = rise.Navigation.parseHash(window.location.hash);
                if (parsedHash.page && !app.pagesByPath[parsedHash.page]) {
                    rise.Navigation.goToPage(startPage, [parsedHash.page]);
                } else {
                    rise.Navigation.onHashChange();
                }
            } else {
                rise.Navigation.goToPage(startPage);
            }

            $body.fadeIn('fast');
        });
    }

    /* global error handler */
    window.onerror = function(msg, url, line) {
        // remove progress bar
        $('div.notification').remove();

        var suppressErrorAlert = false;
        return suppressErrorAlert;
    };

    function _has_page_permission(page) {
        return _.any(page.permissions,
            function(permission) {
                return window.app.session.hasPermission(permission);
            })
    }

}
