(function(app) {
    "use strict";

    /**
     * TODO: The DMSInterval and the TourTimeCConstraint widgets can probably
     * be abstracted together...
     */

    /*** EXPORTS ***/
    app.widgets.DMSIntervalForm = DMSIntervalForm;
    app.widgets.DMSIntervalListForm = DMSIntervalListForm;

    /*** IMPORTS ***/
    var form = app.widgets.form;
    var DMSInterval = app.model.DMSInterval;

    /*** CONSTANTS ***/
    var weekDays = [
        {'id' : 1, 'label': 'Montag'},
        {'id' : 2, 'label': 'Dienstag'},
        {'id' : 3, 'label': 'Mittwoch'},
        {'id' : 4, 'label': 'Donnerstag'},
        {'id' : 5, 'label': 'Freitag'},
        {'id' : 6, 'label': 'Samstag'},
        {'id' : 0, 'label': 'Sonntag'}
    ];


    /*************************************************************************/

    function DMSIntervalForm(dmsi) {
        this.dmsi = new DMSInterval(dmsi);
    }

    DMSIntervalForm.prototype.getDMSInterval = function () {
        return new DMSInterval(this.dmsi);
    };

    DMSIntervalForm.prototype.setRemoveActionCallback = function (fn) {
        this.removeActionCallback = fn;
    };

    DMSIntervalForm.prototype.render = function($parent, view) {
        var self = this;

        $parent.appendElement(div({
            create: function() {
                self.$wrapperDiv = $(this);
                self.$wrapperDiv.empty();
                self.$wrapperDiv.appendElement(self.renderWidget(view));
            }
        }));
    };

    DMSIntervalForm.prototype.renderWidget = function(view) {
        var self = this;

        return fieldset({'class': ''}, [
            view ? undefined :
                a({title:"Löschen", "class":"button delete image-only right",
                    'click': function() {
                        self.removeActionCallback(self);
                    }
                }, img({ src:"style/images/content/cross.png" })),
            ul({"class":"fieldset-content"}, self.renderBody(view))
        ]);
    };

    DMSIntervalForm.prototype.renderBody = function(view) {
        var self = this,
            now = new JustTime();

        self.dmsi.time_from = self.dmsi.time_from ? self.dmsi.time_from : now;
        self.dmsi.time_to = self.dmsi.time_to ? self.dmsi.time_to :
            new JustTime(now.getHours() + 1, now.getMinutes());
        self.dmsi.weekdays = self.dmsi.weekdays ? self.dmsi.weekdays : "";
        self.dmsi.period = self.dmsi.period ? self.dmsi.period : 60;

        return [
            form.formItemCheckboxList({id: 'dmsi-weekly-day', label: 'Tag', required: true,
                options: _.map(weekDays, function(day) { return day.label }),
                value: function() {
                    return _.map(self.dmsi.weekdays, function(weekdayId) {
                        var found = $.grep(weekDays, function(day) {
                            return day.id == weekdayId;
                        });

                        return found.length > 0 ? found[0].label : undefined;
                    });
                }(),
                listChanged: function(selectedDays) {
                    var selectedDays = _.map(selectedDays, function(selectedDay) {
                        var dayId = $.grep(weekDays, function(day) {
                            return day.label == selectedDay;
                        })[0].id;
                        return dayId;
                    });
                    self.dmsi.weekdays = selectedDays;
                }
            }, view),
            form.formItemTime({id: "dmsi-time-from", label: 'Von', required: true,
                time: self.dmsi.time_from,
                change: function() {
                    if ($(this).val()) {
                        var newTimeFrom = new JustTime($(this).datepicker('getDate'));
                        if (self.dmsi.time_to && self.dmsi.period &&
                            calcIntervalDiff(newTimeFrom, self.dmsi.time_to) < self.dmsi.period) {
                            app.showErrorMessage("Der Zeitbereich ist kleiner als das Intervall");
                            $(this).val(window.JustTime.formatTimeString(self.dmsi.time_from));
                            return;
                        }

                        self.dmsi.time_from = newTimeFrom;
                    }
                }
            }, view),
            form.formItemTime({id: "dmsi-time-to", label: 'Bis', required: true,
                time: self.dmsi.time_to,
                change: function() {
                    if ($(this).val()) {
                        var newTimeTo = new JustTime($(this).datepicker('getDate'));
                        if (self.dmsi.time_from && self.dmsi.period &&
                            calcIntervalDiff(self.dmsi.time_from, newTimeTo) < self.dmsi.period) {
                            app.showErrorMessage("Der Zeitbereich ist kleiner als das Intervall");
                            $(this).val(window.JustTime.formatTimeString(self.dmsi.time_to));
                            return;
                        }

                        self.dmsi.time_to = newTimeTo;
                    }
                }
            }, view),
            form.formItemText({id: "dmsi-period", label: 'Intervall (min)', required: true,
                style: "width:100px",
                value: self.dmsi.period,
                change: function () {
                    var newPeriod = parseInt($(this).val());
                    if (newPeriod < 30) {
                        app.showErrorMessage("Das Intervall muss mindestens 30 Minuten lang sein");
                        $(this).val(self.dmsi.period.toString());
                        return;
                    }

                    if (self.dmsi.time_from && self.dmsi.time_to && self.dmsi.period &&
                        calcIntervalDiff(self.dmsi.time_from, self.dmsi.time_to) < newPeriod) {
                        app.showErrorMessage("Der Zeitbereich ist kleiner als das Intervall");
                        $(this).val(self.dmsi.period.toString());
                        return;
                    }

                    self.dmsi.period = newPeriod;
                }
            }, view)
        ];
    };

    DMSIntervalForm.prototype.validate = function() {
        var self = this;

        if (self.dmsi.weekdays.length === 0) {
            app.showErrorMessage("Mindestens ein Wochentag muss ausgewählt werden");
            return false;
        }

        return true;
    };

    function calcIntervalDiff(fromTime, toTime) {
        var from = fromTime.getHours() * 60 + fromTime.getMinutes(),
            to = toTime.getHours() * 60 + toTime.getMinutes();

        if (from > to) {
            to = to + (24 * 60);
        }

        return to - from;
    }

    /*************************************************************************/

    function DMSIntervalListForm(dmsis) {
        this.dmsiForms = _.map(dmsis, function(dmsi) {
            return new DMSIntervalForm(dmsi);
        });
    }

    DMSIntervalListForm.prototype.render = function($parent, view) {
        var self = this;

        if (view && self.dmsiForms.length == 0) {
            return;
        }

        var addDMSIntervalForm = function() {
            self.dmsiForms.push(new DMSIntervalForm(new DMSInterval()));

            $("#dms-intervals-div").remove();
            self.render($parent, view);
        };

        var removeDMSIntervalForm = function(dmsiForm) {
            self.dmsiForms = _.without(self.dmsiForms, dmsiForm);

            $("#dms-intervals-div").remove();
            self.render($parent, view);
        };

        $parent.appendElement(div({id: 'dms-intervals-div'}, [
            h2({}, "Totmannschaltung Zeitintervalle"),
            div({id: 'dms-intervals-list',
                create: function(e) {
                    var $dms_intervals_list = $(this);

                    _.each(self.dmsiForms, function(df) {
                        df.setRemoveActionCallback(removeDMSIntervalForm);
                    });

                    _.each(self.dmsiForms, function(df) {
                        df.render($dms_intervals_list, view);
                    });
                }
            }),
            view ? undefined :
                a({title: "Neues Interval", "class": "button add", "click": addDMSIntervalForm },
                    img({ src: "style/images/content/plus.png" })
                )
        ]));
    };

    DMSIntervalListForm.prototype.getDMSIntervals = function() {
        var self = this;

        return _.map(self.dmsiForms, function(df) {
            return df.getDMSInterval();
        });
    };

    DMSIntervalListForm.prototype.validate = function() {
        var self = this,
            valid = true;

        _.each(self.dmsiForms, function(df) {
            if (!df.validate()) {
                valid = false;
            }
        });

        return valid;
    };

})(window.app);
