/**
 * Tour-run detail page.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var pages               = app.pages;
    var form                = app.widgets.form;
    var Map                 = app.widgets.Map;
    var editIconButton      = app.widgets.editIconButton;
    var cancelIconButton    = app.widgets.cancelIconButton;
    var saveIconButton      = app.widgets.saveIconButton;
    var refreshIconButton   = app.widgets.refreshIconButton;
    var commandBox          = app.widgets.commandBox;
    var scrollTableSection  = app.widgets.scrollTableSection;

    var checkpointType      = app.dto.TourCheckpointType;

    /* vienna default geo-location */

    /** DAOs **/
    var tourRunDao = app.dao.tourRunDao;

    /** CONSTANTS **/

    /** GLOBALS **/

    /*** Export page ***/
    pages.tour_run.detail = app.pagesByPath['/tour-run/detail'] = TourRunDetail;

    function TourRunDetail() {
        var self = this;
        this.name = "/tour-run/detail";
        this.goToPage = null;

        /* instance variables */
        self.animationStarted = false;

        this.create = function ($parent, tourRun, goToPage, goToPageArgs) {
            this.goToPage = app.pagesByPath[goToPage];
            self.goToPageArgs = goToPageArgs;

            return $.when(
                tourRunDao.getTourRunDetails(tourRun.id)
            ).done(function(tourRunDetails) {
                self = $.extend(self, tourRunDetails);

                self.checkpoints = _.sortBy(self.checkpoints, function(checkpoint) {
                    return checkpoint.check_time === null ? 0 : checkpoint.check_time;
                });

                self.invalidCheckpoints = _.sortBy(self.invalidCheckpoints, function(checkpoint) {
                    return checkpoint.check_time === null ? 0 : checkpoint.check_time;
                });

                renderTourRun($parent);
            });
        };

        this.destroy = function() {
            // called after page has been destroyed
        };

        function renderThumbnails($parent) {
            if (self.thumbnails && self.thumbnails.length > 0) {
                $parent.appendElement(div([
                    h2({}, "Bilder"),
                    div({'class': 'whiteboard'},
                        $.map(self.thumbnails, renderThumbnail))
                ]));

                $parent.appendElement(div({id: "photo-div", style: "display:none;"}));
            }
        }

        function renderThumbnail(thumbnail) {
            function _mkImage(image) {
                return img({
                    'id': 'image-' + image.id,
                    'style':`${thumbnail.image ? '' : 'cursor:default;'}`,
                    'src': thumbnail.image
                        ? "data:image/" + image.type + ";base64," + image.image
                        : "style/images/content/img-placeholder.jpg"
                });
            }

            return div({'class': 'whiteboard thumbnail'}, [
                a({ click: function() {
                    if (!thumbnail.image) {
                        return;
                    }

                    $.when(
                        tourRunDao.getTourRunPhoto(thumbnail.id)
                    ).done(function(photo) {
                        var $photoDiv = $("#photo-div");
                        var href = "data:image/" + photo.type + ";base64," + photo.image;
                        $photoDiv.empty();

                        $photoDiv.appendElement(a({id: 'thumb-link',
                            'data-lightbox': 'thumb-link',
                            'style': "display:none;",
                            href: href,
                            'data-title': photo.check_time
                                ? photo.tag_name + ' ' + photo.check_time.toFormatString(app.settings.dateFormat + " HH:mm") + '.' + photo.type.toLowerCase()
                                : photo.tag_name + '.' + photo.type.toLowerCase()
                        }));
                        $("#thumb-link").click();
                    });
                }}, _mkImage(thumbnail)),
                div({'class': 'thumbnail-tag-caption'}, thumbnail.tag_name),
                div({}, thumbnail.checkpoint_info),
                div({'class': 'thumbnail-date-caption'},
                    thumbnail.created_at.toFormatString(app.settings.dateTimeFormat))
            ]);
        }

        function renderMap($parent, height) {
            var locations = _.flatten([
                self.start !== null ? self.start : [],
                self.checkpoints,
                self.invalidCheckpoints,
                self.end !== null ? self.end : []
            ]);

            locations = _.sortBy(locations, function(l) {
                return l.check_time === null ? 0 : l.check_time;
            });

            function onMouseOut(map) {
                map.setMapOptions({scrollwheel: false});
            }

            function onClick(map) {
                map.setMapOptions({scrollwheel: true});
            }

            function getInfo(tagId) {
                var info = "";
                _.each(locations, function(l) {
                    if (l.tag_id === tagId) {
                        info += l.tag_name + ": " + l.check_time.toFormatString(app.settings.dateTimeFormat);
                        info += "<br />";
                    }
                });

                return info;
            }

            var pathLocations = [];
            _.each(locations, function(l) {
                if (l.latitude !== null && l.check_time !== null) {
                    pathLocations.push({
                        'title': l.tag_name,
                        'coordinates': new google.maps.LatLng(l.latitude, l.longitude),
                        'infoText': getInfo(l.tag_id),
                        'type': l.type
                    });
                }
            });

            if (pathLocations.length > 0) {
                $parent.appendElement([
                    h2({}, "Map Visualisierung"),
                    div({id: 'map-outer-div', 'class': 'map-outer-div whiteboard'})
                ]);

                var map = new Map($("#map-outer-div"), {
                    'sectionTime': 1500,
                    'location': pathLocations[0].coordinates,
                    'zoom': 17,
                    'scrollwheel': false,
                    'showPrintButton': true,
                    'showAnimateButton': true,
                    'onClick': onClick,
                    'onMouseOut': onMouseOut,
                    'fnGetMarkerPassTime': function(idx) {
                        return locations[idx].check_time;
                    }
                });

                map.render("height: " + height + "px").done(function () {
                    map.update();

                    map.renderPath(pathLocations);
                    map.markLocations(pathLocations);
                });
            }
        }

        function renderTourRun($parent) {

            var _render = function() {

                $parent.appendElement([
                    renderCommandBox($parent),
                    div({id: 'two-split-form-div', 'class': 'columns-3'}),
                    renderCheckpoints()
                ]);

                var $formDiv = $('#two-split-form-div');
                $formDiv.appendElement(
                    div({'class': 'columns-2'},
                        renderTourRunForm()));
                $formDiv.appendElement(
                    div({'class': 'column'},
                        [renderErrorForm(),
                         renderIncidents()]));

                renderThumbnails($parent);
                renderMap($parent, 700);

                $parent.activateValidators();
            };

            _render();
        }

        function refresh($parent) {
            var $oldContent = $parent.children();

            self.destroy();
            self.create($parent.empty(), self.tourRun, self.goToPage)
                .done(function() {
                    $oldContent.remove();
                });
        }

        function renderCommandBox($parent) {

            function refreshButton() {
                if (self.tourRun.end_time === null) {
                    return refreshIconButton({id: 'refresh-tour-run',
                        click: function () {
                            refresh($parent);
                        }
                    }, 'Aktualisieren');
                }

                return undefined;
            }

            function endTourRunButton() {
                if (self.tourRun.end_time === null && app.session.hasPermission(app.model.PermissionTypes.TOUR_MODIFY) ) {
                    return cancelIconButton({id: 'end-tour-run',
                        click: function () {
                            endTourRun($parent);
                        }
                    }, 'Rundgang beenden');
                }

                return undefined;
            }

            function addAdminCommentButton() {
                if (self.tourRun.end_time !== null && app.session.hasPermission(app.model.PermissionTypes.TOUR_MODIFY)) {
                    return editIconButton({id: 'add-admin-comment-button',
                        click: function () {
                            openAddAdminCommentDialog($parent);
                        }
                    }, 'Anmerkung');
                }

                return undefined;
            }

            function backAction() {
                return function navigateBack() {
                    if (this instanceof navigateBack) {
                        throw new Error('not constructable');
                    }
                    if (self.goToPage) {
                        return rise.Navigation.goToPage(self.goToPage, self.goToPageArgs ?? [true]);
                    } else {
                        return rise.Navigation.goToPage(pages.tour_run.list.open);
                    }
                };
            }

            return commandBox({backAction:backAction(), title: 'Rundgang ' + self.tourRun.tour_name},
                [refreshButton(),
                 endTourRunButton(),
                 addAdminCommentButton()]);
        }

        function endTourRun($parent) {
            tourRunDao.autoEndTourRun(self.tourRun.id).done(endTourRunDone);

            function endTourRunDone (result) {
                self.tourRun = result;
                $parent.empty();

                renderTourRun($parent);
                app.showSuccessMessage('Rundgang wurde beendet');
            }
        }

        function renderTourRunForm() {
            return div([
                h2("Zusammenfassung"),
                fieldset({id: 'tourrun-view'}, [
                    ul({'class': 'fieldset-content'}, [
                        form.formItemTextarea({id: 'tour-info', label: 'Beschreibung', disabled: true,
                            value: self.tourRun.tour_description ? self.tourRun.tour_description : '-'
                        }, true),
                        form.formItemText({id: 'tourrun-duration', label: 'Dauer',
                            value: self.tourRun.duration
                        }, true),
                        form.formItemText({id: 'tourrun-scanned-tags-count', label: 'Gelesene Kontrollpunkte',
                            value: self.tourRun.scanned_tags_count.toString()
                        }, true),
                        form.formItemText({id: 'tourrun-scanned-tags-count', label: 'Fehlende Kontrollpunkte',
                            value: (self.tourRun.tags_count - self.tourRun.scanned_tags_count).toString()
                        }, true),
                        form.formItemText({id: 'tourrun-start-time', label: 'Gestartet am', disabled: true,
                            value: self.tourRun.start_time.toFormatString(app.settings.dateTimeFormat) +
                                (self.tourRun.manually_started ? ' (manuell gestartet)' : '')
                        }, true),
                        form.formItemText({id: 'tourrun-end-time', label: 'Beendet am', disabled: true,
                            value: self.tourRun.end_time !== null ?
                                self.tourRun.end_time.toFormatString(app.settings.dateTimeFormat) +
                                (self.tourRun.manually_ended ? ' (manuell beendet)' : ''):
                                null
                        }, true),
                        form.formItemText({id: 'tourrun-started-by', label: 'Gestartet von',
                            value: self.tourRun.user_name
                        }, true),
                        form.formItemText({id: 'tourrun-ended-by', label: 'Beendet von',
                            value: self.tourRun.end_time !== null ?
                                (self.tourRun.auto_ended ? 'admin' : self.tourRun.user_name)
                                : null
                        }, true),
                        self.tourRun.app_version && app.session.hasPermission(app.model.PermissionTypes.TOUR_MODIFY) ?
                            form.formItemText({id: 'tourrun-app-version', label: 'App-Version', disabled: true,
                                value: self.tourRun.app_version
                            }, true) : undefined,
                        self.tourRun.admin_comment ?
                            form.formItemTextarea({id: 'tourrun-admin-comment', label: 'Anmerkung', disabled: true,
                                value: self.tourRun.admin_comment}, true) : undefined,
                        self.tourRun.info ?
                            form.formItemTextarea({id: 'tourrun-info', label: 'Abbruch-Begründung', disabled: true,
                                value: self.tourRun.info}, true) : undefined
                    ])
                ])
            ]);
        }

        function renderErrorForm() {
            return div([
                h2("Fehler"),
                fieldset({id: 'tourrun-errors'}, [
                    ul({'class': 'fieldset-content'}, [
                        self.tourRun.end_time ? form.formItemTextarea({id: 'tourrun-error-auto-ended',
                            label: 'Vom System beendet', disabled: true,
                            value: self.tourRun.auto_ended ? "Ja" : "Nein"
                        }, true) : undefined,
                        self.tourRun.end_time ? form.formItemText({id: 'tourrun-error-incomplete', label: 'Abgebrochen',
                            value: self.tourRun.tags_count > self.tourRun.scanned_tags_count ? "Ja" : "Nein"
                        }, true) : undefined,
                        form.formItemText({id: 'tourrun-error-duration', label: 'Dauereinschränkung verletzt',
                            value: self.tourRun.durationc_violated ? "Ja" : "Nein"
                        }, true),
                        form.formItemText({id: 'tourrun-error-incorrect-order', label: 'Falsche Reihenfolge',
                            value: self.tourRun.incorrect_order ? "Ja" : "Nein"
                        }, true)
                    ])
                ])
            ]);
        }

        function renderCheckpoints() {
            var getRowLabel = function(aoData) {
                if (aoData.type === checkpointType.INVALID) {
                    return "Nicht zugehörige Kontrollpunkte";
                } else {
                    if (aoData.check_time !== null) {
                        return "Bereits gelesene Kontrollpunkte";
                    } else {
                        return "Noch nicht gelesene Kontrollpunkte";
                    }
                }
            };

            var checkpoints = _.flatten([
                self.start !== null && self.start.check_time !== null ? self.start : [],
                self.checkpoints,
                self.end !== null && self.end.check_time !== null ? self.end : [],
                self.missingCheckpoints,
                self.invalidCheckpoints
            ]);

            return scrollTableSection({
                id: "tour-checkpoints",
                title: 'Rundgang Kontrollpunkte',
                elements: checkpoints,
                filter: true,
                paging: true,
                columns: [
                    { sTitle: "category", sAttrName: "category", bVisible: false,
                        mRender: function(mData, type, obj) {
                            if (obj.type === checkpointType.MISSED) {
                                return 0;
                            } else if (obj.type === checkpointType.INVALID) {
                                return 1;
                            } else {
                                return 2;
                            }
                        }
                    },
                    { sTitle: "Tag", sAttrName: "tag_name" },
                    { sTitle: "Gelesen am",
                        sAttrName: "check_time",
                        mRender: function(mData, type, obj) {
                            return obj.check_time ? obj.check_time.toFormatString(app.settings.dateTimeFormat) : "Noch nicht gelesen";
                        }
                    },
                    { sTitle: "Bemerkung", sAttrName: "info",
                        mRender: function(mData, type, obj) {
                            return obj.info ? obj.info : '-';
                        }
                    },
                    { sTitle: "Seriennummer", sAttrName: "serial_nfc" }
                ],
                height: "100%",
                getGroupingRowLabel: getRowLabel,
                groupedByColumn: 0,
                aaSortingColumn: 1
            });
        }

        function renderIncidents() {
            return div([
                h2("Vorkommnisse"),
                fieldset({id: 'tourrun-errors'}, [
                    ul({'class': 'fieldset-content'}, [
                        form.formItemText({id: 'tourrun-photos-count', label: 'Anzahl der Fotos',
                            value: self.tourRun.photos_count.toString()
                        }, true),
                        form.formItemText({id: 'tourrun-comments-count', label: 'Anzahl der Anmerkungen',
                            value: self.tourRun.comments_count.toString()
                        }, true)
                    ])
                ])
            ]);
        }

        function openAddAdminCommentDialog($parent) {
            var addAdminCommentDialog = new AddAdminCommentDialog({
                id: "create-tag-dialog",
                parent: $parent
            });

            addAdminCommentDialog.open();
        }

        /*** ADD COMMENT DIALOG ***/

        function AddAdminCommentDialog(options) {
            if (!options.parent) {
                throw "No parent element provided!";
            }

            this.options = $.extend({
                id: "add-admin-comment-dialog",
                title: "Anmerkung hinzufügen",
                click: null
            }, options);
            this.tourRun = self.tourRun;

            this._renderDialog();
        }

        AddAdminCommentDialog.prototype.open = function() {
            this.$dialog.dialog("open");
        };

        AddAdminCommentDialog.prototype.close = function() {
            this.$dialog.dialog("close").remove();
        };

        AddAdminCommentDialog.prototype._renderDialog = function () {
            var self = this;

            this.options.parent.appendElement(
                div({ id: this.options.id + "-wrapper" }, [
                    div({'class': 'add-comment-dialog-content'}, [
                        fieldset({id: 'comment'}, [
                            ul({'class': 'fieldset-content'}, [
                                form.formItemTextarea({id: 'tourrun-comment', label: 'Anmerkung', required: true,
                                    value: self.tourRun.admin_comment,
                                    change: function () {
                                        self.tourRun.admin_comment = $(this).val();
                                    }
                                }, false)
                            ])
                        ])
                    ]),
                    div({ "class": "buttonpane" }, [
                        div({ "class": "three-column" }, [
                            div({ "class": "left-column" }),
                            div({ "class": "center-column" }),
                            div({"class": "right-column"}, [
                                cancelIconButton({"click": function () {
                                    self.close();
                                }}),
                                saveIconButton({ "click": function () {
                                    tourRunDao.addAdminComment(self.tourRun.id,
                                        self.tourRun.admin_comment).done(function() {
                                            self.close();

                                            self.options.parent.empty();
                                            renderTourRun(self.options.parent);
                                        });
                                }})
                            ])
                        ])
                    ])
                ]));

            // init dialog
            this.$dialog = $("#" + this.options.id + "-wrapper").first();
            this.$dialog.dialog({
                modal: true,
                autoOpen: false,
                width: 650,
                close: function (e) {
                    $(e.target).dialog("close").remove();
                },
                title: this.options.title
            });
        };

    }

}) (window.rise, window.app);
