/**
 * Data Access Object for the customer endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
export const customerDao = (function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.customer");

    /*** Import ***/
    var Customer        = app.model.Customer;

    /*** Export Dao ***/
    app.dao.customerDao = new CustomerDao();

    /**
     * @constructor
     */
    function CustomerDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.Customer, new Rpc('/rpc/customer'));
    }

    // extend the parent Dao prototype
    $.extend(CustomerDao.prototype, Object.create(rise.Dao.prototype));

    CustomerDao.prototype.findCustomersAndLocations = function() {
        log.info('List all workplaces');
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke('find_customers_and_locations', {})
            .done(function(data) {
                if (data.length === 0) {
                    log.info('No customer data sets found');
                    deferred.resolve([]);
                } else {
                    log.info('Received %s User(s)', data.length);
                    deferred.resolve(data.map(function(d) {
                        return new app.model.Customer(d);
                    }));
                }
            })
            .fail(function(error) {
                log.error('Fetching all customers failed. %s', error.message);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Get a particular customer
     */
    CustomerDao.prototype.getCustomerDetails = function(customer_id, include_locations) {
        var deferred = new $.Deferred();

        this.rpc.invoke('get_customer_details', { 'id':customer_id })
            .done(function(json) {
                deferred.resolve( new app.dto.CustomerDetailsDto(json) );
            })
            .fail(function(error) {
                log.error('Unable to retrieve customer failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Get customer assignments
     */
    CustomerDao.prototype.getCustomerAssignments = function(customer_id) {
        var deferred = new $.Deferred();

        this.rpc.invoke('get_customer_assignments', { 'id':customer_id })
            .done(function(json) {
                deferred.resolve( new app.dto.CustomerAssignmentsDto(json) );
            })
            .fail(function(error) {
                log.error('Unable to retrieve customer failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Find workplaces incl. customer, location and object information
     */
    CustomerDao.prototype.listAll = function(only_active, filter_workplaces) {
        var deferred = new $.Deferred();

        only_active = (typeof only_active === 'boolean') ? only_active : false;
        filter_workplaces = _.isBoolean(filter_workplaces) ? filter_workplaces : true;

        this.rpc.invoke('list_all', {
            only_active: only_active,
            filter_workplaces: filter_workplaces
        }).done(function(customers) {
            if (customers.length === 0) {
                log.info('No customers found (list_all)');
                deferred.resolve([]);
            } else {
                log.info('Received %d customers (list_all)', customers.length);
                var result = _.map(customers, function(customer) {
                    return new Customer(customer);
                });
                deferred.resolve(result);
            }
        })
        .fail(function(error) {
            log.error('Finding (list_all) customer failed: %s', error);
            deferred.reject(error);
        });

        return deferred.promise();
    };

    CustomerDao.prototype.findAll = function(only_active) {
        var deferred = new $.Deferred();

        only_active = _.isBoolean(only_active) ? only_active : false;

        this.rpc.invoke('find_all', {
            only_active: only_active
        }).done(function(customers) {
            if (customers.length === 0) {
                log.info('No customers found (find_all)');
                deferred.resolve([]);
            } else {
                log.info('Received %d customers (find_all)', customers.length);
                var result = _.map(customers, function(customer) {
                    return new Customer(customer);
                });
                deferred.resolve(result);
            }
        })
        .fail(function(error) {
            log.error('Finding (find_all) customer failed: %s', error);
            deferred.reject(error);
        });

        return deferred.promise();
    };

    /**
     * Update customer and assignments
     */
    CustomerDao.prototype.updateCustomerAndAssignments = function(customer, assignedEventTypes, assignedBusinessDocuments) {
        var deferred = new $.Deferred();

        this.rpc.invoke('update_customer_and_assignments',
            { customer:customer, event_type_ids:assignedEventTypes, business_document_ids:assignedBusinessDocuments })
            .done(function(json) {
                    var result = new Customer(json);
                    deferred.resolve(result);
            })
            .fail(function(error) {
                log.error('Finding customer failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Update customer and assignments
     */
    CustomerDao.prototype.insertCustomerAndAssignments = function(customer, assignedEventTypes, assignedBusinessDocuments) {
        var deferred = new $.Deferred();

        this.rpc.invoke('insert_customer_and_assignments',
            { customer:customer, event_type_ids:assignedEventTypes, business_document_ids:assignedBusinessDocuments })
            .done(function(json) {
                var result = new Customer(json);
                deferred.resolve(result);
            })
            .fail(function(error) {
                log.error('Inserting customer failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    return new CustomerDao();
})(window.rise, window.app);
