/**
 * Widgets for the generation of the menu
 *
 * @module app.widgets
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function (app) {
    "use strict";

    /*** EXPORT ***/
    var menu = app.widgets.menu = {};

    /*** GLOBAL ***/
    var pageClass = 'nav-page';

    function followNavigation($nav_base) {
        rise.Navigation.addPageChangeCallback(function(page, args) {
            var navigatedPage;

            $nav_base.find('.' + pageClass).each(function(idx, item) {
                if ($(item).data('page') === page) {
                    navigatedPage = $(item);
                    return false;
                }
            });

            if (navigatedPage) {
                $nav_base.find('.current').removeClass('current');
                navigatedPage.addClass('current').parents('.nav-item').first().addClass('current');

                var breadcrumbs = [];

                breadcrumbs.push(li(navigatedPage.data('page-label')));
                var parent = navigatedPage;
                while ((parent = parent.parent().closest('.' + pageClass)).length > 0) {
                    breadcrumbs.push(li(parent.data('page-label')));
                    if (parent.closest('ul').hasClass('nav-list')) {
                        parent.prev().addClass('current').parents('.nav-item').first().addClass('current');
                    }
                }

                breadcrumbs.reverse();
            }
        });
    }

    menu.create = function (childs) {
        return ul({ 'class': 'nav-list',
            create: function () {
                initializeMenu($(this));
                followNavigation($(this));
            }
        }, childs);
    };

    /**
     * first level item with a drop down.
     *
     * possible params parameters:
     *  * label: Label which is displayed
     *  * tooltip: tooltip for links
     *  * page: page function where this item should lead
     *  * horizontal: if the first items in the drop down should be arranged horizontally
     *
     * @param params
     * @param [childs]
     */
    menu.tab = function(params, childs) {

        if (!params.label) {
            throw "Missing label parameter in 'tab' menu declaration";
        }

        return renderMenuItem(params.id, params.label, params.tooltip, params.page,
            'tab' + (params.horizontal ? ' horizontal' : ''), childs);
    };

    /**
     * Menu item in an arbitrary level.
     *
     * possible params parameters:
     *  * label: Label which is displayed
     *  * tooltip: tooltip for links
     *  * page: page function where this item should lead
     *
     * @param params
     * @param [childs]
     */
    menu.item = function(params, childs ) {

        if (!params.label) {
            throw "Missing label parameter in 'item' menu declaration";
        }

        return renderMenuItem(params.id, params.label, params.tooltip, params.page, params['class'] || '', childs);
    };

    function hasPagePermission(page) {
        if (page.permissions) {
            if (!_.any(_.map(page.permissions, function(permission) { return window.app.session.hasPermission(permission); }))) {
                return false;
            }
        }

        return true;
    }

    function renderMenuItem(id, label, tooltip, Page, li_class, children) {
        var navigation = rise.Navigation,
            filteredChildren = _.filter(children || [], function(child) {
                return child.children || (child.page && hasPagePermission(child.page));
            });

        if(!(filteredChildren.length || (Page && hasPagePermission(Page)))) {
            return undefined;
        }

        return li({'class': 'nav-item ' + li_class, id:"nav-item-" + id}, [
            a({title: tooltip, 'class': 'nav-label', id:"nav-item-link-" + id,
                create: function() {
                    $(this)
                        .addClass(pageClass)
                        .data('page', Page)
                        .data('page-label', label);
                },
                click: function () {
                    var targetPage = children ? getFirstPage(children) : Page;
                    navigation.goToPage(targetPage);
                    closeMobileMenu();
                    return false;
                }
            }, label),
            children ?
                ul({'class': 'nav-list',
                    create: function() {
                        $(this)
                            .addClass(pageClass)
                            .data('page-label', label);
                    }
                }, _.map(children, function(child) {
                    return menu.item(child);
                }))
                : ''
        ]);
    }

    function getFirstPage(children) {
        return _.find(children, function(child) {
            return hasPagePermission(child.page);
        }).page;
    }

    /**
     * adds a help content to the drop down menu. Usually this splits the menu in vertically into two parts
     * left is the menu displayed as usually, right is this help displayed. It consists of a content, which is
     * usually text and a help link. The help link is usually a link (a) but can actually be what ever
     *
     * @param content
     * @param help_link
     * @returns {*}
     */
    menu.help = function (content, help_link) {
        return li({'class': 'nav-item'}, [
            div({'class': 'nav-sub-content-help-content'}, content),
            div({'class': 'nav-sub-content-help-link'}, help_link)
        ]);
    };

    /**
     *  Enhance the menu functionality, mouseover function.
     */
    function showMenu() {
        $(this).addClass("hover");
        var content = $(this).find('> .nav-list');
        if(content.length){
            $(content).css('left', '0px');
            var leftOffset = content.offset().left;
            var contentWidth = content.width();
            var delta = ($(window).width()) - (leftOffset+contentWidth);
            if (delta < 0){
                content.css('left', (delta-6)+'px');
            }
        }
    }

    /**
     *  Enhance the menu functionality, mouseout function.
     */
    function hideMenu() {
        $(this).removeClass("hover");
    }

    /**
     *  Configure the menu in the primary navigation.
     */
    function initializeMenu($primaryUl) {
        $primaryUl
            .children()
            .on('click', function() {
                if ($(this).hasClass('hover')) {
                    hideMenu.apply(this);
                } else {
                    showMenu.apply(this);
                }
            });

        // this is needed so the li.drop-down is not invoked, when an event is bubbling up
        // e.g. from clicking a menu entry
        $primaryUl.find('li>ul').click(function (e) {
            e.stopPropagation();
        });
    }

    function closeMobileMenu() {
        $("#nav-primary").removeClass("m-open");
        $("#nav-supplementary").removeClass("m-open");
    }

})(window.app);
