/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function (app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.LogbookEntryNote = function LogbookEntryNote(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }


        this.id                 = obj.id != null ? parseInt(obj.id) : null;
        this.logbook_entry_id   = obj.logbook_entry_id != null ? parseInt(obj.logbook_entry_id) : null;
        this.user_id        = obj.user_id != null ? parseInt(obj.user_id) : null;

        this.created_time =  obj.created_time ?
            typeof obj.created_time === 'string' ?
                Date.fromISO(obj.created_time)
                : obj.created_time
            : null;

        this.content             = obj.content != null ? obj.content : null;

        // read-only properties
        this._user_first_name = obj._user_first_name ? obj._user_first_name : null;
        this._user_last_name = obj._user_last_name ? obj._user_last_name : null;
    };

}(window.app));
