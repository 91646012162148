/**
 * Logging console appender, which writes to the browser JavaScript console.
 *
 * @module rise.Logging.ConsoleAppender
 * @author Christoph Mayerhofer
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 * ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise) {
    "use strict";

    var logging = rise.logging;

    /**
     * Simple console appender.
     * @param {Function} formatter Formatter which formats the log text.
     *                  Default: date [LEVEL] message.
     * @constructor
     */
    function ConsoleAppender(formatter) {
        this.formatter = formatter || function(message, level, timestamp) {
            return timestamp.toISOString() + " [" + logging.levelName[level] + "] " + message;
        };
    }

    // add to logging module
    logging.ConsoleAppender = ConsoleAppender;

    /**
     * Write the message to the JavaScript console.
     * @param {String} message The message text.
     * @param {Number} level The logging level.
     * @param {Date} timestamp The timestamp of the log.
     */
    ConsoleAppender.prototype.append = function(message, level, timestamp) {
        if (window.console) {
            var msg = this.format(message, level, timestamp);
            switch (level) {
                case logging.logLevel.DEBUG:
                    window.console.debug ? window.console.debug(msg) : window.console.log(msg);
                    break;
                case logging.logLevel.INFO:
                    window.console.info(msg);
                    break;
                case logging.logLevel.WARN:
                    window.console.warn(msg);
                    break;
                case logging.logLevel.ERROR:
                    window.console.error(msg);
                    break;
                case logging.logLevel.FATAL:
                    window.console.error(msg);
                    break;
            }
        }
    };

    /**
     * Format the message for the appender.
     * @param {String} message The message text.
     * @param {Number} level The logging level.
     * @param {Date} timestamp The timestamp of the log.
     * @return {String} The formatted message.
     */
    ConsoleAppender.prototype.format = function(message, level, timestamp) {
        if (this.formatter) {
            return this.formatter(message, level, timestamp);
        }
        throw "Formatter is not defined!";
    };

}(window.rise));