/**
 * Data Access Object for the logbook-entry endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.logbook-entry");

    /*** Export Dao ***/
    app.dao.eventLogbookEntryDao = new EventLogbookEntryDao();

    /**
     * @constructor
     */
    function EventLogbookEntryDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.EventLogbookEntry, new Rpc('/rpc/event-logbook-entry'));
    }

    // extend the parent Dao prototype
    $.extend(EventLogbookEntryDao.prototype, Object.create(rise.Dao.prototype));

    EventLogbookEntryDao.prototype.insert = function(eventEntry, fileContents) {
        var deferred = new $.Deferred();

        this.rpc.invoke("insert", {
            event_logbook_entry: eventEntry,
            file_contents: fileContents
        }).done(function(json) {
                deferred.resolve(new app.model.EventLogbookEntry(json));
            })
            .fail(function (error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    EventLogbookEntryDao.prototype.getCustomerList = function () {
        var deferred = new $.Deferred();

        this.rpc.invoke('get_customer_list')
            .done(function(rows) {
                deferred.resolve(_.map(rows, function(e) {return new app.model.Customer(e);}));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    EventLogbookEntryDao.prototype.getEventTypes = function(objectId, workplaceId) {
        var deferred = new $.Deferred();

        log.info(sprintf('Get event types visible to a workplace(id=%s) and object(id=%s)',
            workplaceId, objectId));

        this.rpc.invoke('get_event_type_list', {
            workplace_id: workplaceId,
            object_id: objectId
        })
            .done(function(eventTypes) {
                deferred.resolve(_.map(eventTypes, function(json) {
                    return new app.model.EventType(json);
                }));
            })
            .fail(function (error) {
                log.error('List of event types cannot be retrieved. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

})(window.rise, window.app);
