import keycode from "keycode";

/**
 * Task detail page.
 *
 * @author Martin Reiterer (martin.reiterer@rise-world.com)
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var log         = rise.logging.getLogger('pages.task.detail');
    var nav         = rise.Navigation;
    var pages       = app.pages;
    var form        = app.widgets.form;

    var JustTime        = window.JustTime;
    var SelectDialog    = app.widgets.SelectDialog;
    var textButton      = app.widgets.textButton;
    var cancelIconButton = app.widgets.cancelIconButton;
    var saveIconButton  = app.widgets.saveIconButton;
    var commandBox      = app.widgets.commandBox;

    /** DAOs **/
    var Task            = app.model.Task;
    var DayType         = app.model.MonthlyTask.DayType;
    var taskDao         = app.dao.taskDao;

    /** CONSTANTS **/
    var DEFAULT_TIME = new JustTime(12, 0);


    /** GLOBALS **/
    var rotationTypes = [
        { id:"UNIQUE", name:"Einmalig", info:"Einmalige Aufgaben müssen zu einem spezifizierten Zeitpunkt ausgeführt werden.",
            form: renderRotationUnique },
        { id:"DAILY", name:"Täglich", info:"Tägliche Aufgaben müssen jeden Tag zur spezifizierten Uhrzeit ausgeführt werden.",
            form: renderRotationDaily },
        { id:"WEEKLY", name:"Wöchentlich", info:"Wöchentliche Aufgaben müssen jede Woche an den spezifizierten Tagen zur gewählten Uhrzeit ausgeführt werden.",
            form: renderRotationWeekly,
            days: [
                {id: "mon", name: "Montag"},
                {id: "tue", name: "Dienstag"},
                {id: "wed", name: "Mittwoch"},
                {id: "thu", name: "Donnerstag"},
                {id: "fri", name: "Freitag"},
                {id: "sat", name: "Samstag"},
                {id: "sun", name: "Sonntag"}
            ]
        },
        { id:"MONTHLY", name:"Monatlich", info:"Monatliche Aufgaben müssen an den gewählten Monaten am spezifizierten Tag ausgeführt werden.",
            form: renderRotationMonthly,
            months: [
                {id: "jan", name: "Jänner", days: 31},
                {id: "feb", name: "Februar", days: 28},
                {id: "mar", name: "März", days: 31},
                {id: "apr", name: "April", days: 30},
                {id: "may", name: "Mai", days: 31},
                {id: "jun", name: "Juni", days: 30},
                {id: "jul", name: "Juli", days: 31},
                {id: "aug", name: "August", days: 31},
                {id: "sep", name: "September", days: 30},
                {id: "oct", name: "Oktober", days: 31},
                {id: "nov", name: "November", days: 30},
                {id: "dec", name: "Dezember", days: 31}
            ],
            days: function() {
                var days = [];
                for (var i = 1; i <= 31; i++) {
                    days.push({ name: new String(i) });
                }
                return days;
            }()
        }
    ];

    var assignmentTypes = [
        { id:"WORKPLACE", name:"Arbeitsplatz", form: renderAssignmentWorkplace },
        { id:"EMPLOYEE", name:"Benutzer", form: renderAssignmentUser }
    ];

    var PageType = {
        VIEW: 1,
        EDIT: 2,
        NEW: 3
    };

    /*** Export page ***/
    pages.task.detail = app.pagesByPath['/task/detail'] = function TaskDetail() {
        var obj = this;
        this.name = "/task/detail";

        this.type = PageType.VIEW;
        this.task = null;
        this.customers = null;
        this.workplaceOrUserAssignment = undefined;

        this.goToPage = null;

        this.create = function ($parent, id, goToPage) {

            obj.goToPage = app.pagesByPath[goToPage];

            if (id) {
                return taskDao.getTaskDetails(id)
                    .done(function(taskDto) {

                        obj.task = taskDto.task;

                        obj.workplaceOrUserAssignment =
                            taskDto.workplace_name ?
                                taskDto.workplace_name :
                                taskDto.assigned_user;

                        obj.assignedCustomer = taskDto.assigned_customer;

                        taskDao.getCustomerList()
                            .done(function(customers) {
                                obj.customers = customers;
                                renderTask($parent, PageType.VIEW);
                            });
                    });
            } else {
                obj.task = {
                    rotation_type: "UNIQUE"
                };

                taskDao.getCustomerList()
                    .done(function(customers) {
                        obj.customers = customers;
                        renderTask($parent, PageType.NEW);
                    });
            }
        };

        this.destroy = function() {
            // called after page has been destroyed
        };

        function renderTask($parent, type) {
            obj.type = type;

            var _render = function() {

                $parent.appendElement([
                    renderCommandBox($parent),

                    renderTaskDetails(obj.task, obj.type !== PageType.NEW),

                    renderTimeConstraintForm(obj.task, obj.type === PageType.VIEW),

                    renderAssignmentForm(obj.task, obj.customers, onWorkplaceChanged, obj.type === PageType.VIEW,
                        obj.workplaceOrUserAssignment, obj.assignedCustomer)
                ]);

                $.validation.addValidators({
                    '.valid-month-selected': [
                        validateCheckboxList,
                        'Es wurde kein Monat ausgewählt',
                        'click'
                    ],
                    '.valid-weekday-selected': [
                        validateCheckboxList,
                        'Es wurde kein Tag ausgewählt',
                        'click'
                    ],
                    '.valid-notempty-assigned-user': [
                        function(e, val) {
                            return val;
                        },
                        'Es wurde kein Benutzer zugeordnet',
                        'change'
                    ],
                    '.valid-unique-task-date': [
                        function(e, val) {
                            var now = new Date();
                            now.setSeconds(0);
                            now.setMilliseconds(0);

                            return $(e.target).datepicker('getDate') >  now;
                        },
                        'Datum muss in der Zukunft liegen',
                        'change'
                    ]
                });

                $parent.activateValidators();
            };

            _render();
        }

        function validateCheckboxList(e, val) {
            var $checkboxes = $(e.target).find(':checkbox');

            var oneSelected = false;
            $checkboxes.each(function(index, $checkbox) {
                oneSelected = oneSelected || $($checkbox).is(':checked');
            });

            return oneSelected;
        }

        function renderCommandBox($parent) {
            var saved_task = Task.newInstance(obj.task);

            function saveButton() {
                return saveIconButton({id: 'save-task',
                    click: function () {
                        if ($parent.validate()) {
                            saveTask($parent);
                        }
                    }
                });
            }

            function cancelButton(backToOverview) {
                return cancelIconButton({id: 'cancel-edit-task',
                    click: function () {
                        if (backToOverview) {
                            nav.goToPage(pages.task.list);
                        } else {
                            $.validation.reset();
                            $parent.empty();

                            obj.task = saved_task;
                            renderTask($parent, PageType.VIEW);
                        }
                    }
                });
            }


            function getTitle () {
                switch (obj.type) {
                    case PageType.NEW:  return 'Neue Aufgabe';
                    case PageType.EDIT: return 'Aufgabe bearbeiten';
                    case PageType.VIEW: return 'Aufgabe: ' + obj.task.label;
                }
                return '';
            }

            function getCommandButtons() {
                switch (obj.type) {
                    case PageType.EDIT:
                        return [
                            saveButton(),
                            cancelButton()
                        ];

                    case PageType.NEW:
                        return [
                            saveButton(),
                            cancelButton(true)
                        ];
                }

                return [];
            }

            function backAction() {
                if(obj.goToPage) {
                    return obj.goToPage;
                } else {
                    return pages.task.list;
                }
            }

            return commandBox({title:getTitle(),backAction: backAction()},getCommandButtons());
        }

        function saveTask($parent) {

            obj.task.email = $("#task-email").tokenlist("value") || null;

            if(obj.task.id) {
                taskDao.update(obj.task).done(updateDoneMethod);
            } else {

                obj.task = Task.newInstance(obj.task);

                taskDao.insert(obj.task).done(insertDoneMethod);
            }

            function insertDoneMethod(result) {
                obj.task = result;
                $parent.empty();

                nav.goToPage(pages.task.list);
                app.showSuccessMessage('Gespeichert');
            }

            function updateDoneMethod(result) {
                obj.task = result;
                $parent.empty();

                renderTask ($parent, PageType.VIEW);
                app.showSuccessMessage('Aktualisiert');
            }
        }

        function onWorkplaceChanged(hierarchy, param_id) {
            if (hierarchy == 3) {
                obj.task.assigned_workplace_id = param_id;
            } else {
                obj.task.assigned_workplace_id = null;
            }

            if (hierarchy == 0) {
                obj.task.assigned_customer_id = param_id;
            }
        }
    };

    function renderTaskDetails(task, view) {
        return fieldset({id: 'task-edit'}, [
            ul({'class': 'fieldset-content'}, [
                form.formItemText({id: 'task-label', label: 'Aufgabenbezeichnung', required: true,
                    value: task.label,
                    change: function () {
                        task.label = $(this).val();
                    }
                }, view),
                form.formItemTextarea({id: 'task-description', label: 'Beschreibung', required: true,
                    value: task.description,
                    change: function () {
                        task.description = $(this).val();
                    }
                }, view),
                form.formItemText({id: 'task-email', label: 'E-Mail-Benachrichtigung bei nicht-fristgerechter Ausführung',
                    value: view ? task.email && task.email.split(';').join('; ') : task.email,
                    create: function() {
                        $(this).tokenlist({
                            tokenDelimiter: [keycode('space'), keycode(',')],
                            split: /\s*;\s*/,
                            join: ';',
                            acceptCustomInput: true,
                            validate: function(labelValue) {
                                var val = labelValue.value;
                                if(val !== undefined) {
                                    var pattern = new RegExp(/^([a-zA-Z0-9._\\+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})?$/);
                                    return pattern.test(val);
                                }

                                return true;
                            }
                        });
                    }
                }, view),
                form.formItemCheckbox({
                    id: "task-active",
                    label: "Aktiv",
                    checked: task.active != null ? task.active : task.active=true,
                    change: function (e) {
                        task.active = $(e.target).prop("checked");
                    }
                }, view)
            ])
        ]);
    }

    function renderTimeConstraintForm(task, view) {
        var currentRotationType = null;

        for (var ir=0; ir < rotationTypes.length; ir++) {
            if (task.rotation_type === rotationTypes[ir].id) {
                currentRotationType = rotationTypes[ir];
                break;
            }
        }

        return fieldset({id: 'task-edit'}, [
            legend({}, "Zeitliche Einstellungen"),
            ul({'class': 'fieldset-content'}, [
                form.formItemSelect({id: 'task-rotation-type', label: 'Wiederholung', required: true ,
                    source: rotationTypes, valueProp: 'name',
                    value: currentRotationType, sort: false,
                    selected: function(e, rotationType) {
                        if(currentRotationType !== rotationType) {
                            task.rotation_type = rotationType.id;
                            updateRotationTypeInfo($("#task-rotation-type-info"), rotationType);
                            updateRotationForm($("#task-rotation-form"), rotationType, task, view);

                            currentRotationType = rotationType;
                        }
                    }
                }, view),
                !view
                    ? li({ id:"task-rotation-type-info", create:function() {
                        updateRotationTypeInfo($(this), currentRotationType);
                      }})
                    : undefined,
                li({ id:"task-rotation-form", create:function() {
                    updateRotationForm($(this), currentRotationType, task, view)
                }})
            ])
        ]);
    }

    function updateRotationTypeInfo($infoSection, rotationType) {
        $infoSection
            .empty()
            .appendElement(span({ "class":"info" }, rotationType && rotationType.info || null));
    }

    function updateRotationForm($li, rotationType, task, view) {
        $li
            .empty()
            .appendElement(rotationType.form(task, view, rotationType));
    }

    function renderRotationUnique(task, view) {
        return form.formItemDateTime({id: 'task-rotation-unique-date', label: 'Datum', required: true,
            date: task.timestamp || (function() {
                var currentDatePlus1Hours = new Date();
                currentDatePlus1Hours.setHours(currentDatePlus1Hours.getHours()+1);
                return currentDatePlus1Hours;
            })(),
            validation: 'valid-unique-task-date',
            change: function() {
                task.timestamp = $(this).datepicker('getDate');
            }
        }, view);
    }

    function renderRotationDaily(task, view) {
        return form.formItemTime({id: 'task-rotation-daily-time', label: 'Uhrzeit', required: true,
            time: (task.time && new JustTime(task.time.hours, task.time.minutes))
                || (task.time=DEFAULT_TIME),
            change: function() {
                if ($(this).val()) {
                    task.time = new JustTime($(this).datepicker('getDate'));
                }
            }
        }, view);
    }

    function renderRotationWeekly(task, view, rotationTypeWeekly) {
        return [
            form.formItemCheckboxList({id: 'task-rotation-weekly-day', label: 'Tag', required: true,
                options: _.map(rotationTypeWeekly.days, function(day) { return day.name }),
                value: function() {
                    if(view) {
                        return _.map(task.weekdays.split(','), function(weekdayId) {
                            return $.grep(rotationTypeWeekly.days, function(day) {
                                return day.id == weekdayId;
                            })[0].name;
                        });
                    } else {
                        return undefined;
                    }
                }(),
                validation: 'valid-weekday-selected',
                listChanged: function(selectedDays) {
                    var selectedDays = _.map(selectedDays, function(selectedDay) {
                        var dayId = $.grep(rotationTypeWeekly.days, function(day) {
                            return day.name == selectedDay;
                        })[0].id;
                        return dayId;
                    });
                    task.weekdays = selectedDays.join(',');
                }
            }, view),
            form.formItemTime({id: 'task-rotation-weekly-time', label: 'Uhrzeit', required: true,
                time: (task.time && new JustTime(task.time.hours, task.time.minutes))
                    || (task.time=DEFAULT_TIME),
                change: function() {
                    if ($(this).val()) {
                        task.time = new JustTime($(this).datepicker('getDate'));
                    }
                }
            }, view)
        ];
    }

    function renderRotationMonthly(task, view, rotationTypeMonthly) {

        var onChangeRatioButton = function(e) {
            var val = $(this).val();
            var combobox = $('#task-rotation-monthly-day-combobox');

            switch(val) {
                case DayType.DAY_FIRST:
                    combobox.prop('disabled', true);
                    combobox.next().prop('disabled', true);
                    task.day = 1;
                    task.day_type = DayType.DAY_SPECIFIC;
                    break;
                case DayType.DAY_LAST:
                    combobox.prop('disabled', true);
                    combobox.next().prop('disabled', true);
                    task.day = null;
                    task.day_type = DayType.DAY_LAST;
                    break;
                case DayType.DAY_SPECIFIC:
                    combobox.prop('disabled', false);
                    combobox.next().prop('disabled', false);
                    task.day = combobox.val();
                    task.day_type = DayType.DAY_SPECIFIC;
                    break;
            }
        };

        var days = rotationTypeMonthly.days.slice(0);

        return [
            form.formItemCheckboxList({id: 'task-rotation-monthly-month', label: 'Monat', required: true,
                options: _.map(rotationTypeMonthly.months, function(month) { return month.name }),
                value: function() {
                    if(view) {
                        return _.map(task.months.split(','), function(monthId) {
                            return $.grep(rotationTypeMonthly.months, function(month) {
                                return month.id == monthId;
                            })[0].name;
                        });
                    } else {
                        return undefined;
                    }
                }(),
                validation: 'valid-month-selected',
                listChanged: function(selectedMonthNames) {
                    var selectedMonths = _.map(selectedMonthNames, function(selectedMonthName) {
                        var month = $.grep(rotationTypeMonthly.months, function(month) {
                            return month.name == selectedMonthName;
                        })[0];
                        return month;
                    });
                    task.months = _.map(selectedMonths, function(selectedMonth) { return selectedMonth.id }).join(',');

                    // remove not allowed days from combobox-source
                    // use same source object
                    rotationTypeMonthly.days.splice(0);
                    Array.prototype.push.apply(rotationTypeMonthly.days, days);

                    selectedMonths.sort(function(a, b) {
                        return a.days - b.days;
                    });

                    if(selectedMonths.length > 0)  {
                        var index = selectedMonths[0].days;
                        rotationTypeMonthly.days.splice(index, rotationTypeMonthly.days.length-index);
                    }
                }
            }, view),
            !view ?
                li({'class': 'fieldset-item', id: 'task-rotation-monthly-day'}, [
                    label({'for': "task-rotation-monthly-day" ,'class': "required" }, "Tag"),
                    ul({}, [
                        li({}, [
                            input({id: "task-rotation-monthly-day-first", name: "radio-day", type: 'radio', "checked": true,
                                value: DayType.DAY_FIRST,
                                create: onChangeRatioButton,
                                change: onChangeRatioButton
                            }),
                            label({'for': "task-rotation-monthly-day-first"}, "erster Tag")
                        ]),
                        li({}, [
                            input({id: "task-rotation-monthly-last", name: "radio-day", type: 'radio', value: DayType.DAY_LAST,
                                change: onChangeRatioButton
                            }),
                            label({'for': "task-rotation-monthly-last"}, "letzter Tag")
                           ]),
                        li({}, [
                            input({id: "task-rotation-monthly-specific", name: "radio-day", type: 'radio', value: DayType.DAY_SPECIFIC,
                                change: onChangeRatioButton
                            }),
                            label({'for': "task-rotation-monthly-specific"}, "spezifischer Tag"),
                            input({id: "task-rotation-monthly-day-combobox", 'class': "valid-day-selected", disabled: true,
                                create: function () {
                                    $(this).combobox({
                                        source: rotationTypeMonthly.days,
                                        valueProp: 'name',
                                        sort: false,
                                        selected: function(e, selection) {
                                            task.day = selection.name;
                                        }
                                    }).combobox('setSelected', rotationTypeMonthly.days[0]);

                                    $(this).next().prop('disabled', true);
                                }
                            })
                        ])
                    ])
                ]) :
                li({'class': 'fieldset-item'}, [
                    label({'for': "task-rotation-monthly" }, "Tag"),
                    span({id: 'task-rotation-monthly'}, task.day_type == DayType.DAY_LAST ? 'letzter Tag' : task.day)
                ]),
            form.formItemTime({id: 'task-rotation-monthly-time', label: 'Uhrzeit', required: true,
                time: (task.time && new JustTime(task.time.hours, task.time.minutes))
                    || (task.time=DEFAULT_TIME),
                change: function() {
                    if ($(this).val()) {
                        task.time = new JustTime($(this).datepicker('getDate'));
                    }
                }
            }, view)
        ];
    }

    function renderAssignmentForm(task, customers, onChange, view, workplaceOrUserAssignment, assignedCustomer) {
        var currentAssignmentType = assignmentTypes[0];

        var isUser = typeof(workplaceOrUserAssignment) === "object";

        return fieldset({id: 'task-assignment-edit'}, [
            legend({}, "Zuständigkeiten"),
            ul({'class':'fieldset-content'},
                view ?
                    isUser ?
                        [form.formItemText({id: 'task-assignment-customer', label: 'Kunde',
                            value: assignedCustomer ? assignedCustomer.name : null
                         }, view),
                         form.formItemText({id: 'task-assignment-user', label: 'Benutzer',
                            value: workplaceOrUserAssignment.first_name + ' ' + workplaceOrUserAssignment.last_name
                         }, view)] :
                        [form.formItemText({id: 'task-assignment-workplace', label: 'Arbeitsplatz',
                            value: workplaceOrUserAssignment
                        }, view)] : [
                    form.formItemSelect({id: 'task-assignment-type', label: 'Typ', required: true,
                        source: assignmentTypes,
                        value: currentAssignmentType,
                        valueProp: "name",
                        selected: function(e, assignmentType) {
                            if(currentAssignmentType !== assignmentType)  {
                                updateAssignmentForm($("#task-assignment-form"), assignmentType, task, view,
                                    customers, onChange);
                                currentAssignmentType = assignmentType;
                            }
                        }
                    }, view),
                    li({ id:"task-assignment-form", create:function() {
                        updateAssignmentForm($(this), assignmentTypes[0], task, view,
                            customers, onChange);
                    }})]
            )
        ]);
    }

    function updateAssignmentForm($li, assignmentType, task, view, customers, onChange) {
        task.assigned_workplace_id = null;
        task.assigned_user_id = null;
        task.assigned_customer_id = null;

        $li
            .empty()
            .appendElement(assignmentType.form(task, view, customers, onChange));
    }

    function renderAssignmentWorkplace(task, view, customers, onChange) {
        return form.formItemWorkplaceSelect({
            id: "select-workplace",
            customers: customers,
            change: onChange,
            required: true
        }, view);
    }

    function renderAssignmentUser(task, view, customers) {
        var userSelector,
            mkUserSelector;

        function updateAssignedUser(users) {
            var fullname = "-";
            if (task.assigned_user_id) {
                var user = _.find(users,
                    function(user) {
                        return user.id === task.assigned_user_id;
                    }
                );
                fullname = user.first_name + " " + user.last_name;
                $("#assigned-user").val(fullname).change();
            }
        }

        function customerSelected(hierachy, param_id) {
            if (task.assigned_customer_id === param_id)
                return;

            task.assigned_customer_id = param_id;
            task.assigned_user_id = null;

            $("#assigned-user").val("");

            taskDao.getAssignableUsers(task.assigned_customer_id)
                .done(function(users) {
                userSelector = mkUserSelector(users);
            });
        }

        return div({ id:"select-user", "class":"action-box bottom",
                     create: function (e) {
                         mkUserSelector = function(users) {
                             var $target = $(e.target);

                             // enable assignment button
                             $target.find("#task-assignment-assign-user").removeClass("disabled");

                             return new SelectDialog({
                                 id: "user-select",
                                 parent: $target,
                                 data: users,
                                 columns: [
                                     { sTitle: "Nachname", sAttrName: "last_name", sWidth: "25%" },
                                     { sTitle: "Vorname", sAttrName: "first_name", sWidth: "25%" },
                                     { sTitle: "Benutzername", sAttrName: "username" }
                                 ],
                                 selected: function(row) {
                                     task.assigned_user_id = row.id;
                                     updateAssignedUser(users);
                                 }
                             });
                         };
                     }}, [
            form.formItemWorkplaceSelect({
                id: "select-customer",
                customers: customers,
                change: customerSelected,
                required: true,
                depth: 1
            }, view),
            ul({ style:"margin-bottom: 10px"}, [
                li({'class': 'fieldset-item'}, [
                    label({'for': "assigned-user", 'class': "required" }, "Zugeordneter Benutzer"),
                    input({id: "assigned-user", disabled: true, 'class': "valid-notempty-assigned-user" })
                ])
            ]),
            div({ style:"position:relative" }),
            textButton({"id": 'task-assignment-assign-user',
                "class": "disabled",
                "click": function(e) {
                    userSelector.open();
                }
            }, 'Benutzer zuweisen')
        ])
    }

}) (window.rise, window.app);
