/**
 * @module JustTime
 * @author Martin Reiterer
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */

export const JustTime = (function () {
    "use strict";

    window.JustTime = function JustTime(d) {
        var tempDate = null;

        if (arguments.length === 0) {
            tempDate = new Date();
        } else if (arguments.length === 1 ) {
            tempDate = new Date(arguments[0]);
        } else if (arguments.length === 2 ) {
            tempDate = new Date();
            tempDate.setHours(arguments[0]);
            tempDate.setMinutes(arguments[1]);
        } else {
            throw "Invalid number of arguments! Only 0, 1 or 2 are accepted. Checkout the docs";
        }

        this.hours = tempDate.getHours();
        this.minutes = tempDate.getMinutes();
    };

    window.JustTime.prototype.setHours = function(hours) {
        this.hours = hours;
        return this;
    };

    window.JustTime.prototype.getHours = function() {
        return this.hours;
    };

    window.JustTime.prototype.setMinutes = function(minutes) {
        this.minutes = minutes;
        return this;
    };

    window.JustTime.prototype.getMinutes = function() {
        return this.minutes;
    };

    window.JustTime.prototype.toTimeString = function() {
        return window.JustTime.formatTimeString(this);
    };

    window.JustTime.formatTimeString = function(t) {
        var now = new Date();

        now.setHours(t.hours);
        now.setMinutes(t.minutes);

        return now.toFormatString("HH:mm");
    };

    window.JustTime.prototype.toISOString = function() {
        var now = new Date();

        now.setHours(this.hours);
        now.setMinutes(this.minutes);

        return now.toFormatString("HH:mmZ");
    };

    window.JustTime.prototype.toJSON = function() {
        return this.toISOString();
    };

    window.JustTime.fromISO = function(timeIsoString) {
        return new JustTime(Date.fromISO(new JustDate().toISOString()+"T"+timeIsoString));
    };

    return window.JustTime;

})();
