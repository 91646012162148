/**
 * This is the dto for the tasks
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    /*** Imports ***/
    var TaskType = app.model.TaskType;

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.dto.TaskListDto = function TaskListDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id ? parseInt(obj.id) : null;
        this.rotation_type = obj.rotation_type ? TaskType.valueOf(obj.rotation_type) : null;
        this.label = obj.label ? obj.label : null;
        this.active = (obj.active === true || obj.active === false) ? obj.active : null;
        this.created_by = obj.created_by != null
            ? new app.dto.UserListDto(obj.created_by)
            : null;

        this.workplace_name = obj.workplace_name != null
            ? obj.workplace_name
            : null;
        this.assigned_user = obj.assigned_user != null
            ? new app.dto.UserListDto(obj.assigned_user)
            : null;
    };

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.dto.TaskDetailsDto = function TaskDetailsDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.task = obj.task != null ?
            new app.model.Task(obj.task)
            : null;

        this.workplace_name = obj.workplace_name != null ? obj.workplace_name : null;
        this.assigned_user = obj.assigned_user != null ? new app.model.User(obj.assigned_user) : null;
        this.created_by = obj.created_by != null ? new app.model.User(obj.created_by) : null;
        this.assigned_customer = obj.assigned_customer != null ? new app.dto.CustomerDto(obj.assigned_customer) : null;
    };

}(window.app));
