/**
 * This file contains all model used by the task RPC endpoint
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.Task = function Task(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        if (this instanceof app.model.Task) {
            switch (obj.rotation_type) {
            case app.model.TaskType.UNIQUE:
                return new app.model.UniqueTask(json);
            case app.model.TaskType.DAILY:
                return new app.model.DailyTask(json);
            case app.model.TaskType.WEEKLY:
                return new app.model.WeeklyTask(json);
            case app.model.TaskType.MONTHLY:
                return new app.model.MonthlyTask(json);
            }
        }

        this.id             = obj.id != null ? parseInt(obj.id) : null;
        this.active         = (obj.active === true || obj.active === false) ? obj.active : null;
        this.label          = obj.label ? obj.label : null;
        this.description    = obj.description ? obj.description : null;
        this.rotation_type  = obj.rotation_type ? obj.rotation_type : null;
        this.rotation_info  = obj.rotation_info ? obj.rotation_info : null;
        this.assigned_user_id = obj.assigned_user_id ? obj.assigned_user_id : null;
        this.assigned_customer_id = obj.assigned_customer_id ? obj.assigned_customer_id : null;
        this.assigned_workplace_id  = obj.assigned_workplace_id ? obj.assigned_workplace_id : null;
        this.active_since   = obj.active_since ?
            typeof obj.active_since === 'string'
                ? Date.fromISO(obj.active_since)
                : obj.active_since
            : null;
        this.created_by     = obj.created_by ? obj.created_by : null;
        this.email = obj.email ? obj.email : null;
    };

    app.model.Task.newInstance = function(json) {
        switch (json.rotation_type || app.model.TaskType.UNIQUE) {
        case app.model.TaskType.UNIQUE:
            return new app.model.UniqueTask(json);
        case app.model.TaskType.DAILY:
            return new app.model.DailyTask(json);
        case app.model.TaskType.WEEKLY:
            return new app.model.WeeklyTask(json);
        case app.model.TaskType.MONTHLY:
            return new app.model.MonthlyTask(json);
        }
    };

}(window.app));
