/**
 * BusinessDocument detail page.
 *
 * @author Martin Reiterer (martin.reiterer@rise-world.com)
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav         = rise.Navigation;
    var pages       = app.pages;
    var formWidgets = app.widgets.form;
    var NotificationManager = new window.notification.NotificationManager({});

    var textButton          = app.widgets.textButton;
    var editIconButton      = app.widgets.editIconButton;
    var cancelIconButton    = app.widgets.cancelIconButton;
    var saveIconButton      = app.widgets.saveIconButton;
    var commandBox          = app.widgets.commandBox;
    var tabsWidget          = app.widgets.tabsWidget;
    var scrollTableSection  = app.widgets.scrollTableSection;

    /** DAOs **/
    var BusinessDocument     = app.model.BusinessDocument;
    var businessDocumentDao  = app.dao.businessDocumentDao;
    var businessDocumentCategoryDao = app.dao.businessDocumentCategoryDao;


    /** CONSTANTS **/
    var PageType = {
        VIEW: 1,
        EDIT: 2,
        NEW: 3
    };

    var MAX_FILE_SIZE = 30 * 1024 * 1024;  //max file-size is set to 30MB
    var obj;

    /*** Export page ***/
    pages.business_document.detail = app.pagesByPath['/business-document/detail'] = function BusinessDocumentDetail() {

        obj = this;

        this.name = "/business-document/detail";

        this.type = PageType.VIEW;
        this.business_document = null;
        this.assignments = null;
        this.customers = null;
        this.adminView = null;
        this.readConfirmations = null;
        this.categories = null;

        this.goToPage = null;

        var attachIdx = 0;

        this.create = function ($parent, business_document_id, adminView, goToPage) {
            obj.adminView = adminView;
            obj.goToPagePath = goToPage;
            obj.goToPage = app.pagesByPath[goToPage];

            obj.fileContents = [];
            attachIdx = 0;

            if (business_document_id) {
                obj.business_document_id = business_document_id;

                return renderBusinessDocument($parent, PageType.VIEW);
            } else {
                return renderBusinessDocument($parent, PageType.NEW);
            }
        };

        this.destroy = function() {
            // called after page has been destroyed
        };

        function renderBusinessDocument($parent, type) {
            obj.type = type;

            var emptyBusinessDocumentAssignment = {
                customers: [],
                locations: [],
                objects: [],
                workplaces: []
            };

            var _render = function(business_documentAndAssignments, customers, readConfirmations, categories) {
                obj.business_document = business_documentAndAssignments.business_document;
                obj.assignments = business_documentAndAssignments.assignments;
                obj.readConfirmations = readConfirmations;
                obj.categories = categories || [];

                if (customers) {
                    obj.customers = customers;
                }

                $parent.appendElement([
                    renderCommandBox($parent),

                    function ($box) {

                        if (obj.adminView) {

                            var tabs = [
                                {
                                    'id': 'business_document-general-information-tab',
                                    'title': 'Allgemein',
                                    'fnRender': $.proxy(renderGeneralInformation, self)
                                }
                            ];

                            if(app.session.hasPermission(app.model.PermissionTypes.BUSINESS_DOCUMENT_MODIFY)) {
                                tabs.push(
                                    {
                                        'id': 'business_document-read-confirmations-tab',
                                        'title': 'Lesebestätigungen',
                                        'fnRender': $.proxy(renderReadConfirmations, self)
                                    }
                                );
                            }

                            $box.appendElement(tabsWidget(
                                { id: "business_document-details" }, tabs
                            ));
                        } else {
                            $box.appendElement(renderGeneralInformation());
                        }
                    }
                ]);

                $parent.activateValidators({});
            };

            if (app.session.hasPermission(app.model.PermissionTypes.BUSINESS_DOCUMENT_MODIFY) ||
                app.session.getSessionInfo().user.type === app.model.UserType.CUSTOMER) {
                return $.when(
                    obj.type !== PageType.NEW
                        ? businessDocumentDao.getBusinessDocumentAndAssignments(obj.business_document_id)
                        : {
                            "business_document": new BusinessDocument({
                                // business_document_category_id: 1 // TODO remove temporary fix
                            }),
                            "assignments": emptyBusinessDocumentAssignment
                          },
                    app.session.hasPermission(app.model.PermissionTypes.BUSINESS_DOCUMENT_MODIFY)
                        ? businessDocumentDao.getCustomerList()
                        : undefined,
                    obj.type !== PageType.NEW && obj.adminView
                        ? businessDocumentDao.getReadConfirmations(obj.business_document_id)
                        : [],
                    obj.type !== PageType.VIEW ? businessDocumentCategoryDao.findAll({business_document_category_id:1}) : []
                ).done(_render);
            } else {
                return $.when(businessDocumentDao.get(obj.business_document_id),
                              undefined)
                    .done(function(business_document, customers) {
                        _render({
                            "business_document": business_document,
                            "assignments": emptyBusinessDocumentAssignment
                        }, customers);
                    });
            }
        }

        function renderCommandBox($parent) {
            var saved_business_document = new BusinessDocument(obj.business_document);

            function saveButton() {
                return saveIconButton({id: 'save-business_document',
                    click: function () {
                        if ($parent.validate()) {
                            saveBusinessDocument($parent);
                        }
                    }
                });
            }

            function cancelButton(backToOverview) {
                return cancelIconButton({id: 'cancel-edit-business_document',
                    click: function () {
                        if (backToOverview) {
                            nav.goToPage(obj.adminView
                                ? pages.business_document.listall
                                : pages.business_document.list);
                        } else {
                            $.validation.reset();
                            $parent.empty();

                            obj.business_document = saved_business_document;
                            renderBusinessDocument($parent, PageType.VIEW);
                        }
                    }
                });
            }

            function readButton() {
                return textButton({id: 'business_document-read-button',
                    click: function() {
                        businessDocumentDao.markRead(obj.business_document.id)
                            .done(markReadDoneMethod);
                    }
                }, 'Gelesen');
            }


            function editAssignmentButton() {
                return editIconButton({id: 'edit-business_document',
                    click: function () {
                        $parent.empty();
                        renderBusinessDocument($parent, PageType.EDIT);
                    }
                }, 'Zuweisung bearbeiten');
            }

            function markReadDoneMethod() {
                app.showSuccessMessage('Dokument gelesen');
                nav.goToPage(pages.business_document.list);
            }

            function getTitle() {
                switch (obj.type) {
                case PageType.NEW:
                    return 'Neues Dokument';
                case PageType.EDIT:
                    return 'Zuweisung bearbeiten';
                case PageType.VIEW:
                    return 'Dokument: ' + obj.business_document.name;
                }
                return '';
            }

            function backAction() {
                if(obj.goToPage) {
                    return obj.goToPage;
                } else {
                    return obj.adminView
                        ? pages.business_document.listall
                        : pages.business_document.list;
                }
            }

            function getCommandButtons() {
                switch (obj.type) {
                case PageType.VIEW:
                    if (obj.adminView) {
                        return [
                            app.session.hasPermission(app.model.PermissionTypes.BUSINESS_DOCUMENT_MODIFY) ?
                                editAssignmentButton() :
                                undefined
                        ];
                    } else if (!obj.business_document.read_confirmed_at) {
                        return [
                            readButton(),
                            cancelButton(true)
                        ];
                    } else {
                        return;
                    }

                case PageType.EDIT:
                    return ul({}, [
                        cancelButton(),
                        saveButton()
                    ]);

                case PageType.NEW:
                    return ul({}, [
                        cancelButton(true),
                        saveButton()
                    ]);
                }

                return [];
            }

            return commandBox({title:getTitle(),backAction:backAction()},getCommandButtons());
        }

        function saveBusinessDocument($parent) {
            if(obj.business_document.id) {
                businessDocumentDao.update(obj.business_document.id, obj.assignments.customers,
                        obj.assignments.locations, obj.assignments.objects,
                        obj.assignments.workplaces, obj.business_document.business_document_category_id,
                        obj.business_document.customer_only)
                    .done(updateDoneMethod);
            } else {
                // set active flag
                obj.business_document.active = true;

                var toInsert = new BusinessDocument(obj.business_document);

                toInsert.documents = _.filter(obj.business_document.documents, function(d) {
                    return d;
                });

                obj.fileContents = _.filter(obj.fileContents, function(f) {
                    return f;
                });

                businessDocumentDao.insert(toInsert, obj.fileContents, obj.assignments.customers,
                    obj.assignments.locations, obj.assignments.objects, obj.assignments.workplaces)
                    .done(insertDoneMethod);
            }

            function insertDoneMethod(result) {
                obj.business_document_id = result.id;
                $parent.empty();

                renderBusinessDocument ($parent, PageType.VIEW);
                app.showSuccessMessage('Gespeichert');
            }

            function updateDoneMethod(assignments) {
                obj.assignments = assignments;
                $parent.empty();

                renderBusinessDocument ($parent, PageType.VIEW);
                app.showSuccessMessage('Zuordnung aktualisiert');
            }
        }

        /** GENERAL INFORMATION TAB **/

        function renderGeneralInformation() {
            return div({}, [
                obj.type !== PageType.NEW
                    ? renderBusinessDocumentForm(obj.type !== PageType.NEW)
                    : div({ "class":"columns-2" },
                    function ($box) {
                        $box.appendElement(renderBusinessDocumentForm(false));
                        $box.appendElement(renderDocumentUploadForm());
                    }),

                obj.adminView
                    ? renderBusinessDocumentAssignmentForm(obj.type === PageType.VIEW,
                    obj.business_document, obj.customers, obj.assignments)
                    : undefined
            ]);
        }

        function renderBusinessDocumentForm(view) {
            var business_document = obj.business_document;

            return fieldset({id: 'business_document-edit', 'class': ' column'}, [
                legend({}, "Details"),
                ul({"class":"fieldset-content"}, [
                    formWidgets.formItemText({id: 'business_document-name', label: 'Name', required: true,
                        value: business_document.name,
                        change: function () {
                            business_document.name = $(this).val();
                        }
                    }, view),
                    formWidgets.formItemSelect({id: 'business_document-category', label: 'Kategorie',
                        required: true,
                        "source": obj.categories || [],
                        "valueProp": 'name',
                        "value": business_document.category_name,
                        "class": "large-input select-input",
                        "selected": function ($event, category) {
                            business_document.business_document_category_id = category.id;
                            business_document.category_name = category.name;
                        }
                    }, obj.type === PageType.VIEW),
                    formWidgets.formItemTextarea({id: 'business_document-description', label: 'Beschreibung',
                        value: business_document.description,
                        change: function () {
                            business_document.description = $(this).val();
                        }
                    }, view)
                ].concat(renderDownloadLinks(business_document, view)))
            ]);
        }

        function renderDownloadLinks(business_document, view) {
            if (view) {
                return _.map(business_document.documents, function(document) {
                    return li({"class": "fieldset-item"}, [
                        label({"for": "business_document-document-download"}, "Anhang"),
                        span({},
                            form({method: "POST", action: "/document"}, [
                                input({
                                    id: "business_document-document-download",
                                    type: "hidden",
                                    name: "method",
                                    value: "download"
                                }),
                                input({
                                    id: "business_document-document-x-session-id",
                                    type: "hidden",
                                    name: "X-Session-Id",
                                    value: window.app.session.getSessionId()
                                }),
                                input({
                                    id: "business_document-document-id",
                                    type: "hidden",
                                    name: "id",
                                    value: document.data
                                }),
                                a({
                                    id: "business_document-document-download",
                                    click: function() {
                                        $(this).closest("form").submit();
                                    }
                                }, document.filename)
                            ])
                        )
                    ]);
                });
            }
        }

        function checkFileMaxSize(file, fileInput) {
            if (file.size > MAX_FILE_SIZE) {
                $(fileInput).val('');
                app.showErrorMessage("Die max. Dateigröße beträgt 30 MB");
                return false;
            }

            return true;
        }

        function renderDocumentUploadForm() {
            return fieldset({id: 'business_document-document-upload', 'class': 'column'}, [
                legend({}, "Anhang"),
                ul({id: 'attachment-list', "class":"fieldset-content"},
                    _.flatten([
                        createUploadForm(attachIdx),
                        createAddUploadFormButton()
                    ])
                )
            ]);
        }

        function createAddUploadFormButton() {
            return [
                li({id:"add-attachment-button", class:"fieldset-item" }, [
                    a({ title:"Weiteren Anhang hinzufügen",
                            "click": function(e, $elem) {
                                addUploadForm();
                            }},
                        img({ src:"style/images/content/plus.png" })
                    )
                ])
            ];
        }

        function addUploadForm() {
            $("#add-attachment-button").remove();

            attachIdx++;

            var $attachmentList = $("#attachment-list");
            $attachmentList.appendElement(
                _.flatten([
                    createUploadForm(attachIdx),
                    createAddUploadFormButton()
                ])
            );
        }

        function createUploadForm(idx) {
            return [
                li({ "class":"fieldset-item" }, [
                    label({ "for":"business_document-document-upload" }, "Anhang"),
                    span({}, [
                        form({ id: "form-upload"+idx, "class":'file_upload' }, [
                            input({ id: "business_document-document-upload"+idx, type:"file", name: "file",
                                change: function() {
                                    var fileInput = this;

                                    app.removeErrorMessages();

                                    fileReaderUpload(fileInput, idx);
                                }
                            })
                        ])
                    ])
                ]),
                li({ "class":"fieldset-item", "style":"display:none;" }, [
                    label({ "for": "business_document-document-filesize"+idx }, "Dateigröße"),
                    span({ id:"business_document-document-filesize"+idx })
                ]),
                li({ "class":"fieldset-item", "style":"display:none;" }, [
                    label({ "for": "business_document-document-last-modified"+idx }, "Zuletzt verändert"),
                    span({ id:"business_document-document-last-modified"+idx })
                ])
            ];
        }

        function fileReaderUpload(fileInput, idx) {
            var file;

            if (fileInput.files.length > 0 && checkFileMaxSize(fileInput.files[0], fileInput)) {
                file = fileInput.files[0];

                loadFile(file, idx);

                // update file stats
                var size = file.size;
                var units = ['Bytes', 'KB', 'MB'];
                var unit = units[0];

                while (units.indexOf(unit) < units.length && size > 1024) {
                    size /= 1024;
                    unit = units[units.indexOf(unit) + 1];
                }
                $("#business_document-document-filesize"+idx)
                    .html(Math.round(size * 100)/100 + " " + unit)
                    .parent().show();
                $("#business_document-document-last-modified"+idx)
                    .html(file.lastModifiedDate.toFormatString(app.settings.dateTimeFormat))
                    .parent().show();
            } else {
                loadFile(null, idx);

                // update file stats
                $("#business_document-document-filesize"+idx).empty().parent().hide();
                $("#business_document-document-last-modified"+idx).empty().parent().hide();
            }
        }

        function loadFile(file, idx) {
            var reader = new FileReader();

            reader.onload =  function (e) {
                obj.fileContents[idx] = e.target.result;

                var document = new app.model.Document();
                document.filename = file.name;
                obj.business_document.documents[idx] = document;
            };

            if (file) {
                // read file content as base64 string
                reader.readAsDataURL(file);
            } else {
                delete obj.fileContents[idx];
                delete obj.business_document.documents[idx];
            }
        }

        /** READ CONFIRMATIONS TAB **/

        function renderReadConfirmations() {
            return scrollTableSection({
                filter: true,
                id: "read-confirmations",
                elements: obj.readConfirmations,
                paging: true,
                columns: [
                    {   sTitle: "Nachname",
                        sAttrName: "user_last_name"
                    },
                    {   sTitle: "Vorname",
                        sAttrName: "user_first_name"
                    },
                    {   sTitle: "Benutzername",
                        sAttrName: "user_name"
                    },
                    {   sTitle: "Zuerst gesichtet am",
                        sAttrName: "business_document_read_first_at",
                        mRender: function(mData, type, obj) {
                            if (obj.business_document_read_first_at) {
                                return obj.business_document_read_first_at.toFormatString(app.settings.dateTimeFormat);
                            } else {
                                return '-';
                            }
                        }
                    },
                    {   sTitle: "Als gelesen markiert",
                        sAttrName: "business_document_read_confirmed_at",
                        mRender: function(mData, type, obj) {
                            if (obj.business_document_read_confirmed_at) {
                                return obj.business_document_read_confirmed_at.toFormatString(app.settings.dateTimeFormat);
                            } else {
                                return '-';
                            }
                        }
                    }
                ],
                filters: {
                    business_document_read_first_at: {
                        labels: {
                            on: "Gesichtet",
                            off: "Nicht gesichtet"
                        },
                        values: {
                            on: "^(?!-)(.*)$",
                            off: "^-$"
                        }
                    },
                    business_document_read_confirmed_at: {
                        labels: {
                            on: "Gelesen",
                            off: "Nicht gelesen"
                        },
                        values: {
                            on: "^(?!-)(.*)$",
                            off: "^-$"
                        }
                    }
                },
                height: "100%",
                click: displayUser
            });
        }
    };

    function renderBusinessDocumentAssignmentForm(view, business_document, customers, assignments) {
        return fieldset({id: 'business_document-assignment-edit', 'class':' column'}, [
            legend({}, "Sichtbarkeit festlegen"),
            ul({"class":"fieldset-content", create(e) {
                    $(e.target).find("label[for='customer-select']").addClass('required');
                }}, [
                formWidgets.formItemCheckbox({id: 'business_document-customer_only', label: 'nur für Kunden',
                    checked: business_document.customer_only,
                    change: function () {
                        business_document.customer_only = $(this).prop("checked");
                    }}, obj.type === PageType.VIEW),
                formWidgets.formHierarchicalWorkplaceSelect({
                    id: "customer-select",
                    customers: customers,
                    assignments: assignments,
                    change: function(level, instances) {
                        assignments[{
                            0: 'customers',
                            1: 'locations',
                            2: 'objects',
                            3: 'workplaces'
                        }[level]] = instances;
                    }
                }, view)
            ])
        ]);
    }

    function displayUser(user) {
        if(app.session.hasPermission(app.model.PermissionTypes.EMPLOYEE_MODIFY)) {
            nav.goToPage(pages.user.detail, [user.user_id, obj.goToPage ? obj.goToPagePath : '/business-document/listall']);
        }
    }

}) (window.rise, window.app);
