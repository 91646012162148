/**
 * This file contains all model used by the ticket RPC endpoint
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.Workplace = function Workplace(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id != null ? parseInt(obj.id) : null;
        this.located_in_object_id = obj.located_in_object_id != null ? parseInt(obj.located_in_object_id) : null;

        this.active = (obj.active === true || obj.active === false) ? obj.active : null;
        this.name = obj.name ? obj.name : null;
        this.send_emails =  obj.send_emails != null ? obj.send_emails : null;

        this.dms_active = (obj.dms_active === true || obj.dms_active === false) ? obj.dms_active : null;

        this.event_types = obj.event_types || [];
    };

    app.model.DMSInterval = function DMSInterval(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id != null ? obj.id : null;
        this.period = obj.period != null ? obj.period : null;
        this.weekdays = obj.weekdays != null ? obj.weekdays : null;

        this.time_from = obj.time_from ?
                typeof obj.time_from === 'string'
            ? new window.JustTime.fromISO(obj.time_from)
            : obj.time_from
            : null;

        this.time_to = obj.time_to ?
                typeof obj.time_to === 'string'
            ? new window.JustTime.fromISO(obj.time_to)
            : obj.time_to
            : null;
    };

}(window.app));
