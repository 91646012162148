import keycode from "keycode";

/**
 * Dialogs for rendering selections.
 *
 * @module app.widgets
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    /*** EXPORTS ***/
    app.widgets.SelectDialog = SelectDialog;
    app.widgets.EmailDialog = EmailDialog;
    app.widgets.EditDialog = EditDialog;
    app.widgets.ConfirmationDialog = ConfirmationDialog;

    /*** IMPORTS ***/
    var initColumns     = app.widgets.initColumns;
    var textButton      = app.widgets.textButton;
    var form            = app.widgets.form;

    /*** SELECT DIALOG ***/

    function SelectDialog(options) {

        if (!options.parent) {
            throw "No parent element provided!";
        }

        this.options = $.extend({
            id: "selector",
            selected: null,
            listPage: null,
            pageParams: undefined,
            data: [],
            columns: [],
            width: 800,
            title: "Benutzer wählen"
        }, options);

        // init page load deferred
        this.pageLoadDeferred = null;

        this._renderDialog();
    }

    SelectDialog.prototype.open = function() {
        var self = this;

        if (self.pageLoadDeferred) {
            self.pageLoadDeferred
                .done(function() {
                    setTimeout(function() {
                        self.$dialog.dialog("open");
                    }, 100);

                });
        } else {
            self.$dialog.dialog("open");
        }
    };

    SelectDialog.prototype.close = function() {
        this.$dialog.dialog("close");
    };

    SelectDialog.prototype._renderSelectionTable = function() {
        var self = this;

        var $table = this.$dialogContentWrapper.appendElement(
            div({ "class": "whiteboard" }, [
                table({id:this.options.id, "class":"display", style:"width:100%"})
            ])
        ).find('table');

        $table.dataTable({

            "bAutoWidth": false,

            /* columns of the datatable */
            "aoColumns": initColumns(this.options.columns).aoColumns,

            "aaData": this.options.data,

            "aaSorting": [[0, "asc"]],

            "bLengthChange": false,

            /* number of rows to display on a single page */
            "iDisplayLength": 10,

            "sPaginationType": "full_numbers",

            "bStateSave": false,

            "fnDrawCallback": function(oSettings) {
                $(oSettings.nTable)
                    .find("th.active")
                    .addClass("center");

                $(oSettings.nTableWrapper)
                    .find(".dataTables_filter input")
                    .attr("placeholder", "Name");

                $(oSettings.nTable).find("td").mouseenter(function() {
                    $(this).parent()
                        .children()
                        .each(function() {
                            $(this).toggleClass('highlight-row');
                        });
                }).mouseleave(function() {
                    $(this).parent()
                        .children()
                        .each(function() {
                            $(this).toggleClass('highlight-row');
                        });
                }).not('.active').click(function() {
                    var oTable = oSettings.oInstance;
                    var aPos = oTable.fnGetPosition(this);

                    // get the data array for this row
                    var oData = oTable.fnGetData(aPos[0]);

                    self._selectItem(oData, aPos[0]);
                });
            }
        });

        return $table;
    };

    SelectDialog.prototype._renderSelectionListPage = function() {
        var self = this,
            listPage = new self.options.listPage(_.extend({
                showCommandBox: false,
                onSelect: $.proxy(self._selectItem, self),
                prefix: 'select-dialog'
            }, self.options.pageParams || {}));

        // render list page in dialog content wrapper
        self.pageLoadDeferred = listPage.create(self.$dialogContentWrapper);
    };

    SelectDialog.prototype._renderDialog = function () {
        var self = this;

        // add dialog element
        this.$dialog = this.options.parent.appendElement(
            div({ id:this.options.id + "-wrapper" }, [
                div({ "class": "dialog-content" }),
                div({ "class": "buttonpane" }, [
                    div({ "class":"left-column" },
                        textButton({ "click": function() {
                            self.close();
                        }}, "Abbrechen")
                    ),
                    div({ "class":"center-column" }),
                    div({ "class":"right-column" })
                ])
            ]));
        this.$dialogContentWrapper = this.$dialog.find('.dialog-content');

        // render table widget / selection page
        if (this.options.listPage) {
            this.listPage = this._renderSelectionListPage();
        } else {
            this.$table = this._renderSelectionTable();
        }

        // init dialog
        this.$dialog.dialog({
            modal: true,
            autoOpen: false,
            width: self.options.width,
            title: self.options.title
        });
    };


    SelectDialog.prototype._selectItem = function(data, pos) {
        var self = this;

        if (self.options.selected) {
            self.options.selected(data, pos);
        }
        self.close();
    };

    SelectDialog.prototype.destroy = function() {
        $('#'+this.options.id + "-wrapper").remove();

        try {
            this.$dialog.dialog("destroy");
        } catch (e) { }
    };

    /*** EMAIL DIALOG ***/

    function EmailDialog(options) {
        if (!options.parent) {
            throw "No parent element provided!";
        }

        this.options = $.extend({
            id: "email-dialog",
            click: null
        }, options);

        this._renderDialog();
    }

    EmailDialog.prototype.open = function() {
        this.$dialog.dialog("open");
    };

    EmailDialog.prototype.close = function() {
        this.$dialog.dialog("close").remove();
    };

    EmailDialog.prototype._renderDialog = function () {
        var self = this;

        this.options.parent.appendElement(
            div({ id:this.options.id + "-wrapper" }, [
                fieldset({}, [
                    ul({'class': 'fieldset-content'}, [
                        form.formItemText({id: this.options.id + '-emails', label: 'Email-Adresse(n)',
                            value: null,
                            create: function() {
                                $(this).tokenlist({
                                    tokenDelimiter: [keycode('space'), keycode(',')],
                                    split: /\s*;\s*/,
                                    join: ';',
                                    acceptCustomInput: true,
                                    validate: function(labelValue) {
                                        var val = labelValue.value;
                                        if(val !== undefined) {
                                            var pattern = new RegExp(/^([a-zA-Z0-9._\\+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})?$/);
                                            return pattern.test(val);
                                        }

                                        return true;
                                    }
                                });
                            }
                        })
                    ])
                ]),
                div({ "class": "buttonpane" },
                    div({ "class": "three-column" }, [
                        div({ "class":"left-column" },
                            textButton({ "click": function() {
                                self.close();
                            }}, "Abbrechen")
                        ),
                        div({ "class":"center-column" }),
                        div({ "class":"right-column" },
                            textButton({"click": function() {
                                if (self.options.click) {
                                    self.options.click($("#" + self.options.id + '-emails').tokenlist("value"));
                                }
                                self.close();
                            }}, "OK")
                        )
                    ])
                )
            ])
        );

        // init dialog
        this.$dialog = $("#" + this.options.id + "-wrapper").first();
        this.$dialog.dialog({
            modal: true,
            autoOpen: false,
            width: 600,
            close: function(e) {
                $(e.target).dialog("close").remove();
            },
            title:"Email versenden"
        });

    };


    /*** CONFIRMATION DIALOG ***/

    function ConfirmationDialog(options) {
        if (!options.parent) {
            throw "No parent element provided!";
        }

        this.options = $.extend({
            id: "confirmation-dialog",
            click: null
        }, options);

        this._renderDialog();
    }

    ConfirmationDialog.prototype.open = function() {
        this.$dialog.dialog("open");
    };

    ConfirmationDialog.prototype.close = function() {
        this.$dialog.dialog("close").remove();
    };

    ConfirmationDialog.prototype._renderDialog = function () {
        var self = this;

        this.options.parent.appendElement(
            div({ id:this.options.id + "-wrapper" }, [
                fieldset({}, [
                    ul({'class': 'fieldset-content'}, [
                        form.formItemText({id: this.options.id + '-confirmation', value: this.options.text}, true)
                    ])
                ]),
                div({ "class": "buttonpane" },
                    div({ "class": "three-column" }, [
                        div({ "class":"left-column" },
                            textButton({ "click": function() {
                                self.close();
                            }}, "Nein")
                        ),
                        div({ "class":"center-column" }),
                        div({ "class":"right-column" },
                            textButton({"click": function() {
                                if (self.options.click) {
                                    self.options.click();
                                }
                                self.close();
                            }}, "Ja")
                        )
                    ])
                )
            ])
        );

        // init dialog
        this.$dialog = $("#" + this.options.id + "-wrapper").first();
        this.$dialog.dialog({
            modal: true,
            autoOpen: false,
            width: 450,
            close: function(e) {
                $(e.target).dialog("close").remove();
            },
            title:this.options.title
        });

    };

    /*** EDIT/CREATE DIALOG (category) ***/

    function EditDialog(options) {
        if (!options.parent) {
            throw "No parent element provided!";
        }

        if (!options.model) {
            throw "No model provided!";
        }

        this.options = $.extend({
            id: "edit-dialog",
            click: null
        }, options);

        if (!this.options.model.id) {
            this.options.isNew = true;
        }

        this._renderDialog();
    }

    EditDialog.prototype.open = function() {
        this.$dialog.dialog("open");
    };

    EditDialog.prototype.close = function() {
        this.$dialog.dialog("close").remove();
    };

    EditDialog.prototype._renderDialog = function () {
        var self = this;

        this.options.parent.appendElement(
            div({ id:this.options.id + "-wrapper" }, [
                fieldset({}, [
                    ul({'class': 'fieldset-content'}, [
                        form.formItemText({id: self.options.id + '-edit', label: 'Kategoriename',
                            value: self.options.model.name, change: function(e) {
                                self.options.model.name = $(e.target).val();
                            }
                        })
                    ])
                ]),
                div({ "class": "buttonpane" },
                    div({ "class": "three-column" }, [
                        div({ "class":"left-column" },
                            textButton({ "click": function() {
                                self.close();
                            }}, "Abbrechen")
                        ),
                        div({ "class":"center-column" }),
                        div({ "class":"right-column" },
                            textButton({"click": function() {
                                if ($.trim(self.options.model.name)) {
                                    if (self.options.click) {
                                        self.options.click(self.options.model);
                                    }
                                    self.close();
                                }
                            }}, (this.options.isNew ? "Anlegen" : "Speichern"))
                        )
                    ])
                )
            ])
        );

        // init dialog
        this.$dialog = $("#" + this.options.id + "-wrapper").first();
        this.$dialog.dialog({
            modal: true,
            autoOpen: false,
            width: 600,
            close: function(e) {
                $(e.target).dialog("close").remove();
            },
            title:(this.options.isNew ? "Kategorie anlegen" : "Kategorie bearbeiten")
        });

    };

})(window.app);
