/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.UserTag = function UserTag(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id != null ? obj.id : null;
        this.employee_number = obj.employee_number != null ? obj.employee_number : null;
        this.nfc_serial = obj.nfc_serial != null ? obj.nfc_serial : null;
        this.active = obj.active != null ? obj.active : null;

        if (obj.hasOwnProperty('employee_name')) {
            this.employee_name = obj.employee_name;
        }

        /**
         * set on server side
         * @type {JustDate}
         */
        this.created_at = obj.created_at ?
            typeof obj.created_at === 'string' ?
                Date.fromISO(obj.created_at)
                : obj.created_at
            : null;

        this.last_programmed_at =  obj.last_programmed_at ?
            typeof obj.last_programmed_at === 'string' ?
                Date.fromISO(obj.last_programmed_at)
                : obj.last_programmed_at
            : null;
    };

}(window.app));
