/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.User = function User(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        if (this instanceof app.model.User) {
            switch (obj.type) {
                case app.model.UserType.CUSTOMER:
                    return new app.model.CustomerUser(json);
                case app.model.UserType.EMPLOYEE:
                    return new app.model.Employee(json);

            }
        }

        this.id = obj.id != null ? parseInt(obj.id) : null;
        this.type = app.model.UserType.valueOf(obj.type);

        this.roles = _.map(obj.roles || [], function(role) {
            return new app.model.Role(role);
        });

        this.active =           (obj.active === true || obj.active === false) ? obj.active : null;
        this.first_name =       obj.first_name != null ? obj.first_name : null;
        this.last_name =        obj.last_name != null ? obj.last_name : null;
        this.email =            obj.email != null ? obj.email : null;
        this.username =         obj.username != null ? obj.username : null;
        this.date_of_birth =    obj.date_of_birth ?
            typeof obj.date_of_birth === 'string' ?
                new JustDate(obj.date_of_birth.replace(/-/g,'/'))
                : obj.date_of_birth
            : null;

    };

    app.model.UserType = {
        CUSTOMER: 'CUSTOMER',
        EMPLOYEE: 'EMPLOYEE',

        valueOf: function(value) {
            return this.hasOwnProperty(value) ? value : null;
        },

        values: function() {
            return _.filter(_.values(this), function(el) { return !_.isFunction(el); });
        }
    };

}(window.app));
