/**
 * Displays a list of all sessions visible for the current user.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav         = rise.Navigation;
    var clowFilter  = app.widgets.clowFilter;
    var permissions = app.model.PermissionTypes;
    var form        = app.widgets.form;

    /** DAOs **/
    var sessionDao  = app.dao.sessionDao;
    var workplaceDao = app.dao.workplaceDao;

    /*** GLOBALS ***/
    var pages   = app.pages;

    /*** Export Pages ***/
    pages.session.list = app.pagesByPath['/session/list'] = SessionList;

    /*** Set permission ***/
    SessionList.permissions = [
        permissions.SESSION_MODIFY
    ];

    var defaultSettings = {
        prefix: "session",
        labels: {
            pageHeader: "Angemeldete Benutzer"
        }
    };

    function SessionList() {

        var filter = { filter_expression: '', type: null };

        var self = app.widgets.ListPage.call(this, $.extend(true, {}, defaultSettings, {
            pageName: '/session/list',
            rpc: function() {
                return [ sessionDao.listAll(), workplaceDao.listAll() ];
            },
            refresh: function () {
                filterSessions(filter);
            }
        }));

        this.create = (function(create) {
            return function() {
                if (create && create.apply) {
                    create.apply(self, arguments);
                }
            };
        }(this.create));

        this.updateWorkplaces = function(workplaces) {
            self.workplacesById = {};

            _.each(workplaces, function(workplace) {
                self.workplacesById[workplace.id] = workplace;
            });
        };

        this.prepareTable = function($parent, sessions, workplaces) {
            var columns = getListTableColumns();
            var settings = getTableSettings();

            app.widgets.notificationWidget.refresh();

            // update workplace cache
            self.updateWorkplaces(workplaces);

            $("#placeholder-filters").prependElement(
                renderFilter($parent, filter));

            this.renderTable(sessions, columns, settings);
        };

        this.savePageState = function() { };

        this.loadPageState = function($parent, sessions, workplaces) {
            app.widgets.notificationWidget.refresh();

            // update workplace cache
            self.updateWorkplaces(workplaces);

            this.renderTableUpdate(sessions);
        };

        this.showDetailsPage = function(session) {
            showDetailsPage(session, false);
        };

        function getListTableColumns() {
            return [
                {
                    sTitle: "Benutzername",
                    sAttrName: "user.username"
                },
                {
                    sTitle: "Name",
                    sAttrName: "user.last_name",
                    mRender: function(mData, type, obj) {
                        return sprintf("%s %s", obj.user.first_name, obj.user.last_name);
                    }
                },
                {
                    sTitle: "Begonnen am",
                    sAttrName: "created_at",
                    sWidth: "120px",
                    mRender: function(mData, type, obj) {
                        if (type === "display") {
                            return obj.created_at.toFormatString(app.settings.dateTimeFormat);
                        } else {
                            return obj.created_at;
                        }
                    }
                },
                {
                    sTitle: "Begonnen vor",
                    sAttrName: "created_at",
                    sWidth: "120px",
                    mRender: function(mData, type, obj) {
                        return obj.created_at.difference(new Date, "HH Std. mm Min.")
                    }
                },
                {
                    sTitle: "Letzter Zugriff vor",
                    sAttrName: "created_at",
                    sWidth: "120px",
                    mRender: function(mData, type, obj) {
                        return obj.last_accessed_at.difference(new Date, "HH Std. mm Min.")
                    }
                },
                {
                    sTitle: "Schicht",
                    sAttrName: "shift",
                    sWidth: "800px",
                    mRender: function(mData, type, obj) {
                        if (obj.shift) {
                            return sprintf("%s > %s > %s > %s (Startzeitpunkt: %s)",
                                self.workplacesById[obj.shift.workplace_id].customer_name,
                                self.workplacesById[obj.shift.workplace_id].location_name,
                                self.workplacesById[obj.shift.workplace_id].object_name,
                                self.workplacesById[obj.shift.workplace_id].workplace_name,
                                obj.shift.started.toFormatString(app.settings.dateTimeFormat));
                        } else {
                            return "-";
                        }
                    }
                },
                {
                    sTitle: "Typ",
                    sAttrName: "type",
                    sWidth: "50px"
                }
            ];
        }

        function renderFilter($parent, filter) {
            /* jshint indent: false */

            return div({ id: 'user-tag-filter-form', 'class': 'whiteboard' }, [
                legend({}),
                ul({ "class":"inline-list title-row-highlight" }, [
                    form.formItemText({id: 'user-tag-filter-search', label: 'Benutzername', required: false,
                        name: "filter_expression",
                        keyup: function () {
                            filter.filter_expression = $(this).val();
                        },
                        keypress: function (e) {
                            var keyCode = e.which;
                            if (keyCode === 13){
                                filterSessions(filter);
                                return false;
                            }
                        },
                        change: function () {
                            filterSessions(filter);
                        }
                    }),

                    createSessionTypeSelector($parent, filter)
                ])
            ]);
        }

        function createSessionTypeSelector($parent, datatable) {
            var stateTypes = [
                { id: 'ALL', name: 'Alle', value: undefined },
                { id: 'APP', name: 'Tracker App (APP)', value: 'APP' },
                { id: 'WEB', name: 'Elektronisches Wachbuch (WEB)', value: 'WEB' }
            ];

            return form.formItemSelect({id: 'filter-activated', label: 'Typ', required: false,
                source: stateTypes, valueProp: 'name',
                value: stateTypes[0],
                sort: false,
                selected: function (e, stateType) {
                    if (filter.type !== stateType.value) {
                        filter.type = stateType.value;
                        filterSessions(filter);
                    }
                }
            });
        }

        function filterSessions(filter) {
            sessionDao.listAll({filter_params: filter})
                .done(function(tags) {
                    self.renderTableUpdate(tags);
                });
        }
    }

    function showDetailsPage(session, adminView) {
        nav.goToPage(pages.user.detail, [session.user.id, '/session/list']);
    }

    function getTableSettings() {
        return { };
    }

})(window.rise, window.app);
