import { ReportScheduleListPage } from 'app/report-schedule/pages/list-page/ReportScheduleListPage';
import { TourReportPage } from 'app/tour-report/pages/TourReportPage';

(function(app) {
    "use strict";

    /** EXPORT **/
    app.menu.main = function() {
        var pages = app.pages;
        var menuWidget = app.widgets.menu;

        return menuWidget.create([
            menuWidget.tab({ id:"shift", label: 'Dienst' },[
                { id:"shift-check", label: 'Schicht', page: pages.shift.check },
                { id:"business_document-list", label: 'Dokumente', page: pages.business_document.list },
                { id:"business_document-listall", "class": "separator", label: 'Dokumente verwalten', page: pages.business_document.listall }
            ]),
            menuWidget.tab({ id:"logbook", label: 'Logbuch' }, [
                { id:"logbook-entries-list", label: 'Logbuch ansehen', page: pages.logbook.entries.list },
                { id:"logbook-hours-list", label: 'Arbeitsstunden ansehen', page: pages.logbook.hours.list },
                { id:"logbook-create", label: 'Ereignis hinzufügen', page: pages.logbook.create },
                { id:"event-type-list", "class": "separator", label: 'Ereignistypen verwalten', page: pages.event_type.list }
            ]),
            menuWidget.tab({ id:"task", label: 'Aufgaben' }, [
                { id:"task-list-open", label: 'Offene Aufgaben', page: pages.logbook.task.list.open },
                { id:"task-list-finished", label: 'Abgeschlossene Aufgaben', page: pages.logbook.task.list.finished },
                { id:"task-list", "class": "separator", label: 'Aufgaben verwalten', page: pages.task.list }
            ]),
            menuWidget.tab({ id:"tour", label: 'Rundgänge' }, [
                { id:"tour-run-list-open", label: 'Gestartete Rundgänge', page: pages.tour_run.list.open },
                { id:"tour-run-list-finished", label: 'Beendete Rundgänge', page: pages.tour_run.list.finished },
                { id:"tour-report", label: 'Rundgang-Report', page: TourReportPage },
                { id:"tour-area-report", label: 'Revier-Report', page: pages.report.tour_area },
                { id:"tour-list", "class": "separator", label: 'Rundgänge', page: pages.tour.list },
                { id:"tag-list", "class": "separator", label: 'Kontrollstellen', page: pages.tag.list },
                { id:"user-tag-list", "class": "separator", label: 'Mitarbeiterkarten', page: pages.user_tag.list },
                { id:"tour-area", "class": "separator", label: 'Reviere', page: pages.tour_area.list }
            ]),
            menuWidget.tab({ id:"reports", label: 'Berichte' }, [
                { id:"report-schedules", label: 'Automatischer Berichtversand', page: ReportScheduleListPage },
            ]),
            menuWidget.tab({ id:"settings", label: 'Einstellungen' }, [
                { id:"customer-manager", label: 'Kundenmanager', page: pages.customer.manager },
                { id:"role-list", label: 'Gruppen', page: pages.role.list },
                { id:"user-list", label: 'Benutzer', page: pages.user.list },
                { id:"session-list", label: 'Sessions', page: pages.session.list },
                { id:"settings-password ", label: 'Passwort ändern', page: pages.settings.password }
            ])
        ]);
    };

})(window.app);
