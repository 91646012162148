/**
 * Data Access Object for the tag endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.tag");

    /*** Import ***/
    var Tag = app.model.Tag;

    /*** Export Dao ***/
    app.dao.tagDao = new TagDao();

    /**
     * @constructor
     */
    function TagDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.Tag, new Rpc('/rpc/tag'));
    }

    // extend the parent Dao prototype
    $.extend(TagDao.prototype, Object.create(rise.Dao.prototype));

    TagDao.prototype.findAll = function(params) {
        var deferred = new $.Deferred();

        this.rpc.invoke('find_all', params)
            .done(function(result) {
                var list = {
                    'totalCount': result.total_count,
                    'filteredCount': result.filtered_count
                };
                list.rows = _.map(result.rows, function(e) {return new Tag(e);});
                deferred.resolve(list);
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();

    };

    TagDao.prototype.deleteProgram = function(tagId) {
        var deferred = new $.Deferred();

        this.rpc.invoke('delete_program_on_tag',
            { tag_id:tagId })
            .done(function(json) {
                var result = new Tag(json);
                deferred.resolve(result);
            })
            .fail(function(error) {
                log.error('Deleting program on the tag failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TagDao.prototype.deactivate = function(tagId) {
        var deferred = new $.Deferred();

        this.rpc.invoke('deactivate',
            { tag_id:tagId })
            .done(function(json) {
                var result = new Tag(json);
                deferred.resolve(result);
            })
            .fail(function(error) {
                log.error('Deactivating the tag failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TagDao.prototype.updateTagCoordinates = function(tagId, longitude, latitude) {
        var deferred = new $.Deferred();

        this.rpc.invoke('update_tag_coordinates',
            {
                tag_id:tagId,
                longitude: longitude,
                latitude: latitude
            })
            .done(function(json) {
                var result = new Tag(json);
                deferred.resolve(result);
            })
            .fail(function(error) {
                log.error('Deactivating the tag failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };


})(window.rise, window.app);
