/**
 * Messages (errors and warnings) shown during RPC handling.
 *
 * @module app.i18n
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    app.i18n.rpcMessages = {

        "-1": { message: "A generic error occurred" },

        // JSON-RPC ERROR CODES
        "-401": { title: 'Unautorisiert',
            message: "Ihr Username oder Passwort ist nicht korrekt." },
        "-403": { title: "Forbidden",
            message: "You are not authorized to access this resource." },
        "-405": { title: "Request Failed",
            message: "The requested method is missing or unknown." },
        "-415": { title: "Invalid Request",
            message: "The request content type is missing or wrong." },
        "-422": { title: "Invalid Request",
            message: "The JSON-RPC request is invalid." },
        "-500": { title: "Interner Fehler",
            message: "Ein serverseitiger Fehler ist aufgetreten." },

        "-400":   {
            title: "Validierungsfehler",
            message: "Bitte überprüfen Sie Ihre Daten!",
            validation_messages: {
                // Shift
                "overlapping_shift": "Überlappende Schicht.",
                "shift_already_running": "Sie haben bereits eine Schicht gestartet.",
                "no_shift_started": "Diese Aktion erfordert eine gestartete Schicht.",

                // User / Role
                "invalid_parameter_old_password": "Altes Passwort ist nicht korrekt.", // NOSONAR
                "invalid_parameter_new_password": "Das neue Passwort muss sich vom alten Passwort unterscheiden.", // NOSONAR
                "username_in_use": "Der Benutzername ist bereits in Verwendung.",

                // Tour
                "duplicate_tour_name": "Der von Ihnen gewählte Name wird bereits für einen anderen Rundgang verwendet. Bitte definieren Sie einen anderen Namen" ,
                "duplicate_role_name": "Der von Ihnen gewählte Name wird bereits für eine andere Gruppe verwendet. Bitte definieren Sie einen anderen Namen",
                "duplicate_tag_name": "Der von Ihnen gewählte Name wird bereits für einen anderen Tag verwendet. Bitte definieren Sie einen anderen Namen",
                "employee_number_in_use": "Die Mitarbeiternummer ist in Verwendung und kann nicht geändert werden",
                "tour_workplace_assignment_for_non_assigned_customer": "Es wurde die Zuweisung für mindestens einen Kunden aufgehoben der noch Zuweisungen auf Arbeitsplatzebene definiert. Bitte heben Sie zuerst die entsprechenden Arbeitsplatzzuweisungen auf",
                "tour_to_workplace_assign_without_customer_assign": "Dem Arbeitsplatz wurde mindestens ein Rundgang zugeordnet, der nicht für diesen Kunden verfügbar ist. Bitte ordnen Sie den entsprechenden Rundgang zuerst dem Kunden zu.",
                "duplicate_tour_area_tour_assignment": "Dem Revier wurde die zeitliche Einstellung eines Rundgangs mehrfach zugeordnet.",

                // Tasks
                "future_entry_already_exists": "Für diese Aufgabe wurde bereits ein Logbuch-Eintrag erzeugt.",
                "unique_task_defined_in_past": "Der Erfüllungszeitpunkt dieser Aufgabe liegt in der Vergangenheit.",
                "task_not_active": "Die gewählte Aufgabe ist inaktiv.",

                // Event Types
                "event_type_already_finalized": "Der Ereignistyp wurde bereits abgeschlossen. Änderungen in der Zuordnung sind nicht mehr möglich.",
                "missing_event_type_assignments": "Der Ereignistyp wurde keinem Kunden, Standort, Objekt oder Arbeitsplatz zugeordnet."
            }
        },

        // CUSTOM WARNING MESSAGES
        "-207": {
            title: "Warning",
            message: "",
            validation_messages: {
            }
        }
    };

}(window.app));
