/**
 * Page for starting a shift
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import { PermissionType } from 'app/PermissionType';

(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav         = rise.Navigation;

    var textButton  = app.widgets.textButton;
    var commandBox = app.widgets.commandBox;

    /** DAOs **/
    var Shift = app.model.Shift;
    var shiftDao = app.dao.shiftDao;
    var customerDao = app.dao.customerDao;

    /*** GLOBALS ***/
    var pages   = app.pages;
    var form    = app.widgets.form;

    /*** Export page ***/
    pages.shift.begin = app.pagesByPath['/shift/begin'] = ShiftBegin;

    /*** Set permission ***/
    ShiftBegin.permissions = [PermissionType.SHIFT_START_END_OWNED];

    function ShiftBegin() {
        var obj = this;
        this.name = "/shift/begin";

        this.customers = null;

        this.shift = new Shift({employee_id: app.session.getSessionInfo().user.id});
        this.workplaces = null;

        this.create = function ($parent) {
            return customerDao.listAll(true)
                .done(function(customers) {

                    obj.customers = customers;

                    if(customers.length > 0) {
                        renderShiftBegin($parent);
                    } else {
                        app.showErrorMessage("No workplaces have been found");
                    }
                });
        };

        this.destroy = function() {
            // called after page has been destroyed
        };

        function renderShiftBegin($parent) {

            $parent.appendElement([
                commandBox({title:"Dienst"}),

                renderShiftBeginForm(obj.shift, obj.customers, onWorkplaceChanged),
                div({ "class":"action-box bottom" },
                    ul({},
                        li({}, textButton({id: 'start-shift', click: function() {
                            if ($parent.validate()) {
                                return startShift(obj.shift);
                            }
                        }}, 'Schicht starten'))
                    )
                )
            ]);

            $.validation.addValidators({
                '.valid-shift-start-not-in-future': [
                    function(e) {
                        var shiftStart = $(e.target).datepicker('getDate');
                        var dateBoundary =  new Date();
                        dateBoundary.setMinutes(dateBoundary.getMinutes() + 30);

                        return shiftStart <= dateBoundary;
                    },
                    'Schichtbeginn liegt mehr als 30 Minuten in der Zukunft',
                    'change'
                ]
            });

            $parent.activateValidators();
        }

        function onWorkplaceChanged(hierarchy, workplace) {
            if (hierarchy === 3) {
                obj.shift.workplace_id = workplace;
            } else {
                obj.shift.workplace_id = null;
            }
        }

    }

    function renderShiftBeginForm(shift, customers, onChange) {
        return fieldset({id: 'shift-edit'}, [
            legend(),
            ul({'class': 'fieldset-content'}, [
                form.formItemWorkplaceSelect({
                    id: "workplace-select",
                    customers: customers,
                    change: onChange,
                    required: true,
                    attributeNameWorkplaces: 'workplaces',
                }),
                form.formItemDateTime({id: 'shift-started', label: 'Beginn', required: true,
                    date: shift.started || new Date(),
                    validation: 'valid-shift-start-not-in-future',
                    change: function() {
                        shift.setStartedRounded($(this).datepicker('getDate'));
                    }
                })
            ])
        ]);
    }


    function startShift(shift) {
        var actionDeferred = new $.Deferred();

        // save shift in backend
        shiftDao.beginShift(shift)
            .done(function(shift) {
                app.session.refreshSessionInfo().done(function() {
                    app.widgets.notificationWidget.refresh();
                    nav.goToPage(pages.shift.end, [shift]);

                    app.showSuccessMessage('Schicht gestartet.');
                    actionDeferred.resolve();
                });
            })
            .fail(function() {
                actionDeferred.resolve();
            });

        return actionDeferred.promise();
    }

})(window.rise, window.app);
