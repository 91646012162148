import React, { Component } from 'react';
import { AbstractLegacyPage } from 'app/AbstractLegacyPage';

export class PermissionDeniedPage extends Component implements AbstractLegacyPage {
    // eslint-disable-next-line  react/no-unused-class-component-methods
    name = '/permission-denied';

    render() {
        return <h1>Zugriff verweigert</h1>;
    }
}
