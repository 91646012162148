/**
 * This file contains the model used for the session handling
 * of the application.
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    app.model.SessionType = {
        WEB: 'WEB',
        APP: 'APP',

        valueOf: function(value) {
            return this.hasOwnProperty(value) ? value : null;
        },

        values: function() {
            return _.filter(_.values(this), function(el) { return !_.isFunction(el); });
        }
    };

    /**
     * Session info object.
     * @param {*} [json] JSON object.
     * @constructor
     */
    app.model.Session = function Session(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id                 = obj.id || null;
        this.created_at         = new Date(obj.ts_created);
        this.last_accessed_at   = new Date(obj.last_accessed_at);
        this.type               = app.model.SessionType.valueOf(obj.type);
        this.info               = obj.info;
    };

}(window.app));
