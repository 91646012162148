import React, { useCallback, useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import {
    formatDate, PLOTLY_DEFAULT_CONFIG, PLOTLY_DEFAULT_LAYOUT, PLOTLY_DEFAULT_STYLE, TourState,
} from 'app/tour-report/TourReportConstants';
import { TourReportState } from 'app/tour-report/model/TourReportState';
import { ChartData, ChartState } from 'app/tour-report/model/ChartData';

type Props = TourReportState['weeksChart'] & Pick<TourReportState['weeksChartFilter'], 'dateFrom' | 'dateTo'> & {
    handleOnClick: (dateFrom: Date, dateTo: Date, week: string) => void;
};

export function WeeksChart(props: Props) {
    const { dateFrom, dateTo, tourReports, handleOnClick } = props;

    const [state, setState] = useState<ChartState>({
        timecViolations: null,
        incidentsTotal: null,
        incorrectTotal: null,
        correctTotal: null,
    });

    useEffect(() => {
        const timecViolations = new ChartData(TourState.TIME_CONSTRAINT);
        const incidentsTotal = new ChartData(TourState.INCIDENTS);
        const incorrectTotal = new ChartData(TourState.INCORRECT);
        const correctTotal = new ChartData(TourState.CORRECT);

        for (const weekData of tourReports) {
            const total = (weekData.timec_violations ?? 0)
                + (weekData.incidents_total ?? 0)
                + (weekData.incorrect_total ?? 0)
                + (weekData.correct_total ?? 0);

            const label = `${weekData.week} <sub>(Gesamt ${total})</sub>`;

            timecViolations.x.push(label);
            timecViolations.y.push(weekData.timec_violations);
            timecViolations.text.push(weekData.timec_violations);

            incidentsTotal.x.push(label);
            incidentsTotal.y.push(weekData.incidents_total);
            incidentsTotal.text.push(weekData.incidents_total);

            incorrectTotal.x.push(label);
            incorrectTotal.y.push(weekData.incorrect_total);
            incorrectTotal.text.push(weekData.incorrect_total);

            correctTotal.x.push(label);
            correctTotal.y.push(weekData.correct_total);
            correctTotal.text.push(weekData.correct_total);
        }

        setState({
            timecViolations,
            incidentsTotal,
            incorrectTotal,
            correctTotal,
        });
    }, [tourReports]);

    const clickHandler = useCallback((e) => {
        const selected = tourReports[e.points[0].pointIndex];
        handleOnClick(selected.begin, selected.end, selected.week);
    }, [handleOnClick, tourReports]);

    return (
        <>
            <h2>
                {`Erfolgreiche und fehlerhafte Rundgänge im Zeitraum ${formatDate(dateFrom)} - ${formatDate(dateTo)}`}
            </h2>
            <div className="whiteboard">
                { tourReports.length ? (
                    <Plot
                        onClick={clickHandler}
                        data={[
                            state.timecViolations,
                            state.incidentsTotal,
                            state.incorrectTotal,
                            state.correctTotal,
                        ]}
                        layout={
                            {
                                ...PLOTLY_DEFAULT_LAYOUT,
                                hovermode: 'x unified',
                            }
                        }
                        config={{
                            ...PLOTLY_DEFAULT_CONFIG,
                        }}
                        style={{
                            ...PLOTLY_DEFAULT_STYLE,
                        }}
                        useResizeHandler
                    />
                ) : <div className="loading" />}
            </div>
        </>
    );
}
