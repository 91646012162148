/**
 * Display list of all tasks.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav           = rise.Navigation;
    var formTemplates = app.widgets.form;
    var clowFilter      = app.widgets.clowFilter;

    /** DAOs **/
    var taskLogbookEntryDao = app.dao.taskLogbookEntryDao;

    /*** GLOBALS ***/
    var pages         = app.pages;
    var taskLogbookEntryById;

    /*** CONSTANTS ***/
    var TASK_LABEL_COLUMN_INDEX = 2;
    var FILTER_DATE_STARTED = 14;

    var rotationTypes = [
        { id:"UNIQUE", name:"Einmalig" },
        { id:"DAILY", name:"Täglich" },
        { id:"WEEKLY", name:"Wöchentlich" },
        { id:"MONTHLY", name:"Monatlich" }
    ];

    var filter = {
        started: null,
        stopped: null,

        user_id: null,

        rotations: null,
        customers: null,

        customer_id: null,
        location_id: null,
        object_id: null,
        workplace_id: null,

        selectedRotation: null,
        filterExpression: null
    };

    /*** Export Page ***/
    pages.logbook.task.list = pages.logbook.task.list || {};
    pages.logbook.task.list.finished = app.pagesByPath['/logbook/task/list/finished'] = TaskLogbookListFinished;

    /*** Set permission ***/
    TaskLogbookListFinished.permissions = [
        app.model.PermissionTypes.TASK_VIEW_ADMIN,
        app.model.PermissionTypes.TASK_VIEW_SUPERVISOR,
        app.model.PermissionTypes.TASK_VIEW_EMPLOYEE,
        app.model.PermissionTypes.TASK_VIEW_CUSTOMER
    ];

    function TaskLogbookListFinished() {
        /* jshint indent:false */

        var self;
        var sessionInfo = app.session.getSessionInfo();
        var isEmployee = app.session.hasPermission(app.model.PermissionTypes.TASK_VIEW_EMPLOYEE)
            && !app.session.hasPermission(app.model.PermissionTypes.TASK_VIEW_SUPERVISOR)
            && !app.session.hasPermission(app.model.PermissionTypes.TASK_VIEW_ADMIN)
            && !app.session.hasPermission(app.model.PermissionTypes.TASK_VIEW_CUSTOMER);

        //filter.user_id = sessionInfo.user.id;
        filter.workplace_id = sessionInfo.shift ? sessionInfo.shift.workplace_id : null;

        // specify lookup date span
        if(!filter.stopped) {
            filter.stopped = new JustDate();
            var filterStarted = new Date();
            filterStarted.setDate(filterStarted.getDate() - FILTER_DATE_STARTED);
            filter.started = new JustDate(filterStarted);
        }

        self = app.widgets.ListPage.call(this, {
            pageName: '/logbook/task/list/finished',
            prefix: "task-list-finished",
            rpc: function() {
                return [
                    // get customer for workplace
                    taskLogbookEntryDao.getCustomerList()
                ];
            },
            labels: {
                pageHeader: "Abgeschlossene Aufgaben"
            },
            "export": window.app.session.hasPermission(app.model.PermissionTypes.TASK_EXPORT)
                ? {
                    method: "export_tasks",
                    criteriaFn: getFilterCriteria
                }
                : undefined
        });

        this.prepareTable = function($parent, customers) {
            var columns = getTableColumns();
            var settings = getTableSettings();

            this.renderTable(null, columns, settings);

            createFilterEntries(filter, customers);
            $("#placeholder-filters").prependElement(
                renderFilter($parent, filter, self.datatable, isEmployee));

            $parent.activateValidators({});
        };

        this.savePageState = function() { };

        this.loadPageState = function() {
            this.updateTableServerData();
        };

        this.showDetailsPage = function(taskLogbookEntryDto) {
            nav.goToPage(pages.logbook.task.detail.finished, [ taskLogbookEntryDto.id, taskLogbookEntryById]);
        };

        function getFilterCriteria() {
            return {
                begin: filter.started,
                end: filter.stopped,
                task_done: true,
                customer_id: filter.customer_id,
                location_id: filter.location_id,
                object_id: filter.object_id,
                workplace_id: filter.workplace_id,
                rotation_type: filter.selectedRotation,
                assigned_user: filter.user_id,
                filter_expression: filter.filterExpression
            };
        }

        function getTableSettings() {
            return {
                aaSorting: [[ TASK_LABEL_COLUMN_INDEX, "desc" ]],
                bServerSide: true,
                fnServerData: function(sSource, aoData, fnCallback) {
                    aoData = _.object(_.map(aoData, function(oData) { return [oData.name, oData.value]; }));

                    taskLogbookEntryDao.findTaskLogbookEntries($.extend({
                            offset: aoData.iDisplayStart,
                            limit: aoData.iDisplayLength,
                            sort_column: aoData['iSortCol_0'],
                            sort_direction: aoData['sSortDir_0']
                        }, getFilterCriteria())).done(function (data) {
                            fnCallback({
                                aaData: data.rows,
                                iTotalRecords: data.totalCount,
                                iTotalDisplayRecords: data.filteredCount,
                                sEcho: aoData.sEcho
                            });

                            taskLogbookEntryById = _.object(_.map(data.rows, function(item, i) {
                                return [item.id, {
                                    prev: data.rows[i-1] ? data.rows[i-1].id : null,
                                    next: data.rows[i+1] ? data.rows[i+1].id : null
                                }];
                            }));
                        });
                }
            };
        }

    }

    function getTableColumns() {
        var columns = [
                {
                    sTitle: "Abgeschlossen am",
                    sAttrName: "time_logged",
                    sWidth: "130px",
                    mRender: function (mData, type, obj) {
                        return obj.time_logged.toDateTimeString();
                    }
                },
                {
                    sTitle: "Beschreibung",
                    sAttrName: "task_label"
                },
                {
                    sTitle: "Zugewiesen an",
                    sAttrName: "assigned_to"
                },
                {
                    sTitle: "Gesichtet",
                    sAttrName: "read_count",
                    sWidth: "50px"
                },
                {
                    sTitle: "Abgeschlossen von",
                    sAttrName: "logged_user_name"
                },
                {
                    sTitle: "Anmerkungen",
                    sAttrName: "note_count",
                    sWidth: "80px"
                },
                {
                    sTitle: "Eingetragen von",
                    sAttrName: "created_by_user_name"
                },
                {
                    sTitle: "Kunde",
                    sAttrName: "customer_name",
                    mRender: function (mData, type, obj) {
                        return obj.customer_name
                            ? obj.customer_name
                            : '';
                    }
                },
                {
                    sTitle: "Standort",
                    sAttrName: "location_name",
                    mRender: function (mData, type, obj) {
                        return obj.location_name
                            ? obj.location_name
                            : '';
                    }
                },
                {
                    sTitle: "Objekt",
                    sAttrName: "object_name",
                    mRender: function (mData, type, obj) {
                        return obj.object_name
                            ? obj.object_name
                            : '';
                    }
                },
                {
                    sTitle: "Rotation",
                    sAttrName: "task_rotation_type",
                    bVisible: false
                }
            ],
            user = app.session.getSessionInfo().user;

        if (user.type === app.model.UserType.EMPLOYEE &&
            !(app.session.hasPermission(app.model.PermissionTypes.TASK_VIEW_ADMIN ||
              app.session.hasPermission(app.model.PermissionTypes.TASK_VIEW_SUPERVISOR)))) {
            columns.splice(columns.length - 3, 1);
        }

        return columns;
    }

    function createFilterEntries(filter, customers) {

        filter.rotations = [];

        filter.rotations.push({
            id: 'all',
            name: 'Alle'
        });
        _.each(rotationTypes, function(rotationType) {
            filter.rotations.push({
                id: rotationType.id,
                name: rotationType.name
            });
        });

        filter.customers = customers;
    }

    function renderFilter($parent, filter, datatable, isEmployee) {
        /* jshint indent: false */
        var customerAll = {
            id: 'all',
            name: 'Alle'
        };

        return div({ id: 'task-logbook-filter-form', 'class': 'whiteboard' }, [
            legend({}),
            ul({ "class":"inline-list title-row-highlight" }, [
                formTemplates.formItemText({id: 'task-logbook-filter-search', label: 'Suche', required: false,
                    name: "filter_expression",
                    keyup: function () {
                        filter.filterExpression = $(this).val();
                    },
                    keypress: function (e) {
                        var keyCode = e.which;
                        if (keyCode === 13){
                            filterTaskLogbookEntries($parent, datatable);
                            return false;
                        }
                    },
                    change: function () {
                        filterTaskLogbookEntries($parent, datatable);
                    }
                }),
                formTemplates.formItemDate({
                    id: 'task-logbook-filter-start-date', label: 'Anfangsdatum',
                    required: true, datepickerParams: { appendText: "" },
                    date: filter.started.toDate(),
                    name: "begin",
                    change: function() {
                        filter.started = new JustDate($(this).datepicker('getDate'));
                        filterTaskLogbookEntries($parent, datatable);
                    },
                    keypress: app.helper.keyPressEnter,
                    renderDayScroller: true
                }),
                formTemplates.formItemDate({
                    id: 'task-logbook-filter-end-date', label: 'Enddatum',
                    required: true, datepickerParams: { appendText: "" },
                    date: filter.stopped.toDate(),
                    name:"end",
                    change: function() {
                        filter.stopped = new JustDate($(this).datepicker('getDate'));
                        filterTaskLogbookEntries($parent, datatable);
                    },
                    keypress: app.helper.keyPressEnter,
                    renderDayScroller: true
                }),
                function($parent) {
                    clowFilter({
                        customers: filter.customers,
                        $parent: $parent,
                        change: function (selection) {
                            filter.customer_id = selection.customer === -1 ? null : selection.customer;
                            filter.location_id = selection.location === -1 ? null : selection.location;
                            filter.object_id = selection.object === -1 ? null : selection.object;
                            filter.workplace_id = selection.workplace === -1 ? null : selection.workplace;

                            filterTaskLogbookEntries($parent, datatable);
                        },
                        attributeNameWorkplaces: isEmployee ? 'workplaces' : 'assigned_workplaces'
                    });
                },
                formTemplates.formItemSelect({id: 'task-logbook-filter-rotations', label: 'Wiederholung', required: false ,
                    source: filter.rotations, valueProp: 'name', sort: false,
                    value: filter.rotations[0],
                    name: "rotation_type",
                    selected: function(e, selection) {
                        var oldVal = filter.selectedRotation;

                        if (selection.id === 'all') {
                            filter.selectedRotation = null;
                        } else {
                            filter.selectedRotation = selection.id;
                        }

                        if (oldVal !== filter.selectedRotation) {
                            filterTaskLogbookEntries($parent, datatable);
                        }
                    }
                }),
                formTemplates.formItemCheckbox({id: 'task-logbook-filter-own-entries', label: 'Nur eigene Einträge',
                    name: "user_id",
                    change: function() {
                        filter.user_id = $(this).prop("checked")
                            ? app.session.getSessionInfo().user.id
                            : null;

                        filterTaskLogbookEntries($parent, datatable);
                    }
                })
            ])
        ]);
    }

    function filterTaskLogbookEntries($parent, datatable) {
        if ($parent.validate()) {
            datatable.fnDraw();
        }
    }

})(window.rise, window.app);
