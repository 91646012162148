/**
 * LogbookEntry list working hours page.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav             = rise.Navigation;
    var formTemplates   = app.widgets.form;
    var clowFilter      = app.widgets.clowFilter;

    /** DAOs **/
    var logbookEntryDao  = app.dao.logbookEntryDao;
    var eventTypeDao     = app.dao.eventTypeDao;

    /*** GLOBALS ***/
    var pages         = app.pages;
    var logbookEntriesById;

    var filter = {
        events: [],
        objects: [],
        customers: [],

        started: null,
        stopped: null,

        user_id: null,

        customer_id: null,
        location_id: null,
        object_id: null,
        workplace_id: null,

        filterExpression: null,
        onlyEntriesWithComments: false,
        logbookEntryType: null,
        eventLogbookEntryTypeId: null,
        shiftLogbookEntryType: null
    };

    /*** CONSTANTS ***/
    var FILTER_DATE_STARTED = 3;

    /*** Export Page ***/
    pages.logbook.entries = pages.logbook.entries || {};
    pages.logbook.entries.list = app.pagesByPath['/logbook-entries/list'] = LogbookList;

    /*** Set permission ***/
    LogbookList.permissions = [
        app.model.PermissionTypes.LOGBOOK_VIEW_ADMIN,
        app.model.PermissionTypes.LOGBOOK_VIEW_SUPERVISOR,
        app.model.PermissionTypes.LOGBOOK_VIEW_EMPLOYEE,
        app.model.PermissionTypes.LOGBOOK_VIEW_CUSTOMER
    ];


    function LogbookList() {
        /* jshint indent:false */

        var self;
        var sessionInfo = app.session.getSessionInfo();

        var labels = {
            pageHeader: "Logbuch Einträge",
            csvExportButton: "Ereignisexport csv",
            xlsxExportButton: "Ereignisexport xlsx"
        };

        if(!sessionInfo.shift
            && app.session.hasPermission(app.model.PermissionTypes.LOGBOOK_VIEW_EMPLOYEE)
            && !app.session.hasPermission(app.model.PermissionTypes.LOGBOOK_VIEW_SUPERVISOR)
            && !app.session.hasPermission(app.model.PermissionTypes.LOGBOOK_VIEW_ADMIN)
            && !app.session.hasPermission(app.model.PermissionTypes.LOGBOOK_VIEW_CUSTOMER)) {
            app.showErrorMessage('Keine Schicht gestartet');
            return;
        }

        if(
            window.app.session.hasPermission(app.model.PermissionTypes.EVENT_INSERT_ADMIN) ||
            window.app.session.hasPermission(app.model.PermissionTypes.EVENT_INSERT_SUPERVISOR) ||
            window.app.session.hasPermission(app.model.PermissionTypes.EVENT_INSERT_EMPLOYEE) ||
            window.app.session.hasPermission(app.model.PermissionTypes.EVENT_INSERT_CUSTOMER)
        ) {
            labels.buttonNew = "Neues Ereignis";
        }

        self = app.widgets.ListPage.call(this, {
            pageName: '/logbook-entries/list',
            prefix: "logbook-list",
            rpc: function() {
                return [
                    logbookEntryDao.getCustomerList()
                ];
            },
            labels: labels,
            "export":
                ( window.app.session.hasPermission(app.model.PermissionTypes.EVENT_EXPORT) ||
                  window.app.session.hasPermission(app.model.PermissionTypes.SHIFT_EXPORT) )
                ? {
                    method: "export_events",
                    criteriaFn: getFilterCriteria
                }
                : undefined,
            excel:
                ( window.app.session.hasPermission(app.model.PermissionTypes.EVENT_EXPORT) ||
                  window.app.session.hasPermission(app.model.PermissionTypes.SHIFT_EXPORT) )
                ? {
                    method: "export_events",
                    criteriaFn: getFilterCriteria
                }
                : undefined
        });

        this.prepareTable = function($parent, customers) {
            var columns = getTableColumns();
            var settings = getTableSettings();

            self.$parent = $parent;

            createFilterEntries(customers);

            this.renderTable(null, columns, settings);
            $("#placeholder-filters").prependElement(renderFilter());

            $parent.activateValidators({});
        };

        this.savePageState = function() { };

        this.loadPageState = function() {
            // update table data
            this.updateTableServerData();
        };

        this.showDetailsPage = function(logbookEntryDto) {
            if(!logbookEntryDto) {
                nav.goToPage(pages.logbook.create);
            } else {
                nav.goToPage(pages.logbook.entries.detail, [ logbookEntryDto.id, logbookEntriesById ]);
            }
        };

        function getFilterCriteria() {

            return {
                logbook_entry_type: filter.logbookEntryType,
                shift_logbook_entry_type: filter.shiftLogbookEntryType,
                event_type_id: filter.eventLogbookEntryTypeId,
                filter_expression: filter.filterExpression,
                customer_id: filter.customer_id,
                location_id: filter.location_id,
                object_id: filter.object_id,
                workplace_id: filter.workplace_id,
                begin: filter.started,
                end: filter.stopped,
                user_id: filter.user_id,
                only_with_comments: filter.onlyEntriesWithComments
            };
        }

        function getTableSettings() {
            return {
                bServerSide: true,
                fnServerData: function(sSource, aoData, fnCallback) {
                    /* jshint indent:false */
                    aoData = _.object(_.map(aoData, function(oData) { return [oData.name, oData.value]; }));
                    logbookEntryDao.findLogbookEntries($.extend({
                            offset: aoData.iDisplayStart,
                            limit: aoData.iDisplayLength,
                            sort_column: aoData['iSortCol_0'],
                            sort_direction: aoData['sSortDir_0']
                        }, getFilterCriteria())) .done(function(logbookEntryListDto) {
                            fnCallback({
                                aaData: logbookEntryListDto.rows,
                                iTotalRecords: logbookEntryListDto.totalCount,
                                iTotalDisplayRecords: logbookEntryListDto.filteredCount,
                                sEcho: aoData.sEcho
                            });

                            logbookEntriesById = _.object(_.map(logbookEntryListDto.rows, function(item, i) {
                                return [item.id, {
                                    prev: logbookEntryListDto.rows[i-1] ? logbookEntryListDto.rows[i-1].id : null,
                                    next: logbookEntryListDto.rows[i+1] ? logbookEntryListDto.rows[i+1].id : null
                                }];
                            }));
                        });
                }
            };
        }

        function createFilterEntries(customers) {
            filter.events = [];

            // specify lookup date span
            filter.stopped = new JustDate();
            var filterStarted = new Date();
            filterStarted.setDate(filterStarted.getDate() - FILTER_DATE_STARTED);
            filter.started = new JustDate(filterStarted);

            filter.events.push.apply(filter.events, [
                { id:"all", name: 'Alle', list: 'Alle'  },
                { id:"shift-all", name:'Alle Schichtereignisse', list:'<b>Schichten</b></br>Alle Schichtereignisse'},
                { id:"shift-started", name: 'Schichtbeginn', list: 'Schichtbeginn' },
                { id:"shift-stopped", name: 'Schichtende', list: 'Schichtende' },
                { id:"event-all", name: 'Alle Ereignisse', list: '<b>Ereignisse</b></br>Alle Ereignisse'  }
            ]);

            // update event filter
            updateEventsFilter();

            filter.customers = customers;
        }

        function renderFilter() {
            return div({ id: 'logbook-filter-form', 'class': 'whiteboard',
                keydown: function(e) {
                    e.stopImmediatePropagation();
                }
            }, [
                legend({}),
                ul({ "class":"inline-list title-row-highlight"}, [
                    formTemplates.formItemText({id: 'logbook-filter-search', label: 'Suche', required: false,
                        name: "filter_expression",
                        create: function(e) {
                            $(e.target).removeClass('x-large-input').addClass('large-input');
                        },
                        keyup: function () {
                            filter.filterExpression = $(this).val();
                        },
                        keypress: function (e) {
                            var keyCode = e.which;
                            if (keyCode === 13){
                                filterTable(self.$parent);
                                return false;
                            }
                        },
                        change: function () {
                            filterTable(self.$parent);
                        }
                    }),
                    formTemplates.formItemDate({id: 'logbook-filter-start-date', label: 'Anfangsdatum', required: false,
                        name: "begin",
                        date: filter.started.toDate(), datepickerParams: { appendText: "" },
                        change: function() {

                            filter.started = new JustDate($(this).datepicker('getDate'));
                            filterTable(self.$parent);
                        },
                        keypress: app.helper.keyPressEnter,
                        renderDayScroller: true
                    }),
                    formTemplates.formItemDate({id: 'logbook-filter-end-date', label: 'Enddatum', required: false,
                        name: "end",
                        date: filter.stopped.toDate(), datepickerParams: { appendText: "" },
                        change: function() {
                            filter.stopped = new JustDate($(this).datepicker('getDate'));
                            filterTable(self.$parent);
                        },
                        keypress: app.helper.keyPressEnter,
                        renderDayScroller: true
                    }),
                    function($parent) {
                        clowFilter({
                            customers: filter.customers,
                            $parent: $parent,
                            depth: 4,
                            change: function (selection) {
                                filter.customer_id = selection.customer === -1 ? null : selection.customer;
                                filter.location_id = selection.location === -1 ? null : selection.location;
                                filter.object_id = selection.object === -1 ? null : selection.object;
                                filter.workplace_id = selection.workplace === -1 ? null : selection.workplace;

                                // update event filter
                                updateEventsFilter();

                                filterTable(self.$parent);
                            }
                        });
                    },
                    formTemplates.formItemSelect({id: 'logbook-filter-event', label: 'Ereignis', required: false,
                        source: filter.events, valueProp: 'name', listProp: 'list', sort: false,
                        value: filter.events[0],
                        selected: function(e, eventType) {
                            filter.logbookEntryType = null;
                            filter.shiftLogbookEntryType = null;
                            filter.eventLogbookEntryTypeId = null;

                            if (eventType.id === 'shift-all') {
                                filter.logbookEntryType = app.model.LogbookEntryType.SHIFT;
                            } else if (eventType.id === 'shift-started') {
                                filter.logbookEntryType = app.model.LogbookEntryType.SHIFT;
                                filter.shiftLogbookEntryType = 'started';
                            } else if (eventType.id === 'shift-stopped') {
                                filter.logbookEntryType = app.model.LogbookEntryType.SHIFT;
                                filter.shiftLogbookEntryType = 'stopped';
                            } else if (eventType.id === 'event-all') {
                                filter.logbookEntryType = app.model.LogbookEntryType.EVENT;
                            } else if (eventType.id !== 'all') {
                                filter.logbookEntryType = app.model.LogbookEntryType.EVENT;
                                filter.eventLogbookEntryTypeId = eventType.id;
                            }

                            filterTable(self.$parent);
                            unHideExportButton($(this));
                        },
                        create: function() {
                            unHideExportButton($(this));
                        }
                    }),
                    formTemplates.formItemCheckbox({id: 'logbook-filter-own-entries', label: 'Nur eigene Einträge',
                        change: function() {
                            filter.user_id =
                                $(this).is(':checked')
                                    ? app.session.getSessionInfo().user.id
                                    : null;

                            filterTable(self.$parent);
                        }
                    }),
                    formTemplates.formItemCheckbox({id: 'logbook-filter-with-notes', label: 'Einträge mit Anmerkungen',
                        name: "only_with_comments",
                        change: function() {
                            filter.onlyEntriesWithComments = $(this).prop('checked');
                            filterTable(self.$parent);
                        }
                    })
                ])
            ]);
        }

        function filterTable($parent) {
            if (!self.oldFilter) {
                self.oldFilter = _.extend({}, filter);
            }

            if (!_.isMatch(self.oldFilter, filter)) {
                if ($parent.validate()) {
                    self.datatable.fnDraw();
                    self.oldFilter = _.extend({}, filter);
                }
            }
        }

        function updateEventsFilter () {
            var deferred = eventTypeDao.findEventTypes(filter.customer_id, filter.object_id,
                filter.workplace_id, filter.location_id),
                selected;

            deferred.done(function(eventTypes) {
                eventTypes = _.uniq(eventTypes).sort(
                    function(et1, et2) {
                        return et1.label < et2.label ? -1 : 1;
                    }
                );
                filter.events.length = 5;
                filter.events.splice.apply(filter.events, [ filter.events.length, 0 ].concat(
                    _.map(eventTypes, function(eventType) {
                        return {
                            id: eventType.id,
                            name: eventType.label,
                            list: eventType.label
                        };
                    }))
                );

                if (filter.logbookEntryType == app.model.LogbookEntryType.EVENT &&
                    filter.eventLogbookEntryTypeId) {
                    selected = _.findWhere(filter.events, {id: filter.eventLogbookEntryTypeId});

                }

                if (!selected) {
                    if (filter.events.length > 2) {
                        selected = filter.events[0];
                    } else {
                        selected = filter.events[1];
                    }
                }

                $('#logbook-filter-event').combobox('setSelected', selected);
            });
        }
    }

    function getTableColumns() {
        return [
            {
                sTitle: "Eingetragene Zeit",
                sAttrName: "time_entered" ,
                mRender: function (mData, type, obj) {
                    return obj.time_entered.toDateTimeString();
                }
            },
            {
                sTitle: "Ereignis",
                sAttrName: "entry_type",
                bSortable: false,
                mRender: function (mData, type, obj) {
                    /* jshint indent:false */
                    switch (obj.entry_type) {
                        case app.model.LogbookEntryType.SHIFT:
                            return obj.shift_is_start ? 'Schichtbeginn' : 'Schichtende';
                        case app.model.LogbookEntryType.EVENT:
                            return obj.event_type_label;
                    }
                }
            },
            {
                sTitle: "Beschreibung",
                sAttrName: "description",
                sWidth: "200px",
                mRender: function (mData, type, obj) {
                    if (obj.description && obj.description.length > 100) {
                        return obj.description.substring(0, 100) + '...';
                    } else {
                        return obj.description;
                    }
                }
            },
            {
                sTitle: "Benutzer",
                sAttrName: "user_name"
            },
            {
                sTitle: "Anmerkungen",
                sAttrName: "note_count"
            },
            {
                sTitle: "Kunde",
                sAttrName: "customer_name"
            },
            {
                sTitle: "Standort",
                sAttrName: "location_name"
            },
            {
                sTitle: "Objekt",
                sAttrName: "object_name"
            },
            {
                sTitle: "Arbeitsplatz",
                sAttrName: "workplace_name"
            }
        ];
    }

    function unHideExportButton($select) {
        var $export = $("#export-csv");
        var selectedElement = $select.combobox("getSelected");

        $export.hide();

        if(selectedElement.id === "all"
            && window.app.session.hasPermission(app.model.PermissionTypes.EVENT_EXPORT)
            && window.app.session.hasPermission(app.model.PermissionTypes.SHIFT_EXPORT) ) {
            $export.show();
        } else if((typeof selectedElement.id === 'number'
            || ((typeof selectedElement.id === "string") && selectedElement.id.indexOf("event") === 0))
            && window.app.session.hasPermission(app.model.PermissionTypes.EVENT_EXPORT) ) {
            $export.show();
        } else if ((typeof selectedElement.id === "string") && selectedElement.id.indexOf("shift") === 0
            && window.app.session.hasPermission(app.model.PermissionTypes.SHIFT_EXPORT) ) {
            $export.show();
        }
    }

})(window.rise, window.app);
