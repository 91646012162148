/**
 * Logbook detail page.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function (rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var tabsWidget = app.widgets.tabsWidget;
    var commandBox = app.widgets.commandBox;
    var addIconButton = app.widgets.addIconButton;
    var textButton = app.widgets.textButton;
    var iconButton = app.widgets.iconButton;
    var nav = rise.Navigation;
    var EmailDialog = app.widgets.EmailDialog;

    /** DAOs **/
    var logbookEntryDao = app.dao.logbookEntryDao;
    var LogbookEntryNote = app.model.LogbookEntryNote;
    var logbookEntryNoteDao = app.dao.logbookEntryNoteDao;

    /*** GLOBALS ***/
    var pages = app.pages;
    var formWidgets = app.widgets.form;

    /*** CONSTANT ***/
    var LOGBOOK_ENTRY_NOTE_SHOW_ID = 'logbook-entry-notes-show';
    var LOGBOOK_ENTRY_CONTENT_ID = 'logbook-entry-content';

    /*** Export page ***/
    pages.logbook.entries = pages.logbook.entries || {};
    pages.logbook.entries.detail = app.pagesByPath['/logbook-entries/detail'] = function LogbookDetail() {
        var obj = this;
        this.name = "/logbook-entries/detail";

        this.logbookEntryDto = null;

        this.create = function ($parent, id, logbookEntriesById) {
            return logbookEntryDao.getLogbookEntryDetails(id)
                .done(function (logbookEntry) {
                    obj.logbookEntryDto = logbookEntry;

                    obj.logbookEntriesById = logbookEntriesById;
                    renderLogbook($parent);
                });
        };

        this.destroy = function () {
            // called after page has been destroyed
        };

        function renderLogbook($parent) {
            $parent.appendElement([

                renderCommandBox(obj.logbookEntryDto.logbook_entry.id, obj.logbookEntriesById),

                function ($box) {
                    var tabs = [
                        {
                            'id': 'logbook-entry-general-information-tab',
                            'title': 'Allgemein',
                            'fnRender': $.proxy(renderGeneralInformation, self)
                        },
                        {
                            'id': 'logbook-entry-notes-tab',
                            'title': 'Anmerkungen',
                            'fnRender': $.proxy(renderLogbookEntryNotes, self, $parent,
                                obj.logbookEntryDto.logbook_entry.notes,
                                obj.logbookEntryDto.logbook_entry.id, saveNote)
                        }
                    ];

                    $box.appendElement(tabsWidget({id: "task-logbook-entry-details"}, tabs));
                }
            ]);

            $parent.activateValidators();
        }

        function renderGeneralInformation() {
            return div({}, [
                // logbook entry
                renderLogbookForm(obj.logbookEntryDto, true),

                // logbook entry events
                obj.logbookEntryDto.logbook_entry.entry_type === app.model.LogbookEntryType.EVENT
                    ? renderLogbookEntryEvents(obj.logbookEntryDto, true)
                    : undefined
            ]);
        }

        function renderCommandBox(logbookEntryId, logbookEntriesById) {
            /* jshint indent:false*/

            var currentLogbookEntry = logbookEntriesById[logbookEntryId];

            function getTitle() {
                var titlePrefix = "Logbuch: ";
                switch (obj.logbookEntryDto.logbook_entry.entry_type) {
                    case app.model.LogbookEntryType.SHIFT:
                        return titlePrefix + (obj.logbookEntryDto.logbook_entry.is_start ? 'Schichtbeginn' : 'Schichtende');
                    case app.model.LogbookEntryType.EVENT:
                        return titlePrefix + obj.logbookEntryDto.event_type.label;
                }
            }

            function getCommandButtons() {

                function prevButton() {
                    return iconButton({
                        id: 'prev-task-logbook', "class": ' button-previous',
                        click: function () {
                            nav.goToPage(pages.logbook.entries.detail, [currentLogbookEntry.prev, logbookEntriesById]);
                        }
                    });
                }

                function nextButton() {
                    return iconButton({
                        id: 'next-task-logbook', "class": 'button-foreward ',
                        click: function () {
                            nav.goToPage(pages.logbook.entries.detail, [currentLogbookEntry.next, logbookEntriesById]);
                        }
                    });
                }

                return [
                    !!currentLogbookEntry.next ? nextButton() : undefined,
                    !!currentLogbookEntry.prev ? prevButton() : undefined
                ];

            }

            return commandBox({title: getTitle(), backAction: pages.logbook.entries.list}, getCommandButtons());
        }

        function saveNote(logbookEntryNote) {

            var deferred = new $.Deferred();

            logbookEntryNoteDao.insert(logbookEntryNote)
                .done(function (newLogbookEntryNote) {
                    app.showSuccessMessage('Anmerkung hinzugefügt');

                    obj.logbookEntryDto.logbook_entry.notes.push(newLogbookEntryNote);

                    deferred.resolve(newLogbookEntryNote);
                });

            return deferred.promise();
        }
    };

    // logbook entry
    function renderLogbookForm(logbookEntryDto, view) {
        return fieldset({id: 'logbook-entry-form', "class": "whitebard"}, [
            legend({}, 'Ereignisdaten'),
            ul({'class': 'fieldset-content'}, [
                formWidgets.formItemDateTime({
                    id: 'logbook-entry-time-entered', label: 'Eingetragene Zeit',
                    date: logbookEntryDto.logbook_entry.time_entered
                }, view),
                formWidgets.formItemDateTime({
                    id: 'logbook-entry-time-logged', label: 'Logbuch Zeit',
                    date: logbookEntryDto.logbook_entry.time_logged
                }, view),
                formWidgets.formItemText({
                    id: 'logbook-entry-employee', label: 'Benutzer',
                    value: logbookEntryDto.user_first_name + ' ' + logbookEntryDto.user_last_name
                }, view),
                formWidgets.formItemText({
                    id: 'logbook-entry-customer', label: 'Kunde',
                    value: logbookEntryDto.customer_name
                }, view),
                formWidgets.formItemText({
                    id: 'logbook-entry-location', label: 'Standort',
                    value: logbookEntryDto.location_name
                }, view),
                formWidgets.formItemText({
                    id: 'logbook-entry-object', label: 'Objekt',
                    value: logbookEntryDto.object_name
                }, view),
                formWidgets.formItemText({
                    id: 'logbook-entry-workplace', label: 'Arbeitsplatz',
                    value: logbookEntryDto.workplace_name
                }, view),
                app.session.hasPermission(app.model.PermissionTypes.IP_READ) ?
                    formWidgets.formItemText({
                        id: 'logbook-entry-client-ip', label: 'IP-Adresse',
                        value: logbookEntryDto.client_ip
                    }, view) :
                    undefined,
                showAlarmEmailInfo(logbookEntryDto, true)
            ].concat(renderDownloadLinks(logbookEntryDto, true)))
        ]);
    }

    function showAlarmEmailInfo(logbookEntryDto, view) {
        if (logbookEntryDto.event_type !== null &&
            logbookEntryDto.event_type.alarm_email &&
            logbookEntryDto.logbook_entry.sent_email_to !== null) {
            return formWidgets.formItemText({
                id: 'logbook-entry-alarm-mail', label: 'Email versandt an',
                value: logbookEntryDto.logbook_entry.sent_email_to.split(';').join(', ')
            }, view);
        }

        return undefined;
    }

    // logbook entry events
    function renderLogbookEntryEvents(eventLogbookEntry, view) {
        return div({}, [
            h2({}, "Ereignisfelder"),
            fieldset({id: 'logbook-entry-event-fields'}, [
                ul({'class': 'fieldset-content'},
                    formWidgets.formItemsForEvent(eventLogbookEntry.event_type, eventLogbookEntry.logbook_entry, view)
                )
            ]),
            div({"class": "action-box bottom"},
                ul({},
                    li({}, textButton({
                        id: 'send-entry', click: function (e) {
                            new EmailDialog({
                                parent: $(e.target),
                                click: function (mailAddresses) {
                                    if (mailAddresses) {
                                        sendEmail(eventLogbookEntry.logbook_entry.id, mailAddresses);
                                    } else {
                                        app.showErrorMessage("Keine Email-Adresse angegeben");
                                    }
                                }
                            }).open();
                        }
                    }, 'Eintrag als Email verschicken'))
                )
            )
        ]);
    }

    function sendEmail(eventLogbookEntryId, mailAddresses) {
        logbookEntryDao.sendByMail(eventLogbookEntryId, mailAddresses)
            .done(function () {
                app.showSuccessMessage("Der Eintrag wurde Erfolgreich versendet");
            })
            .fail(function () {
                app.showErrorMessage("Der Eintrag konnte nicht versendet werden");
            });
    }

    // logbook entry notes
    function renderLogbookEntryNotes($parent, logbookEntryNotes, logbookEntryId, saveNote) {

        var logbookEntryNote = new LogbookEntryNote();
        logbookEntryNote.logbook_entry_id = logbookEntryId;
        logbookEntryNote.user_id = app.session.getSessionInfo().user.id;

        return div({id: 'logbook-entry-notes'}, [
            div({id: LOGBOOK_ENTRY_NOTE_SHOW_ID},
                renderLogbookEntryNoteShowForms($parent, logbookEntryNotes)
            ),
            div({id: 'logbook-entry-notes-new'},
                renderLogbookEntryNoteAddForm($parent, logbookEntryNote, saveNote)
            )
        ]);
    }

    function renderLogbookEntryNoteShowForms($parent, logbookEntryNotes) {
        return $.map(logbookEntryNotes, function (logbookEntryNote) {
            return renderLogbookEntryNoteForm(true, logbookEntryNote);
        });
    }

    function renderLogbookEntryNoteAddForm($parent, logbookEntryNote, saveNote) {
        return div({id: 'logbook-entry-note-new-form', "class": ''}, [
            renderLogbookEntryNoteForm(false, logbookEntryNote),
            div({"class": "action-box bottom"},
                ul({},
                    li({}, addIconButton({
                        id: 'logbook-entry-note-save', click: function () {
                            if ($parent.validate()) {
                                saveNote(logbookEntryNote)
                                    .done(function (newLogbookEntryNote) {
                                        // append new note
                                        $('#' + LOGBOOK_ENTRY_NOTE_SHOW_ID)
                                            .appendElement(renderLogbookEntryNoteForm(true, newLogbookEntryNote));

                                        // reset content field
                                        $('#' + LOGBOOK_ENTRY_CONTENT_ID).val('');
                                    });
                            }
                        }
                    }, 'Anmerkung hinzufügen'))
                )
            )
        ]);
    }

    function renderLogbookEntryNoteForm(view, logbookEntryNote) {
        return fieldset({id: 'logbook-entry-notes-form', "class": ""}, [
            ul({'class': 'fieldset-content'}, [
                view
                    ? formWidgets.formItemText({
                        id: 'logbook-entry-employee', label: 'Benutzer',
                        value: logbookEntryNote._user_first_name + ' ' + logbookEntryNote._user_last_name
                    }, view)
                    : undefined,
                view
                    ? formWidgets.formItemDateTime({
                        id: 'logbook-entry-date', label: 'Datum',
                        date: logbookEntryNote.created_time
                    }, view)
                    : undefined,
                formWidgets.formItemTextarea({
                    id: LOGBOOK_ENTRY_CONTENT_ID + (view ? '-view' : ''), label: 'Inhalt', required: true,
                    value: logbookEntryNote.content,
                    change: function () {
                        logbookEntryNote.content = $(this).val();
                    }
                }, view)
            ])
        ]);
    }

    function renderDownloadLinks(logbookEntryDto, view) {
        if (view) {
            return _.map(logbookEntryDto.logbook_entry.documents, function (document) {
                return li({"class": "fieldset-item"}, [
                    label({"for": "event-document-download"}, "Anhang"),
                    span({},
                        form({method: "POST", action: "/document"}, [
                            input({
                                id: "event-document-download",
                                type: "hidden",
                                name: "method",
                                value: "download"
                            }),
                            input({
                                id: "event-document-x-session-id",
                                type: "hidden",
                                name: "X-Session-Id",
                                value: window.app.session.getSessionId()
                            }),
                            input({
                                id: "event-document-id",
                                type: "hidden",
                                name: "id",
                                value: document.data
                            }),
                            a({
                                id: "event-document-download",
                                click: function () {
                                    $(this).closest("form").submit();
                                }
                            }, document.filename || "unknown_filename")
                        ])
                    )
                ]);
            });
        }
    }

})(window.rise, window.app);
