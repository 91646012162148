/**
 * Tour area detail page.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav         = rise.Navigation;
    var pages       = app.pages;
    var form        = app.widgets.form;

    var ConfirmationDialog          = app.widgets.ConfirmationDialog;
    var TourTimeCListSelector       = app.widgets.TourTimeCListSelector;
    var editIconButton              = app.widgets.editIconButton;
    var printIconButton             = app.widgets.printIconButton;
    var deleteIconButton            = app.widgets.deleteIconButton;
    var cancelIconButton            = app.widgets.cancelIconButton;
    var saveIconButton              = app.widgets.saveIconButton;
    var commandBox                  = app.widgets.commandBox;

    /** DAOs **/
    var TourArea        = app.model.TourArea;
    var tourDao         = app.dao.tourDao;
    var tourAreaDao     = app.dao.tourAreaDao;
    var customerDao     = app.dao.customerDao;

    /** CONSTANTS **/


    /** GLOBALS **/
    var PageType = {
        VIEW: 1,
        EDIT: 2,
        NEW: 3
    };

    /*** Set permission ***/
    TourAreaDetail.permissions = [
        app.model.PermissionTypes.TOUR_MODIFY
    ];

    /*** Export page ***/
    pages.tour_area.detail = app.pagesByPath['/tour-area/detail'] = TourAreaDetail;

    function TourAreaDetail() {
        var self = this;
        this.name = "/tour-area/detail";
        this.type = PageType.VIEW;
        this.goToPage = null;
        this.tourTimeCListSelector = null;

        self.customers = null;
        self.tourArea = null;
        self.calendars = null;
        self.referencedTours = null;
        self.assignments = new app.model.TourAreaAssignments();

        this.create = function ($parent, tourArea, goToPage, renderAsSubpage) {
            this.type = tourArea ? PageType.VIEW : PageType.NEW;
            this.goToPage = app.pagesByPath[goToPage];
            this.renderAsSubpage = renderAsSubpage;

            if (tourArea) {
                return $.when(
                    customerDao.findAll(),
                    tourAreaDao.getTourAreaDetails(tourArea.id, true),
                    tourDao.getCalendars()
                ).done(function (customers, tourAreaDetails, calendars) {
                    self.customers = customers;
                    self.tourArea = tourAreaDetails.tour_area;
                    self.assignments = tourAreaDetails.assignments;
                    self.calendars = calendars;
                    self.referencedTours = tourAreaDetails.referenced_tours;

                    renderTourArea($parent);
                });

            } else {
                return $.when(
                    customerDao.findAll(),
                    tourDao.getCalendars()
                ).done(function(customers, calendars) {
                    self.customers = customers;
                    self.tourArea = new TourArea();
                    self.calendars = calendars;
                    self.referencedTours = [];

                    renderTourArea($parent);
                });
            }
        };

        this.destroy = function () {
            // called after page has been destroyed
            if (self.tourTimeCListSelector) {
                self.tourTimeCListSelector.destroy();
            }
        };

        function renderTourArea($parent) {
            var view = self.type === PageType.VIEW;

            $parent.appendElement([
                self.renderAsSubpage ? undefined : renderCommandBox($parent),
                renderTourAreaForm(self.tourArea, view),
                renderTourAreaTimeCForm(view)
            ]);

            $parent.activateValidators();
        }

        function renderCommandBox($parent) {
            var savedTourArea = new TourArea(self.tourArea);
            var savedAssignments = new app.model.TourAreaAssignments(self.assignments);

            function saveButton() {
                return saveIconButton({id: 'save-tour-area',
                    click: function () {
                        if ($parent.validate()) {
                            saveTourArea($parent);
                        }
                    }
                });
            }

            function cancelButton(backToOverview) {
                return cancelIconButton({id: 'cancel-edit-tour-area',
                    click: function () {
                        if (backToOverview) {
                            nav.goToPage(pages.tour_area.list);
                        } else {
                            $.validation.reset();
                            $parent.empty();

                            self.tourArea = savedTourArea;
                            self.assignments = savedAssignments;
                            self.type = PageType.VIEW;

                            self.destroy();

                            renderTourArea($parent);
                        }
                    }
                });
            }

            function deleteButton() {
                return deleteIconButton({id: 'delete-tour-area',
                    click: function(e) {
                        new ConfirmationDialog({
                            parent: $(e.target),
                            title: "Revier löschen",
                            text: "Wollen Sie das Revier wirklich löschen?",
                            click: function() {
                                deleteTourArea();
                            }
                        }).open();
                    }
                }, 'Revier löschen');
            }

            function editTourAreaButton() {
                return editIconButton({id: 'edit-tour-area',
                    click: function () {
                        self.type = PageType.EDIT;
                        $parent.empty();
                        renderTourArea($parent);
                    }
                }, 'Revier bearbeiten');
            }

            function printTourAreaButton() {
                return printIconButton({id: 'print-tour-area',
                    click: function () {
                        printTourArea();
                    }

                }, 'Revieranfahrtsliste drucken');
            }

            function getHeader() {
                /* jshint indent:false */
                switch (self.type) {
                    case PageType.NEW:
                        return 'Neues Revier';
                    case PageType.EDIT:
                        return 'Revier bearbeiten';
                    case PageType.VIEW:
                        return 'Revier: ' + self.tourArea.name;
                }
                return '';
            }

            function getCommandButtons() {
                /* jshint indent:false */
                switch (self.type) {
                    case PageType.VIEW:
                        return [
                            editTourAreaButton(),
                            printTourAreaButton(),
                            deleteButton()
                        ];
                    case PageType.EDIT:
                        return [
                            saveButton(),
                            cancelButton()
                        ];

                    case PageType.NEW:
                        return [
                            saveButton(),
                            cancelButton(true)
                        ];
                }

                return [];
            }

            function backAction() {
                if (self.goToPage) {
                    return self.goToPage;
                } else {
                    return pages.tour_area.list;
                }
            }

            return commandBox({backAction: backAction(), title: getHeader()}, getCommandButtons());

        }

        function saveTourArea($parent) {
            // update assignments
            self.assignments.tour_area_timecs = self.tourTimeCListSelector.getSelected();

            if (self.type === PageType.EDIT) {
                // update the tour-area
                tourAreaDao.update(self.tourArea, self.assignments).done(updateDoneMethod);
            } else if (self.type === PageType.NEW) {
                tourAreaDao.insert(self.tourArea, self.assignments).done(insertDoneMethod);
            }

            function updateDoneMethod(tourAreaDetails) {
                // update referenced tours
                self.referencedTours = tourAreaDetails.referenced_tours;

                // update assignments
                self.assignments = tourAreaDetails.assignments;

                // update tour area
                self.tourArea = tourAreaDetails.tour_area;

                $parent.empty();
                self.type = PageType.VIEW;

                renderTourArea($parent);
                app.showSuccessMessage('Revier aktualisiert');

                // remove cached pages for forcing filter update
                rise.Navigation.clearPageCache();
            }

            function insertDoneMethod(tourAreaDetails) {
                // update referenced tours
                self.referencedTours = tourAreaDetails.referenced_tours;

                // update assignments
                self.assignments = tourAreaDetails.assignments;

                // update tour area
                self.tourArea = tourAreaDetails.tour_area;

                self.type = PageType.VIEW;
                self.destroy();

                $parent.empty();
                renderTourArea($parent);
                app.showSuccessMessage('Revier gespeichert');

                // remove cached pages for forcing filter update
                rise.Navigation.clearPageCache();
            }
        }

        function renderTourAreaTimeCForm(view, title) {
            if (view && !(self.assignments.tour_area_timecs && self.assignments.tour_area_timecs.length)) {
                return undefined;
            } else {
                return div({'class': 'tour-area-timec-list'}, [
                    h2({}, title ? title : "Zugeordnete Rundgänge"),
                    function($parent) {
                        self.tourTimeCListSelector = new TourTimeCListSelector({
                            parent: $parent,
                            calendars: self.calendars,
                            referencedTours: self.referencedTours,
                            selected: self.assignments.tour_area_timecs,
                            onSelect: onSelectTimeC,
                            editable: !view,
                            customers: self.customers
                        });
                    }
                ]);
            }
        }

        function onSelectTimeC(tour_area_timecs) {
            self.assignments.tour_area_timecs = tour_area_timecs;
        }

        function deleteTourArea() {
            tourAreaDao.deleteTourArea(self.tourArea.id).done(deleteTourAreaDone);

            function deleteTourAreaDone () {
                // remove cached pages for forcing filter update
                rise.Navigation.clearPageCache();

                app.showSuccessMessage('Revier wurde gelöscht');
                self.destroy();
                nav.goToPage(pages.tour_area.list);
            }
        }

        function printTourArea() {
            app.printpreview.openPrintPreview({
                title: "Revieranfahrtsliste",
                reportClass: "print-tour-area",
                fnRenderHeaderAdditions: renderReportHeaderAdditions,
                fnRenderBody: function($parent) {
                    // append tour list with time-constraint information
                    $parent.appendElement(renderTourAreaTimeCForm(true, sprintf("Revier: %s", self.tourArea.name)));
                }
            });
        }

        function renderReportHeaderAdditions() {
            // render meta info
        }
    }

    function renderTourAreaForm(tourArea, view) {
        return fieldset({id: 'tour-area-new'}, [
            ul({'class': 'fieldset-content'}, [
                form.formItemText({id: 'tour-area-id', label: 'Name', required: true,
                    value: tourArea.name,
                    change: function () {
                        tourArea.name = $(this).val();
                    }
                }, view),
                form.formItemTextarea({id: 'tour-area-description', label: 'Beschreibung',
                    value: tourArea.description,
                    change: function () {
                        tourArea.description = $(this).val();
                    },
                    maxlength: 255
                }, view)
            ])
        ]);
    }

}) (window.rise, window.app);
