import { DEFAULT_WEEKS_CHART_MIN_DAYS, TourState, ViewType } from 'app/tour-report/TourReportConstants';
import { TourReportDaysResponse, TourReportWeeksResponse } from 'app/tour-report/dao/TourReportDao';
import { Customer } from 'app/legacy/model/customer';

export class TourReportState {
    view: ViewType;

    weeksChart: {
        tourReports: TourReportWeeksResponse;
    };

    weeksChartFilter: {
        dateFrom: Date;
        dateTo: Date;
        customerId: number | null;
        locationId: number | null;
        objectId: number | null;
        customers: Array<Customer>;
    };

    daysChart: {
        dateFrom: Date;
        dateTo: Date;
        week?: string;
        tourReports: TourReportDaysResponse;
    };

    dayListTable: {
        dateFrom: Date;
        dateTo: Date;
        customerId: number | null;
        locationId: number | null;
        objectId: number | null;
        tourState: TourState;
    };

    constructor(data: TourReportState) {
        const { view, weeksChart, weeksChartFilter, daysChart, dayListTable } = data ?? {};

        this.view = view ?? ViewType.WEEKS;

        const today = new Date();

        const mostRecentMonday = new Date(today);
        mostRecentMonday.setDate(mostRecentMonday.getDate() + 1 - mostRecentMonday.getDay());

        const weeksChartDefaultDateFrom = new Date(mostRecentMonday);
        weeksChartDefaultDateFrom.setHours(0, 0, 0, 0);
        weeksChartDefaultDateFrom.setDate(weeksChartDefaultDateFrom.getDate() - DEFAULT_WEEKS_CHART_MIN_DAYS);
        const weeksChartDefaultDateTo = new Date(today);
        weeksChartDefaultDateTo.setHours(23, 59, 0, 0);

        this.weeksChart = {
            tourReports: weeksChart?.tourReports ?? [],
        };

        this.weeksChartFilter = {
            dateFrom: weeksChartFilter?.dateFrom ? new Date(weeksChartFilter.dateFrom) : weeksChartDefaultDateFrom,
            dateTo: weeksChartFilter?.dateTo ? new Date(weeksChartFilter.dateTo) : weeksChartDefaultDateTo,
            customerId: weeksChartFilter?.customerId ?? -1,
            locationId: weeksChartFilter?.locationId ?? -1,
            objectId: weeksChartFilter?.objectId ?? -1,
            customers: weeksChartFilter?.customers ?? [],
        };

        const daysChartDefaultDateFrom = new Date(mostRecentMonday);
        daysChartDefaultDateFrom.setHours(0, 0, 0, 0);
        const daysChartDefaultDateTo = new Date(today);
        daysChartDefaultDateTo.setHours(23, 59, 59, 999);
        this.daysChart = {
            dateFrom: daysChart?.dateFrom ? new Date(daysChart.dateFrom) : daysChartDefaultDateFrom,
            dateTo: daysChart?.dateTo ? new Date(daysChart.dateTo) : daysChartDefaultDateTo,
            week: daysChart?.week ?? null,
            tourReports: daysChart?.tourReports ?? [],
        };

        const dayListTableDefaultDateFrom = new Date(today);
        dayListTableDefaultDateFrom.setHours(0, 0, 0, 0);
        const dayListTableDefaultDateTo = new Date(today);
        dayListTableDefaultDateTo.setHours(23, 59, 59, 999);
        this.dayListTable = {
            dateFrom: dayListTable?.dateFrom ? new Date(dayListTable.dateFrom) : dayListTableDefaultDateFrom,
            dateTo: dayListTable?.dateTo ? new Date(dayListTable.dateTo) : dayListTableDefaultDateTo,
            tourState: dayListTable?.tourState ?? TourState.TIME_CONSTRAINT,
            customerId: weeksChartFilter?.customerId ?? null,
            locationId: weeksChartFilter?.locationId ?? null,
            objectId: weeksChartFilter?.objectId ?? null,
        };
    }
}
