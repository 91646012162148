/**
 * Wrapper for the AJAX call which also handles the error cases.
 *
 * @module rise.JsonRpc
 * @author Martin Reiterer
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 * ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
export const Rpc = (function(rise) {
    "use strict";

    /*** IMPORTS ***/
    var log = rise.logging.getLogger("rpc");

    /** General Configuration section */
    $.ajaxSetup({
        contentType: 'application/json-rpc',
        type: "POST",
        async: true
    });

    /*** GLOBALS ***/
    var callId = 1;

    var cfg = {
        beforeCallback: function() {},
        failCallback: function(errorObj) {},
        alwaysCallback: function() {}
    };

    /**
     * define the endpoint of the RPC. The url is constructed by `Settings.rpc_url` and `endpoint`
     *
     * @param {String} [endpoint]
     * @constructor
     */
    rise.Rpc = function Rpc(endpoint) {
        log.info("Setting up JSON RPC with endpoint %s", endpoint);
        this.endpoint = endpoint;
    };

    rise.Rpc.setConfig = function(config) {
        if(config.beforeCallback !== undefined && !_.isFunction(config.beforeCallback)) {
            throw "Wrong config parameter for Rpc.setConfig: 'beforeCallback' is not a valid method";
        }

        if(config.failCallback !== undefined && !_.isFunction(config.failCallback)) {
            throw "Wrong config parameter for Rpc.setConfig: 'failCallback' is not a valid method";
        }

        if(config.alwaysCallback !== undefined && !_.isFunction(config.alwaysCallback)) {
            throw "Wrong config parameter for Rpc.setConfig: 'alwaysCallback' is not a valid method";
        }

        cfg = $.extend(cfg, config);
    };

    /**
     * Global method to invoke a JSON RPC call. This method cleans up any present `data.document` object and removes
     * all elements which start with an underscore. In case of an error (JSON RPC or transport level), the method shows
     * an appropriate error message.
     *
     * @param {String} method Name of the JSON RPC method
     * @param {Object} [params] Parameter for the method
     * @param {Boolean} [async] Defines if the call is sent asynchronous. Default: true
     * @return {jQuery.Deferred} returns a jQuery Deferred object. The `done` method is invoked with 1 to 2 parameters:
     *                          `function(result, [warning])` where the warning object has the same structure as the
     *                          error object.
     *                          The error/waring object comes in two flavors:
     *                              Standard error object:
     *                              {
     *                                  code: code of the error,
     *                                  message: more human readable error message
     *                              }
     *
     *                              Error object with detailed errors:
     *                              {
     *                                  code: code of general error, e.g. -400 for validation error
     *                                  message: more human readable error message
     *                                  data: array of detailed errors
     *                              }
     */
    rise.Rpc.prototype.invoke = function(method, params, async) {
        var thisCallId = callId++;
        log.debug('[%d] Invoking JSON RPC call with method "%s", params %s and async %s', thisCallId, method, params, async);

        cfg.beforeCallback();

        var response = $.Deferred();

        // define optional parameter
        async = (async === undefined) ? true : async;

        log.info("using JSON RPC with endpoint %s", this.endpoint);

        // request json-rpc endpoint
        $.ajax({
            url: this.endpoint,
            async: async,
            data: JSON.stringify({
                id: thisCallId,
                jsonrpc: "2.0",
                method: method,
                params: params
            }),
            beforeSend: function (xhr) {
                if (cfg.sessionId) {
                    xhr.setRequestHeader("X-Session-Id", cfg.sessionId);
                }
            }
        }).done( function (data, textStatus, jqXHR) {
            var result;

            if (data.result !== undefined) {
                result = data.result;

                if (data.warning !== undefined) {
                    log.debug('[%d] Success Response with warning for "%s". %s', thisCallId, method, data);

                    cfg.failCallback(data.warning);
                    response.resolve(result, data.warning);
                } else {
                    log.debug('[%d] Success Response for "%s": %s', thisCallId, method, data);
                    response.resolve(result);
                }
            } else if (data.error !== undefined) {
                log.warn('[%d] Error Response for "%s". %s', thisCallId, method, data);
                cfg.failCallback(data.error);
                response.reject(data.error);
            } else {
                log.error('[%d] Unexptected Response for "%s". %s', thisCallId, method, data);
                result = {code: 666, message: 'unknown error'};
                cfg.failCallback(result);
                response.reject(result);
            }
        }).fail(function (jqXHR, textStatus, errorThrown) {
            var errorObj;

            log.error('[%d] Request failed: %s', thisCallId, jqXHR);

            if (jqXHR && jqXHR.readyState === 0 && jqXHR.status === 0) {
                // request aborted
                errorObj = {code: jqXHR.status, message: 'Aborted'};
            } else if (jqXHR && jqXHR.status === 400 || jqXHR.status === 401) {
                // unauthorized, show login page
                // note: an invalid (timed-out) session-id will return a HTTP 400 Bad Request
                errorObj = {code: jqXHR.status, message: jqXHR.status === 400
                    ? 'Session timed out'
                    : 'Unauthorized'};

            } else {
                // other errors
                errorObj = {code: 666, message: 'Unknown Error'};
            }

            cfg.failCallback(errorObj);

            response.reject(errorObj);
        }).always(function() {
            cfg.alwaysCallback(arguments);
        });

        return response.promise();
    };

    return rise.Rpc;

})(window.rise);
