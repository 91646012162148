/**
 * Date helper methods.
 *
 * @author Conrad Wandl
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function() {
    "use strict";

    /*** EXPORTS ***/
    rise.Date = {};

    Date.HOURS_DAY      = 24;
    Date.MINUTES_HOUR   = 60;
    Date.SECONDS_MINUTE = 60;
    Date.MILLIS_SECOND  = 1000;

    Date.MILLIS_MINUTE  = Date.MILLIS_SECOND * Date.SECONDS_MINUTE;
    Date.MILLIS_HOUR    = Date.MILLIS_MINUTE * Date.MINUTES_HOUR;
    Date.MILLIS_DAY     = Date.MILLIS_HOUR   * Date.HOURS_DAY;

    Date.defaults = $.datepicker.regional.de;

    var zeros    = "00000000000000000000",
        splitter = /(.)\1+|./g,
        regexCache = {};

    /**
     *
     * @param format
     * @param {Boolean} [utc]
     * @returns {string}
     */
    Date.prototype.toFormatString = function(format, utc) {
        var self, result, pattern;

        if(isNaN(this)) {
            return "Invalid Date";
        }

        self = this;
        result = "";
        pattern = format.match(splitter);

        _.each(pattern, function(element, index, array) {
            switch(element) {
                case "YYYY":
                    result += utc ? self.getUTCFullYear() : self.getFullYear();
                    break;
                case "M":
                    result += ((utc ? self.getUTCMonth() : self.getMonth()) + 1);
                    break;
                case "MM":
                    result += _.pad((utc ? self.getUTCMonth() : self.getMonth()) + 1, 2, '0');
                    break;
                case "MMM":
                    result += Date.defaults.monthNamesShort[utc ? self.getUTCMonth() : self.getMonth()];
                    break;
                case "MMMM":
                    result += Date.defaults.monthNames[utc ? self.getUTCMonth() : self.getMonth()];
                    break;
                case "D":
                    result += utc ? self.getUTCDate() : self.getDate();
                    break;
                case "DD":
                    result += _.pad(utc ? self.getUTCDate() : self.getDate(), 2, '0');
                    break;
                case "DDD":
                    result += Date.defaults.dayNamesShort[utc ? self.getUTCDay() : self.getDay()];
                    break;
                case "DDDD":
                    result += Date.defaults.dayNames[utc ? self.getUTCDay() : self.getDay()];
                    break;
                case "HH":
                    result += _.pad(utc ? self.getUTCHours() : self.getHours(), 2, '0');
                    break;
                case "mm":
                    result += _.pad(utc ? self.getUTCMinutes() : self.getMinutes(), 2, '0');
                    break;
                case "ss":
                    result += _.pad(utc ? self.getUTCSeconds() : self.getSeconds(), 2, '0');
                    break;
                case "sss":
                    result += _.pad(utc ? self.getUTCMilliseconds() : self.getMilliseconds(), 2, '0');
                    break;
                case "Z":
                    var offset = self.getTimezoneOffset();

                    result += utc ? "Z" : (offset > 0 ? "-" : "+") + _.pad(Math.abs(Math.floor(offset / Date.MINUTES_HOUR)), 2, '0') + ":" + _.pad(offset % Date.MINUTES_HOUR, 2, '0');
                    break;
                default:
                    result += element;
            }
        });

        return result;
    };

    Date.prototype.difference = function(date, format) {
        var pattern, regex, string, diff, result;

        diff = this > date ? this - date : date - this;

        if(!format) {
            return diff;
        }

        result = "";
        pattern = format.match(splitter);

        _.each(pattern, function(element, index, array) {
            switch(element) {
                case "D":
                    result += Math.floor(diff / Date.MILLIS_DAY);
                    diff = diff % Date.MILLIS_DAY;
                    break;
                case "DD":
                    result += _.pad(Math.floor(diff / Date.MILLIS_DAY), 2, '0');
                    diff = diff % Date.MILLIS_DAY;
                    break;
                case "HH":
                    result += _.pad(Math.floor(diff / Date.MILLIS_HOUR), 2, '0');
                    diff = diff % Date.MILLIS_HOUR;
                    break;
                case "mm":
                    result += _.pad(Math.floor(diff / Date.MILLIS_MINUTE), 2, '0');
                    diff = diff % Date.MILLIS_MINUTE;
                    break;
                case "ss":
                    result += _.pad(Math.floor(diff / Date.MILLIS_SECOND), 2, '0');
                    diff = diff % Date.MILLIS_SECOND;
                    break;
                case "sss":
                    result += _.pad(diff, 2, '0');
                    break;
                default:
                    result += element;
            }
        });

        return result;
    };

    Date.parseFormat = function(string, format, utc) {
        var pattern, regex, date, timezoneOffset;

        pattern = format.match(splitter);
        regex = createRegex(pattern);
        string = string.toUpperCase().match(regex);

        if(!string) {
            return NaN;
        }

        string.shift();

        var year = 0, month = 0, day = 1, hour = 0, minute = 0, second = 0, millisecond = 0;

        _.each(pattern, function(element, index, array) {
            switch(element) {
                case "YYYY":
                    year = string.shift();
                    break;
                case "M": case "MM":
                month = parseInt(string.shift(), 10) - 1;
                break;
                case "MMM":
                    month = Date.defaults.monthNamesShort.indexOf(string.shift());
                    break;
                case "MMMM":
                    month = Date.defaults.monthNames.indexOf(string.shift());
                    break;
                case "D": case "DD":
                day = string.shift();
                break;
                case "HH":
                    hour = string.shift();
                    break;
                case "mm":
                    minute = string.shift();
                    break;
                case "ss":
                    second = string.shift();
                    break;
                case "sss":
                    millisecond = string.shift();
                    break;
                case "Z":
                    var utc, sign, hours, minutes;

                    utc = string.shift();
                    sign = string.shift();
                    hours = parseInt(string.shift(), 10);
                    minutes = parseInt(string.shift(), 10);

                    utc === "Z" ? timezoneOffset = 0 : timezoneOffset = (sign === "+" ? -1 : 1) * (hours * Date.MINUTES_HOUR + minutes);
                    break;
            }
        });

        date = new Date(year, month, day, hour, minute, second, millisecond);

        return new Date(Date.UTC(year, month, day, hour, minute, second, millisecond)
            + ((timezoneOffset === undefined ? (utc ? 0 : date.getTimezoneOffset()) : timezoneOffset)) * Date.MILLIS_MINUTE);
    };

    /**
     * Parses a ISO date and returns a date object.
     *
     * @see http://stackoverflow.com/questions/6228302/javascript-date-iso8601
     *
     * @param {string} isoString Date as full ISO string with time
     * @return {Date} date object
     */
    Date.fromISO = function fromISO(isoString) {
        // Date.parseFormat is not working as there are optional parts in here!
        var day, tz, rx = /^(\d{4}\-\d\d\-\d\d([tT][\d:\.]*)?)([zZ]|([+\-])(\d\d):?(\d\d))?$/, p = rx.exec(isoString) || [];
        if (p[1]) {
            day = p[1].split(/\D/);
            for (var i = 0, L = day.length; i < L; i++) {
                day[i] = parseInt(day[i], 10) || 0;
            }
            day[1] -= 1;
            day = new Date(Date.UTC.apply(Date, day));
            if (!day.getDate()) return NaN;
            if (p[5]) {
                tz = (parseInt(p[5], 10) * 60);
                if (p[6]) tz += parseInt(p[6], 10);
                if (p[4] == '+') tz *= -1;
                if (tz) day.setUTCMinutes(day.getUTCMinutes() + tz);
            } else if (!p[3]) {
                var offset = new Date().getTimezoneOffset();
                day.setMinutes(day.getMinutes() + offset);
            }
            return day;
        }
        return NaN;
    };

    /**
     * Add the specified days to the date. This function also deals with daylight savings.
     * @param val The number of days which will be added, may be negative.
     * @return Number The time in milliseconds.
     */
    Date.prototype.addDays = function(val) {

        if(!isNaN(val)) {
            this.setDate(this.getDate() + val);
        }

        return this.getTime();
    };

    function createRegex(pattern) {
        var key = pattern.join("");

        if(regexCache[key]) {
            return regexCache[key];
        }

        var regex = new RegExp();

        _.each(pattern, function(element, index, array) {
            switch(element) {
                case "YYYY":
                    regex = new RegExp(regex.source + /(\d\d\d\d)/.source);
                    break;
                case "M": case "MM": case "D": case "DD": case "HH": case "mm": case "ss":
                    regex = new RegExp(regex.source + /(\d\d?)/.source);
                    break;
                case "MMM":
                    regex = arrayToRegex(Date.defaults.monthNamesShort);
                    break;
                case "MMMM":
                    regex = arrayToRegex(Date.defaults.monthNames);
                    break;
                case "sss":
                    regex = new RegExp(regex.source + /(\d\d\d)/.source);
                    break;
                case "Z":
                    regex = new RegExp(regex.source + /(?:(Z)|(\+|-)(\d\d):(\d\d))/.source);
                    break;
                case "\\": case "^": case "$": case "*": case "+":  case "?": case ".":
                    regex = new RegExp(regex.source + "\\" + element);
                    break;
                default:
                    regex = new RegExp(regex.source + element);
            }
        });

        regexCache[key] = new RegExp( /^/.source + regex.source + /$/.source );
        return regexCache[key];
    }

    function arrayToRegex(array) {
        return new RegExp( "(" + array.join("|") + ")" );
    }

    /**
     * Converts the Date format used in the rise helper to the format which is understood by the jquery UI date picker
     *
     * @param {string} dateFormat
     * @returns {string}
     */
    rise.Date.convertFormatToJqUi = function (dateFormat) {
        return dateFormat.replace((/YY/g), 'y').replace((/M/g), 'm').replace(/D/g, 'd');
    }
}());
