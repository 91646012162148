(function(app) {
    "use strict";

    /*** EXPORTS ***/
    app.widgets.TourTimeCForm           = TourTimeCForm;
    app.widgets.TourTimeCFormList       = TourTimeCFormList;
    app.widgets.TourTimeCSelector       = TourTimeCSelector;
    app.widgets.TourTimeCListSelector   = TourTimeCListSelector;

    /*** IMPORTS ***/
    var form            = app.widgets.form;
    var TourTimeC       = app.model.TourTimeC;
    var textButton      = app.widgets.textButton;
    var SelectDialog    = app.widgets.SelectDialog;

    /*** CONSTANTS ***/
    var types = [
        {'id' : 'DAILY', 'label': 'Täglich'},
        {'id' : 'WEEKLY', 'label': 'Wöchentlich'},
        {'id' : 'CALENDAR', 'label': 'Kalender'}
    ];

    var weekDays = [
        {'id' : 1, 'label': 'Montag'},
        {'id' : 2, 'label': 'Dienstag'},
        {'id' : 3, 'label': 'Mittwoch'},
        {'id' : 4, 'label': 'Donnerstag'},
        {'id' : 5, 'label': 'Freitag'},
        {'id' : 6, 'label': 'Samstag'},
        {'id' : 0, 'label': 'Sonntag'}
    ];

    var TypeEnum = {
        DAILY: 'DAILY',
        WEEKLY: 'WEEKLY',
        CALENDAR: 'CALENDAR'
    };

    /*************************************************************************/

    function TourTimeCForm(calendars, timec) {
        this.timec = _.extend({}, timec);
        this.calendars = calendars;
    }

    TourTimeCForm.prototype.getTimeConstraint = function () {
        return new TourTimeC(this.timec);
    };

    TourTimeCForm.prototype.setRemoveActionCallback = function (fn) {
        this.removeActionCallback = fn;
    };

    TourTimeCForm.prototype.render = function($parent, view) {
        var self = this;

        $parent.appendElement(div({
            'create': function() {
                self.$wrapperDiv = $(this);
                self.$wrapperDiv.empty();
                self.$wrapperDiv.appendElement(self.renderWidget(view));
            }
        }));
    };

    /**
     * Renders the tour time constraint form. Also used for the customer print preview.
     */
    TourTimeCForm.prototype.renderWidget = function(view) {
        var self = this;
        self.type = getTypeOfTimeC(self.timec);
        self.timec.timec_type = self.type.id;

        return fieldset({'class': ''}, [
            view ? undefined :
                a({title:"Löschen", "class":"button delete image-only right",
                    'click': function() {
                        self.removeActionCallback(self);
                    }
                  }, img({ src:"style/images/content/cross.png" })),
            ul({"class":"fieldset-content"}, _.flatten([
                form.formItemSelect({id: "timec-type-select", label: 'Wiederholung', required: true ,
                    source: types, valueProp: 'label',
                    value: self.type, sort: false,
                    selected: function(e, selected) {
                        if(self.type.id !== selected.id) {
                            self.type = selected;
                            self.timec = {timec_type: self.type.id};

                            self.$wrapperDiv.empty();
                            self.$wrapperDiv.appendElement(self.renderWidget(view));
                        }
                    }
                }, view),
                self.renderBody(view)
            ]))
        ]);
    };

    TourTimeCForm.prototype.renderBody = function(view) {
        var self = this;

        switch (self.type.id) {
            case TypeEnum.DAILY:
                return self.renderDaily(view);
            case TypeEnum.WEEKLY:
                return self.renderWeekly(view);
            case TypeEnum.CALENDAR:
                return self.renderCalendar(view);
            default:
                return undefined;
        }
    };

    TourTimeCForm.prototype.renderTimeCForm = function (view) {
        var self = this,
            now = new JustTime(),
            assignedTourAreas = _.map(self.timec.tour_areas, function(ta) { return ta && ta.name; });

        self.timec.time_from = self.timec.time_from ? self.timec.time_from : now;
        self.timec.time_to = self.timec.time_to ? self.timec.time_to :
            new JustTime(now.getHours() + 1, now.getMinutes());
        self.timec.quota = self.timec.quota ? self.timec.quota : 1;

        return [
            form.formItemTime({id: "timec-time-from", label: 'Von', required: true,
                time: self.timec.time_from,
                change: function() {
                    if ($(this).val()) {
                        self.timec.time_from = new JustTime($(this).datepicker('getDate'));
                    }
                }
            }, view),
            form.formItemTime({id: "timec-time-to", label: 'Bis', required: true,
                time: self.timec.time_to,
                change: function() {
                    if ($(this).val()) {
                        self.timec.time_to = new JustTime($(this).datepicker('getDate'));
                    }
                }
            }, view),
            form.formItemText({id: "timec-type-quota", label: 'Anzahl', required: true,
                value: self.timec.quota,
                style: "width:100px",
                change: function () {
                    self.timec.quota = parseInt($(this).val());
                }
            }, view),

            // display assigned tour areas
            view && self.timec.tour_areas && self.timec.tour_areas.length > 0
                ? form.formItemText({id: "timec-type-quota", label: 'Zugewiesene Reviere', required: true,
                    value: assignedTourAreas.join(', ')
                  }, true)
                : undefined
        ];
    };

    TourTimeCForm.prototype.renderDaily = function(view) {
        return this.renderTimeCForm(view);
    };

    TourTimeCForm.prototype.renderWeekly = function (view) {
        var self = this;

        self.timec.weekdays = self.timec.weekdays ? self.timec.weekdays : "";

        return _.flatten([
            form.formItemCheckboxList({id: 'weekly-day', label: 'Tag', required: true,
                options: _.map(weekDays, function(day) { return day.label }),
                value: function() {
                    return _.map(self.timec.weekdays, function(weekdayId) {
                            var found = $.grep(weekDays, function(day) {
                                return day.id == weekdayId;
                            });

                            return found.length > 0 ? found[0].label : undefined;
                    });
                }(),
                listChanged: function(selectedDays) {
                    selectedDays = _.map(selectedDays, function(selectedDay) {
                        return $.grep(weekDays, function (day) {
                            return day.label == selectedDay;
                        })[0].id;
                    });
                    self.timec.weekdays = selectedDays;
                }
            }, view),
            self.renderTimeCForm(view)
        ]);
    };

    TourTimeCForm.prototype.renderCalendar = function (view) {
        var self = this,
            calendarSelectList = _.map(self.calendars, function(calendar) {
                return {
                    'label': calendar.name,
                    'id': calendar.id
                };
            }),
            selected = _.find(calendarSelectList, function(e) {
                return e.id === self.timec.calendar_id;
            });

        return _.flatten([
            form.formItemSelect({id: "calendar-select", label: 'Kalender', required: true,
                value: selected, source: calendarSelectList, valueProp: 'label',
                selected: function(e, selected) {
                    self.timec.calendar_id = selected.id;
                }
            }, view),
            self.renderTimeCForm(view)
        ]);
    };

    TourTimeCForm.prototype.validate = function () {
        var self = this;

        if (self.timec.timec_type === 'WEEKLY' && self.timec.weekdays.length === 0) {
            app.showErrorMessage("Mindestens ein Wochentag muss ausgewählt werden");
            return false;
        }

        return true;
    };

    /*********************************************************************************/

    function TourTimeCFormList(timecs, calendars) {
        this.timecForms = _.map(timecs, function(timec) {
            return new TourTimeCForm(calendars, timec);
        });

        this.calendars = calendars;
    }

    TourTimeCFormList.prototype.render = function($parent, view) {
        var self = this;

        if (view && self.timecForms.length == 0) {
            return;
        }

        var addTimeConstraintForm = function() {
            self.timecForms.push(new TourTimeCForm(self.calendars));

            $("#tour-time-constraints-div").remove();
            self.render($parent, view);
        };

        var removeTimeConstraintForm = function(tcForm) {
            self.timecForms = _.without(self.timecForms, tcForm);

            $("#tour-time-constraints-div").remove();
            self.render($parent, view);
        };

        $parent.appendElement(div({id: 'tour-time-constraints-div'}, [
            h2({}, "Zeitliche Einstellungen"),
            div({id: 'tour-time-constraints-list',
                create: function(e) {
                    var $listDiv = $(e.target);

                    _.each(self.timecForms, function(tf) {
                        tf.setRemoveActionCallback(removeTimeConstraintForm);
                    });

                    _.each(self.timecForms, function(tf) {
                        tf.render($listDiv, view);
                    });
                }
            }),
            view ? undefined :
                a({title: "Neue Einstellung", "class": "button add", "click": addTimeConstraintForm },
                    img({ src: "style/images/content/plus.png" })
                )
        ]));

    };

    TourTimeCFormList.prototype.getTimeConstraints = function() {
        var self = this;

        return _.map(self.timecForms, function(tcForm) {
            return tcForm.getTimeConstraint();
        });
    };

    TourTimeCFormList.prototype.validate = function() {
        var self = this,
            valid = true;

        _.each(self.timecForms, function(tf) {
            if (!tf.validate()) {
                valid = false;
            }
        });

        return valid;
    };

    /*********************************************************************************/

    function TourTimeCSelector(params) {
        this.$parent = params.parent;

        if (!params.parent) {
            throw "No parent element provided!";
        }

        this.options = $.extend({
            change: null,
            selected: new app.model.TourAreaTimeC(),
            editable: true,
            hidden: false,
            calendars: [],
            referencedTour: null,
            onRemove: null,
            onSelect: null,
            fnGetCustomerByLocationId: null,
            fnGetLocationByCustomerId: null,
            fnGetLocationByLocationId: null,
            fnGetLocationByObjectId: null,
            fnGetLocationByWorkplace: null
        }, params);

        // init available tour time constraints
        this.tourTimeConstraints = [];

        this.init();
    }

    TourTimeCSelector.prototype.init = function() {
        var self = this;

        // init selected tour
        self.selectedTour = null;

        // init selected timec
        self.selectedTimeC = null;

        // render widget
        self.$widget = self.$parent.appendElement(
            fieldset({'class': '', style: self.options.hidden ? 'display:none' : ''}, [
                self.options.editable
                    ? a({title:"Löschen", "class":"button delete image-only", "style":"float:right",
                        'click': function() {
                            if (self.options.onRemove) {
                                self.options.onRemove(self);
                            }

                            // remove widget from dom tree
                            self.hide().always(
                                function() {
                                    self.destroy();
                                    self.$widget.remove();
                                });

                            if (self.options.onSelect) {
                                self.options.onSelect();
                            }
                        }
                      }, img({ src:"style/images/content/cross.png" }))
                    : undefined,
                ul({"class":"fieldset-content"}, [
                    li({'class': 'fieldset-item'}, [
                        label({ 'class': self.options.editable ? "required" : "" }, "Rundgang"),
                        span({ 'class': 'selected-tour-info', 'style': 'display:none' }),
                        self.options.editable
                            ? textButton({ id: "tour-select-button", click: $.proxy(self._selectTour, self) }, [
                                "Rundgang wählen"
                              ])
                            : undefined
                    ]),
                    form.formItemTextarea({id: 'tour-area-timec-notes',
                        label: 'Anmerkungen',
                        required: false,
                        "class": "x-large-input",
                        value: self.options.selected.note,
                        change: function (e) {
                            var $target = $(e.target);

                            self.options.selected.note = $target.val();
                        }
                    }, !self.options.editable),
                    li({'class': 'fieldset-item address-list-widget', style: 'display:none'}, [
                        label('Adresse'),
                        div({ 'class': 'address-list'})
                    ])
                ])
            ])
        );

        if (self.options.selected && self.options.referencedTour) {
            self._onSelectTour(self.options.referencedTour.tour, self.options.referencedTour.assignments,
                self.options.referencedTour.timecs);
        }
    };

    TourTimeCSelector.prototype._selectTour = function() {
        var self = this,
            existingSelectDialog = self.$parent.find('.tour-select-list').data('selectDialog'),
            $parent;

        // remove existing dialog instances
        if (existingSelectDialog) {
            existingSelectDialog.destroy();
            $('.tour-select-list').remove();
        }
        $('#select-dialog-table').parents('.ui-dialog').remove();

        $parent = this.$parent.appendElement(div({ 'class': 'tour-select-list' }));

        // init tour selection dialog
        self.selectDialog = new SelectDialog({
            id: "tour-select-dialog",
            parent: $parent,
            selected: $.proxy(self._onSelectTour, self),
            listPage: app.pages.tour.list,
            pageParams: {
                filterToursWithTimeC: true
            },
            title: "Rundgang wählen",
            width: 1250
        });

        // add dialog instance to dom data
        $parent.data('selectDialog', self.selectDialog);

        self.selectDialog.open();
    };

    TourTimeCSelector.prototype._onSelectTour = function(tour, assignments, timecs) {
        var self = this,
            $selectedTourInfo = self.$widget.find('.selected-tour-info'),
            formattedTourName;

        // update selected tour
        self.selectedTour = tour;

        // format tour name
        if (tour.description) {
            formattedTourName = sprintf("%s (%s)", tour.name, tour.description || "");
        } else {
            formattedTourName = tour.name;
        }

        // disable select tour button
        self.$widget.find('#tour-select-button')
            .addClass('disabled');

        function updateTimeCs(timeConstraints) {
            $selectedTourInfo
                .show()
                .text(formattedTourName);

            // enable select tour button
            self.$widget.find('#tour-select-button')
                .removeClass('disabled');

            // update time constraint selection
            self._updateTourTimeConstraints(timeConstraints);

            if (!self.$widget.find('#tour-timec-select').length) {
                self.$widget.find("ul")
                    .appendElement(form.formItemSelect({id: 'tour-timec-select', label: 'Zeit',
                        required: self.options.editable,
                        "source": self._getTourTimeConstraints(),
                        "valueProp": 'name',
                        "value": _.findWhere(self._getTourTimeConstraints(), {
                            id: self.options.selected.tour_timec_id
                        }),
                        "class": "xx-large-input",
                        "selected": function (e, timeC) {
                            self.selectedTimeC = timeC;
                            self.options.selected.tour_timec_id = timeC.id;

                            if (self.options.onSelect) {
                                self.options.onSelect(timeC);
                            }
                        }
                    }, !self.options.editable)
                );
            }
        }

        if (timecs) {
            // render addresses
            self._updateAddresses(assignments);

            updateTimeCs(timecs);
        } else {
            app.dao.tourDao.getTourDetails(tour.id)
                .done(function (tourDetails) {
                    self._updateAddresses(tourDetails.assignments);
                    updateTimeCs(tourDetails.timecs);
                });
        }
    };

    TourTimeCSelector.prototype._updateAddresses = function(assignments) {
        var self = this,
            locationList = _.unique(_.flatten([]
                .concat(_.map(assignments.customers || [],
                    function(c) { return self.options.fnGetLocationByCustomerId (c); }))
                .concat(_.map(assignments.locations || [],
                    function(l) { return self.options.fnGetLocationByLocationId (l); }))
                .concat(_.map(assignments.objects || [],
                    function(o) { return self.options.fnGetLocationByObjectId (o); }))
                .concat(_.map(assignments.workplaces || [],
                    function(w) { return self.options.fnGetLocationByWorkplaceId (w); }))
            )),
            $addressList = self.$widget.find('.address-list').empty();

        _.each(locationList || [], function(location) {
            $addressList.appendElement(div({ 'class': 'address' }, [
                div({ 'class': 'customer-name' }, self.options.fnGetCustomerByLocationId(location.id).name),
                div({ 'class': 'location-name' }, location.name),
                div({ 'class': 'street' }, location.address ),
                div({ 'class': 'zip' }, location.zip ),
                div({ 'class': 'city' }, location.city ),
                div({ 'class': 'country' }, location.country )
            ]));
        });

        // show addresses
        self.$widget.find('.address-list-widget').show();
    };

    TourTimeCSelector.prototype._getTourTimeConstraints = function() {
        return this.tourTimeConstraints;
    };

    TourTimeCSelector.prototype._updateTourTimeConstraints = function(timeConstraints) {
        var self = this;

        this.tourTimeConstraints.length = 0;

        this.tourTimeConstraints.splice.apply(this.tourTimeConstraints, [0, 0].concat(
            _.map(timeConstraints, function(timeConstraint) {
                return {
                    id: timeConstraint.id,
                    name: self._formatTourTimeConstraint(timeConstraint)
                };
            })
        ));

        this.$widget.find('#tour-timec-select').val("");
    };

    TourTimeCSelector.prototype._formatTourTimeConstraint = function(timeConstraint) {
        return timeConstraint.toString(this.options.calendars);
    };

    TourTimeCSelector.prototype.show = function() {
        var self = this,
            deferred = new $.Deferred();

        self.$widget.slideDown('fast', function() {
            deferred.resolve();
        });

        return deferred.promise();
    };

    TourTimeCSelector.prototype.hide = function() {
        var self = this,
            deferred = new $.Deferred();

        self.$widget.slideUp('fast', function() {
            deferred.resolve();
        });

        return deferred.promise();
    };

    TourTimeCSelector.prototype.destroy = function() {
        var self = this;

        if (self.selectDialog) {
            self.selectDialog.destroy();
        }
    };

    TourTimeCSelector.prototype.getSelected = function() {
        if (this.options.selected.tour_timec_id) {
            return this.options.selected;
        }

        return null;
    };

    /*********************************************************************************/

    function TourTimeCListSelector(params) {
        var self = this;

        this.$parent = params.parent;

        if (!params.parent) {
            throw "No parent element provided!";
        }

        if (!params.customers) {
            throw "No customer list provided!";
        }

        this.options = $.extend({
            change: null,
            selected: [],
            editable: true,
            calendars: [],
            customers: [],
            referencedTours: [],
            onSelect: null
        }, params);

        // map customer structure
        this.locationCustomerMap = {};
        this.customerLocMap = {};
        this.locationLocMap = {};
        this.objectLocMap = {};
        this.workplaceLocMap = {};

        _.each(self.options.customers, function(customer) {
            self.customerLocMap[customer.id] = customer.locations;
            _.each(customer.locations, function(location) {
                self.locationLocMap[location.id] = location;
                self.locationCustomerMap[location.id] = customer;
                _.each(location.objects, function(object) {
                    self.objectLocMap[object.id] = location;
                    _.each(object.workplaces, function(workplace) {
                        self.workplaceLocMap[workplace.id] = location;
                    });
                });
            });
        });

        this.init();
    }

    TourTimeCListSelector.prototype.init = function() {
        var self = this;

        // init list of TourTimeCSelector widgets
        self.selectionWidgets = [];

        self.$parent.appendElement(
            div({id: 'tour-area-timec-form'}, [
                div({id: 'tour-area-timec-list',
                    create: function (e) {
                        var $tourAreaTimeCList = $(e.target);

                        _.each(self.options.selected, function(tour_area_timec) {
                            self.selectionWidgets.push(
                                new TourTimeCSelector({
                                    parent: $tourAreaTimeCList,
                                    calendars: self.options.calendars,
                                    onSelect: $.proxy(self._onChangeSelection, self),
                                    onRemove: $.proxy(self._onRemove, self),
                                    selected: tour_area_timec,
                                    referencedTour: self._getTourOfTimeC(tour_area_timec.tour_timec_id),
                                    editable: self.options.editable,
                                    fnGetCustomerByLocationId: $.proxy(self._getCustomerByLocationId, self),
                                    fnGetLocationByCustomerId: $.proxy(self._getLocationByCustomerId, self),
                                    fnGetLocationByLocationId: $.proxy(self._getLocationByLocationId, self),
                                    fnGetLocationByObjectId: $.proxy(self._getLocationByObjectId, self),
                                    fnGetLocationByWorkplaceId: $.proxy(self._getLocationByWorkplaceId, self)
                                })
                            );
                        });
                    }
                }),
                self.options.editable
                    ? a({title: "Rundgang hinzufügen", "class": "button add",
                        click: function() {
                            var selectorWidget = new TourTimeCSelector({
                                parent: self.$selectorWidgets,
                                hidden: true,
                                calendars: self.options.calendars,
                                onSelect: $.proxy(self._onChangeSelection, self),
                                onRemove: $.proxy(self._onRemove, self),
                                editable: self.options.editable,
                                fnGetCustomerByLocationId: $.proxy(self._getCustomerByLocationId, self),
                                fnGetLocationByCustomerId: $.proxy(self._getLocationByCustomerId, self),
                                fnGetLocationByLocationId: $.proxy(self._getLocationByLocationId, self),
                                fnGetLocationByObjectId: $.proxy(self._getLocationByObjectId, self),
                                fnGetLocationByWorkplaceId: $.proxy(self._getLocationByWorkplaceId, self)
                            });

                            // open the widget
                            selectorWidget.show();

                            self.selectionWidgets.push(selectorWidget);
                        }
                      },
                        img({ src: "style/images/content/plus.png"})
                      )
                    : undefined
            ])
        );

        self.$selectorWidgets = self.$parent.find('#tour-area-timec-list');
    };

    TourTimeCListSelector.prototype._getCustomerByLocationId = function(locationId) {
        return this.locationCustomerMap[locationId];
    };

    TourTimeCListSelector.prototype._getLocationByCustomerId = function(customerId) {
        return this.customerLocMap[customerId];
    };

    TourTimeCListSelector.prototype._getLocationByLocationId = function(locationId) {
        return this.locationLocMap[locationId];
    };

    TourTimeCListSelector.prototype._getLocationByObjectId = function(objectId) {
        return this.objectLocMap[objectId];
    };

    TourTimeCListSelector.prototype._getLocationByWorkplaceId = function(workplaceId) {
        return this.workplaceLocMap[workplaceId];
    };

    TourTimeCListSelector.prototype.destroy = function() {
        _.each(this.selectionWidgets, function(widget) {
            widget.destroy();
        });
    };

    TourTimeCListSelector.prototype._onChangeSelection = function() {
        var self = this;

        if (this.options.onSelect) {
            this.options.onSelect(self.getSelected());
        }
    };

    TourTimeCListSelector.prototype._onRemove = function(removedWidget) {
        var self = this;

        self.selectionWidgets = _.without(self.selectionWidgets, removedWidget);
    };

    TourTimeCListSelector.prototype.getSelected = function() {
        var selection = [];

        _.each(this.selectionWidgets, function(widget) {
            var tourAreaTimeC = widget.getSelected();

            if (tourAreaTimeC !== null && tourAreaTimeC.tour_timec_id !== undefined &&
                !_.contains(_.pluck(selection, "tour_timec_id"), tourAreaTimeC)) {
                selection.push(tourAreaTimeC);
            }
        });

        return selection;
    };

    TourTimeCListSelector.prototype._getTourOfTimeC = function(timec_id) {
        var self = this,
            tour;

        _.each(self.options.referencedTours || [], function(t) {
            if (!!_.findWhere(t.timecs, {id: timec_id})) {
                tour = t;
            }
        });

        return tour;
    };

    /*************************************************************** PRIVATE ********/

    function getTypeOfTimeC(timec) {
        var i;

        for (i = 0; i < types.length; i++) {
            if (types[i].id === timec.timec_type) {
                return types[i];
            }
        }

        return types[0];
    }

})(window.app);
