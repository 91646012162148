/* eslint-disable camelcase */
import $ from 'jquery';
import _ from 'underscore';

import { Rpc } from 'lib/rise/rpc';

import { ReportScheduleDetailsRequestDto } from 'app/report-schedule/dto/ReportScheduleDetailsRequestDto';
import { ReportInterval, ReportType } from 'app/report-schedule/ReportScheduleConstants';
import { ReportSchedule } from 'app/report-schedule/ReportSchedule';
import { ReportScheduleListEntryDto } from 'app/report-schedule/dto/ReportScheduleListEntryDto';
import { Dao } from 'app/Dao';

export interface ReportScheduleFilterParams {
    type?: ReportType;
    interval?: ReportInterval;
    customer_id?: number;
    location_id?: number;
    object_id?: number;
    tour_area_id?: number;
    offset: number;
    limit: number;
    sort_column?: string;
    sort_direction?: string;
    active?: boolean;
}

class ReportScheduleDao extends Dao<ReportScheduleDetailsRequestDto, ReportSchedule> {
    constructor() {
        super(ReportScheduleDetailsRequestDto, ReportSchedule, new Rpc('/rpc/report_schedule'));
    }

    async insert(model: ReportSchedule): Promise<ReportSchedule> {
        return super.insert(new ReportScheduleDetailsRequestDto(model));
    }

    async update(model: ReportSchedule): Promise<ReportSchedule> {
        return super.update(new ReportScheduleDetailsRequestDto(model));
    }

    findReportSchedules(filterParams: ReportScheduleFilterParams):
        Promise<{ rows: Array<ReportScheduleListEntryDto>; totalCount: number; filteredCount: number }> {
        const deferred = $.Deferred();
        const params = {
            filter_params: filterParams,
        };

        this.rpc.invoke('find_report_schedules', params)
            .done((result) => {
                const list = {
                    rows: _.map(result.rows, (e) => new ReportScheduleListEntryDto(e)),
                    totalCount: result.total_count,
                    filteredCount: result.filtered_count,
                };
                deferred.resolve(list);
            })
            .fail((error) => {
                deferred.reject(error);
            });

        return Promise.resolve(deferred.promise());
    }

    getReportSchedule(reportScheduleId: number): Promise<ReportSchedule> {
        const deferred = $.Deferred();

        this.rpc.invoke('get_report_schedule_details', { report_schedule_id: reportScheduleId })
            .done((result) => {
                deferred.resolve(new ReportSchedule(result));
            })
            .fail((error) => {
                deferred.reject(error);
            });

        return Promise.resolve(deferred.promise());
    }
}

export const reportScheduleDao = new ReportScheduleDao();
