/**
 * Print report widgets.
 *
 * @module app.widgets.print
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function (app) {
    "use strict";

    /*** CONSTANTS ***/
    var REPORT_FETCH_DAYS_AT_ONCE = 4;

    /** EXPORT **/
    app.widgets.print = {
        tourReport: printTourReport,
        tourEventReport: printTourEventReport
    };

    function formatDate(justDate) {
        return justDate.toDate().toFormatString("DDD " + app.settings.dateFormat);
    }

    function formatDateTime(date) {
        return date.toFormatString("DDD " + app.settings.dateFormat + " HH:mm");
    }

    function addTime(justDate, h, m, s, ms) {
        var date = justDate.toDate();
        date.setHours(h);
        date.setMinutes(m);
        date.setSeconds(s);
        date.setMilliseconds(ms);

        return date;
    }

    function printTourEventReport(title, customers, tour_areas, users, filter) {
        var selectedCustomer = filter.customer_id
            ? _.findWhere(customers, {id: filter.customer_id})
            : null,
            selectedLocation = filter.location_id
                ? _.findWhere((selectedCustomer && selectedCustomer.locations) || [], {id: filter.location_id})
                : null,
            selectedObject = filter.object_id
                ? _.findWhere((selectedLocation && selectedLocation.objects) || [], {id: filter.object_id})
                : null,
            selectedTourArea = filter.tour_area_id
                ? _.findWhere(tour_areas, {id: filter.tour_area_id})
                : null,
            selectedUser = filter.user_id
                ? _.findWhere(users, {id: filter.user_id})
                : null,
            tourRunDao = app.dao.tourRunDao;

        function renderEventReportHeaderAdditions($parent) {
            // render selected date range
            $parent.appendElement([
                div({"class": "filter-info"}, [
                    div({"class": "date-range"},
                        sprintf("Auswerte-Zeitraum: %s bis %s ",
                            formatDateTime(filter.started), formatDateTime(filter.stopped))
                    ),
                    div({"class": "customer-info"},
                        sprintf("Kunde: %s, Standort: %s, Objekt: %s",
                            ((selectedCustomer && selectedCustomer.name) || "Alle"),
                            ((selectedLocation && selectedLocation.name) || "Alle"),
                            ((selectedObject && selectedObject.name) || "Alle")
                        )
                    ),
                    div({"class": "tour-area-info"},
                        sprintf("Revier: %s, Benutzer: %s",
                            ((selectedTourArea && selectedTourArea.name) || "Alle"),
                            ((selectedUser && selectedUser.username) || "Alle")
                        )
                    )
                ])
            ]);
        }

        function renderEventReportBody($parent) {
            var deferred = $.Deferred();
            $parent.appendElement(span('Bericht wird geladen ...'));

            tourRunDao.getCheckpointsWithIncident({
                begin: filter.started,
                end: filter.stopped,
                customer_id: filter.customer_id,
                location_id: filter.location_id,
                object_id: filter.object_id,
                tour_area_id: filter.tour_area_id,
                user_id: filter.user_id
            }).done(function (tourCheckpointsWithIncident) {
                $parent.empty();

                if (!tourCheckpointsWithIncident.length) {
                    $parent.appendElement(span('Es wurden keine Ereignisse zu den angegebenen Filterkriterien gefunden!'));
                }

                _.each(tourCheckpointsWithIncident, function (tourCheckpointWithIncident) {
                    $parent.appendElement(div({"class": "checkpoint-with-incident"},
                        ul({"class": "checkpoint-incident-details"}, [
                            li({}, [
                                label('Zeitpunkt: '),
                                span(tourCheckpointWithIncident.check_time
                                    ? formatDateTime(tourCheckpointWithIncident.check_time)
                                    : '-')
                            ]),
                            li({}, [
                                label('Kunde: '),
                                span(tourCheckpointWithIncident.customer_name)
                            ]),
                            li({}, [
                                label('Standort: '),
                                span(tourCheckpointWithIncident.location_name)
                            ]),
                            li({}, [
                                label('Objekt: '),
                                span(tourCheckpointWithIncident.object_name)
                            ]),
                            li({}, [
                                label('Rundgang: '),
                                span(tourCheckpointWithIncident.tour_name)
                            ]),
                            li({}, [
                                label('Mitarbeiter/in: '),
                                span(tourCheckpointWithIncident.user_first_name + ' '
                                    + tourCheckpointWithIncident.user_last_name + ' ('
                                    + tourCheckpointWithIncident.user_name + ')')
                            ]),
                            li({}, [
                                label('Kontrollpunkt: '),
                                span(tourCheckpointWithIncident.tag_name)
                            ]),
                            li({}, [
                                label('Wachmeldung: '),
                                span(tourCheckpointWithIncident.info)
                            ]),
                            tourCheckpointWithIncident.images.length ? li({}, [
                                label('Fotos: '),
                                ul({
                                    "class": "checkpoint-incident-photos"
                                }, _.map(tourCheckpointWithIncident.images, function (image) {
                                    return li({}, img({
                                        'style': "maxwidth:100%",
                                        'src': "data:image/jpeg;base64," + image
                                    }));
                                }))
                            ]) : undefined,
                        ])
                    ));
                });
                deferred.resolve();
            }).fail(function (error) {
                deferred.reject(error);
            });

            return deferred.promise();
        }

        app.printpreview.openPrintPreview({
            title: title,
            fnRenderBody: renderEventReportBody,
            fnRenderHeaderAdditions: renderEventReportHeaderAdditions
        });
    }

    function printTourReport(title, customers, tour_areas, users, renderSuccessfulTagActions, renderFailedTagActions, filter) {
        var selectedCustomer = filter.customer_id
            ? _.findWhere(customers, {id: filter.customer_id})
            : null,
            selectedLocation = filter.location_id
                ? _.findWhere((selectedCustomer && selectedCustomer.locations) || [], {id: filter.location_id})
                : null,
            selectedObject = filter.object_id
                ? _.findWhere((selectedLocation && selectedLocation.objects) || [], {id: filter.object_id})
                : null,
            selectedTourArea = filter.tour_area_id
                ? _.findWhere(tour_areas, {id: filter.tour_area_id})
                : null,
            selectedUser = filter.user_id
                ? _.findWhere(users, {id: filter.user_id})
                : null,
            reportDao = app.dao.tourReportDao,
            tourRunDao = app.dao.tourRunDao;

        function renderReportBody($parent) {
            var $tourRunsByDate = $parent.appendElement([
                    div({"class": "tour-runs-by-date"})
                ]),
                numTourRuns = 0, numMissingTourRuns = 0,
                numCheckpoints = 0, numCheckpointsCorrect = 0, numCheckpointsMissing = 0,
                durationInMinutes = 0;

            var $summary = $parent.appendElement(
                div({"class": "summary"}, [
                    h2("Gesamtstatistik"),
                    ul({"class": "stats"}, [
                        li({"id": "tour-count"}, [
                            label("Durchgeführte Kontrollgänge: "),
                            span({"id": "num-tour-runs"}, numTourRuns)
                        ]),
                        li([
                            label("Stechungen: "),
                            span({"id": "num-checkpoints"}, numCheckpoints)
                        ]),
                        li([
                            label("Gesamtdauer: "),
                            span({"id": "duration"})
                        ])
                    ])
                ])
            );

            // render relation between correct and incorrect checked tags
            if (renderFailedTagActions) {
                $summary.find("#tour-count")
                    .afterElement([
                        li({"class": "missing-tour-runs"}, [
                            label("Kontrollgänge mit unzur. Wiederh.:"),
                            span({"id": "num-missing-tour-runs"}, numMissingTourRuns)
                        ])
                    ]);

                $summary.find("ul.stats")
                    .appendElement([
                        li([
                            label("Richtig gestochen: "),
                            span({"id": "checkpoints-correct-abs"}),
                            span({"id": "checkpoints-correct-rel"})
                        ]),

                        li([
                            label("Nicht gestochen: "),
                            span({"id": "checkpoints-missing-abs"}),
                            span({"id": "checkpoints-missing-rel"})
                        ])
                    ]);
            }

            function fnUpdateStats(incTourRuns, incMissingTourRuns, incCorrectCheckpoints, incMissingCheckpoints,
                                   incDurationInMinutes) {
                numTourRuns += incTourRuns;
                numMissingTourRuns += incMissingTourRuns;
                numCheckpointsCorrect += incCorrectCheckpoints;
                numCheckpointsMissing += incMissingCheckpoints;
                numCheckpoints += (incCorrectCheckpoints + incMissingCheckpoints);
                durationInMinutes += incDurationInMinutes;

                $summary.find("#num-tour-runs").text(numTourRuns);
                $summary.find("#num-missing-tour-runs").text(numMissingTourRuns);
                $summary.find("#num-checkpoints").text(numCheckpoints);
                $summary.find("#duration").text(
                    durationInMinutes > 60
                        ? sprintf("%s Std. %s Min.", parseInt(durationInMinutes / 60, 10), durationInMinutes % 60)
                        : sprintf("%s Min.", durationInMinutes)
                );

                if (renderFailedTagActions) {
                    $summary.find("#checkpoints-correct-abs").text(numCheckpointsCorrect);
                    $summary.find("#checkpoints-correct-rel").text(
                        sprintf("(%.1f%%)", numCheckpoints > 0 ? (100.0 / numCheckpoints * numCheckpointsCorrect) : 0));

                    $summary.find("#checkpoints-missing-abs").text(numCheckpointsMissing);
                    $summary.find("#checkpoints-missing-rel").text(
                        sprintf("(%.1f%%)", numCheckpoints > 0 ? (100.0 / numCheckpoints * numCheckpointsMissing) : 0));
                }
            }

            return renderTourRunsByDate($tourRunsByDate, new JustDate(filter.started),
                REPORT_FETCH_DAYS_AT_ONCE, renderSuccessfulTagActions, renderFailedTagActions, fnUpdateStats, 0);
        }

        function getDurationDeviation(duration, minDuration, maxDuration) {
            if (minDuration && duration < minDuration) {
                return duration - minDuration;
            } else if (maxDuration && duration > maxDuration) {
                return duration - maxDuration;
            }

            return 0;
        }

        function renderTourRunsByDate($parent, startDate, daysToFetchAtOnce, renderSuccessfulTagActions,
                                      renderFailedTagActions, fnUpdateStats, offsetCorrectCheckpoints) {
            var endDate = new JustDate(startDate.toDate()),
                deferred = new $.Deferred(),
                stoppedDate = new JustDate(filter.stopped);

            // define end date based on fetch interval
            endDate.addDays(daysToFetchAtOnce - 1);
            if (endDate > stoppedDate) {
                endDate = stoppedDate;
            }

            var begin = addTime(startDate, 0, 0, 0, 0),
                end = addTime(endDate, 23, 59, 59, 999);

            if (filter.started > begin) {
                begin = filter.started;
            }

            if (filter.stopped < end) {
                end = filter.stopped;
            }

            $.when(
                tourRunDao.findFinishedTourRunDetails({
                    sort_column: 1,
                    sort_direction: "asc",
                    begin: begin,
                    end: end,
                    customer_id: filter.customer_id,
                    location_id: filter.location_id,
                    object_id: filter.object_id,
                    tour_area_id: filter.tour_area_id,
                    user_id: filter.user_id
                }),
                renderFailedTagActions
                    ? reportDao.getTimecViolations(
                    begin,
                    end,
                    filter.customer_id,
                    filter.location_id,
                    filter.object_id,
                    filter.tour_area_id,
                    filter.user_id
                    )
                    : undefined
            ).done(function (tourRuns, timecViolations) {
                // render tour runs
                var dailyTourRuns, dailyTimecViolations, currentDateString,
                    $tourRuns, $missingTourRuns;

                tourRuns = tourRuns.rows;

                for (var currentDate = new JustDate(startDate.toDate());
                     currentDate <= endDate && currentDate <= stoppedDate;
                     currentDate = currentDate.addDays(1)) {

                    currentDateString = formatDate(currentDate);

                    // get tour-runs and timec-viloations of current day
                    var numTourRuns = 0;

                    dailyTourRuns = _.filter(tourRuns, function (tourRun) {
                        var isCurrentDate =
                            formatDate(new JustDate(tourRun.tourRun.start_time)) === currentDateString,
                            hasMissingCheckpoints = tourRun.missingCheckpoints.length > 0;

                        if (isCurrentDate) {
                            numTourRuns++;
                        }

                        return isCurrentDate && (hasMissingCheckpoints || renderSuccessfulTagActions);
                    });

                    fnUpdateStats(numTourRuns, 0, 0, 0, 0);

                    dailyTimecViolations = _.filter(timecViolations || [], function (violation) {
                        var isCurrentDate =
                            formatDate(new JustDate(violation.timestamp_from)) === currentDateString;

                        return isCurrentDate && renderFailedTagActions;
                    });

                    if (dailyTourRuns.length > 0 || dailyTimecViolations.length > 0) {
                        // print grouping header for date
                        $parent.appendElement(h3(currentDateString));
                        $tourRuns = $parent.appendElement(div({"class": "tour-runs"}));
                        $missingTourRuns = $parent.appendElement(div({"class": "missing-tour-runs"}));

                        // render tour runs
                        _.each(dailyTourRuns, function (tourRunDetail) {
                            tourRunDetail.checkpoints = _.flatten([
                                tourRunDetail.start !== null && tourRunDetail.start.check_time !== null ? tourRunDetail.start : [],
                                tourRunDetail.checkpoints,
                                tourRunDetail.invalidCheckpoints,
                                tourRunDetail.end !== null && tourRunDetail.end.check_time !== null ? tourRunDetail.end : []
                            ]);

                            tourRunDetail.checkpoints = _.sortBy(tourRunDetail.checkpoints, function (checkpoint) {
                                return checkpoint.check_time;
                            });

                            renderTourRun($tourRuns, tourRunDetail, renderSuccessfulTagActions, renderFailedTagActions,
                                fnUpdateStats, offsetCorrectCheckpoints);

                            // increment total checkpoint offset
                            offsetCorrectCheckpoints += (tourRunDetail.checkpoints || []).length;
                        });

                        // render time constraint violations
                        _.each(_.uniq(_.pluck(dailyTimecViolations, "tour_id")), function (tour_id) {
                            var tcViolations = _.where(dailyTimecViolations, {tour_id: tour_id});
                            renderMissingTourRun(
                                $missingTourRuns,
                                tour_id,
                                tcViolations[0].tour_name,
                                tcViolations[0].tour_customers,
                                tcViolations[0].tour_locations,
                                tcViolations[0].tour_objects,
                                tcViolations[0].tour_workplaces,
                                currentDate,
                                tcViolations,
                                fnUpdateStats
                            );
                        });
                    }
                }

                if (stoppedDate > endDate) {
                    endDate.addDays(1);
                    renderTourRunsByDate($parent, endDate, daysToFetchAtOnce,
                        renderSuccessfulTagActions, renderFailedTagActions,
                        fnUpdateStats, offsetCorrectCheckpoints)
                        .done(function () {
                            deferred.resolve();
                        })
                        .fail(function (error) {
                            deferred.reject(error);
                        });
                } else {
                    deferred.resolve();
                }
            })
                .fail(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise();
        }

        function renderMissingTourRun($parent, tourId, tourName, tourCustomers, tourLocations,
                                      tourObjects, tourWorkplaces, date, timeConstraints, fnUpdateStats) {
            $parent.appendElement(div({"class": "tour-run"}, [
                div({"class": "header "}, [
                    div({"class": "date"}, formatDate(date)),
                    div({"class": "title"}, [
                        tourName,
                        span({"class": "violation-type"}, ": Unzureichende Wiederholungen")
                    ]),
                    div({"class": "tour-info clow-info"}, [
                        sprintf(" (%s/%s/%s/%s)",
                            tourCustomers !== null ? tourCustomers : "-",
                            tourLocations !== null ? tourLocations : "-",
                            tourObjects !== null ? tourObjects : "-",
                            tourWorkplaces !== null ? tourWorkplaces : "-")
                    ])
                ]),
                div({"class": "timec-violations"}, [
                    label("Unzureichende Wiederholungen"),
                    ul(
                        _.map(timeConstraints, function (tc) {
                            return li({"class": "time-constraint "}, [
                                div({"class": "timespan"},
                                    sprintf("Zf. %s-%s: ",
                                        window.JustTime.formatTimeString(new JustTime(tc.timestamp_from)),
                                        window.JustTime.formatTimeString(new JustTime(tc.timestamp_to)))
                                ),
                                div({"class": "count"}, sprintf("%s/%s", tc.tourrun_count, tc.quota))
                            ]);
                        })
                    )
                ])
            ]));

            // update stats
            fnUpdateStats(0, 1, 0, 0, 0);
        }

        function renderTourRun($parent, tourRunDetails, renderSuccessfulTagActions, renderFailedTagActions,
                               fnUpdateStats, offsetCorrectCheckpoints) {
            var tourRun = tourRunDetails.tourRun,
                duration = Math.round((tourRun.end_time.getTime() - tourRun.start_time.getTime()) / (1000 * 60)),
                durationDeviation = getDurationDeviation(duration, tourRun.min_duration, tourRun.max_duration);

            var $tourRun = $parent.appendElement(div({"class": "tour-run"}, [
                div({"class": "header "}, [
                    div({"class": "date"}, formatDate(new JustDate(tourRun.start_time))),
                    div({"class": "title"}, [
                        tourRun.tour_name,
                        tourRun.photos_count > 0 ? " (Fotos vorhanden)" : "",
                        sprintf(", %s-%s",
                            window.JustTime.formatTimeString(new JustTime(tourRun.start_time)),
                            window.JustTime.formatTimeString(new JustTime(tourRun.end_time))),
                        sprintf(", Dauer: %s", tourRun.duration)
                    ]),
                    div({"class": "tour-info clow-info"}, [
                        sprintf(" (%s/%s/%s/%s)",
                            tourRun.customers !== null ? tourRun.customers : "-",
                            tourRun.locations !== null ? tourRun.locations : "-",
                            tourRun.objects !== null ? tourRun.objects : "-",
                            tourRun.workplaces !== null ? tourRun.workplaces : "-")
                    ])
                ])
            ]));

            if (renderSuccessfulTagActions) {
                var checkpointsWithComments = _.filter(tourRunDetails.checkpoints, function (checkpoint) {
                    return checkpoint.info ? true : false;
                });

                $tourRun.appendElement([
                    ol({"class": "checkpoints"},
                        _.map(tourRunDetails.checkpoints, function (checkpoint, idx) {
                            return li({"class": "checkpoint "}, [
                                div({"class": "index"}, sprintf("%s.", (idx + 1 + offsetCorrectCheckpoints))),
                                div({"class": "timestamp"}, window.JustTime.formatTimeString(new JustTime(checkpoint.check_time))),
                                div({"class": "tag-name"}, checkpoint.tag_name)
                            ]);
                        })
                    ),
                    checkpointsWithComments.length > 0 ? div({"class": "tour-comments"}, [
                        label("Wächter Bemerkungen:"),
                        ul(_.map(checkpointsWithComments, function (checkpoint) {
                                return li({"class": "tour-comment "}, [
                                    div({"class": "timestamp"}, window.JustTime.formatTimeString(new JustTime(checkpoint.check_time))),
                                    div({"class": "tag-name"}, checkpoint.info)
                                ]);
                            })
                        )
                    ]) : undefined
                ]);
            }

            if (renderFailedTagActions) {
                // enhance tour-run title with error stats
                $tourRun.find(".title").appendElement([
                    durationDeviation ? sprintf("(%s min), ", (durationDeviation > 0 ? "+" : "") + durationDeviation) : "",
                    sprintf("%s%%", tourRun.tags_count !== 0 ? Math.round(100 / tourRun.tags_count * tourRun.scanned_tags_count) : 100),
                    sprintf(", (Mitarbeiter: %s)", tourRun.user_name)
                ]);

                if (renderFailedTagActions) {
                    _.each(tourRunDetails.timeCInfos || [], function (info) {
                        $tourRun.find(".clow-info")
                            .afterElement(div({"class": "tour-info"},
                                sprintf("(Zf: %s-%s %s. Kontrolle, Soll: %s)",
                                    window.JustTime.formatTimeString(info.time_from),
                                    window.JustTime.formatTimeString(info.time_to),
                                    info.order, info.quota)));
                    });
                }

                // add missing checkpoints
                if ((tourRunDetails.missingCheckpoints || []).length > 0) {
                    $tourRun.appendElement(
                        div({"class": "missing-checkpoints tour-comments"}, [
                            label("Nicht gestochen:"),
                            ul(
                                _.map(tourRunDetails.missingCheckpoints, function (checkpoint) {
                                    return li({"class": "checkpoint "}, [
                                        div({"class": "index"}, " "),
                                        div({"class": "timestamp"}, " "),
                                        div({"class": "tag-name"}, checkpoint.tag_name)
                                    ]);
                                })
                            )
                        ])
                    );
                }

                // add admin comment if one exists
                if (tourRun.admin_comment) {
                    $tourRun.appendElement(
                        div({"class": "admin-comment"}, [
                            label("Anmerkung:"),
                            div({}, tourRun.admin_comment)
                        ])
                    );
                }

            }

            // update stats
            fnUpdateStats(0, 0, tourRunDetails.checkpoints.length,
                tourRunDetails.missingCheckpoints.length, duration);
        }

        function renderReportHeaderAdditions($parent) {
            // render selected date range
            $parent.appendElement([
                div({"class": "filter-info"}, [
                    div({"class": "date-range"},
                        sprintf("Auswerte-Zeitraum: %s bis %s ",
                            formatDateTime(filter.started), formatDateTime(filter.stopped))
                    ),
                    div({"class": "customer-info"},
                        sprintf("Kunde: %s, Standort: %s, Objekt: %s",
                            ((selectedCustomer && selectedCustomer.name) || "Alle"),
                            ((selectedLocation && selectedLocation.name) || "Alle"),
                            ((selectedObject && selectedObject.name) || "Alle")
                        )
                    ),
                    div({"class": "tour-area-info"},
                        sprintf("Revier: %s, Benutzer: %s",
                            ((selectedTourArea && selectedTourArea.name) || "Alle"),
                            ((selectedUser && selectedUser.username) || "Alle")
                        )
                    )
                ])
            ]);
        }

        app.printpreview.openPrintPreview({
            title: title,
            fnRenderBody: renderReportBody,
            fnRenderHeaderAdditions: renderReportHeaderAdditions
        });
    }

}(window.app));

export const printTourEventReport = app.widgets.print.tourEventReport;
export const printTourReport = app.widgets.print.tourReport;
