/**
 * Displays a list of all tour-runs visible for the current user.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav = rise.Navigation;
    var form = app.widgets.form;
    var clowFilter = app.widgets.clowFilter;

    /** DAOs **/
    var TourRunDao  = app.dao.tourRunDao;

    /*** GLOBALS ***/
    var pages = app.pages;

    /*** Export Pages ***/
    pages.tour_run.list = pages.tour_run.list || {};
    pages.tour_run.list.open = app.pagesByPath['/tour-run/list/open'] = TourRunListOpen;

    var filter = {
        filterExpression: null
    };

    /*** Set permission ***/
    TourRunListOpen.permissions = [
        app.model.PermissionTypes.TOUR_MODIFY,
        app.model.PermissionTypes.TOUR_VIEW_CUSTOMER,
        app.model.PermissionTypes.TOUR_VIEW_SUPERVISOR,
        app.model.PermissionTypes.TOUR_VIEW_EMPLOYEE
    ];

    function TourRunListOpen() {
        var self;

        var isEmployee =
            app.session.hasPermission(app.model.PermissionTypes.TOUR_VIEW_EMPLOYEE)
            && !app.session.hasPermission(app.model.PermissionTypes.TOUR_VIEW_SUPERVISOR)
            && !app.session.hasPermission(app.model.PermissionTypes.TOUR_VIEW_CUSTOMER)
            && !app.session.hasPermission(app.model.PermissionTypes.TOUR_MODIFY);

        if(!app.session.getSessionInfo().shift && isEmployee) {
            app.showErrorMessage('Keine Schicht gestartet');
            return;
        }

        self = app.widgets.ListPage.call(this, {
            pageName: '/tour-run/list/open',
            prefix: "tour-list-open",
            rpc: function () {
                return [
                    TourRunDao.getCustomerList(),
                    TourRunDao.findOpenTourRuns({filter_params: getFilterCriteria()}),
                    TourRunDao.getUserFilterList()
                ];
            },
            labels: {
                pageHeader: "Gestartete Rundgänge"
            },
            refresh: function() {
                filterTourRunEntries(self);
            }
        });

        this.prepareTable = function($parent, customers, dataTourRuns, users) {
            var columns = getTableColumns();
            var settings = getTableSettings();

            this.renderTable(dataTourRuns, columns, settings);

            filter.customers = customers;
            filter.users = users;
            $("#placeholder-filters").prependElement(
                renderFilter($parent, filter, this));
        };

        this.savePageState = function() { };

        this.loadPageState = function($parent, customers, dataTourRuns) {
            var $tourrunFilterCustomer = $('#tour-run-filter-customer');

            if ($tourrunFilterCustomer.hasClass('ui-autocomplete-input')) {
                filter.customers = customers;
                $tourrunFilterCustomer.combobox('option', 'source', filter.customers);
            }
            this.renderTableUpdate(dataTourRuns);
        };

        this.showDetailsPage = function(tourRun) {
            showDetailsPage(tourRun);
        };
    }

    function showDetailsPage(tourRun) {
        nav.goToPage(pages.tour_run.detail, [ tourRun, '/tour-run/list/open' ]);
    }

    function getFilterCriteria() {
        return {
            customer_id: filter.customer_id,
            location_id: filter.location_id,
            object_id: filter.object_id,
            workplace_id: filter.workplace_id,
            user_id: filter.user_id,
            filter_expression: filter.filterExpression
        };
    }

    function getTableColumns() {
        return [
            {
                sTitle: "Rundgang",
                sAttrName: "tour_description",
                mRender: function(mData, type, obj) {
                    return obj.tour_name + (obj.tour_description ? (" (" + obj.tour_description + ")") : "");
                }
            },
            {
                sTitle: "Gestartet am",
                sAttrName: "start_time",
                mRender: function(mData, type, obj) {
                    if (type === "display") {
                        return obj.start_time.toFormatString(app.settings.dateTimeFormat);
                    } else {
                        return obj.start_time;
                    }
                }
            },
            {
                sTitle: "Fortschritt",
                sAttrName: "progress",
                mRender: function(mData, type, obj) {
                    return obj.progress + '%';
                }
            },
            {
                sTitle: "Dauer",
                sAttrName: "duration"
            },
            {
                sTitle: "Benutzer",
                sAttrName: "user_name"
            },
            {
                sTitle: "Revier",
                sAttrName: "tour_areas",
                bVisible: false
            },
            {
                sTitle: "Kunde",
                sAttrName: "customers"
            },
            {
                sTitle: "Standort",
                sAttrName: "locations"
            },
            {
                sTitle: "Objekt",
                sAttrName: "objects"
            },
            {
                sTitle: "Arbeitsplatz",
                sAttrName: "workplaces"
            }
        ];
    }

    function getTableSettings() {
        return {
            aaSorting: [[ 1, "desc" ]]
        };
    }

    function renderFilter($parent, filter, listpage) {
        /* jshint indent: false */

        return div({ id: 'tour-run-filter-form', 'class': 'whiteboard' }, [
            legend({}),
            ul({ "class": "inline-list title-row-highlight" }, [
                form.formItemText({id: 'tour-run-filter-search', label: 'Suche', required: false,
                    style: "width: 250px",
                    name: "filter_expression",
                    keyup: function () {
                        filter.filterExpression = $(this).val();
                    },
                    keypress: function (e) {
                        var keyCode = e.which;
                        if (keyCode === 13) {
                            filterTourRunEntries(listpage);
                            return false;
                        }
                    },
                    change: function () {
                        filterTourRunEntries(listpage);
                    }
                }),

                function($parent) {
                    clowFilter({
                        customers: filter.customers,
                        $parent: $parent,
                        change: function (selection) {
                            filter.customer_id = selection.customer === -1 ? null : selection.customer;
                            filter.location_id = selection.location === -1 ? null : selection.location;
                            filter.object_id = selection.object === -1 ? null : selection.object;
                            filter.workplace_id = selection.workplace === -1 ? null : selection.workplace;

                            filterTourRunEntries(listpage);
                        },
                        attributeNameWorkplaces: 'assigned_workplaces'
                    });
                },
                createUserSelector(listpage, filter)
            ])
        ]);
    }

    function filterTourRunEntries(listpage) {
        var openTourRunsDeferred = TourRunDao.findOpenTourRuns({filter_params: getFilterCriteria()});

        openTourRunsDeferred.done(function (dataTours) {
            listpage.renderTableUpdate(dataTours);
        });
    }

    function createUserSelector(listpage, filter) {
        var all = {'id': null, 'username': 'Alle'};

        return form.formItemSelect({id: 'filter-user', label: 'Benutzer', required: false,
            source: filter.users,
            valueProp: 'username',
            value: all,
            firstElem: all,
            sort: true,
            selected: function (e, user) {
                if (filter.user_id !== user.id) {
                    filter.user_id = user.id;
                    filterTourRunEntries(listpage);
                }
            }
        });
    }

})(window.rise, window.app);
