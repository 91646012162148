/**
 * This file contains all model used by the shift RPC endpoint
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.Shift = function Shift(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id =           obj.id != null ? parseInt(obj.id) : null;
        this.workplace_id = obj.workplace_id != null ? parseInt(obj.workplace_id) : null;
        this.employee_id =  obj.employee_id != null ? parseInt(obj.employee_id) : null;
        this.started =      obj.started ?
            typeof obj.started === 'string' ?
                Date.fromISO(obj.started)
                : obj.started
            : null;

        this.stopped =      obj.stopped ?
            typeof obj.stopped === 'string' ?
                Date.fromISO(obj.stopped)
                : obj.stopped
            : null;
    };

    app.model.Shift.prototype.setStartedRounded = function (startedToRound) {
        this.started = roundTo15Minutes(startedToRound);
    };

    app.model.Shift.prototype.setStoppedRounded = function (stoppedToRound) {
        this.stopped = roundTo15Minutes(stoppedToRound);
    };

    function roundTo15Minutes(roundToDate) {
        var min = roundToDate.getMinutes();
        var remainder = min%15;
        roundToDate.setMinutes(remainder < 8 ? min-remainder : min+(15-remainder));

        return roundToDate;
    }

}(window.app));
