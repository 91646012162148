/**
 * @license Copyright 2014 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */

import _ from 'underscore';
import _str from 'underscore.string';

const NODE_ENV = __NODE_ENV__;

const $
    = window.$
    = window.jQuery
    = require('jquery');

window._ = _;
window._.str = _str;
_.mixin(_str.exports());

window.sprintf = require('sprintf-js').sprintf;

// Google
require('lib/markerclusterer_compiled.js');

// JQuery UI
require('lib/jquery-ui/js/jquery.ui.core.js');
require('lib/jquery-ui/js/jquery.ui.effects.js');
require('lib/jquery-ui/js/jquery.ui.accordion.js');
require('lib/jquery-ui/js/jquery.ui.autocomplete.js');
require('lib/jquery-ui/js/jquery.ui.button.js');
require('lib/jquery-ui/js/jquery.ui.datepicker.js');
require('lib/jquery-ui/js/i18n/jquery.ui.datepicker-de.js');
require('lib/jquery-ui/js/jquery.ui.dialog.js');
require('lib/jquery-ui/js/jquery.ui.draggable.js');
require('lib/jquery-ui/js/jquery.ui.droppable.js');
require('lib/jquery-ui/js/jquery.ui.menu.js');
require('lib/jquery-ui/js/jquery.ui.mouse.js');
require('lib/jquery-ui/js/jquery.ui.progressbar.js');
require('lib/jquery-ui/js/jquery.ui.resizable.js');
require('lib/jquery-ui/js/jquery.ui.selectable.js');
require('lib/jquery-ui/js/jquery.ui.slider.js');
require('lib/jquery-ui/js/jquery.ui.sortable.js');
require('lib/jquery-ui/js/jquery.ui.spinner.js');
require('lib/jquery-ui/js/jquery.ui.tabs.js');
require('lib/jquery-ui/js/jquery.ui.tooltip.js');
require('lib/jquery-ui-timepicker-addon.js');

// JQuery Dynatree
require('lib/dynatree/jquery.dynatree.js');

// JQuery Combo Tree
require('lib/combotree/comboTreePlugin.js');
require('lib/combotree/icontains.js');

// JQuery lightbox
require('lib/jquery-lightbox/lightbox.js');

// other JQuery libs
require('lib/jquery.hoverIntent.js');
require('lib/jquery.sha256.js');

// epoly v3
require('lib/v3_epoly.js');

// Signature Pad
require('lib/signature_pad.js');

// Create RISE package structure
require('lib/rise/packages.js');
require('lib/rise/logging/logging.js');
require('lib/rise/logging/logger.js');
require('lib/rise/logging/console-appender.js');
require('lib/rise/dao.js');
require('lib/rise/date-helper.js');
require('lib/rise/jquery-ui.combobox.js');
require('lib/rise/jquery-ui.tokenlist.js');
require('lib/rise/jquery.box.js');
require('lib/rise/jquery.validation.js');
require('lib/rise/justdate.js');
require('lib/rise/justtime.js');
require('lib/rise/rpc.js');
require('lib/rise/navigation.js');
require('lib/rise/gatekeeper/session.js');
require('lib/rise/notificationbar/notificationbar.js');

require('app/legacy/packages.js');
require('app/legacy/settings.js');

require('app/legacy/model/document.js');
require('app/legacy/model/business-document.js');
require('app/legacy/model/logbook-entry.js');
require('app/legacy/model/logbook-entry-note.js');
require('app/legacy/model/logbook-entry-type.js');
require('app/legacy/model/session-info.js');
require('app/legacy/model/shift.js');
require('app/legacy/model/shift-logbook-entry.js');
require('app/legacy/model/task.js');
require('app/legacy/model/workplace.js');
require('app/legacy/model/object.js');
require('app/legacy/model/location.js');
require('app/legacy/model/customer.js');
require('app/legacy/model/event-type.js');
require('app/legacy/model/event-field-type-options.js');
require('app/legacy/model/event-field-type.js');
require('app/legacy/model/event-logbook-entry.js');
require('app/legacy/model/event-field.js');
require('app/legacy/model/task-logbook-entry.js');
require('app/legacy/model/task-type.js');
require('app/legacy/model/unique-task.js');
require('app/legacy/model/daily-task.js');
require('app/legacy/model/weekly-task.js');
require('app/legacy/model/monthly-task.js');
require('app/legacy/model/role.js');
require('app/legacy/model/permission.js');
require('app/legacy/model/user.js');
require('app/legacy/model/employee.js');
require('app/legacy/model/customer-user.js');
require('app/legacy/model/tag.js');
require('app/legacy/model/user-tag.js');
require('app/legacy/model/tour.js');
require('app/legacy/model/tour-area.js');
require('app/legacy/model/session.js');
require('app/legacy/model/business-document-category.js');
require('app/legacy/model/ticket.js');

require('app/legacy/dto/logbook-entry-dto.js');
require('app/legacy/dto/user-dto.js');
require('app/legacy/dto/task-dto.js');
require('app/legacy/dto/task-logbook-entry-dto.js');
require('app/legacy/dto/customer-dto.js');
require('app/legacy/dto/notifications-dto.js');
require('app/legacy/dto/event-type-dto.js');
require('app/legacy/dto/location-dto.js');
require('app/legacy/dto/object-dto.js');
require('app/legacy/dto/workplace-dto.js');
require('app/legacy/dto/business-document-dto.js');
require('app/legacy/dto/business-document-category-dto.js');
require('app/legacy/dto/role-user-dto.js');
require('app/legacy/dto/tour-run-dto.js');
require('app/legacy/dto/tour-dto.js');
require('app/legacy/dto/tour-area-dto.js');
require('app/legacy/dto/session-dto.js');

require('app/legacy/dao/logbook-entry-dao.js');
require('app/legacy/dao/logbook-entry-note-dao.js');
require('app/legacy/dao/event-logbook-entry-dao.js');
require('app/legacy/dao/business-document-dao.js');
require('app/legacy/dao/shift-dao.js');
require('app/legacy/dao/task-dao.js');
require('app/legacy/dao/workplace-dao.js');
require('app/legacy/dao/customer-dao.js');
require('app/legacy/dao/event-type-dao.js');
require('app/legacy/dao/task-logbook-entry-dao.js');
require('app/legacy/dao/employee-dao.js');
require('app/legacy/dao/location-dao.js');
require('app/legacy/dao/object-dao.js');
require('app/legacy/dao/role-dao.js');
require('app/legacy/dao/user-dao.js');
require('app/legacy/dao/tag-dao.js');
require('app/legacy/dao/user-tag-dao.js');
require('app/legacy/dao/tour-dao.js');
require('app/legacy/dao/tour-area-dao.js');
require('app/legacy/dao/tour-run-dao.js');
require('app/legacy/dao/tour-report-dao.js');
require('app/legacy/dao/customer-user-dao.js');
require('app/legacy/dao/ticket-dao.js');
require('app/legacy/dao/session-dao.js');
require('app/legacy/dao/business-document-category-dao.js');

require('app/legacy/widgets/tools.js');
require('app/legacy/widgets/select.js');
require('app/legacy/widgets/button.js');
require('app/legacy/widgets/form.js');
require('app/legacy/widgets/menu.js');
require('app/legacy/widgets/page.js');
require('app/legacy/widgets/print-preview.js');
require('app/legacy/widgets/print-report.js');
require('app/legacy/widgets/table.js');
require('app/legacy/widgets/section.js');
require('app/legacy/widgets/notification.js');
require('app/legacy/widgets/dialog.js');
require('app/legacy/widgets/commandbox.js');
require('app/legacy/widgets/tabs.js');
require('app/legacy/widgets/listpage.js');
require('app/legacy/widgets/map.js');
require('app/legacy/widgets/tour-time-constraint.js');
require('app/legacy/widgets/dms.js');
require('app/legacy/helper.js');
require('app/legacy/validators.js');

require('app/legacy/pages/shift/begin.js');
require('app/legacy/pages/shift/check.js');
require('app/legacy/pages/shift/end.js');
require('app/legacy/pages/settings-password.js');
require('app/legacy/pages/business_document/list.js');
require('app/legacy/pages/business_document/detail.js');
require('app/legacy/pages/tag/list.js');
require('app/legacy/pages/tag/detail.js');
require('app/legacy/pages/user_tag/list.js');
require('app/legacy/pages/user_tag/detail.js');
require('app/legacy/pages/tour_area/list.js');
require('app/legacy/pages/tour_area/detail.js');
require('app/legacy/pages/tour/list.js');
require('app/legacy/pages/tour/detail.js');
require('app/legacy/pages/tour_run/list-open.js');
require('app/legacy/pages/tour_run/list-finished.js');
require('app/legacy/pages/tour_run/detail.js');
require('app/legacy/pages/logbook/list.js');
require('app/legacy/pages/logbook/list-hours.js');
require('app/legacy/pages/logbook/detail.js');
require('app/legacy/pages/logbook/detail-hours.js');
require('app/legacy/pages/logbook/create.js');
require('app/legacy/pages/task/list.js');
require('app/legacy/pages/task/detail.js');
require('app/legacy/pages/logbook/task-list-open.js');
require('app/legacy/pages/logbook/task-list-finished.js');
require('app/legacy/pages/logbook/task-detail.js');
require('app/legacy/pages/event_type/list.js');
require('app/legacy/pages/event_type/detail.js');
require('app/legacy/pages/example-ticket-list.js');
require('app/legacy/pages/example-ticket-detail.js');
require('app/legacy/pages/customer/manager.js');
require('app/legacy/pages/customer/detail.js');
require('app/legacy/pages/location/detail.js');
require('app/legacy/pages/object/detail.js');
require('app/legacy/pages/print/customer.js');
require('app/legacy/pages/report/tour-area.js');
require('app/legacy/pages/workplace/detail.js');
require('app/legacy/pages/role/list.js');
require('app/legacy/pages/role/detail.js');
require('app/legacy/pages/user/list.js');
require('app/legacy/pages/user/detail.js');
require('app/legacy/i18n/rpc-messages.js');
require('app/legacy/rpc-handler.js');
require('app/legacy/menu.js');
require('app/legacy/session.js');
require('app/legacy/pages/session/list.js');
require('app/legacy/index.js');
require('app/legacy/notifications.js');

/**
 * Required to use import instead for require() because
 * CSS modules would be loaded before these CSS files.
 */
import 'lib/dynatree/css/ui.dynatree.css';
import 'lib/combotree/style.css';
import 'lib/jquery-lightbox/css/lightbox.css';
import 'lib/jquery-ui-timepicker-addon.css';

import 'style/css/normalize.css';
import 'style/css/basic.css';
import 'style/css/content.css';
import 'style/css/helper.css';
import 'style/css/print.css';
import 'style/css/datatable.css';
import 'style/css/singlepage.css';
import 'style/css/style-changes.css';
import 'style/css/responsive.css';
import 'lib/rise/notificationbar/css/notificationbar.css';
