/**
 * LogbookEntry list working hours page.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav             = rise.Navigation;
    var formTemplates   = app.widgets.form;
    var clowFilter      = app.widgets.clowFilter;

    /** DAOs **/
    var logbookEntryDao = app.dao.logbookEntryDao;

    /*** GLOBALS ***/
    var pages           = app.pages;

    /*** CONSTANTS ***/
    var FILTER_DATE_STARTED = 3;

    /*** Export Page ***/
    pages.logbook.hours = pages.logbook.hours || {};
    pages.logbook.hours.list = app.pagesByPath['/logbook/hours/list'] = LogbookListWorkingHours;

    /*** Set permission ***/
    LogbookListWorkingHours.permissions = [
        app.model.PermissionTypes.LOGBOOK_VIEW_ADMIN,
        app.model.PermissionTypes.LOGBOOK_VIEW_SUPERVISOR,
        app.model.PermissionTypes.LOGBOOK_VIEW_EMPLOYEE,
        app.model.PermissionTypes.LOGBOOK_VIEW_CUSTOMER
    ];


    var filter = {
        events: [],

        objects: [],
        customers: [],

        started: null,
        stopped: null,

        user_id: null,

        customer_id: null,
        location_id: null,
        object_id: null,
        workplace_id: null,

        filterExpression: null
    };


    function LogbookListWorkingHours() {
        /* jshint indent:false */

        var self;

        var sessionInfo = app.session.getSessionInfo();

        if(!(sessionInfo.shift && sessionInfo.shift.workplace_id)
            && app.session.hasPermission(app.model.PermissionTypes.LOGBOOK_VIEW_EMPLOYEE)
            && !app.session.hasPermission(app.model.PermissionTypes.LOGBOOK_VIEW_SUPERVISOR)
            && !app.session.hasPermission(app.model.PermissionTypes.LOGBOOK_VIEW_ADMIN)
            && !app.session.hasPermission(app.model.PermissionTypes.LOGBOOK_VIEW_CUSTOMER)) {
            app.showErrorMessage('Keine Schicht gestartet');
            return;
        }

        self = app.widgets.ListPage.call(this, {
            pageName: '/logbook/hours/list',
            prefix: "logbook-list-hours",
            rpc: function() {
                return [
                    logbookEntryDao.getCustomerList()
                ];
            },
            labels : {
                pageHeader: "Arbeitsstunden ansehen"
            },
            "export":
                window.app.session.hasPermission(app.model.PermissionTypes.SHIFT_EXPORT)
                ? {
                    method: "export_working_hours",
                    criteriaFn: getFilterCriteria
                }
                : undefined
        });

        this.prepareTable = function($parent, customers) {
            var columns = getTableColumns();
            var settings = getTableSettings();

            // set parent container
            self.$parent = $parent;

            createFilterEntries(customers);

            this.renderTable(null, columns, settings);
            $("#placeholder-filters").prependElement(renderFilter());

            $parent.activateValidators({});
        };

        this.savePageState = function() { };

        this.loadPageState = function() {
            this.updateTableServerData();
        };

        this.showDetailsPage = function(logbookEntryDto) {
            nav.goToPage(pages.logbook.hours.detail, [ logbookEntryDto ]);
        };

        function getFilterCriteria() {
            return {
                filter_expression: filter.filterExpression,
                customer_id: filter.customer_id,
                location_id: filter.location_id,
                object_id: filter.object_id,
                workplace_id: filter.workplace_id,
                begin: filter.started,
                end: filter.stopped,
                employee_id: filter.user_id
            };
        }

        function getTableSettings() {
            return {
                bServerSide: true,
                fnServerData: function(sSource, aoData, fnCallback) {
                    aoData = _.object(_.map(aoData, function(oData) { return [oData.name, oData.value]; }));

                    logbookEntryDao.getWorkingHours($.extend({
                            offset: aoData.iDisplayStart,
                            limit: aoData.iDisplayLength,
                            sort_column: aoData['iSortCol_0'],
                            sort_direction: aoData['sSortDir_0']
                        }, getFilterCriteria())).done(function(logbookEntryListDto) {
                            fnCallback({
                                aaData: logbookEntryListDto.rows,
                                iTotalRecords: logbookEntryListDto.totalCount,
                                iTotalDisplayRecords: logbookEntryListDto.filteredCount,
                                sEcho: aoData.sEcho
                            });
                        });
                }
            };
        }

        function createFilterEntries(customers) {
            filter.events = [];

            // specify lookup date span
            filter.stopped = new JustDate();
            var filterStarted = new Date();
            filterStarted.setDate(filterStarted.getDate() - FILTER_DATE_STARTED);
            filter.started = new JustDate(filterStarted);

            filter.events.push.apply(filter.events, [
                { id:"all", name: 'Alle' },
                { id:"shift-all", name: '<b>Schichten</b></br>Alle Schichtereignisse'},
                { id:"shift-started", name: 'Schichtbeginn' },
                { id:"shift-stopped", name: 'Schichtende' },
                { id:"event-all", name: '<b>Ereignisse</b></br>Alle Ereignisse' }
            ]);

            filter.customers = customers;
        }

        function renderFilter() {
            return div({ id:'logbook-filter-form', 'class': 'whiteboard',
                keydown: function(e) {
                    e.stopImmediatePropagation();
                }
            }, [
                legend({}),
                ul({ "class":"inline-list title-row-highlight"}, [
                    formTemplates.formItemText({id: 'logbook-filter-search', label: 'Suche', required: false,
                        name: "filter_expression",
                        keyup: function () {
                            filter.filterExpression = $(this).val();
                        },
                        keypress: function (e) {
                            var keyCode = e.which;
                            if (keyCode === 13){
                                filterTable(self.$parent);
                                return false;
                            }
                        },
                        change: function () {
                            filterTable(self.$parent);
                        }
                    }),
                    formTemplates.formItemDate({id: 'logbook-filter-start-date', label: 'Anfangsdatum', required: false,
                        name: "begin",
                        date: filter.started.toDate(), datepickerParams: { appendText: "" },
                        change: function() {
                            filter.started = new JustDate($(this).datepicker('getDate'));
                            filterTable(self.$parent);
                        },
                        keypress: app.helper.keyPressEnter,
                        renderDayScroller: true
                    }),
                    formTemplates.formItemDate({id: 'logbook-filter-end-date', label: 'Enddatum', required: false,
                        name: "end",
                        date: filter.stopped.toDate(), datepickerParams: { appendText: "" },
                        change: function() {
                            filter.stopped = new JustDate($(this).datepicker('getDate'));
                            filterTable(self.$parent);
                        },
                        keypress: app.helper.keyPressEnter,
                        renderDayScroller: true
                    }),
                    function($parent) {
                        clowFilter({
                            customers: filter.customers,
                            $parent: $parent,
                            change: function (selection) {
                                filter.customer_id = selection.customer === -1 ? null : selection.customer;
                                filter.location_id = selection.location === -1 ? null : selection.location;
                                filter.object_id = selection.object === -1 ? null : selection.object;
                                filter.workplace_id = selection.workplace === -1 ? null : selection.workplace;

                                filterTable(self.$parent);
                            }
                        });
                    },
                    formTemplates.formItemCheckbox({id: 'logbook-filter-own-entries', label: 'Nur eigene Einträge',
                        change: function() {
                            filter.user_id =
                                $(this).is(':checked')
                                    ? app.session.getSessionInfo().user.id
                                    : null;
                            filterTable(self.$parent);
                        }
                    })
                ])
            ]);
        }

        function filterTable($parent) {
            if ($parent.validate()) {
                self.datatable.fnDraw();
            }
        }
    }

    function getTableColumns() {
        return [
            {
                sTitle: "Benutzer",
                sAttrName: "employee_name"
            },
            {
                sTitle: "Start",
                sAttrName: "shift_begin",
                mRender: function(mData, type, obj) {
                    return obj.shift_begin.toFormatString(app.settings.dateTimeFormat);
                }
            },
            {
                sTitle: "Ende",
                sAttrName: "shift_end",
                mRender: function(mData, type, obj) {
                    return obj.shift_end.toFormatString(app.settings.dateTimeFormat);
                }
            },
            {
                sTitle: "Arbeitsstunden",
                sAttrName: "working_time",
                mRender: function(mData, type, obj) {
                    return Math.round(obj.working_time / 36) / 100;
                }
            },
            {
                sTitle: "Kunde",
                sAttrName: "customer_name"
            },
            {
                sTitle: "Standort",
                sAttrName: "location_name"
            },
            {
                sTitle: "Objekt",
                sAttrName: "object_name"
            },
            {
                sTitle: "Arbeitsplatz",
                sAttrName: "workplace_name"
            }
        ];
    }


})(window.rise, window.app);
