/**
 * This file contains all model used by the shift RPC endpoint
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.EventFieldType = function EventFieldType(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id =            obj.id != null ? parseInt(obj.id) : null;
        this.event_type_id = obj.event_type_id != null ? parseInt(obj.event_type_id) : null;
        this.field_type =    obj.field_type ? obj.field_type : null;
        this.label =         obj.label != null ? obj.label : null;
        this.required =      (obj.required === true || obj.required === false) ? obj.required : false;
        this.order_number =  obj.order_number != null ? parseInt(obj.order_number) : null;
        this.desc_field =    (obj.desc_field === true || obj.desc_field === false) ? obj.desc_field : false;
        this.options =       new app.model.EventFieldTypeOptions(obj.options || {});
    };

}(window.app));
