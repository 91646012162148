/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
export class Customer extends (function(app) {
    "use strict";

    /*** IMPORTS ***/
    var Location    = app.model.Location;

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.Customer = function Customer(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id =               obj.id != null ? parseInt(obj.id) : null;

        this.name =             obj.name != null ? obj.name : null;
        this.active =           (obj.active === true || obj.active === false) ? obj.active : null;
        this.email =            obj.email != null ? obj.email : null;

        this.event_types = obj.event_types || [];

        // map location instances
        this.locations = _.map(obj.locations || [], function(location) {
            return new Location(location);
        });
    };

    app.model.Customer.prototype.getAllObjects = function () {
        var objects = [];

        _.each(this.locations, function (location) {
            objects = objects.concat(location.objects);
        });

        return objects.sort(function(o1, o2) {
            return o1.name < o2.name ? -1 : 1;
        });
    };

    app.model.Customer.prototype.getObjectsAssignedToWorkplace = function(workplaceId) {
        var objects = [];

        _.each(this.locations, function (location) {
            _.each(location.objects, function (object) {
                var assignedWorkplaceIds = _.map(object.assigned_workplaces, function(wp) { return wp.id; });
                if (_.contains(assignedWorkplaceIds, workplaceId)) {
                    objects.push(object);
                }
            });
        });

        return objects.sort(function(o1, o2) {
            return o1.name < o2.name ? -1 : 1;
        });
    };


    app.model.Customer.prototype.getEventTypes = function(object_id, workplace_id) {
        var self = this;

        var eventTypes = [].concat(this.event_types);

        _.each(this.locations, function(location) {
            var objectIds = _.pluck(location.objects, 'id').indexOf(object_id);
            if (!object_id || objectIds >= 0) {
                eventTypes.splice.apply(eventTypes, [eventTypes.length, 0].concat(location.event_types));

                _.each(location.objects, function(obj) {
                    if (!object_id || object_id === obj.id) {
                        eventTypes.splice.apply(eventTypes, [eventTypes.length, 0].concat(obj.event_types));
                        _.each(obj.workplaces, function(wp) {
                            if (!workplace_id || workplace_id == wp.id) {
                                eventTypes.splice.apply(eventTypes, [eventTypes.length, 0].concat(wp.event_types));
                            }
                        });
                    }
                });
            }
        });

        return eventTypes;
    };

    return app.model.Customer;
}(window.app)){
    constructor(json) {
        super(json);
    }
}
