import { TourState } from 'app/tour-report/TourReportConstants';

type TourStateProps = 'timecViolations' | 'incidentsTotal' | 'incorrectTotal' | 'correctTotal';
export type ChartState = { [key in TourStateProps]: ChartData };

export class ChartData {
    name: TourState;

    x: Array<string>;

    y: Array<number>;

    text: Array<number>;

    type: string;

    marker: {
        color: string;
    };

    hovertemplate: string;

    constructor(tourState: TourState) {
        this.name = tourState;
        this.x = [];
        this.y = [];
        this.text = [];
        this.type = 'bar';
        this.marker = {
            color: '#FFFFFF',
        };
        this.hovertemplate = '%{y}';

        switch (tourState) {
            case TourState.TIME_CONSTRAINT:
                this.marker.color = '#CB0538';
                break;
            case TourState.INCIDENTS:
                this.marker.color = '#6B9AFF';
                break;
            case TourState.INCORRECT:
                this.marker.color = '#E16C0A';
                break;
            case TourState.CORRECT:
                this.marker.color = '#008000';
                break;
        }
    }
}
