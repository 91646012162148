import { DATE_FORMAT } from 'app/Constants';

export const DEFAULT_WEEKS_CHART_MIN_DAYS = 14;

export const MAX_MONTH_DIFFERENCE_FROM_TO = 3;

export enum ViewType {
    WEEKS = 'WEEKS',
    DAYS = 'DAYS',
    DAY_LIST = 'DAY_LIST',
}

export enum TourState {
    CORRECT = 'Erfolgreiche Rundgänge',
    INCORRECT = 'Fehlerhafte Rundgänge',
    INCIDENTS = 'Rundgänge mit Vorkommnissen',
    TIME_CONSTRAINT = 'Unzureichende Wiederholungen',
}

export enum TourFilter {
    CORRECT = 'ONLY_CORRECT',
    INCORRECT = 'ONLY_INCORRECT',
    INCIDENTS = 'ONLY_WITH_INCIDENTS',
}

export function getTourFilterFromTourState(state: TourState): TourFilter|null {
    switch (state) {
        case TourState.CORRECT:
            return TourFilter.CORRECT;
        case TourState.INCORRECT:
            return TourFilter.INCORRECT;
        case TourState.INCIDENTS:
            return TourFilter.INCIDENTS;
        default:
            return null;
    }
}

export const formatDate = (date) => date.toFormatString(DATE_FORMAT);

export const IMG_CROSS = '<img src="style/images/content/cross.png" />';

/**
 * plotly Graphing Library defaults
 */
export const PLOTLY_DEFAULT_STYLE = {
    width: '100%',
    height: '100%',
};

export const PLOTLY_DEFAULT_LAYOUT = {
    autosize: true,
    barmode: 'group',
    modebar: {
        orientation: 'h',
    },
    margin: {
        t: 20,
    },
    legend: {
        orientation: 'h',
        x: 0,
        y: 1.5,
    },
    yaxis: {
        title: 'Gesamte Rundgänge',
        fixedrange: true,
        type: 'log',
        dtick: '1',
    },
    xaxis: {
        fixedrange: true,
    },
};

export const PLOTLY_DEFAULT_CONFIG = {
    displayModeBar: false,
    showTips: false,
};
