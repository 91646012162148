import { hasPermissionLegacy } from 'app/legacy/session';

export const hasPermission = (permission: PermissionType) => hasPermissionLegacy(permission);

export enum PermissionType {
    CUSTOMER_MODIFY = 'customer.modify',
    CUSTOMERVIEW_MODIFY = 'customerview.modify',
    EMPLOYEE_MODIFY = 'employee.modify',
    EVENT_INSERT = 'event.insert',
    GROUPS_MODIFY = 'groups.modify',
    SESSION_MODIFY = 'session.modify',

    EVENT_TYPE_MODIFY = 'event_type.modify',

    BUSINESS_DOCUMENT_VIEW_EMPLOYEE = 'business_document.view.employee',
    BUSINESS_DOCUMENT_VIEW_CUSTOMER = 'business_document.view.customer',
    BUSINESS_DOCUMENT_VIEW_SUPERVISOR = 'business_document.view.supervisor',
    BUSINESS_DOCUMENT_MODIFY = 'business_document.modify',

    LOUNGEASSIGNMENT_MODIFY = 'loungeassignment.modify',

    CUSTOMER_VIEW_SUPERVISOR = 'customer.view.supervisor',
    CUSTOMER_VIEW_CUSTOMER = 'customer.view.customer',

    SHIFT_START_END_OWNED = 'shift.start_end_owned',

    TASK_MODIFY_ADMIN = 'task.modify.admin',
    TASK_MODIFY_SUPERVISOR = 'task.modify.supervisor',
    TASK_MODIFY_CUSTOMER = 'task.modify.customer',
    TASK_VIEW_ADMIN = 'task.view.admin',
    TASK_VIEW_SUPERVISOR = 'task.view.supervisor',
    TASK_VIEW_EMPLOYEE = 'task.view.employee',
    TASK_VIEW_CUSTOMER = 'task.view.customer',
    TASK_EXPORT = 'task.export',

    EVENT_EXPORT = 'event.export',
    EVENT_INSERT_ADMIN = 'event.insert.admin',
    EVENT_INSERT_SUPERVISOR = 'event.insert.supervisor',
    EVENT_INSERT_EMPLOYEE = 'event.insert.employee',
    EVENT_INSERT_CUSTOMER = 'event.insert.customer',

    SHIFT_EXPORT = 'shift.export',

    LOGBOOK_VIEW_ADMIN = 'logbook.view.admin',
    LOGBOOK_VIEW_SUPERVISOR = 'logbook.view.supervisor',
    LOGBOOK_VIEW_EMPLOYEE = 'logbook.view.employee',
    LOGBOOK_VIEW_CUSTOMER = 'logbook.view.customer',

    TOUR_MODIFY = 'tour.modify',
    TOUR_VIEW_CUSTOMER = 'tour.view.customer',
    TOUR_VIEW_EMPLOYEE = 'tour.view.employee',
    TOUR_VIEW_SUPERVISOR = 'tour.view.supervisor',
    TOUR_REPORT_OBJECT_VIEW = 'tour.report.object.view',
    TOUR_REPORT_EVENT_VIEW = 'tour.report.event.view',
    TOUR_REPORT_INCIDENT_VIEW = 'tour.report.incident.view',
    TOUR_REPORT_SHIFT_VIEW = 'tour.report.shift.view',
    TOUR_REPORT_OVERVIEW_VIEW = 'tour.report.overview.view',
    TOUR_AREA_REPORT_OVERVIEW_VIEW = 'tour-area.report.overview.view',
    IP_READ = 'ip.read',

    REPORT_SCHEDULE_VIEW_ADMIN = 'report-schedule.view.admin',
    REPORT_SCHEDULE_MODIFY_ADMIN = 'report-schedule.modify.admin',
}

// register for legacy code
(window as any).app.model.PermissionTypes = PermissionType;
