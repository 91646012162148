/**
 * Displays a list of all business_documents visible for the current user.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function (rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var log = rise.logging.getLogger('pages.role.detail');
    var nav = rise.Navigation;

    var textButton          = app.widgets.textButton;
    var iconLinkButton      = app.widgets.iconLinkButton;
    var SelectDialog        = app.widgets.SelectDialog;
    var commandBox          = app.widgets.commandBox;
    var editIconButton      = app.widgets.editIconButton;
    var cancelIconButton    = app.widgets.cancelIconButton;
    var saveIconButton      = app.widgets.saveIconButton;
    var form                = app.widgets.form;
    var scrollTableSection  = app.widgets.scrollTableSection;

    /** DAOs **/
    var Role            = app.model.Role;
    var RoleType        = app.model.RoleType;
    var roleDao         = app.dao.roleDao;
    var employeeDao     = app.dao.employeeDao;
    var customerUserDao = app.dao.customerUserDao;

    /** DTOs **/
    var RoleUserDto     = app.dto.RoleUserDto;

    var pages                       = app.pages;
    var tableSelectorPermissions    = "permissions-table";
    var tableSelectorUsers          = "users-table";
    var userSelector = null;

    var PageType = {
        VIEW: 1,
        EDIT: 2,
        NEW: 3
    };

    /*** Export Page ***/
    pages.role.detail = app.pagesByPath['/role/detail'] = function RoleDetail() {
        var self = this;

        this.permissionsById = {};
        this.assignedUsersById = {};

        this.existingRolesCustomer = [];
        this.existingRolesEmployee = [];

        this.customerPerm = [];
        this.supervisorPerm = [];
        this.adminPerm = [];
        this.employeeSupervisorAdminPerm = [];

        this.role = null;

        this.name = "/role/detail";
        this.pageType = null;

        this.create = function (parent, role, existingRoles, goToPage) {

            this.goToPagePath = goToPage;
            this.goToPage = app.pagesByPath[goToPage];

            if (existingRoles && !role) {
                _.each(existingRoles, function (role) {
                    if (role.type === RoleType.CUSTOMER) {
                        self.existingRolesCustomer.push(role);
                    } else {
                        self.existingRolesEmployee.push(role);
                    }
                });

                return $.when(
                    roleDao.findPermissionsForRole(Role.INTERNAL_ROLE.CUSTOMER),
                    roleDao.findPermissionsForRole(Role.INTERNAL_ROLE.ADMIN),
                    roleDao.findPermissionsForRole(Role.INTERNAL_ROLE.SUPERVISOR),
                    roleDao.findPermissionsForRole(Role.INTERNAL_ROLE.EMPLOYEE)
                ).done(function (customerPerm, adminPerm, supervisorPerm, employeePerm) {
                    self.customerPerm = customerPerm;
                    self.adminPerm = adminPerm;
                    self.supervisorPerm = supervisorPerm;
                    self.employeeSupervisorAdminPerm = _.uniq(
                        _.union(employeePerm, supervisorPerm, adminPerm));

                    self.role = new Role();
                    self.role.type = RoleType.EMPLOYEE;

                    prepareAndRender(self.employeeSupervisorAdminPerm, PageType.NEW);
                }).fail(function(error){
                    log.error(error);
                });
            } else {
                return $.when(
                    roleDao.findPermissionsForRole(Role.INTERNAL_ROLE.CUSTOMER),
                    roleDao.findPermissionsForRole(Role.INTERNAL_ROLE.ADMIN),
                    roleDao.findPermissionsForRole(Role.INTERNAL_ROLE.SUPERVISOR),
                    roleDao.findPermissionsForRole(Role.INTERNAL_ROLE.EMPLOYEE),
                    roleDao.findPermissionsForRole(role.id),
                    role.type === app.model.UserType.CUSTOMER ?
                        roleDao.findCustomers(role.id) :
                        roleDao.findEmployees(role.id)
                ).done(function (customerPerm, adminPerm, supervisorPerm, employeePerm, permissions,
                                 roleUserDtos) {
                    self.role = new Role(role);

                    self.adminPerm = adminPerm;
                    self.supervisorPerm = supervisorPerm;
                    self.customerPerm = customerPerm;
                    self.employeeSupervisorAdminPerm = _.uniq(
                        _.union(employeePerm, supervisorPerm, adminPerm));

                    updateAssignedUsers(roleUserDtos);
                    prepareAndRender(permissions, PageType.VIEW);
                });
            }

            function prepareAndRender(permissions, pageType) {
                self.role.permissions = permissions;
                updatePermissionsById(permissions);
                renderContent(parent, pageType);
            }

        };

        this.destroy = function () {
            if (userSelector) {
                userSelector.destroy();
            }
            self.existingRolesCustomer = [];
            self.existingRolesEmployee = [];
        };

        function renderTables(parent) {
            var $parent = parent.appendElement(
                div({
                    id: 'two-split-div',
                    'class': 'two-row-split'
                }));

            renderPermissionTable($parent);
            renderUserTable($parent, _.values(self.assignedUsersById));
        }

        function renderContent(parent, pageType) {

            self.pageType = pageType;

            parent.appendElement(renderCommandBox(parent));

            if (self.pageType !== PageType.VIEW) {
                parent.appendElement(renderRoleForm());
                parent.activateValidators();
            }

            renderTables(parent);
        }


        function renderCommandBox($parent) {

            var savedRole = new Role(self.role);
            var savedAssignedUsers = $.extend(true, {}, self.assignedUsersById);

            function saveRoleAction() {

                if (!$parent.validate()) {
                    return;
                }

                function getPermissions(returnIds){
                    return _.map(
                        _.where(_.values(self.permissionsById), {_active:true}),
                        function (activePermission) {
                            return returnIds ? activePermission.id : activePermission;
                        }
                    );
                }

                function getUsers(returnIds){
                    return _.map(self.assignedUsersById,
                        function (roleUserDto) {
                            return returnIds ? roleUserDto.user.id : roleUserDto.user;
                        }
                    );
                }

                switch (self.pageType) {
                case PageType.EDIT:
                    self.role.permissions = getPermissions(false);
                    self.role.users =  getUsers(false);
                    $.when( roleDao.update(self.role) )
                        .done(function (role){
                            app.showSuccessMessage("Gruppe aktualisiert");
                            self.role = role;
                            if (self.assignedUsersById[app.session.getSessionInfo().user.id]){
                                app.showInfoMessage(
                                    "Sie haben eine Gruppe bearbeitet, in der Sie Mitglied sind. " +
                                    "Alle Änderungen werden erst nach einem neuerlichen Login " +
                                    "wirksam.", false);
                                app.session.refreshSessionInfo();
                            }
                            $parent.empty();
                            updatePermissionsById(self.role);
                            renderContent($parent, PageType.VIEW);
                        });
                    break;

                case PageType.NEW:
                    self.role.permissions = getPermissions(false);
                    self.role.users = getUsers(false);

                    roleDao.insertRole(self.role).done(function (result) {
                        app.showSuccessMessage("Gruppe hinzugefügt");
                        self.role = result;
                        self.role.active = true;
                        updatePermissionsById(self.role.permissions);

                        _.each(self.role.permissions, function(permission) {
                            permission._active = true;
                        });

                        $parent.empty();
                        renderContent($parent, PageType.VIEW);
                    });
                    break;
                }
            }

            function saveButton() {
                return saveIconButton({id: 'save-role',
                    click: saveRoleAction
                });
            }

            function cancelButton() {
                return cancelIconButton({id: 'cancel-edit-role',
                    click: function () {
                        if (self.pageType === PageType.NEW){
                            nav.goToPage(app.pages.role.list);
                        }
                        else {
                            self.assignedUsersById = savedAssignedUsers;
                            self.role = savedRole;
                            updatePermissionsById(savedRole.permissions);
                            $parent.empty();
                            renderContent($parent, PageType.VIEW);
                        }
                    }
                });
            }

            function editRoleButton() {
                return editIconButton({id: 'edit-role',
                    click: function () {
                        updateSelectedPermissions(self.role.permissions, self.role.type);
                        $parent.empty();
                        renderContent($parent, PageType.EDIT);
                    }
                }, 'Gruppe bearbeiten');
            }

            function getTitle() {
                if (self.pageType === PageType.NEW){
                    return "Neue Gruppe Hinzufügen";
                } else {
                    var titlePostfix = self.pageType === PageType.EDIT ? ' bearbeiten' : '';

                    switch (self.role.type) {
                    case app.model.UserType.CUSTOMER:
                        return "Kundengruppe: " + self.role.label + titlePostfix;
                    case app.model.UserType.EMPLOYEE:
                        return "Mitarbeitergruppe: " + self.role.label + titlePostfix;
                    }
                    return "";
                }
            }

            function getCommandButtons() {
                switch (self.pageType) {
                case PageType.VIEW:
                    return [
                        self.role.id < 0
                            ? undefined
                            : editRoleButton()
                    ];
                case PageType.EDIT:
                    return [
                        saveButton(),
                        cancelButton()
                    ];
                case PageType.NEW:
                    return [
                        saveButton(),
                        cancelButton()
                    ];
                }

                return [];
            }

            function backAction() {
                if(self.goToPage) {
                    return self.goToPage;
                } else {
                    return pages.role.list;
                }
            }

            return commandBox({backAction: backAction(), title: getTitle()}, getCommandButtons());
        }

        function renderPermissionTable($parent) {
            var data = _.values(self.permissionsById),

                mapId = function (ele) {
                    return ele.id;
                },
                employeeSupervisorAdminPermIds = _.map(self.employeeSupervisorAdminPerm, mapId),
                adminPermIds = _.map(self.adminPerm, mapId),
                supervisorPermIds = _.map(self.supervisorPerm, mapId),
                customerPermIds = _.map(self.customerPerm, mapId),

                getRowLabel = function(aoData) {
                    if (self.role.type === RoleType.EMPLOYEE) {
                        if (_.contains(supervisorPermIds, aoData.id)) {
                            return "Berechtigungen für Wachführer";
                        } else if (_.contains(adminPermIds, aoData.id)) {
                            return "Berechtigungen für Administratoren";
                        } else if (_.contains(employeeSupervisorAdminPermIds, aoData.id)) {
                            return "Berechtigungen für Mitarbeiter";
                        }
                    } else {
                        if (_.contains(customerPermIds, aoData.id)) {
                            return "Berechtigungen für Kunden";
                        }
                    }

                    return "Unbekannte Berechtigungen";
                },

                columns = [
                    { sTitle: "Berechtigungstyp", sAttrName: "id", bVisible: false,
                        mRender: function(mData, type, obj) {
                            return getRowLabel(obj);
                        }
                    },
                    { sTitle: "Bezeichnung", sAttrName: "label" }
                ],

                tableOptions = {
                    parent: $parent,
                    id: tableSelectorPermissions,
                    title: "Berechtigungen",
                    filter: true,
                    columns: columns,
                    "class": "",
                    height: "100%",
                    select: function(selected) {
                        var selectedIds = _.map(selected, mapId);
                        _.each(data, function (permission) {
                            permission._active = _.contains(selectedIds, permission.id);
                        });
                    },
                    getGroupingRowLabel: getRowLabel,
                    groupedByColumn: 0
                };

            if (self.pageType === PageType.VIEW) {
                tableOptions.elements = data;
            } else {
                tableOptions.elements = data;
                tableOptions.selected = _.filter(data, function (perm) {
                    return perm._active;
                });
            }

            if (self.role.type === RoleType.CUSTOMER) {
                tableOptions.columns.splice(0, 1);
                delete tableOptions.groupedByColumn;
                delete tableOptions.getGroupingRowLabel;
            }

            // render basic content
            $parent.appendElement(div({'class': 'two-row-split-left'},
                function(box) {
                    self.permissionTable = $(box).appendElement(
                        scrollTableSection(tableOptions)
                    ).find("table");
                }
            ));
        }

        function renderUserTable($parent, data) {
            var columns = [
                {
                    sTitle: "Nachname",
                    sAttrName: "user.last_name"
                },
                {
                    sTitle: "Vorname",
                    sAttrName: "user.first_name"
                },
                {
                    sTitle: "Benutzername",
                    sAttrName: "user.username"
                }
            ];

            switch (self.role.type) {
            case app.model.UserType.CUSTOMER:
                columns.push({
                    sTitle: "Kunde",
                    sAttrName: "customer.name",
                    mRender: function (mData, type, obj) {
                        return obj.customer ? obj.customer.name : '';
                    }
                });
                columns.push({
                    sTitle: "Location",
                    sAttrName: "location.name",
                    mRender: function (mData, type, obj) {
                        return obj.location ? obj.location.name : '';
                    }
                });
                break;
            }

            if(self.pageType !== PageType.VIEW){
                columns.push({
                    sAttrName: "remove",
                    bSortable: false,
                    bSearchable: false,
                    mData: null,
                    mRender: function () {
                        return $.box.html(
                            iconLinkButton({icon: 'img-arrow-grey-right'}, 'entfernen')
                        );
                    },
                    fnCreatedCell: function (nTd, sData, oData) {
                        var $nTd = $(nTd);
                        $nTd.find('a').click(function () {
                            if (self.pageType !== PageType.VIEW) {
                                var tbl = $(_.find($.fn.dataTable.fnTables(true),
                                            function(el) {
                                                return el.id === (tableSelectorUsers + '-table');
                                            })).dataTable();
                                var iRow = tbl.fnGetPosition($nTd.prev('td')[0])[0];
                                removeUser(tbl, oData.user, iRow);
                            }
                        });
                    }
                });
            }

            // render basic content
            $parent.appendElement(div({'class': 'two-row-split-right'},

                function(box) {
                    self.usersTable = $(box).appendElement(
                        scrollTableSection({
                            parent: $parent,
                            id: tableSelectorUsers,
                            title: self.role.type === app.model.UserType.CUSTOMER ? "Kunde" : "Mitarbeiter",
                            elements: data,
                            selected: null,
                            filter: true,
                            columns: columns,
                            "class": "",
                            click: displayUser,
                            height: "100%"
                        },
                        self.pageType !== PageType.VIEW ?
                          renderAddUser(self.role.id) :
                          undefined
                        )).find("table");
                }
            ));
        }

        function renderAddUser(roleId) {

            var columns = [
                { sTitle: "Nachname", sAttrName: "last_name", sWidth: "25%" },
                { sTitle: "Vorname", sAttrName: "first_name", sWidth: "25%" },
                { sTitle: "Benutzername", sAttrName: "username" }
            ];

            if(self.role.type === app.model.UserType.CUSTOMER) {

                columns.push({ sTitle: "Kunde", sAttrName: "customer.name",
                    mRender: function (mData, type, obj) {
                        return obj.customer ? obj.customer.name : '';
                    }});
                columns.push({ sTitle: "Standort", sAttrName: "location.name",
                    mRender: function (mData, type, obj) {
                        return obj.location ? obj.location.name : '';
                    }});
            }

            function createSelectDialog(parent, data) {
                userSelector = new SelectDialog({
                    id: "user-select",
                    parent: parent,
                    data: data,
                    columns: columns,
                    selected: function (row) {
                        if (!self.assignedUsersById[row.id]) {
                            var tbl = $(_.find($.fn.dataTable.fnTables(true),
                                function(el) {
                                    return el.id === (tableSelectorUsers + '-table');
                                }
                            )).dataTable();
                            addUser(tbl, row);
                        }
                    }
                });
            }

            return div({ id: "select-user", "class": "action-box bottom",
                create: function (e) {
                    var $target = $(e.target);
                    $.when(self.pageType === PageType.EDIT ?
                            (self.role.type === app.model.UserType.EMPLOYEE ?
                                roleDao.findNotAssignedEmployees(roleId) :
                                roleDao.findNotAssignedCustomers(roleId)) :
                            (self.role.type === app.model.UserType.EMPLOYEE ?
                                employeeDao.listAll({only_active: true}) :
                                customerUserDao.listAll({only_active: true}))
                        )
                    .done(function (users) {
                        createSelectDialog($target,users);
                    }).fail(function (){
                        createSelectDialog($target,[]);
                    });
                }}, [
                div({ style: "position:relative" }), function () {
                    if (self.pageType !== PageType.VIEW) {
                        return textButton({"id": 'role-assign-user',
                            "click": function () {
                                userSelector.open();
                            }
                        }, self.role.type === app.model.UserType.EMPLOYEE ?
                            'Mitarbeiter zuweisen' :
                            'Kunde zuweisen');
                    }
                }
            ]);
        }


        function renderRoleForm() {

            var sortRoles = function (a, b) {
                var aLabel = a.label.toLowerCase();
                var bLabel = b.label.toLowerCase();
                return ((aLabel < bLabel) ? -1 : ((aLabel > bLabel) ? 1 : 0));
            };
            self.existingRolesCustomer.sort(sortRoles);
            self.existingRolesEmployee.sort(sortRoles);

            var tempRoles = [({ id: 'defaultValue', label: 'Keine' })];
            var addNew = self.pageType === PageType.NEW;

            function reRenderTables(users, permissions) {
                if (users !== null){
                    updateAssignedUsers(users);
                    if (userSelector){
                        userSelector.destroy();
                    }
                }
                if (permissions !== null) {
                    updateSelectedPermissions(permissions,self.role.type);
                }
                var table = $("#two-split-div");
                var parent = table.parent();
                table.detach().remove();
                renderTables(parent);
            }

            var onChangeType = function () {
                var val = $(this).val(),
                    $rolePermissions = $('#role-permissions'),
                    $roleUsers = $('#role-users');

                switch (val) {
                case RoleType.CUSTOMER:
                    self.role.type = RoleType.CUSTOMER;
                    tempRoles.splice(1);
                    tempRoles.push.apply(tempRoles, self.existingRolesCustomer);
                    $rolePermissions.combobox('setSelected', tempRoles[0]);
                    $roleUsers.combobox('setSelected', tempRoles[0]);
                    reRenderTables({}, self.customerPerm);

                    break;
                case RoleType.EMPLOYEE:
                    self.role.type = RoleType.EMPLOYEE;
                    tempRoles.splice(1);
                    tempRoles.push.apply(tempRoles, self.existingRolesEmployee);
                    $rolePermissions.combobox('setSelected', tempRoles[0]);
                    $roleUsers.combobox('setSelected', tempRoles[0]);
                    reRenderTables({},self.employeePerm);
                    break;
                }
            };

            return fieldset({id: 'role-new'}, [
                ul({'class': 'fieldset-content'}, [
                    form.formItemText({id: 'role-label', label: 'Bezeichnung', required: true,
                        change: function () {
                            self.role.label = $(this).val();
                        },
                        create: function () {
                            if (!addNew){
                                $(this).val(self.role.label);
                            }
                        }
                    }),

                    addNew ?
                        li({'class': 'fieldset-item'}, [
                        label({'for': "role-type", 'class': "required" }, "Typ"),
                        input({id: "role-customer", name: "role-type-radio", type: 'radio', "checked": false,
                            value: RoleType.CUSTOMER,
                            change: onChangeType
                        }),
                        label({'for': "role-customer"}, "Kunde"),
                        input({id: "role-employee", name: "role-type-radio", type: 'radio', "checked": true,
                            value: RoleType.EMPLOYEE,
                            change: onChangeType,
                            create: onChangeType
                        }),
                        label({'for': "role-employee"}, "Mitarbeiter")
                    ]) : undefined,

                    addNew ?
                        form.formItemSelect({id: 'role-permissions', label: 'Grundlage Berechtigungen',
                        required: false, source: tempRoles, value: 'Keine', sort: false,
                        valueProp: 'label',
                        selected: function (e, selection) {
                            if (selection.id !== 'defaultValue') {
                                self.role._role_id_permissions = selection.id;
                                roleDao.findPermissionsForRole(self.role._role_id_permissions)
                                    .done(function (permissions) {
                                        reRenderTables(null,permissions);
                                    });
                            } else {
                                resetPermissions(self.role.type);
                                reRenderTables(null,
                                    self.role.type === RoleType.CUSTOMER ?
                                    self.customerPerm :
                                    self.employeePerm);
                                self.role._role_id_permissions = null;
                            }
                        }
                    }) : undefined,

                    addNew ?
                        form.formItemSelect({id: 'role-users', label: 'Grundlage Benutzer',
                        required: false, source: tempRoles, value: 'Keine', sort: false,
                        valueProp: 'label',
                        selected: function (e, selection) {
                            if (selection.id !== 'defaultValue') {
                                self.role._role_id_users = selection.id;
                                $.when(self.role.type === RoleType.EMPLOYEE ?
                                    roleDao.findEmployees(self.role._role_id_users) :
                                    roleDao.findCustomers(self.role._role_id_users))
                                    .done(function (roleUserDtos) {
                                        reRenderTables(roleUserDtos,null);
                                    });
                            } else {
                                resetPermissions(self.role.type);
                                self.role._role_id_users = null;
                                reRenderTables({},null);
                            }
                        }
                    }) : undefined
                ])
            ]);
        }

        function resetPermissions(roleType){
            _.map(
                roleType === RoleType.CUSTOMER ?
                self.customerPerm :
                self.employeePerm,
                function(permission){
                    permission._active = false;
                });
        }

        function updateSelectedPermissions(permissions, roleType){
            resetPermissions(roleType);
            var permissionsBase = roleType === RoleType.CUSTOMER ?
                self.customerPerm :
                self.employeeSupervisorAdminPerm;
            updatePermissionsById(permissionsBase);
            _.each(permissions, function(permission){
                if (self.permissionsById[permission.id]) {
                    self.permissionsById[permission.id]._active = permission._active;
                }
            });
        }

        function updatePermissionsById(role) {
            self.permissionsById =
                _.object(
                    _.map(role instanceof Role ? role.permissions : role,
                        function (item) {
                            return [item.id, item];
                        })
                );
        }

        function updateAssignedUsers(roleUserDtos) {
            self.assignedUsersById = _.object(_.map(roleUserDtos, function (item) {
                return [item.user.id, item];
            }));
        }

        function addUser(tbl, user) {
            var roleUserDto = new RoleUserDto({user: user, location: user.location, customer: user.customer});
            tbl.fnAddData(roleUserDto);
            tbl._fnAdjustColumnSizing();
            self.assignedUsersById[user.id] = roleUserDto;

        }

        function removeUser(tbl, employee, iRow) {
            tbl.fnDeleteRow(iRow);
            tbl._fnAdjustColumnSizing();
            delete self.assignedUsersById[employee.id];
        }

        function displayUser(userDto){
            if (self.pageType === PageType.VIEW &&
                    app.session.hasPermission(app.model.PermissionTypes.EMPLOYEE_MODIFY)) {
                nav.goToPage(pages.user.detail, [userDto.user.id, self.goToPage ? self.goToPagePath : '/role/list' ]);
            }
        }
    };

})(window.rise, window.app);
