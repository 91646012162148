import React, { Component } from 'react';

import { PermissionType } from 'app/PermissionType';
import { AbstractLegacyPage } from 'app/AbstractLegacyPage';

import { WeeksChart } from 'app/tour-report/pages/components/WeeksChart';
import { TourState, ViewType } from 'app/tour-report/TourReportConstants';
import { DaysChart } from 'app/tour-report/pages/components/DaysChart';
import { TimeConstraintTable } from 'app/tour-report/pages/components/TimeConstraintTable';
import { TourRunTable } from 'app/tour-report/pages/components/TourRunTable';
import { WeeksChartFilter } from 'app/tour-report/pages/components/WeeksChartFilter';
import { tourRunDao } from 'app/legacy/dao/tour-run-dao';
import { tourReportDao } from 'app/tour-report/dao/TourReportDao';
import { TourReportState } from 'app/tour-report/model/TourReportState';
import { CommandBox } from 'app/tour-report/pages/components/CommandBox';

const { rise, app } = window as any;

type Props = {
    args?: TourReportState;
}

const URL = '/report/tour-report';

export class TourReportPage extends Component<Props, TourReportState> implements AbstractLegacyPage {
    // eslint-disable-next-line  react/no-unused-class-component-methods
    name = URL;

    constructor(props) {
        super(props);
        const { args } = props;

        this.state = new TourReportState(args);

        this.showDayListTable = this.showDayListTable.bind(this);
        this.showDaysChart = this.showDaysChart.bind(this);
        this.showDaysChartStateParams = this.showDaysChartStateParams.bind(this);
        this.showWeeksChart = this.showWeeksChart.bind(this);
        this.backToWeeksChart = this.backToWeeksChart.bind(this);
        this.showSelectedTourDetails = this.showSelectedTourDetails.bind(this);
    }

    static get permissions() {
        return [
            PermissionType.TOUR_MODIFY,
            PermissionType.TOUR_REPORT_OVERVIEW_VIEW,
        ];
    }

    render() {
        const { view, weeksChart, weeksChartFilter, daysChart, dayListTable } = this.state;

        const header = (
            <>
                <CommandBox
                    dateFrom={weeksChartFilter.dateFrom}
                    dateTo={weeksChartFilter.dateTo}
                    customerId={weeksChartFilter.customerId}
                    locationId={weeksChartFilter.locationId}
                    objectId={weeksChartFilter.objectId}
                    customers={weeksChartFilter.customers}
                />
                <WeeksChartFilter
                    dateFrom={weeksChartFilter.dateFrom}
                    dateTo={weeksChartFilter.dateTo}
                    customerId={weeksChartFilter.customerId}
                    locationId={weeksChartFilter.locationId}
                    objectId={weeksChartFilter.objectId}
                    customers={weeksChartFilter.customers}
                    handleApplyFilter={this.showWeeksChart}
                />
            </>
        );

        switch (view) {
            case ViewType.WEEKS:
                return (
                    <>
                        {header}
                        <WeeksChart
                            dateFrom={weeksChartFilter.dateFrom}
                            dateTo={weeksChartFilter.dateTo}
                            tourReports={weeksChart.tourReports}
                            handleOnClick={this.showDaysChart}
                        />
                    </>
                );
            case ViewType.DAYS:
                return (
                    <DaysChart
                        dateFrom={daysChart.dateFrom}
                        dateTo={daysChart.dateTo}
                        week={daysChart.week}
                        tourReports={daysChart.tourReports}
                        handleOnClick={this.showDayListTable}
                        handleBackButton={this.backToWeeksChart}
                    />
                );
            case ViewType.DAY_LIST:
                switch (dayListTable.tourState) {
                    case TourState.CORRECT:
                    case TourState.INCIDENTS:
                    case TourState.INCORRECT:
                        return (
                            <TourRunTable
                                dateFrom={dayListTable.dateFrom}
                                dateTo={dayListTable.dateTo}
                                customerId={weeksChartFilter.customerId}
                                locationId={weeksChartFilter.locationId}
                                objectId={weeksChartFilter.objectId}
                                tourState={dayListTable.tourState}
                                handleSelect={this.showSelectedTourDetails}
                                handleBackButton={this.showDaysChartStateParams}
                            />
                        );
                    case TourState.TIME_CONSTRAINT:
                        return (
                            <TimeConstraintTable
                                dateFrom={dayListTable.dateFrom}
                                dateTo={dayListTable.dateTo}
                                customerId={weeksChartFilter.customerId}
                                locationId={weeksChartFilter.locationId}
                                objectId={weeksChartFilter.objectId}
                                handleSelect={this.showSelectedTourDetails}
                                handleBackButton={this.showDaysChartStateParams}
                            />
                        );
                }
        }

        return <div />;
    }

    backToWeeksChart() {
        this.setState({
            view: ViewType.WEEKS,
        });
    }

    async showWeeksChart(filter: Omit<TourReportState['weeksChartFilter'], 'customers'>) {
        const { weeksChart, weeksChartFilter, daysChart } = this.state;
        const { dateFrom, dateTo, customerId, locationId, objectId } = filter;

        const weeksChartFilterNew = {
            ...weeksChartFilter,
            ...filter,
        };

        this.setState({
            view: ViewType.WEEKS,
            weeksChartFilter: {
                ...weeksChartFilterNew,
            },
            weeksChart: {
                tourReports: weeksChart.tourReports,
            },
        });

        tourReportDao.getWeekReports(
            dateFrom,
            dateTo,
            customerId < 0 ? null : customerId,
            locationId < 0 ? null : locationId,
            objectId < 0 ? null : objectId,
        ).then((tourReports) => this.setState({
            weeksChart: {
                tourReports,
            },
            daysChart: {
                ...daysChart,
                tourReports: [],
            },
        }));

        if (!weeksChartFilter.customers.length) {
            tourRunDao.getCustomerList().then((customers) => this.setState({
                weeksChartFilter: {
                    ...weeksChartFilterNew,
                    customers,
                },
            }));
        }
    }

    async showDaysChartStateParams() {
        const { daysChart } = this.state;
        const { dateFrom, dateTo, week } = daysChart;
        return this.showDaysChart(dateFrom, dateTo, week);
    }

    async showDaysChart(dateFrom: Date, dateTo: Date, week: string) {
        const { daysChart, weeksChartFilter } = this.state;
        const { customerId, locationId, objectId } = weeksChartFilter;

        const changed = daysChart.dateFrom.getTime() !== dateFrom.getTime()
                            || daysChart.dateTo.getTime() !== dateTo.getTime();

        if (changed || !daysChart.tourReports.length) {
            const daysChartNew = {
                ...daysChart,
                tourReports: [],
                dateFrom,
                dateTo,
                week,
            };

            this.setState({
                view: ViewType.DAYS,
                daysChart: {
                    ...daysChartNew,
                },
            });

            const tourReports = await tourReportDao.getDayReports(
                dateFrom,
                dateTo,
                customerId < 0 ? null : customerId,
                locationId < 0 ? null : locationId,
                objectId < 0 ? null : objectId,
            );

            this.setState({
                daysChart: {
                    ...daysChartNew,
                    tourReports,
                },
            });
        } else {
            this.setState({
                view: ViewType.DAYS,
            });
        }
    }

    showDayListTable(dateFrom: Date, dateTo: Date, tourState: TourState) {
        const { weeksChartFilter } = this.state;
        const { customerId, locationId, objectId } = weeksChartFilter;

        this.setState({
            view: ViewType.DAY_LIST,
            dayListTable: {
                dateFrom,
                dateTo,
                customerId,
                locationId,
                objectId,
                tourState,
            },
        });
    }

    showSelectedTourDetails(data: any) {
        const { weeksChart, weeksChartFilter, daysChart, dayListTable } = this.state;

        const detailPage = dayListTable.tourState === TourState.TIME_CONSTRAINT
            ? app.pages.tour.detail
            : app.pages.tour_run.detail;

        rise.Navigation.goToPage(detailPage, [data, URL, {
            view: ViewType.DAY_LIST,
            weeksChart: {
                ...weeksChart,
                tourReports: [],
            },
            weeksChartFilter: {
                ...weeksChartFilter,
                customers: [],
            },
            daysChart: {
                ...daysChart,
                tourReports: [],
            },
            dayListTable,
        }]);
    }
}

/* Export Pages */
app.pages.report.tour_report_new = TourReportPage;
app.pagesByPath[URL] = TourReportPage;
