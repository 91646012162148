import React from 'react';
import $ from 'jquery';

import { BoxWorld } from 'lib/rise/jquery.box';

type Props = {
    children: any;
};

/*
 React names some DOM attributes differently.
 key: DOM attribute
 value: React attribute
 */
const attrDomReactMapping = {
    class: 'className',
    for: 'htmlFor',
    maxlength: 'maxLength',
    value: 'defaultValue',
};

/*
 All events known to BoxWorld.
 'create' event has a special meaning and is therefore removed.
 */
const events = BoxWorld.settings.coreevents.filter((event) => event !== 'create');

/**
 * This TSX component transforms BoxWorld elements into React elements
 *
 * e.g.
 *
 * <Box>
 *     <div />
 *     {
 *         div({ class: 'foo' }, [
 *             ($parent) => $parent.appendElement(div('generator function manipulates DOM')),
 *             () => div('generator function returns BoxElement'),
 *             div({
 *                 id: 'some-box-element',
 *                 change: (e) => $(e.target).appendElement(div('change event triggered')),
 *                 create(e) {
 *                     if (e.type === 'destroy') {
 *                         this.replaceChildren();
 *                         return;
 *                     }
 *
 *                     $(this).appendElement(div('create event triggered'));
 *                 },
 *             }),
 *             div({ create: '' })
 *             div('BoxElement child'),
 *             'Text child',
 *         ])
 *     }
 *     <span />
 * </Box>
 *
 * @deprecated
 * There is no replacement, use native React instead.
 */
export function Box(props: Props) {
    const { children } = props;

    let childrenArr = children ?? [];
    if (!Array.isArray(children)) {
        childrenArr = [children];
    }

    const elements = reactify(childrenArr);
    return React.createElement(React.Fragment, null, ...elements);
}

function reactify(boxes: Array<any>): Array<any> {
    let elems = boxes;
    if (!Array.isArray(boxes)) {
        elems = [boxes];
    }

    return elems.map((elem) => {
        const { children, domElementName, parms } = elem ?? {};

        if (typeof elem === 'function') {
            return emulateGeneratorFunction(elem);
        }

        if (Array.isArray(elem)) {
            return React.createElement(React.Fragment, null, ...reactify(elem));
        }

        if (!domElementName && !parms) {
            // that's no BoxWorld element, most likely it's already a React element
            return elem;
        }

        let reactChildren = null;
        if (children === null || children === undefined) {
            reactChildren = null;
        } else if (Array.isArray(children)) {
            reactChildren = reactify(children);
        } else if (isString(children)) {
            reactChildren = children;
        } else if (isBoxElement(children) || typeof children === 'function') {
            reactChildren = reactify([children]);
        } else {
            // eslint-disable-next-line no-console
            console.error(children);
            throw new Error('UNSUPPORTED CHILDREN');
        }

        handleCreateEvent(parms);

        convertStyles(parms);

        renameDomAttributes(parms);

        attachEventHandlers(parms);

        return React.createElement(
            domElementName,
            parms,
            ...(Array.isArray(reactChildren) ? reactChildren : [reactChildren]),
        );
    });
}

function isBoxElement(elem) {
    return isString(elem?.domElementName) && typeof elem?.createElement === 'function';
}

function isString(elem) {
    return typeof elem === 'string' || elem instanceof String;
}

/**
 * BoxWorld fires a custom event called 'create' after the DOM element was rendered.
 * This can be emulated using React Callback Refs (https://reactjs.org/docs/refs-and-the-dom.html#callback-refs).
 *
 * However, there is a difference. React calls Callback Refs twice, once with `null` and once with the created DOM
 * element. For the `null` case the fired event is of type `destroy` (instead of `create`) and the create event
 * handler should do whatever is necessary to clean up e.g. appended DOM elements.
 *
 * e.g.
 *
 * div({
 *   create(e) {
 *     // destroy timepicker
 *     if (e.type === 'destroy') {
 *       $(this).timepicker('destroy');
 *       return;
 *     }
 *
 *     // create timepicker
 *     $(this).timepicker(...);
 * });
 */
function handleCreateEvent(parms) {
    if ('create' in parms) {
        const createCallback = parms.create;
        delete parms.create;
        // parms.key = parms.key ?? uuidv4();

        if (typeof createCallback === 'function') {
            parms.ref = memoizeRef((ref, detachRef) => {
                createCallback.bind(ref)({
                    type: detachRef ? 'destroy' : 'create',
                    target: ref,
                });
            });
        }
    }
}

/**
 * BoxWorld style attribute is a string whereas React needs an object.
 * e.g.
 * div({ style: 'display:block; color:"green";' })
 * vs
 * <div style={{ display: "block", color: "green" }} />
 */
function convertStyles(parms) {
    if ('style' in parms) {
        const style = parms.style;

        if (isString(style)) {
            parms.style = Object.fromEntries(style.split(';').map((prop) => prop.split(':')));
        } else {
            delete parms.style;
        }
    }
}

/**
 * Rename DOM attribute names to React attribute names if necessary.
 * The mapping is defined in variable `attrDomReactMapping`.
 */
function renameDomAttributes(parms) {
    for (const [attr, value] of Object.entries(parms)) {
        if (attr in attrDomReactMapping) {
            const reactAttr = attrDomReactMapping[attr];

            parms[reactAttr] = value;
            delete parms[attr];

            // eslint-disable-next-line no-console
            // console.log(`rewrite ${attr} to ${reactAttr}: ${value}`);
        }
    }
}

/**
 * BoxWorld uses jQuery's event system (https://api.jquery.com/category/events/event-object/) which is incompatible
 * to React's event system (https://reactjs.org/docs/events.html) and also incompatible with native DOM events.
 *
 * Therefore, event handlers need to be registered using $.on to keep legacy code working.
 */
function attachEventHandlers(parms) {
    for (const [event, handler] of Object.entries(parms)) {
        if (events.includes(event) && typeof handler === 'function') {
            delete parms[event];

            const createFn = parms.ref;
            parms.ref = memoizeRef((ref, detachRef) => {
                // call pre-existing create functions
                if (typeof createFn === 'function') {
                    createFn(detachRef ? null : ref);
                }

                if (detachRef) {
                    $(ref).off(event, null, handler as any);
                } else {
                    $(ref).on(event, null, handler as any);
                }
            });
        }
    }
}

/**
 * Emulate BoxWorld generator functions. BoxWorld generator functions expect a jQuery object of the parent as first
 * argument. They may return BoxElements or handle DOM manipulation themselves.
 */
function emulateGeneratorFunction(generatorFn) {
    // create DOM parent and call generator function
    const parent = document.createDocumentFragment();
    const boxes = generatorFn($(parent));

    if (isBoxElement(boxes) || isString(boxes)) {
        return React.createElement(React.Fragment, null, ...reactify([boxes]));
    }
    if (Array.isArray(boxes)) {
        return React.createElement(React.Fragment, null, ...reactify(boxes));
    }

    const children = Array.from(parent.children);

    // Most likely the generator function does DOM manipulation on its own, so we have to attach the parent DOM element
    // somewhere. Therefore, we create a React placeholder <div> and append it using a callback ref.
    return React.createElement('div', {
        ref: memoizeRef((ref, detachRef) => {
            if (detachRef) {
                // re-insert original ref into DOM, React is not amused otherwise
                children[0].replaceWith(ref);

                // remove all generated DOM elements
                for (const child of children) {
                    child.remove();
                }
            } else {
                // replace placeholder <div> with actual content
                ref.replaceWith(parent);
            }
        }),
    });
}

/*
 * Memoizes DOMElement for subsequent calls to callback ref.
 *
 * According to docs: "... it will get called twice during updates, first with null and then again with the DOM element.
 * This is because a new instance of the function is created with each render, so React needs to clear the old ref and
 * set up the new one."
 */
function memoizeRef(callback: (ref: HTMLElement, detachRef: boolean) => void) {
    return (() => {
        let ref = null;
        return (el) => {
            ref = el ?? ref;
            callback(ref, !el);
        };
    })();
}
