/**
 * Data Access Object for the logbook-entry endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.logbook-entry");

    /*** Export Dao ***/
    app.dao.logbookEntryDao = new LogbookEntryDao();

    /**
     * @constructor
     */
    function LogbookEntryDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.LogbookEntry, new Rpc('/rpc/logbook-entry'));
    }

    // extend the parent Dao prototype
    $.extend(LogbookEntryDao.prototype, Object.create(rise.Dao.prototype));

    /**
     * Find all logbook entries with the given parameters
     */
    LogbookEntryDao.prototype.findLogbookEntries = function(params) {
        log.info('Finding logbook entries');
        var deferred = new $.Deferred();

        this.rpc.invoke('find_logbook_entries', { 'filter_params': params })
            .done(function(json) {
                deferred.resolve(new app.dto.LogbookEntryListDto(json));
            })
            .fail(function(error) {
                log.error('Request for finding logbook entries failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * compute working hours per shift
     */
    LogbookEntryDao.prototype.getWorkingHours = function(params) {
        log.info('Finding logbook entries');
        var deferred = new $.Deferred();

        this.rpc.invoke('get_working_hours', { 'filter_params': params })
            .done(function(json) {
                deferred.resolve(new app.dto.WorkingHoursListDto(json));
            })
            .fail(function(error) {
                log.error('Request for finding logbook entries failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Get details for a particular logbook entry
     */
    LogbookEntryDao.prototype.getLogbookEntryDetails = function(id) {
        var deferred = new $.Deferred();

        this.rpc.invoke('get_logbook_entry_details', { id:id })
            .done(function(json) {
                deferred.resolve(new app.dto.LogbookEntryDetailsDto(json));
            })
            .fail(function(error) {
                log.error('Request for finding logbook entry details failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    LogbookEntryDao.prototype.getCustomerList = function () {
        var deferred = new $.Deferred();

        this.rpc.invoke('get_customer_list')
            .done(function(rows) {
                deferred.resolve(_.map(rows, function(e) {return new app.model.Customer(e);}));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    LogbookEntryDao.prototype.sendByMail = function(id, mail_addresses) {
        var deferred = new $.Deferred();

        this.rpc.invoke('send_event_by_mail', { event_id:id, mail_addresses: mail_addresses })
            .done(function() {
                deferred.resolve();
            })
            .fail(function(error) {
                log.error('Request for sending logbook entry failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };
})(window.rise, window.app);
