/**
 * This file contains the model used for the session handling
 * of the application.
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    /**
     * Session info object.
     * @param {*} [json] JSON object.
     * @constructor
     */
    app.model.SessionInfo = function SessionInfo(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.session_id         = obj.session_id || null;
        this.username           = obj.username || null;
        this.roles              = obj.roles || [];
        this.ts_created         = new Date(obj.ts_created);
        this.ts_updated         = new Date();
        this.max_idle_time      = obj.max_idle_time || 0;
        this.max_session_time   = obj.max_session_time || 0;
        this.user               = new app.model.User(obj.user);
        this.notifications      = new app.dto.NotificationsDto(obj.notifications);
        this.shift              = obj.shift || null;
        this.permissions        = obj.permissions || [];
        this.password_reset_required = !!obj.password_reset_required;
    };

    /**
     * Check if the current session is expired.
     * @return {boolean} True if expired, false for an active session.
     */
    app.model.SessionInfo.prototype.isExpired = function() {
        return this.isIdleTimeExpired() || this.isSessionTimeExpired();
    };

    /**
     * Check if the max. idle time is expired.
     * @return {boolean} True if expired, false if not expired.
     */
    app.model.SessionInfo.prototype.isIdleTimeExpired = function() {
        var endTimeout = new Date(this.ts_updated);
        var now = new Date();
        endTimeout.setSeconds(endTimeout.getSeconds() + this.max_idle_time);
        return now.getTime() > endTimeout.getTime();
    };

    /**
     * Check if the max. session time is expired.
     * @return {boolean} True if expired, false if not expired.
     */
    app.model.SessionInfo.prototype.isSessionTimeExpired = function() {
        var endSession = new Date(this.ts_created);
        var now = new Date();
        endSession.setSeconds(endSession.getSeconds() + this.max_session_time);
        return now.getTime() > endSession.getTime();
    };

    /**
     * Updates the updated timestamp.
     */
    app.model.SessionInfo.prototype.update = function() {
        this.ts_updated = new Date();
    };

    /**
     * Check if the User of the current session has a specific permission
     * @param permission Permission identifier
     * @returns {boolean} True if permission is set, false otherwise
     */
    app.model.SessionInfo.prototype.hasPermission = function(permission) {
        return _.indexOf(this.permissions, permission) !== -1;
    };

}(window.app));
