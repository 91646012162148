/**
 * Ticket detail page.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var log         = rise.logging.getLogger('pages.ticket.detail');
    var nav         = rise.Navigation;

    var textButton  = app.widgets.textButton;

    /** DAOs **/
    var Ticket      = app.model.Ticket;
    var TicketDao   = app.dao.ticket;

    /*** GLOBALS ***/
    var pages   = app.pages;
    var form    = app.widgets.form;

    var PageType = {
        VIEW: 1,
        EDIT: 2,
        NEW: 3
    };

    /*** Export page ***/
    pages.ticket.detail = app.pagesByPath['/ticket/detail'] = function TicketDetail() {
        var obj = this;
        this.name = "/ticket/detail";

        this.type = PageType.VIEW;
        this.ticket = null;

        this.create = function ($parent, ticket_id) {
            if (ticket_id) {
                TicketDao.get(ticket_id).done(function(ticket) {
                    obj.ticket = ticket;
                    log.info('this is the ticket', ticket);
                    renderTicket($parent, PageType.VIEW);
                });
            } else {
                obj.ticket = new Ticket();
                renderTicket($parent, PageType.NEW);
            }
        };

        this.destroy = function() {
            // called after page has been destroyed
        };

        function renderTicket($parent, type) {
            obj.type = type;

            $parent.appendElement([
                h1({}, function () {
                    switch (obj.type) {
                        case PageType.NEW:  return 'New Ticket';
                        case PageType.EDIT: return 'Edit Ticket';
                        case PageType.VIEW: return 'Ticket: ' + obj.ticket.title;
                    }
                    return '';
                }),

                renderCommandBox($parent),

                renderTicketForm(obj.type === PageType.VIEW, obj.ticket)
            ]);

            $parent.activateValidators();
        }

        function renderCommandBox($parent) {
            var saved_ticket = new Ticket(obj.ticket);

            function backToListButton() {
                return li({}, textButton({id: 'back-to-ticket-list',
                    click: function () {
                        nav.goToPage(pages.ticket.list);
                    }
                }, 'Ticket List'));
            }

            function saveButton() {
                return li({}, textButton({id: 'save-ticket', icon: 'img-disk-return-black',
                    click: function () {
                        if ($parent.validate()) {
                            saveTicket($parent);
                        }
                    }
                }, 'Save'));
            }

            return div({'class': 'command-box top'}, [function () {
                switch (obj.type) {
                    case PageType.VIEW:
                        return ul({}, [
                            backToListButton(),
                            li({}, textButton({id: 'edit-ticket', icon: 'img-pencil',
                                click: function () {
                                    $parent.empty();
                                    renderTicket($parent, PageType.EDIT);
                                }
                            }, 'Edit'))
                        ]);

                    case PageType.EDIT:
                        return ul({}, [
                            backToListButton(),
                            saveButton(),
                            li({}, textButton({id: 'cancel-edit-ticket', icon: 'img-cross',
                                click: function () {
                                    $.validation.reset();
                                    $parent.empty();

                                    obj.ticket = saved_ticket;
                                    renderTicket($parent, PageType.VIEW);
                                }
                            }, 'Cancel'))
                        ]);

                    case PageType.NEW:
                        return ul({}, [
                            backToListButton(),
                            saveButton()
                        ]);
                }

                return '';
            }]);
        }

        function saveTicket($parent) {
            if(obj.ticket.id) {
                TicketDao.update(obj.ticket).done(doneMethod);
            } else {
                TicketDao.insert(obj.ticket).done(doneMethod);
            }

            function doneMethod(result) {
                obj.ticket = result;
                $parent.empty();

                renderTicket ($parent, PageType.VIEW);
                app.showSuccessMessage('Saved');
            }
        }
    };

    function renderTicketForm(view, ticket) {
        return fieldset({id: 'ticket-edit'}, [
            legend({}, 'Base Information'),
            ul({'class': 'fieldset-content'}, [
                form.formItemText({id: 'ticket-title', label: 'Title', required: true,
                    value: ticket.title,
                    change: function () {
                        ticket.title = $(this).val();
                    }
                }, view),
                form.formItemSelect({id: 'ticket-type', label: 'Type', required: false, source: ['', 'Bug', 'Feature'],
                    value: ticket.type, valueProp: null,
                    selected: function(e, selection) {
                        ticket.type = selection;
                    }
                }, view),
                form.formItemTextarea({id: 'ticket-description', label: 'Description',
                    value: ticket.description,
                    change: function () {
                        ticket.description = $(this).val();
                    }
                }, view),
                form.formItemDate({id: 'ticket-occurred', label: 'Occurred', required: false,
                    date: ticket.occurred,
                    change: function() {
                        ticket.occurred = new JustDate($(this).datepicker('getDate'));
                    }
                }, view),
                form.formItemDateTime({id: 'ticket-creation', label: 'Creation', required: true,
                    date: ticket.creation,
                    change: function() {
                        ticket.creation = $(this).datetimepicker('getDate');
                    }
                }, view)
            ])
        ]);
    }

})(window.rise, window.app);
