/**
 * Placeholder for Gatekeeper3 session management.
 *
 * @module rise.Gatekeeper
 * @author Christoph Mayerhofer
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 * ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise) {
    "use strict";

    var Gatekeeper = rise.Gatekeeper = {};

}(window.rise));