import 'datatables.net';
import * as DOMPurify from 'dompurify';

/**
 * (Helper-) Functions for rendering of tables.
 *
 * @module app.widgets
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
export const DataTable = (function (app) {
    "use strict";

    /*** Exports ***/
    app.widgets.DataTable = DataTable;

    // set some reasonable DataTable defaults
    $.extend(true, $.fn.dataTable.defaults, {
        /* enables the filtering of data */
        "bFilter": true,

        /* enables sorting of columns */
        "bSort": true,

        /* enable table information about visible data */
        "bInfo": true,

        "oLanguage": {
            "sProcessing":   "Bitte warten...",
            "sLengthMenu":   "_MENU_ Einträge anzeigen",
            "sZeroRecords":  "Keine Einträge vorhanden.",
            "sInfo":         "Zeige _START_ bis _END_ von _TOTAL_ Einträgen",
            "sInfoEmpty":    "0 bis 0 von 0 Einträgen",
            "sInfoFiltered": "(gefiltert von _MAX_  Einträgen)",
            "sInfoPostFix":  "",
            "sSearch":       "",
            "sUrl":          "",
            "sEmptyTable":   "Keine Einträge vorhanden.",
            "oPaginate": {
                "sFirst":    "Erster",
                "sPrevious": "Zurück",
                "sNext":     "Nächster",
                "sLast":     "Letzter"
            }
        },

        /* STATE SAVING */

        /* enable state saving */
        "bStateSave": true,

        /* callback for saving the state */
        "fnStateSave": function(oSettings, oData) {
            if (window.localStorage) {
                var storageName = getStorageName(oSettings);
                window.localStorage.setItem(storageName, JSON.stringify(oData));
            }
        },

        /* callback for restoring the state
         should return the oData state object */
        "fnStateLoad": function(oSettings) {
            var storageName = getStorageName(oSettings);
            if (window.localStorage && window.localStorage.getItem(storageName)) {
                try {
                    return JSON.parse(window.localStorage.getItem(storageName));
                } catch(e) {
                    // error parsing stored object, pass
                }
            }
        },

        /* callback for modifying the state to be saved */
        "fnStateSaveParams": function(oSettings, oData) {
            // only persist sorting and visible columns state
            oData.aoSearchCols = undefined;
            oData.oSearch = undefined;
            oData.iCreate = undefined;
            oData.iStart = undefined;
            oData.iEnd = undefined;
            oData.iScroller = undefined;
        }
    });

    /**
     * Get the name used to perist the DataTable state in the DOM storage.
     * @param {Object} oSettings DataTable settings object.
     * @return {String} Key used for DOM storage.
     */
    function getStorageName(oSettings) {
        return oSettings.sTableId;
    }

    /**
     * Generate the columns usable for DataTable initialization.
     * @param {Array} aoColumns Column definitions.
     * @return {{aoColumns: Array, getColumnIdx: Function, getColumn: Function}}
     */
    app.widgets.initColumns = function initColumns(aoColumns) {
        /*
         * Private column declarations.
         * sTitle: Used for the table header
         * sAttrName: Used for mDataProp if fnRender is not implemented
         * sType: Used for the column filter
         * sRangeFormat: Used for the column filter
         * aValues: Used fro the column filter select box
         * mRender: Used for rendering the cell content
         * bVisible: Default visibility state
         * bSortable: Indicates if the column can be sorted
         * bSearchable: Indicates if the column is searchable, used for column filter
         */

        /**
         * Initialize some attributes.
         */
        _.each(aoColumns, function(col, idx) {
            // set mDataProp to null if mRender is available
            // mRender and mData are legacy attributes, use render and data instead
            const renderFn = col.render || col.mRender;
            if (typeof renderFn === 'function') {
                col.mData = null;
                col.data = null;

                col.mRender = null;
                col.render = (...args) => DOMPurify.sanitize(renderFn(...args))
            } else {
                col.render = $.fn.dataTable.render.text();
                col.mData = col.sAttrName;
            }

            // set title to empty string if missing
            if (!col.sTitle) {
                col.sTitle = "";
            }

            // set type for column filtering
            if (col.sType) {
                col.type = col.sType;
            } else {
                col.type = "text";
            }

            if (col.aValues) {
                col.values = col.aValues;
            }

            // set column CSS class
            col.sClass = col.sAttrName.replace(/_/g, "-");
            col.idx = idx;

            // make aoColumns a associative array
            aoColumns[col.sAttrName] = col;
        });

        return {
            /**
             * The columns ready for DataTable initialization.
             */
            aoColumns: aoColumns,

            /**
             * Returns the index of the column with the specified name.
             * @param {String} attrName The attribute name
             * @return {Number} Index of the column, -1 if not found.
             */
            getColumnIdx: function(attrName) {
                if (aoColumns[attrName]) {
                    return aoColumns[attrName].idx;
                } else {
                    return -1;
                }
            },

            /**
             * Get the column with the specified column index.
             * @param {Number} columnIdx The column index.
             * @return {*} The column object.
             */
            getColumn: function(columnIdx) {
                return aoColumns[columnIdx];
            }
        };
    };


    /*** DataTable Widget *********************************************************************************************/

    function DataTable (options) {
        var self;
        var tableSelector;
        var tableId;
        var dataById;

        self = this;

        this.data = undefined;
        this.columns = undefined;
        this.datatable = undefined;

        var defaults = {
            "refix": "prefix",
            "export": null,
            "labels": {
                filterAll: "<filterAll>"
            },
            "keyboardNavigationEnabled": true
        };

        // extend this with all defaults and overwrite with options
        _.extend(this, defaults, options);

        tableId = self.prefix+"-table";
        tableSelector = "#"+tableId;

        // use legacy AJAX parameters https://datatables.net/manual/server-side#Legacy
        $.fn.dataTable.ext.legacy.ajax = true;

        var defaultTableSettings = {

            /* DATATABLE GENERAL OPTIONS */
            bAutoWidth: false,

            /* Replace a DataTable which matches the given selector */
            bDestroy: true,

            /* number of rows to display on a single page */
            iDisplayLength: 25,

            sPaginationType: "full_numbers",

            aaSorting: [[0, "asc"]],

            sDom: self.filters ? "lfrtip" : "rtip",

            "oSearch": {"bSmart": false},

            fnInitComplete: function(oSettings) {

                if(self.filterSearchFieldInactive) {
                    $(oSettings.nTableWrapper).find(".dataTables_filter input").remove();
                } else {
                    $(oSettings.nTableWrapper).find(".dataTables_filter input").attr("placeholder", "Eintrag suchen");
                }

                if(self.filters) {

                    var $filters = $(oSettings.nTableWrapper).find(".dataTables_filter").appendElement(
                        ul({},
                            li({}, a({ "class":"filter-active", click: function() {
                                if(self.filtersClearCallback) {
                                    $(".dataTables_filter li a").removeClass("filter-active");
                                    self.filtersClearCallback();
                                } else {
                                    removeAllFilters();
                                }

                                $(this).addClass("filter-active");
                            }}, self.labels.filterAll))
                        )
                    );

                    _.each(self.filters, function(elem, name) {

                        $filters.appendElement([
                            li({}, [
                                elem.icons === false
                                    ? undefined
                                    : img({ src:"style/images/content/tick.png" }),
                                a({ id:sprintf("%s-%s-on-filter", self.prefix, name), click:function() {
                                    if(elem.callback) {
                                        $(".dataTables_filter li a").removeClass("filter-active");
                                        elem.callback(true);
                                    } else {
                                        activateFilter(elem.values.on, name);
                                    }

                                    $(this).addClass("filter-active");

                                }}, elem.labels.on)
                            ]),
                            li({}, [
                                elem.icons === false
                                    ? undefined
                                    : img({ src:"style/images/content/cross.png" }),
                                a({ id:sprintf("%s-%s-off-filter", self.prefix, name), click:function() {
                                    if(elem.callback) {
                                        $(".dataTables_filter li a").removeClass("filter-active");
                                        elem.callback(false);

                                    } else {
                                        activateFilter(elem.values.off, name);
                                    }

                                    $(this).addClass("filter-active");

                                }}, elem.labels.off)
                            ])
                        ]);
                    });
                }
            },

            fnDrawCallback: function(oSettings) {
                $(oSettings.nTable).find("td").mouseenter(function() {
                    $(this).parent()
                        .children()
                        .each(function() {
                            $(this).toggleClass('highlight-row');
                        });
                }).mouseleave(function() {
                    $(this).parent()
                        .children()
                        .each(function() {
                            $(this).toggleClass('highlight-row');
                        });
                }).not('.active').click(function() {
                    if($(this).hasClass("dataTables_empty")) {
                        return;
                    }

                    var oTable = oSettings.oInstance;
                    var aPos = oTable.fnGetPosition(this);

                    // get the data array for this row
                    var oData = oTable.fnGetData(aPos[0]);

                    // remove row highlighting
                    $(".highlight-row").removeClass("highlight-row");

                    // open details page
                    if (self.options && self.options.onSelect) {
                        self.options.onSelect(oData);
                    }
                });
            },

            fnRowCallback: function(nRow, aData) {
                nRow.setAttribute('id',aData.id);
            }

        };

        this.create = function ($parent) {
            renderContent($parent);

            $parent.activateValidators({});

            if (this.keyboardNavigationEnabled) {
                registerKeyListener(tableSelector);
            }
        };

        this.onAttach = function($parent) {
            self.datatable = $parent.find(tableSelector).dataTable();

            if (this.keyboardNavigationEnabled) {
                registerKeyListener(tableSelector);
            }
        };

        this.onDetach = function() {
            $('body').off('keydown');
        };

        this.renderTable = function(data, columns, settings) {
            this.data = data;

            // update data map
            this.updateDataById(data);

            if(this.active) {
                addActiveColumn(columns, this.active.disabled);
            }

            this.columns = app.widgets.initColumns(columns);

            // initialize datatable
            this.datatable = $(tableSelector).dataTable($.extend(true, {}, defaultTableSettings, {
                /* columns of the datatable */
                aoColumns: this.columns.aoColumns,
                aaData: data
            }, settings));

            return this.datatable;
        };

        this.renderTableUpdate = function(data) {
            this.updateDataById(data);

            this.datatable.fnClearTable(false);
            this.datatable.api().rows.add(data);
            this.datatable.fnDraw();

        };

        this.updateDataById = function(data) {
            dataById = _.object(_.map(data, function(item) {
                return [item.id, item];
            }));
        };

        this.updateTableServerData = function() {
            this.datatable.fnDraw(true);
        };

        this.updateState = function(oldSelf) {
            this.datatable = oldSelf.datatable;
            this.columns = oldSelf.columns;
            this.data = oldSelf.data;

            this.updateDataById(this.data);
        };

        function renderContent($parent) {
            var whiteboardId = self.prefix+"-whiteboard";

            // render basic content
            $parent.appendElement([
                div({ id:whiteboardId, "class": self.options.noWhiteboard ? "" : "whiteboard" }, [
                    table({ id: tableId, style:"width:100%", "class":"display" })
                ])
            ]);
        }

        function activateFilter(state, name) {
            removeAllFilters();
            if(self.datatable) {
                self.datatable.fnFilter(""+state, self.columns.getColumnIdx(name));
            }
        }

        function removeAllFilters() {
            $(".dataTables_filter li a").removeClass("filter-active");

            if(self.datatable) {
                _.each(self.filters, function(elem, name) {
                    self.datatable.fnFilter("", self.columns.getColumnIdx(name));
                });
            }
        }

        function onActiveStateChanged(eventType, $checkbox, newDataElement) {
            $checkbox.prop("checked", newDataElement.active);
            eventType.active = newDataElement.active;

            // show success message
            app.showSuccessMessage(sprintf("%s \"%s\" wurde erfolgreich %s", self.active.name,
                    newDataElement.label || newDataElement.name || newDataElement.username, newDataElement.active ? "aktiviert" : "deaktiviert"));
        }

        function addActiveColumn(columns, disabled) {
            var activeColumn = {
                sTitle: "Aktiv",
                sAttrName: "active",
                sWidth: "50px",
                sClass: "center",
                mRender: function (mData, type, obj) {
                    if (type === "display") {
                        return $.box.html(
                            input({ type:"checkbox", checked:obj.active, disabled: !!disabled })
                        );
                    }

                    return dataById[obj.id].active;
                },
                fnCreatedCell: function(nTd, sData, oData) {
                    var $nTd = $(nTd);

                    if(!!disabled) {
                        return;
                    }

                    $nTd.addClass("center").find('input[type=checkbox]').one("change", function _toggleActive(e) {
                        var dataElement = dataById[oData.id],
                            $target = $(e.target);

                        self.active.dao.setActiveState(dataElement.id, $target.prop("checked"))
                            .done($.proxy(onActiveStateChanged, self, dataElement, $target))
                            .fail(function() {
                                $target.prop("checked", dataElement.active);

                                if (!self.active.noGenericErrorMessage) {
                                    // show error message
                                    app.showErrorMessage(sprintf("%s \"%s\" konnte nicht %s werden", self.active.name,
                                        dataElement.label ||
                                        dataElement.name ||
                                        dataElement.username, !dataElement.active ? "aktiviert" : "deaktiviert"));
                                }
                            })
                            .always(function() {
                                $target.one("change", _toggleActive);
                            });
                    });
                }
            };

            columns.push(activeColumn);

            return columns;
        }

        return this;
    }

    function registerKeyListener(tableSelector) {
        $('body').on('keydown', function (e) {
            if ($(e.target).is('input')) {
                return;
            }

            if (e.keyCode === 39) {
                // arrow right
                $(tableSelector).dataTable().fnPageChange('next');
            } else if (e.keyCode === 37) {
                // arrow left
                $(tableSelector).dataTable().fnPageChange('previous');
            }
        });
    }

    return app.widgets.DataTable;

})(window.app);
