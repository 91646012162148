/**
 * Data Access Object for the shift endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.shift");

    /*** Export Dao ***/
    app.dao.shiftDao = new ShiftDao();

    /**
     * @constructor
     */
    function ShiftDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.Shift, new Rpc('/rpc/shift'));
    }

    // extend the parent Dao prototype
    $.extend(ShiftDao.prototype, Object.create(rise.Dao.prototype));

    /**
     * Find the current of the employee if there is one running
     *
     * Following parameters are passed in the `done` method
     * null - is passed to the done method, if there is no running shift
     * Shift - model is passed, if a running shift was found
     */
    ShiftDao.prototype.findRunning = function() {
        log.info('Finding current shift of employee');
        var deferred = new $.Deferred();
        var userId = app.session.getSessionInfo().user.id;

        this.rpc.invoke('find_running', {'employee_id': userId})
            .done(function(shift) {
                if (shift) {
                    log.info('Received current shift');
                    deferred.resolve(new app.model.Shift(shift));
                } else {
                    log.info('No current shift found');
                    deferred.resolve(null);
                }
            })
            .fail(function(error) {
                log.error('Request for current shift failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Starts a shift
     */
    ShiftDao.prototype.beginShift = function(shift) {
        log.info('Beginning shift');
        var deferred = new $.Deferred();

        this.rpc.invoke('begin_shift', {document: shift})
            .done(function(s) {
                log.info('Received shift for begin shift');
                rise.Navigation.clearPageCache();
                deferred.resolve(new app.model.Shift(s));
            })
            .fail(function(error) {
                log.error('Request for begin shift failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };


    /**
     * End a shift
     */
    ShiftDao.prototype.endShift = function(shift) {
        log.info('Ending shift');
        var deferred = new $.Deferred();

        this.rpc.invoke('end_shift', {id : shift.id, stopped : shift.stopped })
            .done(function() {
                log.info('Shift ending successful');
                rise.Navigation.clearPageCache();
                deferred.resolve();
            })
            .fail(function(error) {
                log.error('Request for end shift failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

})(window.rise, window.app);
