/**
 * This is the dto for the tasks
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.dto.RoleUserDto = function RoleUserDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.user = obj.user != null ?
            new app.model.User(obj.user)
            : null;

        this.customer = obj.customer != null ?
            new app.model.Customer(obj.customer)
            : null;

        this.location = obj.location != null ?
            new app.model.Location(obj.location)
            : null;
    };

}(window.app));
