/* eslint-disable camelcase */
import { ReportScheduleTaskStatus } from 'app/report-schedule/ReportScheduleConstants';

export class ReportScheduleTaskDto {
    id: number | null;

    status: ReportScheduleTaskStatus;

    planned_at: Date;

    finished_at: Date;

    failed_at: Date;

    filter_datetime_from: Date;

    filter_datetime_until: Date;

    error_details?: string;

    constructor(jsonObject: string | any) {
        let obj = (typeof jsonObject === 'string') ? JSON.parse(jsonObject) : jsonObject;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id ? parseInt(obj.id, 10) : null;
        this.status = obj.status as ReportScheduleTaskStatus;
        this.error_details = obj.error_details || '';

        if (obj.planned_at) {
            this.planned_at = typeof obj.planned_at === 'string'
                ? new Date(obj.planned_at)
                : obj.planned_at;
        }

        if (obj.finished_at) {
            this.finished_at = typeof obj.finished_at === 'string'
                ? new Date(obj.finished_at)
                : obj.finished_at;
        }

        if (obj.failed_at) {
            this.failed_at = typeof obj.failed_at === 'string'
                ? new Date(obj.failed_at)
                : obj.failed_at;
        }

        if (obj.filter_datetime_from) {
            this.filter_datetime_from = typeof obj.filter_datetime_from === 'string'
                ? new Date(obj.filter_datetime_from)
                : obj.filter_datetime_from;
        }

        if (obj.filter_datetime_until) {
            this.filter_datetime_until = typeof obj.filter_datetime_until === 'string'
                ? new Date(obj.filter_datetime_until)
                : obj.filter_datetime_until;
        }
    }
}
