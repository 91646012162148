/**
 * LogbookEntry list (overview) page.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav         = rise.Navigation;

    var EventType   = app.model.EventType;

    /** DAOs **/
    var eventTypeDao     = app.dao.eventTypeDao;

    /*** GLOBALS ***/
    var pages         = app.pages;

    /*** CONSTANTS ***/
    var EVENT_NAME_COLUMN_INDEX = 1;

    /*** Export Page ***/
    pages.event_type.list = app.pagesByPath['/event-type/list'] = EventTypeList;

    /*** Set permission ***/
    EventTypeList.permissions = [app.model.PermissionTypes.EVENT_TYPE_MODIFY];

    function EventTypeList() {
        app.widgets.ListPage.call(this, {
            pageName: '/event-type/list',
            prefix: "event-type",
            rpc: function() {
                return [ eventTypeDao.listAll() ];
            },
            labels: {
                pageHeader: "Ereignistypen",
                buttonNew: "Neuer Ereignistyp",
                filterAll: "Alle Ereignistypen"
            },
            active: {
                dao: eventTypeDao,
                name: "Der Ereignistyp"
            },
            filters: {
                finalized: {
                    icons: false,
                    labels: {
                        on: "Offene",
                        off: "Abgeschlossene"
                    },
                    values: {
                        on: false,
                        off: true
                    }
                },
                active: {
                    labels: {
                        on: "Aktive",
                        off: "Deaktivierte"
                    },
                    values: {
                        on: true,
                        off: false
                    }
                }
            }
        });

        this.prepareTable = function($parent, dataEventTypes) {
            var columns = getTableColumns();
            var settings = getTableSettings();

            this.renderTable(dataEventTypes, columns, settings);
        };

        this.savePageState = function() { };

        this.loadPageState = function($parent, dataEventTypes) {
            // update eventTypesById
            this.renderTableUpdate(dataEventTypes);
        };

        this.showDetailsPage = function(eventType) {
            nav.goToPage(pages.event_type.detail, eventType ? [ new EventType(eventType) ] : undefined);
        };
    }

    function getTableColumns() {
        return [
            {
                sTitle: "ID",
                sAttrName: "id"
            },
            {
                sTitle: "Ereignisname",
                sAttrName: "label"
            },
            {
                sTitle: "Hinterlegte Email Adresse(n)",
                sAttrName: "send_emails",
                mRender: function (mData, type, obj) {
                    return obj.send_emails && obj.send_emails.split(';').join('; ');
                }
            },
            {
                sTitle: "Abgeschlossen",
                sAttrName: "finalized",
                sWidth: "50px",
                sClass: "center",
                mRender: function (mData, type, obj) {
                    if (type === "display") {
                        return $.box.html(
                            input({ type:"checkbox", disabled:true, checked:obj.finalized })
                        );
                    }

                    return obj.finalized;
                }
            }
        ];
    }

    function getTableSettings() {
        return {
            aaSorting: [[ EVENT_NAME_COLUMN_INDEX, "asc" ]]
        };
    }

})(window.rise, window.app);
