/**
 * Display list of all tasks.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav         = rise.Navigation;
    var clowFilter  = app.widgets.clowFilter;
    var form        = app.widgets.form;

    /** DAOs **/
    var taskDao     = app.dao.taskDao;

    /*** GLOBALS ***/
    var pages       = app.pages;

    /*** CONSTANTS ***/
    var TASK_LABEL_COLUMN_INDEX = 2;
    var rotationTypeDisplayName = {
        UNIQUE: 'Einmalig',
        MONTHLY: 'Monatlich',
        WEEKLY: 'Wöchentlich',
        DAILY: 'Täglich'
    };

    /*** Export Page ***/
    pages.task.list = app.pagesByPath['/task/list'] = TaskList;

    /*** Set permission ***/
    TaskList.permissions = [
        app.model.PermissionTypes.TASK_MODIFY_ADMIN,
        app.model.PermissionTypes.TASK_MODIFY_SUPERVISOR,
        app.model.PermissionTypes.TASK_MODIFY_CUSTOMER
    ];

    function TaskList() {

        var self = this;

        // define default filter
        self.filter = {
            customer_id: null,
            location_id: null,
            object_id: null,
            workplace_id: null,
            active: null
        };

        app.widgets.ListPage.call(this, {
            pageName: '/task/list',
            rpc: function () {
                return [ taskDao.findTasks(), taskDao.getCustomerList()];
            },
            prefix: "task",
            labels: {
                pageHeader: "Aufgaben verwalten",
                buttonNew: "Neue Aufgabe",
                filterAll: "Alle Aufgaben"
            },
            active: {
                dao: taskDao,
                name: "Die Aufgabe",
                noGenericErrorMessage: true
            }
        });

        this.prepareTable = function($parent, dataEventTypes, customers) {
            var columns = getTableColumns();
            var settings = getTableSettings();

            this.renderTable(dataEventTypes, columns, settings);

            if (customers) {
                $("#placeholder-filters").prependElement(
                    clowFilter({
                        customers: customers,
                        change: $.proxy(this.onFilterChange, this),
                    }, [
                        this.createEventTypeRotationTypeSelector(),
                        createStateSelector()
                    ])
                );
            }
        };

        this.savePageState = function() { };

        this.loadPageState = function($parent, dataEventTypes, customers) {
            clowFilter({
                customers: customers,
                refresh: true,
                change: $.proxy(this.onFilterChange, this),
            });
            filterTable();
        };

        this.showDetailsPage = function(task) {
            nav.goToPage(pages.task.detail, task ? [ task.id ] : undefined);
        };

        this.onFilterChange = function(selection) {
            var oldFilter = _.extend({}, self.filter);

            self.filter.customer_id = selection.customer !== -1 ? selection.customer : null;
            self.filter.location_id = selection.location !== -1 ? selection.location : null;
            self.filter.object_id = selection.object !== -1 ? selection.object : null;
            self.filter.workplace_id = selection.workplace !== -1 ? selection.workplace : null;

            if (!_.isMatch(oldFilter, self.filter)) {
                filterTable();
            }
        };

        function filterTable() {
            taskDao.findTasks(self.filter)
                .done(function(tasks) {
                    self.renderTableUpdate(tasks);
                });
        }

        function createStateSelector() {
            var stateTypes = [
                { id: 'ALL', name: 'Alle', value: undefined },
                { id: 'ACTIVATED', name: 'Aktiviert', value: true },
                { id: 'NOT_ACTIVATED', name: 'Nicht Aktiviert', value: false }
            ];

            return form.formItemSelect({id: 'filter-activated', label: 'Zustand', required: false,
                source: stateTypes, valueProp: 'name',
                value: stateTypes[0],
                sort: false,
                selected: function (e, stateType) {
                    if (self.filter.active !== stateType.value) {
                        self.filter.active = stateType.value;
                        filterTable();
                    }
                }
            });
        }

        this.createEventTypeRotationTypeSelector = function() {
            var rotationTypes = [
                    { id:-1, name:"Alle" },
                    { id:'UNIQUE', name:'Einmalig' },
                    { id:'DAILY', name:'Täglich' },
                    { id:'WEEKLY', name:'Wöchentlich' },
                    { id:'MONTHLY', name:'Monatlich' }
                ],
                self = this;

            return form.formItemSelect({
                id: 'filter-rotation-type', label: 'Wiederholung', required: false,
                source: rotationTypes, valueProp: 'name',
                value: rotationTypes[0],
                sort: false,
                selected: function(e, rotationType) {
                    var filter = "";

                    if (rotationType.id !== -1) {
                        filter = rotationType.name;
                    }

                    self.datatable.fnFilter(filter, 0, false);
                }
            });
        };
    }

    function getTableColumns() {
        return [
            {
                sTitle: "Wiederholung",
                sAttrName: "rotation_type",
                mRender: function(mData, type, obj) {
                    return rotationTypeDisplayName[obj.rotation_type];
                }
            },
            {
                sTitle: "Zugeordnet",
                sAttrName: "ass_user_id",
                mRender: function(mData, type, obj) {
                    if (obj.assigned_user) {
                        return obj.assigned_user.first_name + " " + obj.assigned_user.last_name;
                    } else if (obj.workplace_name) {
                        return obj.workplace_name;
                    } else {
                        return "";
                    }
                }
            },
            {
                sTitle: "Aufgabenbezeichnung",
                sAttrName: "label",
                mRender: function(mData, type, obj) {
                    return obj.label;
                }
            }
        ];
    }

    function getTableSettings() {
        return {
            aaSorting: [[ TASK_LABEL_COLUMN_INDEX, "asc" ]]
        };
    }

})(window.rise, window.app);
