/**
 * Dialogs for rendering selections.
 *
 * @module app.widgets
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    /*** EXPORTS ***/
    app.widgets.scrollTableSection = scrollTableSection;
    app.widgets.clowFilter = clowFilter;

    /*** IMPORTS ***/
    var initColumns         = app.widgets.initColumns;

    /*** scrollTableSection ***/

    function scrollTableSection(params, children) {
        var options = $.extend({
                id: "assigned-elements-section",
                title: null,
                columns: [],
                elements: [],
                click: null,
                'class': "",
                filter: false,
                filters: null,
                height: "320px",
                selected: null,
                select: null,
                groupedByColumn: null,
                getGroupingRowLabel: null,
                isSelectable: null,
                aaSortingColumn: 0,
                aaSortingOrder: "asc",
                paging: false,
                required: false,
            }, params),
            oTable = null;

        if (options.selected !== null) {
            options.aaSortingColumn++;
            options.click = null;

            if (options.groupedByColumn !== null) {
                options.groupedByColumn ++;
                options.aaSortingColumn ++;
            }


            options.columns = [{
                sWidth: "25px",
                sAttrName: "id",
                bSortable: false,
                mRender: function (mData, type, obj) {
                            if (type === "display") {
                                return $.box.html(
                                    input({
                                        type:"checkbox",
                                        disabled: options.isSelectable ? !options.isSelectable(obj) : false,
                                        checked: _.where(options.selected, { id: obj.id }).length > 0
                                    })
                                );
                            }

                            return "";
                        },
                fnCreatedCell: function(nTd, sData, oData) {
                    var $nTd = $(nTd);

                    $nTd.addClass("center")
                        .find('input[type=checkbox]').on("change", function _toggleSelect(e) {
                            var $target = $(e.target);

                            if ($target.prop("checked")) {
                                options.selected.push(oData);
                            } else {
                                options.selected = _.filter(options.selected, function(ele) {
                                    return !(ele.id === oData.id &&
                                        ele.assigned_to_customer === oData.assigned_to_customer &&
                                        ele.assigned_to_location === oData.assigned_to_location &&
                                        ele.assigned_to_object === oData.assigned_to_object &&
                                        ele.assigned_to_workplace === oData.assigned_to_workplace);
                                });
                            }

                            if (options.select) {
                                options.select(options.selected);
                            }

                            e.preventDefault();
                            return false;
                        });
                }
            }].concat(options.columns);
        } else {
            if (options.groupedByColumn !== null) {
                options.aaSortingColumn ++;
            }
        }

        function initTable(e) {
            var $table = $(e.target);

            options.columns = initColumns(options.columns);

            var dataTableOptions = {

                "bAutoWidth": true,

                /* columns of the datatable */
                "aoColumns": options.columns.aoColumns,

                "aaData": options.elements,

                "aaSorting": [[options.aaSortingColumn, options.aaSortingOrder]],

                "oSearch": {"bSmart": false},

                "bFilter": options.filter,

                "aaSortingFixed": options.groupedByColumn !== null ? [ [options.groupedByColumn, 'asc'] ] : undefined,

                "aoColumnDefs": options.groupedByColumn !== null
                    ? [{ "bVisible": false, "aTargets": [options.groupedByColumn] }]
                    : undefined,

                "bStateSave": false,

                "fnInitComplete": function(oSettings) {
                    if(options.filters) {

                        var $filters = $(oSettings.nTableWrapper).find(".dataTables_filter").appendElement(
                            ul({},
                                li({}, a({ "class":"filter-active", click: function() {
                                    removeAllFilters();
                                    $(this).addClass("filter-active");
                                }}, "Alle"))
                            )
                        );

                        _.each(options.filters, function(elem, name) {

                            $filters.appendElement([
                                li({}, [
                                    img({ src:"style/images/content/tick.png" }),
                                    a({ id:sprintf("%s-on-filter", name), click:function() {
                                        activateFilter(elem.values.on, name);
                                        $(this).addClass("filter-active");

                                    }}, elem.labels.on)
                                ]),
                                li({}, [
                                    img({ src:"style/images/content/cross.png" }),
                                    a({ id:sprintf("%s-off-filter", name), click:function() {
                                        activateFilter(elem.values.off, name);
                                        $(this).addClass("filter-active");

                                    }}, elem.labels.off)
                                ])
                            ]);
                        });
                    }
                },

                "fnDrawCallback": function(oSettings) {
                    $(oSettings.nTableWrapper).find(".dataTables_filter input").attr("placeholder", "Eintrag suchen");

                    $(oSettings.nTable).find("td").mouseenter(function() {
                        $(this).parent()
                            .children()
                            .each(function() {
                                $(this).toggleClass('highlight-row');
                            });
                    }).mouseleave(function() {
                        $(this).parent()
                            .children()
                            .each(function() {
                                $(this).toggleClass('highlight-row');
                            });
                    }).click(function() {
                        var oTable = oSettings.oInstance;
                        var aPos = oTable.fnGetPosition(this);

                        // get the data array for this row
                        var oData = oTable.fnGetData(aPos[0]);

                        // open member details page
                        if (options.click) {
                            options.click(oData);
                        }
                    });

                    if ( oSettings.aiDisplay.length === 0 ) {
                        return;
                    }

                    if (options.groupedByColumn !== null) {
                        renderGroupingRows($table, oSettings);
                    }

                    $(oSettings.nTable).parent().css("height", "auto");
                    $(oSettings.nTable).parent().css("max-height", options.height);
                },

                "oLanguage": {
                    // remove search text
                    "sSearch": ""
                }
            };

            if (options.filters) {
                dataTableOptions.sDom = "lfrtip";
            }

            if (options.paging) {
                dataTableOptions.iDisplayLength = 25;
                dataTableOptions.bPaginate = true;
                dataTableOptions.bLengthChange = true;
                dataTableOptions.sPaginationType = "full_numbers";
                dataTableOptions.bInfo = true;
            } else {
                dataTableOptions.bScrollCollapse = true;
                dataTableOptions.bLengthChange = false;
                dataTableOptions.sPaginationType = null;
                dataTableOptions.bPaginate = false;
                dataTableOptions.bInfo = false;
            }

            oTable = $table.dataTable(dataTableOptions);
        }

        function renderGroupingRows($table, oSettings) {
            var nGroup, nCell, iIndex, sGroup;
            var sLastGroup = "";

            var nTrs = $table.find('tbody tr');
            var iColspan = nTrs[0].getElementsByTagName('td').length;

            for ( var i=0 ; i<nTrs.length ; i++ )
            {
                iIndex = oSettings._iDisplayStart + i;
                if (options.getGroupingRowLabel) {
                    sGroup = options.getGroupingRowLabel(oSettings.aoData[ oSettings.aiDisplay[iIndex] ]._aData);
                } else {
                    sGroup = oSettings.aoData[ oSettings.aiDisplay[iIndex] ]._aSortData[options.groupedByColumn];
                }

                if ( sGroup !== sLastGroup )
                {
                    /* Cell to insert into the frozen columns */
                    nGroup = document.createElement( 'tr' );
                    nCell = document.createElement( 'td' );
                    nCell.colSpan = iColspan;
                    nCell.className = "group";
                    nCell.innerHTML = sGroup;
                    nGroup.appendChild( nCell );
                    nTrs[i].parentNode.insertBefore( nGroup, nTrs[i] );
                    sLastGroup = sGroup;
                }
            }
        }

        function createWidget() {
            if (options.title) {
                return div({id: options.id, 'class': options['class']}, [
                    h2({}, options.title),
                    div({ id:"placeholder-filters" }),
                    div({'class': 'whiteboard', style:options.tableStyle }, [
                        table({id:options.id + "-table", "class":options.paging ? "display" : "",
                               style:"width:100%", create:initTable}),
                        div({ }, children)
                    ])
                ]);
            } else {
                return div({id: options.id, 'class': options['class']}, [
                    div({ id:"placeholder-filters" }),
                    table({id:options.id + "-table", "class":options.paging ? "display" : "",
                           style:"width:100%", create:initTable}),
                    div({ }, children)
                ]);
            }
        }

        function activateFilter(state, name) {
            removeAllFilters();
            oTable.fnFilter(""+state, options.columns.getColumnIdx(name), true);
        }

        function removeAllFilters() {
            $(".dataTables_filter li a").removeClass("filter-active");

            _.each(options.filters, function(elem, name) {
                oTable.fnFilter("", options.columns.getColumnIdx(name));
            });
        }

        return createWidget();
    }

    /** Customer/Location/Object/Workplace filter section **/

    function clowFilter(params, children) {
        var options = $.extend({
                id: "clow-filter-section",
                customers: [],
                depth: 4,
                change: null,
                refresh: false,
                $parent: null,
                attributeNameLocations: 'locations',
                attributeNameObjects: 'objects',
                attributeNameWorkplaces: 'assigned_workplaces',
                selectedCustomerId: null,
                selectedLocationId: null,
                selectedObjectId: null,
                selectedWorkplaceId: null,
                react: false,   // used by react component?
                view: null,     // set true|false to force either view (true) or edit (false) mode.
            }, params),
            filter =  {
                customer: null,
                location: null,
                object: null,
                workplace: null
            },
            allEntries = { id:-1, name:"Alle" },
            customers = [],
            locations = [],
            objects = [],
            workplaces = [],
            form = app.widgets.form;

        function concatLists(list1, list2) {
            _.each(list2, function(elem) {
                if (!_.findWhere(list1, {id: elem.id})) {
                    list1.push(elem);
                }
            });

            return list1;
        }

        function getAllCustomers() {
            customers.length = 0;
            customers.splice.apply(customers, [0, 0].concat(options.customers));

            customers = customers.sort(fnNameSort);
            customers.splice(0, 0, allEntries);

            if (customers.length === 2) {
                customers.shift();
            }

            if (options.selectedCustomerId) {
                filter.customer = customers.find((customer) => customer.id === options.selectedCustomerId)
                    ?? customers[0];
            } else {
                filter.customer = customers[0];
            }

            return customers;
        }

        function getAllLocations() {
            locations.length = 0;

            if (!filter.customer) {
                return [];
            }

            if (filter.customer.id === -1) {
                _.each(customers, function(customer) {
                    if (customer[options.attributeNameLocations]) {
                        concatLists(locations, customer[options.attributeNameLocations]);
                    }
                });
            } else {
                concatLists(locations, filter.customer[options.attributeNameLocations]);
            }

            locations = locations.sort(fnNameSort);
            locations.splice(0, 0, allEntries);

            if (options.selectedLocationId) {
                filter.location = locations.find((location) => location.id === options.selectedLocationId)
                    ?? locations[0];
            } else if (locations.length === 2) {
                filter.location = locations[1];
            } else {
                filter.location = locations[0];
            }

            return locations;
        }

        function getAllObjects() {
            objects.length = 0;

            if (!filter.location) {
                return [];
            }

            if (filter.location.id === -1) {
                _.each(locations, function(location) {
                    if (location[options.attributeNameObjects]) {
                        concatLists(objects, location[options.attributeNameObjects]);
                    }
                });
            } else {
                concatLists(objects, filter.location[options.attributeNameObjects]);
            }

            objects = objects.sort(fnNameSort);
            objects.splice(0, 0, allEntries);

            if (options.selectedObjectId) {
                filter.object = objects.find((object) => object.id === options.selectedObjectId)
                    ?? objects[0];
            } else if (objects.length === 2) {
                filter.object = objects[1];
            } else {
                filter.object = objects[0];
            }

            return objects;
        }

        function getAllWorkplaces() {
            workplaces.length = 0;

            if (!filter.object) {
                return [];
            }

            if (filter.object.id === -1) {
                _.each(objects, function(object) {
                    if (object[options.attributeNameWorkplaces]) {
                        concatLists(workplaces, object[options.attributeNameWorkplaces]);
                    }
                });
            } else {
                concatLists(workplaces, filter.object[options.attributeNameWorkplaces]);
            }

            workplaces = workplaces.sort(fnNameSort);
            workplaces.splice(0, 0, allEntries);

            if (options.selectedWorkplaceId) {
                filter.workplace = workplaces.find((workplace) => workplace.id === options.selectedWorkplaceId)
                    ?? workplaces[0];
            } else if (workplaces.length === 2) {
                filter.workplace = workplaces[1];
            } else {
                filter.workplace = workplaces[0];
            }

            return workplaces;
        }

        function onChange() {
            if (options.change) {
                options.change({
                    "customer": filter.customer ? filter.customer.id : null,
                    "location": filter.location ? filter.location.id : null,
                    "object": filter.object ? filter.object.id : null,
                    "workplace": filter.workplace ? filter.workplace.id : null
                });
            }
        }

        function onChangeRefresh() {
            if (options.change) {
                options.change({
                    "customer": $('#filter-customer').hasClass('ui-autocomplete-input')
                        ? $("#filter-customer").combobox("getSelected").id
                        : null,
                    "location": $('#filter-location').hasClass('ui-autocomplete-input')
                        ? $("#filter-location").combobox("getSelected").id
                        : null,
                    "object": $('#filter-object').hasClass('ui-autocomplete-input')
                        ? $("#filter-object").combobox("getSelected").id
                        : null,
                    "workplace": $('#filter-workplace').hasClass('ui-autocomplete-input')
                        ? $("#filter-workplace").combobox("getSelected").id
                        : null
                });
            }
        }

        function fnNameSort(obj1, obj2) {
            return obj1.name < obj2.name ? -1 : 1;
        }

        /* refresh filters */
        if(options.refresh) {
            if($('#filter-customer').hasClass('ui-autocomplete-input')) {
                $('#filter-customer').combobox('option', 'source', getAllCustomers());
            }
            onChangeRefresh();
        }  else {
            var $dropDownWidgets = [
                // customer filter
                options.depth >= 1 ? form.formItemSelect({
                    id: 'filter-customer',
                    label: 'Kunde',
                    required: options.required,
                    source: getAllCustomers(),
                    valueProp: 'name',
                    value: filter.customer,
                    sort: false,
                    selected: function(e, customer) {
                        if (customer.id) {
                            const prevCustomer = filter.customer;
                            filter.customer = customer;

                            getAllLocations();

                            if (!options.react || filter.customer.id !== prevCustomer?.id) {
                                $("#filter-location").combobox("setSelected", filter.location);
                                onChange();
                            }
                        }
                    }
                }, options.view !== null ? options.view : customers.length <= 1): undefined,

                // location filter
                options.depth >= 2 ? form.formItemSelect({
                    id: 'filter-location',
                    label: 'Standort',
                    required: options.required,
                    source: getAllLocations(),
                    valueProp: 'name',
                    value: filter.location,
                    sort: false,
                    selected: function(e, location) {
                        if (location.id) {
                            const prevLocation = filter.location;
                            filter.location = location;

                            getAllObjects();

                            if (!options.react || filter.location.id !== prevLocation?.id) {
                                $("#filter-object").combobox("setSelected", filter.object);
                                onChange();
                            }
                        }
                    }
                }, options.view !== null ? options.view : locations.length <= 2) : undefined,

                // object filter
                options.depth >= 3 ? form.formItemSelect({
                    id: 'filter-object',
                    label: 'Objekt',
                    required: options.required,
                    source: getAllObjects(),
                    valueProp: 'name',
                    value: filter.object,
                    sort: false,
                    selected: function(e, object) {
                        if (object.id) {
                            const prevObject = filter.object;
                            filter.object = object;

                            getAllWorkplaces();

                            if (!options.react || filter.object.id !== prevObject?.id) {
                                $("#filter-workplace").combobox("setSelected", filter.workplace);
                                onChange();
                            }
                        }
                    }
                }, options.view !== null ? options.view : objects.length <= 2) : undefined,

                // workplace filter
                options.depth >= 4 ? form.formItemSelect({
                    id: 'filter-workplace',
                    label: 'Arbeitsplatz',
                    required: options.required,
                    source: getAllWorkplaces(),
                    valueProp: 'name',
                    value: filter.workplace,
                    sort: false,
                    selected: function(e, workplace) {
                        if (workplace.id) {
                            const prevWorkplace = filter.workplace;
                            filter.workplace = workplace;

                            if (!options.react || filter.workplace.id !== prevWorkplace?.id) {
                                onChange();
                            }
                        }
                    }
                }, options.view !== null ? options.view : workplaces.length <= 2) : undefined,

                function(box) {
                    if (children) {
                        box.appendElement(children);
                    }
                }
            ];

            if (options.$parent) {
                options.$parent.appendElement($dropDownWidgets);
            } else {
                return div({ id: options.id, 'class': 'whiteboard',
                    keydown: function (e) {
                        e.stopImmediatePropagation();
                    }
                }, [
                    legend({}),
                    ul({ "class": "inline-list title-row-highlight"}, $dropDownWidgets)
                ]);
            }
        }
    }

})(window.app);

export const scrollTableSection = app.widgets.scrollTableSection;
export const clowFilter = app.widgets.clowFilter;
export function clowFilterReact(params, children) {
    return app.widgets.clowFilter({
        ...params,
        react: true,
    }, children);
}
