/**
 * Defines the notification widget.
 *
 * @module app.widgets
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    /** DAOs **/
    var userDao = app.dao.userDao;

    /*** IMPORTS ***/
    app.widgets.NotificationWidget = NotificationWidget;

    function NotificationWidget (options) {

        if (!options.parent) {
            throw "No parent element provided!";
        }

        this.options = $.extend({
            rules: {
                openTasks: {
                    stat: 0,
                    errorMessage: "%s offene Aufgaben",
                    errorAction: function() {
                        rise.Navigation.goToPage(app.pages.logbook.task.list.open);
                    },
                    condition: function(stat) { return stat === 0; }
                },

                unreadbusinessDocuments: {
                    stat: 0,
                    errorMessage: "%s ungelesene Dokumente",
                    errorAction: function() {
                        rise.Navigation.goToPage(app.pages.business_document.list);
                    },
                    condition: function(stat) { return stat === 0; }
                },

                shiftTime: {
                    stat: null,
                    errorMessage: "Der Schichtbeginn war vor mehr als 24 Stunden",
                    errorAction: function() {
                        rise.Navigation.goToPage(app.pages.shift.check);
                    },
                    condition: function(stat) {
                        var before24Hours = new Date();
                        before24Hours.addDays(-1);

                        return !stat || stat > before24Hours;
                    }
                }
            },
            interval: 15
        }, options);

        this.updateNotifications();
        this.scheduleRefreshAction();
    }

    NotificationWidget.prototype.scheduleRefreshAction = function() {
        var self = this;

        if (app.session.hasActiveSession()) {
            setTimeout(function() {
                if (app.session.hasActiveSession()) {
                    self.refresh(true);
                    self.scheduleRefreshAction();
                }
            }, self.options.interval * 60 * 1000);
        }
    };

    NotificationWidget.prototype.updateStats = function(stats) {
        _.each(this.options.rules, function(rule, id) {
            if (stats[id] !== undefined) {
                rule.stat = stats[id];
            }
        });
        this.updateNotifications();
    };


    NotificationWidget.prototype.updateNotifications = function() {
        var self = this;
        var empty = true;

        if($(this.options.parent).children().length === 0) {
            this.$notificationList = this.options.parent.appendElement(
                div({ id:"notification-widget" },
                    ul({ id:"notification-list" })
                )
            ).find("#notification-list");
        }

        self.$notificationList.empty();
        _.each(this.options.rules, function (rule) {
            if (!rule.condition(rule.stat)) {
                self.$notificationList.appendElement(li({ id:"notification-list-item" },
                    a({ click:rule.errorAction }, sprintf(rule.errorMessage, rule.stat))
                ));
                empty = false;
            }
        });

        if(empty) {
            $(this.options.parent).empty();
        }
    };

    NotificationWidget.prototype.refresh = function(showNotification) {
        var self = this;

        userDao.getNotifications().done(function(notifications) {

            try {
                // update session
                var sessionInfo = app.session.getSessionInfo();
                sessionInfo.notifications.open_task_cnt = notifications.open_task_cnt;
                sessionInfo.notifications.unread_business_documents_cnt = notifications.unread_business_documents_cnt;
                app.session.saveSessionInfo(sessionInfo);

                if (self.updateStats) {

                    self.updateStats({
                        openTasks: sessionInfo.notifications.open_task_cnt,
                        unreadbusinessDocuments: sessionInfo.notifications.unread_business_documents_cnt,
                        shiftTime: sessionInfo.shift
                            ? new Date(sessionInfo.shift.started)
                            : null
                    });

                    if(showNotification) {
                        _.each(self.options.rules, function (rule) {
                            if (!rule.condition(rule.stat)) {
                                app.showInfoMessage(sprintf(rule.errorMessage, rule.stat));
                            }
                        });
                    }
                }
            } catch (e) {
                if (console && console.log) {
                    console.log(e);
                }
            }
        });

    };



})(window.app);
