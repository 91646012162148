/**
 * This file contains all model used by the shift RPC endpoint
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.EventType = function EventType(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id =           obj.id != null ? parseInt(obj.id) : null;
        this.active =       (obj.active === true || obj.active === false) ? obj.active : null;
        this.label =        obj.label != null ? obj.label : null;
        this.finalized =    (obj.finalized === true || obj.finalized === false) ? obj.finalized : null;
        this.alarm_email =  (obj.alarm_email === true || obj.alarm_email === false) ? obj.alarm_email : null;
        this.send_emails =  obj.send_emails != null ? obj.send_emails : null;
        this.priority =     (obj.priority === true || obj.priority === false) ? obj.priority : null;

        this.event_field_types = _.map(obj.event_field_types || [], function(json) {
            return new app.model.EventFieldType(json);
        });
    };

}(window.app));
