/**
 * Data Access Object for the business_document_category endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;

    /*** Import ***/
    var BusinessDocumentCategory = app.model.BusinessDocumentCategory;

    /*** Export Dao ***/
    app.dao.businessDocumentCategoryDao = new BusinessDocumentCategoryDao();

    /**
     * @constructor
     */
    function BusinessDocumentCategoryDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.BusinessDocumentCategory, new Rpc('/rpc/business_document_category'));
    }

    // extend the parent Dao prototype
    $.extend(BusinessDocumentCategoryDao.prototype, Object.create(rise.Dao.prototype));

    BusinessDocumentCategoryDao.prototype.findAll = function(filter) {
        var deferred = new $.Deferred();

        this.rpc.invoke('find_all', {
            filter_params: filter
        })
            .done(function(result) {
                var list = _.map(result, function(e) {return new BusinessDocumentCategory(e);});
                deferred.resolve(list);
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();

    };

    BusinessDocumentCategoryDao.prototype.findWithStats = function(filter) {
        var deferred = new $.Deferred();

        this.rpc.invoke('find_with_stats', filter)
            .done(function(result) {
                deferred.resolve(_.map(result, function(e) {
                    return new app.dto.BusinessDocumentCategoryListDto(e);
                }));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();

    };

    BusinessDocumentCategoryDao.prototype.delete = function(id) {
        var deferred = new $.Deferred();

        this.rpc.invoke('remove', {
            id: id
        })
            .done(function(result) {
                deferred.resolve(result);
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();

    };

    BusinessDocumentCategoryDao.prototype.create = function(category) {
        var deferred = new $.Deferred();

        this.rpc.invoke('insert', {
            document: category
        })
            .done(function(result) {
                deferred.resolve(result);
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();

    };

    BusinessDocumentCategoryDao.prototype.update = function(category) {
        var deferred = new $.Deferred();

        this.rpc.invoke('update', {
            document: category
        })
            .done(function(result) {
                deferred.resolve(result);
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();

    };

})(window.rise, window.app);
