import keycode from "keycode";

/**
 * Workplace detail page.
 *
 * @author Martin Reiterer (martin.reiterer@rise-world.com)
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav                 = rise.Navigation;
    var pages               = app.pages;
    var form                = app.widgets.form;
    var scrollTableSection  = app.widgets.scrollTableSection;
    var tabsWidget          = app.widgets.tabsWidget;
    var editIconButton      = app.widgets.editIconButton;
    var cancelIconButton    = app.widgets.cancelIconButton;
    var saveIconButton      = app.widgets.saveIconButton;
    var printIconButton     = app.widgets.printIconButton;
    var commandBox          = app.widgets.commandBox;
    var DMSIntervalListForm = app.widgets.DMSIntervalListForm;

    /** DAOs **/
    var Workplace      = app.model.Workplace;
    var workplaceDao   = app.dao.workplaceDao;
    var eventTypeDao   = app.dao.eventTypeDao;
    var businessDocumentDao = app.dao.businessDocumentDao;
    var objectDao      = app.dao.objectDao;
    var employeeDao    = app.dao.employeeDao;
    var tourDao        = app.dao.tourDao;

    var currentTab = 0;

    var PageType = {
        VIEW: 1,
        EDIT: 2,
        NEW: 3
    };

    /** CONSTANTS **/

    /*** Export page ***/
    pages.workplace.detail = app.pagesByPath['/workplace/detail'] = function WorkplaceDetail() {
        var self = this;

        this.onUpdate = null;
        this.onTreeNavigate = null;
        this.onAddChild = null;
        this.getParentId = null;
        this.name = "/workplace/detail";

        this.type = PageType.VIEW;

        this.workplace = null;
        this.eventTypes = [];
        this.business_documents = [];
        this.tasks = [];
        this.employees = [];

        this.observedObjects = [];
        this.assignedEmployees = [];
        this.assignedEventTypes = [];
        this.assignedBusinessDocuments = [];
        this.assignedTasks = [];
        this.observedObjects = [];
        this.assignedTours = [];
        this.dmsIntervals = [];

        this.$parent = null;

        this.create = function ($parent, workplaceId, onUpdate, onTreeNavigate, onAddChild, getParentId, getNodeData, getCustomerData) {
            this.$parent = $parent;
            this.onUpdate = onUpdate;
            this.onTreeNavigate = onTreeNavigate;
            this.onAddChild = onAddChild;
            this.getParentId = getParentId;
            this.getCustomerData = getCustomerData;
            this.getNodeData = getNodeData;

            if (workplaceId) {
                return workplaceDao.getWorkplaceDetails(workplaceId)
                    .done(function(workplaceDetailsDto) {
                        self.workplace = workplaceDetailsDto.workplace;
                        self.assignedEventTypes = workplaceDetailsDto.assigned_event_types;
                        self.assignedBusinessDocuments = workplaceDetailsDto.assigned_business_documents;
                        self.assignedEmployees = workplaceDetailsDto.assigned_employees;
                        self.assignedTasks = workplaceDetailsDto.assigned_tasks;
                        self.observedObjects = workplaceDetailsDto.observed_objects;
                        self.assignedTours = workplaceDetailsDto.assigned_tours;
                        self.dmsIntervals = workplaceDetailsDto.dms_intervals;
                        self.dmsiFormList = new DMSIntervalListForm(self.dmsIntervals);

                        self.type = PageType.VIEW;
                        renderWorkplace();
                    });
            } else {
                var objectId = parseInt(self.getParentId(), 10);

                return $.when(eventTypeDao.listAll(),
                        businessDocumentDao.listAll({
                            include_read_count: false
                        }),
                        employeeDao.listAll(),
                        objectDao.getObjectAssignments(objectId),
                        objectDao.findObservableObjects(objectId))
                    .done(function(eventTypes, business_documents, employees, objectAssignments, observableObjects, assignableTours) {
                        self.eventTypes = eventTypes;
                        self.business_documents = business_documents;
                        self.employees = employees;
                        self.assignedEventTypes = objectAssignments.assigned_event_types;
                        self.assignedBusinessDocuments = objectAssignments.assigned_business_documents;
                        self.observableObjects = observableObjects;
                        self.dmsiFormList = new DMSIntervalListForm(self.dmsIntervals);

                        /* by default a created workplace should monitor the object that it was created in */
                        self.observedObjects = $.grep(observableObjects, function(obj) {
                            return obj.id === objectId;
                        });

                        self.type = PageType.NEW;
                        self.workplace = new Workplace();
                        renderWorkplace();
                    });
            }
        };

        this.destroy = function() {
            // called after page has been destroyed
        };

        this.reRenderOverviewSection = function(view) {
            var $overviewSection = $("#overview-section");

            $overviewSection.empty().appendElement([
                createWorkplaceDetails(self.workplace, view, self),
                renderObservedObjectsList(view)
            ]);

            if (self.workplace.dms_active === true) {
                self.dmsiFormList.render($overviewSection, view);
            }
        };

        function renderWorkplace() {
            var view = self.type === PageType.VIEW;

            var tabs =  [
                {
                    'id': 'customer-details-tab',
                    'title': 'Allgemein',
                    'fnRender': $.proxy(renderOverviewSection, self, view)
                },
                {
                    'id':"assigned-event-types-tab",
                    'title':"Ereignistypen",
                    'fnRender': $.proxy(renderAssignedEventTypesList, self, view)
                },
                {
                    'id':"assigned-business-documents-tab",
                    'title':"Dokumente",
                    'fnRender': $.proxy(renderAssignedBusinessDocumentsList, self, view)
                },
                {
                    'id':"assigned-tasks-tab",
                    'title':"Aufgaben",
                    'fnRender': $.proxy(renderAssignedTasksList, self, view)
                },
                {
                    'id':"assigned-shift-user-tab",
                    'title':"Benutzer für Schicht",
                    'fnRender': $.proxy(renderShiftUsersList, self, view ||
                        !window.app.session.hasPermission(app.model.PermissionTypes.LOUNGEASSIGNMENT_MODIFY))
                }
            ];

            if (view) {
                tabs.push({
                    'id': "assigned-tours-tab",
                    'title': "Rundgänge",
                    'fnRender': $.proxy(renderAssignedTours, self, view)
                });
            }

            self.$parent.empty().appendElement(createCommandBox());

            self.$parent.appendElement(tabsWidget(
                { id: "customer-assignments", beforeActivate: beforeActivate, active: currentTab }, tabs
            ));

            self.$parent.activateValidators();
        }

        function beforeActivate(event, ui) {
            currentTab = ui.newTab.index();
        }

        function renderOverviewSection(view) {
            return div({id: "overview-section",
                create: function (e) {
                    var $overviewSection = $(this);

                    $overviewSection.appendElement([
                        createWorkplaceDetails(self.workplace, view, self),
                        renderObservedObjectsList(view)
                    ]);

                    if (self.workplace.dms_active === true) {
                        self.dmsiFormList.render($overviewSection, view);
                    }
                }});
        }

        function createCommandBox() {
            var savedWorkplace = new Workplace(self.workplace);

            function cancelEditWorkplace () {
                if (self.type !== PageType.NEW) {
                    $.validation.reset();

                    self.workplace = savedWorkplace;
                    self.type = PageType.VIEW;
                    self.dmsiFormList = new DMSIntervalListForm(self.dmsIntervals);
                    renderWorkplace();
                } else {
                    self.onTreeNavigate("object", { id: self.getParentId() });
                }
            }

            function saveButton() {
                return saveIconButton({id: 'save-workplace',
                    click: saveWorkplace
                });
            }

            function cancelButton() {
                return cancelIconButton({id: 'cancel-edit-workplace',
                    click: cancelEditWorkplace
                });
            }

            function editButton() {
                return editIconButton({id: 'edit-workplace',
                    click: editWorkplace
                });
            }

            function printButton() {
                return printIconButton({id: 'print-customer',
                    click: function() {
                        app.printpreview.openCustomerPrintPreview({
                            customer: self.getCustomerData(),
                            workplace: self.getNodeData()
                        });
                    }
                }, 'Stammdaten drucken');
            }

            function getTitle() {
                switch (self.type) {
                case PageType.NEW:
                    return 'Neuer Arbeitsplatz';
                case PageType.EDIT:
                    return 'Arbeitsplatz bearbeiten';
                case PageType.VIEW:
                    return 'Arbeitsplatz: ' + self.workplace.name;
                }
                return '';
            }

            function getCommandButtons () {
                if (!window.app.session.hasPermission(app.model.PermissionTypes.CUSTOMER_MODIFY)) {
                    return [];
                }

                switch (self.type) {
                case PageType.VIEW:
                    return [
                        printButton(),
                        editButton()
                    ];

                case PageType.EDIT:
                    return [
                        saveButton(),
                        cancelButton()
                    ];

                case PageType.NEW:
                    return [
                        saveButton(),
                        cancelButton()
                    ];
                }

                return [];
            }

            return commandBox({title: getTitle(),'class':'inner'},getCommandButtons());

        }

        function editWorkplace () {
            var fnEdit = function() {
                self.type = PageType.EDIT;
                renderWorkplace();
            };

            if (self.eventTypes.length > 0 && self.business_documents.length > 0) {
                fnEdit();
            } else {
                var objectId = parseInt(self.getParentId(), 10);
                return $.when(eventTypeDao.listAll(),
                        businessDocumentDao.listAll({
                            include_read_count: false
                        }),
                        employeeDao.listAll(),
                        objectDao.findObservableObjects(objectId))
                    .done(function(eventTypes, business_documents, employees, observableObjects) {
                        self.eventTypes = eventTypes;
                        self.business_documents = business_documents;
                        self.employees = employees;
                        self.observableObjects = observableObjects;

                        fnEdit();
                    });
            }
        }

        function saveWorkplace() {
            if (!self.$parent.validate() || !self.dmsiFormList.validate()) {
                return;
            }

            self.dmsIntervals = self.dmsiFormList.getDMSIntervals();

            var method = $.proxy(workplaceDao.insertWorkplaceAndAssignments, workplaceDao);

            if(self.workplace.id) {
                method = $.proxy(workplaceDao.updateWorkplaceAndAssignments, workplaceDao);
            } else {
                self.workplace.located_in_object_id = parseInt(self.getParentId(), 10);
            }

            var $workplaceEmail = $("#workplace-email");
            if ($workplaceEmail.length > 0) {
                self.workplace.send_emails = $workplaceEmail.tokenlist("value") || null;
            }

            function saveDoneMethod(result) {
                self.workplace = result;
                self.type = PageType.VIEW;

                renderWorkplace();
                app.showSuccessMessage('Gespeichert');

                if (self.onUpdate) {
                    self.onUpdate(result);
                }
            }

            // execute action
            return method(self.workplace,
                    _.pluck(self.observedObjects, 'id'),
                    _.pluck(_.filter(self.assignedEventTypes,
                        function(et) { return et.assigned_to_workplace === true; }), 'id'),
                    _.pluck(_.filter(self.assignedBusinessDocuments,
                        function(inst) { return inst.assigned_to_workplace === true; }), 'id'),
                    window.app.session.hasPermission(app.model.PermissionTypes.LOUNGEASSIGNMENT_MODIFY)
                        ? _.pluck(self.assignedEmployees, 'id') : null,
                    _.pluck(self.assignedTours, 'id'),
                    self.dmsIntervals)
                .done(saveDoneMethod);
        }

        function renderAssignedEventTypesList(view) {
            var getRowLabel = function(aoData) {
                    return aoData.assigned_to_workplace === true ||
                        !_.find(self.assignedEventTypes, function (et) { return et.id === aoData.id; })
                        ? "Direkt dem Arbeitsplatz zugeordnet"
                        : aoData.assigned_to_object
                            ? "Übernommen von Objekt"
                            : aoData.assigned_to_location
                                ? "Übernommen von Standort"
                                : "Übernommen von Kunde"
                },
                isSelectable = function(aoData) {
                    return getRowLabel(aoData) === "Direkt dem Arbeitsplatz zugeordnet";
                };

            return scrollTableSection({
                    filter: true,
                    id: "assigned-event-types",
                    elements: view
                        ? self.assignedEventTypes
                        : getAllEventTypes(),
                    selected: view ? null : self.assignedEventTypes,
                    select: function(selected) {
                        _.each(selected, function(sel) {
                            if (! (sel.assigned_to_customer || sel.assigned_to_location || sel.assigned_to_object)) {
                                sel.assigned_to_workplace = true;
                            }
                        });
                        self.assignedEventTypes = selected;
                    },
                    isSelectable: isSelectable,
                    columns: [
                        { sTitle: "Vererbt", sAttrName: "assigned_to_workplace", bVisible: false,
                            mRender: function(mData, type, obj) {
                                return getRowLabel(obj);
                            } },
                        { sTitle: "ID", sAttrName: "id" },
                        { sTitle: "Bezeichnung", sAttrName: "label" }
                    ],
                    click: displayEventType,
                    getGroupingRowLabel: getRowLabel,
                    groupedByColumn: 0,
                    height: "100%"
                });
        }

        function renderObservedObjectsList(view) {
            return scrollTableSection({
                id: "observed-objects",
                title: 'Bewachte Objekte',
                elements: view
                    ? self.observedObjects
                    : getObservableObjects(),
                selected: view ? null : self.observedObjects,
                select: function(selected) {
                    self.observedObjects = selected;
                },
                isSelectable: function(obj) {
                    var objectId = parseInt(self.getParentId(), 10);
                    return obj.id != objectId;
                },
                columns: [
                    { sTitle: "Bezeichnung", sAttrName: "name" },
                    { sTitle: "Aktiv", sAttrName: "active", mRender:function(mData, type, obj) {
                        return obj.active ? "Ja" : "Nein";
                    }}
                ],
                "class": "",
                click: displayObject
            });
        }

        function renderAssignedBusinessDocumentsList(view) {
            var getRowLabel = function(aoData) {
                    return aoData.assigned_to_workplace === true ||
                        !_.find(self.assignedBusinessDocuments, function (et) { return et.id === aoData.id; })
                        ? "Direkt dem Arbeitsplatz zugeordnet"
                        : aoData.assigned_to_object
                            ? "Übernommen von Objekt"
                            : aoData.assigned_to_location
                                ? "Übernommen von Standort"
                                : "Übernommen von Kunde"
                },
                isSelectable = function(aoData) {
                    return getRowLabel(aoData) === "Direkt dem Arbeitsplatz zugeordnet";
                };

            /* jshint indent:false */

            return scrollTableSection({
                filter: true,
                id: "assigned-business-documents",
                elements: view
                    ? self.assignedBusinessDocuments
                    : getAllBusinessDocuments(),
                selected: view ? null : self.assignedBusinessDocuments,
                select: function(selected) {
                    _.each(selected, function(sel) {
                        if (!(sel.assigned_to_customer || sel.assigned_to_location || sel.assigned_to_object)) {
                            sel.assigned_to_workplace = true;
                        }
                    });
                    self.assignedBusinessDocuments = selected;
                },
                isSelectable: isSelectable,
                columns: [
                    { sTitle: "Vererbt", sAttrName: "assigned_to_workplace", bVisible: false,
                        mRender: function(mData, type, obj) {
                            return getRowLabel(obj);
                        }
                    },
                    { sTitle: "ID", sAttrName: "id" },
                    { sTitle: "Bezeichnung", sAttrName: "name" }
                ],
                getGroupingRowLabel: getRowLabel,
                click: displayBusinessDocument,
                groupedByColumn: 0,
                height: "100%"
            });
        }

        function renderAssignedTasksList(view) {
            return scrollTableSection({
                    filter: true,
                    id: "assigned-tasks",
                    elements: self.assignedTasks,
                    columns: [
                        {
                            sTitle: "Wiederholung",
                            sAttrName: "rotation_type",
                            mRender: function(mData, type, obj) {
                                return {
                                    UNIQUE: 'Einmalig',
                                    MONTHLY: 'Monatlich',
                                    WEEKLY: 'Wöchentlich',
                                    DAILY: 'Täglich'
                                }[obj.rotation_type];
                            }
                        },
                        {
                            sTitle: "Bezeichnung",
                            sAttrName: "label",
                            mRender: function(mData, type, obj) {
                                return obj.label;
                            }
                        },
                        {
                            sTitle: "Aktiv",
                            sAttrName: "active",
                            bSortable: true,
                            sWidth: "50px",
                            mRender: function (mData, type, obj) {
                                return obj.active ? "Ja" : "Nein";
                            }
                        }
                    ],
                    click: displayTask,
                    height: "100%"
                });
        }

        function renderShiftUsersList(view) {
            return scrollTableSection({
                    id: "assigned-users",
                    filter: true,
                    elements: view
                        ? self.assignedEmployees
                        : self.employees,
                    selected: view ? null : self.assignedEmployees,
                    select: function(selected) {
                        self.assignedEmployees = selected;
                    },
                    columns: [
                        { sTitle: "Nachname", sAttrName: "last_name" },
                        { sTitle: "Vorname", sAttrName: "first_name" },
                        { sTitle: "Benutzername", sAttrName: "username" }
                    ],
                    click: displayEmployee,
                    height: "100%"
                });
        }

        function renderAssignedTours(view) {
            return scrollTableSection({
                filter: true,
                id: "assigned-tours",
                elements: self.assignedTours,
                columns: [
                    {
                        sTitle: "Erstellt am",
                        sAttrName: "created_at",
                        mRender: function(mData, type, obj) {
                            return obj.created_at.toFormatString(app.settings.dateTimeFormat);
                        }
                    },
                    {
                        sTitle: "Name",
                        sAttrName: "name"
                    },
                    {
                        sTitle: "Beschreibung",
                        sAttrName: "description"
                    }
                ],
                click: displayTour,
                height: "100%"
            });
        }

        function displayObject(object) {
            if (self.onTreeNavigate) {
                self.onTreeNavigate("object", object);
            }
        }

        function getObservableObjects() {
            return _.map(self.observableObjects, function(object) {
                var o = _.find(self.observedObjects, function (o) { return o.id === object.id; });
                return o || object;
            });
        }

        function getAllEventTypes() {
            return _.map(self.eventTypes, function(eventType) {
                var et = _.find(self.assignedEventTypes, function (et) { return et.id === eventType.id; });
                return et || eventType;
            });
        }

        function getAllBusinessDocuments() {
            return _.map(self.business_documents, function(business_document) {
                var inst = _.find(self.assignedBusinessDocuments, function (inst) { return inst.id === business_document.id; });
                return inst || business_document;
            });
        }

    };

    function displayTour(tour) {
        if(window.app.session.hasPermission(app.model.PermissionTypes.TOUR_MODIFY)) {
            nav.goToPage(pages.tour.detail, [tour, '/customer/manager']);
        }
    }

    function displayBusinessDocument(business_document) {
        nav.goToPage(pages.business_document.detail, [business_document.id, true, '/customer/manager']);
    }

    function displayEventType(eventType) {
        nav.goToPage(pages.event_type.detail, [eventType, '/customer/manager']);
    }

    function displayTask(task) {
        nav.goToPage(pages.task.detail, [task.id, '/customer/manager']);
    }

    function displayEmployee(employee) {
        nav.goToPage(pages.user.detail, [employee.id, '/customer/manager']);
    }

    /**
     * Renders the workplace details. Also used for the customer print preview.
     */
    const createWorkplaceDetails = pages.workplace.detail.createWorkplaceDetails = function(workplace, view, detailPage) {
        return fieldset({id: 'workplace-details'}, [
            ul({'class':'fieldset-content'}, [
                form.formItemText({
                    id: "workplace-name",
                    required: true,
                    label: "Name",
                    value: workplace.name,
                    change: function(e) {
                        workplace.name = $(e.target).val();
                    }
                }, view),

                form.formItemCheckbox({
                    id: "workplace-active",
                    label: "Aktiv",
                    checked: workplace.active != null ? workplace.active : workplace.active=true,
                    change: function(e) {
                        workplace.active = $(e.target).prop("checked");
                    }
                }, view),

                form.formItemCheckbox({
                    id: "dms-active",
                    label: "Totmannschaltung aktiv",
                    checked: workplace.dms_active != null ? workplace.dms_active : workplace.dms_active=false,
                    change: function(e) {
                        workplace.dms_active = $(e.target).prop("checked");
                        detailPage.reRenderOverviewSection();
                    }
                }, view),

                form.formItemText({id: 'workplace-email', label: 'Email-Adresse(n)', required: workplace.dms_active,
                    value: view ? workplace.send_emails && workplace.send_emails.split(';').join('; ') : workplace.send_emails,
                    create: function() {
                        $(this).tokenlist({
                            tokenDelimiter: [keycode('space'), keycode(',')],
                            split: /\s*;\s*/,
                            join: ';',
                            acceptCustomInput: true,
                            validate: function(labelValue) {
                                const val = labelValue.value;
                                if(val !== undefined) {
                                    const pattern = new RegExp(/^([a-zA-Z0-9._\\+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})?$/);
                                    return pattern.test(val);
                                }

                                return true;
                            }
                        });
                    }
                }, view)
            ])
        ]);
    };


}) (window.rise, window.app);
