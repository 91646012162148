/**
 * Ticket detail page.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav         = rise.Navigation;

    var iconButton  = app.widgets.iconButton;
    var scrollTableSection  = app.widgets.scrollTableSection;
    var commandBox = app.widgets.commandBox;

    /** Models **/
    var User            = app.model.User;

    /** DAOs **/
    var customerDao  = app.dao.customerDao;
    var roleDao      = app.dao.roleDao;
    var workplaceDao = app.dao.workplaceDao;
    var userDao      = app.dao.userDao;

    /*** GLOBALS ***/
    var pages   = app.pages;
    var form    = app.widgets.form;
    var self;

    var PageType = {
        VIEW: 1,
        EDIT: 2,
        NEW: 3
    };

    /*** Export page ***/
    pages.user.detail = app.pagesByPath['/user/detail'] = function UserDetail() {
        self = this;
        this.name = "/user/detail";

        this.type = PageType.VIEW;

        this.user = null;

        this.roles = [];
        this.workplaces = [];
        this.customers = [];
        this.assignedWorkplaces = [];

        this.newPassword = null;

        this.goToPage = null;

        var userTypes = [
            { id:app.model.UserType.EMPLOYEE, name:"Administrator", subtype:'ADMINISTRATOR' },
            { id:app.model.UserType.EMPLOYEE, name:"Mitarbeiter", subtype:'EMPLOYEE' },
            { id:app.model.UserType.CUSTOMER, name:"Kunde" }
        ];

        this.create = function ($parent, userId, goToPage) {
            this.$parent = $parent;
            this.goToPagePath = goToPage;
            this.goToPage = app.pagesByPath[goToPage];

            if (userId) {
                return userDao.getUserDetails(userId)
                    .done(function(userDetailsDto) {
                        self.user = userDetailsDto.user;
                        self.customers = [userDetailsDto.assigned_customer];
                        self.assignedWorkplaces = userDetailsDto.assigned_workplaces;

                        self.type = PageType.VIEW;
                        renderUser();
                    });
            } else {
                return $.when(workplaceDao.listAll(), roleDao.findRoles(), customerDao.findCustomersAndLocations())
                    .done(function(workplaces, roles, customers) {
                        self.user = new User();
                        self.roles = roles;
                        self.workplaces = workplaces;
                        self.customers = customers;
                        self.type = PageType.NEW;
                        renderUser();
                    });
            }
        };

        this.destroy = function() {
            // called after page has been destroyed
        };

        function renderUser() {
            var view = (self.type === PageType.VIEW);

            if (self.user.type === 'EMPLOYEE') {
                if (self.user.roles.length === 1 && self.user.roles[0].id === -1) {
                    self.user.subtype = 'ADMINISTRATOR';
                } else {
                    self.user.subtype = 'EMPLOYEE';
                }
            }

            self.$parent.empty().appendElement([
                createCommandBox()
            ]);

            self.$parent.appendElement(div({ "class":"" },
                function ($box) {
                    renderGeneralUserInfo($box, view);
                    renderUserDetails($box, view);
                }
            ));

            // render assigned workplaces
            renderAssignedWorkplaces(self.$parent, view ||
                !window.app.session.hasPermission(app.model.PermissionTypes.LOUNGEASSIGNMENT_MODIFY));

            // add custom validators (e.g. password confirm)
            addCustomValidators();

            // activate validators
            self.$parent.activateValidators();
        }

        function addCustomValidators() {
            function validatePasswordConfirm (e) {
                var userPassword = $('#user-password').val(),
                    userPasswordConfirm = $(e.target).val();

                return userPassword === userPasswordConfirm;
            }

            $.validation.addValidators({
                '.valid-password-confirm': [
                    validatePasswordConfirm,
                    'Das angegebene Passwort stimmt nicht mit der Bestätigung überein',
                    'change'
                ]
            });
        }

        function createCommandBox() {
            var savedUser = new User(self.user),
                savedAssignedWorkplaces = $.extend(true, [], self.assignedWorkplaces),
                savedNewPassword = self.newPassword;

            function cancelEditUser () {
                $.validation.reset();

                self.user = savedUser;
                self.assignedWorkplaces = savedAssignedWorkplaces;
                self.newPassword = savedNewPassword;
                self.type = PageType.VIEW;
                renderUser();
            }

            function saveButton() {
                return iconButton({id: 'save-user', "class": 'button-save',
                    click: saveUser
                }, 'Speichern');
            }

            function cancelButton() {
                return iconButton({id: 'cancel-edit-user', "class":'button-cancel',
                    click: function () {
                        switch (self.type) {
                        case PageType.NEW:
                            nav.goToPage(pages.user.list);
                            break;
                        case PageType.EDIT:
                            cancelEditUser();
                        }
                    }
                }, 'Abbrechen');
            }

            function editButton() {
                return window.app.session.hasPermission(app.model.PermissionTypes.CUSTOMERVIEW_MODIFY)
                    ? iconButton({id: 'edit-user', "class":'button-edit',
                        click: editUser
                    }, 'Bearbeiten')
                    : undefined;
            }

            function getHeader () {
                switch (self.type) {
                case PageType.NEW:
                    return 'Benutzer';
                case PageType.EDIT:
                    return 'Benutzer bearbeiten';
                case PageType.VIEW:
                    return sprintf('Benutzer: %s %s', self.user.first_name || "", self.user.last_name || "");
                }
                return '';
            }

            function getCommandButtons () {
                switch (self.type) {
                case PageType.VIEW:
                    return [
                        editButton()
                    ];

                case PageType.EDIT:
                    return [
                        saveButton(),
                        cancelButton()
                    ];

                case PageType.NEW:
                    return [
                        saveButton(),
                        cancelButton()
                    ];
                }

                return [];
            }

            function backAction() {
                if(self.goToPage) {
                    return self.goToPage;
                } else {
                    return pages.user.list;
                }
            }


            return commandBox({title:getHeader(),backAction:backAction()},getCommandButtons());
        }

        function saveUser() {
            if (!self.$parent.validate()) {
                return;
            }

            function saveDoneMethod(result) {
                var roles = self.user.roles;
                self.user = result;
                self.user.roles = roles;
                self.type = PageType.VIEW;

                renderUser();
                if(roles.length === 0){
                    app.showWarningMessage("Der Benutzer ist keiner Gruppe zugeordnet.", true, 10000, true);
                }
                app.showSuccessMessage('Gespeichert');

                if (self.onUpdate) {
                    self.onUpdate(result);
                }

                /* refresh session if current user has been edited */
                var sessionInfo = app.session.getSessionInfo();
                if(sessionInfo.user.id === self.user.id) {
                    app.session.refreshSessionInfo().done(function() {
                        /* update display name */
                        $("#user-name-in-nav-bar").text(self.user.first_name != null && self.user.last_name != null ?
                            self.user.first_name + " " + self.user.last_name :
                            self.user.username
                        );
                    });
                    app.showInfoMessage("Sie haben ihren eigenen Benutzer bearbeitet. Alle Änderungen werden erst nach einem neuerlichen Login wirksam.",false,10000);
                }
            }

            var method = $.proxy(userDao.insertUserDetails, userDao);

            if(self.user.id) {
                method = $.proxy(userDao.updateUserDetails, userDao);
            }

            // execute action
            return method(
                new User(self.user),
                self.newPassword,
                window.app.session.hasPermission(app.model.PermissionTypes.LOUNGEASSIGNMENT_MODIFY)
                    ? _.pluck(self.assignedWorkplaces, 'id') : null
            ).done(saveDoneMethod);
        }

        function editUser () {
            var fnEdit = function() {
                self.type = PageType.EDIT;
                renderUser();
            };

            if (self.workplaces.length > 0) {
                fnEdit();
            } else {
                if (self.user.type === app.model.UserType.EMPLOYEE) {
                    return $.when(workplaceDao.listAll(), roleDao.findRoles(self.user.type))
                        .done(function(workplaces, roles) {
                            self.workplaces = workplaces;
                            self.roles = roles;
                            fnEdit();
                        });
                } else {
                    return $.when(customerDao.findCustomersAndLocations(), roleDao.findRoles(self.user.type))
                        .done(function(customers, roles) {
                            self.customers = customers;
                            self.roles = roles;
                            fnEdit();
                        });
                }
            }
        }

        function renderGeneralUserInfo($parent, view) {
            $parent.appendElement(
                fieldset({id: 'user-edit'}, [
                    legend({}, "Allgemein"),
                    ul({'class': 'fieldset-content'}, [
                        form.formItemSelect({id: 'user-type-selection', label: 'Typ', required: true ,
                            source: userTypes, valueProp: 'name',
                            value: _.find(userTypes, function(userType) { return userType.id === self.user.type && userType.subtype == self.user.subtype; }),
                            sort: true,
                            selected: function(e, userType) {
                                if (self.user.type !== userType.id ||
                                    self.user.subtype !== userType.subtype) {

                                    self.user.roles = [];
                                    self.assignedWorkplaces = [];

                                    self.user.type = userType.id;

                                    if (userType.subtype === 'ADMINISTRATOR') {
                                        self.user.roles = _.where(self.roles, { id: -1 });
                                    }

                                    if (self.user.type === 'EMPLOYEE') {
                                        if (self.user.roles.length === 1 && self.user.roles[0].id === -1) {
                                            self.user.subtype = 'ADMINISTRATOR';
                                        } else {
                                            self.user.subtype = 'EMPLOYEE';
                                        }
                                    }

                                    $parent.find('#user-details-fieldset').detach().remove();
                                    renderUserDetails($parent, view);

                                    var $ul = $parent.find("ul");
                                    $ul.find(".customer-specific").remove();
                                    $ul.find(".employee-specific").remove();

                                    if (self.$assignedWorkplaces) {
                                        self.$assignedWorkplaces.detach().remove();
                                    }

                                    if (self.user.type === app.model.UserType.CUSTOMER) {
                                        $ul.appendElement(getCustomerSpecificFormItems());
                                    } else {
                                        renderAssignedWorkplaces($parent.parent(), view || !window.app.session.hasPermission(app.model.PermissionTypes.LOUNGEASSIGNMENT_MODIFY));
                                        $ul.appendElement(getEmployeeSpecificFormItems());
                                    }
                                }
                            }
                        }, self.type !== PageType.NEW),
                        form.formItemText({id: 'user-last-name', label: 'Nachname', required: true,
                            value: self.user.last_name,
                            change: function () {
                                self.user.last_name = $(this).val();
                            }
                        }, view),
                        form.formItemText({id: 'user-first-name', label: 'Vorname', required: true,
                            value: self.user.first_name,
                            change: function () {
                                self.user.first_name = $(this).val();
                            }
                        }, view),
                        form.formItemText({id: 'user-email', label: 'E-Mail', required: false,
                            value: self.user.email,
                            validation: "valid-email",
                            change: function () {
                                self.user.email = $(this).val();
                            }
                        }, view),
                        form.formItemText({id: 'user-first-username', label: 'Benutzername', required: true,
                            value: self.user.username,
                            validation: 'valid-no-spaces',
                            change: function () {
                                self.user.username = $(this).val();
                            }
                        }, view),
                        view ? undefined :
                            form.formItemText({id: 'user-password', label: 'Passwort',
                                required: self.type === PageType.NEW,
                                validation: 'valid-password valid-no-spaces valid-password-length', type: 'password'
                            }),
                        view ? undefined :
                            form.formItemText({id: 'user-password-confirm', label: 'Passwort bestätigen',
                                required: self.type === PageType.NEW,
                                validation: 'valid-password valid-password-confirm valid-no-spaces valid-password-length',
                                type: 'password',
                                change: function() {
                                    self.newPassword = $.sha256($(this).val());
                                }
                            }),
                        form.formItemCheckbox({id: 'user-active', label: 'Aktiv',
                            checked: self.user.active != null ? self.user.active : self.user.active=true,
                            change: function() {
                                self.user.active = $(this).is(':checked');
                            }
                        }, view),
                        form.formItemDate({id: 'logbook-filter-start-date', label: 'Geburtsdatum', required: false,
                            date: self.user.date_of_birth ? self.user.date_of_birth : null,
                            validation: 'valid-birthdate',
                            change: function(e) {
                                self.user.date_of_birth = new JustDate($(e.target).datetimepicker('getDate'));
                            }
                        }, view),
                        function($parent) {
                            if (self.user.type === app.model.UserType.CUSTOMER) {
                                $parent.appendElement(getCustomerSpecificFormItems(view));
                            } else if (self.user.type === app.model.UserType.EMPLOYEE) {
                                $parent.appendElement(getEmployeeSpecificFormItems(view));
                            }
                        }
                    ])
                ])
            );
        }

        function getEmployeeSpecificFormItems(view) {
            return [
                form.formItemText({id: 'employee-number', label: 'Mitarbeiternummer', required: true,
                    "class":"employee-specific",
                    value: self.user.number,
                    validation: 'valid-employee-number',
                    change: function () {
                        self.user.number = $(this).val();
                    }
                }, view),

                form.formItemDateTime({
                    id: 'employee-synchronized-at',
                    label: 'Synchronisiert am',
                    "class":"employee-specific",
                    date: self.user.synchronized_at
                }, true)
            ];
        }

        function getCustomerSpecificFormItems(view) {
            var customer = _.find(self.customers, function(customer) {
                    return customer.id === self.user.customer_id;
                }),
                locations = customer ? [].concat(customer.locations) : [];

            return [
                form.formItemSelect({id: 'user-type-customer-view-customer',
                    "class":"customer-specific",
                    label: 'Kunde', required: true,
                    source: self.customers, valueProp: 'name',
                    sort: true,
                    value: customer,
                    selected: function(e, selection) {
                        self.user.customer_id = selection ? selection.id : null;

                        // update locations
                        locations.length = 0;
                        if (selection) {
                            locations.splice.apply(locations, [0, 0].concat(selection.locations));
                        }
                    }
                }, view),
                form.formItemSelect({id: 'user-type-customer-view-location',
                    "class":"customer-specific",
                    label: 'Standort', required: false,
                    source: locations, valueProp: 'name', sort: true,
                    value: _.find(locations, function(location) {
                        return location.id === self.user.location_id;
                    }),
                    selected: function(e, selection) {
                        self.user.location_id = selection ? selection.id : null;
                    }
                }, view)
            ];
        }

        function renderUserDetails($parent, view) {
            if (!self.user.type || self.user.subtype === 'ADMINISTRATOR') {
                return;
            }

            $parent.appendElement(
                scrollTableSection({
                    parent: $parent,
                    id: "user-details-fieldset",
                    title: 'Gruppen',
                    elements: view ? self.user.roles : _.where(self.roles, { type: self.user.type }),
                    selected: view ? null : self.user.roles,
                    select: function(selected) {
                        self.user.roles = selected;
                    },
                    filter: true,
                    columns: [
                        {
                            sTitle: "Name",
                            sAttrName: "label"
                        },
                        {
                            sTitle: "Aktiv",
                            sAttrName: "active",
                            mRender: function(mData, type, obj) {
                                return obj.active ? "Ja" : "Nein";
                            }
                        }
                    ],
                    "class": "",
                    click: displayRole,
                    height: view ? "100%" : "248px"
                })
            );
        }

        function renderAssignedWorkplaces($parent, view) {
            if (self.user.type !== app.model.UserType.EMPLOYEE || self.user.subtype === 'ADMINISTRATOR') {
                return;
            }

            self.$assignedWorkplaces = $parent.appendElement(
                scrollTableSection({
                    parent: $parent,
                    id: "user-details-assigned-workplaces-fieldset",
                    title: 'Zugeordnete Arbeitsplätze',
                    elements: view ? self.assignedWorkplaces : self.workplaces,
                    selected: view ? null : self.assignedWorkplaces,
                    select: function(selected) {
                        self.assignedWorkplaces = selected;
                    },
                    filter: true,
                    columns: [
                        {
                            sTitle: "Kunde",
                            sAttrName: "customer_name"
                        },
                        {
                            sTitle: "Standort",
                            sAttrName: "location_name"
                        },
                        {
                            sTitle: "Objekt",
                            sAttrName: "object_name"
                        },
                        {
                            sTitle: "Arbeitsplatz",
                            sAttrName: "workplace_name"
                        }
                    ],
                    "class": "",
                    click: null
                })
            );
        }

        function displayRole(role){
            if(app.session.hasPermission(app.model.PermissionTypes.GROUPS_MODIFY)) {
                nav.goToPage(pages.role.detail, [role, undefined, self.goToPage ? self.goToPagePath :'/user/list']);
            }
        }

    };

})(window.rise, window.app);
