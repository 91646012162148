/**
 * Displays a list of all user tags
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav        = rise.Navigation;
    var form       = app.widgets.form;

    /** DAOs **/
    var UserTagDao = app.dao.userTagDao;

    /*** GLOBALS ***/
    var pages = app.pages;

    /*** CONSTANTS ***/
    var TAG_ID_COLUMN_INDEX = 1;

    /*** Export Pages ***/
    pages.user_tag.list = app.pagesByPath['/user-tag/list'] = UserTagList;

    /*** Set permission ***/
    UserTagList.permissions = [app.model.PermissionTypes.TOUR_MODIFY];

    function UserTagList() {

        var self = this;

        var filter = { filter_expression: '', programmed: null };

        app.widgets.ListPage.call(this, {
            pageName: '/user-tag/list',
            rpc: function () {
                return [ UserTagDao.findAll({filter: filter}) ];
            },
            prefix: "user_tag",
            labels: {
                pageHeader: "Mitarbeiterkarten",
                buttonNew: "Neue Mitarbeiterkarte",
                filterAll: "Alle Mitarbeiterkarten"
            },
            filterSearchFieldInactive: true,
            "export":
                window.app.session.hasPermission(app.model.PermissionTypes.TOUR_MODIFY)
                    ? {
                    method: "export_user_tags",
                    criteriaFn: getFilterCriteria
                }
                : undefined
        });

        this.prepareTable = function($parent, userTags) {
            var columns = getTableColumns();
            var settings = getTableSettings();

            this.renderTable(userTags, columns, settings);
            $("#placeholder-filters").prependElement(renderFilter($parent, filter));
        };

        this.savePageState = function() { };

        this.loadPageState = function($parent, userTags) {
            this.renderTableUpdate(userTags);
        };

        this.showDetailsPage = function(tag) {
            showDetailsPage(tag);
        };

        function getFilterCriteria() {
            return {
                filter: filter
            };
        }

        function renderFilter($parent, filter) {
            /* jshint indent: false */

            return div({ id: 'user-tag-filter-form', 'class': 'whiteboard' }, [
                legend({}),
                ul({ "class":"inline-list title-row-highlight" }, [
                    form.formItemText({id: 'user-tag-filter-search', label: 'Suche', required: false,
                        name: "filter_expression",
                        keyup: function () {
                            filter.filter_expression = $(this).val();
                        },
                        keypress: function (e) {
                            var keyCode = e.which;
                            if (keyCode === 13){
                                filterUserTagEntries(filter);
                                return false;
                            }
                        },
                        change: function () {
                            filterUserTagEntries(filter);
                        }
                    }),

                    createProgrammedStateSelector($parent, filter)
                ])
            ]);
        }

        function createProgrammedStateSelector($parent, datatable) {
            var stateTypes = [
                { id: 'ALL', name: 'Alle', value: undefined },
                { id: 'PROGRAMMED', name: 'Ja', value: true },
                { id: 'NOT_PROGRAMMED', name: 'Nein', value: false }
            ];

            return form.formItemSelect({id: 'filter-activated', label: 'Programmiert', required: false,
                source: stateTypes, valueProp: 'name',
                value: stateTypes[0],
                sort: false,
                selected: function (e, stateType) {
                    if (filter.programmed !== stateType.value) {
                        filter.programmed = stateType.value;
                        filterUserTagEntries(filter);
                    }
                }
            });
        }

        function filterUserTagEntries(filter) {
            UserTagDao.findAll({filter: filter})
                .done(function(tags) {
                    self.renderTableUpdate(tags);
                });
        }

    }

    function showDetailsPage(userTag) {
        nav.goToPage(pages.user_tag.detail, userTag ? [ userTag ] : undefined);
    }

    function getTableColumns() {
        return [
            {
                sTitle: "Erstellt am",
                sAttrName: "created_at",
                sWidth: "120px",
                mRender: function(mData, type, obj) {
                    if (type === "display") {
                        return obj.created_at.toFormatString(app.settings.dateTimeFormat);
                    } else {
                        return obj.created_at;
                    }
                }
            },
            {
                sTitle: "Mitarbeiternummer",
                sAttrName: "employee_number"
            },
            {
                sTitle: "Mitarbeitername",
                sAttrName: "employee_name"
            },
            {
                sTitle: "Programmiert",
                sAttrName: "nfc_serial" ,
                sWidth: "50px",
                sClass: "center",
                mRender: function (mData, type, obj) {
                    return obj.nfc_serial ? 'Ja' : 'Nein';
                }
            }
        ];
    }

    function getTableSettings() {
        return {
            aaSorting: [[ TAG_ID_COLUMN_INDEX, "asc" ]]
        };
    }

})(window.rise, window.app);
