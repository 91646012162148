/**
 * This is the dto for the notifications
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.dto.NotificationsDto = function NotificationsDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.open_task_cnt = obj.open_task_cnt != null ? obj.open_task_cnt : null;
        this.unread_business_documents_cnt = obj.unread_business_documents_cnt != null ? obj.unread_business_documents_cnt : null;
    };

}(window.app));
