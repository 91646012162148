/**
 * This file contains all model used by the task RPC endpoint
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.MonthlyTask = function MonthlyTask(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id             = obj.id != null ? parseInt(obj.id) : null;
        this.months         = obj.months != null ? obj.months : null;
        this.day            = obj.day != null ? parseInt(obj.day) : null;
        this.day_type       = app.model.MonthlyTask.DayType.valueOf(obj.day_type);

        this.time           = obj.time ?
            typeof obj.time === 'string'
                ? new window.JustTime.fromISO(obj.time)
                : obj.time
            : null;

        app.model.Task.apply(this, [obj]);
    };

    app.model.MonthlyTask.DayType = {
        DAY_SPECIFIC: 'DAY_SPECIFIC',
        DAY_LAST: 'DAY_LAST',
        DAY_FIRST: 'DAY_FIRST',

        valueOf: function(value) {
            return this.hasOwnProperty(value) ? value : null;
        },

        values: function() {
            return _.filter(_.values(this), function(el) { return !_.isFunction(el); });
        }
    };

}(window.app));
