/**
 * Displays a list of all business_documents visible for the current user.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav         = rise.Navigation;

    /** DAOs **/
    var userDao    = app.dao.userDao;

    /*** GLOBALS ***/
    var pages         = app.pages;

    /*** Export Page ***/
    pages.user.list = app.pagesByPath['/user/list'] = UserList;

    /*** Set permission ***/
    UserList.permissions = [app.model.PermissionTypes.EMPLOYEE_MODIFY];

    function UserList() {

        app.widgets.ListPage.call(this, {
            pageName: '/user/list',
            prefix: "user",
            rpc: function () {
                return [ userDao.listAll() ];
            },
            labels: {
                pageHeader: "Benutzer",
                buttonNew: window.app.session.hasPermission(app.model.PermissionTypes.CUSTOMERVIEW_MODIFY)?
                    "Neuer Benutzer":
                    undefined,
                filterAll: "Alle Benutzer"
            },
            active: {
                dao: userDao,
                name: "Der Benutzer",
                disabled: true,
            },
            filters: {
                active: {
                    labels: {
                        on: "Aktive",
                        off: "Deaktivierte"
                    },
                    values: {
                        on: true,
                        off: false
                    }
                }
            }
        });

        this.prepareTable = function($parent, dataUsers) {
            var columns = getTableColumns();

            this.renderTable(dataUsers, columns, {});
        };

        this.savePageState = function() { };

        this.loadPageState = function($parent, dataUsers) {
            this.renderTableUpdate(dataUsers);
        };

        this.showDetailsPage = function(user) {
            nav.goToPage(pages.user.detail, user == null ? undefined : [ user.id ]);
        };
    }

    function getTableColumns() {
        return [
            {
                sTitle: "Nachname",
                sAttrName: "last_name"
            },
            {
                sTitle: "Vorname",
                sAttrName: "first_name"
            },
            {
                sTitle: "Benutzername",
                sAttrName: "username"
            },
            {
                sTitle: "Typ",
                sAttrName: "type",
                mRender: function (mData, type, obj) {
                    if (obj.type === app.model.UserType.CUSTOMER) {
                        return 'Kunde';
                    } else {
                        return 'Mitarbeiter';
                    }
                }
            }
        ];
    }

})(window.rise, window.app);
