/**
 * Data Access Object for the tour_run endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;

    /*** Import ***/
    var TourRunDetailsDto = app.dto.TourRunDetailsDto;
    var TourRunDto = app.dto.TourRunDto;
    var TourPhotoDto = app.dto.TourPhotoDto;
    var UserListDto = app.dto.UserListDto;
    var TourCheckPointWithIncidentDto = app.dto.TourCheckPointWithIncidentDto;

    var Customer = app.model.Customer;

    /*** GLOBAL ***/
    var self;

    /*** Export Dao ***/
    app.dao.tourRunDao = new TourRunDao();

    /**
     * @constructor
     */
    function TourRunDao() {
        self = this;
        this.rpc = new Rpc('/rpc/tour_run');
    }

    // extend the parent Dao prototype
    $.extend(TourRunDao.prototype, Object.create(rise.Dao.prototype));

    TourRunDao.prototype.findOpenTourRuns = callGetListRpcFunction('find_open_tour_runs', TourRunDto);

    TourRunDao.prototype.getTourRunDetails = function (tourRunId) {
        var deferred = new $.Deferred();

        self.rpc.invoke('get_tourrun_details', {tourrun_id: tourRunId})
            .done(function(result) {
                deferred.resolve(new TourRunDetailsDto(result));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TourRunDao.prototype.getTourRunPhoto = function (tourRunPhotoId) {
        var deferred = new $.Deferred();

        self.rpc.invoke('get_tourrun_photo', {id: tourRunPhotoId})
            .done(function(result) {
                deferred.resolve(new TourPhotoDto(result));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TourRunDao.prototype.autoEndTourRun = function (tourRunId) {
        var deferred = new $.Deferred();

        self.rpc.invoke('auto_end_tourrun', {tourrun_id: tourRunId})
            .done(function(result) {
                deferred.resolve(new TourRunDto(result));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();

    };

    TourRunDao.prototype.addAdminComment = function (tourRunId, adminComment) {
        var deferred = new $.Deferred();

        self.rpc.invoke('add_admin_comment', {
            tourrun_id: tourRunId,
            admin_comment: adminComment
        }).done(function(result) {
            deferred.resolve(new TourRunDto(result));
        })
        .fail(function(error) {
            deferred.reject(error);
        });

        return deferred.promise();

    };

    TourRunDao.prototype.findFinishedTourRuns = function (filter_params) {
        var deferred = new $.Deferred(),
            params = {filter_params: filter_params};

        self.rpc.invoke('find_finished_tour_runs', params)
            .done(function(result) {
                var list = {
                    'totalCount': result.total_count,
                    'filteredCount': result.filtered_count
                };
                list.rows = _.map(result.rows, function(e) {return new TourRunDto(e);});
                deferred.resolve(list);
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();

    };

    TourRunDao.prototype.findFinishedTourRunDetails = function (filter_params, noCheckpoints, noMissingCheckpoints,
                                                                noTimeCInfos) {
        var deferred = new $.Deferred(),
            params = {
                filter_params: filter_params,
                include_checkpoints: !noCheckpoints,
                include_missing_checkpoints: !noMissingCheckpoints,
                include_timec_info: !noTimeCInfos
            };

        self.rpc.invoke('find_finished_tourrun_details', params)
            .done(function(result) {
                result.rows = _.map(result.rows || [], function(e) {return new TourRunDetailsDto(e);});
                deferred.resolve(result);
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();

    };

    TourRunDao.prototype.getUserFilterList = function () {
        var deferred = new $.Deferred();

        self.rpc.invoke('get_user_filter_list')
            .done(function(rows) {
                deferred.resolve(_.map(rows, function(e) {return new UserListDto(e);}));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();

    };

    TourRunDao.prototype.getCustomerList = function () {
        var deferred = new $.Deferred();

        self.rpc.invoke('get_customer_list')
            .done(function(rows) {
                deferred.resolve(_.map(rows, function(e) {return new Customer(e);}));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();

    };

    TourRunDao.prototype.getCheckpointsWithIncident = function (filter_params) {
        var deferred = new $.Deferred(),
            params = {
                filter_params: filter_params
            };

        self.rpc.invoke('get_checkpoints_with_incident', params)
            .done(function(result) {
                result = _.map(result || [], function(e) {
                    return new TourCheckPointWithIncidentDto(e);
                });
                deferred.resolve(result);
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();

    };

    function callGetListRpcFunction(method_name, ObjClass) {
        function rpc_call(params) {
            var deferred = new $.Deferred();

            if (!params) {
                params = {};
            }

            self.rpc.invoke(method_name, params)
                .done(function(result) {
                    deferred.resolve(_.map(result, function(e) {return new ObjClass(e);}));
                })
                .fail(function(error) {
                    deferred.reject(error);
                });

            return deferred.promise();
        }

        return rpc_call;
    }

    return TourRunDao;
})(window.rise, window.app);

export const tourRunDao = app.dao.tourRunDao;
