import keycode from "keycode";

/**
 * Customer detail page.
 *
 * @author Martin Reiterer (martin.reiterer@rise-world.com)
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function (rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav = rise.Navigation;
    var pages = app.pages;
    var form = app.widgets.form;
    var scrollTableSection = app.widgets.scrollTableSection;
    var tabsWidget = app.widgets.tabsWidget;
    var addIconButton = app.widgets.addIconButton;
    var editIconButton = app.widgets.editIconButton;
    var cancelIconButton = app.widgets.cancelIconButton;
    var saveIconButton = app.widgets.saveIconButton;
    var printIconButton = app.widgets.printIconButton;
    var commandBox = app.widgets.commandBox;

    /** DAOs **/
    var Customer = app.model.Customer;
    var customerDao = app.dao.customerDao;
    var eventTypeDao = app.dao.eventTypeDao;
    var businessDocumentDao = app.dao.businessDocumentDao;
    var currentTab = 0;

    var PageType = {
        VIEW: 1,
        EDIT: 2,
        NEW: 3
    };

    /*** Export page ***/
    pages.customer.detail = app.pagesByPath['/customer/detail'] = function CustomerDetail() {
        var self = this;

        this.onUpdate = null;
        this.onTreeNavigate = null;
        this.onAddChild = null;
        this.name = "/customer/detail";

        this.type = PageType.VIEW;
        this.customer = null;

        this.eventTypes = [];
        this.business_documents = [];

        this.assignedEventTypes = [];
        this.assignedBusinessDocuments = [];

        this.$parent = null;

        this.create = function ($parent, customerId, onUpdate, onTreeNavigate, onAddChild, getParentId, getNodeData, getCustomerData) {
            this.$parent = $parent;
            this.onUpdate = onUpdate;
            this.onTreeNavigate = onTreeNavigate;
            this.onAddChild = onAddChild;
            this.getParentId = getParentId;
            this.getNodeData = getNodeData;
            this.getCustomerData = getCustomerData;

            if (customerId) {
                return customerDao.getCustomerDetails(customerId)
                    .done(function (customerDetailsDto) {
                        self.customer = customerDetailsDto.customer;
                        self.assignedEventTypes = customerDetailsDto.assigned_event_types;
                        self.assignedBusinessDocuments = customerDetailsDto.assigned_business_documents;
                        self.assignedTours = customerDetailsDto.assigned_tours;

                        self.type = PageType.VIEW;
                        renderCustomer();
                    });
            } else {
                return $.when(eventTypeDao.listAll(),
                        businessDocumentDao.listAll({
                            include_read_count: false
                        }))
                    .done(function (eventTypes, business_documents) {
                        self.customer = new Customer();

                        self.eventTypes = eventTypes;
                        self.business_documents = business_documents;

                        self.type = PageType.NEW;
                        renderCustomer();
                    });
            }
        };

        this.destroy = function () {
            // called after page has been destroyed
        };

        function renderCustomer() {
            var view = (self.type === PageType.VIEW);

            self.$parent.empty().appendElement(createCommandBox());

            var tabs = [
                    {
                        'id': 'customer-details-tab',
                        'title': 'Allgemein',
                        'fnRender': $.proxy(renderOverviewSection, self, view)
                    },
                    {
                        'id': "assigned-event-types-tab",
                        'title': "Ereignistypen",
                        'fnRender': $.proxy(renderAssignedEventTypesList, self, view)
                    },
                    {
                        'id': "assigned-business_documents-tab",
                        'title': "Dokumente",
                        'fnRender': $.proxy(renderAssignedBusinessDocumentsList, self, view)
                    }
            ];

            if (view) {
                tabs.push({
                    'id':"assigned-tours-tab",
                    'title':"Rundgänge",
                    'fnRender': $.proxy(renderAssignedTours, self, view)
                });
            }

            self.$parent.appendElement(tabsWidget(
                { id: "customer-assignments", beforeActivate: beforeActivate, active: currentTab}, tabs
            ));

            self.$parent.activateValidators();
        }

        function beforeActivate(event, ui) {
            currentTab = ui.newTab.index();
        }

        function renderOverviewSection(view) {
            return div({}, [
                createCustomerDetails(self.customer, view),
                self.type === PageType.VIEW ? renderAssignedLocationsList() : undefined
            ]);
        }

        function createCommandBox() {

            if (!window.app.session.hasPermission(app.model.PermissionTypes.CUSTOMER_MODIFY)) {
                return commandBox({'class': 'inner', title: getHeader()});
            }

            var savedCustomer = new Customer(self.customer);

            function cancelEditCustomer() {
                if (self.type !== PageType.NEW) {
                    $.validation.reset();

                    self.customer = savedCustomer;
                    self.type = PageType.VIEW;
                    renderCustomer();
                } else {
                    self.onTreeNavigate("customer");
                }
            }

            function getSaveButton() {
                return saveIconButton({id: 'save-customer',
                    click: saveCustomer
                });
            }

            function getCancelButton() {
                return cancelIconButton({id: 'cancel-edit-customer',
                    click: cancelEditCustomer
                });
            }

            function getEditButton() {
                return editIconButton({id: 'edit-customer',
                    click: editCustomer
                });
            }

            function getPrintButton() {
                return printIconButton({id: 'print-customer',
                    click: function() {
                        app.printpreview.openCustomerPrintPreview({
                            customer: self.getCustomerData()
                        });
                    }
                }, 'Stammdaten drucken');
            }

            function getAddLocationButton() {
                return addIconButton({id: 'add-location',
                    click: addNewLocation
                }, 'Standort hinzufügen');
            }

            function getHeader() {
                switch (self.type) {
                case PageType.NEW:
                    return 'Neuer Kunde';
                case PageType.EDIT:
                    return 'Kunde bearbeiten';
                case PageType.VIEW:
                    return 'Kunde: ' + self.customer.name;
                }
                return '';
            }

            function getCommandButtons() {
                switch (self.type) {
                case PageType.VIEW:
                    return [
                        getPrintButton(),
                        getAddLocationButton(),
                        getEditButton()
                    ];

                case PageType.EDIT:
                    return [
                        getSaveButton(),
                        getCancelButton()
                    ];

                case PageType.NEW:
                    return [
                        getSaveButton(),
                        getCancelButton()
                    ];
                }

                return[];
            }

            return commandBox({'class': 'inner', title: getHeader()}, getCommandButtons());
        }

        function editCustomer() {
            var fnEdit = function () {
                self.type = PageType.EDIT;
                renderCustomer();
            };

            if (self.eventTypes.length > 0 && self.business_documents.length > 0) {
                fnEdit();
            } else {
                return $.when(eventTypeDao.findAll(),
                        businessDocumentDao.listAll({
                            include_read_count: false
                        }))
                    .done(function (eventTypes, business_documents) {
                        self.eventTypes = eventTypes;
                        self.business_documents = business_documents;
                        fnEdit();
                    });
            }
        }

        function addNewLocation() {
            // create a new Location that is assigned to the current customer
            var location = new app.model.Location({ customer_id: self.customer.id });

            if (self.onAddChild) {
                self.onAddChild(location);
            }
        }

        function saveCustomer() {
            if (!self.$parent.validate()) {
                return;
            }

            function saveDoneMethod(result) {
                self.customer = result;
                self.type = PageType.VIEW;

                renderCustomer();
                app.showSuccessMessage('Gespeichert');

                if (self.onUpdate) {
                    self.onUpdate(result);
                }
            }

            self.customer.email = $("#customer-email").tokenlist("value") || null;

            var method = $.proxy(customerDao.insertCustomerAndAssignments, customerDao);

            if (self.customer.id) {
                method = $.proxy(customerDao.updateCustomerAndAssignments, customerDao);
            }

            // execute action
            return method(self.customer,
                _.pluck(self.assignedEventTypes, 'id'),
                _.pluck(self.assignedBusinessDocuments, 'id')).done(saveDoneMethod);
        }

        function renderAssignedLocationsList() {
            return scrollTableSection({
                id: "assigned-locations",
                title: 'Standorte',
                elements: self.customer.locations,
                columns: [
                    { sTitle: "Bezeichnung", sAttrName: "name" },
                    { sTitle: "Adresse", sAttrName: "address" },
                    { sTitle: "PLZ", sAttrName: "zip" },
                    { sTitle: "Stadt", sAttrName: "city" },
                    { sTitle: "Land", sAttrName: "country" },
                    { sTitle: "Aktiv", sAttrName: "active", mRender: function (mData, type, obj) {
                        return obj.active ? "Ja" : "Nein";
                    }}
                ],
                "class": "",
                click: displayLocation
            });
        }

        function renderAssignedEventTypesList(view) {
            return scrollTableSection({
                filter: true,
                id: "assigned-event-types",
                elements: view ? self.assignedEventTypes : self.eventTypes,
                selected: view ? null : self.assignedEventTypes,
                select: function (selected) {
                    self.assignedEventTypes = selected;
                },
                columns: [
                    { sTitle: "ID", sAttrName: "id" },
                    { sTitle: "Bezeichnung", sAttrName: "label" }
                ],
                click: displayEventType,
                height: "100%"
            });
        }

        function renderAssignedBusinessDocumentsList(view) {
            return scrollTableSection({
                filter: true,
                id: "assigned-business_documents",
                elements: view ? self.assignedBusinessDocuments : self.business_documents,
                selected: view ? null : self.assignedBusinessDocuments,
                select: function (selected) {
                    self.assignedBusinessDocuments = selected;
                },
                columns: [
                    { sTitle: "ID", sAttrName: "id" },
                    { sTitle: "Bezeichnung", sAttrName: "name" },
                    { sTitle: "Kategorie", sAttrName: "category_name" }
                ],
                click: displayBusinessDocument,
                height: "100%"
            });
        }

        function renderAssignedTours() {
            return scrollTableSection({
                filter: true,
                id: "assigned-tours",
                elements: self.assignedTours,
                columns: [
                    {
                        sTitle: "Erstellt am",
                        sAttrName: "created_at",
                        mRender: function(mData, type, obj) {
                            return obj.created_at.toFormatString(app.settings.dateTimeFormat);
                        }
                    },
                    {
                        sTitle: "Name",
                        sAttrName: "name"
                    },
                    {
                        sTitle: "Beschreibung",
                        sAttrName: "description"
                    }
                ],
                click: displayTour,
                height: "100%"
            });
        }

        function displayLocation(location) {
            if (self.onTreeNavigate) {
                self.onTreeNavigate("location", location);
            }
        }

    };

    function displayBusinessDocument(business_document) {
        nav.goToPage(pages.business_document.detail, [business_document.id, true, '/customer/manager']);
    }

    function displayEventType(eventType) {
        nav.goToPage(pages.event_type.detail, [eventType, '/customer/manager']);
    }

    function displayTour(tour) {
        if(window.app.session.hasPermission(app.model.PermissionTypes.TOUR_MODIFY)) {
            nav.goToPage(pages.tour.detail, [tour, '/customer/manager']);
        }
    }

    function createCustomerDetails(customer, view) {
        return fieldset({id: 'customer-details'}, [
            ul({'class': 'fieldset-content'}, [
                form.formItemText({
                    id: "customer-name",
                    required: true,
                    label: "Name",
                    value: customer.name,
                    change: function (e) {
                        customer.name = $(e.target).val();
                    }
                }, view),

                form.formItemText({
                    id: "customer-email",
                    required: false,
                    label: "E-Mail",
                    value: view ? customer.email && customer.email.split(';').join('; ') : customer.email,
                    create: function() {
                        $(this).tokenlist({
                            tokenDelimiter: [keycode('space'), keycode(',')],
                            split: /\s*;\s*/,
                            join: ';',
                            acceptCustomInput: true,
                            validate: function(labelValue) {
                                var val = labelValue.value;
                                if(val !== undefined) {
                                    var pattern = new RegExp(/^([a-zA-Z0-9._\\+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})?$/);
                                    return pattern.test(val);
                                }

                                return true;
                            }
                        });
                    }
                }, view),

                form.formItemCheckbox({
                    id: "customer-active",
                    label: "Aktiv",
                    checked: !!customer.active ? customer.active : customer.active=true,
                    change: function (e) {
                        customer.active = $(e.target).prop("checked");
                    }
                }, view)
            ])
        ]);
    }

})(window.rise, window.app);
