/**
 * Data Access Object for the user endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.user");

    /*** Export Dao ***/
    app.dao.userDao = new UserDao();

    /**
     * @constructor
     */
    function UserDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.User, new Rpc('/rpc/user'));
    }

    // extend the parent Dao prototype
    $.extend(UserDao.prototype, Object.create(rise.Dao.prototype));

    UserDao.prototype.getNotifications = function() {
        return this.rpc.invoke("get_notifications")
    };

    UserDao.prototype.listAll = function(params) {
        log.info('List all Users');
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke('list_all', params)
            .done(function(data) {
                if (data.length === 0) {
                    log.info('No User data sets found');
                    deferred.resolve([]);
                } else {
                    log.info('Received %s User(s)', data.length);
                    deferred.resolve(data.map(function(d) {
                        return new app.dto.UserListDto(d);
                    }));
                }
            })
            .fail(function(error) {
                log.error('Fetching all Users failed. %s', error.message);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Get a particular user
     */
    UserDao.prototype.getUserDetails = function(user_id) {
        var deferred = new $.Deferred();

        this.rpc.invoke('get_user_details', { 'id':user_id })
            .done(function(json) {
                deferred.resolve( new app.dto.UserDetailsDto(json) );
            })
            .fail(function(error) {
                log.error('Unable to retrieve user: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Update user and assignments
     */
    UserDao.prototype.updateUserDetails = function(user, newPassword, assignedWorkplaces) {
        var deferred = new $.Deferred();

        this.rpc.invoke('update_user_details',
            { user:user, workplace_ids:assignedWorkplaces, password:newPassword })
            .done(function(json) {
                var result = new app.model.User(json);
                deferred.resolve(result);
            })
            .fail(function(error) {
                log.error('updating user failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Insert user and assignments
     */
    UserDao.prototype.insertUserDetails = function(user, password, assignedWorkplaces) {
        var deferred = new $.Deferred();

        this.rpc.invoke('insert_user_details',
            { user:user, workplace_ids:assignedWorkplaces, password:password })
            .done(function(json) {
                var result = new app.model.User(json);
                deferred.resolve(result);
            })
            .fail(function(error) {
                log.error('updating user failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Changes password
     */
    UserDao.prototype.changePassword = function(oldPassword, newPassword) {
        var deferred = new $.Deferred();

        this.rpc.invoke('change_password', { 'old':oldPassword, 'new':newPassword })
            .done(function() {
                deferred.resolve();
            })
            .fail(function(error) {
                log.error('Request for changing password failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

})(window.rise, window.app);
