/**
 * Data Access Object for the tour-area endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.tourarea");

    /*** Import ***/
    var TourArea = app.model.TourArea;
    var TourAreaDetailsDto = app.dto.TourAreaDetailsDto;

    /*** Export Dao ***/
    app.dao.tourAreaDao = new TourAreaDao();

    /**
     * @constructor
     */
    function TourAreaDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.TourArea, new Rpc('/rpc/tour_area'));
    }

    // extend the parent Dao prototype
    $.extend(TourAreaDao.prototype, Object.create(rise.Dao.prototype));

    TourAreaDao.prototype.deleteTourArea = function(tourAreaId) {
        var deferred = new $.Deferred();

        this.rpc.invoke('delete',
            { tour_area_id: tourAreaId })
            .done(function(json) {
                var result = new TourArea(json);
                deferred.resolve(result);
            })
            .fail(function(error) {
                log.error('Deleting tour area failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TourAreaDao.prototype.getTourAreaDetails = function(tour_area_id, include_referenced_tours) {
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke('get_tour_area_details', {
            tour_area_id: tour_area_id,
            include_referenced_tours: include_referenced_tours
        })
            .done(function(result) {
                deferred.resolve(new TourAreaDetailsDto(result));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TourAreaDao.prototype.insert = function(tour_area, assignments) {
        var deferred = new $.Deferred();
        var self = this;
        var params = {
            tour_area: tour_area,
            assignments: assignments
        };

        self.rpc.invoke('insert', params)
            .done(function(result) {
                deferred.resolve(new TourAreaDetailsDto(result));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TourAreaDao.prototype.update = function(tour_area, assignments) {
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke('update', {
            tour_area: tour_area,
            assignments: assignments
        })
            .done(function(result) {
                deferred.resolve(new TourAreaDetailsDto(result));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    return TourAreaDao;

})(window.rise, window.app);

export const tourAreaDao = app.dao.tourAreaDao;
