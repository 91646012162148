/**
 * This is the dto for the event-type
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    app.dto.EventTypeWithAssignmentInfoDto = function EventTypeWithAssignmentInfoDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id ? parseInt(obj.id, 10) : null;
        this.label = obj.label ? obj.label : null;
        this.active = obj.active === true ? true : false;
        this.finalized = obj.finalized === true ? true : false;
        this.send_emails = obj.send_emails !== null ? obj.send_emails : null;

        this.assigned_to_customer = obj.assigned_to_customer ? obj.assigned_to_customer === true : null;
        this.assigned_to_location = obj.assigned_to_location ? obj.assigned_to_location === true : null;
        this.assigned_to_object = obj.assigned_to_object ? obj.assigned_to_object === true : null;
        this.assigned_to_workplace = obj.assigned_to_workplace ? obj.assigned_to_workplace === true : null;
    };

}(window.app));
