import _ from 'underscore';

import { Dao } from 'lib/rise/dao';
import { Rpc } from 'lib/rise/rpc';

import { ReportSchedule } from 'app/report-schedule/ReportSchedule';
import { ReportScheduleTaskDto } from 'app/report-schedule/dto/ReportScheduleTaskDto';

class ReportScheduleTaskDao extends Dao<ReportSchedule, ReportSchedule> {
    constructor() {
        super(ReportSchedule, new Rpc('/rpc/report_schedule_task'), ReportSchedule);
    }

    findByReportScheduleId(reportScheduleId: number): Promise<Array<ReportScheduleTaskDto>> {
        return new Promise((resolve, reject) => {
            const params = {
                report_schedule_id: reportScheduleId,
            };

            this.rpc.invoke('find_by_report_schedule_id', params)
                .done((result) => {
                    resolve(_.map(result, (e) => new ReportScheduleTaskDto(e)));
                })
                .fail((error) => {
                    reject(error);
                });
        });
    }
}

export const reportScheduleTaskDao = new ReportScheduleTaskDao();
