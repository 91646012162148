/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.BusinessDocument = function BusinessDocument(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id =               obj.id != null ? parseInt(obj.id) : null;

        this.name =             obj.name != null ? obj.name : null;
        this.typ =              obj.typ != null ? obj.typ : null;
        this.active =           (obj.active === true || obj.active === false) ? obj.active : null;
        this.business_document_category_id = obj.business_document_category_id ? parseInt(obj.business_document_category_id, 10) : null;
        this.category_name = obj.category_name;
        this.customer_only = obj.customer_only || false;
        /**
         * set on server side
         * @type {JustDate}
         */
            this.created_at =       obj.created_at ?
                typeof obj.created_at === 'string' ?
                    Date.fromISO(obj.created_at)
                    : obj.created_at
                : null;
        /**
         * set on server side
         * @type {Number}
         */
        this.created_by = obj.created_by != null ? parseInt(obj.created_by) : null;
        this.description = obj.description != null ? obj.description : null;

        this.documents   = _.map(obj.documents || [], function(document) {
            return new app.model.Document(document);
        });

        if (obj.hasOwnProperty('read_confirmed_at')) {
            this.read_confirmed_at = obj.read_confirmed_at ?
                typeof obj.read_confirmed_at === 'string' ?
                    Date.fromISO(obj.read_confirmed_at)
                    : obj.read_confirmed_at
                : null;
        }
    };

}(window.app));
