/**
 * Data Access Object for the session endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.session");

    /*** Export Dao ***/
    app.dao.sessionDao = new SessionDao();

    /**
     * @constructor
     */
    function SessionDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.Session, new Rpc('/rpc/session'));
    }

    // extend the parent Dao prototype
    $.extend(SessionDao.prototype, Object.create(rise.Dao.prototype));

    SessionDao.prototype.listAll = function(params) {
        log.info('List all Sessions');
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke('list_all', params)
            .done(function(data) {
                if (data.length === 0) {
                    log.info('No session data sets found');
                    deferred.resolve([]);
                } else {
                    log.info('Received %s session record(s)', data.length);
                    deferred.resolve(data.map(function(d) {
                        return new app.dto.SessionDto(d);
                    }));
                }
            })
            .fail(function(error) {
                log.error('Fetching all Sessions failed. %s', error.message);
                deferred.reject(error);
            });

        return deferred.promise();
    };

})(window.rise, window.app);
