/**
 * Data Access Object for the logbook-entry endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.task-logbook-entry");

    /*** Export Dao ***/
    app.dao.taskLogbookEntryDao = new TaskLogbookEntryDao();

    /**
     * @constructor
     */
    function TaskLogbookEntryDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.TaskLogbookEntry, new Rpc('/rpc/task-logbook-entry'));
    }

    // extend the parent Dao prototype
    $.extend(TaskLogbookEntryDao.prototype, Object.create(rise.Dao.prototype));

    /**
     * Find all task logbook entries with the given parameters
     */
    TaskLogbookEntryDao.prototype.getTaskLogbookEntryDetails = function(id) {
        var deferred = new $.Deferred();

        this.rpc.invoke('get_task_logbook_entry_details', {id:id})
            .done(function(details) {
                deferred.resolve( new app.dto.TaskLogbookEntryDetailsDto(details) );
            })
            .fail(function(error) {
                log.error('Request for finding task logbook entry failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Find all task logbook entries with the given parameters
     */
    TaskLogbookEntryDao.prototype.findTaskLogbookEntries = function(params) {
        log.info('Finding task logbook entries');
        var deferred = new $.Deferred();

        this.rpc.invoke('find_task_logbook_entries', { filter_params: params })
            .done(function(task_logbook_entries) {
                deferred.resolve(new app.dto.TaskLogbookEntryListDto(task_logbook_entries));
            })
            .fail(function(error) {
                log.error('Request for finding taks logbook entries failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Finish task
     */
    TaskLogbookEntryDao.prototype.finishTask = function(id, comment) {
        log.info('Finish task');
        var deferred = new $.Deferred();

        this.rpc.invoke('finish_task', {id: id, comment: comment})
            .done(function(task_logbook_entry) {
                log.info('task with id ' + id + " has been finished");
                deferred.resolve(new app.model.TaskLogbookEntry(task_logbook_entry));
            })
            .fail(function(error) {
                log.error('Finishing task failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TaskLogbookEntryDao.prototype.getCustomerList = function () {
        var deferred = new $.Deferred();

        this.rpc.invoke('get_customer_list')
            .done(function(rows) {
                deferred.resolve(_.map(rows, function(e) {return new app.model.Customer(e);}));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

})(window.rise, window.app);
