/* eslint-disable camelcase */
import { ReportInterval, ReportType } from 'app/report-schedule/ReportScheduleConstants';
import { JustTime } from 'lib/rise/justtime';

export class ReportScheduleListEntryDto {
    id: number | null;

    type: ReportType;

    interval: ReportInterval;

    customer_name: string | null;

    location_name: string | null;

    object_name: string | null;

    tour_area_name: string | null;

    day_of_week: number | null;

    time: string | null;

    recipients: Array<string>;

    created_at: Date;

    modified_at: Date;

    active: boolean;

    constructor(jsonObject: string | any) {
        let obj = (typeof jsonObject === 'string') ? JSON.parse(jsonObject) : jsonObject;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id ? parseInt(obj.id, 10) : null;
        this.type = obj.type as ReportType;
        this.interval = obj.interval as ReportInterval;
        this.customer_name = obj.customer_name ?? null;
        this.location_name = obj.location_name ?? null;
        this.object_name = obj.object_name ?? null;
        this.tour_area_name = obj.tour_area_name ?? null;
        this.day_of_week = (obj.day_of_week !== undefined && obj.day_of_week !== null)
            ? parseInt(obj.day_of_week, 10) : null;
        this.recipients = obj.recipients;
        this.active = !!obj.active;

        if (obj.time) {
            this.time = typeof obj.time === 'string'
                ? JustTime.fromISO(obj.time)
                : obj.time;
        }

        if (obj.created_at) {
            this.created_at = typeof obj.created_at === 'string'
                ? new Date(obj.created_at)
                : obj.created_at;
        }

        if (obj.modified_at) {
            this.modified_at = typeof obj.modified_at === 'string'
                ? new Date(obj.modified_at)
                : obj.modified_at;
        }
    }
}
