export enum PageType {
    VIEW,
    NEW,
    UPDATE,
}

export enum ReportInterval {
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
}

export const ReportIntervalLabels: { [k in ReportInterval]: string } = {
    DAILY: 'Täglich',
    WEEKLY: 'Wöchentlich',
    MONTHLY: 'Monatlich',
};

export enum ReportType {
    EVENT_REPORT = 'EVENT_REPORT',
    EXCEPTION_REPORT = 'EXCEPTION_REPORT',
    SHIFT_REPORT = 'SHIFT_REPORT',
    OBJECT_REPORT = 'OBJECT_REPORT',
}

export const ReportTypeLabels: { [k in ReportType]: string } = {
    EVENT_REPORT: 'Ereignisreport',
    EXCEPTION_REPORT: 'Ausnahmereport',
    SHIFT_REPORT: 'Dienstreport',
    OBJECT_REPORT: 'Objektreport',
};

export const WEEKDAYS = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];

export enum ReportScheduleTaskStatus {
    PLANNED = 'PLANNED',
    STARTED = 'STARTED',
    FINISHED = 'FINISHED',
    FAILED = 'FAILED',
}

export const ReportScheduleTaskStatusLabels: { [k in ReportScheduleTaskStatus]: string } = {
    PLANNED: 'Geplant',
    STARTED: 'Wird ausgeführt',
    FINISHED: 'Abgeschlossen',
    FAILED: 'Fehlgeschlagen',
};
