import $ from 'jquery';
import _ from 'underscore';
import React from 'react';

import { JustTime } from 'lib/rise/justtime';

import { ReportSchedule } from 'app/report-schedule/ReportSchedule';
import { PageType, ReportInterval, ReportIntervalLabels, WEEKDAYS } from 'app/report-schedule/ReportScheduleConstants';
import { formItemSelect, formItemTime } from 'app/legacy/widgets/form';
import { Box } from 'app/BoxWorld';

type Props = {
    pageType: PageType;
    interval: ReportSchedule['interval'];
    time: ReportSchedule['time'];
    dayOfWeek: ReportSchedule['day_of_week'];
    onChange: (ReportSchedule) => void;
};

const reportScheduleIntervals = _.map(ReportIntervalLabels, (v, k) => ({
    id: k,
    name: v,
    value: k,
}));

const weekdayValues = _.map(WEEKDAYS, (v, idx) => ({
    id: idx,
    name: v,
    value: idx,
}));

export function IntervalSettings(props: Props) {
    const { pageType, interval, time, dayOfWeek, onChange } = props;

    const readonly = pageType === PageType.VIEW;
    const reportInterval = interval;

    const intervalSelect = formItemSelect({
        id: 'interval-type',
        label: 'Intervall',
        required: true,
        source: reportScheduleIntervals,
        valueProp: 'name',
        value: reportScheduleIntervals.filter((rt) => rt.value === reportInterval)[0],
        sort: false,
        selected: (e, elem) => {
            if (elem && elem.value && reportInterval !== elem.value) {
                onChange({
                    interval: elem.value,
                    time,
                    day_of_week: null,
                });
            }
        },
    }, readonly);

    const onTimeChange = (e) => {
        const pickedTime = new JustTime($(e.target).datepicker('getDate'));
        if (pickedTime && time?.toISOString() !== pickedTime.toISOString()) {
            onChange({
                time: pickedTime,
            });
        }
    };

    const dailyIntervalSettings = formItemTime({
        id: 'daily-time',
        label: 'Uhrzeit',
        required: true,
        time,
        change: onTimeChange,
    }, readonly);

    const weeklyIntervalSettings = [
        formItemSelect({
            id: 'day-of-week',
            label: 'Wochentag',
            required: true,
            source: weekdayValues,
            valueProp: 'name',
            value: weekdayValues.filter((rt) => rt.value === dayOfWeek)[0],
            sort: false,
            selected: (e, val) => {
                if (val && Number.isInteger(val.value) && dayOfWeek !== val.value) {
                    onChange({
                        day_of_week: val.value,
                    });
                }
            },
        }, readonly),
        formItemTime({
            id: 'daily-time',
            label: 'Uhrzeit',
            required: true,
            time,
            change: onTimeChange,
        }, readonly),
    ];

    const monthlyIntervalSettings = formItemTime({
        id: 'daily-time',
        label: 'Uhrzeit am Monatsersten',
        required: true,
        time,
        change: onTimeChange,
    }, readonly);

    return (
        <div className="column">
            <fieldset id="time-interval-settings-edit">
                <legend>Zeitliche Einstellungen</legend>
                <ul className="fieldset-content">
                    <Box>
                        {intervalSelect}
                        {reportInterval === ReportInterval.DAILY ? dailyIntervalSettings : null}
                        {reportInterval === ReportInterval.WEEKLY ? weeklyIntervalSettings : null}
                        {reportInterval === ReportInterval.MONTHLY ? monthlyIntervalSettings : null}
                    </Box>
                </ul>
            </fieldset>
        </div>
    );
}
