import React from 'react';

import { CommandBox as LegacyCommandBox } from 'app/legacy/widgets/commandbox';
import { printIconButton } from 'app/legacy/widgets/button';
import { printTourEventReport, printTourReport } from 'app/legacy/widgets/print-report';
import { TourReportState } from 'app/tour-report/model/TourReportState';
import { hasPermission, PermissionType } from 'app/PermissionType';
import { Box } from 'app/BoxWorld';

type Props = TourReportState['weeksChartFilter'];

export function CommandBox(props: Props) {
    const { customers, dateFrom, dateTo, customerId, locationId, objectId } = props;

    const filter = {
        started: dateFrom,
        stopped: dateTo,
        customer_id: customerId,
        location_id: locationId,
        object_id: objectId,
    };

    const eventButton = printIconButton({
        click: () => printTourEventReport('Historie-Ereignisreport', customers, [], [], filter),
    }, 'Ereignisreport');

    const incidentButton = printIconButton({
        click: () => printTourReport('Historie-Ausnahmereport', customers, [], [], false, true, filter),
    }, 'Ausnahmereport');

    const shiftButton = printIconButton({
        click: () => printTourReport('Historie-Dienstreport', customers, [], [], true, true, filter),
    }, 'Dienstreport');

    const objectButton = printIconButton({
        click: () => printTourReport('Historie-Objektreport', customers, [], [], true, false, filter),
    }, 'Objektreport');

    const elements = LegacyCommandBox(
        { title: 'Report' },
        hasPermission(PermissionType.TOUR_MODIFY)
            ? [
                hasPermission(PermissionType.TOUR_REPORT_EVENT_VIEW) ? eventButton : undefined,
                hasPermission(PermissionType.TOUR_REPORT_INCIDENT_VIEW) ? incidentButton : undefined,
                hasPermission(PermissionType.TOUR_REPORT_SHIFT_VIEW) ? shiftButton : undefined,
                hasPermission(PermissionType.TOUR_REPORT_OBJECT_VIEW) ? objectButton : undefined,
            ]
            : [],
    );

    return <Box>{elements}</Box>;
}
