/**
 * Contains common validators which are not specific to a page.
 *
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    if ($.validation) {

        $.validation.addValidators({
            ".valid-no-xss-char": [
                /^[^<>]*$/,
                "Dieses Feld enthält ungülte Zeichen: < or >",
                "change"
            ],
            ".valid-datetime": [
                function(e, val) {
                    var pickerDate = $(e.target).datetimepicker('getDate');
                    var inputDate = Date.parseFormat(val, app.settings.dateTimeFormat);

                    return $.trim(val) === '' ||
                        (inputDate !== null && inputDate !== undefined && !isNaN(inputDate) &&
                         inputDate.valueOf() === pickerDate?.valueOf() && inputDate.getFullYear() >= 1900);
                },
                sprintf("Dieses Feld enthält eine ungültige Datum- und Zeitangabe (%s)", app.settings.dateTimeFormatHuman),
                "change"
            ],
            ".valid-date": [
                function(e, val) {
                    var pickerDate = new JustDate($(e.target).datepicker('getDate'));
                    var inputDate = new JustDate(Date.parseFormat(val, app.settings.dateFormat));

                    return $.trim(val) === '' ||
                        (inputDate != null &&
                         inputDate.valueOf() === pickerDate.valueOf() &&
                         inputDate.year >= 1900);
                },
                sprintf("Dieses Feld enthält eine ungültige Datumsangabe (%s)", app.settings.dateFormatHuman),
                "change"
            ],
            ".valid-time": [
                (e, val) => val.match(/(^([0-1]?\d|2[0-3]):[0-5]\d$)|(^$)/),
                "Dieses Feld enthält eine ungültige Zeitangabe (HH:MM)",
                "change"
            ],
            ".valid-duration": [
                function(e, val) {
                    var re = /^((\d+):)?(\d+)$/;
                    var matches = val.match(re);

                    return $.trim(val) === '' || (matches && (!matches[2] || matches[3].match(/[0-5]{1}\d{1}/)));
                },
                "Dieses Feld enthält eine ungültige Dauerangabe (HH:MM)",
                "change"
            ],
            ".valid-numeric": [
                /^\d*$/,
                "Dieses Feld enthält eine ungültige Zahl",
                "change"
            ],
            ".valid-alpha": [
                /^[a-zA-Z]*$/,
                "Dieses Feld enthält ungültige alphabetische Werte",
                "change"
            ],
            ".valid-alphanumeric": [
                /^[\w\s]*$/,          // includes whitespace
                "Dieses Feld enthält ungültige alphanumerische Werte",
                "change"
            ],
            ".valid-employee-number": [
                function(e, val) {
                    return val.match(/^[\w]*$/) && val.length <= 12;
                },
                "Dieses Feld enthält ungültige Mitarbeiternummer (maximal 12 Zeichen)",
                "change"
            ],
            ".valid-email": [
                /^([a-zA-Z0-9._\\+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})?$/,
                "Dieses Feld enthält eine ungültige E-Mail Adresse",
                "change"
            ],
            ".valid-notempty": [
                /\S/,   // there must be at least one single non-whitespace character
                "Dieses Feld ist ein Pflichtfeld",
                "change"
            ],
            ".valid-phonenumber": [
                function(e, val) {
                    return val ? $.trim(val).match(/^\+?(?:[0-9] *){6,20}[0-9]$/) : true;
                },
                "Dieses Feld enthält eine ungültige Telefonnummer",
                "change"
            ],
            ".valid-float": [
                /^(\-?\d+\.?)*\d*$/,
                "Dieses Feld enthält eine ungültige Kommazahl",
                "change"
            ],
            ".valid-neg-float": [
                /^(\-?\d+\.?)*\d*$/,
                "Dieses Feld enthält eine ungültige Kommazahl",
                "change"
            ],
            ".valid-selected": [
                function(e) {
                    const $input = $(e.target)
                    if ($input.data('uiCombobox')) {
                        const selected = $input.combobox("getSelected");
                        return selected !== null;
                    }
                    return true;
                },
                "Dieses Feld ist ein Pflichtfeld",
                "comboboxselected"
            ],
            '.valid-customer-selection': [
                function(e, val) {
                    return val;
                },
                'Es wurde kein Kunde ausgewählt',
                ''
            ],
            '.valid-location-selection': [
                function(e, val) {
                    return val;
                },
                'Es wurde kein Standort ausgewählt',
                ''
            ],
            '.valid-object-selection': [
                function(e, val) {
                    return val;
                },
                'Es wurde kein Objekt ausgewählt',
                ''
            ],
            '.valid-workplace-selection': [
                function(e, val) {
                    return val;
                },
                'Es wurde kein Arbeitsplatz ausgewählt',
                ''
            ],
            '.valid-selection-remove': [
                function(e, val) {
                    var errorLabel = $(e.target).closest('span').next('.error');
                    if(errorLabel.length && val) {
                        $.validation.reset();
                        errorLabel.remove();
                        $('div#content-inner').children(":first").validate();
                    }

                    return true;
                },
                '',
                'change'
            ],
            '.valid-no-spaces': [
                function(e, val) {
                    return val.indexOf(' ') === -1;
                },
                'Leerzeichen sind nicht erlaubt',
                'change'
            ],
            '.valid-password-length': [
                function(e, val) {
                    return !val || val.length >= 8;
                },
                'Passwort muss mindestens 8 Zeichen lang sein',
                'change'
            ],
            ".valid-birthdate": [
                function(e, val) {
                    var date = new JustDate($(e.target).datepicker('getDate'));
                    var today = new Date();

                    var before110y = new JustDate(today.getFullYear() - 110, today.getMonth(), today.getDay());
                    var before14y = new JustDate(today.getFullYear() - 14, today.getMonth(), today.getDay());
                    return date.valueOf() > before110y.valueOf() && date.valueOf() <= before14y.valueOf();
                },
                "Das Geburtsdatum liegt in der Zukunft oder zu weit in der Vergangenheit",
                "change"
            ],
            ".valid-signature-required": [
                function(e, val) {
                    return !$(e.target).hasClass("no-signature");
                },
                "Dieses Feld ist ein Pflichtfeld",
                "change"
            ],
            ".valid-radio-selected": [
                function(e, val) {
                    return $(e.target).find("input[type='radio']:checked").length > 0;
                },
                "Dieses Feld ist ein Pflichtfeld",
                "change"
            ]
        });

        // change defaults validation plugin settings
        $.validation.setDefaults({
            errorClass: "error",
            // show custom stylings for fields containing errors
            onProgress: function($element, error) {
                var $parent = $element.closest(".fieldset-item");

                $parent.find("label.error").remove();
                if(error) {
                    $parent.addClass("error");
                    $parent.appendElement(
                        label({"class":"error"}, error.message )
                    );
                } else {
                    $parent.removeClass("error");
                }
            },
            onComplete: function(isValid) {
                if(isValid) {
                    app.removeValidationErrors();
                } else {
                    app.showValidationError();
                }
            }
        });

        // add validate-event to all BoxWorld elements
        $.merge($.box.settings.coreevents, [ $.validation.defaults.eventType ]);

        // override $.fn.validate to show the error dialog after the validation
        $.fn.validateDefault = $.fn.validate;
        $.fn.validate = (function(fnValidate) {
            return function() {
                var valid = fnValidate.call(this);

                // show error summary
                if(!valid) {
                    app.showValidationError();
                } else {
                    app.removeValidationErrors();
                }

                return valid;
            };
        }($.fn.validate));

        // override $.fn.reset to remove error dialog
        $.validation.resetDefault = $.validation.reset;
        $.validation.reset = (function(fnReset) {
            return function() {
                app.removeValidationErrors();

                return fnReset.call(this);
            };
        }($.validation.reset));
    }
})(window.app);
