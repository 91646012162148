/**
 * Universal (Helper-) Functions for widgets.
 *
 * @module app.widgets.tools
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function (app) {
    "use strict";

    /*** EXPORT ***/
    var tools = app.widgets.tools = {};

    /**
     * Extend the params with the defined defaults. E.g add CSS classes to the defined
     * or call a the default create method before the defined create method
     *
     * @param {object}  params      Params which should be extended with the defaults
     * @param {object}  defaults    Defaults which should be added to the params
     */
    tools.extend = function (params, defaults) {
        /* jshint noempty: false */

        if (!params) {
            return defaults;
        }

        params = $.extend(true, {}, params);
        _.each(defaults, function (def, key) {
            if (['string', 'number', 'function', 'object', 'boolean'].indexOf(typeof def) === -1) {
                throw 'I don\'t know how to merge type: ' + typeof def;
            }

            if (params[key]) {
                if (typeof params[key] !== typeof def) {
                    throw sprintf('Cannot merge values of key "%s" with different types: %s != %s',
                        key, typeof params[key], typeof def);
                }
                if (typeof def === 'string') {
                    params[key] = $.trim(params[key]) + ' ' + $.trim(def);
                } else if (typeof def === 'number') {
                    // don't replace the number values
                } else if (typeof def === 'boolean') {
                    // don't replace the number values
                } else if (typeof def === 'function') {
                    params[key] = (function (f1, f2) {
                        return function () {
                            f1.apply(this, arguments);
                            f2.apply(this, arguments);
                        };
                    }(def, params[key]));
                } else if (_.isDate(def)){
                    // don't replace the date values
                } else {
                    throw 'I don\'t know how to merge type: ' + typeof def;
                }
            } else {
                params[key] = def;
            }
        });
        return params;
    };

})(window.app);
