/**
 * jQuery UI Tokenlist Plugin (with added key/value-data support)
 *
 * Based on work of
 *      Jörn Zaefferer (https://github.com/jzaefferer/jquery.ui-tokenlist)
 *      Mark Gibson (http://github.com/jollytoad/jquery.ui-tokenlist)
 *
 * Depends:
 *  jquery.ui.widget.js
 *  jquery.ui.autocomplete.js
 *  underscore.string.js
 * Optional:
 *  jquery.ui.sortable.js (which depends on jquery.ui.mouse.js)
 *
 * @author Conrad Wandl
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function($) {
    "use strict";

    $.widget('ui.tokenlist', {

        options: {
            items: [],
            split: /\s*,\s*/,
            join: ', ',
            removeTip: "Remove Item",
            duplicates: false,
            acceptCustomInput: false,
            validate: false, // May be false, an array of allowed values, or a validation function
            sortable: false,
            tokenDelimiter: [], // additional keycodes to end a token -- ENTER is fixed
            changed: false,
        },

        _create: function() {
            var self = this, key = $.ui.keyCode;

            this.element
                // Hide the original field
                .hide()
                // Update our list if the original field is changed
                .bind('change.' + this.widgetName, function() {
                    self.value(self.element.val(), true);
                });

            // Generate a list element to replace the original field
            this.tokenlist =
                $('<ul/>')
                    .insertAfter(this.element)
                    // Allow the widget to also be accessed via the generated element
                    .data(this.widgetName, this);

            this.tokenlist
                .addClass(this.widgetFullName + ' ui-widget ui-widget-content ui-helper-clearfix')
                .bind('keydown.' + this.widgetName, function(ev) {
                    /* jshint indent:false */

                    var focus, disabled = self._getData('disabled');
                    switch (ev.which) {
                        case key.LEFT:
                        case key.UP:
                        case key.BACKSPACE:
                            focus = $(ev.target).closest('li').prev('li');
                            break;
                        case key.RIGHT:
                        case key.DOWN:
                        case key.DELETE:
                            focus = $(ev.target).closest('li').next('li.'+self.widgetFullName+'-item');
                            if (!focus.length && !disabled) {
                                focus = self.inputElem;
                            }
                            break;
                        case key.HOME:
                        case key.PAGE_UP:
                            focus = $(ev.target).closest('ul').find('>li:first');
                            break;
                        case key.END:
                        case key.PAGE_DOWN:
                            focus = self.inputElem;
                            break;
                    }

                    switch (ev.which) {
                        case key.DELETE:
                        case key.BACKSPACE:
                            if (disabled) {
                                focus = undefined;
                            } else {
                                self._removeItem(ev.target);
                            }
                            break;
                    }

                    if (focus && focus.length) {
                        focus[0].focus();
                        ev.stopPropagation();
                        ev.preventDefault();
                    }
                })

                // Delete the item if the button is clicked
                .bind('click.' + this.widgetName, function(ev) {
                    if (!self._getData('disabled')) {
                        if ($(ev.target).is('.'+self.widgetFullName+'-remove')) {
                            self._removeItem(ev.target);
                        }
                        if (this === ev.target) {
                            self.inputElem[0].focus();
                        }
                    }
                });

            if (self.options.sortable && $.fn.sortable) {
                this.tokenlist.sortable({
                    stop: function() {
                        // update items based on list values
                        self.options.items = self.tokenlist.find(".ui-tokenlist-label").map(function() { return $(this).text(); }).get();
                        self._change();
                    }
                });
            }

            this.inputElem =
                $('<input type="text" class="x-large-input" style="margin-top: -4px"/>')
                    .bind('keydown.' + this.widgetName, function(ev) {
                        if (ev.which === key.LEFT) {
                            // If caret is at the far-left of the field, move focus to the last item
                            var caret;
                            if (this.selectionEnd !== undefined) {
                                caret = this.selectionEnd;
                            }
                            if (caret === 0) {
                                $(this).closest('li').prev('li').each(function() { this.focus(); });
                                ev.preventDefault();
                            }
                        }

                        if(_.indexOf(self.options.tokenDelimiter, ev.which) !== -1) {
                            ev.preventDefault();
                            $(ev.target).trigger("change."+self.widgetName);
                        }
                        ev.stopPropagation();
                    })
                    .bind('change.' + this.widgetName, function(event, item) {
                        if(item) {
                            self.add(item);
                        } else if(self.options.acceptCustomInput) {
                            var value = _.trim($(this).val());
                            if(value.length > 0) {
                                self.add({ value:value, label:value });
                            }
                        }
                        $(this).val('');

                    }).autocomplete({
                        /* jshint indent: false */
                        source: function(request, response) {
                            // escape regex characters
                            var matcher, list;

                            matcher = new RegExp($.ui.autocomplete.escapeRegex(request.term), "i");

                            list = $.map(self.options.validate, function(label, id) {
                                if(matcher.test(label) && $.inArray(id, self._getData('items')) < 0) {
                                    return { value:id, label:label };
                                }
                            });

                            response(list);
                        },
                        focus: function( event, ui ) {
                            $(this).val( ui.item.label );
                            return false;
                        },
                        delay: 0,
                        select: function(event, item) {
                            setTimeout(function() {
                                self.inputElem.trigger("change.tokenlist", item.item );
                            }, 13);
                        }
                    });

            this.inputElem.data("uiAutocomplete")._renderItem = function( ul, item ) {

                return $("<li><a>"+item.label+"</a></li>")
                        .data( "item.autocomplete", item )
                        .appendTo( ul );
            };

            // Add the new item input field
            $('<li/>')
                .appendTo(this.tokenlist)
                .addClass(this.widgetFullName+'-input')
                .append(this.inputElem);

            this.value(this.element.val());

            if (this.element[0].disabled) {
                this.disable();
            }
        },

        _setData: function(key, value) {
            $.widget.prototype._setData.apply(this, arguments);

            if (key === 'disabled') {
                this.inputElem[0].disabled = value;
            }
        },

        input: function() {
            return $(this.inputElem);
        },

        list: function() {
            return $(this.tokenlist);
        },

        items: function() {
            return this._getData('items');
        },

        empty: function() {
            // Remove all existing items
            $('> li.'+this.widgetFullName+'-item', this.tokenlist).remove();
            this.options.items = [];
            return this;
        },

        value: function(newValue, noChange) {
            var value, newItems;

            value = this._stringify(this.items());

            if (arguments.length > 0) {
                newItems = this._parse(newValue);
                newValue = this._stringify(newItems);

                this.empty().add(newItems, noChange);
                value = newValue;
            }

            return value;
        },

        add: function(newItems, noChange) {
            var items = this.items(),
                unique = !this._getData('duplicates'),
                validate = this._getData('validate'),
                added = [],
                self = this;

            if (!$.isArray(newItems)) {
                newItems = [newItems];
            }

            $.each(newItems, function(i, item) {
                // Discard duplicate items if duplicates are not allowed
                if (unique && $.inArray(item.value, items) >= 0) { return; }

                // Validate the item
                if (validate) {
                    if ($.isArray(validate)) {
                        if ($.inArray(item, validate) < 0) { return; }
                    } else if ($.isFunction(validate)) {
                        if (!validate.call(self, item)) { return; }
                    }
                }

                if(_.isString(item)) {
                    item = { value:item, label:item };
                }

                added.push(item.value);
                items.push(item.value);
                self._addItemElem(item);
            });

            if (added.length && !noChange) {
                this._change(newItems);
            }

            return added;
        },

        _addItemElem: function(token) {
            var input = $('.'+this.widgetFullName+'-input', this.tokenlist),
                label =
                    $('<span/>')
                        .addClass(this.widgetFullName+'-label')
                        .text(token.label),
                button =
                    $('<span>x</span>')
                        .addClass(this.widgetFullName+'-remove ui-icon ui-icon-close')
                        .attr('alt', this._getData('removeTip'))
                        .css('width', '15px');

            return $('<li/>')
                .insertBefore(input)
                .addClass(this.widgetFullName+'-item ui-state-default ui-corner-all')
                .attr('tabindex','-1')
                .append(label)
                .append(button)

                // Apply/remove style for a focused item
                .bind('focus.' + this.widgetName, function() { $(this).addClass('ui-state-focus'); })
                .bind('blur.' + this.widgetName, function() { $(this).removeClass('ui-state-focus'); });
        },

        _removeItem: function(target) {
            var item = $(target).closest('li'),
                position = $(item).prevAll('li').length;
                //,removed = this.items()[position];
            this.items().splice(position, 1);
            item.remove();
            //$(this.tokenlist).trigger('remove', removed);
            this.element.val(this._stringify(this.items()));
            $(this.element).trigger('change', this.items());

            const changed = this._getData('changed');
            if (changed) {
                changed.call(this, this.items());
            }
        },

        _parse: function(value) {
            return _.reject(value.split(this._getData('split')), function(val) { return val == null || val === ""; });
        },

        _stringify: function(items) {
            return items.join(this._getData('join'));
        },

        _change: function(newItems) {
            this.element.val(this._stringify(this.items()));
            $(this.element).trigger('change', newItems );
            $(this.tokenlist).trigger('change', newItems );

            const changed = this._getData('changed');
            if (changed) {
                changed.call(this, this.items());
            }
        },

        _getData: function(data) {
            return this.options[data];
        },

        disable: function() {
            $(this.tokenlist)
                .addClass("ui-state-disabled")
                .unbind("keydown")
                .unbind("click")
                .find("li")
                    .unbind("focus")
                    .unbind("blur")
                .end()
                .find(".ui-icon-close")
                    .remove();

            $(this.inputElem).remove();
        },

        _destroy: function() {
            this.tokenlist.remove();
            this.element.unbind('change.' + this.widgetName);
            this.element.show();
        }
    });

}(jQuery));
/* @license end of code */
