import React, { useEffect, useState } from 'react';

import { TourArea } from 'app/legacy/model/tour-area';
import { Customer } from 'app/legacy/model/customer';

import { ReportSchedule } from 'app/report-schedule/ReportSchedule';
import { PageType } from 'app/report-schedule/ReportScheduleConstants';
import { formItemSelect, formItemWorkplaceSelect } from 'app/legacy/widgets/form';
import { Box } from 'app/BoxWorld';

const FILTER_TYPE_OBJECT = {
    id: 'object',
    name: 'Kunde / Standort / Objekt',
    value: 'object',
};
const FILTER_TYPE_TOUR_AREA = {
    id: 'tour_area',
    name: 'Revier',
    value: 'tour_area',
};

type Props = {
    pageType: PageType;
    tourAreas: Array<TourArea>;
    customers: Array<Customer>;
    tourAreaId: ReportSchedule['tour_area_id'];
    customerId: ReportSchedule['customer_id'];
    locationId: ReportSchedule['location_id'];
    objectId: ReportSchedule['object_id'];
    onChange: (ReportSchedule) => void;
};

type State = typeof FILTER_TYPE_OBJECT | typeof FILTER_TYPE_TOUR_AREA;

export function ObjectTourAreaFilter(props: Props) {
    const {
        pageType, tourAreas, customers, tourAreaId, customerId, objectId, locationId, onChange,
    } = props;

    const [selectedFilterType, setSelectedFilterType] = useState<State>(
        tourAreaId ? FILTER_TYPE_TOUR_AREA : FILTER_TYPE_OBJECT,
    );

    useEffect(() => {
        if (tourAreaId) {
            setSelectedFilterType(FILTER_TYPE_TOUR_AREA);
        }
    }, [tourAreaId]);

    useEffect(() => {
        if (customerId) {
            setSelectedFilterType(FILTER_TYPE_OBJECT);
        }
    }, [customerId]);

    const readonly = pageType === PageType.VIEW;

    const filterTypeSelect = formItemSelect({
        id: 'filter-type',
        label: 'Filtertyp',
        required: true,
        source: [FILTER_TYPE_OBJECT, FILTER_TYPE_TOUR_AREA],
        valueProp: 'name',
        value: selectedFilterType,
        sort: false,
        class: 'large-input',
        selected: (e, filterType) => {
            if (filterType && Object.keys(filterType).length && selectedFilterType !== filterType) {
                onChange({
                    customer_id: null,
                    location_id: null,
                    object_id: null,
                    tour_area_id: null,
                });
                setSelectedFilterType(filterType);
            }
        },
    }, readonly);

    const selection = customerId ? {
        customers: [customerId],
        locations: [locationId],
        objects: [objectId],
    } : undefined;
    const objectFilterForm = formItemWorkplaceSelect({
        id: 'select-object',
        customers,
        depth: 3,
        required: false,
        selected: selection,
        noSelectPlaceholder: 'Alle',
        change: (level, value) => {
            if (level === 0) {
                if (value && customerId !== value) {
                    onChange({
                        customer_id: value,
                        location_id: undefined,
                        object_id: undefined,
                    });
                }
            } else if (level === 1) {
                if (value && locationId !== value) {
                    onChange({
                        location_id: value,
                        object_id: undefined,
                    });
                }
            } else if (level === 2) {
                if (value && objectId !== value) {
                    onChange({
                        object_id: value,
                    });
                }
            }
        },
    }, readonly);

    const tourAreaFilterForm = formItemSelect({
        id: 'filter-tour-area',
        label: 'Revier',
        required: true,
        source: tourAreas,
        valueProp: 'name',
        value: tourAreas.filter((ta) => ta.id === tourAreaId)[0],
        sort: true,
        selected: (e, tourArea) => {
            if (tourArea && tourArea.id && tourAreaId !== tourArea.id) {
                onChange({
                    tour_area_id: tourArea.id,
                });
            }
        },
    }, readonly);

    return (
        <div className="column">
            <fieldset id="filter-settings-edit">
                <legend>Rundgangfilter</legend>
                <ul className="fieldset-content">
                    <Box>
                        {filterTypeSelect}
                        {selectedFilterType === FILTER_TYPE_OBJECT ? objectFilterForm : null}
                        {selectedFilterType === FILTER_TYPE_TOUR_AREA ? tourAreaFilterForm : null }
                    </Box>
                </ul>
            </fieldset>
        </div>
    );
}
