/**
 * Displays a list of all tours visible for the current user.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav = rise.Navigation;
    var clowFilter = app.widgets.clowFilter;
    var form = app.widgets.form;

    /** DAOs **/
    var CustomerDao = app.dao.customerDao;
    var TourDao = app.dao.tourDao;

    /*** GLOBALS ***/
    var pages = app.pages;

    /*** CONSTANTS ***/
    var TOUR_ID_COLUMN_INDEX = 0;

    /*** Export Pages ***/
    pages.tour.list = app.pagesByPath['/tour/list'] = TourList;

    /*** Set permission ***/
    TourList.permissions = [app.model.PermissionTypes.TOUR_MODIFY];

    var defaultSettings = {
        prefix: "tour",
        labels: {
            pageHeader: "Rundgänge"
        }
    };

    function TourList(options) {
        var self = this,
            settings = {},
            filter = {};

        self.options = _.extend({
            showCommandBox       : true,
            showFilterBar        : true,
            onSelect             : null,
            filterToursWithTimeC : false
        }, options);

        if (options && options.prefix) {
            settings.prefix = options.prefix;
        }

        self = app.widgets.ListPage.call(this, $.extend(true, {}, defaultSettings, {
            pageName: '/tour/list',
            rpc: function () {
                return [ CustomerDao.findAll() ];
            },
            labels: {
                buttonNew: "Neuer Rundgang"
            },
            filterSearchFieldInactive: true,
            "export":
                window.app.session.hasPermission(app.model.PermissionTypes.TOUR_MODIFY)
                    ? {
                        method: "export_tours",
                        criteriaFn: function() {
                            return {
                                'filter_params': getFilterCriteria()
                            };
                        }
                      }
                    : undefined,
            options: self.options
        }, settings));

        this.prepareTable = function($parent, customers) {
            var columns = getTableColumns();
            var settings = getTableSettings();

            this.renderTable(null, columns, settings);
            $("#placeholder-filters").prependElement(
                div({ id: 'tour-filter-form', 'class': 'whiteboard' }, [
                    legend({}),
                    ul({ "class":"inline-list title-row-highlight" }, [
                        form.formItemText({id: 'tour-filter-search', label: 'Suche', required: false,
                            style: "width: 250px",
                            name: "filter_expression",
                            keyup: function () {
                                filter.filter_expression = $(this).val();
                            },
                            keypress: function (e) {
                                var keyCode = e.which;
                                if (keyCode === 13){
                                    filterTourEntries($parent, self.datatable);
                                    return false;
                                }
                            },
                            change: function () {
                                filterTourEntries($parent, self.datatable);
                            }
                        }),
                        function($parent) {
                            return clowFilter({
                                customers: customers,
                                $parent: $parent,
                                change: function(selection) {
                                    var newCustomerID = selection.customer !== -1 ? selection.customer : undefined,
                                        newLocationID = selection.location !== -1 ? selection.location : undefined,
                                        newObjectID = selection.object !== -1 ? selection.object : undefined,
                                        newWorkplaceID = selection.workplace !== -1 ? selection.workplace : undefined,
                                        oldCustomerID = filter.customer_id,
                                        oldLocationID = filter.location_id,
                                        oldObjectID = filter.object_id,
                                        oldWorkplaceID = filter.workplace_id;

                                    filter.customer_id = newCustomerID;
                                    filter.location_id = newLocationID;
                                    filter.object_id = newObjectID;
                                    filter.workplace_id = newWorkplaceID;

                                    if (newCustomerID !== oldCustomerID || newLocationID !== oldLocationID ||
                                        newObjectID !== oldObjectID || newWorkplaceID !== oldWorkplaceID) {
                                        filterTourEntries($parent, self.datatable);
                                    }
                                },
                                depth: 4
                            });
                        },
                        createStateSelector($parent, self.datatable)
                    ])
            ]));
        };

        this.savePageState = function() { };

        this.loadPageState = function($parent, dataTours) {
            this.updateTableServerData();
        };

        this.showDetailsPage = function(tour) {
            showDetailsPage(tour);
        };

        function getFilterCriteria() {
            return filter;
        }

        function showDetailsPage(tour) {
            nav.goToPage(pages.tour.detail, tour ? [ tour ] : undefined);
        }

        function createStateSelector($parent, datatable) {
            var stateTypes = [
                { id: 'ALL', name: 'Alle', value: undefined },
                { id: 'ACTIVATED', name: 'Aktiviert', value: true },
                { id: 'NOT_ACTIVATED', name: 'Nicht Aktiviert', value: false }
            ];

            return form.formItemSelect({id: 'filter-activated', label: 'Zustand', required: false,
                source: stateTypes, valueProp: 'name',
                value: stateTypes[0],
                sort: false,
                selected: function (e, stateType) {
                    if (filter.active !== stateType.value) {
                        filter.active = stateType.value;
                        filterTourEntries($parent, datatable);
                    }
                }
            });
        }

        function getTableColumns() {
            return [
                {
                    sTitle: "Erstellt am",
                    sAttrName: "created_at",
                    sWidth: "120px",
                    mRender: function(mData, type, obj) {
                        if (obj && obj.created_at) {
                            return obj.created_at.toFormatString(app.settings.dateTimeFormat);
                        }

                        return "";
                    }
                },
                {
                    sTitle: "Name",
                    sAttrName: "name"
                },
                {
                    sTitle: "Beschreibung",
                    sAttrName: "description"
                },
                {
                    sTitle: "Kunde",
                    sAttrName: "customers"
                },
                {
                    sTitle: "Standort",
                    sAttrName: "locations"
                },
                {
                    sTitle: "Objekt",
                    sAttrName: "objects"
                },
                {
                    sTitle: "Arbeitsplatz",
                    sAttrName: "workplaces"
                },
                {
                    sTitle: "Aktiviert",
                    sAttrName: "active",
                    sWidth: "60px",
                    mRender: function(mData, type, obj) {
                        return obj && obj.active ? 'Ja' : 'Nein';
                    }
                }
            ];
        }

        function getTableSettings() {
            return {
                aaSorting: [[ TOUR_ID_COLUMN_INDEX, "asc" ]],
                bServerSide: true,
                fnServerData: function (sSource, aoData, fnCallback) {
                    aoData = _.object(_.map(aoData, function (oData) {
                        return [oData.name, oData.value];
                    }));

                    var filter = getFilterCriteria();
                    filter = $.extend({
                        with_timec: self.options.filterToursWithTimeC,
                        offset: aoData.iDisplayStart,
                        limit: aoData.iDisplayLength,
                        sort_column: aoData.iSortCol_0,
                        sort_direction: aoData.sSortDir_0
                    }, filter);

                    TourDao.findAll(filter).done(function (data) {
                        fnCallback({
                            aaData: data.rows,
                            iTotalRecords: data.totalCount,
                            iTotalDisplayRecords: data.filteredCount,
                            sEcho: aoData.sEcho
                        });
                    });
                }
            };
        }

        function filterTourEntries($parent, datatable) {
            if ($parent.validate()) {
                datatable.fnDraw();
            }
        }
    }
})(window.rise, window.app);
