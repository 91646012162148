/**
 * Initializes the window.rise package. This script should be executed
 * before any other RISE libraries are loaded.
 *
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 * ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(self) {
    "use strict";

    self.rise = {};
    self.rise.settings = {};

}(window));