/**
 * Location detail page.
 *
 * @author Martin Reiterer (martin.reiterer@rise-world.com)
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function (rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav = rise.Navigation;
    var pages = app.pages;
    var form = app.widgets.form;
    var scrollTableSection = app.widgets.scrollTableSection;
    var tabsWidget = app.widgets.tabsWidget;
    var addIconButton = app.widgets.addIconButton;
    var editIconButton = app.widgets.editIconButton;
    var printIconButton = app.widgets.printIconButton;
    var cancelIconButton = app.widgets.cancelIconButton;
    var saveIconButton = app.widgets.saveIconButton;
    var commandBox = app.widgets.commandBox;

    /** DAOs **/
    var Location = app.model.Location;
    var locationDao = app.dao.locationDao;
    var customerDao = app.dao.customerDao;
    var eventTypeDao = app.dao.eventTypeDao;
    var businessDocumentDao = app.dao.businessDocumentDao;

    var currentTab = 0;

    var PageType = {
        VIEW: 1,
        EDIT: 2,
        NEW: 3
    };

    /*** Export page ***/
    pages.location.detail = app.pagesByPath['/location/detail'] = function LocationDetail() {
        var self = this;

        this.onUpdate = null;
        this.onTreeNavigate = null;
        this.onAddChild = null;
        this.getParentId = null;
        this.name = "/location/detail";
        this.type = PageType.VIEW;

        this.location = null;
        this.eventTypes = [];
        this.business_documents = [];

        this.assignedEventTypes = [];
        this.assignedBusinessDocuments = [];

        this.$parent = null;

        this.create = function ($parent, locationId, onUpdate, onTreeNavigate, onAddChild, getParentId, getNodeData, getCustomerData) {
            this.$parent = $parent;
            this.onUpdate = onUpdate;
            this.onTreeNavigate = onTreeNavigate;
            this.onAddChild = onAddChild;
            this.getParentId = getParentId;
            this.getCustomerData = getCustomerData;
            this.getNodeData = getNodeData;

            if (locationId) {
                return locationDao.getLocationDetails(locationId)
                    .done(function (locationDetailsDto) {
                        self.location = locationDetailsDto.location;
                        self.assignedEventTypes = locationDetailsDto.assigned_event_types;
                        self.assignedBusinessDocuments = locationDetailsDto.assigned_business_documents;
                        self.assignedTours = locationDetailsDto.assigned_tours;

                        self.type = PageType.VIEW;
                        renderLocation();
                    });
            } else {
                var customerId = parseInt(getParentId(), 10);

                return $.when(eventTypeDao.listAll(),
                    businessDocumentDao.listAll({
                        include_read_count: false
                    }),
                    customerDao.getCustomerAssignments(customerId))
                    .done(function (eventTypes, business_documents, customerAssignments) {
                        self.location = new Location();
                        self.eventTypes = eventTypes;
                        self.business_documents = business_documents;
                        self.assignedEventTypes = customerAssignments.assigned_event_types;
                        self.assignedBusinessDocuments = customerAssignments.assigned_business_documents;

                        self.type = PageType.NEW;
                        renderLocation();
                    });
            }
        };

        this.destroy = function () {
            // called after page has been destroyed
        };

        function renderLocation() {
            var view = self.type === PageType.VIEW,
                tabs = [
                    {
                        'id': 'customer-details-tab',
                        'title': 'Allgemein',
                        'fnRender': $.proxy(renderOverviewSection, self, view)
                    },
                    {
                        'id': "assigned-event-types-tab",
                        'title': "Ereignistypen",
                        'fnRender': $.proxy(renderAssignedEventTypesList, self, view)
                    },
                    {
                        'id': "assigned-business-documents-tab",
                        'title': "Dokumente",
                        'fnRender': $.proxy(renderAssignedBusinessDocumentsList, self, view)
                    }
                ];

            if (view) {
                tabs.push({
                    'id': "assigned-tours-tab",
                    'title': "Rundgänge",
                    'fnRender': $.proxy(renderAssignedTours, self, view)
                });
            }

            // render command box
            self.$parent.empty().appendElement(renderCommandBox());

            // render tabs
            self.$parent.appendElement(tabsWidget(
                {id: "customer-assignments", beforeActivate: beforeActivate, active: currentTab},
                tabs
            ));

            self.$parent.appendElement(div({"class": "two-row-split"},
                function () {
                    renderAssignedEventTypesList(view);
                    renderAssignedBusinessDocumentsList(view);
                }
            ));

            self.$parent.activateValidators();
        }

        function beforeActivate(event, ui) {
            currentTab = ui.newTab.index();
        }

        function renderOverviewSection(view) {
            return div({}, [
                createLocationDetails(self.location, view),
                self.type === PageType.VIEW ? renderAssignedObjectsList() : undefined
            ]);
        }

        function renderCommandBox() {
            var savedLocation = new Location(self.location);

            function cancelEditAction() {
                if (self.type !== PageType.NEW) {
                    $.validation.reset();

                    self.location = savedLocation;
                    self.type = PageType.VIEW;
                    renderLocation();
                } else {
                    self.onTreeNavigate("customer", {id: self.getParentId()});
                }
            }

            function addAction() {
                // create a new Location that is assigned to the current customer
                var object = new app.model.Object({location_id: self.location.id});

                if (self.onAddChild) {
                    self.onAddChild(object);
                }
            }

            function saveButton() {
                return saveIconButton({
                    id: 'save-location',
                    click: saveLocation
                });
            }

            function cancelButton() {
                return cancelIconButton({
                    id: 'cancel-edit-location',
                    click: cancelEditAction
                });
            }

            function editButton() {
                return editIconButton({
                    id: 'edit-location',
                    click: editLocation
                });
            }

            function addObjectButton() {
                return addIconButton({
                    id: 'add-location',
                    click: addAction
                }, 'Objekt hinzufügen');
            }

            function printButton() {
                return printIconButton({
                    id: 'print-customer',
                    click: function () {
                        app.printpreview.openCustomerPrintPreview({
                            customer: self.getCustomerData(),
                            location: self.getNodeData()
                        });
                    }
                }, 'Stammdaten drucken');
            }

            function getTitle() {
                switch (self.type) {
                    case PageType.NEW:
                        return 'Neuer Standort';
                    case PageType.EDIT:
                        return 'Standort bearbeiten';
                    case PageType.VIEW:
                        return 'Standort: ' + self.location.name;
                }
                return '';
            }

            function getCommandButtons() {
                if (!window.app.session.hasPermission(app.model.PermissionTypes.CUSTOMER_MODIFY)) {
                    return [];
                }

                switch (self.type) {
                    case PageType.VIEW:
                        return [
                            printButton(),
                            addObjectButton(),
                            editButton()
                        ];

                    case PageType.EDIT:
                        return [
                            saveButton(),
                            cancelButton()
                        ];

                    case PageType.NEW:
                        return [
                            saveButton(),
                            cancelButton()
                        ];
                }

                return [];
            }

            return commandBox({title: getTitle(), 'class': 'inner'}, getCommandButtons());
        }

        function editLocation() {
            var fnEdit = function () {
                self.type = PageType.EDIT;
                renderLocation();
            };

            if (self.eventTypes.length > 0 && self.business_documents.length > 0) {
                fnEdit();
            } else {
                return $.when(eventTypeDao.listAll(),
                    businessDocumentDao.listAll({
                        include_read_count: false
                    }))
                    .done(function (eventTypes, business_documents) {
                        self.eventTypes = eventTypes;
                        self.business_documents = business_documents;
                        fnEdit();
                    });
            }
        }

        function saveLocation() {
            if (!self.$parent.validate()) {
                return;
            }

            var method = $.proxy(locationDao.updateLocationAndAssignments, locationDao);

            if (!self.location.id) {
                self.location.customer_id = parseInt(self.getParentId(), 10);
                method = $.proxy(locationDao.insertLocationAndAssignments, locationDao);
            }

            function saveDoneMethod(result) {
                self.location = result;
                self.type = PageType.VIEW;

                renderLocation();
                app.showSuccessMessage('Gespeichert');

                if (self.onUpdate) {
                    self.onUpdate(result);
                }
            }

            // execute action
            return method(self.location,
                _.pluck(_.filter(self.assignedEventTypes,
                    function (et) {
                        return et.assigned_to_location === true;
                    }), 'id'),
                _.pluck(_.filter(self.assignedBusinessDocuments,
                    function (inst) {
                        return inst.assigned_to_location === true;
                    }), 'id'))
                .done(saveDoneMethod);
        }

        function renderAssignedObjectsList() {
            return scrollTableSection({
                id: "assigned-objects",
                title: 'Objekte',
                elements: self.location.objects,
                columns: [
                    {sTitle: "Bezeichnung", sAttrName: "name"},
                    {
                        sTitle: "Aktiv", sAttrName: "active", mRender: function (mData, type, obj) {
                            return obj.active ? "Ja" : "Nein";
                        }
                    }
                ],
                "class": "",
                click: displayObject
            });
        }

        function renderAssignedEventTypesList(view) {
            var getRowLabel = function (aoData) {
                    return aoData.assigned_to_location === true || !_.find(self.assignedEventTypes, function (et) {
                        return et.id === aoData.id;
                    })
                        ? "Direkt dem Standort zugeordnet"
                        : "Übernommen von Kunde";
                },
                isSelectable = function (aoData) {
                    return getRowLabel(aoData) === "Direkt dem Standort zugeordnet";
                };

            return scrollTableSection({
                filter: true,
                id: "assigned-event-types",
                elements: view
                    ? self.assignedEventTypes
                    : getAllEventTypes(),
                selected: view ? null : self.assignedEventTypes,
                select: function (selected) {
                    _.each(selected, function (sel) {
                        if (!sel.assigned_to_customer) {
                            sel.assigned_to_location = true;
                        }
                    });
                    self.assignedEventTypes = selected;
                },
                isSelectable: isSelectable,
                columns: [
                    {
                        sTitle: "Vererbt", sAttrName: "assigned_to_location", bVisible: false,
                        mRender: function (mData, type, obj) {
                            return getRowLabel(obj);
                        }
                    },
                    {sTitle: "ID", sAttrName: "id"},
                    {sTitle: "Bezeichnung", sAttrName: "label"}
                ],
                click: displayEventType,
                getGroupingRowLabel: getRowLabel,
                groupedByColumn: 0,
                height: "100%"
            });
        }

        function renderAssignedBusinessDocumentsList(view) {
            var getRowLabel = function (aoData) {
                    return aoData.assigned_to_location === true || !_.find(self.assignedBusinessDocuments, function (inst) {
                        return inst.id === aoData.id;
                    })
                        ? "Direkt dem Standort zugeordnet"
                        : "Übernommen von Kunde";
                },
                isSelectable = function (aoData) {
                    return getRowLabel(aoData) === "Direkt dem Standort zugeordnet";
                };

            return scrollTableSection({
                filter: true,
                id: "assigned-business-documents",
                elements: view
                    ? self.assignedBusinessDocuments
                    : getAllBusinessDocuments(),
                selected: view ? null : self.assignedBusinessDocuments,
                select: function (selected) {
                    _.each(selected, function (sel) {
                        if (!sel.assigned_to_customer) {
                            sel.assigned_to_location = true;
                        }
                    });
                    self.assignedBusinessDocuments = selected;
                },
                isSelectable: isSelectable,
                columns: [
                    {
                        sTitle: "Vererbt", sAttrName: "assigned_to_location", bVisible: false,
                        mRender: function (mData, type, obj) {
                            return getRowLabel(obj);
                        }
                    },
                    {sTitle: "ID", sAttrName: "id"},
                    {sTitle: "Bezeichnung", sAttrName: "name"}
                ],
                getGroupingRowLabel: getRowLabel,
                click: displayBusinessDocument,
                groupedByColumn: 0,
                height: "100%"
            });
        }

        function renderAssignedTours() {
            return scrollTableSection({
                filter: true,
                id: "assigned-tours",
                elements: self.assignedTours,
                columns: [
                    {
                        sTitle: "Erstellt am",
                        sAttrName: "created_at",
                        mRender: function (mData, type, obj) {
                            return obj.created_at.toFormatString(app.settings.dateTimeFormat);
                        }
                    },
                    {
                        sTitle: "Name",
                        sAttrName: "name"
                    },
                    {
                        sTitle: "Beschreibung",
                        sAttrName: "description"
                    }
                ],
                click: displayTour,
                height: "100%"
            });
        }

        function displayTour(tour) {
            if (window.app.session.hasPermission(app.model.PermissionTypes.TOUR_MODIFY)) {
                nav.goToPage(pages.tour.detail, [tour, '/customer/manager']);
            }
        }

        function displayObject(object) {
            if (self.onTreeNavigate) {
                self.onTreeNavigate("object", object);
            }
        }

        function getAllEventTypes() {
            return _.map(self.eventTypes, function (eventType) {
                var et = _.find(self.assignedEventTypes, function (et) {
                    return et.id === eventType.id;
                });
                return et || eventType;
            });
        }

        function getAllBusinessDocuments() {
            return _.map(self.business_documents, function (business_document) {
                var inst = _.find(self.assignedBusinessDocuments, function (inst) {
                    return inst.id === business_document.id;
                });
                return inst || business_document;
            });
        }
    };

    function displayBusinessDocument(business_document) {
        nav.goToPage(pages.business_document.detail, [business_document.id, true, '/customer/manager']);
    }

    function displayEventType(eventType) {
        nav.goToPage(pages.event_type.detail, [eventType, '/customer/manager']);
    }

    /**
     * Renders the location details. Also used for the customer print preview.
     */
    var createLocationDetails = pages.location.detail.createLocationDetails = function (location, view) {
        return fieldset({id: 'location-details', 'class': ''}, [
            ul({"class": "fieldset-content"}, [
                form.formItemText({
                    id: "location-name",
                    required: true,
                    label: "Name",
                    value: location.name,
                    change: function (e) {
                        location.name = $(e.target).val();
                    }
                }, view),

                form.formItemText({
                    id: "location-address",
                    required: true,
                    label: "Adresse",
                    value: location.address,
                    change: function (e) {
                        location.address = $(e.target).val();
                    }
                }, view),

                form.formItemText({
                    id: "location-city",
                    required: true,
                    label: "Stadt",
                    value: location.city,
                    change: function (e) {
                        location.city = $(e.target).val();
                    }
                }, view),

                form.formItemText({
                    id: "location-zip",
                    required: true,
                    label: "PLZ",
                    value: location.zip,
                    change: function (e) {
                        location.zip = $(e.target).val();
                    }
                }, view),

                form.formItemText({
                    id: "location-country",
                    required: true,
                    label: "Land",
                    value: location.country,
                    change: function (e) {
                        location.country = $(e.target).val();
                    }
                }, view),

                form.formItemCheckbox({
                    id: "location-active",
                    label: "Aktiv",
                    checked: !!location.active ? location.active : location.active = true,
                    change: function (e) {
                        location.active = $(e.target).prop("checked");
                    }
                }, view)
            ])
        ]);
    };

})(window.rise, window.app);
