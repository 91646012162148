/**
 * Functions for rendering buttons.
 *
 * @module app.widgets
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */

export const CommandBox = (function (app) {
    "use strict";

    /*** IMPORTS ***/
    var iconButton = app.widgets.iconButton;
    var nav = rise.Navigation;

    /*** EXPORTS ***/
    app.widgets.commandBox = CommandBox;

    function CommandBox(params, children) {

        var options = $.extend({
            'class':'top',
            title: "",
            backAction: null
        }, params);


        function createBackButton() {
            var backAction = options.backAction;
            if (backAction === null) {
                return;
            } else {
                return li({ "class": "left command-item-back" },
                    iconButton({id: 'back-button', "class": 'button-back',
                        click: function (e) {
                            if (isPageObject(backAction)) {
                                nav.goToPage(backAction);
                            } else if ($.isFunction(backAction)) {
                                return backAction(e);
                            }
                        }
                    }));
            }
        }


        function isPageObject(Page) {
            if (!!Page.prototype.isReactComponent) {
                return true;
            }

            try {
                var instPage = new Page();
                return instPage.create;
            } catch (e) {
                return false;
            }
        }


        function createHeader() {
            return li({ "class": "left command-item-title" }, h1({}, options.title));
        }

        return div({'class': 'command-box '+options['class']}, ul({"class":"clearfix" }, [
            createBackButton(),
            createHeader(),
            function () {
                if (children) {
                    if(!_.isArray(children)) {
                        children = [ children ];
                    }

                    return _.map(children, function (child, index) {
                        return li({ "class": "command-item" }, child);
                    });
                } else {
                    return [];
                }
            }
        ]));

    }

    return CommandBox;

})(window.app)
