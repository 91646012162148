import React, { useEffect, useState } from 'react';
import $ from 'jquery';

import { formItemDateTime } from 'app/legacy/widgets/form';
import { intervalNextIconButton, intervalPreviousIconButton } from 'app/legacy/widgets/button';
import { clowFilterReact } from 'app/legacy/widgets/section';
import { TourReportState } from 'app/tour-report/model/TourReportState';
import { Customer } from 'app/legacy/model/customer';
import { MAX_MONTH_DIFFERENCE_FROM_TO } from 'app/tour-report/TourReportConstants';
import { Box } from 'app/BoxWorld';

type State = Omit<TourReportState['weeksChartFilter'], 'customers'>;

type Props = TourReportState['weeksChartFilter'] & {
    handleApplyFilter: (filter: State) => void;
};

const placeholderCustomer = new Customer({
    name: '<Placeholder>',
    locations: [{
        name: '<Placeholder>',
        objects: [{
            name: '<Placeholder>',
        }],
    }],
});

export function WeeksChartFilter(props: Props) {
    const { dateFrom, dateTo, customerId, locationId, objectId, customers, handleApplyFilter } = props;
    const [state, setState] = useState<State>({
        dateFrom,
        dateTo,
        customerId,
        locationId,
        objectId,
    });
    const [filterRestored, setFilterRestored] = useState<boolean>(false);

    const dateError = getDateErrorMessage(state.dateFrom, state.dateTo);

    useEffect(() => {
        const savedFilter = JSON.parse(window.sessionStorage.getItem('wachbuch.tour-report.filter'));
        if (savedFilter) {
            setState({
                ...savedFilter,
                dateFrom: new Date(savedFilter.dateFrom),
                dateTo: new Date(savedFilter.dateTo),
            });
        }
        setFilterRestored(true);
    }, []);

    useEffect(() => {
        if (!dateError && filterRestored) {
            handleApplyFilter(state);
            window.sessionStorage.setItem('wachbuch.tour-report.filter', JSON.stringify(state));
        }
    }, [handleApplyFilter, state, dateError, filterRestored]);

    function scrollDayInterval(days: number) {
        const dateFromNext = new Date(state.dateFrom);
        dateFromNext.setDate(dateFromNext.getDate() + days);

        const dateToNext = new Date(state.dateTo);
        dateToNext.setDate(dateToNext.getDate() + days);

        setState({
            ...state,
            dateFrom: dateFromNext,
            dateTo: dateToNext,
        });
    }

    function getDateErrorMessage(from: Date, to: Date): JSX.Element | null {
        const start = new Date(from);
        const end = new Date(to);

        if (start.getTime() >= end.getTime()) {
            return (
                /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                <label className="error" style={{ marginLeft: 16 }}>
                    Das &apos;Von&apos;-Datum muss vorm dem &apos;Bis&apos;-Datum liegen.
                </label>
            );
        }

        start.setMonth(start.getMonth() + MAX_MONTH_DIFFERENCE_FROM_TO);
        if (start.getTime() < end.getTime()) {
            return (
                /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                <label className="error" style={{ marginLeft: 16 }}>
                    {`Der Auswerte-Zeitraum darf höchsten ${MAX_MONTH_DIFFERENCE_FROM_TO} Monate betragen`}
                </label>
            );
        }

        return null;
    }

    const filters = [
        intervalPreviousIconButton({
            click: () => scrollDayInterval(-1),
        }),
        formItemDateTime({
            id: 'tour-run-filter-start-time',
            label: 'Von',
            required: true,
            datetimepickerParams: {
                appendText: '',
                showButtonPanel: true,
            },
            renderDayScroller: true,
            date: state.dateFrom,
            name: 'begin',
            class: dateError ? 'error disable-validation' : '',
            change(e) {
                const date: Date = $(e.target).datepicker('getDate');

                if (date && date.getTime() !== state.dateFrom.getTime()) {
                    setState({ ...state, dateFrom: date });
                }
            },
        }),
        formItemDateTime({
            id: 'tour-run-filter-end-time',
            label: 'Bis',
            required: true,
            datetimepickerParams: {
                appendText: '',
                showButtonPanel: true,
            },
            renderDayScroller: true,
            date: state.dateTo,
            name: 'end',
            class: dateError ? 'error disable-validation' : '',
            change(e) {
                const date: Date = $(e.target).datepicker('getDate');
                if (date && date.getTime() !== state.dateTo.getTime()) {
                    setState({ ...state, dateTo: date });
                }
            },
        }),
        intervalNextIconButton({
            click: () => scrollDayInterval(1),
        }),
        ($parent) => {
            clowFilterReact({
                customers: customers.length ? customers : [placeholderCustomer],
                $parent,
                depth: 3,
                required: true,
                selectedCustomerId: state.customerId,
                selectedLocationId: state.locationId,
                selectedObjectId: state.objectId,
                view: false,
                change: (selection) => {
                    const selectedCustomerId = selection.customer;
                    let selectedLocationId = selection.location;
                    let selectedObjectId = selection.object;

                    if (state.customerId !== selection.customer) {
                        selectedLocationId = null;
                        selectedObjectId = null;
                    }

                    if (state.locationId !== selectedLocationId) {
                        selectedObjectId = null;
                    }

                    if (state.customerId !== selectedCustomerId
                        || state.locationId !== selectedLocationId
                        || state.objectId !== selectedObjectId) {
                        setState({
                            ...state,
                            customerId: selectedCustomerId,
                            locationId: selectedLocationId,
                            objectId: selectedObjectId,
                        });
                    }
                },
            });
        },
    ];

    return (
        <div id="tour-run-filter-form" className="whiteboard">
            <ul className="inline-list title-row-highlight">
                <div style={{ display: 'inline-block' }}>
                    <Box>{filters}</Box>
                    { dateError }
                </div>
            </ul>
        </div>
    );
}
