import React, { useCallback, useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

import { iconButton } from 'app/legacy/widgets/button';
import {
    formatDate, PLOTLY_DEFAULT_CONFIG, PLOTLY_DEFAULT_LAYOUT, PLOTLY_DEFAULT_STYLE, TourState,
} from 'app/tour-report/TourReportConstants';
import { TourReportState } from 'app/tour-report/model/TourReportState';
import { ChartData, ChartState } from 'app/tour-report/model/ChartData';
import { Box } from 'app/BoxWorld';

type Props = TourReportState['daysChart'] & {
    handleOnClick: (dateFrom: Date, dateTo: Date, tourState: TourState) => void;
    handleBackButton: () => void;
};

export function DaysChart(props: Props) {
    const { dateFrom, dateTo, week, tourReports, handleOnClick, handleBackButton } = props;

    const [state, setState] = useState<ChartState>({
        timecViolations: null,
        incidentsTotal: null,
        incorrectTotal: null,
        correctTotal: null,
    });

    useEffect(() => {
        const timecViolations = new ChartData(TourState.TIME_CONSTRAINT);
        const incidentsTotal = new ChartData(TourState.INCIDENTS);
        const incorrectTotal = new ChartData(TourState.INCORRECT);
        const correctTotal = new ChartData(TourState.CORRECT);

        for (const dayData of tourReports) {
            const total = (dayData.timec_violations ?? 0)
                + (dayData.incidents_total ?? 0)
                + (dayData.incorrect_total ?? 0)
                + (dayData.correct_total ?? 0);

            const label = `${formatDate(new Date(dayData.day))} <sub>(Gesamt ${total})</sub>`;

            timecViolations.x.push(label);
            timecViolations.y.push(dayData.timec_violations);
            timecViolations.text.push(dayData.timec_violations);

            incidentsTotal.x.push(label);
            incidentsTotal.y.push(dayData.incidents_total);
            incidentsTotal.text.push(dayData.incidents_total);

            incorrectTotal.x.push(label);
            incorrectTotal.y.push(dayData.incorrect_total);
            incorrectTotal.text.push(dayData.incorrect_total);

            correctTotal.x.push(label);
            correctTotal.y.push(dayData.correct_total);
            correctTotal.text.push(dayData.correct_total);
        }

        setState({
            timecViolations,
            incidentsTotal,
            incorrectTotal,
            correctTotal,
        });
    }, [tourReports]);

    const clickHandler = useCallback((e) => {
        const selectedData = tourReports[e.points[0].pointIndex];
        const tourState = e.points[0].data.name;

        const begin = new Date(selectedData.day);
        begin.setHours(0, 0, 0, 0);

        const end = new Date(selectedData.day);
        end.setHours(23, 59, 59, 999);

        handleOnClick(begin, end, tourState);
    }, [handleOnClick, tourReports]);

    const backButton = iconButton({
        id: 'back-button',
        class: 'button-back',
        click: handleBackButton,
    }, 'Zurück zur Wochenansicht');

    return (
        <>
            <h2>
                { `Erfolgreiche und fehlerhafte Rundgänge im Zeitraum ${formatDate(dateFrom)} - ${formatDate(dateTo)}` }
                { week ? ` (${week})` : '' }
            </h2>
            <div className="whiteboard">
                <Box>{backButton}</Box>
                { tourReports.length ? (
                    <div>
                        <Plot
                            onClick={clickHandler}
                            data={[
                                state.timecViolations,
                                state.incidentsTotal,
                                state.incorrectTotal,
                                state.correctTotal,
                            ]}
                            layout={
                                {
                                    ...PLOTLY_DEFAULT_LAYOUT,
                                    hovermode: 'closest',
                                }
                            }
                            style={{
                                ...PLOTLY_DEFAULT_STYLE,
                            }}
                            config={{
                                ...PLOTLY_DEFAULT_CONFIG,
                            }}
                            useResizeHandler
                        />
                    </div>
                ) : <div className="loading" />}
            </div>
        </>
    );
}
