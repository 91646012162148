/**
 * Generic Data Access Object.
 *
 * Provides generic CRUD functionality.
 *
 * Does not work out of the box, you need to subclass it.
 *
 *
 * Usage:
 *   function TicketDao() {
 *       rise.Dao.call(this); // call parent constructor+
 *   }
 *
 *   $.extend(TicketDao.prototype, Object.create(rise.Dao.prototype));
 *   TicketDao.prototype.model_class = app.model.Ticket;
 *   TicketDao.prototype.rpc = new Rpc('/rpc/ticket');
 *
 *
 * A subclass needs to set the following properties:
 * - model_class: Class of the model object for the Dao
 * - rpc: instance of an {@link rise.Rpc} Endpoint Wrapper
 *
 *
 * The following properties can be defined optionally:
 * - primary_key_column: name of the id column, used to check whether an update contains a key
 * - find_all_method: name of the method to fetch all instances
 * - get_method: name of the method to fetch a single instance by id
 * - insert_method: name of the method to persist an instance
 * - update_method: name of the method to update an instance
 * - remove_method: name of the method to remove an instance by id
 *
 *
 * @module rise.Dao
 * @author Matej Kosco
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
export const Dao = (function(rise) {
    "use strict";

    /*** IMPORTS ***/
    var log = rise.logging.getLogger("dao");

    /**
     * Default constructor
     *
     * @param {Object} model_class model class for this dao
     * @param {rise.Rpc} rpc instance of the rpc endpoint
     * @param {Object} model_class_request optional request model class for this dao
     * @constructor
     */
    rise.Dao = function Dao(model_class, rpc, model_class_request) {
        if (!model_class) {
            throw "A valid 'model_class' needs to be defined";
        }

        /*if (!getFunctionName(model_class)) {
            throw "The model class seems to be an anonymous function. Be sure that 'model.name' is properly set";
        }*/

        if (!rpc) {
            throw "RPC is not a valid instance!";
        }

        this.model_class = model_class;
        this.model_class_request = model_class_request ?? model_class;
        this.rpc = rpc;
    };

    /**
     * the model class for instantiating objects
     */
    rise.Dao.prototype.model_class = undefined;
    rise.Dao.prototype.model_class_request = undefined;

    /**
     * field that should be used as a default key for delete operations
     */
    rise.Dao.prototype.primary_key_column = 'id';

    /**
     * the RPC endpoint for this DAO
     * @type {rise.Rpc}
     */
    rise.Dao.prototype.rpc = null;

    /**
     * RPC method to use for fetching all instances of the model class
     * @type {string}
     */
    rise.Dao.prototype.find_all_method = 'find_all';

    /**
     * RPC method to use for fetching a single instances of the model class by its id
     * @type {string}
     */
    rise.Dao.prototype.get_method = 'get';

    /**
     * RPC method to use for persisting a single instances of the model class
     * @type {string}
     */
    rise.Dao.prototype.insert_method = 'insert';

    /**
     * RPC method to use for updating a single instances of the model class
     * @type {string}
     */
    rise.Dao.prototype.update_method = 'update';

    /**
     * RPC method to use for removing a single instances of the model class by its id
     * @type {string}
     */
    rise.Dao.prototype.remove_method = 'remove';

    /**
     * Find all records from the given table. As we receive a list, we call it find
     *
     * The success callback returns a list of model instances of the model class
     * or an empty array if not records exist.
     *
     * @return {jQuery.Deferred} in the done method a list of model instances is passed. List may be empty
     */
    rise.Dao.prototype.findAll = function(params) {
        log.info('Find all %s', getFunctionName(this.model_class));
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke(self.find_all_method, params)
            .done(function(data) {
                if (data.length === 0) {
                    log.info('No %s data sets found for id %s', getFunctionName(self.model_class));
                    deferred.resolve([]);
                } else {
                    log.info('Received %s %s(s)', data.length, getFunctionName(self.model_class));
                    deferred.resolve(data.map(function(d) {
                        return new self.model_class(d);
                    }));
                }
            })
            .fail(function(error) {
                log.error('Fetching all %s failed. %s', getFunctionName(self.model_class), error.message);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Get an Object with the given id from the given table. As we receive an entity or null, we call it get
     *
     * The success callback returns a model instance of the model class
     * or null if no object with the given ID exists.
     *
     * Fails if the select returns multiple objects.
     *
     * @param {number} id
     * @return {jQuery.Deferred} in the done method a object of the model class or null is passed
     */
    rise.Dao.prototype.get = function(id) {
        log.info('Find %s by id', getFunctionName(this.model_class));
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke(self.get_method, {id: id})
            .done(function(data) {
                if (_.isArray(data.length)) {
                    log.error('Got Array as a result, but expected single "%s" entity for id %s', getFunctionName(self.model_class), id);
                    deferred.reject({message: 'Invalid response for find with id ' + id + '!'});
                } else {
                    log.info('Received %s', getFunctionName(self.model_class));
                    deferred.resolve(new self.model_class(data));
                }
            })
            .fail(function(error) {
                log.error('Get %s by id failed. %s', getFunctionName(self.model_class), error.message);
                deferred.reject(error);
            });

        return deferred.promise();
    };


    /**
     * Persists the given model instance
     *
     * The success callback returns the persisted model instance with
     * the generated id
     *
     * Fails it model instance is not an instance of the model class
     * or if the id parameter of the model instance is already set
     *
     * @param {Object} model_instance
     * @returns {jQuery.Deferred} in the done method a inserted object is returned with the ID set
     */
    rise.Dao.prototype.insert = function(model_instance) {
        log.info('Persist %s', getFunctionName(this.model_class));
        var deferred = new $.Deferred();
        var self = this;

        if (model_instance.id) {
            log.error('Cannot insert object. ID already set');
            deferred.reject({message: 'Parameter id must no be set'});
            return deferred.promise();
        }

        if (model_instance instanceof self.model_class_request === false) {
            log.error('Cannot insert object. Not an instance of the right model class "%s"', getFunctionName(self.model_class_request));
            deferred.reject({message: sprintf('Object is not an instance of the right class "%s"', getFunctionName(self.model_class_request))});
            return deferred.promise();
        }

        self.rpc.invoke(self.insert_method, {document: sanitizeObject(model_instance)})
            .done(function(persisted_entity) {
                log.info('%s inserted', getFunctionName(self.model_class));

                //re-add the removed fields by `sanitizeObject`
                deferred.resolve(new self.model_class(
                    $.extend({}, model_instance, persisted_entity)
                ));
                // deferred.resolve(new self.model_class(persisted_entity));
            })
            .fail(function(error) {
                log.error('Persisting %s failed. %s', getFunctionName(self.model_class), error.message);
                deferred.reject(error);
            });

        return deferred.promise();
    };


    /**
     * Updates the given model object
     *
     * The success callback returns the updated model instance
     *
     * Fails it model instance is not an instance of the model class
     * or if the id parameter of the model instance is not set
     *
     * @param {Object} model_instance
     * @return {jQuery.Deferred} in the done method a updated object is returned
     */
    rise.Dao.prototype.update = function(model_instance) {
        log.info('Update %s', getFunctionName(this.model_class));
        var deferred = new $.Deferred();
        var self = this;

        if (!model_instance[self.primary_key_column]) {
            log.error('Cannot update object. Primary Key is not set');
            deferred.reject({message: 'Primary key must be defined'});
            return deferred.promise();
        }

        if (model_instance instanceof self.model_class_request === false) {
            log.error('Cannot update object. Not an instance of the right model class "%s"', getFunctionName(self.model_class_request));
            deferred.reject({message: sprintf('Object is not an instance of the right class "%s"', getFunctionName(self.model_class_request))});
            return deferred.promise();
        }

        self.rpc.invoke(self.update_method, {document: sanitizeObject(model_instance)})
            .done(function(persisted_entity) {
                log.info('%s updated', getFunctionName(self.model_class));

                //re-add the removed fields by `sanitizeObject`
                deferred.resolve(new self.model_class(
                    $.extend({}, model_instance, persisted_entity)
                ));
            })
            .fail(function(error) {
                log.error('Updating %s failed. %s', getFunctionName(self.model_class), error.message);
                deferred.reject(error);
            });

        return deferred.promise();
    };


    /**
     * Removes the object with the given id
     *
     * The success callback returns the removed model instance
     *
     * @param {number} id
     * @return {jQuery.Deferred} in the done method the removed object is passed
     */
    rise.Dao.prototype.remove = function(id) {
        log.info('Remove %s', getFunctionName(this.model_class));
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke(self.remove_method, {id: id})
            .done(function(removed_entity) {
                log.info('%s removed', getFunctionName(self.model_class));
                deferred.resolve(new self.model_class(removed_entity));
            })
            .fail(function(error) {
                log.error('Removing %s failed. %s', getFunctionName(self.model_class), error.message);
                deferred.reject(error);
            });

        return deferred.promise();
    };


    var sanitizeObject = rise.Dao.sanitizeObject = function(obj) {
        var clone = new obj.constructor(obj);

        $.each(clone, function(name) {
            if (name.indexOf("_") === 0) {
                delete clone[name];
            }
        });

        return clone;
    };


    /**
     * Helper method for looking up function name in IE compatible fashion.
     *
     * @param func The function handler
     */
    function getFunctionName(func) {
        if (func.name) {
            return func.name;
        } else {
            return func.toString().match(/^function\s*([^\s(]+)/);
        }
    }

    return rise.Dao;

})(window.rise);
