/* eslint-disable camelcase */
export class TourReport {
    incomplete: number;

    auto_ended: number;

    durationc_violated: number;

    incorrect_total: number;

    correct_total: number;

    incidents_total: number;

    timec_violations: number;

    constructor(data: any) {
        this.incomplete = data.incomplete;
        this.auto_ended = data.auto_ended;
        this.durationc_violated = data.durationc_violated;
        this.incorrect_total = data.incorrect_total;
        this.correct_total = data.correct_total;
        this.incidents_total = data.incidents_total;
        this.timec_violations = data.timec_violations;
    }
}
