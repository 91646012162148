/**
 * Data Access Object for the object endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.object");

    /*** Export Dao ***/
    app.dao.objectDao = new ObjectDao();

    /**
     * @constructor
     */
    function ObjectDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.Object, new Rpc('/rpc/object'));
    }

    // extend the parent Dao prototype
    $.extend(ObjectDao.prototype, Object.create(rise.Dao.prototype));

    ObjectDao.prototype.findByWorkplace = function(workplaceId) {
        var deferred = new $.Deferred();

        log.info('Find all objects that are observed by the workplace with with id ' + workplaceId);

        this.rpc.invoke("find_by_workplace", {'workplace_id': workplaceId})
            .done(function(objects) {
                deferred.resolve(_.map(objects, function(json) {
                    return new app.model.Object(json);
                }));
            })
            .fail(function (error) {
                log.error('List of objects cannot be retrieved. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

    ObjectDao.prototype.findObservableObjects = function(objectId) {
        var deferred = new $.Deferred();

        log.info('Find all objects that are observable by the workplace with with id ' + objectId);

        this.rpc.invoke("find_observable_objects", {'object_id': objectId})
            .done(function(objects) {
                deferred.resolve(_.map(objects, function(json) {
                    return new app.model.Object(json);
                }));
            })
            .fail(function (error) {
                log.error('List of objects cannot be retrieved. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Get a particular object
     */
    ObjectDao.prototype.getObjectDetails = function(object_id) {
        var deferred = new $.Deferred();

        this.rpc.invoke('get_object_details', { 'id':object_id })
            .done(function(json) {
                deferred.resolve( new app.dto.ObjectDetailsDto(json) );
            })
            .fail(function(error) {
                log.error('Unable to retrieve object failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Get a particular object
     */
    ObjectDao.prototype.getObjectAssignments = function(object_id) {
        var deferred = new $.Deferred();

        this.rpc.invoke('get_object_details', { 'id':object_id })
            .done(function(json) {
                deferred.resolve( new app.dto.ObjectAssignmentsDto(json) );
            })
            .fail(function(error) {
                log.error('Unable to retrieve object failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Insert object and assignments
     */
    ObjectDao.prototype.insertObjectAndAssignments = function(object, assignedEventTypes, assignedBusinessDocuments) {
        var deferred = new $.Deferred();

        this.rpc.invoke('insert_object_and_assignments',
            { object:object, event_type_ids:assignedEventTypes, business_document_ids:assignedBusinessDocuments })
            .done(function(json) {
                var result = new app.model.Object(json);
                deferred.resolve(result);
            })
            .fail(function(error) {
                log.error('Inserting object failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Update object and assignments
     */
    ObjectDao.prototype.updateObjectAndAssignments = function(object, assignedEventTypes, assignedBusinessDocuments) {
        var deferred = new $.Deferred();

        this.rpc.invoke('update_object_and_assignments',
            { object:object, event_type_ids:assignedEventTypes, business_document_ids:assignedBusinessDocuments })
            .done(function(json) {
                var result = new app.model.Object(json);
                deferred.resolve(result);
            })
            .fail(function(error) {
                log.error('Finding object failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

})(window.rise, window.app);
