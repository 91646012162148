/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
export class TourArea extends (function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.TourArea = function TourArea(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id != null ? obj.id : null;
        this.name = obj.name != null ? obj.name : null;
        this.description = obj.description != null ? obj.description : null;
        this.active = obj.active != null ? obj.active : null;

        /**
         * set on server side
         * @type {JustDate}
         */
        this.created_at = obj.created_at ?
            typeof obj.created_at === 'string' ?
                Date.fromISO(obj.created_at)
                : obj.created_at
            : null;
        this.created_by = obj.created_by != null ? parseInt(obj.created_by, 10) : null;
    };

    app.model.TourAreaAssignments = function TourAreaAssignments(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.tour_area_timecs = _.map(obj.tour_area_timecs, function (tour_area_timec) {
            return new app.model.TourAreaTimeC(tour_area_timec);
        });
    };

    app.model.TourAreaTimeC = function TourAreaTimeC(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.tour_area_id = obj.tour_area_id != null ? parseInt(obj.tour_area_id, 10) : null;
        this.tour_timec_id = obj.tour_timec_id != null ? parseInt(obj.tour_timec_id, 10) : null;
        this.note = obj.note != null ? obj.note : null;
    };

    return app.model.TourArea;
}(window.app)) {

    id;

    constructor(json) {
        super(json);
    }
}
