/**
 * This is the dto for the logbook entry
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    app.dto.TaskLogbookEntryListDto = function TaskLogbookEntryListDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.rows = obj.rows != null ? _.map(obj.rows, function(d) { return new app.dto.TaskLogbookEntryDto(d); }) : null;
        this.totalCount = obj.total_count != null ? parseInt(obj.total_count) : null;
        this.filteredCount = obj.filtered_count != null ? parseInt(obj.filtered_count) : null;
    };

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.dto.TaskLogbookEntryDto = function TaskLogbookEntryDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.time_logged =  obj.time_logged ?
            typeof obj.time_logged === 'string' ?
                Date.fromISO(obj.time_logged)
                : obj.time_logged
            : null;

        this.time_entered = obj.time_entered ?
            typeof obj.time_entered === 'string' ?
                Date.fromISO(obj.time_entered)
                : obj.time_entered
            :null;

        this.id = obj.id != null ? parseInt(obj.id) : null;
        this.workplace_name = obj.workplace_name != null ? obj.workplace_name : null;
        this.object_name = obj.object_name != null ? obj.object_name : null;
        this.location_name = obj.location_name != null ? obj.location_name : null;
        this.customer_name = obj.customer_name != null ? obj.customer_name : null;

        this.assigned_to = obj.assigned_to != null ? obj.assigned_to : null;
        this.created_by_user_name = obj.created_by_user_name != null ? obj.created_by_user_name : null;
        this.logged_user_name  = obj.logged_user_name != null ? obj.logged_user_name : null;
        this.note_count = obj.note_count != null ? parseInt(obj.note_count, 10) : null;
        this.task_id  = obj.task_id != null ? parseInt(obj.task_id) : null;
        this.task_label  = obj.task_label != null ? obj.task_label : null;
        this.task_rotation_type  = obj.task_rotation_type != null ? obj.task_rotation_type : null;

        this.read_count = obj.read_count != null ? parseInt(obj.read_count, 10) : 0;
    };

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.dto.TaskLogbookEntryDetailsDto = function TaskLogbookEntryDetailsDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.task_logbook_entry = new app.model.TaskLogbookEntry(obj.task_logbook_entry || null);
        this.task_logbook_entry_read_log = _.map(obj.task_logbook_entry_read_log,
            function(logItem) {
                return new app.dto.TaskLogbookEntryReadLogListItemDto(logItem || null);
            });
        this.logged_user = new app.dto.UserListDto(obj.logged_user || null);
        this.task = new app.dto.TaskDetailsDto(obj.task || null);
    };

    app.dto.TaskLogbookEntryReadLogListItemDto = function TaskLogbookEntryReadLogListItemDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.user_id = obj.user_id ? parseInt(obj.user_id, 10) : null;
        this.user_last_name = obj.user_last_name ? obj.user_last_name : null;
        this.user_first_name = obj.user_first_name != null ? obj.user_first_name : null;
        this.user_name = obj.user_name != null ? obj.user_name : null;

        this.task_logbook_entry_read_first_at = obj.task_logbook_entry_read_first_at ?
            typeof obj.task_logbook_entry_read_first_at === 'string' ?
                Date.fromISO(obj.task_logbook_entry_read_first_at)
                : obj.task_logbook_entry_read_first_at
            : null;
    };

}(window.app));
