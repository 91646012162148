/**
 * A small date implementation which does not have any time information
 *
 * This type is meant to hold a date information as it would usually be used in Python with datetime.date.
 * Main purpose is to cope with difficulties to get steady date information of the build in date type as
 * the timezone information is interfering. Also the JSON representation should not contain any time.
 *
 * @module JustDate
 * @author Michael Pickelbauer
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */

export const JustDate = (function () {
    "use strict";

    /**
     * The constructor accepts different parameters, similar to the original Date
     *
     *  * Number - An UNIX time stamp, where only the date information is kept
     *  * String - Date represented in a string. Accepts all formats as the original Date
     *  * Date - A date object itself. The time information is removed
     *  * [year], [month], [day] - Three numbers as FullYear (0000 - 9999), Month (0-11), Day(0-31)
     *
     * @param {Number|Date|String} d
     * @constructor
     */
    window.JustDate = function JustDate(d) {
        var tempDate = null;

        if (arguments.length === 0) {
            tempDate = new Date();
        } else if (arguments.length === 1) {
            tempDate = new Date(d);
        } else if (arguments.length === 3 ) {
            tempDate = new Date(arguments[0], arguments[1], arguments[2])
        } else {
            throw "Invalid number of arguments! Only 0, 1 or 3 are accepted. Checkout the docs";
        }

        this.year = tempDate.getFullYear();
        this.month = tempDate.getMonth();
        this.day = tempDate.getDate();
    };

    window.JustDate.prototype.addDays = function(days) {
        var date = this.toDate();
        date.addDays(days);

        this.year = date.getFullYear();
        this.month = date.getMonth();
        this.day = date.getDate();

        return this;
    };

    window.JustDate.prototype.setYear = function(fullYear) {
        this.year = fullYear;
        return this;
    };
    window.JustDate.prototype.getYear = function() {
        return this.year;
    };

    window.JustDate.prototype.setMonth = function(month) {
        if(month < 0 || month > 11) {
            throw "Impossible value for month: " + month;
        }
        return this;
    };
    window.JustDate.prototype.getMonth = function() {
        return this.month;
    };

    window.JustDate.prototype.setDay = function(day) {
        if(day < 0 || day > 31) {
            throw "Impossible value for day: " + day;
        }
        return this;
    };
    window.JustDate.prototype.getDay = function() {
        return this.day;
    };

    window.JustDate.prototype.toDateString = function() {
        window.JustDate.toDateString(this);
    };

    window.JustDate.toDateString = function(d) {
        if( Date.prototype._toDateString ) {
            return (new Date(d.year, d.month, d.day))._toDateString();
        } else {
            return new Date(d.year, d.month, d.day).toDateString();
        }
    };

    window.JustDate.prototype.toString = function() {
        return this.toISOString();
    };

    window.JustDate.prototype.toDate = function() {
        return new Date(this.year, this.month, this.day);
    };

    window.JustDate.prototype.toUTCDate = function() {
        return new Date(Date.UTC(this.year, this.month, this.day));
    };

    window.JustDate.prototype.toJSON = function() {
        return this.toISOString();
    };

    window.JustDate.prototype.toISOString = function() {
        return window.JustDate.formatISOString(this);
    };

    window.JustDate.formatISOString = function(d) {
        var sYear = ("0000" + d.year).slice(-4);
        var sMonth = ("0" + (d.month+1)).slice(-2);
        var sDay = ("0" + d.day).slice(-2);

        return sYear + "-" + sMonth + "-" + sDay;
    };

    window.JustDate.prototype.valueOf = function() {
        return this.toDate().valueOf();
    };

    /**
     * function to parse a date with a specific format
     *
     * @param {String}  dateString  date as a string
     * @param {String}  format      format of the string
     * @return {JustDate}
     */
    window.JustDate.parseDateFormat = function(dateString, format) {
        //TODO check if the format does not contain any hour formats
        var d = Date.parseFormat(dateString, format);

        return new JustDate(d);
    };


    window.JustDate.fromISO = function(dateIsoString) {
        return new JustDate(Date.parseFormat(dateIsoString, 'YYYY-MM-DD'));
    };

    window.JustDate.parse = function(dateString) {
        return new JustDate(Date.parse(dateString));
    };

    window.JustDate.today = function() {
        return new JustDate(new Date());
    };

    /**
     * Shadows the date specific methods of the Date object and replaces them with a method which throws an
     * exception with a hint that probably the "JustDate" type should be used.
     *
     * The original methods will be prepended with an "_".
     */
    window.JustDate.shadowDateMethods = function() {
        if( !Date.prototype._toDateString ) {
            Date.prototype._toDateString = Date.prototype.toDateString;
            Date.prototype.toDateString = function() {
                throw "Date.toDateString was shadowed! You should probably use the JustDate type";
            };

            Date.prototype._toLocaleDateString = Date.prototype.toLocaleDateString;
            Date.prototype.toLocaleDateString = function() {
                throw "Date.toLocaleDateString was shadowed! You should probably use the JustDate type";
            };
        }
    };

    /**
     * Un-shadows the shadowed methods of the date type and restores the functionality of Date as it was before
     */
    window.JustDate.unshadowDateMethods = function() {
        if( Date.prototype._toDateString ) {
            Date.prototype.toDateString = Date.prototype._toDateString;
            Date.prototype._toDateString = undefined;

            Date.prototype.toLocaleDateString = Date.prototype._toLocaleDateString;
            Date.prototype._toLocaleDateString = undefined;
        }
    };

    return window.JustDate;
})();
