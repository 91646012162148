/**
 * @module app.model
 * @license Copyright 2017 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    app.dto.BusinessDocumentCategoryListDto = function BusinessDocumentCategoryListDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id ? parseInt(obj.id, 10) : null;
        this.name = obj.name ? obj.name : null;
        this.assigned_documents = obj.assigned_documents ? parseInt(obj.assigned_documents, 10) : 0
    };


}(window.app));
