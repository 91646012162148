/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.Document = function Document(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id =               obj.id != null ? parseInt(obj.id) : null;

        this.filename =         obj.filename != null ? obj.filename : null;
        this.data =             obj.data != null ? obj.data : null;

        /**
         * set on server side
         * @type {JustDate}
         */
        this.created_at =       obj.created_at ?
            typeof obj.created_at === 'string' ?
                Date.fromISO(obj.created_at)
                : obj.created_at
            : null;
        /**
         * set on server side
         * @type {Number}
         */
        this.created_by = obj.created_by != null ? parseInt(obj.created_by) : null;
        this.description = obj.description != null ? obj.description : null;
    };

}(window.app));
