/**
 * Data Access Object for the logbook-entry endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;

    /*** Export Dao ***/
    app.dao.logbookEntryNoteDao = new LogbookEntryNoteDao();

    /**
     * @constructor
     */
    function LogbookEntryNoteDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.LogbookEntryNote, new Rpc('/rpc/logbook-entry-note'));
    }

    // extend the parent Dao prototype
    $.extend(LogbookEntryNoteDao.prototype, Object.create(rise.Dao.prototype));


})(window.rise, window.app);
