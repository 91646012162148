/* Import TinyMCE */
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

import tinymce from 'tinymce';

/* Default icons are required for TinyMCE 5.3 or above */
import 'tinymce/icons/default';

/* A theme is also required */
import 'tinymce/themes/silver';

/* Import the skin */
import 'tinymce/skins/ui/oxide/skin.css';

/* Import default tinyMce model */
import 'tinymce/models/dom';

/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/lists';

type Props = {
    text: string;
    readonly: boolean;
    onChange: (ReportSchedule) => void;
};

export function RichTextEditor(props: Props) {
    const { text, readonly, onChange } = props;

    return (
        <>
            <div style={{ display: 'none' }}>
                {/* Workaround to keep tinymce as a global variable - DO NOT REMOVE */}
                {`TinyMCE ${tinymce.majorVersion}.${tinymce.minorVersion}`}
            </div>
            <Editor
                value={text}
                disabled={readonly}
                init={{
                    height: 300,
                    width: 850,
                    menubar: false,
                    plugins: ['advlist', 'lists'],
                    toolbar: `undo redo | blocks | bold italic backcolor | alignleft aligncenter
                             alignright alignjustify | bullist numlist outdent indent | removeformat`,
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    content_css: false,
                    skin: false,
                }}
                onEditorChange={(value) => {
                    if (text !== value) {
                        onChange({
                            mail_text: value,
                        });
                    }
                }}
            />
        </>
    );
}
