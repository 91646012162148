/**
 * Application session handling.
 *
 * This includes login, logout, storing the session data (including the
 * session-id) and setting up the JSON-RPC service wrapper.
 *
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /** EXPORT **/
    var session = app.session = {};

    /** IMPORT **/
    var Rpc = rise.Rpc;
    var SessionInfo = app.model.SessionInfo;

    /** VARIABLES **/
    var log = rise.logging.getLogger('session');
    var rpc = new Rpc('/rpc/auth');
    var cfg = {
        loginCallback: function() {},
        logoutCallback: function() {}
    };


    session.setConfig = function(config) {
        if(config.loginCallback !== undefined && !_.isFunction(config.loginCallback)) {
            throw "Wrong config parameter for session.setConfig: 'loginCallback' is not a valid method";
        }

        if(config.loginCallback !== undefined && !_.isFunction(config.logoutCallback)) {
            throw "Wrong config parameter for session.setConfig: 'logoutCallback' is not a valid method";
        }

        cfg = $.extend(cfg, config);
    };

    /**
     * Makes the application login. This function calls the 'auth' service
     * with the login credentials. On success, the session data is saved
     * and the Rpc config is updated with the new session-id.
     * @param {String} username Username string
     * @param {String} password Password string (optional hashed or encrypted).
     */
    session.login = function login(username, password) {
        var deferred = $.Deferred();

        rpc.invoke('auth', {
            'username': username,
            'password': $.sha256(password)
        })
            .done(function(data) {
                session.saveSessionInfo(data);
                session.setRpcHeader(data);
                deferred.resolve("ok");
                cfg.loginCallback();
                location.reload();
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Makes the application logout. This function calls the `release` service
     * to close the current session. On success, the session data is flushed
     * and the Rpc config is updated.
     * @param {Boolean} [noRelease] Boolean indicating if the `release` service
     *                       should be called. This would be counterproductive
     *                       if the session has already timed out server-side.
     */
    session.logout = function logout(noRelease) {
        var deferred = $.Deferred();

        rise.Navigation.reset();

        if (noRelease) {
            sessionStorage.clear();
            session.setRpcHeader();
            deferred.resolve("ok");
            cfg.logoutCallback();
        } else {
            rpc.invoke('release')
                .done(function() {
                    sessionStorage.clear();
                    session.setRpcHeader();
                    deferred.resolve("ok");
                    cfg.logoutCallback();

                    // Do not expose information of the previously logged in
                    // user at login screen.
                    app.removeInfoMessages();
                    app.removeWarningMessages();
                    app.removeSuccessMessages();
                })
                .fail(function(error) {
                    deferred.reject(error);
                });
        }

        return deferred.promise();
    };

    /**
     * Refreshes session info
     */
    session.refreshSessionInfo = function refreshSessionInfo() {
        var deferred = $.Deferred();

        rpc.invoke('get_session_info', {})
            .done(function(data) {
                session.saveSessionInfo(data);
                session.setRpcHeader(data);
                deferred.resolve("ok");
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };


    /**
     * Check if an active session exists.
     * @return {Boolean} True if a session exists, otherwise false.
     */
    session.hasActiveSession = function hasActiveSession() {
        return !!session.getSessionInfo();
    };

    /**
     * Updates the idle time of the current session.
     */
    session.updateIdleTime = function updateIdleTime() {
        var sessionInfo = session.getSessionInfo();
        if (sessionInfo) {
            sessionInfo.update();
            sessionInfo.saveSessionInfo(sessionInfo);
        }
    };

    /**
     * Updates the sessionId property for the Rpc config. The
     * sessionId property is set to `undefined` if no session exists.
     * @param {*} [sessionInfo] Optional session info object.
     */
    session.setRpcHeader = function setRpcHeader(sessionInfo) {
        var obj = sessionInfo || session.getSessionInfo();
        Rpc.setConfig({sessionId: obj ? obj.session_id : undefined});
    };

    /**
     * Get the session data of the current session.
     * @return {*} SessionInfo object.
     */
    session.getSessionInfo = function getSessionInfo() {
        try {
            var val = sessionStorage.getItem("sessionInfo");
            if (val !== null) {
                return new SessionInfo(sessionStorage.getItem("sessionInfo"));
            } else {
                return undefined;
            }
        } catch (e) {
            log.error("Error get 'session' from sessionStorage: " + e);
        }
    };

    /**
     * Saves the sessionInfo data to the DOM storage.
     * @param sessionInfo SessionInfo object.
     */
    session.saveSessionInfo = function saveSessionInfo(sessionInfo) {
        try {
            sessionStorage.setItem("sessionInfo", JSON.stringify(sessionInfo));
            log.debug("Saved 'sessionInfo' object to sessionStorage");
        } catch (e) {
            log.error("Error save 'sessionInfo' to sessionStorage: " + e);
        }
    };

    /**
     * Convenience wrapper for @see SessionInfo.hasPermission
     * @param permission Permission identifier
     * @returns {boolean} True if permission is set, false otherwise
     */
    session.hasPermission = function(permission) {
        var sessionInfo = session.getSessionInfo();

        if (sessionInfo.password_reset_required) {
            return false;
        }

        return sessionInfo.hasPermission(permission);
    };

    /**
     * Returns the session_id of the current session
     * @returns {String} SessionId
     */
    session.getSessionId = function() {
        return session.getSessionInfo().session_id;
    };

    session.hasActiveShift = function() {
        return session.getSessionInfo().shift !== null;
    };

}(window.rise, window.app));

export const hasPermissionLegacy = app.session.hasPermission;
