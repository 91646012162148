/**
 * This is the dto for the logbook entry
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    app.dto.WorkingHoursListDto = function WorkingHoursListDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.rows = obj.rows != null ? _.map(obj.rows, function(d) { return new app.dto.WorkingHoursListItemDto(d); }) : null;
        this.totalCount = obj.total_count != null ? parseInt(obj.total_count) : null;
        this.filteredCount = obj.filtered_count != null ? parseInt(obj.filtered_count) : null;
    };

    app.dto.LogbookEntryListDto = function LogbookEntryListDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.rows = obj.rows != null ? _.map(obj.rows, function(d) { return new app.dto.LogbookEntryListItemDto(d); }) : null;
        this.totalCount = obj.total_count != null ? parseInt(obj.total_count) : null;
        this.filteredCount = obj.filtered_count != null ? parseInt(obj.filtered_count) : null;
    };

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.dto.LogbookEntryListItemDto = function LogbookEntryListItemDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id != null ? obj.id : null;
        this.entry_type = obj.entry_type ? obj.entry_type : null;
        this.time_logged =  obj.time_logged ?
            typeof obj.time_logged === 'string' ?
                Date.fromISO(obj.time_logged)
                : obj.time_logged
            : null;

        this.time_entered = obj.time_entered ?
            typeof obj.time_entered === 'string' ?
                Date.fromISO(obj.time_entered)
                : obj.time_entered
            : null;
        this.description =  obj.description != null ? obj.description : null;
        this.shift_is_start = obj.shift_is_start != null ? obj.shift_is_start === true : null;
        this.event_type_label = obj.event_type_label != null ? obj.event_type_label : null;
        this.note_count = obj.note_count != null ? parseInt(obj.note_count) : null;
        this.user_name = obj.user_name != null ? obj.user_name : null;

        this.workplace_name = obj.workplace_name != null ? obj.workplace_name : null;
        this.object_name = obj.object_name != null ? obj.object_name : null;
        this.location_name = obj.location_name != null ? obj.location_name : null;
        this.customer_name = obj.customer_name != null ? obj.customer_name : null;

    };

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.dto.WorkingHoursListItemDto = function WorkingHoursListItemDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.shift_id = obj.shift_id != null ? obj.id : null;
        this.shift_begin =  obj.shift_begin ?
            typeof obj.shift_begin === 'string' ?
                Date.fromISO(obj.shift_begin)
                : obj.shift_begin
            : null;

        this.shift_end = obj.shift_end ?
            typeof obj.shift_end === 'string' ?
                Date.fromISO(obj.shift_end)
                : obj.shift_end
            : null;

        this.customer_name =  obj.customer_name != null ? obj.customer_name : null;
        this.location_name =  obj.location_name != null ? obj.location_name : null;
        this.object_name =  obj.object_name != null ? obj.object_name : null;
        this.workplace_name =  obj.workplace_name != null ? obj.workplace_name : null;
        this.employee_name =  obj.employee_name != null ? obj.employee_name : null;
        this.working_time = obj.working_time != null ? parseInt(obj.working_time, 10) : null;

    };

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.dto.LogbookEntryDetailsDto = function LogbookEntryDetailsDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.logbook_entry = obj.logbook_entry != null ?
            new app.model.LogbookEntry(obj.logbook_entry)
            : null;

        this.shift = obj.Shift != null ? new app.model.Shift(obj.shift) : null;
        this.event_type  = obj.event_type != null ? new app.model.EventType(obj.event_type) : null;
        this.event_fields = obj.logbook_entry != null ?
            _.map(obj.logbook_entry.event_fields || [], function(event_field) { return app.model.EventField(event_field); }) : null;

        this.user_first_name = obj.user_first_name != null ? obj.user_first_name : null;
        this.user_last_name = obj.user_last_name != null ? obj.user_last_name : null;

        this.workplace_id = obj.workplace_id != null ? obj.workplace_id : null;
        this.workplace_name = obj.workplace_name != null ? obj.workplace_name : null;

        this.object_id = obj.object_id != null ? obj.object_id : null;
        this.object_name = obj.object_name != null ? obj.object_name : null;

        this.location_id = obj.location_id != null ? obj.location_id : null;
        this.location_name = obj.location_name != null ? obj.location_name : null;

        this.customer_id = obj.customer_id != null ? obj.customer_id : null;
        this.customer_name = obj.customer_name != null ? obj.customer_name : null;

        this.client_ip =  obj.client_ip != null ? obj.client_ip : null;

    };

}(window.app));
