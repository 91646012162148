/**
 * The map widget.
 *
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /** Imports **/
    var google                  = window.google;
    var printIconButton         = app.widgets.printIconButton;
    var playIconButton          = app.widgets.playIconButton;
    var stopIconButton          = app.widgets.stopIconButton;
    var pauseIconButton         = app.widgets.pauseIconButton;
    var rewindIconButton        = app.widgets.rewindIconButton;
    var fastForwardIconButton   = app.widgets.fastForwardIconButton;

    var checkpointType          = app.dto.TourCheckpointType;

    /** Globals */
    var ANIMATION_INTERVAL = 50;
    var MIN_STEP_SIZE = 0.001;
    var PRINT_OPTIONS = {
        disableDefaultUI: false,
        showPrintButton: false,
        showAnimateButton: false
    };

    /*FIXME: CLEAN UP THE CODE!!! This is a mess*/

    app.widgets.Map = function($parent, options, children) {
        this.$parent = $parent;

        // list of rendered markers
        this.markers = [];
        this.stashedMarkers = [];

        // list of rendered polylines
        this.paths = [];
        this.stashedPaths = [];

        // list of rendered info windows
        this.infoWindows = [];
        this.stashedInfoWindows = [];

        // flag indicating of path animation is currently running
        this.pathAnimationStarted = false;

        // init path animation speed factor
        this.pathAnimationSpeed = 1;

        // list of additional map header entries
        this.children = children;

        this.options = _.extend({
            "mapWrapperClass"               : null,
            "animationStopTimeAtCheckpoint" : 1500,
            "zoom"                          : 14,
            "showPrintButton"               : false,
            "showAnimateButton"             : false,
            "disableDefaultUI"              : false,
            "onClick"                       : null,
            "onMarkerDrag"                  : null,
            "fnGetMarkerPassTime"           : null
        }, options);
    };

    app.widgets.Map.prototype.update = function() {
        google.maps.event.trigger(this.map, 'resize');
        if (this.options.location) {
            this.map.setCenter(this.options.location);
            this.map.setZoom(this.options.zoom);
        } else {
            this.map.fitBounds(this.map.getBounds());
            //this.map.setZoom(this.map.getZoom());
        }
    };

    app.widgets.Map.prototype.setCenter = function(location) {
        this.options.location = location;

        if (this.options.location) {
            this.map.setCenter(self.options.location);
        }
    };

    app.widgets.Map.prototype.render = function (style) {
        var self = this,
            deferred = new $.Deferred(),
            promise = deferred.promise();

        function initializeMap(mapWrapper) {
            // init map
            var mapOptions = _.extend({
                center: self.options.location ? self.options.location : undefined,
                zoom: self.options.zoom,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: self.options.disableDefaultUI
            }, self.options);

            self.map = new google.maps.Map(mapWrapper, mapOptions);

            if (self.options.cluster) {
                self.mc = new window.MarkerClusterer(self.map, [], self.options.cluster);
            }
        }

        function animationFinished() {
            // unstash markers
            self.unstashMarkers();

            // release semaphore
            self.pathAnimationStarted = false;
            self.pathAnimationStopped = false;
            self.pathAnimationRunning = false;

            // show map control buttons
            self.$parent.find(".animate-button, .button-print").show();

            // hide animation control buttons
            self.$parent.find(".stop-button, .pause-button, .ff-button, .rw-button, .animation-speed").hide();

            // clear info text
            self.showGlobalInfo();
        }

        function updateDistance(d) {
            var $distanceInfo = self.$parent.find("#tour-run-distance-info");

            if (d < 1000) {
                $distanceInfo.text("Distanz: " + Math.round(d) + " m");
            } else {
                $distanceInfo.text("Distanz: " + (d/1000).toFixed(2) + " km");
            }
        }

        function updateCheckpoint(check_time) {
            if (!check_time) {
                return;
            }

            var $checkpointInfo = self.$parent.find("#tour-run-checkpoint-info"),
                text = "Letzter Scan: " + check_time.toFormatString(app.settings.dateTimeFormat);

            $checkpointInfo.text(text);
        }

        function startAnimation() {
            if (self.pathAnimationRunning || self.paths.length === 0) {
                // nothing to do
            } else if (self.pathAnimationStarted && !self.pathAnimationRunning) {
                self.pathAnimationRunning = true;
                self.pathAnimationStopped = false;

                // show pause button
                self.$parent.find(".pause-button").show();

                // hide animation control buttons
                self.$parent.find(".animate-button").hide();
            } else {

                // set semaphore
                self.pathAnimationStarted = true;
                self.pathAnimationRunning = true;
                self.pathAnimationStopped = false;

                // hide map control buttons
                self.$parent.find(".animate-button, .button-print").hide();

                // show animation control buttons
                self.$parent.find(".stop-button, .pause-button, .ff-button, .rw-button, .animation-speed").show();

                // render animation info
                self.showGlobalInfo(renderAnimationInfoBox(!!self.options.fnGetMarkerPassTime));

                // stash markers
                self.stashMarkers();

                self.animatePath({
                    'finished': animationFinished,
                    'updateDistance': updateDistance,
                    'updateCheckpoint': updateCheckpoint,
                    'sectionTime': self.options.sectionTime, // time of animation between subsequent checkpoints
                    'stepSize': 100 // given in meter/s
                });
            }
        }

        function pauseAnimation() {
            if (self.pathAnimationStarted) {
                self.pathAnimationRunning = false;
                self.pathAnimationStop = false;

                // hide pause button
                self.$parent.find(".pause-button").hide();

                // show animate buttons
                self.$parent.find(".animate-button").show();
            }
        }

        function stopAnimation() {
            if (self.pathAnimationStarted) {
                self.pathAnimationStopped = true;
            }
        }

        function rwAnimation() {
            if (self.pathAnimationStarted) {
                self.pathAnimationJumpOffset = (self.pathAnimationJumpOffset || 0) - 1;
            }
        }

        function ffAnimation() {
            if (self.pathAnimationStarted) {
                self.pathAnimationJumpOffset = (self.pathAnimationJumpOffset || 0) + 1;
            }
        }

        function renderPlayButton() {
            return playIconButton({
                'class': 'animate-button map-control-button',
                'click': startAnimation
            });
        }

        function renderPauseButton() {
            return pauseIconButton({
                'class': 'pause-button map-control-button',
                'click': pauseAnimation,
                'style': 'display:none;'
            });
        }

        function renderStopButton() {
            return stopIconButton({
                'class': 'stop-button map-control-button',
                'click': stopAnimation,
                'style': 'display:none;'
            });
        }

        function renderRewindButton() {
            return rewindIconButton({
                'class': 'rw-button map-control-button',
                'click': rwAnimation,
                'style': 'display:none;'
            });
        }

        function renderFastForwardButton() {
            return fastForwardIconButton({
                'class': 'ff-button map-control-button',
                'click': ffAnimation,
                'style': 'display:none;'
            });
        }

        function renderSpeedSlider() {
            return span({ "class": "animation-speed", "style": "display:none;" }, [
                span("Geschwindigkeit"),
                div({
                    'class': 'speed-slider',
                    create: function(e) {
                        var $target = $(e.target);

                        $target.slider({
                            animate: "fast",
                            min: 20,
                            max: 300,
                            step: 10,
                            range: false,
                            value: 100,
                            slide: function(e, ui) {
                                self.pathAnimationSpeed = ui.value / 100.0;

                                self.$parent.find('.speed-slider-value')
                                    .text(sprintf("%sx", Math.round(self.pathAnimationSpeed * 10) / 10));
                            }
                        });
                    }
                }),
                span({'class': 'speed-slider-value'}, "1x")
            ]);
        }

        function renderAnimationButtons() {
            return span([
                renderRewindButton(),
                renderPlayButton(),
                renderPauseButton(),
                renderStopButton(),
                renderFastForwardButton(),
                renderSpeedSlider()
            ]);
        }

        function renderPrintButton() {
            return printIconButton({
                "class": "print-button map-control-button",
                "click": $.proxy(self._print, self)
            }, "Drucken");
        }

        self.$parent.appendElement([
            div({ "class": "map-action-buttons" }, [
                self.options.showPrintButton
                    ? renderPrintButton()
                    : undefined,

                self.options.showAnimateButton
                    ? renderAnimationButtons()
                    : undefined,

                renderInfoSection(),

                function($parent) {
                    if (self.children) {
                        $parent.appendElement(self.children);
                    }
                }
            ]),

            div({ "class": self.options.mapWrapperClass, style: style ? style : "",
                create: function(e) {
                    var mapWrapper = e.target;
                    initializeMap(mapWrapper);

                    google.maps.event.addListener(self.map, 'tilesloaded', function() {
                        deferred.resolve();
                    });

                    google.maps.event.addListener(self.map, 'click', function(e) {
                        if (self.options.onClick) {
                            self.options.onClick(self, e);
                        }
                    });

                    google.maps.event.addListener(self.map, 'mouseout', function(e) {
                        if (self.options.onMouseOut) {
                            self.options.onMouseOut(self, e);
                        }
                    });

                    // Workaround to fix the problem, where the tiles are initially
                    // not loaded
                    setTimeout(function() {
                        google.maps.event.trigger(self.map, 'resize');
                    }, 500);
                }})
        ]);

        return promise;
    };

    app.widgets.Map.prototype.stashMarkers = function() {
        var self = this;

        self.stashedMarkers = self.markers;
        self.stashedInfoWindows = self.infoWindows;

        // remove markers from map
        _.each(self.stashedMarkers, function (marker) {
            marker.setMap(null);
        });

        self.markers = [];
    };

    app.widgets.Map.prototype.unstashMarkers = function() {
        var self = this;

        self.markers = self.stashedMarkers;
        self.infoWindows = self.stashedInfoWindows;

        // remove markers from map
        _.each(self.markers, function (marker) {
            marker.setMap(self.map);
        });

        self.stashedMarkers = [];
    };

    app.widgets.Map.prototype.stashPaths = function() {
        var self = this;

        self.stashedPaths = self.paths;

        // remove paths from map
        _.each(self.stashedPaths, function (path) {
            path.setMap(null);
        });

        self.paths = [];
    };

    app.widgets.Map.prototype.unstashPaths = function() {
        var self = this;

        self.paths = self.stashedPaths;

        // remove paths from map
        _.each(self.paths, function (path) {
            path.setMap(self.map);
        });

        self.stashedPaths = [];
    };

    app.widgets.Map.prototype.showGlobalInfo = function(children) {
        var self = this,
            infoSection = self.$parent.find(".info-section");

        infoSection.empty();

        if (!children) {
            infoSection.hide();
        } else {
            infoSection.show().appendElement(children);
        }
    };

    app.widgets.Map.prototype.setMapOptions = function (options) {
        this.map.setOptions(options);
    };

    app.widgets.Map.prototype.setMapCenter = function(location) {
        this.map.setCenter(location);
        this.options.location = location;
    };

    app.widgets.Map.prototype.markMapCenter = function(loc, addr, zoom) {
        var self = this,
            infowindow = new google.maps.InfoWindow();

        if (!loc) {
            throw "no location specified";
        }

        self.options.location = loc;

        function mark(location, address) {
            // init marker
            var marker = new google.maps.Marker({
                map: self.map,
                position: location,
                animation: google.maps.Animation.DROP
            });

            if (address) {
                infowindow.setContent(address);

                google.maps.event.addListener(marker, 'click', function() {
                    infowindow.open(self.map, marker);
                });
            }

            self.map.setCenter(location);
            self.markers.push(marker);
            self.infoWindows.push(infowindow);

            if (zoom) {
                self.options.zoom = zoom;
                self.map.setZoom(self.options.zoom);
            }
        }

        mark(loc, addr);
    };

    app.widgets.Map.prototype.renderPath = function(locations, strokeColor) {
        var self = this,
            coordinates = _.map(locations, function(loc) {
                return loc.coordinates;
            });

        var path = new google.maps.Polyline({
            path: coordinates,
            strokeColor: strokeColor || '#FF0000',
            strokeOpacity: 1.0,
            strokeWeight: 2
        });

        path.setMap(self.map);

        self.paths.push(path);

        if (self.paths.length > 1) {
            self.$parent.find(".animate-button").addClass("disabled");
        } else {
            self.$parent.find(".animate-button").removeClass("disabled");
        }
    };

    app.widgets.Map.prototype.animatePath = function(params) {
        var self = this,
            finishedCallback = params.finished,
            updateDistanceCallback = params.updateDistance,
            updateCheckpointCallback = params.updateCheckpoint,
            path = self.paths[0],
            stepSize = params.stepSize,
            sectionTime = params.sectionTime,
            infoWindow = new google.maps.InfoWindow(),
            infoWindowOpenTimestamp = 0,
            markers = self.stashedMarkers.slice(),
            coordinates,
            distBetweenCheckpoints, distFromLastCheckpoint,
            point, pointIdx,
            prevPoint, prevPointIdx,
            accFactor = 1,
            markerIdx = 0,
            checkpointShownMap= {};

        // if there is no path on map, we cannot animate it
        if (!path) { return; }

        coordinates = _.map(path.getPath().getArray(), function(coord) {
            return coord;
        });

        // define moving marker
        var marker = new google.maps.Marker({
            map: self.map,
            position: coordinates[0],
            icon: 'style/images/content/pin_barett.png'
        });

        // set the initial marker
        self.markers.push(marker);

        // todo split up this method
        function move(marker, totalDistance, wait) {
            var isInfoWindowVisible =
                (infoWindowOpenTimestamp + (self.options.animationStopTimeAtCheckpoint / self.pathAnimationSpeed)) >
                    new Date().getTime(),
                oldTotalDistance;

            function continueAnimation() {
                // call the next "frame" of the animation
                setTimeout(function() {
                    move(marker, totalDistance, wait);
                }, wait);
            }

            function stopAnimation() {
                marker.setMap(null);
                finishedCallback();
            }

            if (!self.pathAnimationJumpOffset) {
                // check if animation is paused / stopped
                if (self.pathAnimationStopped) {
                    stopAnimation();
                    return;
                } else if (!self.pathAnimationRunning) {
                    continueAnimation();
                    return;
                } else if (isInfoWindowVisible) {
                    continueAnimation();
                    return;
                }
            }

            if (self.pathAnimationJumpOffset) {
                if (pointIdx === null) {
                    pointIdx = markers.length;
                }

                pointIdx += Math.min(self.pathAnimationJumpOffset, markers.length);

                if (self.pathAnimationJumpOffset > 0 ||
                    (self.pathAnimationJumpOffset < 0 && isInfoWindowVisible)) {
                    pointIdx = Math.max((pointIdx || 0) - 1, 0);
                }

                oldTotalDistance = totalDistance;
                totalDistance = 0;
                for (var i = 1, dist; i <= Math.min(Math.max((pointIdx || 0), 0), markers.length - 1); i++) {
                    dist = path.getPath().getAt(i - 1).distanceFrom(path.getPath().getAt(i));
                    totalDistance += (typeof dist === 'number' ? dist : 0);
                }

                totalDistance += MIN_STEP_SIZE;
                if (oldTotalDistance === totalDistance) {
                    if (self.pathAnimationJumpOffset < 0 && totalDistance > MIN_STEP_SIZE) {
                        totalDistance -= MIN_STEP_SIZE;
                    }
                }

                self.pathAnimationJumpOffset = 0;
                stepSize = 0;

                _.each(checkpointShownMap, function(val, idx) {
                    if (idx >= pointIdx) {
                        checkpointShownMap[idx] = false;
                    }
                });

            } else if (sectionTime && path.getPath().getLength() > 1) {
                // compute appropriate step size for first section
                if (typeof pointIdx === "number") {
                    distBetweenCheckpoints = path.getPath().getAt(pointIdx)
                        .distanceFrom(path.getPath().getAt(pointIdx - 1));
                    distFromLastCheckpoint = path.getPath().getAt(pointIdx - 1)
                        .distanceFrom(path.GetPointAtDistance(totalDistance));

                    accFactor = Math.max(4 * Math.sin(Math.PI / distBetweenCheckpoints * distFromLastCheckpoint),
                                         0.01);

                    stepSize = Math.ceil(
                            (distBetweenCheckpoints - distFromLastCheckpoint) /
                            ((Math.max(distBetweenCheckpoints - distFromLastCheckpoint, MIN_STEP_SIZE) / distBetweenCheckpoints)
                                * (sectionTime / 1000))
                        * self.pathAnimationSpeed * accFactor);
                } else {
                    stepSize = MIN_STEP_SIZE;
                }
            }

            // update total distance
            totalDistance += stepSize / 1000 * ANIMATION_INTERVAL;

            // fetch point at the new distance
            prevPoint = point;
            prevPointIdx = pointIdx;

            point = path.GetPointAtDistance(totalDistance);
            pointIdx = path.GetIndexAtDistance(totalDistance);

            var idx = pointIdx === null ? markers.length : pointIdx,
                showCheckpoint = false;
            if (!checkpointShownMap[idx]) {
                point = path.getPath().getAt(idx-1);
                checkpointShownMap[idx] = true;
                showCheckpoint = true;
            }

            if (infoWindowOpenTimestamp) {
                infoWindowOpenTimestamp = 0;
                infoWindow.close();

                if (pointIdx === null && prevPointIdx === null && totalDistance > 0) {
                    stopAnimation();
                    return;
                }
            }

            if (point) {
                updateDistanceCallback(totalDistance);
                marker.setPosition(point);

                if (!self.map.getBounds().contains(marker.getPosition())) {
                    self.map.setCenter(point);
                }
            } else if (path.GetPointAtDistance(totalDistance - 1)) {
                updateDistanceCallback(totalDistance - 1);
                marker.setPosition(path.GetPointAtDistance(totalDistance - 1));
            }

            // set marker index
            if (pointIdx !== null) {
                markerIdx = Math.max(0, (pointIdx || 0) - 1);
            } else {
                markerIdx = markers.length - 1;
                prevPointIdx = markers.length;
            }

            if (showCheckpoint && markers.length > markerIdx) {
                var m = markers[markerIdx],
                    markerPassTime = self.options.fnGetMarkerPassTime
                        ? self.options.fnGetMarkerPassTime(self.stashedMarkers.indexOf(m))
                        : null,
                    info;

                if (markerPassTime) {
                    info = m.title + ":" + markerPassTime.toFormatString(app.settings.dateTimeFormat);
                } else {
                    info = self.stashedInfoWindows[self.stashedMarkers.indexOf(m)]
                        .getContent()
                        .replace('"button"', '"button disabled"');
                }
                infoWindow.setContent(info);
                infoWindow.open(self.map, marker);
                infoWindowOpenTimestamp = new Date().getTime();
                updateCheckpointCallback(markerPassTime);
            }

            continueAnimation();
        }

        // start with delay of 100ms for allowing to load the marker icon
        setTimeout(function() {
            move(marker, 0, ANIMATION_INTERVAL);
        }, 100);
    };

    app.widgets.Map.prototype.markLocations = function(locations) {
        var self = this;

        try {
            const bounds = new window.google.maps.LatLngBounds();

            _.each(locations, function (l, idx) {
                var markerOptions = {
                    map: self.map,
                    position: l.coordinates,
                    title: l.title,
                    draggable: !!l.draggable
                };

                if (l.type === checkpointType.START) {
                    markerOptions.icon = 'style/images/content/map_pin_start_flag.png';
                } else if (l.type === checkpointType.END) {
                    markerOptions.icon = 'style/images/content/map_pin_checkers_flag.png';
                } else {
                    markerOptions.color = '#00FF00';
                }

                var infowindow = new google.maps.InfoWindow(),
                    marker = new google.maps.Marker(markerOptions);

                //extend the bounds to include each marker's position
                bounds.extend(marker.position);

                marker.type = l.type;
                infowindow.setContent(l.infoText);

                google.maps.event.addListener(marker, 'click', function () {
                    infowindow.open(self.map, marker);
                });

                if (self.options.onMarkerDrag) {
                    google.maps.event.addListener(marker, 'dragend', function (event) {
                        self.options.onMarkerDrag(l.id, {
                            latitude: this.position.lat(),
                            longitude: this.position.lng()
                        });
                    });
                }

                if (self.mc) {
                    self.mc.addMarker(marker);
                }

                self.markers.push(marker);
                self.infoWindows.push(infowindow);
            });

            //now fit the map to the newly inclusive bounds
            if (self.markers.length >= 1) {
                self.map.fitBounds(bounds);
                //self.map.setZoom(self.map.getZoom() - 1);
            }

            // stop path animation
            self.pathAnimationStopped = true;
        } catch (e) {
            console.error("MapWidget: Error marking locations.");
        }
    };


    app.widgets.Map.prototype.setMarker = function(latLng) {
        var self = this;

        var marker = new google.maps.Marker({
            map: self.map,
            position: latLng,
            animation: google.maps.Animation.DROP
        });

        self.markers.push(marker);

        // stop path animation
        self.pathAnimationStopped = true;
    };

    app.widgets.Map.prototype.removeAllMarkers = function() {
        var self = this;

        _.each(this.markers, function (marker) {
            marker.setMap(null);
        });

        _.each(this.stashedMarkers, function (marker) {
            marker.setMap(null);
        });

        if (self.mc) {
            self.mc.clearMarkers();
        }

        self.markers.length = 0;
        self.stashedMarkers.length = 0;
        self.infoWindows.length = 0;
        self.stashedInfoWindows.length = 0;

        // stop path animation
        self.pathAnimationStopped = true;
    };

    app.widgets.Map.prototype.removeAllPaths = function() {
        var self = this;

        _.each(this.paths, function (path) {
            path.setMap(null);
        });

        _.each(this.stashedPaths, function (path) {
            path.setMap(null);
        });

        self.paths.length = 0;
        self.stashedPaths.length = 0;

        // stop path animation
        self.pathAnimationStopped = true;
    };

    app.widgets.Map.prototype._print = function() {
        var self = this,
            mapWidget;

        function printMap($parent) {
            var $mapParent = $parent.appendElement(
                    div({ "class": "print-map" })
                );

            mapWidget = new app.widgets.Map($mapParent,
                _.extend({}, self.options, PRINT_OPTIONS)
            );

            mapWidget.render("width:100%; height: 525px;")
                .done(function() {
                    // add markers
                    mapWidget.markLocations(_.map(self.markers, function(marker) {
                        return {
                            coordinates: marker.position,
                            title: marker.title,
                            label: marker.title,
                            type: marker.type,
                            draggable: marker.draggable
                        };
                    }));

                    _.each(self.paths, function(polyline) {
                        var polylineCoordinates = _.map(polyline.getPath().getArray(), function(coord) {
                            return {
                                coordinates: coord
                            };
                        });

                        mapWidget.renderPath(polylineCoordinates, polyline.strokeColor);
                    });

                    mapWidget.update();
                });
        }

        function onBeforePrint() {
            var deferred = new $.Deferred();

            mapWidget.setMapOptions({
                "disableDefaultUI": true
            });

            google.maps.event.trigger(mapWidget.map, 'resize');
            mapWidget.map.setZoom(mapWidget.map.getZoom());

            setTimeout(function() {
                deferred.resolve();
            }, 200);

            return deferred.promise();
        }

        app.printpreview.openPrintPreview({
            title: "Karte",
            onBeforePrint: onBeforePrint,
            fnRenderBody: printMap,
            landscape: true
        });
    };

    /* Helper functions *****************************************************************/

    function renderAnimationInfoBox(withCheckTime) {
        return div({
            'id': 'animation-info-div',
            'class': 'animation-info-box'
        }, [
            span({
                'id': 'tour-run-checkpoint-info',
                'class': 'animation-info-text',
                'style': !withCheckTime ? 'display:none;' : ''
            }, "Letzter Checkpoint: "),
            span({
                'id': 'tour-run-distance-info',
                'class': 'animation-info-text'
            }, "Distanz: 0 m")
        ]);
    }

    function renderInfoSection() {
        return span({ "class": "info-section", "style": "display:none" });
    }

}(window.rise, window.app));
