/**
 * Initializes the window.app package. This script should be executed
 * before any other application libraries are loaded.
 *
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(self) {
    "use strict";

    self.app = {};
    self.app.body = {};
    self.app.dto = {};
    self.app.model = {};
    self.app.dao = {};
    self.app.i18n = {};
    self.app.pagesByPath = {};
    self.app.pages = {};
    self.app.pages.customer = {};
    self.app.pages.location = {};
    self.app.pages.object = {};
    self.app.pages.workplace = {};
    self.app.pages.business_document = {};
    self.app.pages.tag = {};
    self.app.pages.logbook = {};
    self.app.pages.shift = {};
    self.app.pages.settings = {};
    self.app.pages.task = {};
    self.app.pages.logbook.task = {};
    self.app.pages.logbook.task.detail = {};
    self.app.pages.event_type = {};
    self.app.pages.ticket = {};
    self.app.pages.user = {};
    self.app.pages.user_tag = {};
    self.app.pages.role = {};
    self.app.pages.tour = {};
    self.app.pages.tour_run = {};
    self.app.pages.tour_area = {};
    self.app.pages.report = {};
    self.app.pages.session = {};
    self.app.pages.report_schedule = {};
    self.app.widgets = {};
    self.app.menu = {};
    self.app.settings = {};

}(window));
