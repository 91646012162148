/**
 * Data Access Object for the workplace endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.workplace");

    /*** Export Dao ***/
    app.dao.workplaceDao = new WorkplaceDao();

    /**
     * @constructor
     */
    function WorkplaceDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.Workplace, new Rpc('/rpc/workplace'));
    }

    // extend the parent Dao prototype
    $.extend(WorkplaceDao.prototype, Object.create(rise.Dao.prototype));

    WorkplaceDao.prototype.listAll = function(params) {
        log.info('List all workplaces');
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke('list_all', params)
            .done(function(data) {
                if (data.length === 0) {
                    log.info('No workplace data sets found');
                    deferred.resolve([]);
                } else {
                    log.info('Received %s User(s)', data.length);
                    deferred.resolve(data.map(function(d) {
                        return new app.dto.WorkplaceListDto(d);
                    }));
                }
            })
            .fail(function(error) {
                log.error('Fetching all workplaces failed. %s', error.message);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    WorkplaceDao.prototype.findAssignedEmployees = function(workplaceId) {
        var deferred = new $.Deferred();

        log.info('Search shift users that are assigned to the workplace with id ' + workplaceId);

        this.rpc.invoke("find_assigned_employees", {'workplace_id': workplaceId})
            .done(function(employees) {
                deferred.resolve(_.map(employees, function(json) {
                    return new app.model.Employee(json);
                }));
            })
            .fail(function (error) {
                log.error('List of users cannot be retrieved. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Get a particular workplace
     */
    WorkplaceDao.prototype.getWorkplaceDetails = function(workplace_id) {
        var deferred = new $.Deferred();

        this.rpc.invoke('get_workplace_details', { 'id':workplace_id })
            .done(function(json) {
                deferred.resolve( new app.dto.WorkplaceDetailsDto(json) );
            })
            .fail(function(error) {
                log.error('Unable to retrieve workplace failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Insert workplace and assignments
     */
    WorkplaceDao.prototype.insertWorkplaceAndAssignments = function(workplace, assignedObjectIds, assignedEventTypes,
                                                                    assignedBusinessDocuments, assignedEmployees, assignedTours,
                                                                    dmsIntervals) {
        var deferred = new $.Deferred();

        this.rpc.invoke('insert_workplace_and_assignments', {
            workplace: workplace,
            event_type_ids: assignedEventTypes,
            business_document_ids: assignedBusinessDocuments,
            employee_ids: assignedEmployees,
            observed_object_ids: assignedObjectIds,
            assigned_tour_ids: assignedTours,
            dms_intervals: dmsIntervals
        }).done(function(json) {
            var result = new app.model.Workplace(json);
            deferred.resolve(result);
        }).fail(function (error) {
            log.error('Inserting workplace failed: %s', error);
            deferred.reject(error);
        });

        return deferred.promise();
    };

    /**
     * Update workplace and assignments
     */
    WorkplaceDao.prototype.updateWorkplaceAndAssignments = function(workplace, assignedObjectIds, assignedEventTypes,
                                                                    assignedBusinessDocuments, assignedEmployees,assignedTours,
                                                                    dmsIntervals) {
        var deferred = new $.Deferred();

        this.rpc.invoke('update_workplace_and_assignments',
            { workplace:workplace, event_type_ids:assignedEventTypes, business_document_ids:assignedBusinessDocuments,
              employee_ids:assignedEmployees, observed_object_ids: assignedObjectIds,
              assigned_tour_ids: assignedTours, dms_intervals: dmsIntervals })
            .done(function(json) {
                var result = new app.model.Workplace(json);
                deferred.resolve(result);
            })
            .fail(function(error) {
                log.error('Updating workplace failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

})(window.rise, window.app);
