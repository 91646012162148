/**
 * TaskLogbookEntry list open (overview) page.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function (rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav = rise.Navigation;
    var clowFilter = app.widgets.clowFilter;

    /** DAOs **/
    var taskLogbookEntryDao = app.dao.taskLogbookEntryDao;

    /*** GLOBALS ***/
    var pages = app.pages;

    var taskLogbookEntriesById;


    /*** Export Page ***/
    pages.logbook.task.list = pages.logbook.task.list || {};
    pages.logbook.task.list.open = app.pagesByPath['/logbook/task/list/open'] = TaskLogbookListOpen;

    /*** Set permission ***/
    TaskLogbookListOpen.permissions = [
        app.model.PermissionTypes.TASK_VIEW_ADMIN,
        app.model.PermissionTypes.TASK_VIEW_SUPERVISOR,
        app.model.PermissionTypes.TASK_VIEW_EMPLOYEE,
        app.model.PermissionTypes.TASK_VIEW_CUSTOMER
    ];

    function TaskLogbookListOpen() {

        var sessionInfo = app.session.getSessionInfo();
        var isEmployee = app.session.hasPermission(app.model.PermissionTypes.TASK_VIEW_EMPLOYEE)
            && !app.session.hasPermission(app.model.PermissionTypes.TASK_VIEW_SUPERVISOR)
            && !app.session.hasPermission(app.model.PermissionTypes.TASK_VIEW_ADMIN)
            && !app.session.hasPermission(app.model.PermissionTypes.TASK_VIEW_CUSTOMER);

        if (!(sessionInfo.shift && sessionInfo.shift.workplace_id) && isEmployee) {
            app.showErrorMessage('Keine Schicht gestartet');
            return;
        }

        app.widgets.ListPage.call(this, {
            pageName: '/logbook/task/list/open',
            prefix: "task-list-open",
            rpc: function () {
                return [
                    taskLogbookEntryDao.findTaskLogbookEntries({}),
                    taskLogbookEntryDao.getCustomerList()
                ];
            },
            labels: {
                pageHeader: "Offene Aufgaben"
            },
            filters: {}
        });

        this.prepareTable = function ($parent, dataTasks, customers) {
            var columns = getTableColumns();
            var settings = getTableSettings();

            dataTasks = dataTasks.rows;

            app.widgets.notificationWidget.refresh();

            this.renderTable(dataTasks, columns, settings);

            updateTaskLogbookEntriesById(dataTasks);

            if (customers) {
                $("#placeholder-filters").prependElement(
                    clowFilter({
                        customers: customers,
                        change: $.proxy(this.onFilterChange, this),
                        depth: 4,
                        attributeNameWorkplaces: isEmployee ? 'workplaces' : 'assigned_workplaces'
                    })
                );
            }
        };

        this.savePageState = function () {
        };

        this.loadPageState = function ($parent, dataTasks, customers) {
            app.widgets.notificationWidget.refresh();

            updateTaskLogbookEntriesById(dataTasks.rows);

            if (customers) {
                clowFilter({
                    customers: customers,
                    refresh: true,
                    change: $.proxy(this.onFilterChange, this)
                });
            } else {
                this.renderTableUpdate(dataTasks.rows);
            }

        };

        function updateTaskLogbookEntriesById(dataTasks) {
            taskLogbookEntriesById = _.object(_.map(dataTasks, function (item, i) {
                return [item.id, {
                    prev: dataTasks[i - 1] ? dataTasks[i - 1].id : null,
                    next: dataTasks[i + 1] ? dataTasks[i + 1].id : null
                }];
            }));
        }

        this.showDetailsPage = function (taskLogbookEntryDto) {
            nav.goToPage(pages.logbook.task.detail.open, [taskLogbookEntryDto.id, taskLogbookEntriesById]);
        };

        var oldFilter = {customer_id: -1, location_id: -1, object_id: -1};
        this.onFilterChange = function (selection) {
            var filter = {
                    customer_id: selection.customer !== -1 ? selection.customer : null,
                    location_id: selection.location !== -1 ? selection.location : null,
                    object_id: selection.object !== -1 ? selection.object : null,
                    workplace_id: selection.workplace !== -1 ? selection.workplace : null
                },
                self = this;

            if (filter !== oldFilter) {
                oldFilter = filter;
                taskLogbookEntryDao.findTaskLogbookEntries(filter)
                    .done(function (dataTasks) {
                        updateTaskLogbookEntriesById(dataTasks.rows);
                        self.renderTableUpdate(dataTasks.rows);
                    });
            }
        };
    }

    function getTableColumns() {
        var columns = [
                {
                    sTitle: "Zu erledigen am",
                    sAttrName: "task_logbook_entry.time_entered",
                    sWidth: "130px",
                    mRender: function (mData, type, obj) {
                        if (type === "display") {
                            return obj.time_entered.toDateTimeString();
                        } else {
                            return obj.time_entered;
                        }
                    }
                },
                {
                    sTitle: "Beschreibung",
                    sAttrName: "task_label"
                },
                {
                    sTitle: "Zugewiesen an",
                    sAttrName: "assigned_to"
                },
                {
                    sTitle: "Gesichtet",
                    sAttrName: "read_count",
                    sWidth: "50px"
                },
                {
                    sTitle: "Anmerkungen",
                    sAttrName: "note_count",
                    sWidth: "80px"
                },
                {
                    sTitle: "Eingetragen von",
                    sAttrName: "created_by_user_name"
                },
                {
                    sTitle: "Kunde",
                    sAttrName: "customer_name",
                    mRender: function (mData, type, obj) {
                        return obj.customer_name
                            ? obj.customer_name
                            : '';
                    }
                },
                {
                    sTitle: "Standort",
                    sAttrName: "location_name",
                    mRender: function (mData, type, obj) {
                        return obj.location_name
                            ? obj.location_name
                            : '';
                    }
                },
                {
                    sTitle: "Objekt",
                    sAttrName: "object_name",
                    mRender: function (mData, type, obj) {
                        return obj.object_name
                            ? obj.object_name
                            : '';
                    }
                }
            ],
            user = app.session.getSessionInfo().user;

        if (user.type === app.model.UserType.EMPLOYEE &&
            !(app.session.hasPermission(app.model.PermissionTypes.TASK_VIEW_ADMIN ||
                app.session.hasPermission(app.model.PermissionTypes.TASK_VIEW_SUPERVISOR)))) {
            columns.splice(columns.length - 2, 1);
        }

        return columns;
    }

    function getTableSettings() {
        return {};
    }

})(window.rise, window.app);
