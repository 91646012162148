/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    /*** IMPORTS ***/
    var TourArea = app.model.TourArea;
    var TourAreaAssignments = app.model.TourAreaAssignments;


    app.dto.TourAreaDetailsDto = function TourAreaDetailsDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.tour_area = obj.tour_area !== null ? new TourArea(obj.tour_area) : undefined;
        this.assignments = new TourAreaAssignments(obj.assignments);
        this.referenced_tours = obj.referenced_tours !== null
            ? _.map(obj.referenced_tours || [], function(tourDetails) {
                return new app.dto.TourDetailsDto(tourDetails);
              })
            : [];
    };

}(window.app));

