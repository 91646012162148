import { TourReport } from 'app/tour-report/model/TourReport';

export class TourReportWeek extends TourReport {
    week: string;

    begin: Date;

    end: Date;

    constructor(data) {
        super(data);

        this.week = data.week;

        this.begin = new Date(data.begin);
        this.begin.setHours(0, 0, 0, 0);

        this.end = new Date(data.end);
        this.end.setHours(23, 59, 59, 999);
    }
}
