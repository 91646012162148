/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function (app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.Permission = function Permission(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id != null ? obj.id : null;
        this.description = obj.description != null ? obj.description : null;
        this.label = obj.label != null ? obj.label : null;

        // only for front end
        this._active = (obj._active === true || obj._active === false) ? obj._active : null;
    };

}(window.app));
