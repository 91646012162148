/**
 * Tab widgets
 *
 * @module app.widgets
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    /*** EXPORTS ***/
    app.widgets.tabsWidget = TabsWidget;

    /*** TabsWidget ***/
    function TabsWidget(params, tabs) {
        var options = $.extend({
                "id"    : "assigned-elements-section",
                "class" : null,
                "beforeActivate" : null,
                "activate": null,
                "active": null
            }, params);

        function createWidget() {
            return div({id: options.id, 'class': options['class'], "create":function(e) {
                    $(e.target).tabs({
                        activate: function( event, ui ) {
                            if (options.activate) {
                                options.activate(event, ui);
                            }
                        },
                        beforeActivate: options.beforeActivate,
                        active: options.active ? options.active : 0
                    });
                } }, [
                    ul({ },
                        _.map(tabs, function (tab) {
                            return li({}, a({ href:"#" + tab.id }, tab.title));
                        })
                    ),
                    function($parent) {
                        $parent.appendElement(
                            _.map(tabs, function (tab) {
                                return div({ id:tab.id }, tab.fnRender());
                            })
                        );
                    }
                ]);
        }

        return createWidget();
    }

})(window.app);
