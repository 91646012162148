/**
 * Adds a combobox widget which uses a UI autocomplete
 *
 * Functionality: Textbox where an button for dropdown is added. Only values of the list
 * can be selected. Values can also be typed into.
 *
 * This code is based on the combobox demo on the jQuery UI demo page for autocomplete
 *
 * @author Michael Pickelbauer
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */

(function( $ ) {
    "use strict";

    $.widget( "ui.combobox", {
        options: {
            source: {},
            valueProp: 'value', // the value which is entered in the text field
            listProp: null,     // the value which is displayed in the list
            sort: false
        },

        input: null,
        selectedItem: null,

        _create: function(options) {
            var self = this;
            self.input = this.element;

            self.input.autocomplete({
                    autoFocus: true,
                    delay: 0,
                    minLength: 0,
                    source: function( request, response ) {
                        var matcher = new RegExp( $.ui.autocomplete.escapeRegex(request.term), "i" );
                        var src = self.options.source;
                        var listText = self.options.listProp ? self.options.listProp : self.options.valueProp;

                        if (self.options.sort) {
                            src.sort(function (a, b) {
                                if (typeof a === "object") {
                                    if (typeof a[listText] === "string") {
                                        return a[listText].localeCompare(b[listText]);
                                    } else {
                                        return a[listText] > b[listText];
                                    }
                                } else {
                                    return a > b;
                                }
                            });
                        }
                        response( src ? $.map( src, function(el) {
                            var value =  typeof el === 'object'
                                ? el[listText] !== null && el[listText] !== undefined
                                    ? el[listText].toString()
                                    : ''
                                : el.toString();

                            if ( value !== undefined && ( !request.term || matcher.test(value) ) ) {
                                var matched_part = $.ui.autocomplete.escapeRegex(request.term);
                                var retEl = el;
                                if( typeof el === "object" ) {
                                    if (!el[listText]) {
                                        throw "combobox: didn't found the value prop '" + listText
                                            + "' in element: " + el;
                                    }
                                    return {
                                        value: el[self.options.valueProp],
                                        label: el[listText].replace(
                                            new RegExp('('+matched_part+')', 'i'), '<strong>$1</strong>'),
                                        obj: el
                                    };
                                } else {
                                    return {
                                        value: el,
                                        label: el.replace(
                                            new RegExp('('+matched_part+')', 'i'), '<strong>$1</strong>'),
                                        obj: el
                                    };
                                }
                            }
                        }) : null );
                    },
                    select: function( event, item ) {
                        var $this = $(this);

                        self.selectedItem = typeof  item.item === 'object' ? item.item.obj : item.item;
                        // wait for the UI thread to write the value in the textbox
                        setTimeout(function() {
                            self._trigger( "selected", event, self.selectedItem );
                            $this.change();
                        }, 20);
                    },
                    change: function( event, item ) {
                        if( !item.item ) {
                            var $this = $(this),
                                matcher = new RegExp( "^" + $.ui.autocomplete.escapeRegex( $this.val() ) + "$", "i" ),
                                selectedEl = null;
                            $.each( self.options.source, function( idx, el ) {
                                var value = typeof el === 'object' ? el[self.options.valueProp].toString() : el.toString();
                                if( value.match( matcher ) ) {
                                    selectedEl = el;
                                    return false;
                                }
                            });
                            if ( selectedEl === null ) {
                                // remove invalid value, as it didn't match anything
                                self.selectedItem = null;
                                $this.val( "" );
                                self.input.data( "ui-autocomplete" ).term = "";
                                self._trigger( "selected", event, undefined );
                                return false;
                            } else {
                                //trigger selected as it could also happen that the user types in everything
                                self.selectedItem = selectedEl;
                                self._trigger( "selected", event, selectedEl );
                            }
                        }

                        $(this).change();
                    }
                })
                .addClass( "ui-widget ui-widget-content ui-corner-left" );

            self.input.data( "ui-autocomplete" )._renderItem = function( ul, item ) {
                return $( "<li></li>" )
                    .data( "item.autocomplete", item )
                    .append( "<a>" + item.label + "</a>" )
                    .appendTo( ul );
            };

            this.button = $( "<button type='button'>&nbsp;</button>" )
                .attr( "tabIndex", -1 )
                .attr( "title", "Show All Items" )
                .insertAfter( self.input )
                .button({
                    icons: {
                        primary: "ui-icon-triangle-1-s"
                    },
                    text: false
                })
                .removeClass( "ui-corner-all" )
                .addClass( "ui-corner-right ui-button-icon ui-autocomplete-button" )
                .click(function() {
                    // close if already visible
                    if( self.input.autocomplete( "widget" ).is( ":visible" ) ) {
                        self.input.autocomplete( "close" );
                        return;
                    }

                    // work around a bug (likely same cause as #5265)
                    $( this ).blur();

                    // pass empty string as value to search for, displaying all results
                    self.input.autocomplete( "search", "" );
                    self.input.focus();
                });
        },

        _setOption: function( key, value ) {
            var self = this;

            if ( key === "source" ) {
                self.options.source = value;
            }
            else {
                self.input.autocomplete('option', key, value);
            }
        },

        /**
         * returns the selected item
         * @return {*} selected item otherwise null!
         */
        getSelected: function() {
            if( this.selectedItem === null
                || this.input.val() === this.selectedItem
                || this.input.val() === this.selectedItem[this.options.valueProp] ) {
                return this.selectedItem;
            }
            else {
                return null;
            }
        },

        /**
         * sets the value of the combobox
         * if the value is not defined in `source`, the field is cleared
         *
         * triggers the change event in any case!
         *
         * e.g. $elem.combobox('setSelected', function(v) { return v.id === 1 } );
         *
         * @param   {Object|Function}   v   value to be set as defined in the array or as `value` attribute in the object
         *                                  of each array. Can also be a function for $.grep to filter elements
         * @return  {Object|String}         returns the selected element OR null if not found
         */
        setSelected: function(v) {
            var self = this;
            var text;

            if( typeof v === 'function' ) {
                text = $.grep( this.options.source, v );
            }
            else {
                if( typeof v === 'object' ) {
                    text = this.options.source ? $.grep( this.options.source, function( elem ) { return elem === v; } )
                        : [];
                }
                else {
                    text = this.options.source ? $.grep( this.options.source, function( elem ) {
                        return elem === v || (typeof elem === 'object' ? elem[self.options.valueProp] === v : false);
                    }) : [];
                }
            }

            if( text.length > 0 ) {
                text = text[0];
                this.input.val( typeof text === 'object' ? text[self.options.valueProp] : text );
            }
            else {
                text = null;
                this.input.val('');
            }
            this.selectedItem = text;
            this.input.data( "ui-autocomplete" ).term = "";
            this._trigger( "selected", {}, text !== null ? text : undefined );

            return text;
        },

        destroy: function() {
            this.button.remove();
            this.input.autocomplete('destroy');
            $.Widget.prototype.destroy.call( this );
        }
    });
})( jQuery );
