import React, { Component } from 'react';

import { customerDao } from 'app/legacy/dao/customer-dao';
import { tourAreaDao } from 'app/legacy/dao/tour-area-dao';
import { Customer } from 'app/legacy/model/customer';
import { TourArea } from 'app/legacy/model/tour-area';

import { AbstractLegacyPage } from 'app/AbstractLegacyPage';
import { PermissionType } from 'app/PermissionType';
import { reportScheduleDao } from 'app/report-schedule/dao/ReportScheduleDao';
import { PageType } from 'app/report-schedule/ReportScheduleConstants';
import { ReportSchedule } from 'app/report-schedule/ReportSchedule';

import { CommandBox } from 'app/report-schedule/pages/detail-page/components/CommandBox';
import { IntervalSettings } from 'app/report-schedule/pages/detail-page/components/IntervalSettings';
import { ObjectTourAreaFilter } from 'app/report-schedule/pages/detail-page/components/ObjectTourAreaFilter';
import { ReportScheduleDetails } from 'app/report-schedule/pages/detail-page/components/ReportScheduleDetails';
import { ReportScheduleTaskHistory } from 'app/report-schedule/pages/detail-page/components/ReportScheduleTaskHistory';
import { ReportScheduleListPage } from 'app/report-schedule/pages/list-page/ReportScheduleListPage';
import $ from 'jquery';

const { app, rise } = window as any;

type State = {
    pageType: PageType;
    model: ReportSchedule;
}

type Props = {
    parent: HTMLElement;
    args?: [number];
}

const URL = '/report-schedule/details';

const CommandBoxMemo = React.memo(CommandBox);
const ReportScheduleDetailsMemo = React.memo(ReportScheduleDetails);
const IntervalSettingsMemo = React.memo(IntervalSettings);
const ObjectTourAreaFilterMemo = React.memo(ObjectTourAreaFilter);
const ReportScheduleTaskHistoryMemo = React.memo(ReportScheduleTaskHistory);

let reportScheduleBackup: ReportSchedule | null = null;

export class ReportScheduleDetailPage extends Component<Props, State> implements AbstractLegacyPage {
    // eslint-disable-next-line  react/no-unused-class-component-methods
    name = URL;

    parent: HTMLElement;

    customers: Array<Customer> = [];

    tourAreas: Array<TourArea> = [];

    reportScheduleId?: number;

    constructor(props) {
        super(props);

        this.state = {
            pageType: PageType.VIEW,
            model: new ReportSchedule({}),
        };
        this.parent = props.parent;
        [this.reportScheduleId] = props.args;

        this.handleOnModelChange = this.handleOnModelChange.bind(this);
        this.handleOnStateChange = this.handleOnStateChange.bind(this);
        this.handleOnEdit = this.handleOnEdit.bind(this);
        this.handleOnCancel = this.handleOnCancel.bind(this);
        this.handleOnSave = this.handleOnSave.bind(this);
    }

    static get permissions() {
        return [
            PermissionType.REPORT_SCHEDULE_VIEW_ADMIN,
            PermissionType.REPORT_SCHEDULE_MODIFY_ADMIN,
        ];
    }

    render() {
        const { pageType, model } = this.state;
        const readonly = pageType === PageType.VIEW;

        return (
            <>
                <CommandBoxMemo
                    pageType={pageType}
                    onEdit={this.handleOnEdit}
                    onCancel={this.handleOnCancel}
                    onSave={this.handleOnSave}
                />
                <ReportScheduleDetailsMemo
                    pageType={pageType}
                    active={model.active}
                    recipients={model.recipients}
                    type={model.type}
                    mailText={model.mail_text}
                    reportAttachedToMail={model.report_attached_to_mail}
                    noEmptyReportMail={model.no_empty_report_mail}
                    onChange={this.handleOnModelChange}
                />
                <div className="columns-2">
                    <ObjectTourAreaFilterMemo
                        pageType={pageType}
                        tourAreas={this.tourAreas}
                        customers={this.customers}
                        tourAreaId={model.tour_area_id}
                        customerId={model.customer_id}
                        locationId={model.location_id}
                        objectId={model.object_id}
                        onChange={this.handleOnModelChange}
                    />
                    <IntervalSettingsMemo
                        pageType={pageType}
                        time={model.time}
                        interval={model.interval}
                        dayOfWeek={model.day_of_week}
                        onChange={this.handleOnModelChange}
                    />
                </div>
                {readonly && (
                    <fieldset>
                        <legend>Historische Berichte</legend>
                        <ReportScheduleTaskHistoryMemo reportId={model.id} />
                    </fieldset>
                )}
            </>
        );
    }

    handleOnModelChange(model) {
        this.setState((prevState) => ({
            model: new ReportSchedule({
                ...prevState.model,
                ...model,
            }),
        }));
    }

    handleOnStateChange(state) {
        this.setState((prevState) => ({
            ...prevState,
            ...state,
            model: new ReportSchedule({
                ...prevState.model,
                ...state.model,
            }),
        }));
    }

    handleOnEdit() {
        const { model } = this.state;
        reportScheduleBackup = new ReportSchedule(model);
        this.handleOnStateChange({
            pageType: PageType.UPDATE,
        });
    }

    handleOnCancel(backToOverview) {
        if (backToOverview) {
            rise.Navigation.goToPage(ReportScheduleListPage);
        } else if (reportScheduleBackup !== null) {
            this.handleOnStateChange({
                pageType: PageType.VIEW,
                model: {
                    ...reportScheduleBackup,
                },
            });
        }
        reportScheduleBackup = null;
    }

    async handleOnSave() {
        const { pageType, model } = this.state;

        if (!$(this.parent).validate()) {
            return;
        }

        if (pageType === PageType.NEW) {
            const result = await reportScheduleDao.insert(model);
            this.handleOnStateChange({
                model: result,
                pageType: PageType.VIEW,
            });
            app.showSuccessMessage('Automatischer Bericht gespeichert');
        } else if (pageType === PageType.UPDATE) {
            const result = await reportScheduleDao.update(model);
            this.handleOnStateChange({
                model: result,
                pageType: PageType.VIEW,
            });
            app.showSuccessMessage('Automatischer Bericht aktualisiert');
        }
    }

    async componentDidMount() {
        const [customers, tourAreas, reportSchedule] = await Promise.all([
            customerDao.findAll(true),
            tourAreaDao.findAll(),
            this.reportScheduleId ? reportScheduleDao.getReportSchedule(this.reportScheduleId) : null,
        ]);

        this.customers = customers;
        this.tourAreas = tourAreas;

        await new Promise<void>((resolve) => {
            this.setState({
                pageType: reportSchedule ? PageType.VIEW : PageType.NEW,
                model: reportSchedule || new ReportSchedule({ active: true }),
            }, resolve);
        });
    }
}

/* Export Pages */
app.pages.report_schedule.details = ReportScheduleDetailPage;
app.pagesByPath[URL] = ReportScheduleDetailPage;
