/**
 * Page structure.
 *
 * @module app.widgets
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    /*** EXPORT ***/
    var page = app.widgets.page = {};

    /**
     * Login page structure.
     * @type {*}
     */
    page.loginPage =
        div({ id: "content" }, [
            div({ id: "content-inner" }, [
                div({ "class": "whiteboard form-layout" }, [
                    span({ "class": "login-page-links" }, [
                        a({ "href": "https://ckv-gruppe.at/impressum/", "target": "_blank" }, "Impressum"),
                        " - ",
                        a({ "href": "https://ckv-gruppe.at/datenschutz/", "target": "_blank" }, "Datenschutz")
                    ]),
                    img({ "class":"app-logo", src:"style/images/singlepage/logo.png" }),
                    form({ id: "login-form" },
                        fieldset(
                            ul([
                                li({ "class": "fieldset-item large" }, [
                                    label("Benutzername"),
                                    input({ id: "username", "class": "large-input", type: "text",
                                        autocomplete: false,
                                        readonly: true, focus: function() { // prevent auto-filling credentials
                                            this.removeAttribute('readonly');
                                        }
                                    })
                                ]),
                                li({ "class": "fieldset-item large" }, [
                                    label("Passwort"),
                                    input({ id: "password", "class": "large-input", type: "password",
                                        autocomplete: false,
                                        readonly: true, focus: function() { // prevent auto-filling credentials
                                            this.removeAttribute('readonly');
                                        }
                                    })
                                ]),
                                li({ "class": "fieldset-item large" },
                                    a({ id: "login-button", "class": "button" },
                                        span({ "class": "button-content" }, "Login"))
                                )
                            ])
                        )
                    ),
                ]),
                footer(
                    [
                        span({ "class": "hotline" }, "Hotline 01/ 521 57 - 61"),
                        span({id: "version",
                            "class": 'software-version'
                        }, window.versionInfo)
                    ]
                )
            ])
        ]);

    /**
     * Main (content) page structure.
     * @type {*}
     */
    page.mainPage = [
            div({ id: "header", "class": "clearfix" }, [
                div({ id: "branding", "class": "branding" },
                    a({"class":"logo"},
                        span({ "class": "img img-logo-no-glow" })
                    )
                ),
                div({ id: "notifications" },
                    function($parent) {
                        var sessionInfo = app.session.getSessionInfo();

                        if (sessionInfo) {
                            app.widgets.notificationWidget = new app.widgets.NotificationWidget({
                                parent: $parent
                            });
                            app.widgets.notificationWidget.updateStats({
                                openTasks: sessionInfo.notifications.open_task_cnt,
                                unreadBusinessDocuments: sessionInfo.shift
                                    ? sessionInfo.notifications.unread_business_documents_cnt
                                    : 0,
                                shiftTime: sessionInfo.shift
                                    ? new Date(sessionInfo.shift.started)
                                    : null
                            });
                        }
                    }
                ),
                div({ id: "nav-supplementary" },
                    ul({ "class": "nav-content" }, [
                        li({ "class": "merchant" },
                            function($parent) {
                                var employee = app.session.getSessionInfo().user;

                                if (employee) {
                                    var displayName;

                                    try {
                                        displayName = employee.first_name + " " + employee.last_name;
                                    } catch (ex) {
                                        displayName = employee.username;
                                    }

                                    return span({ "id":"user-name-in-nav-bar" }, displayName);
                                }
                            }
                        ),
                        li({ "class": "manual" },
                            form({ method:"POST", action:"/document" }, [
                                input({ id:"manual-document-download", type:"hidden", name:"method", value:"download_manual" }),
                                input({ id:"manual-document-x-session-id", type:"hidden", name:"X-Session-Id" }),
                                a({ id:"manual-download",
                                    click: function () {
                                        $('#manual-document-x-session-id').val(
                                            window.app.session.getSessionId());
                                        $(this).closest("form").submit();
                                    }
                                }, "Handbuch")
                            ])
                        ),
                        li({ "class": "logged-in"},
                            a({ id:"logout-button",
                                click: function() {
                                     // if shift is active go to shift page
                                    if(app.session.getSessionInfo().shift != null) {
                                        rise.Navigation.goToPage(app.pages.shift.check);
                                        return;
                                    }

                                    app.session.logout();
                                }
                            }, "Logout")
                        )
                    ])
                )
            ]),
            div({ id: "nav-primary", "class": "nav tabs" }, [
                div({'class': 'mobile-nav-control'}, [
                    a({'class': 'toggle-mobile-nav-primary',
                        click: function() {
                            $('#nav-primary').toggleClass("m-open");
                            $('#nav-supplementary').toggleClass("m-open");
                        }
                    })
                ]),
            ]),
            /*div({ id: "breadcrumbs" },
                ol()
            ),*/
            div({ id: "content" },
                div({ id: "content-inner" })
            ),
            div({ id: "footer", "class": "clearfix"},
                ul([
                    li({ "class": "left" }, [
                        a({ "href": "https://ckv-gruppe.at/impressum/", "target": "_blank" }, "Impressum"),
                        " - ",
                        a({ "href": "https://ckv-gruppe.at/datenschutz/", "target": "_blank" }, "Datenschutz")
                    ]),
                    li([
                        a({ "class": "siwacht brand", href:"https://ckv-gruppe.at/siwacht/" }, "Siwacht")
                    ]),
                    li({ "class": "" }, [
                        div({id: "version",
                            "class": "software-version"
                        }, window.versionInfo)
                    ])
                ])
            )
    ];


    /**
     * Error page structure.
     * @type {*}
     */
    page.errorPage =
        div({ id: "content", "class": "page-404" },
            div({ id: "content-inner" }, [
                div({ "class": "whiteboard" }, [
                    div({ "class": "set-back" },
                        img({ 'class': "app-logo", src: "style/images/singlepage/logo.png" })
                    ),
                    h1([
                        img({ "class": "sign", src: "style/images/singlepage/exclamation.png", alt: "Error" }), "Critical Error"
                    ]),
                    div({ id: "error-text" })
                ]),
                footer(
                    span({ "class": "hotline" }, "Hotline 01/ 521 57 - 61")
                )
            ])
        );

})(window.app);
