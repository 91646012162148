/**
 * Notification functions.
 *
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    var log = rise.logging.getLogger('app.notifications');

    $(document).ready(function() {
        log.debug('Initializing Notifications');

        $('body')
            // prevent click-event side effects
            .on("click", ".global-messages", function(e) {
                e.stopPropagation();
            })
            .on("click", ".global-messages-inner li.global-messages-item", function() {
                $(this).toggleClass("expanded");
                $(this).toggleClass("collapsed");
            });
    });

    function showMessage(type, text, expanded, timeout, displayUntilTimeout) {
        var message = $(".global-messages-inner").appendElement(
            li({ "class": sprintf("global-messages-item %s %s%s", type, expanded ? "expanded" : "collapsed", displayUntilTimeout ? " force-display-until-timeout" : "") }, [
                span({ "class":"msg-icon" }),
                span({ "class":"msg-body" },
                    span({ "class":"msg-text" }, text)
                )
            ])
        );

        message.hide().fadeIn();

        if(timeout > 0) {
            setTimeout(function() {
                message.growlOut();
            }, timeout);
        }
    }

    /**
     * Displays a warning message. Will be displayed by default 30 sec and expanded.
     *
     * @param {string} text
     * @param {Boolean} [collapsed] if the message should be displayed collapsed
     * @param {Number} [timeout] Timeout in milliseconds, default 30000
     */
    app.showErrorMessage = function showErrorMessage(text, collapsed, timeout) {
        showMessage("error", text, !collapsed, timeout || 60000, false);
    };

    /**
     * Displays a warning message. Will be displayed by default 30 sec and expanded.
     *
     * @param {string} text
     * @param {Boolean} [collapsed] if the message should be displayed collapsed
     * @param {Number} [timeout] Timeout in milliseconds, default 30000
     */
    app.showWarningMessage = function showWarningMessage(text, collapsed, timeout, displayUntilTimeout) {
        showMessage("warn", text, !collapsed, timeout || 30000, displayUntilTimeout);
    };

    /**
     * Displays a info message. Will be displayed by default 30 sec and expanded.
     *
     * @param {string} text
     * @param {Boolean} [collapsed] if the message should be displayed collapsed
     * @param {Number} [timeout] Timeout in milliseconds, default 30000
     */
    app.showInfoMessage = function showInfoMessage(text, collapsed, timeout) {
        showMessage("info", text, !collapsed, timeout || 30000, false);
    };

    /**
     * Displays a success message. Will be displayed short by default 5 sec and collapsed.
     *
     * @param {string} text
     * @param {Boolean} [expanded] if the message should be displayed expanded
     * @param {Number} [timeout] Timeout in milliseconds, default 5000
     */
    app.showSuccessMessage = function showSuccessMessage(text, expanded, timeout) {
        showMessage("success", text, expanded === true, timeout || 5000, false);
    };

    /**
     * Displays a validation error with all errors found on the page. This is a singleton message
     * and is thus only once displayed. There is no timeout.
     */
    app.showValidationError = function showValidationError() {
        var text = sprintf("Die markierten Eingabefelder enthalten %s Fehler. Bitte überprüfen Sie Ihre Eingaben.",
                             $.validation.getMessages().length);

        var $msg_validation = $(".global-messages-item.error.validation").finish();
        if($msg_validation.length > 0) {
            $msg_validation.find('.msg-text').html(text);
        } else {
            showMessage("error validation", text, false, 0);
        }
    };

    /**
     * Removes all messages of any kind
     */
    app.removeMessages = function removeMessages() {
        $(".global-messages-item").growlOut();
    };

    /**
     * Removes all error messages
     */
    app.removeErrorMessages = function removeErrorMessages() {
        $(".global-messages-item.error:not(.validation)").growlOut();
    };

    /**
     * Removes all warning messages
     */
    app.removeWarningMessages = function removeWarningMessages() {
        $(".global-messages-item.warn:not(.force-display-until-timeout)").growlOut();
    };

    /**
     * Removes all info messages
     */
    app.removeInfoMessages = function removeInfoMessages() {
        $(".global-messages-item.info").growlOut();
    };

    /**
     * Removes all success messages
     */
    app.removeSuccessMessages = function removeSuccessMessages() {
        $(".global-messages-item.success").growlOut();
    };

    /**
     * Removes all validation messages
     */
    app.removeValidationErrors = function removeValidationErrors() {
        $(".global-messages-item.error.validation").growlOut();
    };

    $.fn.growlOut = function(callback) {
        $.each(this, function(idx, element) {
            var $element = $(element);

            $element.animate({opacity: "hide", height: 0}, function() {
                $element.remove();
                if(callback) {
                    callback.call(this);
                }
            });
        });
    };

}(window.app));
