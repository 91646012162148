/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    var Tour = app.model.Tour;
    var TourTimeC = app.model.TourTimeC;
    var Tag = app.model.Tag;

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.dto.TourListDto = function TourListDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id != null ? obj.id : null;
        this.name = obj.name != null ? obj.name : null;
        this.description = obj.description != null ? obj.description : null;
        this.active = obj.active != null ? obj.active : null;
        this.customers =  obj.customers ? obj.customers : null;
        this.locations =  obj.locations ? obj.locations : null;
        this.objects =  obj.objects ? obj.objects : null;
        this.workplaces =  obj.workplaces ? obj.workplaces : null;

        this.created_at = obj.created_at ?
            typeof obj.created_at === 'string' ?
                Date.fromISO(obj.created_at)
                : obj.created_at
            : null;
    };

    app.dto.TourTagDto = function TourTagDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.tag_id = obj.tag_id != null ? obj.tag_id : null;
        this.name = obj.name != null ? obj.name : null;
        this.order_idx = obj.order_idx != null ? obj.order_idx : 0;
        this.must_read = obj.must_read != null ? obj.must_read : true;

        this.longitude = obj.longitude != null ? parseFloat(obj.longitude) : null;
        this.latitude = obj.latitude != null ? parseFloat(obj.latitude) : null;
    };

    app.dto.TourDetailsDto = function TourDetailsDto(json) {
        var TourTagDto =  app.dto.TourTagDto;
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.tour = obj.tour != null ? new Tour(obj.tour) : undefined;
        this.assignments = obj.assignments != null ? obj.assignments : undefined;

        if (obj.tour_tags != null) {
            this.tourTags = obj.tour_tags.checkpoint_tags != null ?
                _.map(obj.tour_tags.checkpoint_tags, function(e){ return new TourTagDto(e);}) : undefined;
            this.tourStartTag = obj.tour_tags.start_tag != null ? new TourTagDto(obj.tour_tags.start_tag)
                : undefined;
            this.tourEndTag = obj.tour_tags.end_tag != null ? new TourTagDto(obj.tour_tags.end_tag)
                : undefined;
        }

        if (obj.assignable_tags != null) {
            this.checkpointTags = obj.assignable_tags.checkpoint_tags != null ?
                _.map(obj.assignable_tags.checkpoint_tags, function(e){ return new Tag(e);}) : undefined;
            this.startTags = obj.assignable_tags.start_tags != null ?
                _.map(obj.assignable_tags.start_tags, function(e){ return new Tag(e);}) : undefined;
            this.endTags = obj.assignable_tags.end_tags != null ?
                _.map(obj.assignable_tags.end_tags, function(e){ return new Tag(e);}) : undefined;
        }

        this.timecs = obj.timecs != null ? _.map(obj.timecs, function(timec) {
            return new TourTimeC(timec);
        }) : undefined;

    };

    app.dto.CalendarDto = function CalendarDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id != null ? obj.id : null;
        this.name = obj.name != null ? obj.name : null;
        this.days = obj.days != null ? _.map(obj.days, function(day) {
            return Date.fromISO(day);
        }) : null;
    };

}(window.app));

