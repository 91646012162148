/**
 * Object detail page.
 *
 * @author Martin Reiterer (martin.reiterer@rise-world.com)
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav                 = rise.Navigation;
    var pages               = app.pages;
    var form                = app.widgets.form;
    var scrollTableSection  = app.widgets.scrollTableSection;
    var tabsWidget          = app.widgets.tabsWidget;

    /** DAOs **/
    var Object              = app.model.Object;
    var objectDao           = app.dao.objectDao;
    var locationDao         = app.dao.locationDao;
    var eventTypeDao        = app.dao.eventTypeDao;
    var businessDocumentDao      = app.dao.businessDocumentDao;

    var addIconButton = app.widgets.addIconButton;
    var editIconButton = app.widgets.editIconButton;
    var cancelIconButton = app.widgets.cancelIconButton;
    var saveIconButton = app.widgets.saveIconButton;
    var printIconButton = app.widgets.printIconButton;
    var commandBox = app.widgets.commandBox;

    var currentTab = 0;

    var PageType = {
        VIEW: 1,
        EDIT: 2,
        NEW: 3
    };

    var tagType = {
        'CHECKPOINT': 'Checkpoint-Tag',
        'START': 'Start-Tag',
        'END': 'End-Tag'
    };

    /*** Export page ***/
    pages.object.detail = app.pagesByPath['/object/detail'] = function ObjectDetail() {
        var self = this;

        this.onUpdate = null;
        this.onTreeNavigate = null;
        this.onAddChild = null;
        this.getParentId = null;
        this.name = "/object/detail";

        this.type = PageType.VIEW;

        this.object = null;
        this.eventTypes = [];
        this.business_documents = [];

        this.assignedEventTypes = [];
        this.assignedBusinessDocuments = [];

        this.$parent = null;

        this.create = function ($parent, objectId, onUpdate, onTreeNavigate, onAddChild,
                                getParentId, getNodeData, getCustomerData) {
            this.$parent = $parent;
            this.onUpdate = onUpdate;
            this.onTreeNavigate = onTreeNavigate;
            this.onAddChild = onAddChild;
            this.getParentId = getParentId;
            this.getCustomerData = getCustomerData;
            this.getNodeData = getNodeData;

            if (objectId) {
                return objectDao.getObjectDetails(objectId)
                    .done(function(objectDetailsDto) {
                        self.object = objectDetailsDto.object;
                        self.assignedEventTypes = objectDetailsDto.assigned_event_types;
                        self.assignedBusinessDocuments = objectDetailsDto.assigned_business_documents;
                        self.assignedTags = objectDetailsDto.assigned_tags;
                        self.assignedTours = objectDetailsDto.assigned_tours;

                        self.type = PageType.VIEW;
                        renderObject();
                    });
            } else {
                var locationId = parseInt(getParentId());
                return  $.when(eventTypeDao.listAll(),
                       businessDocumentDao.listAll({
                           include_read_count: false
                       }),
                       locationDao.getLocationAssignments(locationId))
                    .done(function(eventTypes, business_documents, locationAssignments) {
                        self.object = new app.model.Object();
                        self.eventTypes = eventTypes;
                        self.business_documents = business_documents;
                        self.assignedEventTypes = locationAssignments.assigned_event_types;
                        self.assignedBusinessDocuments = locationAssignments.assigned_business_documents;

                        self.type = PageType.NEW;
                        renderObject();
                    });
            }
        };

        this.destroy = function() {
            // called after page has been destroyed
        };

        function renderObject() {
            var view = self.type === PageType.VIEW;

            self.$parent.empty().appendElement(createCommandBox());

            var tabs = [
                {
                    'id': 'customer-details-tab',
                    'title': 'Allgemein',
                    'fnRender': $.proxy(renderOverviewSection, self, view)
                },
                {
                    'id':"assigned-event-types-tab",
                    'title':"Ereignistypen",
                    'fnRender': $.proxy(renderAssignedEventTypesList, self, view)
                },
                {
                    'id':"assigned-business-documents-tab",
                    'title':"Dokumente",
                    'fnRender': $.proxy(renderAssignedBusinessDocumentsList, self, view)
                }
            ];

            if (view) {
                tabs.push({
                    'id':"assigned-tours-tab",
                    'title':"Rundgänge",
                    'fnRender': $.proxy(renderAssignedTours, self, view)
                });

                if(window.app.session.hasPermission(app.model.PermissionTypes.TOUR_MODIFY)) {
                    tabs.push({
                        'id':"assigned-tags-tab",
                        'title':"Kontrollstellen",
                        'fnRender': $.proxy(renderAssignedTags, self, view)
                    });
                }
            }

            self.$parent.appendElement(tabsWidget(
                { id: "customer-assignments", beforeActivate: beforeActivate, active: currentTab }, tabs
            ));

            self.$parent.activateValidators();
        }

        function beforeActivate(event, ui) {
            currentTab = ui.newTab.index();
        }

        function renderOverviewSection(view) {
            return div({}, [
                createObjectDetails(self.object, view),
                self.type === PageType.VIEW ? renderAssignedWorkplacesList() : undefined
            ]);
        }

        function createCommandBox() {
            var savedObject = new Object(self.object);

            function cancelEditObject () {
                if (self.type !== PageType.NEW) {
                    $.validation.reset();

                    self.object = savedObject;
                    self.type = PageType.VIEW;
                    renderObject();
                } else {
                    self.onTreeNavigate("location", { id: self.getParentId() });
                }
            }

            function addObject() {
                // create a new Object that is assigned to the current Location
                var object = new app.model.Workplace({ located_in_object_id: self.object.id });

                if (self.onAddChild) {
                    self.onAddChild(object);
                }
            }

            function saveButton() {
                return saveIconButton({id: 'save-object',
                    click: saveObject
                })
            }

            function cancelButton() {
                return cancelIconButton({id: 'cancel-edit-object',
                    click: cancelEditObject
                });
            }

            function editButton() {
                return editIconButton({id: 'edit-object',
                    click: editObject
                });
            }

            function addWorkplaceButton() {
                return addIconButton({id: 'add-object',
                    click: addObject
                }, 'Arbeitsplatz hinzufügen');
            }

            function printButton() {
                return printIconButton({id: 'print-customer',
                    click: function() {
                        app.printpreview.openCustomerPrintPreview({
                            customer: self.getCustomerData(),
                            object: self.getNodeData()
                        });
                    }
                }, 'Stammdaten drucken');
            }

            function getTitle() {
                switch (self.type) {
                    case PageType.NEW:  return 'Neues Objekt';
                    case PageType.EDIT: return 'Objekt bearbeiten';
                    case PageType.VIEW: return 'Objekt: ' + self.object.name;
                }
                return '';
            }

            function getCommandButtons() {
                if (!window.app.session.hasPermission(app.model.PermissionTypes.CUSTOMER_MODIFY)) {
                    return [];
                }

                switch (self.type) {
                    case PageType.VIEW:
                        return [
                            printButton(),
                            addWorkplaceButton(),
                            editButton()
                        ];

                    case PageType.EDIT:
                        return [
                            saveButton(),
                            cancelButton()
                        ];

                    case PageType.NEW:
                        return [
                            saveButton(),
                            cancelButton()
                        ];
                }

                return [];
            }

            return commandBox({title: getTitle(),'class':'inner'},getCommandButtons());
        }

        function editObject() {
            var fnEdit = function() {
                self.type = PageType.EDIT;
                renderObject();
            };

            if (self.eventTypes.length > 0 && self.business_documents.length > 0) {
                fnEdit();
            } else {
                return $.when(eventTypeDao.listAll(),
                        businessDocumentDao.listAll({
                            include_read_count: false
                        }))
                    .done(function(eventTypes, business_documents) {
                        self.eventTypes = eventTypes;
                        self.business_documents = business_documents;
                        fnEdit();
                    });
            }
        }

        function saveObject() {
            if (!self.$parent.validate()) {
                return;
            }

            var method = $.proxy(objectDao.updateObjectAndAssignments, objectDao);

            if(!self.object.id) {
                self.object.location_id = parseInt(self.getParentId());
                method = $.proxy(objectDao.insertObjectAndAssignments, objectDao);
            }

            function saveDoneMethod(result) {
                self.object = result;
                self.type = PageType.VIEW;

                renderObject();
                app.showSuccessMessage('Gespeichert');

                if (self.onUpdate) {
                    self.onUpdate(result);
                }
            }

            // execute action
            return method(self.object,
                _.pluck(_.filter(self.assignedEventTypes,
                    function(et) { return et.assigned_to_object === true; }), 'id'),
                _.pluck(_.filter(self.assignedBusinessDocuments,
                    function(inst) { return inst.assigned_to_object === true; }), 'id'))
                .done(saveDoneMethod);
        }

        function renderAssignedWorkplacesList() {
            return scrollTableSection({
                    id: "assigned-workplaces",
                    title: 'Arbeitsplätze',
                    elements: self.object.workplaces,
                    columns: [
                        { sTitle: "Bezeichnung", sAttrName: "name" },
                        { sTitle: "Aktiv", sAttrName: "active", mRender:function(mData, type, obj) {
                            return obj.active ? "Ja" : "Nein";
                        }}
                    ],
                    "class": "",
                    click: displayWorkplace
                });
        }

        function renderAssignedEventTypesList(view) {
            var getRowLabel = function(aoData) {
                    return aoData.assigned_to_object === true ||
                        !_.find(self.assignedEventTypes, function (et) { return et.id === aoData.id; })
                        ? "Direkt dem Objekt zugeordnet"
                        : aoData.assigned_to_customer
                            ? "Übernommen von Kunde"
                            : "Übernommen von Standort";
                },
                isSelectable = function(aoData) {
                    return getRowLabel(aoData) === "Direkt dem Objekt zugeordnet";
                };

            return scrollTableSection({
                    filter: true,
                    id: "assigned-event-types",
                    elements: view
                        ? self.assignedEventTypes
                        : getAllEventTypes(),
                    selected: view ? null : self.assignedEventTypes,
                    select: function(selected) {
                        _.each(selected, function(sel) {
                            if (! (sel.assigned_to_customer || sel.assigned_to_location)) {
                                sel.assigned_to_object = true;
                            }
                        });
                        self.assignedEventTypes = selected;
                    },
                    isSelectable: isSelectable,
                    columns: [
                        { sTitle: "Vererbt", sAttrName: "assigned_to_object", bVisible: false,
                            mRender: function(mData, type, obj) {
                                return getRowLabel(obj);
                            } },
                        { sTitle: "ID", sAttrName: "id" },
                        { sTitle: "Bezeichnung", sAttrName: "label" }
                    ],
                    click: displayEventType,
                    getGroupingRowLabel: getRowLabel,
                    groupedByColumn: 0,
                    height: "100%"
                });
        }

        function renderAssignedBusinessDocumentsList(view) {
            var getRowLabel = function(aoData) {
                    return aoData.assigned_to_object === true ||
                        !_.find(self.assignedBusinessDocuments, function (et) { return et.id === aoData.id; })
                        ? "Direkt dem Objekt zugeordnet"
                        : aoData.assigned_to_customer
                            ? "Übernommen von Kunde"
                            : "Übernommen von Standort"
                },
                isSelectable = function(aoData) {
                    return getRowLabel(aoData) === "Direkt dem Objekt zugeordnet";
                };

            return scrollTableSection({
                    filter: true,
                    id: "assigned-business-documents",
                    elements: view
                        ? self.assignedBusinessDocuments
                        : getAllBusinessDocuments(),
                    selected: view ? null : self.assignedBusinessDocuments,
                    select: function(selected) {
                        _.each(selected, function(sel) {
                            if (!(sel.assigned_to_customer || sel.assigned_to_location)) {
                                sel.assigned_to_object = true;
                            }
                        });
                        self.assignedBusinessDocuments = selected;
                    },
                    isSelectable: isSelectable,
                    columns: [
                        { sTitle: "Vererbt", sAttrName: "assigned_to_object", bVisible: false,
                            mRender: function(mData, type, obj) {
                                return getRowLabel(obj);
                            }
                        },
                        { sTitle: "ID", sAttrName: "id" },
                        { sTitle: "Bezeichnung", sAttrName: "name" }
                    ],
                    getGroupingRowLabel: getRowLabel,
                    click: displayBusinessDocument,
                    groupedByColumn: 0,
                    height: "100%"
                });
        }

        function renderAssignedTags(view) {
            return scrollTableSection({
                filter: true,
                id: "assigned-tags",
                elements: self.assignedTags,
                columns: [
                    {
                        sTitle: "Erstellt am",
                        sAttrName: "created_at",
                        sWidth: "120px",
                        mRender: function(mData, type, obj) {
                            return obj.created_at.toFormatString(app.settings.dateTimeFormat);
                        }
                    },
                    {
                        sTitle: "Name",
                        sAttrName: "name"
                    },
                    {
                        sTitle: "Beschreibung",
                        sAttrName: "description"
                    },
                    {
                        sTitle: "Typ",
                        sAttrName: "tag_type",
                        mRender: function (mData, type, obj) {
                            return tagType[obj.tag_type];
                        }

                    },
                    {
                        sTitle: "Programmiert",
                        sAttrName: "nfc_serial" ,
                        sWidth: "50px",
                        sClass: "center",
                        mRender: function (mData, type, obj) {
                            return obj.nfc_serial ? "Ja" : "Nein";
                        }
                    }
                ],
                height: "100%",
                click: view ? displayTag : null
            });
        }

        function renderAssignedTours() {
            return scrollTableSection({
                filter: true,
                id: "assigned-tours",
                elements: self.assignedTours,
                columns: [
                    {
                        sTitle: "Erstellt am",
                        sAttrName: "created_at",
                        mRender: function(mData, type, obj) {
                            return obj.created_at.toFormatString(app.settings.dateTimeFormat);
                        }
                    },
                    {
                        sTitle: "Name",
                        sAttrName: "name"
                    },
                    {
                        sTitle: "Beschreibung",
                        sAttrName: "description"
                    }
                ],
                click: displayTour,
                height: "100%"
            });
        }

        function displayTour(tour) {
            if(window.app.session.hasPermission(app.model.PermissionTypes.TOUR_MODIFY)) {
                nav.goToPage(pages.tour.detail, [tour, '/customer/manager']);
            }
        }

        function displayWorkplace(workplace) {
            if (self.onTreeNavigate) {
                self.onTreeNavigate("workplace", workplace);
            }
        }

        function getAllEventTypes() {
            return _.map(self.eventTypes, function(eventType) {
                var et = _.find(self.assignedEventTypes, function (et) { return et.id === eventType.id; });
                return et || eventType;
            });
        }

        function getAllBusinessDocuments() {
            return _.map(self.business_documents, function(business_document) {
                var inst = _.find(self.assignedBusinessDocuments, function (inst) { return inst.id === business_document.id; });
                return inst || business_document;
            });
        }
    };

    function displayBusinessDocument(business_document) {
        nav.goToPage(pages.business_document.detail, [business_document.id, true, '/customer/manager']);
    }

    function displayEventType(eventType) {
        nav.goToPage(pages.event_type.detail, [eventType, '/customer/manager']);
    }

    function displayTag(tag) {
        nav.goToPage(pages.tag.detail, [tag, '/customer/manager']);
    }

    /**
     * Renders the object details. Also used for the customer print preview.
     */
    var createObjectDetails = pages.object.detail.createObjectDetails = function (object, view) {
        return fieldset({id: 'object-details'}, [
            ul({'class':'fieldset-content'}, [
                form.formItemText({
                    id: "object-name",
                    required: true,
                    label: "Name",
                    value: object.name,
                    change: function(e) {
                        object.name = $(e.target).val();
                    }
                }, view),

                form.formItemCheckbox({
                    id: "object-active",
                    label: "Aktiv",
                    checked: object.active != null ? object.active : object.active=true,
                    change: function(e) {
                        object.active = $(e.target).prop("checked");
                    }
                }, view)
            ])
        ]);
    }

}) (window.rise, window.app);
