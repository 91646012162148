/**
 * Logbook detail page.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var commandBox = app.widgets.commandBox;

    /*** GLOBALS ***/
    var pages   = app.pages;
    var form    = app.widgets.form;

    /*** Export page ***/
    pages.logbook.hours.detail = app.pagesByPath['/logbook/hours/detail'] = function LogbookDetail() {
        var self = this;
        this.name = "/logbook/hours/detail";

        this.workingHoursListDto = null;

        this.create = function ($parent, workingHoursListDto) {
            this.workingHoursListDto = workingHoursListDto;
            renderLogbook($parent);

            return new $.Deferred(function() {
                this.resolve();
            });
        };

        this.destroy = function() {
            // called after page has been destroyed
        };

        function renderLogbook($parent) {
            $parent.appendElement([

                renderCommandBox(),

                // logbook entry
                renderLogbookForm(self.workingHoursListDto, true)
            ]);

            $parent.activateValidators();
        }

        function renderCommandBox() {
            return commandBox({title:"Arbeitsstunden",backAction:pages.logbook.hours.list});
        }
    };

    // logbook entry
    function renderLogbookForm(workingHoursListDto, view) {
        return fieldset({id: 'logbook-entry-form' }, [
            legend({}, 'Schichtdaten'),
            ul({'class': 'fieldset-content'}, [
                form.formItemText({id: 'logbook-entry-employee', label: 'Benutzer',
                    value: workingHoursListDto.employee_name
                }, view),
                form.formItemText({id: 'logbook-entry-customer', label: 'Kunde',
                    value: workingHoursListDto.customer_name
                }, view),
                form.formItemText({id: 'logbook-entry-location', label: 'Standort',
                    value: workingHoursListDto.location_name
                }, view),
                form.formItemText({id: 'logbook-entry-object', label: 'Objekt',
                    value: workingHoursListDto.object_name
                }, view),
                form.formItemText({id: 'logbook-entry-workplace', label: 'Arbeitsplatz',
                    value: workingHoursListDto.workplace_name
                }, view),
                form.formItemText({id: 'logbook-entry-started', label: 'Start',
                    value: workingHoursListDto.shift_begin.toFormatString(app.settings.dateTimeFormat)
                }, view),
                form.formItemText({id: 'logbook-entry-stopped', label: 'Ende',
                    value: workingHoursListDto.shift_end.toFormatString(app.settings.dateTimeFormat)
                }, view),
                form.formItemText({id: 'logbook-entry-working-hours', label: 'Arbeitsstunden',
                    value: (Math.round(workingHoursListDto.working_time / 36) / 100).toString()
                }, view)
            ])
        ]);
    }

})(window.rise, window.app);
