/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.ShiftLogbookEntry = function ShiftLogbookEntry(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.shift_id =     obj.shift_id != null ? parseInt(obj.shift_id) : null;
        this.is_start =     (obj.is_start === true || obj.is_start === false) ? obj.is_start : null;

        // if model was invoked directly, add also add the LogbookEntry fields
        app.model.LogbookEntry.apply(this, [obj]);
    };

}(window.app));
