/**
 * Page for starting a shift
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav         = rise.Navigation;

    var textButton  = app.widgets.textButton;
    var commandBox = app.widgets.commandBox;

    /** DAOs **/
    var shiftDao = app.dao.shiftDao;

    /*** GLOBALS ***/
    var pages   = app.pages;
    var form    = app.widgets.form;

    /*** Export page ***/
    pages.shift.end = app.pagesByPath['/shift/end'] = function ShiftEnd() {
        var obj = this;
        this.name = "/shift/end";

        this.shift = null;
        this.sessionInfo = null;
        this.workplace = null;

        this.create = function ($parent, shift) {
            obj.sessionInfo = app.session.getSessionInfo();

            obj.workplace = _.object(_.map(obj.sessionInfo.user.workplaces || [], function(item) {
                return [item.id, item];
            }))[obj.sessionInfo.shift.workplace_id];

            obj.shift = shift;
            renderShiftEnd($parent);
        };

        this.destroy = function() {
            // called after page has been destroyed
        };

        function renderShiftEnd($parent) {

            //noinspection JSHint
            $parent.appendElement([
                commandBox({title:"Dienst"}),

                renderShiftEndForm(obj.shift, obj.workplace),
                div({ "class":"action-box bottom" },
                    ul({}, [
                        li({}, textButton({id: 'end-shift', click: function() {
                            if ($parent.validate()) {
                                var actionDeferred = new $.Deferred();

                                shiftDao.endShift(obj.shift)
                                    .done(function() {
                                        app.session.refreshSessionInfo().done(function() {
                                            app.widgets.notificationWidget.refresh();
                                            nav.goToPage(pages.shift.begin);

                                            app.showSuccessMessage('Schicht beendet.');
                                            actionDeferred.resolve();
                                        });
                                    })
                                    .fail(function() {
                                        actionDeferred.resolve();
                                    });

                                return actionDeferred.promise();
                            }
                        }}, 'Schicht beenden')),
                        li({}, textButton({id: 'end-shift-logout', click: function() {
                            if ($parent.validate()) {
                                var actionDeferred = new $.Deferred();

                                shiftDao.endShift(obj.shift)
                                    .done(function() {
                                        app.session.logout()
                                            .done(function() {
                                                app.showSuccessMessage('Schicht beendet.');
                                                actionDeferred.resolve();
                                            });
                                    })
                                    .fail(function() {
                                        actionDeferred.resolve();
                                    });

                                return actionDeferred.promise();
                            }
                        }}, 'Schicht beenden und ausloggen')),
                        li({}, textButton({id: 'end-logout', click: function() {
                            return app.session.logout();
                        }}, 'Ausloggen ohne Schicht beenden'))
                    ])
                )
            ]);

            $.validation.addValidators({
                '.valid-shift-end-before-24h': [
                    function(e) {
                        var shiftEnd = $(e.target).datepicker('getDate');
                        var dateBoundary = new Date(obj.shift.started);
                        dateBoundary.setHours(dateBoundary.getHours() + 24);

                        return shiftEnd <= dateBoundary;
                    },
                    'Schichtende ist mehr als 24h nach Schichtbeginn',
                    'change'
                ],
                '.valid-shift-end-after-start': [
                    function(e) {
                        var shiftEnd = $(e.target).datepicker('getDate');

                        return shiftEnd >= obj.shift.started;
                    },
                    'Schichtende liegt vor Schichtbeginn',
                    'change'
                ],
                '.valid-shift-end-not-in-future': [
                    function(e) {
                        var shiftEnd = $(e.target).datepicker('getDate');
                        var dateBoundary = new Date();
                        dateBoundary.setMinutes(dateBoundary.getMinutes() + 30);

                        return shiftEnd <= dateBoundary;
                    },
                    'Schichtende liegt mehr als 30 Minuten in der Zukunft',
                    'change'
                ]
            });
            $parent.activateValidators();
        }
    };

    function renderShiftEndForm(shift, workplace) {
        return fieldset({id: 'shift-edit'}, [
            legend(),
            ul({'class': 'fieldset-content'}, [
                li({'class': 'fieldset-item'}, [
                    label('Arbeitsplatz'),
                    span({id: 'shift-workplace'}, workplace && workplace.name || "")
                ]),
                li({'class': 'fieldset-item'}, [
                    label('Beginn'),
                    span({id: 'shift-started'}, shift.started.toDateTimeString())
                ]),
                form.formItemDateTime({id: 'shift-stopped', label: 'Ende', required: true,
                    date: shift.stopped || getDefaultShiftStopped(shift.started),
                    validation: 'valid-shift-end-after-start valid-shift-end-before-24h valid-shift-end-not-in-future',
                    change: function() {
                        shift.setStoppedRounded($(this).datepicker('getDate'));
                    }
                })
            ])
        ]);
    }

    function getDefaultShiftStopped(shiftBegin) {
        var currentDate = new Date();

        if(currentDate < shiftBegin)  {
            return shiftBegin;
        } else {
            return currentDate;
        }

    }

})(window.rise, window.app);
