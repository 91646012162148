/**
 * Data Access Object for the tour_report endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;

    /*** GLOBAL ***/
    var self;

    /*** Export Dao ***/
    app.dao.tourReportDao = new TourReportDao();

    /**
     * @constructor
     */
    function TourReportDao() {
        self = this;
        this.rpc = new Rpc('/rpc/tour_report');
    }

    // extend the parent Dao prototype
    $.extend(TourReportDao.prototype, Object.create(rise.Dao.prototype));

    TourReportDao.prototype.getWeekReports = function (dateFrom, dateTo, customerId, locationId, objectId) {
        var deferred = new $.Deferred(),
            params = getFilterParams(arguments);

        self.rpc.invoke('get_week_reports', {filter_params: params})
            .done(function(result) {
                deferred.resolve(result);
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TourReportDao.prototype.getDayReports = function (dateFrom, dateTo, customerId, locationId, objectId) {
        var deferred = new $.Deferred(),
            params = getFilterParams(arguments);

        self.rpc.invoke('get_day_reports',  {filter_params: params})
            .done(function(result) {
                deferred.resolve(result);
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TourReportDao.prototype.getTimecViolatingTours = function (dateFrom, dateTo, customerId, locationId, objectId,
                                                               tour_area_id, user_id, filter_expression,
                                                               offset, limit) {
        var deferred = new $.Deferred(),
            params = getFilterParams(arguments);

        self.rpc.invoke('get_timec_violating_tours',  {filter_params: params})
            .done(function(result) {
                deferred.resolve(result);
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TourReportDao.prototype.getTimecViolations = function (dateFrom, dateTo, customerId, locationId, objectId,
                                                           tour_area_id, user_id, filter_expression) {
        var deferred = new $.Deferred(),
            params = getFilterParams(arguments);

        self.rpc.invoke('get_timec_violations',  {filter_params: params})
            .done(function(result) {
                deferred.resolve(result);
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    function getFilterParams(params) {
        return {
            datetime_from: params[0],
            datetime_to: params[1],
            customer_id: params[2],
            location_id: params[3],
            object_id: params[4],
            tour_area_id: params[5],
            user_id: params[6],
            filter_expression: params[7],
            offset: params[8],
            limit: params[9],
        };
    }

})(window.rise, window.app);

export const tourReportDaoLegacy = app.dao.tourReportDao;
