/**
 * This is the dto for the tasks
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.dto.UserListDto = function UserListDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id ? parseInt(obj.id) : null;
        this.first_name = obj.first_name ? obj.first_name : null;
        this.last_name = obj.last_name ? obj.last_name : null;
        this.username = obj.username ? obj.username : null;
        this.type = app.model.UserType.valueOf(obj.type);
        this.active = obj.active === true ? true : (obj.active === false ? false : null);

        this.customer = obj.customer != null ?
            new app.model.Customer(obj.customer)
            : null;

        this.location = obj.location != null ?
            new app.model.Location(obj.location)
            : null;
    };

    app.dto.UserDetailsDto = function UserDetailsDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.user = obj.user != null
            ? new app.model.User(obj.user)
            : null;

        this.assigned_customer = obj.assigned_customer
            ? new app.model.Customer(obj.assigned_customer)
            : null;

        // map workplaces
        this.assigned_workplaces = obj.assigned_workplaces
            ? _.map(obj.assigned_workplaces, function (json) { return new app.dto.WorkplaceListDto(json); })
            : [];
    };

}(window.app));
