/**
 * This is the dto for the business_document
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    app.dto.BusinessDocumentWithAssignmentInfoDto = function BusinessDocumentWithAssignmentInfoDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id ? parseInt(obj.id, 10) : null;
        this.name = obj.name ? obj.name : null;
        this.typ = obj.typ != null ? obj.typ : null;
        this.category_name = obj.category_name != null ? obj.category_name : null;

        this.assigned_to_customer = obj.assigned_to_customer ? obj.assigned_to_customer === true : null;
        this.assigned_to_location = obj.assigned_to_location ? obj.assigned_to_location === true : null;
        this.assigned_to_object = obj.assigned_to_object ? obj.assigned_to_object === true : null;
        this.assigned_to_workplace = obj.assigned_to_workplace ? obj.assigned_to_workplace === true : null;
    };

    app.dto.BusinessDocumentReadConfirmationListDto = function BusinessDocumentReadConfirmationListDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.user_id = obj.user_id ? parseInt(obj.user_id, 10) : null;
        this.user_last_name = obj.user_last_name ? obj.user_last_name : null;
        this.user_first_name = obj.user_first_name != null ? obj.user_first_name : null;
        this.user_name = obj.user_name != null ? obj.user_name : null;

        this.business_document_read_first_at = obj.business_document_read_first_at ?
            typeof obj.business_document_read_first_at === 'string' ?
                Date.fromISO(obj.business_document_read_first_at)
                : obj.business_document_read_first_at
            : null;

        this.business_document_read_confirmed_at = obj.business_document_read_confirmed_at ?
            typeof obj.business_document_read_confirmed_at === 'string' ?
                Date.fromISO(obj.business_document_read_confirmed_at)
                : obj.business_document_read_confirmed_at
            : null;
    };

    app.dto.BusinessDocumentListDto = function BusinessDocumentListDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id =               obj.id != null ? parseInt(obj.id) : null;

        this.name =             obj.name != null ? obj.name : null;
        this.typ =              obj.typ != null ? obj.typ : null;
        this.active =           (obj.active === true || obj.active === false) ? obj.active : null;
        this.category_name = obj.category_name != null ? obj.category_name : null;
        this.read_confirmed_at =obj.read_confirmed_at != null ? obj.read_confirmed_at : null;

        this.read_first_count = obj.read_first_count != null ? parseInt(obj.read_first_count, 10) : null;
        this.read_confirmed_count = obj.read_confirmed_count != null ? parseInt(obj.read_confirmed_count, 10) : null;

        /**
         * set on server side
         * @type {JustDate}
         */
        this.created_at =       obj.created_at ?
            typeof obj.created_at === 'string' ?
                Date.fromISO(obj.created_at)
                : obj.created_at
            : null;
    };

}(window.app));
