/**
 * Logging module.
 *
 * @module rise.Logging
 * @author Christoph Mayerhofer
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 * ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise) {
    "use strict";

    /**
     * Singleton logging module. This instance should be imported by clients:
     * var logging = window.rise.logging;
     */
    var logging = rise.logging = {};

    /*
     * Saved logger instances (private), initialized with a root logger.
     */
    var loggers = {};

    /**
     * Default logging settings. Overwrite with `setDefaults`.
     * @type {{root: {level: string, appender: string}}}
     */
    var settings = {
        root: {
            level: "ERROR",
            appender: "ConsoleAppender"
        }
    };

    /**
     * Log level enumeration from debug (all) to none.
     * @type {Object} Log levels
     */
    var logLevel = logging.logLevel = {
        DEBUG: 1,
        INFO: 2,
        WARN: 3,
        ERROR: 4,
        FATAL: 5,
        NONE: 6
    };

    /**
     * Log level id to name mapping. Useful to translate
     * the log level to a string.
     * @type {Object} Log level names
     */
    logging.levelName = (function invert(logLevels) {
        var names = {};
        _.each(logLevels, function(val, key) {
            names[val] = key;
        });
        return names;
    }(logging.logLevel));

    /**
     * Get the logger instance with the specified name. If the logger instance
     * has not been instantiated yet, create one and return it. If the name
     * parameter has not been provided, return the "root" logger instance.
     *
     * @param {String} name The logger name (optional).
     * @return {Logger} The logger instance.
     */
    logging.getLogger = function(name) {
        var _name = name || "root";

        // setup new logger instance
        if (!loggers[name]) {
            var logger = loggers[name] = new logging.Logger(),
            // root logger settings
            // use root logging config
                rootSettings = settings["root"] || { level: "INFO" },
            // specific logger settings
            // use logger config or root fallback if logger config not exists
                logSettings = settings[name] || rootSettings;

            if (logSettings.level || rootSettings.level) {
                logger.setLevel(logLevel[logSettings.level || rootSettings.level]);
            }

            if (logSettings.appender || rootSettings.appender) {
                logger.addAppender(logging.getAppender(logSettings.appender || rootSettings.appender));
            }
        }
        return loggers[name];
    };

    /**
     * Returns a well known logging appender. Currently known by this
     * logging module:
     *  - ConsoleAppender
     *
     * @param {String} name The name of the appender class.
     * @return {Object} The appender instance, or undefined if appender is not known.
     */
    logging.getAppender = function(name) {
        if (logging[name] && (logging[name].append || logging[name].prototype.append)) {
            return new logging[name]();
        }
        throw "Appender " + name + " is not defined!";
    };

    // aliases to the log-level enumeration
    _.each(logLevel, function(val, key) {
        logging[key] = val;
    });


    /**
     * Set the logging configuration defaults.
     * @param {*} defaults Logging configuration object.
     */
    logging.setDefaults = function(defaults) {
        if (!defaults) {
            throw 'Only objects allowed for default settings in logger';
        }

        if (!_.isObject(defaults) || _.isArray(defaults)) {
            throw 'Only objects allowed for default settings in logger';
        }
        settings = defaults;
    };

}(window.rise));
