/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.Role = function Role(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id =               obj.id != null ? parseInt(obj.id) : null;
        this.active =           (obj.active === true || obj.active === false) ? obj.active : null;

        this.label =            obj.label != null ? obj.label : null;
        this.internal =         (obj.internal === true || obj.internal === false) ? obj.internal : null;

        this.permissions = _.map(obj.permissions || [], function(permissions) {
            return new app.model.Permission(permissions);
        });

        this.users = _.map(obj.users || [], function(user) {
            return new app.model.User(user);
        });

        this.type = app.model.RoleType.valueOf(obj.type);

        this._role_id_permissions =  obj._role_id_permissions != null ?
            parseInt(obj._role_id_permissions) :
            null;
        this._role_id_users  =  obj._role_id_users != null ?
            parseInt(obj._role_id_users) :
            null;
    };


    app.model.Role.INTERNAL_ROLE = {
        'ADMIN'         : -1,
        'CUSTOMER'      : -2,
        'EMPLOYEE'      : -3,
        'SUPERVISOR'    : -4
    };

    app.model.RoleType = {
        CUSTOMER: 'CUSTOMER',
        EMPLOYEE: 'EMPLOYEE',

        valueOf: function(value) {
            return this.hasOwnProperty(value) ? value : null;
        },

        values: function() {
            return _.filter(_.values(this), function(el) { return !_.isFunction(el); });
        }
    };

}(window.app));
