/**
 * Displays a list of all tags
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav        = rise.Navigation;
    var clowFilter = app.widgets.clowFilter;
    var form       = app.widgets.form;

    /** DAOs **/
    var TagDao      = app.dao.tagDao;
    var CustomerDao = app.dao.customerDao;
    var TourDao     = app.dao.tourDao;

    /*** GLOBALS ***/
    var pages = app.pages;
    var tagType = {
        'CHECKPOINT': 'Kontrollpunkt',
        'START': 'Start',
        'END': 'Ende'
    };

    var filter = {};

    /*** CONSTANTS ***/
    var TAG_ID_COLUMN_INDEX = 1;

    /*** Export Pages ***/
    pages.tag.list = app.pagesByPath['/tag/listall'] = TagList;

    /*** Set permission ***/
    TagList.permissions = [app.model.PermissionTypes.TOUR_MODIFY];

    function TagList() {

        var self = app.widgets.ListPage.call(this, {
            pageName: '/tag/listall',
            rpc: function () {
                return [
                    CustomerDao.findAll(),
                    TourDao.findAll()
                ];
            },
            prefix: "tag",
            labels: {
                pageHeader: "Kontrollstellen",
                buttonNew: "Neue Kontrollstelle",
                filterAll: "Alle Kontrollstellen"
            },
            filterSearchFieldInactive: true,
            "export":
                window.app.session.hasPermission(app.model.PermissionTypes.TOUR_MODIFY)
                    ? {
                    method: "export_logical_tags",
                    criteriaFn: getFilterCriteria
                }
                : undefined
        });

        this.prepareTable = function($parent, customers, tours) {
            self.parent = $parent;

            var columns = getTableColumns();
            var settings = getTableSettings();

            this.renderTable(null, columns, settings);

            var $filters =  $("#placeholder-filters");

            $filters.prependElement(
                clowFilter({
                    customers: customers,
                    change: function(selection) {
                        var newCustomerID = selection.customer !== -1 ? selection.customer : undefined,
                            newLocationID = selection.location !== -1 ? selection.location : undefined,
                            newObjectID = selection.object !== -1 ? selection.object : undefined,
                            oldCustomerID = filter.customer_id,
                            oldLocationID = filter.location_id,
                            oldObjectID = filter.object_id;

                        filter.customer_id = newCustomerID;
                        filter.location_id = newLocationID;
                        filter.object_id = newObjectID;

                        if (newCustomerID !== oldCustomerID || newLocationID !== oldLocationID ||
                            newObjectID !== oldObjectID) {
                            filterTagEntries($parent, self.datatable);
                        }
                    },
                    depth: 3
                })
            );

            $filters.find("li:first").beforeElement(
                form.formItemText({id: 'tag-filter-search', label: 'Suche', required: false,
                    style: "width: 250px",
                    name: "filter_expression",
                    keyup: function () {
                        filter.filter_expression = $(this).val();
                    },
                    keypress: function (e) {
                        var keyCode = e.which;
                        if (keyCode === 13){
                            filterTagEntries($parent, self.datatable);
                            return false;
                        }
                    },
                    change: function () {
                        filterTagEntries($parent, self.datatable);
                    }
                })
            );
            $filters.find("li:last").afterElement(
                createTagTypeSelector($parent, self.datatable)
            );
            $filters.find("li:last").afterElement(
                createStateSelector($parent, self.datatable)
            );
            $filters.find("li:last").afterElement(
                createTourSelector($parent, tours.rows, self.datatable)
            );
        };

        this.savePageState = function() { };

        this.loadPageState = function() {
            this.updateTableServerData();
        };

        this.showDetailsPage = function(tag) {
            showDetailsPage(tag);
        };

        function filterTagEntries($parent, datatable) {
            if ($parent.validate()) {
                datatable.fnDraw();
            }

            /* fetch the tours again with the currently selected filter params */
            TourDao.findAll(filter).done(function (data) {
                var $filters =  $("#placeholder-filters");

                $filters.find("li:last").remove();
                $filters.find("li:last").afterElement(
                    createTourSelector($parent, data.rows, self.datatable)
                );
            });

        }

        function createTagTypeSelector($parent, datatable) {
            var tagTypes = [
                { id: 'ALL', name: 'Alle' },
                { id: 'CHECKPOINT', name: 'Kontrollpunkt' },
                { id: 'START', name: 'Start' },
                { id: 'END', name: 'Ende' }
            ];

            return form.formItemSelect({id: 'filter-tag-type', label: 'Typ', required: false,
                source: tagTypes, valueProp: 'name',
                value: tagTypes[0],
                sort: false,
                selected: function (e, tagType) {
                    var oldVal = filter.tag_type,
                        newVal = tagType.id === 'ALL' ? undefined : tagType.id;

                    if (oldVal !== newVal) {
                        filter.tag_type = newVal;
                        filterTagEntries($parent, datatable);
                    }
                }
            });
        }

        function createStateSelector($parent, datatable) {
            var stateTypes = [
                { id: 'ALL', name: 'Alle', value: undefined },
                { id: 'PROGRAMMED', name: 'Programmiert', value: true },
                { id: 'NOT_PROGRAMMED', name: 'Nicht Programmiert', value: false }
            ];

            return form.formItemSelect({id: 'filter-programmed', label: 'Zustand', required: false,
                source: stateTypes, valueProp: 'name',
                value: stateTypes[0],
                sort: false,
                selected: function (e, stateType) {
                    if (filter.programmed !== stateType.value) {
                        filter.programmed = stateType.value;
                        filterTagEntries($parent, datatable);
                    }
                }
            });
        }

        function createTourSelector($parent, tours, datatable) {
            var all = {'name': "Alle", 'id': null};

            var selectedTour = _.find(tours, function(t) {
                return t.id === filter.tour_id;
            });

            if (selectedTour === undefined) {
                selectedTour = all;
            }

            return form.formItemSelect({id: 'filter-tour', label: 'Rundgang', required: false,
                source: tours,
                valueProp: 'name',
                value: selectedTour,
                firstElem: all,
                sort: true,
                selected: function (e, tour) {
                    if (filter.tour_id !== tour.id) {
                        filter.tour_id = tour.id;
                        filterTagEntries($parent, datatable);
                    }
                }
            });
        }

        function getFilterCriteria() {
            return {
                filter_params: filter
            };
        }

        function showDetailsPage(tag) {
            nav.goToPage(pages.tag.detail, tag ? [ tag ] : undefined);
        }

        function getTableColumns() {
            return [
                {
                    sTitle: "Erstellt am",
                    sAttrName: "created_at",
                    sWidth: "120px",
                    mRender: function(mData, type, obj) {
                        return obj.created_at.toFormatString(app.settings.dateTimeFormat);
                    }
                },
                {
                    sTitle: "Name",
                    sAttrName: "name"
                },
                {
                    sTitle: "Beschreibung",
                    sAttrName: "description"
                },
                {
                    sTitle: "Kunde",
                    sAttrName: "customer_name"
                },
                {
                    sTitle: "Standort",
                    sAttrName: "location_name"
                },
                {
                    sTitle: "Objekt",
                    sAttrName: "object_name"
                },
                {
                    sTitle: "Typ",
                    sAttrName: "tag_type",
                    mRender: function (mData, type, obj) {
                        return tagType[obj.tag_type];
                    }
                },
                {
                    sTitle: "Programmiert",
                    sAttrName: "nfc_serial" ,
                    sWidth: "50px",
                    sClass: "center",
                    mRender: function (mData, type, obj) {
                        return obj.nfc_serial ? "Ja" : "Nein";
                    }
                }
            ];
        }

        function getTableSettings() {
            return {
                aaSorting: [[ TAG_ID_COLUMN_INDEX, "asc" ]],
                bServerSide: true,
                fnServerData: function (sSource, aoData, fnCallback) {
                    aoData = _.object(_.map(aoData, function (oData) {
                        return [oData.name, oData.value];
                    }));

                    var params = getFilterCriteria();
                    params.filter_params = $.extend({
                        offset: aoData.iDisplayStart,
                        limit: aoData.iDisplayLength,
                        sort_column: aoData.iSortCol_0,
                        sort_direction: aoData.sSortDir_0
                    }, params.filter_params);

                    TagDao.findAll(params).done(function (data) {
                        fnCallback({
                            aaData: data.rows,
                            iTotalRecords: data.totalCount,
                            iTotalDisplayRecords: data.filteredCount,
                            sEcho: aoData.sEcho
                        });
                    });
                }
            };
        }
    }
})(window.rise, window.app);
