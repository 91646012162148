import $ from 'jquery';
import React, { ReactPortal, useCallback, useEffect, useState } from 'react';

import { DataTable } from 'app/legacy/widgets/table';

import { ReportSchedule } from 'app/report-schedule/ReportSchedule';
import { ReportScheduleTaskDto } from 'app/report-schedule/dto/ReportScheduleTaskDto';
import { reportScheduleTaskDao } from 'app/report-schedule/dao/ReportScheduleTaskDao';
import { ReportScheduleTaskStatus, ReportScheduleTaskStatusLabels } from 'app/report-schedule/ReportScheduleConstants';
import { createPortal } from 'react-dom';

const { app } = window as any;

const taskTableWidget = new DataTable({
    prefix: 'report-schedule-tasks',
    options: {
        noWhiteboard: false,
    },
    keyboardNavigationEnabled: false,
});

type Props = {
    reportId: ReportSchedule['id'];
};

export function ReportScheduleTaskHistory(props: Props) {
    const [reportHistory, setReportHistory] = useState<Array<ReportScheduleTaskDto>>([]);
    const [downloadLinks, setDownloadLinks] = useState<Array<ReactPortal>>([]);
    const { reportId } = props;

    useEffect(() => {
        (async () => {
            if (reportId) {
                const result = await reportScheduleTaskDao.findByReportScheduleId(reportId);
                setReportHistory(result);
            }
        })();
    }, [reportId]);

    const renderTaskTableWidget = useCallback(async (parent) => {
        if (!parent) {
            return;
        }

        const links = [];
        parent.replaceChildren();
        taskTableWidget.create($(parent));
        taskTableWidget.renderTable(
            reportHistory,
            [
                {
                    sTitle: 'Geplant am',
                    sAttrName: 'planned_at',
                    mRender(mData, type, obj) {
                        if (type === 'display') {
                            return obj.planned_at?.toFormatString(app.settings.dateTimeFormat) ?? '';
                        }
                        return obj.planned_at?.getTime();
                    },
                },
                {
                    sTitle: 'Abgeschlossen am',
                    sAttrName: 'finished_at',
                    mRender(mData, type, obj) {
                        if (type === 'display') {
                            return obj.finished_at?.toFormatString(app.settings.dateTimeFormat) ?? '';
                        }
                        return obj.finished_at?.getTime();
                    },
                },
                {
                    sTitle: 'Fehlgeschlagen am',
                    sAttrName: 'failed_at',
                    mRender(mData, type, obj) {
                        if (type === 'display') {
                            return obj.failed_at?.toFormatString(app.settings.dateTimeFormat) ?? '';
                        }
                        return obj.failed_at?.getTime();
                    },
                },
                {
                    sTitle: 'Fehlerdetails',
                    bSortable: false,
                    sAttrName: 'error_details',
                    mRender(mData, type, obj) {
                        return obj.status === ReportScheduleTaskStatus.FINISHED ? '' : obj.error_details;
                    },
                },
                {
                    sTitle: 'Status',
                    sAttrName: 'status',
                    mRender(mData, type, obj) {
                        return ReportScheduleTaskStatusLabels[obj.status];
                    },
                },
                {
                    sTitle: 'Aktionen',
                    bSortable: false,
                    sAttrName: 'status',
                    mRender() {
                        return '';
                    },
                    createdCell(cell, cellData, rowData) {
                        if (rowData.status !== ReportScheduleTaskStatus.FINISHED) {
                            return;
                        }

                        function submitForm(e) {
                            if (e.type === 'keydown' && !['Enter', 'Space'].includes(e.code)) {
                                return;
                            }

                            const link = e.target as HTMLAnchorElement;
                            const form = link.parentElement as HTMLFormElement;
                            form.submit();
                        }

                        const form = (
                            <form method="post" action="/report_schedule_attachment">
                                <input type="hidden" name="method" value="download" />
                                <input type="hidden" name="id" value={rowData.id} />
                                <input type="hidden" name="X-Session-Id" value={app.session.getSessionId()} />
                                <a
                                    className="link-button"
                                    role="button"
                                    tabIndex={0}
                                    onClick={submitForm}
                                    onKeyDown={submitForm}
                                >
                                    Herunterladen
                                </a>
                            </form>
                        );

                        links.push(createPortal(form, cell));
                    },
                },
            ],
            {
                aaSorting: [
                    [0, 'desc'],
                ],
                bServerSide: false,
            },
        );

        setDownloadLinks(links);
    }, [reportHistory]);

    return (
        <>
            <div ref={renderTaskTableWidget} />
            {downloadLinks}
        </>
    );
}
