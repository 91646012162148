import { Dao } from 'lib/rise/dao';
import { TourReport } from 'app/tour-report/model/TourReport';
import { Rpc } from 'lib/rise/rpc';
import { TourReportWeek } from 'app/tour-report/model/TourReportWeek';
import { TourReportDay } from 'app/tour-report/model/TourReportDay';
import { tourReportDaoLegacy } from 'app/legacy/dao/tour-report-dao';

export type TourReportWeeksResponse = Array<TourReportWeek>;
export type TourReportDaysResponse = Array<TourReportDay>;

class TourReportDao extends Dao<TourReport, TourReport> {
    constructor() {
        super(TourReport, new Rpc('/rpc/tour_report'), TourReport);
    }

    private static getFilterParams(dateFrom, dateTo, customerId, locationId, objectId,
                                   tourAreaId?, userId?, filterExpression?) {
        return {
            datetime_from: dateFrom,
            datetime_to: dateTo,
            customer_id: customerId,
            location_id: locationId,
            object_id: objectId,
            tour_area_id: tourAreaId ?? null,
            user_id: userId ?? null,
            filter_expression: filterExpression ?? null,
        };
    }

    getWeekReports(dateFrom, dateTo, customerId, locationId, objectId): Promise<TourReportWeeksResponse> {
        const params = TourReportDao.getFilterParams(dateFrom, dateTo, customerId, locationId, objectId);

        return new Promise((resolve, reject) => {
            this.rpc.invoke('get_week_reports', { filter_params: params })
                .done((result) => {
                    const weekReports = Object.entries<[string, TourReport]>(result).map(
                        ([week, weekData]) => new TourReportWeek({ week, ...weekData }),
                    );
                    resolve(weekReports);
                })
                .fail((error) => reject(error));
        });
    }

    getDayReports(dateFrom, dateTo, customerId, locationId, objectId): Promise<TourReportDaysResponse> {
        const params = TourReportDao.getFilterParams(dateFrom, dateTo, customerId, locationId, objectId);

        return new Promise((resolve, reject) => {
            this.rpc.invoke('get_day_reports', { filter_params: params })
                .done((result) => {
                    const dayReports = Object.entries<[string, TourReport]>(result).map(
                        ([day, dayData]) => new TourReportDay({ day, ...dayData }),
                    );
                    resolve(dayReports);
                })
                .fail((error) => reject(error));
        });
    }

    // eslint-disable-next-line class-methods-use-this
    getTimecViolatingTours(dateFrom, dateTo, customerId, locationId, objectId) {
        return new Promise((resolve, reject) => {
            tourReportDaoLegacy.getTimecViolatingTours(dateFrom, dateTo, customerId, locationId, objectId)
                .done((result) => resolve(result))
                .fail((error) => reject(error));
        });
    }

    // eslint-disable-next-line class-methods-use-this
    getTimecViolations(dateFrom, dateTo, customerId, locationId, objectId, tourAreaId, userId, filterExpression) {
        return new Promise((resolve, reject) => {
            tourReportDaoLegacy.getTimecViolations(
                dateFrom, dateTo, customerId, locationId, objectId, tourAreaId, userId, filterExpression,
            ).done((result) => resolve(result)).fail((error) => reject(error));
        });
    }
}

export const tourReportDao = new TourReportDao();
