/**
 * Displays reports.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav              = rise.Navigation;
    var form             = app.widgets.form;
    var commandBox       = app.widgets.commandBox;
    var tabsWidget       = app.widgets.tabsWidget;
    var printIconButton  = app.widgets.printIconButton;
    var cancelIconButton = app.widgets.cancelIconButton;
    var DataTable        = app.widgets.DataTable;
    var Map              = app.widgets.Map;
    var printReport      = app.widgets.print.tourReport;
    var printEventReport = app.widgets.print.tourEventReport;
    var TourAreaPage     = app.pages.tour_area.detail;

    /** DAOs **/
    var tourRunDao       = app.dao.tourRunDao;
    var tourAreaDao      = app.dao.tourAreaDao;
    var tourReportDao    = app.dao.tourReportDao;

    /*** GLOBALS ***/
    var pages           = app.pages;

    /*** CONSTANTS ***/
    var FILTER_DATE_STARTED = 1;
    var FETCH_LIMIT_TOUR_RUN_DETAILS = 10;
    var MAX_FILTERED_TIMESPAN_IN_DAYS = 14;

    var filter = {
        started: null,
        stopped: null,
        correct_filter: "ALL"
    };

    /*** Export Pages ***/
    pages.report.tour_area = app.pagesByPath['/report/tour-area'] = TourAreaReport;

    /*** Set permission ***/
    TourAreaReport.permissions = [
        app.model.PermissionTypes.TOUR_MODIFY,
        app.model.PermissionTypes.TOUR_AREA_REPORT_OVERVIEW_VIEW,
    ];

    function TourAreaReport() {
        var self = this;

        // specify lookup date span
        if (!filter.stopped) {
            filter.stopped = new Date();
            filter.started = new Date();

            // set time offset for start date
            filter.started.addDays(-FILTER_DATE_STARTED);

            // set time component of start date to 00:00
            filter.started = new JustDate(filter.started).toDate();

            // set time component of end date to 23:59
            filter.stopped = new Date(new JustDate().toDate().getTime()
                + 86399000);
        }

        self.name = '/report/tour-area';
        self.pageHeader = "Revier Report";
        self.prefix = "tour-area-report";

        self.rpc = [
            tourRunDao.getUserFilterList(),
            tourAreaDao.findAll()
        ];

        self.create = function ($parent) {
            self.$parent = $parent;

            self.abortedLoadOperations = {};

            /* jshint indent: false */
            return $.when.apply(self, self.rpc).done(function (users, tourAreas) {
                filter.users = users;
                filter.tourAreas = tourAreas;

                renderCommandBox($parent);
                renderFilterBar($parent);
                renderContent($parent);

                self._addCustomValidators();
                self.$parent.activateValidators();
            });
        };

        self.applyFilter = function() {
            self.$parent.find("label.error").remove();
            if (!self.$parent.validate()) {
                return;
            }

            // do not filter if the filter condition has not changed
            if (self.activeFilter && self.activeFilter === filter) {
                return;
            } else {
                self.activeFilter = _.extend({}, filter);
            }

            if (self.tableFinishedTourRuns && self.tableToursWithViolations) {
                self.tableFinishedTourRuns.updateTableServerData();
                self.tableToursWithViolations.updateTableServerData();
            }

            if (self.map) {
                self._updateMap();
            }

            refreshTourAreaDefinitionTab();
        };

        self.detach = function() {
            // nothing to do
        };

        self.attach = function() {
            // nothing to do
        };

        self._getTableSettingsForFinishedTourRuns = function() {
            return {
                aaSorting: [
                    [ 2, "desc" ]
                ],
                bServerSide: true,
                fnServerData: function (sSource, aoData, fnCallback) {
                    aoData = _.object(_.map(aoData, function (oData) {
                        return [oData.name, oData.value];
                    }));

                    tourRunDao.findFinishedTourRuns($.extend({
                        offset: aoData.iDisplayStart,
                        limit: aoData.iDisplayLength,
                        sort_column: aoData.iSortCol_0,
                        sort_direction: aoData.sSortDir_0
                    }, self._getFilterCriteria())).done(function (data) {
                        fnCallback({
                            aaData: data.rows,
                            iTotalRecords: data.totalCount,
                            iTotalDisplayRecords: data.filteredCount,
                            sEcho: aoData.sEcho
                        });
                    });
                }
            };
        };

        self._getTableSettingsForTimeCViolations = function() {
            return {
                aaSorting: [
                    [ 0, "desc" ]
                ],
                bServerSide: true,
                fnServerData: function (sSource, aoData, fnCallback) {
                    aoData = _.object(_.map(aoData, function (oData) {
                        return [oData.name, oData.value];
                    }));

                    tourReportDao.getTimecViolatingTours(
                        filter.started.toISOString(),
                        filter.stopped.toISOString(),
                        undefined, undefined, undefined,
                        filter.tour_area_id != null ? filter.tour_area_id : -1, filter.user_id, filter.filterExpression,
                        aoData.iDisplayStart, aoData.iDisplayLength,
                    ).done(function(data) {
                        fnCallback({
                            aaData: data.rows,
                            iTotalRecords: data.total_count,
                            iTotalDisplayRecords: data.filtered_count,
                            sEcho: aoData.sEcho
                        });
                    });
                }
            };
        };

        self._updateMap = function(offset, ts) {
            if (!offset) {
                self.checkpointsByUser = {};
                self.mapCenterLocation = { lng: 0, lat: 0 };
                self.tagsOnMap = 0;

                offset = 0;

                if (self.activeLoadOperation) {
                    self.abortedLoadOperations[self.activeLoadOperation] = true;
                }

                self.activeLoadOperation = ts = new Date().getTime();

                // hide map until all data have been fetched
                self.$parent.find('.map-section').hide();

                // show progress indicator
                self.$parent.find('.map-progress-info').show().text("Rundgänge werden geladen ...");
                self.$parent
                    .find('.map-progress-indicator')
                    .show()
                    .progressbar({
                        value: 0
                    });
                self.$parent.find('.map-progress-buttons').show();
            }

            tourRunDao.findFinishedTourRunDetails(
                $.extend({
                    offset: offset,
                    limit: FETCH_LIMIT_TOUR_RUN_DETAILS,
                    sort_column: 1,
                    sort_direction: 'asc'
                }, self._getFilterCriteria()), false, true, false)
                .done(function (result) {
                    if (result.filtered_count === 0) {
                        self.$parent
                            .find('.map-progress-indicator')
                            .hide();
                        self.$parent.find('.map-progress-buttons').hide();
                        self.$parent.find('.map-progress-info') .text("Für den gewählten Zeitraum wurden keine Rundgänge gefunden.");
                        return;
                    } else if (self.abortedLoadOperations[ts]) {
                        if (!self.activeLoadOperation) {
                            self.$parent
                                .find('.map-progress-indicator')
                                .hide();
                            self.$parent.find('.map-progress-buttons').hide();
                            self.$parent.find('.map-progress-info') .text("Der Ladevorgang wurde unterbrochen.");
                        }
                        return;
                    }

                    self.$parent
                        .find('.map-progress-indicator')
                        .progressbar({
                            value: Math.round(100.0 / parseFloat(result.filtered_count) * parseFloat(offset))
                        });
                    self.$parent.find('.map-progress-info').text(sprintf("Rundgänge werden geladen (%s/%s)",
                            offset + result.rows.length, result.filtered_count));

                    _.each(result.rows, function(tourRunDetails) {
                        var tourRun = tourRunDetails.tourRun,
                            username = tourRunDetails.tourRun.user_name,
                            lat = 0, lng = 0, numTags = 0;

                        _.each(tourRunDetails.checkpoints, function(checkpoint) {
                            if (checkpoint.latitude && checkpoint.longitude) {
                                lat += checkpoint.latitude;
                                lng += checkpoint.longitude;
                                numTags += 1;
                            }
                        });

                        if (numTags > 0) {
                            lat = lat / numTags;
                            lng = lng / numTags;

                            self.mapCenterLocation.lat += lat;
                            self.mapCenterLocation.lng += lng;
                            self.tagsOnMap += 1;

                            self.checkpointsByUser[username] = (self.checkpointsByUser[username] || []).concat({
                                tour_run: tourRun,
                                lat: lat,
                                lng: lng
                            });
                        }
                    });

                    if (offset + FETCH_LIMIT_TOUR_RUN_DETAILS >= result.filtered_count) {
                        self.mapCenterLocation.lng = self.mapCenterLocation.lng / self.tagsOnMap;
                        self.mapCenterLocation.lat = self.mapCenterLocation.lat / self.tagsOnMap;

                        self.$parent
                            .find('.map-progress-indicator')
                            .progressbar({
                                value: 100
                            });

                        renderCheckpointPathsInMap(self.checkpointsByUser, self.mapCenterLocation);
                        self.activeLoadOperation = null;

                        setTimeout(function() {
                            self.map.setMapCenter(self.mapCenterLocation);
                            self.map.update();
                        }, 10);
                    } else {
                        self._updateMap(offset + FETCH_LIMIT_TOUR_RUN_DETAILS, ts);
                    }
                });
        };

        self._getFilterCriteria = function() {
            return {
                begin: filter.started,
                end: filter.stopped,
                tour_area_id: filter.tour_area_id != null ? filter.tour_area_id : -1,
                user_id: filter.user_id,
                filter_expression: filter.filterExpression,
                correct_filter: filter.correct_filter
            };
        };

        self._onSelectTimeCViolation = function(timeConstraint) {
            nav.goToPage(pages.tour.detail, [ timeConstraint.tour, '/report/tour-area' ]);
        };

        self._onSelectFinishedTourRun = function(tourRun) {
            nav.goToPage(pages.tour_run.detail, [ tourRun, '/report/tour-area' ]);
        };

        /*** Validation functions *************************************************************************************/

        self._addCustomValidators = function() {
            $.validation.addValidators({
                '.valid-date-to': [
                    validateDateTo,
                    "Das 'Bis'-Datum muss nach dem 'Von'-Datum liegen.",
                    'change'
                ],

                '.valid-date-span': [
                    validateDateSpan,
                    sprintf('Die max. erlaube Zeitspanne liegt bei %s Tagen.', MAX_FILTERED_TIMESPAN_IN_DAYS),
                    'change'
                ]
            });
        };

        function validateDateSpan() {
            if (filter.started && filter.stopped) {
                var timeDiff = Math.abs(filter.stopped.getTime() - filter.started.getTime());
                return Math.ceil(timeDiff / (1000 * 3600 * 24)) < MAX_FILTERED_TIMESPAN_IN_DAYS;
            }

            return true;
        }

        function validateDateTo() {
            if (filter.started && filter.stopped) {
                return filter.started < filter.stopped;
            }

            return true;
        }

        /*** Render functions *****************************************************************************************/

        function renderCommandBox($parent) {
            $parent.appendElement([
                commandBox({ title: self.pageHeader }, [
                    app.session.hasPermission(app.model.PermissionTypes.TOUR_REPORT_EVENT_VIEW) ||
                    app.session.hasPermission(app.model.PermissionTypes.TOUR_MODIFY)
                        ? printIconButton({
                            click: function() {
                                return printEventReport("Historie-Ereignisreport", [], filter.tourAreas, filter.users, filter);
                            }
                        }, 'Ereignisreport')
                        : undefined,

                    app.session.hasPermission(app.model.PermissionTypes.TOUR_REPORT_INCIDENT_VIEW)
                        ? printIconButton({
                        click: function() {
                            return printReport("Historie-Ausnahmereport", [], filter.tourAreas, filter.users, false, true, filter);
                        }
                    }, 'Ausnahmereport')
                        : undefined,

                    app.session.hasPermission(app.model.PermissionTypes.TOUR_REPORT_SHIFT_VIEW)
                        ? printIconButton({
                        click: function() {
                            return printReport("Historie-Dienstreport", [], filter.tourAreas, filter.users, true, true, filter);
                        }
                    }, 'Dienstreport')
                        : undefined,

                    app.session.hasPermission(app.model.PermissionTypes.TOUR_REPORT_OBJECT_VIEW)
                        ? printIconButton({
                        click: function() {
                            return printReport("Historie-Objektreport", [], filter.tourAreas, filter.users, true, false, filter);
                        }
                    }, 'Objektreport')
                        : undefined
                ])
            ]);
        }

        function renderFilterBar($parent) {
            function scrollDayInterval(next) {
                var $targetFrom = $("#tour-run-filter-start"),
                    $targetTo = $("#tour-run-filter-end"),
                    dateFrom = $targetFrom.datepicker('getDate'),
                    dateTo = $targetTo.datepicker('getDate');

                if (next) {
                    dateFrom.setDate(dateFrom.getDate() + 1);
                    dateTo.setDate(dateTo.getDate() + 1);
                } else {
                    dateFrom.setDate(dateFrom.getDate() - 1);
                    dateTo.setDate(dateTo.getDate() - 1);
                }

                $targetFrom.datepicker('setDate', dateFrom);
                $targetTo.datepicker('setDate', dateTo);
                filter.started = dateFrom;
                filter.stopped = dateTo;

                self.applyFilter();
            }

            $parent.appendElement(div({ id: "placeholder-filters" }, [
                div({ id: 'tour-run-filter-form', 'class': 'whiteboard' }, [
                    legend({}),
                    ul({ "class": "inline-list title-row-highlight" }, [
                        form.formItemText({id: 'tour-run-filter-search', label: 'Suche', required: false,
                            style: "width: 250px",
                            name: "filter_expression",
                            keyup: function () {
                                filter.filterExpression = $(this).val();
                            },
                            keypress: function (e) {
                                var keyCode = e.which;
                                if (keyCode === 13) {
                                    self.applyFilter();
                                    return false;
                                }
                            },
                            change: function () {
                                self.applyFilter();
                            }
                        }),
                        app.widgets.intervalPreviousIconButton({
                            'click': function() {
                                scrollDayInterval(false);
                            }

                        }),
                        form.formItemDateTime({
                            id: 'tour-run-filter-start',
                            label: 'Von',
                            required: true,
                            "validation": "valid-date-from valid-date-span",
                            datetimepickerParams: {
                                appendText: "",
                                showButtonPanel: true,
                                onClose: function () {
                                    filter.started = $(this).datepicker('getDate');

                                    self.applyFilter();
                                }
                            },
                            renderDayScroller: true,
                            date: filter.started,
                            name: "begin",
                            keypress: function(e) {
                                var keyCode = e.which;
                                if (keyCode === 13) {
                                    filter.started = $(this).datepicker('getDate');

                                    self.applyFilter();
                                }

                                app.helper.keyPressEnter(e);
                            }
                        }),
                        form.formItemDateTime({
                            id: 'tour-run-filter-end',
                            label: 'Bis',
                            required: true,
                            "validation": "valid-date-to",
                            datetimepickerParams: {
                                appendText: "",
                                showButtonPanel: true,
                                onClose: function () {
                                    filter.stopped = $(this).datepicker('getDate');

                                    self.applyFilter();
                                }
                            },
                            renderDayScroller: true,
                            date: filter.stopped,
                            name: "end",
                            keypress: function(e) {
                                var keyCode = e.which;
                                if (keyCode === 13) {
                                    filter.stopped = $(this).datepicker('getDate');

                                    self.applyFilter();
                                }

                                app.helper.keyPressEnter(e);
                            }
                        }),
                        app.widgets.intervalNextIconButton({
                            'click': function() {
                                scrollDayInterval(true);
                            }
                        }),
                        createTourAreaSelector(),
                        createUserSelector()
                    ])
                ])
            ]));
        }

        function createTourAreaSelector() {
            var all = {'id': null, 'name': 'Alle'};

            return form.formItemSelect({
                id: 'filter-tour-area',
                label: 'Revier',
                required: true,
                source: filter.tourAreas,
                valueProp: 'name',
                value: all,
                firstElem: all,
                sort: true,
                selected: function (e, tourArea) {
                    if (filter.tour_area_id !== tourArea.id) {
                        filter.tour_area = tourArea;
                        filter.tour_area_id = tourArea.id;
                        self.applyFilter();
                    }
                }
            });
        }

        function createUserSelector() {
            var all = {'id': null, 'username': 'Alle'};

            return form.formItemSelect({id: 'filter-user', label: 'Benutzer', required: false,
                source: filter.users,
                valueProp: 'username',
                value: all,
                firstElem: all,
                sort: true,
                selected: function (e, user) {
                    if (filter.user_id !== user.id) {
                        filter.user_id = user.id;
                        self.applyFilter();
                    }
                }
            });
        }

        function renderContent($parent) {
            var tabs = [
                {
                    'id': 'tab-finished-tour-runs',
                    'title': 'Rundgänge',
                    'fnRender': $.proxy(renderTourRuns, self)
                },
                {
                    'id': "tab-map",
                    'title': "Karte",
                    'fnRender': $.proxy(renderFinishedTourRunMap, self)
                },
                {
                    'id': "tab-tour-area-definition",
                    'title': "Revier Definition",
                    'fnRender': $.proxy(renderTourAreaTab, self)
                }
            ];

            function beforeActivateTab(event, ui) {
                if (ui.newPanel.find('.map-section').length > 0) {
                    if (!self.map && !ui.newPanel.find('.map-section').is(":visible")) {
                        initMap();
                        self._updateMap();
                    } else {
                        setTimeout(function() {
                            self.map.update();
                        }, 10);
                    }
                } else if (ui.newPanel.find('#tour-area-definition').length > 0) {
                    refreshTourAreaDefinitionTab();
                }
            }

            self.$tabs = $parent.appendElement(
                tabsWidget({
                    id: "tour-area-report-tabs",
                    activate: beforeActivateTab
                }, tabs)
            );

            // init first tab
            initTourRunTables();
        }

        function initTourRunTables() {
            if (self.tableFinishedTourRuns) {
                return;
            }

            initFinishedTourRunTable();
            initToursWithTimeCViolations();
        }

        function initMap() {
            var $parent = self.$mapSection = self.$parent.find('#map-tour-runs');

            if (self.map) {
                return;
            }

            function onMouseOut(map) {
                map.setMapOptions({
                    scrollwheel: false
                });
            }

            function onClick(map) {
                map.setMapOptions({
                    scrollwheel: true
                });
            }

            self.map = new Map($parent, {
                'animationStopTimeAtCheckpoint': 3000,
                'sectionTime': 2000,
                'zoom': 12,
                'scrollwheel': false,
                'onClick': onClick,
                'onMouseOut': onMouseOut,
                'showPrintButton': true,
                'showAnimateButton': true
            });

            self.map.render("height: 650px; width: 100%");

            // register click handler for 'Details' button within
            // info window
            $parent.on("click", ".gm-style-iw .button", function(e) {
                var $target = $(e.target),
                    tourRun = {
                        id: $target.data("id")
                    };

                if ($target.hasClass("disabled")) {
                    return;
                }

                nav.goToPage(pages.tour_run.detail, [ tourRun, '/report/tour-area' ]);
            });
        }

        function initFinishedTourRunTable() {
            self.tableFinishedTourRuns = new DataTable({
                prefix: 'finished-tour-runs',
                options: {
                    onSelect: $.proxy(self._onSelectFinishedTourRun, self),
                    noWhiteboard: true
                },
                keyboardNavigationEnabled: false
            });

            self.tableFinishedTourRuns.create(self.$parent.find('#table-finished-tour-runs'));

            self.tableFinishedTourRuns.renderTable(
                null,
                getTableColumnsForFinishedTourRuns(),
                self._getTableSettingsForFinishedTourRuns()
            );
        }

        function initToursWithTimeCViolations() {
            self.tableToursWithViolations = new DataTable({
                prefix: 'timec-violations',
                options: {
                    onSelect: $.proxy(self._onSelectTimeCViolation, self),
                    noWhiteboard: true
                },
                keyboardNavigationEnabled: false
            });

            self.tableToursWithViolations.create(self.$parent.find('#table-timec-violations'));

            self.tableToursWithViolations.renderTable(
                null,
                getTableColumnsForTimeCViolations(),
                self._getTableSettingsForTimeCViolations()
            );
        }

        function renderTourRuns() {
            return div([
                renderFinishedTourRunTable(),
                renderToursWithTimeCViolations()
            ]);
        }

        function renderFinishedTourRunTable() {
            // render finished tour runs
            return div([
                h2('Beendete Rundgänge'),
                div({ id: 'table-finished-tour-runs' })
            ]);
        }

        function renderToursWithTimeCViolations() {
            // render tours with time constraint violations
            return div([
                h2('Rundgänge mit unzureichende Wiederholungen'),
                div({ id: 'table-timec-violations' })
            ]);
        }

        function renderTourAreaTab() {
            return div({ id: "tour-area-definition" });
        }

        function renderFinishedTourRunMap() {
            // render tours with time constraint violations
            return div([
                div({ 'class': 'map-progress-info'}),
                div({ 'class': 'map-progress-indicator'}),
                div({ 'class': 'map-progress-buttons'}, [
                    cancelIconButton({
                        click: function() {
                            self.$parent.find(".map-progress-info").text("Ladevorgang wird abgebrochen ...");
                            if (self.activeLoadOperation) {
                                self.abortedLoadOperations[self.activeLoadOperation] = true;
                                self.activeLoadOperation = null;
                            }
                        }
                    }, 'Abbrechen')
                ]),
                div({ 'class': 'map-section', style: 'display:none' }, [
                    div({ id: 'map-tour-runs' })
                ])
            ]);
        }

        function renderCheckpointPathsInMap(locationsByUser, centerLocation) {
            var pathDrawn = false,
                userColors = ['#E88E13', '#FFF90D', '#FF0000', '#730CE8', '#0D9CFF'],
                tourRunIds = [];

            // set map center
            self.map.setMapCenter(centerLocation);

            // remove all map markers
            self.map.removeAllMarkers();

            // remove all map paths
            self.map.removeAllPaths();

            _.each(_.values(locationsByUser), function(locations, idx) {

                function getInfo(tour_id) {
                    var info = "";
                    _.each(locations, function(loc) {
                        if (loc.tour_run.id === tour_id) {
                            info += "<h3>";
                            info += loc.tour_run.tour_name;
                            info += loc.tour_run.tour_description ? sprintf(" (%s)", loc.tour_run.tour_description) : "";
                            info += "</h3><p>";

                            info +=  loc.tour_run.start_time ? sprintf("<label>Gestartet:</label>%s<br/>",
                                loc.tour_run.start_time.toFormatString(app.settings.dateTimeFormat)) : "";
                            info +=  loc.tour_run.end_time ? sprintf("<label>Beendet:</label>%s<br/>",
                                loc.tour_run.end_time.toFormatString(app.settings.dateTimeFormat)) : "";
                            info += "</p><p>";

                            info += loc.tour_run.customers ? sprintf("<label>Kunde:</label>%s<br/>", loc.tour_run.customers) : "";
                            info += loc.tour_run.locations ? sprintf("<label>Standort:</label>%s<br/>", loc.tour_run.locations) : "";
                            info += loc.tour_run.objects ? sprintf("<label>Objekt:</label>%s<br/>", loc.tour_run.objects) : "";
                            info += loc.tour_run.workplaces ? sprintf("<label>Arbeitsplatz:</label>%s<br/>", loc.tour_run.workplaces) : "";
                            info += "</p><p>";

                            info += sprintf("<label>Erfolgreich:</label>%s<br/>", loc.tour_run.success ? "Ja" : "Nein");
                            info += sprintf("<label>Fortschritt:</label>%s%%<br/>", loc.tour_run.progress);
                            info += sprintf("<label>Gestochen:</label>%s/%s<br/>", loc.tour_run.scanned_tags_count || 0,
                                loc.tour_run.tags_count || 0);

                            info += sprintf('</p><a class="button" data-id="%s">Details</a>', loc.tour_run.id);
                        }
                    });

                    return info;
                }

                if (locations && locations.length > 0) {
                    pathDrawn = true;

                    var pathLocations = [],
                        filteredPathLocations = [];
                    _.each(locations, function(location) {
                        var def = {
                            'title': location.tour_run.tour_name,
                            'coordinates': new google.maps.LatLng(location.lat, location.lng),
                            'infoText': getInfo(location.tour_run.id)
                        };

                        pathLocations.push(def);

                        if (!_.contains(tourRunIds, location.tour_run.id)) {
                            filteredPathLocations.push(def);
                            tourRunIds.push(location.tour_run.id);
                        }
                    });

                    self.map.renderPath(pathLocations, userColors[idx % userColors.length]);
                    if (filteredPathLocations.length > 0) {
                        self.map.markLocations(filteredPathLocations);
                    }
                }
            });

            if (pathDrawn) {
                self.$parent.find('.map-progress-info').hide();
                self.$parent.find('.map-progress-indicator').hide();
                self.$parent.find('.map-progress-buttons').hide();
                self.$parent.find('.map-section').show();
            } else {
                self.$parent.find('.map-section').hide();
            }
        }

        function refreshTourAreaDefinitionTab() {
            if (self.$tabs) {
                if (filter.tour_area_id && self.$parent.find('#tour-area-definition').length > 0) {
                    self.$tabs.tabs("enable", 2);
                    self.tourAreaPage = new TourAreaPage();
                    self.tourAreaPage.create(self.$parent.find('#tour-area-definition').empty(), filter.tour_area,
                        TourAreaReport, true);
                } else if (!filter.tour_area_id || filter.tour_area_id < 0) {
                    self.tourAreaPage = null;
                    if (self.$tabs.find(".ui-tabs-nav li").eq(2).hasClass("ui-state-active")) {
                        self.$tabs.find(".ui-tabs-nav li > a").first().click();
                    }
                    self.$tabs.tabs("disable", 2);
                }
            }
        }
    }

    /*** Static helper functions **************************************************************************************/

    function getTableColumnsForFinishedTourRuns() {
        return [
            {
                sTitle: "Rundgang",
                sAttrName: "tour_description",
                mRender: function (mData, type, obj) {
                    return obj.tour_name + (obj.tour_description ? (" (" + obj.tour_description + ")") : "");
                }
            },
            {
                sTitle: "Gestartet am",
                sAttrName: "start_time",
                mRender: function (mData, type, obj) {
                    return obj.start_time.toFormatString(app.settings.dateTimeFormat);
                }
            },
            {
                sTitle: "Beendet am",
                sAttrName: "end_time",
                mRender: function (mData, type, obj) {
                    return obj.end_time.toFormatString(app.settings.dateTimeFormat);
                }
            },
            {
                sTitle: "Kunde",
                sAttrName: "customers"
            },
            {
                sTitle: "Standort",
                sAttrName: "locations"
            },
            {
                sTitle: "Objekt",
                sAttrName: "objects"
            },
            {
                sTitle: "Arbeitsplatz",
                sAttrName: "workplaces"
            },
            {
                sTitle: "Benutzer",
                sAttrName: "user_name"
            },
            {
                sTitle: "Revier",
                sAttrName: "tour_areas"
            },
            {
                sTitle: "Erfolgreich",
                sAttrName: "success",
                mRender: function (mData, type, obj) {
                    return obj.success ? 'Ja' : 'Nein';
                }
            },
            {
                sTitle: "Fortschritt",
                sAttrName: "progress",
                mRender: function(mData, type, obj) {
                    return obj.progress + '%';
                }
            },
            {
                sTitle: "Dauer",
                sAttrName: "duration"
            }
        ];
    }

    function getTableColumnsForTimeCViolations() {
        return [
            {
                sTitle: "Name",
                sAttrName: "tour.name"
            },
            {
                sTitle: "Beschreibung",
                sAttrName: "tour.description"
            },
            {
                sTitle: "Kunde",
                sAttrName: "tour.customers"
            },
            {
                sTitle: "Standort",
                sAttrName: "tour.locations"
            },
            {
                sTitle: "Objekt",
                sAttrName: "tour.objects"
            },
            {
                sTitle: "Arbeitsplatz",
                sAttrName: "tour.workplaces"
            },
            {
                sTitle: "Revier",
                sAttrName: "tour_areas"
            },
            {
                sTitle: "Anzahl unzureichender Wiederholungen",
                sAttrName: "timec_violations"
            }
        ];
    }

})(window.rise, window.app);
