import React, { useCallback } from 'react';
import { DataTable } from 'app/legacy/widgets/table';
import $ from 'jquery';
import { iconButton } from 'app/legacy/widgets/button';
import { formatDate, getTourFilterFromTourState, IMG_CROSS, TourState } from 'app/tour-report/TourReportConstants';
import { tourRunDao } from 'app/legacy/dao/tour-run-dao';
import { TourReportState } from 'app/tour-report/model/TourReportState';
import { Box } from 'app/BoxWorld';

const { app } = window as any;

type Props = TourReportState['dayListTable'] & {
    handleSelect: (oData) => void;
    handleBackButton: () => Promise<void>;
};

const table = new DataTable({
    prefix: 'tour-run',
    options: {
        noWhiteboard: false,
    },
    keyboardNavigationEnabled: false,
});

export function TourRunTable(props: Props) {
    const { dateFrom, dateTo, customerId, locationId, objectId, tourState, handleSelect, handleBackButton } = props;

    table.options.onSelect = handleSelect;

    const renderTable = useCallback(async (parent) => {
        if (!parent) {
            return;
        }

        parent.replaceChildren();
        table.create($(parent));
        table.renderTable(
            null,
            [
                ...defaultColumns,
                ...(tourState === TourState.INCORRECT ? extraTourIncorrectColumns : []),
                ...(tourState === TourState.INCIDENTS ? extraTourIncidentsColumns : []),
            ],
            {
                bAutoWidth: false,
                iDisplayLength: 25,
                sPaginationType: 'full_numbers',
                aaSorting: [
                    [2, 'desc'],
                ],
                oSearch: {
                    bSmart: false,
                },
                bServerSide: true,
                fnServerData(sSource, aoData, fnCallback) {
                    const data = Object.fromEntries(aoData.map((oData) => [oData.name, oData.value]));

                    tourRunDao.findFinishedTourRuns($.extend({
                        offset: data.iDisplayStart,
                        limit: data.iDisplayLength,
                        sort_column: data.iSortCol_0 === 3 ? 9 : data.iSortCol_0,
                        sort_direction: data.sSortDir_0,
                    }, {
                        begin: dateFrom,
                        end: dateTo,
                        customer_id: customerId < 0 ? null : customerId,
                        location_id: locationId < 0 ? null : locationId,
                        object_id: objectId < 0 ? null : objectId,
                        correct_filter: getTourFilterFromTourState(tourState),
                    })).done((result) => {
                        fnCallback({
                            aaData: result.rows,
                            iTotalRecords: result.totalCount,
                            iTotalDisplayRecords: result.filteredCount,
                            sEcho: result.sEcho,
                        });
                    });
                },
            },
        );
    }, [dateFrom, dateTo, customerId, locationId, objectId, tourState]);

    const backButton = iconButton({
        id: 'back-button',
        class: 'button-back',
        click: handleBackButton,
    }, 'Zurück zur Tagesansicht');

    return (
        <>
            <h2>
                { `${tourState} per ${formatDate(dateFrom)}` }
            </h2>
            <div id="report-whiteboard" className="whiteboard">
                <Box>{backButton}</Box>
                <div style={{ marginTop: '10px' }} ref={renderTable} />
            </div>
        </>

    );
}

const defaultColumns = [
    {
        sTitle: 'Rundgang',
        sAttrName: 'tour_description',
        mRender(mData, type, obj) {
            return obj.tour_name + (obj.tour_description ? (` (${obj.tour_description})`) : '');
        },
    },
    {
        sTitle: 'Gestartet am',
        sAttrName: 'start_time',
        mRender(mData, type, obj) {
            return obj.start_time.toFormatString(app.settings.dateTimeFormat);
        },
    },
    {
        sTitle: 'Beendet am',
        sAttrName: 'end_time',
        mRender(mData, type, obj) {
            return obj.end_time.toFormatString(app.settings.dateTimeFormat);
        },
    },
    {
        sTitle: 'Fortschritt',
        sAttrName: 'progress',
        mRender(mData, type, obj) {
            return `${obj.progress}%`;
        },
    },
    {
        sTitle: 'Arbeitsplätze',
        sAttrName: 'workplaces',
    },
    {
        sTitle: 'Kunden',
        sAttrName: 'customers',
    },
    {
        sTitle: 'Standorte',
        sAttrName: 'locations',
    },
    {
        sTitle: 'Objekte',
        sAttrName: 'objects',
    },
];

const extraTourIncorrectColumns = [
    {
        sTitle: 'Vom System beendet',
        sAttrName: 'auto_ended',
        sWidth: 70,
        bSortable: false,
        mRender(mData, type, obj) {
            return obj.auto_ended ? IMG_CROSS : '';
        },
        fnCreatedCell(nTd, sData, oData) {
            if (oData.auto_ended) {
                $(nTd).addClass('center');
            }
        },
    },
    {
        sTitle: 'Abgebrochen',
        sAttrName: 'incomplete',
        sWidth: 70,
        bSortable: false,
        mRender(mData, type, obj) {
            const incomplete = obj.tags_count > obj.scanned_tags_count;
            return incomplete ? IMG_CROSS : '';
        },
        fnCreatedCell(nTd, sData, oData) {
            const incomplete = oData.tags_count > oData.scanned_tags_count;
            if (incomplete) {
                $(nTd).addClass('center');
            }
        },
    },
    {
        sTitle: 'Dauereinschränkungen verletzt',
        sAttrName: 'durationc_violated',
        sWidth: 70,
        bSortable: false,
        mRender(mData, type, obj) {
            return obj.durationc_violated ? IMG_CROSS : '';
        },
        fnCreatedCell(nTd, sData, oData) {
            if (oData.durationc_violated) {
                $(nTd).addClass('center');
            }
        },
    },
    {
        sTitle: 'Falsche Reihenfolge',
        sAttrName: 'incorrect_order',
        sWidth: 70,
        bSortable: false,
        mRender(mData, type, obj) {
            return obj.incorrect_order ? IMG_CROSS : '';
        },
        fnCreatedCell(nTd, sData, oData) {
            if (oData.incorrect_order) {
                $(nTd).addClass('center');
            }
        },
    },
];

const extraTourIncidentsColumns = [
    {
        sTitle: 'Anzahl der Fotos',
        sAttrName: 'photos_count',
        sWidth: 130,
        bSortable: false,
        mRender(mData, type, obj) {
            return obj.photos_count;
        },
        fnCreatedCell(nTd) {
            $(nTd).addClass('center');
        },
    },
    {
        sTitle: 'Anzahl der Anmerkungen',
        sAttrName: 'comments_count',
        sWidth: 130,
        bSortable: false,
        mRender(mData, type, obj) {
            return obj.comments_count;
        },
        fnCreatedCell(nTd) {
            $(nTd).addClass('center');
        },
    },
];
