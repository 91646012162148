/**
 * Data Access Object for the user-tag endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.tag");

    /*** Import ***/
    var UserTag = app.model.UserTag;

    /*** Export Dao ***/
    app.dao.userTagDao = new UserTagDao();

    /**
     * @constructor
     */
    function UserTagDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.UserTag, new Rpc('/rpc/user_tag'));
    }

    // extend the parent Dao prototype
    $.extend(UserTagDao.prototype, Object.create(rise.Dao.prototype));

    UserTagDao.prototype.deleteProgram = function(userTagId) {
        var deferred = new $.Deferred();

        this.rpc.invoke('delete_program_on_user_tag',
            { user_tag_id: userTagId })
            .done(function(json) {
                var result = new UserTag(json);
                deferred.resolve(result);
            })
            .fail(function(error) {
                log.error('Deleting program on the user tag failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };


    UserTagDao.prototype.deleteUserTag = function(userTagId) {
        var deferred = new $.Deferred();

        this.rpc.invoke('delete',
            { user_tag_id: userTagId })
            .done(function(result) {
                deferred.resolve(result);
            })
            .fail(function(error) {
                log.error('Deleting the user tag failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };


})(window.rise, window.app);
