/**
 * Data Access Object for the shift endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function (rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.userGroup");

    var _cache = { };

    /*** Export Dao ***/
    app.dao.roleDao = new RoleDao();

    /**
     * @constructor
     */
    function RoleDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.Role, new Rpc('/rpc/role '));
    }

    // extend the parent Dao prototype
    $.extend(RoleDao.prototype, Object.create(rise.Dao.prototype));

    /**
     * Find all tasks with additional assignment info
     */
    RoleDao.prototype.findRoles = function (type) {
        log.info('Finding roles');
        var deferred = new $.Deferred();

        this.rpc.invoke('find_roles', {type: type})
            .done(function (roles) {
                if (roles.length === 0) {
                    log.info('No role found');
                    deferred.resolve([]);
                } else {
                    log.info('Received %s roles', roles.length);
                    deferred.resolve(_.map(roles, function (role) {
                        return new app.model.Role(role);
                    }));
                }
            })
            .fail(function (error) {
                log.error('Request for finding role entries failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    RoleDao.prototype.findPermissionsForRole = function (role_id) {
        log.info('Finding permissions for role %s', role_id);
        var deferred = new $.Deferred();

        if (_cache[role_id]) {
            deferred.resolve(_cache[role_id]);
        } else {
            this.rpc.invoke('find_permissions', {role_id: role_id})
                .done(function (permissions) {
                    log.info('Received %s permissions', permissions.length);
                    var active = role_id > 0; //internal roles have negative ids. their permission should not be active by default.
                    var perms = getPermissionObjects(permissions, active);
                    deferred.resolve(perms);
                })
                .fail(function (error) {
                    log.error('Request for finding permissions entries failed: %s', error);
                    deferred.reject(error);
                });
        }

        return deferred.promise();
    };

    function getPermissionObjects(permissions, active) {
        return permissions.map(function(d) {
            var instance = new app.model.Permission(d);
            instance._active = active;
            return instance;
        });

    }

    RoleDao.prototype.findEmployees = function (role_id) {
        log.info('Finding employees for role %s', role_id);
        var deferred = new $.Deferred();

        this.rpc.invoke('find_employees', {role_id: role_id})
            .done(function (users) {
                if (users.length === 0) {
                    log.info('No employee found');
                    deferred.resolve([]);
                } else {
                    log.info('Received %s employees', users.length);
                    deferred.resolve(_.map(users, function (user) {
                        return new app.dto.RoleUserDto(user);
                    }));
                }
            })
            .fail(function (error) {
                log.error('Request for finding employees entries failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    RoleDao.prototype.findCustomers = function (role_id) {
        log.info('Finding customers for role %s', role_id);
        var deferred = new $.Deferred();

        this.rpc.invoke('find_customers', {role_id: role_id})
            .done(function (users) {
                if (users.length === 0) {
                    log.info('No customer found');
                    deferred.resolve([]);
                } else {
                    log.info('Received %s customers', users.length);
                    deferred.resolve(_.map(users, function (user) {
                        return new app.dto.RoleUserDto(user);
                    }));
                }
            })
            .fail(function (error) {
                log.error('Request for finding customers entries failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    RoleDao.prototype.findNotAssignedEmployees = function (role_id) {
        log.info('Finding employees not assigned to role %s', role_id);
        var deferred = new $.Deferred();

        this.rpc.invoke('find_not_assigned_employees', {role_id: role_id})
            .done(function (users) {
                if (users.length === 0) {
                    log.info('No employees found');
                    deferred.resolve([]);
                } else {
                    log.info('Received %s employees', users.length);
                    deferred.resolve(_.map(users, function (user) {
                        return new app.model.Employee(user);
                    }));
                }
            })
            .fail(function (error) {
                log.error('Request for finding employees failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    RoleDao.prototype.findNotAssignedCustomers = function (role_id) {
        log.info('Finding customers not assigned to role %s', role_id);
        var deferred = new $.Deferred();

        this.rpc.invoke('find_not_assigned_customers', {role_id: role_id})
            .done(function (users) {
                if (users.length === 0) {
                    log.info('No customers found');
                    deferred.resolve([]);
                } else {
                    log.info('Received %s customers', users.length);
                    deferred.resolve(_.map(users, function (user) {
                        return new app.dto.UserListDto(user);
                    }));
                }
            })
            .fail(function (error) {
                log.error('Request for finding customers failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    RoleDao.prototype.insertRole = function (role) {
        var deferred = new $.Deferred();
        var self = this;

        this.rpc.invoke('insert_role',
            {document: rise.Dao.sanitizeObject(role)})
            .done(function (json) {
                var result = new app.model.Role(json);
                deferred.resolve(result);
            })
            .fail(function (error) {
                log.error('Persisting %s failed. %s', self.model_class.name, error.message);
                deferred.reject(error);
            });

        return deferred.promise();
    };


    RoleDao.prototype.insert = function (role) {
        log.info('Persist %s', this.model_class.name);
        var deferred = new $.Deferred();
        var self = this;

        if (role.id) {
            log.error('Cannot insert object. ID already set');
            deferred.reject({message: 'Parameter id must not be set'});
            return deferred.promise();
        }

        if (role instanceof self.model_class === false) {
            log.error('Cannot insert object. Not an instance of the right model class "%s"', self.model_class.name);
            deferred.reject({message: sprintf('Object is not an instance of the right class "%s"', self.model_class.name)});
            return deferred.promise();
        }

        self.rpc.invoke(self.insert_method, {document: rise.Dao.sanitizeObject(role),
            role_id_permissions: role._role_id_permissions,
            role_id_users: role._role_id_users })
            .done(function (persisted_entity) {
                log.info('%s inserted', self.model_class.name);

                //re-add the removed fields by `sanitizeObject`
                deferred.resolve(new self.model_class(
                    $.extend({}, role, persisted_entity)
                ));
                deferred.resolve(new self.model_class(persisted_entity));
            })
            .fail(function (error) {
                log.error('Persisting %s failed. %s', self.model_class.name, error.message);
                deferred.reject(error);
            });

        return deferred.promise();
    };

})(window.rise, window.app);
