/**
 * Widgets for rendering selections.
 *
 * @module app.widgets
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    /*** EXPORTS ***/
    app.widgets.HierarchicalWorkplaceSelect = HierarchicalWorkplaceSelect;
    app.widgets.MultiSelect = ObjectSelector;

    /*** CONSTANTS ***/
    var HierarchyEnum = {
        'CUSTOMERS': 0,
        'LOCATIONS': 1,
        'OBJECTS': 2,
        'WORKPLACES': 3
    };
    var hierarchyFields = {
        0: "locations",
        1: "objects",
        2: "workplaces"
    };

    /** HIERARCHICAL CUSTOMER SELECT **/
    function HierarchicalWorkplaceSelect(params) {
        var self = this;

        if (!params.parent) {
            throw "No parent element provided!";
        }
        if (!params.customers) {
            throw "No customer information provided!";
        }

        this.options = $.extend({
            required: false,
            change: null,
            customers: null,
            parent: null,
            selection: null,
            multiple: true,
            depth: 4,
            attributeNameLocations: 'locations',
            attributeNameObjects: 'objects',
            attributeNameWorkplaces: 'assigned_workplaces',
            noSelectPlaceholder: null,
        }, params);

        // update hierarchy fields
        hierarchyFields[0] = self.options.attributeNameLocations;
        hierarchyFields[1] = self.options.attributeNameObjects;
        hierarchyFields[2] = self.options.attributeNameWorkplaces;

        // map customer structure
        this.customersMap = {};
        this.locationsMap = {};
        this.objectsMap = {};
        this.workplacesMap = {};

        _.each(self.options.customers, function(customer) {
            self.customersMap[customer.id] = customer;
            _.each(customer[self.options.attributeNameLocations], function(location) {
                self.locationsMap[location.id] = location;
                _.each(location[self.options.attributeNameObjects], function(object) {
                    self.objectsMap[object.id] = object;
                    _.each(object[self.options.attributeNameWorkplaces], function(workplace) {
                        self.workplacesMap[workplace.id] = workplace;
                    });
                });
            });
        });

        this.init();
    }

    HierarchicalWorkplaceSelect.prototype._getOptions = function(hierarchy) {
        var self = this;

        if (hierarchy === HierarchyEnum.CUSTOMERS) {
            return self.options.customers;
        } else {
            var prevSelection = self.select[hierarchy-1].getSelectedChoiceItem();
            return prevSelection && self.select[hierarchy-1].getSelectedChoiceItem()[hierarchyFields[hierarchy-1]] || null;
        }
    };

    HierarchicalWorkplaceSelect.prototype._onSelectChanged = function(hierarchy, selection) {
        var self = this;

        if (hierarchy !== HierarchyEnum.WORKPLACES && self.select && self.select[hierarchy+1]) {
            self.select[hierarchy+1]
                .updateChoiceOptions(selection != null ? selection[hierarchyFields[hierarchy]] : null);
        }
    };

    HierarchicalWorkplaceSelect.prototype._onChange = function(hierarchy, selection) {
        if (this.options.change) {
            if (this.options.multiple) {
                this.options.change(hierarchy, _.map(selection, function(instance) { return instance.id; }));
            } else {
                this.options.change(hierarchy, selection.id);
            }
        }
    };

    HierarchicalWorkplaceSelect.prototype.getWidget = function () {
        return this.$widget;
    };

    HierarchicalWorkplaceSelect.prototype.updateSelection = function (newSelection) {
        var self = this;

        _.each(self.select, function(multiselect, idx) {
            multiselect.updateSelectedOptions(_.map(newSelection[{
                0: "customers",
                1: "locations",
                2: "objects",
                3: "workplaces"
            }[idx]], function(selection) {
                return self[{
                    0: "customersMap",
                    1: "locationsMap",
                    2: "objectsMap",
                    3: "workplacesMap"
                }[idx]][selection];
            }));
        });
    };

    HierarchicalWorkplaceSelect.prototype.init = function() {
        var self = this;

        this.$widget = this.options.parent.appendElement(
            div({"id":self.options.id, "class":"hierarchical-customer-select"}, [
                this.options.depth >= 1 ? li({ "class":"fieldset-item" }, [
                    label({"for":"customer-select", "class":self.options.required ? "required" : ""}, "Kunde"),
                    function(parent) {
                        self.select = [new ObjectSelector({
                            placeholder: self.options.noSelectPlaceholder,
                            options: self._getOptions(HierarchyEnum.CUSTOMERS),
                            valueProp: "name", parent:parent, id:"customer-select", multiple:self.options.multiple,
                            choiceSelectorChange: $.proxy(self._onSelectChanged, self, HierarchyEnum.CUSTOMERS),
                            change: $.proxy(self._onChange, self, HierarchyEnum.CUSTOMERS),
                            validation: self.options.required ? "valid-customer-selection valid-selection-remove" : "",
                            selection: _.map(self.options.selection && self.options.selection.customers || [],
                                function(customer_id) {
                                    return self.customersMap[customer_id];
                                }
                            ) })];
                    }
                ]) : undefined,
                this.options.depth >= 2 ? li({ "class":"fieldset-item" }, [
                    label({"for":"location-select", "class":self.options.required ? "required" : ""}, "Standort"),
                    function(parent) {
                        self.select.push(new ObjectSelector({ options: self._getOptions(HierarchyEnum.LOCATIONS),
                            placeholder: self.options.noSelectPlaceholder,
                            valueProp: "name", parent:parent, id: "location-select", multiple:self.options.multiple,
                            choiceSelectorChange: $.proxy(self._onSelectChanged, self, HierarchyEnum.LOCATIONS),
                            change: $.proxy(self._onChange, self, HierarchyEnum.LOCATIONS),
                            validation: self.options.required ? "valid-location-selection valid-selection-remove" : "",
                            selection: _.map(self.options.selection && self.options.selection.locations || [],
                                function(location_id) {
                                    return self.locationsMap[location_id];
                                }
                            ) })); }
                ]) : undefined,
                this.options.depth >= 3 ? li({ "class":"fieldset-item" }, [
                    label({"for":"object-select", "class":self.options.required ? "required" : ""}, "Objekt"),
                    function(parent) {
                        self.select.push(new ObjectSelector({ options: self._getOptions(HierarchyEnum.OBJECTS),
                            placeholder: self.options.noSelectPlaceholder,
                            valueProp: "name", parent:parent, id: "object-select", multiple:self.options.multiple,
                            choiceSelectorChange: $.proxy(self._onSelectChanged, self, HierarchyEnum.OBJECTS),
                            change: $.proxy(self._onChange, self, HierarchyEnum.OBJECTS),
                            validation: self.options.required ? "valid-object-selection valid-selection-remove" : "",
                            selection: _.map(self.options.selection && self.options.selection.objects || [],
                                function(object_id) {
                                    return self.objectsMap[object_id];
                                }
                            ) })); }
                ]) : undefined,
                this.options.depth >= 4 ? li({ "class":"fieldset-item" }, [
                    label({"for":"workstation-select", "class":self.options.required ? "required" : ""}, "Arbeitsplatz"),
                    function(parent) {
                        self.select.push(new ObjectSelector({ options: self._getOptions(HierarchyEnum.WORKPLACES),
                            placeholder: self.options.noSelectPlaceholder,
                            valueProp: "name", parent:parent, id: "workstation-select", multiple:self.options.multiple,
                            change: $.proxy(self._onChange, self, HierarchyEnum.WORKPLACES),
                            validation: self.options.required ? "valid-workplace-selection valid-selection-remove" : "",
                            selection: _.map(self.options.selection && self.options.selection.workplaces || [],
                                function(workplace_id) {
                                    return self.workplacesMap[workplace_id];
                                }
                            ) })); }
                ]) : undefined
            ])
        );
    };

    /** Object Selector **/
    function ObjectSelector(params) {
        if (!params.parent) {
            throw "No parent element provided!"
        }
        if (!params.options) {
            params.options = [];
        }

        this.options = $.extend({
            idProp: "id",
            valueProp: "name",
            change: null,
            multiple: true,
            required: false
        }, params);

        // options that can be selected
        this.choiceOptions = {};
        this.choiceSource = [];

        // current selected data
        this.selectedOptions = {};

        this.init();
    }

    ObjectSelector.prototype.init = function () {
        this.$widget = this.options.parent.appendElement(span({"class":"object-selector"}));

        // init choice selector
        this.updateChoiceOptions(this.options.options);

        if (this.options.multiple) {
            // render action buttons
            this._initActionButtons();

            // init target list
            this.updateSelectedOptions(this.options.selection)
        }
    };

    ObjectSelector.prototype._initChoiceSelector = function() {
        var self = this;

        if (!self.$choiceSelector) {
            self.$choiceSelector = self.$widget.appendElement(
                input({ id:self.options.id,
                    placeholder: self.options.placeholder,
                    change: $.proxy(self._onChange, self),
                    "class":"object-selector-choice" + (self.options.validation ? ' ' + self.options.validation :''),
                    "create": function () {
                        var $combo = $(this);

                        self.$comboBox = $combo.combobox({
                            source: self.choiceSource,
                            valueProp: self.options.valueProp,
                            sort: true,
                            change: $.proxy(self._onChange, self)
                        });
                    }}));
        } else {
            self.$comboBox.combobox("setSelected", null);
        }

        self.choiceSource.length = 0;
        $.each(self.choiceOptions, function(idx, value) {
            self.choiceSource.push(value);
        });

        if (!self.options.multiple && self.options.selection.length>0)  {
            self.$comboBox.combobox('setSelected', self.options.selection[0]);
        } else if (self.choiceSource.length == 1) {
            self.$comboBox.combobox('setSelected', self.choiceSource[0]);
        }

        //self._onChange();
        self.$choiceSelector.parent().find("input").change();
    };

    ObjectSelector.prototype._add = function() {
        var selectedItem = this.getSelectedChoiceItem();

        if (selectedItem && !this.isOptionSelected(selectedItem)) {
            this.selectedOptions[selectedItem[this.options.idProp]] = selectedItem;
            this._initTargetSelector();
            //this.$choiceSelector.find("option#" + selectedItem[this.options.idProp]).hide();
            this.$choiceSelector.val(null).change();

            if (this.options.change) {
                this.options.change(this.getTargetItems());
            }
        }
    };

    ObjectSelector.prototype._remove = function() {
        var self = this,
            selectedItems = this.getSelectedTargetItems();

        if (selectedItems) {
            _.each(selectedItems, function(selectedItem) {
                delete self.selectedOptions[selectedItem[self.options.idProp]];
                //this.$choiceSelector.find("option#" + selectedItem[this.options.idProp]).show();
            });

            this._initTargetSelector();
            this.$targetSelector.val(null).change();

            if (this.options.change) {
                this.options.change(this.getTargetItems());
            }
        }
    };

    ObjectSelector.prototype._initActionButtons = function() {
        var self = this;

        self.$buttonBar = self.$widget.appendElement(div({ "class":"object-selector-buttons" }, [
            a({ title:"Entfernen", "class":"button remove disabled image-only", "click": $.proxy(self._remove, self) },
                img({ src:"style/images/content/arrow-180-medium.png" }) ),
            a({ title:"Hinzufügen", "class":"button add " + (self.choiceSource.length !== 1 ? "disabled" : "") +
                    " image-only", "click": $.proxy(self._add, self) },
                img({ src:"style/images/content/arrow-000-medium.png" }))
        ]));
    };

    ObjectSelector.prototype._initTargetSelector = function() {
        var self = this;

        if (self.$targetSelector) {
            self.$targetSelector.empty();
        } else {
            self.$targetSelector = self.$widget.appendElement(
                select({ change: $.proxy(self._onChangeSelection, self), "class":"object-selector-target", multiple:true }))
        }

        // init options
        _.each(_.toArray(self.selectedOptions).sort($.proxy(self._compareOptions, self)),
            function (opt, idx) {
                self.$targetSelector.appendElement(option({ id:opt[self.options.idProp] }, opt[self.options.valueProp]));
            });

        return self.$targetSelector.attr('size', Math.max(_.size(self.selectedOptions), 1)).change();
    };

    ObjectSelector.prototype._onChange = function() {
        var select = this.getSelectedChoiceItem(),
            self = this;

        if (self.$buttonBar) {
            if (!select || this.isOptionSelected(select)) {
                self.$buttonBar.find(".button.add").addClass("disabled");
            } else {
                self.$buttonBar.find(".button.add").removeClass("disabled");
            }
        }

        if (select && !this.options.multiple && this.options.change) {
            this.options.change(select);
        }

        if (this.options.choiceSelectorChange) {
            this.options.choiceSelectorChange(select);
        }
    };

    ObjectSelector.prototype._onChangeSelection = function() {
        var select = this.getSelectedTargetItems(),
            self = this;

        if (self.$buttonBar) {
            if (!select || select.length === 0) {
                self.$buttonBar.find(".button.remove").addClass("disabled");
            } else {
                self.$buttonBar.find(".button.remove").removeClass("disabled");
            }
        }
    };

    ObjectSelector.prototype._compareOptions = function (a, b) {
        return a[this.options.valueProp] < b[this.options.valueProp] ? -1 : 1;
    };

    ObjectSelector.prototype.updateChoiceOptions = function(options) {
        var self = this;

        if (!options) {
            options = [];
        }

        this.choiceOptions = _.object(_.map(options, function(option) {
            return [option[self.options.idProp], option];
        }));

        // re-init choice selector
        this._initChoiceSelector();
    };

    ObjectSelector.prototype.getSelectedChoiceItem = function() {
        var opt = this.$comboBox.combobox("getSelected");

        if (opt) {
            return opt;
        } else {
            return null;
        }
    };

    ObjectSelector.prototype.getSelectedTargetItems = function() {
        var self = this,
            opt = this.$targetSelector.find("option:selected");
        return $.map(opt, function(o) { return self.selectedOptions[$(o).attr("id")]; });
    };

    ObjectSelector.prototype.updateSelectedOptions = function(options) {
        var self = this;

        if (!options) {
            options = [];
        }

        this.selectedOptions = _.object(_.map(options, function(option) {
            return [option[self.options.idProp], option];
        }));

        // re-init choice selector
        this._initTargetSelector();
    };

    ObjectSelector.prototype.isOptionSelected = function(option) {
        return !!this.selectedOptions[option[this.options.idProp]];
    };

    ObjectSelector.prototype.getTargetItems = function() {
        return _.map(this.selectedOptions, function(option) {
            return option;
        });
    };

})(window.app);
