import { SignaturePad } from "lib/signature_pad";

/**
 * Widgets for creating forms for editing and also viewing the data.
 *
 * @module app.widgets.form
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
const form = (function (app) {
    "use strict";

    /*** EXPORTS ***/
    var form = app.widgets.form = {};

    /*** IMPORTS ***/
    var extend = app.widgets.tools.extend;
    var multiSelect = app.widgets.multiSelect;
    var HierarchicalWorkplaceSelect = app.widgets.HierarchicalWorkplaceSelect;

    form.formItemText = function (params, view, compact) {
        var def = {
            maxlength: 50,
            "class": "x-large-input",
            validation: 'valid-no-xss-char',
            type: 'text'
        };

        var textParams = extend(params, def);

        if (params.type) {
            textParams.type = params.type;
        }

        if (!textParams['class']) {
            textParams['class'] = '';
        }
        if (params.required) {
            textParams['class'] += ' valid-notempty';
        }

        if (textParams.validation) {
            textParams['class'] += ' ' + textParams.validation;
        }

        if(compact) {
            textParams["data-name"] = params.label;
        }

        delete textParams.label;
        delete textParams.required;
        delete textParams.validation;
        delete textParams.labelSuffix;

        return li({'class': 'fieldset-item'}, [
            compact ?
                undefined :
                label({'for': params.id, 'class': params['class'] + (params.required && !view ? ' required' : '')}, params.label),
            view ?
                span({id: params.id }, params.value) :
                input(textParams),
            params.labelSuffix ?
                span({'class': 'label-suffix'}, params.labelSuffix) :
                undefined
        ]);
    };

    form.formItemPassword = function (params, view) {
        params.type = 'password';
        return form.formItemText(params, view);
    };

    form.formItemEmail = function (params, view) {
        params.type = 'email';
        return form.formItemText(params, view);
    };

    form.formItemTel = function (params, view) {
        params.type = 'tel';
        return form.formItemText(params, view);
    };

    form.formItemUrl = function (params, view) {
        params.type = 'url';
        return form.formItemText(params, view);
    };

    form.formItemDate = function (params, view) {
        var def = {
            create: function (e) {
                if (e.type === 'destroy') {
                    $this.datepicker('destroy');
                    return;
                }

                if (app.helper.isMobile.any()) {
                    $(this).attr("disabled", "disabled");
                    $(this).addClass("touch-trigger-input date-picker-trigger");
                }

                $(this)
                    .datepicker(extend(params.datepickerParams || {}, params.datepicker))
                    .datepicker('setDate', params.date ? new Date(params.date) : params.date);
            },
            validation: 'valid-date',
            'class': "datepicker",
            type: 'text'
        };

        var dateParams = extend(params, def);

        if (!dateParams['class']) {
            dateParams['class'] = '';
        }
        if (params.required) {
            dateParams['class'] += ' valid-notempty';
        }
        if (dateParams.validation) {
            dateParams['class'] += ' ' + dateParams.validation;
        }

        function previousDayButton() {
            return a({
                'class': 'datepicker-dayscroller-previous',
                'click': function() {
                    var $target = $("#"+params.id),
                        date = $target.datepicker('getDate');

                    date.setDate(date.getDate() - 1);

                    $target.datepicker('setDate', date);
                    $target.change();
                }
            });
        }

        function nextDayButton() {
            return a({
                'class': 'datepicker-dayscroller-next',
                'click': function() {
                    var $target = $("#"+params.id),
                        date = $target.datepicker('getDate');

                    date.setDate(date.getDate() + 1);

                    $target.datepicker('setDate', date);
                    $target.change();
                }
            });
        }

        delete dateParams.label;
        delete dateParams.required;
        delete dateParams.validation;
        delete dateParams.datepicker;
        delete dateParams.date;
        delete dateParams.datepickerParams;
        delete dateParams.renderDayScroller;

        return li({'class': 'fieldset-item ' +
                            (params.renderDayScroller ? 'day-scroller' : '')}, [
            label({'for': params.id, 'class': params.required && !view ? 'required' : ''}, params.label),
            view ?
                span({id: params.id, 'class': params['class']}, params.date ? params.date.toDateString() : '') :
                div({
                    id: params.id + "-div",
                    class: "date-input-wrapper",
                    click: function (e) {
                        var $input = $(this).find("input");
                        if ($(e.target)[0].tagName === 'INPUT' && app.helper.isMobile.any()) {
                            var visible = $input.datepicker("widget").is(":visible");
                            $input.datepicker(visible ? "hide" : "show");
                        }
                    }
                }, [
                    params.renderDayScroller ? previousDayButton() : undefined,
                    input(dateParams),
                    params.renderDayScroller ? nextDayButton() : undefined
                ])
        ]);
    };

    form.formItemDateTime = function (params, view) {
        var def = {
            create: function(e) {
                let $this = $(this);

                if (e.type === 'destroy') {
                    $this.datetimepicker('destroy');
                    return;
                }

                if (params.date) {
                    $this.val(params.date);
                }

                if (app.helper.isMobile.any()) {
                    $this.attr("disabled", "disabled");
                    $this.addClass("touch-trigger-input");
                }

                var datetimepickerParams = extend(params.datetimepickerParams || {}, {
                    customButtonInjector: injectCustomButtons
                });

                $this.datetimepicker(datetimepickerParams, params.datetimepicker);
                if (params.date) {
                    $this.trigger('change');
                }
            },
            validation: 'valid-datetime',
            'class': `datetimepicker ${params.class ?? ''}`,
            type: 'text'
        };

        var dateParams = extend(params, def);

        if (app.helper.isMobile.any()) {
            params.readonly = true;
        }

        if (!dateParams['class']) {
            dateParams['class'] = '';
        }
        if (params.required) {
            dateParams['class'] += ' valid-notempty';
        }
        if (dateParams.validation) {
            dateParams['class'] += ' ' + dateParams.validation;
        }
        if (params.date?.toDateTimeString) {
            params.date = params.date.toDateTimeString();
        }
        if (dateParams.change) {
            dateParams.change = formItemDateTimeChangeHandler(dateParams.change);
        }

        function formItemDateTimeChangeHandler(changeHandler) {
            return (e) => {
                const input = e.target;
                const validDateTime = $.validation.validators['.valid-datetime'][0];

                let value = input.value?.trim();
                if (validDateTime(e, value)) {
                    changeHandler.call(input, e);
                }
            };
        }

        function injectCustomButtons(input) {
                var buttonPane = $(input)
                    .datepicker("widget")
                    .find(".ui-datepicker-buttonpane");

                buttonPane.empty();
                buttonPane.appendElement(dayBeginButton());
                buttonPane.appendElement(dayEndButton());
        }

        function dayBeginButton() {
            return app.widgets.textButton({
                'click': function() {
                    var $target = $("#"+params.id),
                        date = $target.datepicker('getDate');

                    date.setHours(0,0,0,0);
                    $target.datepicker('setDate', date);
                }

            }, '00:00');
        }

        function dayEndButton() {
            return app.widgets.textButton({
                'click': function() {
                    var $target = $("#"+params.id),
                        date = $target.datepicker('getDate');

                    date.setHours(23,59,59,999);
                    $target.datepicker('setDate', date);
                }

            }, '23:59');
        }

        function previousDayButton() {
            return a({
                'class': 'datepicker-dayscroller-previous',
                'click': function() {
                    var $target = $("#"+params.id),
                        date = $target.datepicker('getDate');

                    date.setDate(date.getDate() - 1);

                    $target.datepicker('setDate', date);

                    var e = jQuery.Event("keypress");
                    e.which = 13;
                    $target.trigger(e);
                }
            });
        }

        function nextDayButton() {
            return a({
                'class': 'datepicker-dayscroller-next',
                'click': function() {
                    var $target = $("#"+params.id),
                        date = $target.datepicker('getDate');

                    date.setDate(date.getDate() + 1);

                    $target.datepicker('setDate', date);

                    var e = jQuery.Event("keypress");
                    e.which = 13;
                    $target.trigger(e);
                }
            });
        }

        delete dateParams.label;
        delete dateParams.required;
        delete dateParams.validation;
        delete dateParams.datetimepicker;
        delete dateParams.date;
        delete dateParams.datetimepickerParams;
        delete dateParams.renderDayScroller;

        return li({'class': 'fieldset-item ' +
                            (params.renderDayScroller ? 'day-scroller' : '')}, [
            label({'for': params.id, 'class': params.required && !view ? 'required' : ''}, params.label),
            view ?
                span({id: params.id, 'class': params['class']}, params.date ?? '') :
                div({
                    id: params.id + "-div",
                    "class": "date-input-wrapper",
                    click: function (e) {
                        var $input = $(this).find("input");
                        if ($(e.target)[0].tagName === 'INPUT' &&  app.helper.isMobile.any()) {
                            var visible = $input.datepicker("widget").is(":visible");
                            $input.datepicker(visible ? "hide" : "show");
                        }
                    }
                }, [
                    params.renderDayScroller ? previousDayButton() : undefined,
                    input(dateParams),
                    params.renderDayScroller ? nextDayButton() : undefined
                ])
        ]);
    };

    form.formItemSelect = function (params, view, compact) {
        if (params.valueProp === undefined) {
            throw "Param 'valueProp' is missing!";
        } else if (params.source === undefined) {
            throw "Param 'source' is missing!";
        }

        /* sort hook */
        if (params.sort === undefined || params.sort) {
            var listText = params.listProp ? params.listProp : params.valueProp;
            params.source.sort(function (a, b) {
                if (typeof a === "object") {
                    if (typeof a[listText] === "string") {
                        return a[listText].localeCompare(b[listText]);
                    } else {
                        return a[listText] > b[listText];
                    }
                } else {
                    return a > b;
                }
            });
        }

        if (params.firstElem) {
            params.source.unshift(params.firstElem);
        }

        var def = {
            type: 'text',
            create: function (e) {
                var $combo = $(this);

                if (e.type === 'destroy') {
                    $combo.combobox('destroy');
                    return;
                }

                $combo.combobox({
                    source: params.source,
                    valueProp: params.valueProp,
                    listProp: params.listProp,
                    selected: params.selected,
                    sort: false
                });

                if (params.value !== undefined && params.value !== null) {
                    $combo.combobox('setSelected', params.value);
                }

            }
        };

        var selectParams = extend(params, def);

        if (!selectParams['class']) {
            selectParams['class'] = '';
        }
        if (selectParams.required) {
            selectParams['class'] += ' valid-selected';
        }
        if (selectParams.validation) {
            selectParams['class'] += ' ' + selectParams.validation;
        }
        if(compact) {
            selectParams["data-name"] = params.label;
        }

        delete selectParams.label;
        delete selectParams.required;
        delete selectParams.sort;
        delete selectParams.validation;
        delete selectParams.value;
        delete selectParams.source;
        delete selectParams.selected;
        delete selectParams.valueProp;
        delete selectParams.listProp;
        delete selectParams.firstElem;

        return li({'class': 'fieldset-item'}, [
            compact ? undefined :
                label({'for': params.id, 'class': params['class'] + (params.required && !view ? ' required' : '')}, params.label),
            view ?
                span({id: params.id}, _.isObject(params.value) ? params.value[params.valueProp] : params.value) :
                input(selectParams)
        ]);
    };

    form.formItemMultiSelect = function (params, view, compact) {
        if (params.source === undefined) {
            throw "Param 'source' is missing!";
        } else if (params.valueProp === undefined) {
            throw "Param 'valueProp' is missing!";
        }

        var def = {
            type: 'text',
            create: function (e) {
                var $combo = $(this);

                if (e.type === 'destroy') {
                    return;
                }

                setTimeout(function() {
                    $combo.comboTree({
                        source: params.source.map(function(v) {
                            return {
                                id: v[params.valueProp],
                                title: v[params.valueProp]
                            }
                        }),
                        isMultiple: true
                    });
                });
            }
        };

        var selectParams = extend(params, def);

        if (!selectParams['class']) {
            selectParams['class'] = '';
        }
        if (selectParams.required) {
            selectParams['class'] += ' valid-notempty';
        }
        if (selectParams.validation) {
            selectParams['class'] += ' ' + selectParams.validation;
        }
        if(compact) {
            selectParams["data-name"] = params.label;
        }

        delete selectParams.label;
        delete selectParams.required;
        delete selectParams.sort;
        delete selectParams.validation;
        // delete selectParams.value;
        delete selectParams.source;
        delete selectParams.selected;
        delete selectParams.valueProp;

        return li({'class': 'fieldset-item'}, [
            compact ? undefined :
                label({'for': params.id, 'class': params['class'] + (params.required && !view ? ' required' : '')}, params.label),
            view ?
                span({id: params.id}, _.isObject(params.value) ? params.value[params.valueProp] : params.value) :
                input(selectParams)
        ]);
    };

    form.formItemCheckbox = function (params, view, compact) {
        var def = {
            type: 'checkbox'
        };

        var checkParams = extend(params, def);

        if (!checkParams['class']) {
            checkParams['class'] = '';
        }
        if(compact) {
            checkParams["data-name"] = params.label;
        }

        delete checkParams.label;

        return li({'class': 'fieldset-item'}, [
            compact ? undefined :
                label({'for': params.id, 'class': params['class'] + (params.required && !view ? ' required' : '')}, params.label),
            input($.extend(checkParams, view ? { disabled:true } : {}))
        ]);
    };

    form.formItemCheckboxList = function (params, view) {
        var $ulComboboxes;
        var classParams;

        if (params.validation) {
            classParams = params.validation;
        }

        delete params.validation;

        return li({id: params.id, 'class': 'fieldset-item ' + classParams }, _.flatten([
            label({'for': params.id, 'class': params.required && !view ? 'required' : ''}, params.label),
            view ?
                ul(_(params.value).map(function (item) {
                    return li(item);
                })) :
                ul({create: function (e) {
                    if (e.type === 'destroy') {
                        return;
                    }

                    $ulComboboxes = $(this);
                }}, _.map(params.options, function (item, idx) {
                    var id = _.uniqueId();
                    return li([
                        input({id: id, 'class': params['class'], type: 'checkbox', value: item,
                            checked: _.contains(params.value, item),
                            change: function () {
                                var items = [];
                                $ulComboboxes.find('input[type=checkbox]:checked').map(function (idx, c) {
                                    items.push($(c).val());
                                });

                                params.listChanged(items);
                            }
                        }),
                        label({'for': id}, item)
                    ]);
                }))
        ]));
    };


    form.formItemTextarea = function (params, view, noXss) {
        var def = {
            "class": "xx-large-input",
            validation: noXss ? '' : 'valid-no-xss-char',
            create: function (e) {
                if (e.type === 'destroy') {
                    return;
                }

                $(this).on('input', function () {
                    $(this).outerHeight(38).outerHeight(this.scrollHeight);
                });
            }
        };

        var textParams = extend(params, def);

        if (params.type) {
            textParams.type = params.type;
        }

        if (!textParams['class']) {
            textParams['class'] = '';
        }
        if (params.required) {
            textParams['class'] += ' valid-notempty';
        }

        if (textParams.validation) {
            textParams['class'] += ' ' + textParams.validation;
        }

        delete textParams.label;
        delete textParams.required;
        delete textParams.validation;
        delete textParams.value;

        return li({'class': 'fieldset-item'}, [
            label({'for': params.id, 'class': params.required && !view ? 'required' : ''}, params.label),
            view ?
                span({id: params.id}, params.value ? $.map(params.value.split('\n'), function (elem) {
                    return [elem, br()];
                }) : '') :
                textarea(textParams, params.value)
        ]);
    };

    form.formHierarchicalWorkplaceSelect = function(params, view) {
        var assignedToAllCustomers =
                _.size(params.customers) > 1 &&
                params.assignments.customers.length === _.size(params.customers) &&
                params.assignments.locations.length === 0 &&
                params.assignments.objects.length === 0 &&
                params.assignments.workplaces.length === 0,
            depth = params.depth ? params.depth : 4;

        function renderAssignments(assignments, nameMapping) {
            return function($parent) {
                $parent.appendElement([
                    ul({ 'id': params.id + "-customer",
                            'style': assignments.length === 0 ? "display:none" : ""
                        }, _.map(assignments, function(customer) {
                            return li({ style: "margin:0px;padding:0px;padding-bottom:2px;" }, nameMapping[customer]);
                        })
                    ),
                        assignments.length > 0 ? br() : undefined
                ]);
            };
        }

        if (view) {

            if (assignedToAllCustomers) {
                return form.formItemCheckbox({
                    id:"all-customers", label: "Allen Kunden zugewiesen", checked:assignedToAllCustomers }, view);
            } else {
                // map customer structure
                var customerNameMap = {};
                var locationNameMap = {};
                var objectNameMap = {};
                var workplaceNameMap = {};

                _.each(params.customers, function(customer) {
                    customerNameMap[customer.id] = customer.name;
                    _.each(customer.locations, function(location) {
                        locationNameMap[location.id] = sprintf("%s/%s", customer.name, location.name);
                        _.each(location.objects, function(object) {
                            objectNameMap[object.id] = sprintf("%s/%s/%s", customer.name, location.name, object.name);
                            _.each(object.workplaces, function(workplace) {
                                workplaceNameMap[workplace.id] =
                                    sprintf("%s/%s/%s/%s", customer.name, location.name, object.name, workplace.name);
                            });
                        });
                    });
                });

                return div({ id:params.id }, [
                    li({ 'class': 'fieldset-item object-selector' }, [
                        label({'for': params.id + "-customer"}, "Zuordnung"),
                        renderAssignments(params.assignments.customers, customerNameMap),
                        renderAssignments(params.assignments.locations, locationNameMap),
                        renderAssignments(params.assignments.objects, objectNameMap),
                        renderAssignments(params.assignments.workplaces, workplaceNameMap)
                    ])
                ]);
            }
        } else {
            return function(parent) {
                if (!params.noAssignAllCheckbox) {
                    parent.appendElement(form.formItemCheckbox({
                        id: "all-customers", label: "Allen Kunden zuweisen", checked: assignedToAllCustomers,
                        click: function () {


                            assignedToAllCustomers = $(this).prop("checked");
                            if (assignedToAllCustomers) {
                                params.assignments.customers =
                                    _.map(params.customers, function (customer) {
                                        return customer.id;
                                    });
                                params.assignments.locations = params.assignments.objects =
                                    params.assignments.workplaces = [];
                                selector.updateSelection(params.assignments);

                                if (params.change) {
                                    params.change(0, params.assignments.customers);
                                    params.change(1, []);
                                    params.change(2, []);
                                    params.change(3, []);
                                }

                                selectorWidget.hide();
                            } else {
                                selectorWidget.show();
                            }
                        }
                    }));
                }

                var selector = new HierarchicalWorkplaceSelect({
                        id:params.id,
                        parent:parent,
                        customers: params.customers,
                        change: params.change,
                        selection: params.assignments,
                        required: params.required,
                        depth: params.depth
                    }),
                    selectorWidget = selector.getWidget();

                if (assignedToAllCustomers) {
                    selectorWidget.hide();
                }
            };
        }
    };

    form.formItemWorkplaceSelect = function(params, view) {
        var depth = params.depth ? params.depth : 4;

        if (view) {
            var customer = null,
                location = null,
                object = null,
                workplace = null;

            if (params.selected) {
                for (var ic=0; ic<params.customers.length; ic++) {
                    var c = params.customers[ic];
                    if (depth >= 1 && ($.inArray(c.id, params.selected.customers) >= 0 || $.inArray(c, params.selected.customers) >= 0)) {
                        customer = c;
                        break;
                    }
                }
                if (customer && depth >= 2) {
                    for (var il = 0; il < customer.locations.length; il++) {
                        var l = customer.locations[il];
                        if (depth >= 2 && ($.inArray(l.id, params.selected.locations) >= 0 || $.inArray(l, params.selected.locations) >= 0)) {
                            location = l;
                            break
                        }
                    }
                }
                if (location && depth >= 3) {
                    for (var io = 0; io < location.objects.length; io++) {
                        var o = location.objects[io];
                        if (depth >= 3 && ($.inArray(o.id, params.selected.objects) >= 0 || $.inArray(o, params.selected.objects) >= 0)) {
                            object = o;
                            break
                        }
                    }
                }
                if (object && depth >= 4) {
                    for (var iw=0; iw<object.assigned_workplaces.length; iw++) {
                        var w = object.assigned_workplaces[iw];
                        if ($.inArray(w.id, params.selected.workplaces) >= 0 || $.inArray(w, params.selected.workplaces) >= 0) {
                            workplace = w;
                            break;
                        }
                    }
                }
            }

            return div({ id:params.id }, [
                depth >= 1 ? li({ 'class': 'fieldset-item object-selector' }, [
                    label({'for': params.id + "-customer"}, "Kunde"),
                    span({'id': params.id + "-customer"}, customer && customer.name || params.noSelectPlaceholder || "-")
                ]) : undefined,
                depth >= 2 ? li({ 'class': 'fieldset-item multi-select' }, [
                    label({'for': params.id + "-location"}, "Standort"),
                    span({'id': params.id + "-location" }, location && location.name || params.noSelectPlaceholder || "-")
                ]) : undefined,
                depth >= 3 ? li({ 'class': 'fieldset-item multi-select' }, [
                    label({'for': params.id + "-object"}, "Objekt"),
                    span({'id': params.id + "-object"}, object && object.name || params.noSelectPlaceholder || "-")
                ]) : undefined,
                depth >= 4 ? li({ 'class': 'fieldset-item multi-select' }, [
                    label({'for': params.id + "-workplace"}, "Arbeitsplatz"),
                    span({'id': params.id + "-workplace"}, workplace && workplace.name || params.noSelectPlaceholder || "-")
                ]) : undefined
            ]);
        } else {
            return function(parent) {
                return new HierarchicalWorkplaceSelect({
                    id:params.id,
                    parent:parent,
                    customers: params.customers,
                    change: params.change,
                    selection: params.selected,
                    multiple: false,
                    depth: params.depth,
                    required: params.required,
                    noSelectPlaceholder: params.noSelectPlaceholder,
                    attributeNameWorkplaces: params.attributeNameWorkplaces
                });
            }
        }
    };

    form.formItemYesNoRadio = function (params, view) {
        var def = {
            type: 'radio'
        };

        var checkParams = extend(params, def);

        if (!checkParams['class']) {
            checkParams['class'] = '';
        }

        delete checkParams.label;

        if (view) {
            return li({'class': 'fieldset-item'}, [
                label({'for': params.id}, params.label),
                params.value ? span({'id': params.id}, "Ja") :
                               span({'id': params.id}, "Nein")
            ]);
        } else {
            return li({'class': 'fieldset-item'}, [
                label({'for': params.id + "-yes", 'class': params['class'] + (params.required && !view ? ' required' : '')}, params.label),
                ul({ id: params.id + "-yes-no", 'class': params.required ? 'valid-radio-selected' : '', 'data-name': params.label }, [
                    li({}, [input($.extend({}, checkParams, { "value": true, checked: checkParams.value === true, id: params.id + "-yes" })), label({'for': params.id + "-yes"}, "Ja")]),
                    li({}, [input($.extend({}, checkParams, { "value": false, checked: checkParams.value === false, id: params.id + "-no" })), label({'for': params.id + "-no"}, "Nein")])
                ])
            ]);
        }
    };

    form.formItemRadio = function (params, view, compact) {
        var def = {
            type: 'radio'
        };

        var radioParams = extend(params, def);

        if (!radioParams['class']) {
            radioParams['class'] = '';
        }

        if(compact) {
            radioParams["data-name"] = params.label;
        }

        delete radioParams.label;

        return li({'class': 'fieldset-item'}, [
            compact ? undefined :
                label({'for': params.id}, params.label),
            input($.extend(radioParams, view ? { disabled:true } : {}))
        ]);
    };

    form.formItemTime = function (params, view) {
        var def = {
            create: function (e) {
                if (e.type === 'destroy') {
                    $(this).timepicker('destroy');
                    return;
                }

                if (params.time) {
                    $(this).val(params.time);
                }

                if (app.helper.isMobile.any()) {
                    $(this).attr("disabled", "disabled");
                    $(this).addClass("touch-trigger-input time-picker-trigger");
                }

                $(this)
                    .timepicker(extend({
                        appendText: `(${app.settings.timeFormatHuman})`
                    }, params.timepicker));
            },
            validation: 'valid-time',
            'class': "timepicker",
            type: 'text'
        };

        var dateParams = extend(params, def);

        if (!dateParams['class']) {
            dateParams['class'] = '';
        }
        if (params.required) {
            dateParams['class'] += ' valid-notempty';
        }
        if (dateParams.validation) {
            dateParams['class'] += ' ' + dateParams.validation;
        }
        if (typeof(params.time) === 'object' && params.time.toTimeString) {
            params.time = params.time.toTimeString();
        }

        if (dateParams.change) {
            dateParams.change = formItemTimeChangeHandler(dateParams.change);
        }

        delete dateParams.label;
        delete dateParams.required;
        delete dateParams.validation;
        delete dateParams.timepicker;
        delete dateParams.time;

        return li({'class': 'fieldset-item'}, [
            label({'for': params.id, 'class': params.required && !view ? 'required' : ''}, params.label),
            view ?
                span({id: params.id, 'class': params['class']}, params.time) :
                input(dateParams)
        ]);
    };

    function formItemTimeChangeHandler(changeHandler) {
        return (e) => {
            const input = e.target;
            const validTime = $.validation.validators['.valid-time'][0];

            let value = input.value?.trim();

            const shorthandTime = new RegExp(/^(\d\d)(\d\d)$/).exec(value);
            if (shorthandTime) {
                value = `${shorthandTime[1]}:${shorthandTime[2]}`;
            }

            if (validTime(e, value)) {
                input.value = value;
                changeHandler.call(input, e);
            }
        };
    }

    form.formItemSignature = function (params, view) {
        var def = {
            id: 'signature-pad',
            required: false,

            width: Math.min(550, $(window).width() - 65),
            height: 130,

            lineMinWidth: 0.5,
            lineMaxWidth: 2.5,
            backgroundColor: "rgb(255,255,255)",
            penColor: "rgb(0,0,0)",
            change: null
        };

        var options = $.extend(def, params);
        var signaturePad, canvasElement;

        function renderSignatureWidget() {
            function onCreateCanvas(e) {
                if (e.type === 'destroy') {
                    return;
                }

                canvasElement = e.target;

                signaturePad = new SignaturePad(canvasElement, {
                    minWidth: options.lineMinWidth,
                    maxWidth: options.lineMaxWidth,
                    backgroundColor: options.backgroundColor,
                    penColor: options.penColor,
                    onEnd: onEndStroke
                });

                function removeImageBlanks(imageObject) {
                    var imgWidth = imageObject.width;
                    var imgHeight = imageObject.height;
                    var canvas = document.createElement('canvas');
                    canvas.setAttribute("width", imgWidth);
                    canvas.setAttribute("height", imgHeight);
                    var context = canvas.getContext('2d');
                    context.drawImage(imageObject, 0, 0);

                    var imageData = context.getImageData(0, 0, imgWidth, imgHeight),
                        data = imageData.data,
                        getRBG = function(x, y) {
                            var offset = imgWidth * y + x;
                            return {
                                red:     data[offset * 4],
                                green:   data[offset * 4 + 1],
                                blue:    data[offset * 4 + 2],
                                opacity: data[offset * 4 + 3]
                            };
                        },
                        isWhite = function (rgb) {
                            // many images contain noise, as the white is not a pure #fff white
                            return rgb.red > 200 && rgb.green > 200 && rgb.blue > 200;
                        },
                        scanY = function (fromTop) {
                            var offset = fromTop ? 1 : -1;

                            // loop through each row
                            for(var y = fromTop ? 0 : imgHeight - 1; fromTop ? (y < imgHeight) : (y > -1); y += offset) {

                                // loop through each column
                                for(var x = 0; x < imgWidth; x++) {
                                    var rgb = getRBG(x, y);
                                    if (!isWhite(rgb)) {
                                        return y;
                                    }
                                }
                            }
                            return null; // all image is white
                        },
                        scanX = function (fromLeft) {
                            var offset = fromLeft? 1 : -1;

                            // loop through each column
                            for(var x = fromLeft ? 0 : imgWidth - 1; fromLeft ? (x < imgWidth) : (x > -1); x += offset) {

                                // loop through each row
                                for(var y = 0; y < imgHeight; y++) {
                                    var rgb = getRBG(x, y);
                                    if (!isWhite(rgb)) {
                                        return x;
                                    }
                                }
                            }
                            return null; // all image is white
                        };

                    var cropTop = scanY(true),
                        cropBottom = scanY(false),
                        cropLeft = scanX(true),
                        cropRight = scanX(false),
                        cropWidth = cropRight - cropLeft,
                        cropHeight = cropBottom - cropTop;

                    canvas.setAttribute("width", cropWidth);
                    canvas.setAttribute("height", cropHeight);
                    // finally crop the guy
                    canvas.getContext("2d").drawImage(imageObject,
                        cropLeft, cropTop, cropWidth, cropHeight,
                        0, 0, cropWidth, cropHeight);

                    return canvas.toDataURL();
                }

                function onEndStroke() {
                    if (options.change) {
                        var signature = signaturePad.toDataURL();
                        var img = new Image();
                        img.onload = function () {
                            $(canvasElement).removeClass("no-signature");
                            options.change(removeImageBlanks(img));
                        };

                        img.src = signature;
                    }
                }

                function resizeCanvas() {
                    var ratio =  Math.max(window.devicePixelRatio || 1, 1);
                    canvasElement.width = canvasElement.offsetWidth * ratio;
                    canvasElement.height = canvasElement.offsetHeight * ratio;
                    canvasElement.getContext("2d").scale(ratio, ratio);
                    $(canvasElement).addClass("no-signature");
                    signaturePad.clear(); // otherwise isEmpty() might return incorrect value
                }

                //window.addEventListener("resize", resizeCanvas);
                //setTimeout(resizeCanvas, 0);
            }

            return span([
                canvas({
                    id: options.id,
                    width: options.width,
                    height: options.height,
                    create: onCreateCanvas,
                    "class": "signature " + (options.required ? "valid-signature-required no-signature" : "")
                }),
                div({ "class": "signature-actions" }, [
                    a({
                        click: function() {
                            $(canvasElement).addClass("no-signature");
                            signaturePad.clear();
                        }
                    }, "Unterschrift löschen")
                ])
            ]);
        }

        function renderSignatureImage() {
            return img({
                id: options.id,
                "class": "signature",
                src: options.value || undefined
            });
        }

        return li({'class': 'fieldset-item'}, [
            label({'for': options.id,
                'class': options.required && !view ? 'required' : ''}, params.label),
            view
                ? renderSignatureImage()
                : renderSignatureWidget()
        ]);
    };

    /* COMPOSITE FORM ITEMS
     ****************************************************************/

    form.formItemsForEvent = function(eventType, event, view) {
        var eventFieldByTypeId = _.object(
                _.map(event.event_fields, function(eventField) {
                        return [eventField.event_field_type_id, eventField];
                    }
                )),
            formItemRenderFunctionByFieldType = {
                'CHECKBOX': form.formItemCheckbox,
                'RADIO': form.formItemYesNoRadio,
                'DATE': form.formItemDate,
                'DATETIME': form.formItemDateTime,
                'TIME': form.formItemTime,
                'SINGLELINE': form.formItemText,
                'MULTILINE': form.formItemTextarea,
                'SIGNATURE': form.formItemSignature,
                'SINGLESELECT': form.formItemSelect,
                'MULTISELECT': form.formItemMultiSelect
            };

        function getFormItemByFieldEventType(eventFieldType, eventField, view) {
            var renderFunction = formItemRenderFunctionByFieldType[eventFieldType.field_type],
                params = {
                    'label': eventFieldType.label,
                    'required': eventFieldType.required,
                    'id': 'logbook-entry-event-' + eventFieldType.id,
                    'name': 'logbook-entry-event-' + eventFieldType.id
                };

            if (eventFieldType.field_type === 'SIGNATURE' && !view) {
                eventField.content = null;
            } else if (eventFieldType.field_type === 'MULTISELECT') {
                params['source'] = eventFieldType.options.selectOptions;
                params['valueProp'] = 'value'
            } else if (eventFieldType.field_type === 'SINGLESELECT') {
                params['source'] = eventFieldType.options.selectOptions;
                params['valueProp'] = 'value'
            }

            if (eventField.content) {
                var parsedFieldContent = parseEventFieldValueByType(eventField, eventFieldType);

                if ($.inArray(eventFieldType.field_type, ['DATE', 'DATETIME']) >= 0) {
                    params['date'] = parsedFieldContent;
                } else if (eventFieldType.field_type === 'TIME') {
                    params['time'] = parsedFieldContent;
                } else if (eventFieldType.field_type === 'CHECKBOX') {
                    params['checked'] = parsedFieldContent;
                } else {
                    params['value'] = parsedFieldContent;
                }
            }

            // register change listener
            params['change'] = function(val) {
                if (eventFieldType.field_type !== 'SIGNATURE') {
                    eventField.content = formatEventFieldValueByType($(this), eventFieldType);
                    if (eventFieldType.desc_field) {
                        event.description = eventField.content;
                    }
                } else {
                    eventField.content = val;
                    if (eventFieldType.desc_field) {
                        event.description = '(Unterschrift)';
                    }
                }
            };

            return renderFunction ? renderFunction(params, view) : undefined;
        }

        function formatEventFieldValueByType($eventField, eventFieldType) {
            var value = null;

            switch (eventFieldType.field_type) {
                case 'CHECKBOX':
                    value = $eventField.is(":checked") ? 'on' : 'off';
                    break;

                case 'RADIO':
                    value = $eventField.val() + '';
                    break;

                case 'DATETIME':
                    value = $eventField.datetimepicker('getDate');
                    break;

                case 'DATE':
                    value = new JustDate($eventField.datetimepicker('getDate'));
                    break;

                default:
                    value = $eventField.val() + '';
            }

            return value;
        }

        function parseEventFieldValueByType(eventField, eventFieldType) {
            var value = null;

            switch (eventFieldType.field_type) {
                case 'CHECKBOX':
                    value = eventField.content === 'on';
                    break;
                case 'RADIO':
                    value = eventField.content === 'true';
                    break;

                case 'DATETIME':
                    value = Date.fromISO(eventField.content);
                    break;

                case 'DATE':
                    // IE
                    value = new JustDate(Date.parseFormat(eventField.content, 'YYYY-MM-DD'));
                    break;

                default:
                    value = eventField.content;
            }

            return value;
        }

        return _.map(
            eventType.event_field_types.sort(function(eft1, eft2){
                return eft1.order_number - eft2.order_number;
            }),
            function(eventFieldType) {
                if (!eventFieldByTypeId[eventFieldType.id]) {
                    eventFieldByTypeId[eventFieldType.id] = new app.model.EventField({
                        event_id: event.id,
                        event_field_type_id: eventFieldType.id,
                        content: ""
                    });
                    event.event_fields.push(eventFieldByTypeId[eventFieldType.id]);
                }

                return getFormItemByFieldEventType(eventFieldType, eventFieldByTypeId[eventFieldType.id], view);
            }
        );
    };

    return form;

})(window.app);

export const formItemSelect = form.formItemSelect;
export const formItemCheckbox = form.formItemCheckbox;
export const formItemText = form.formItemText;
export const formItemTextarea = form.formItemTextarea;
export const formItemTime = form.formItemTime;
export const formItemCheckboxList = form.formItemCheckboxList;
export const formItemRadio = form.formItemRadio;
export const formItemWorkplaceSelect = form.formItemWorkplaceSelect;
export const formHierarchicalWorkplaceSelect = form.formHierarchicalWorkplaceSelect;
export const formItemDateTime = form.formItemDateTime;
