/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function (app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.LogbookEntryType = {
        EVENT: 'EVENT',
        SHIFT: 'SHIFT',
        TASK: 'TASK',

        valueOf: function(value) {
            return this.hasOwnProperty(value) ? value : null;
        },

        values: function() {
            return _.filter(_.values(this), function(el) { return !_.isFunction(el); });
        }
    };

}(window.app));
