/**
 * This file contains all model used by the task RPC endpoint
 *
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.WeeklyTask = function WeeklyTask(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id             = obj.id != null ? parseInt(obj.id) : null;
        this.weekdays       = obj.weekdays != null ? obj.weekdays : null;

        this.time           = obj.time ?
            typeof obj.time === 'string'
                ? new window.JustTime.fromISO(obj.time)
                : obj.time
            : null;

        app.model.Task.apply(this, [obj]);
    };

}(window.app));
