/**
 * Helper functions.
 *
 * @module app.helper
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    /** EXPORT **/
    var helper = app.helper = {};

    /*
     * Triggers a change event if enter is pressed --> workaround for IE
     */
    helper.keyPressEnter = function keyPressEnter(e) {
        var ieVersion = getInternetExplorerVersion();
        if(ieVersion > -1) {
            var code = (e.keyCode ? e.keyCode : e.which);
            if(code === 13) { //Enter keycode
                $(e.target).blur();
                $(e.target).focus();
            }
        }
    };

    helper.isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
        },
        any: function() {
            var isMobile = app.helper.isMobile;
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
        }
    };

    // Returns the version of Internet Explorer or a -1
    // (indicating the use of another browser).
    function getInternetExplorerVersion()
    {
            var rv = -1; // Return value assumes failure.
            if (navigator.appName == 'Microsoft Internet Explorer')
            {
                var ua = navigator.userAgent;
                var re  = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
                if (re.exec(ua) != null)
                    rv = parseFloat( RegExp.$1 );
            }
            return rv;
    }

    /**
     * Detect some necessary but missing browser features.
     * @returns {*} BoxWorld element containing the error description
     *              which can be appended to the error page with `appendElement(error)`.
     */
    helper.detectBrowserFeatures = function detectBrowserFeatures() {
//        if (!supportsECMAScript5()) {
//            return div([
//                p("Your browser is outdated!"),
//                p("To get the best possible experience using this application we recommend that you upgrade to a newer version of your web browser. Download links for the most popular browsers:"),
//                p(
//                    ul([
//                        li(a({ href:"https://www.google.com/chrome" }, "Google Chrome")),
//                        li(a({ href:"https://www.mozilla.org/firefox" }, "Mozilla Firefox")),
//                        li(a({ href:"https://www.apple.com/safari" }, "Apple Safari"))
//                    ])
//                )
//            ]);
//        } else
        if (!supportsDOMStorage()) {
            return div([
                p("Your browser doesn't support data storage!"),
                p("This application keeps some data for performance reason in your browser's internal storage. You have to use a browser where this feature is enabled. Sometimes you have to enable Cookies in your browser to enable the storage feature (although Cookies are not stored).")
            ]);
        } else {
            // all features available
        }
    };

    /**
     * Function with returns true if the browser supports ECMAScript5.
     * @returns {Boolean} True if ECMAScript5 support is available, otherwise false.
     */
    function supportsECMAScript5() {
        return Object.create !== undefined && Function.prototype.bind !== undefined;
    }

    /**
     * Function which returns true if the DOM storage is available.
     * @return {Boolean} True if DOM storage is available, otherwise false.
     */
    function supportsDOMStorage() {
        try {
            var key = Math.random()+'', // NOSONAR
                val = "__dom_storage_test__";

            sessionStorage.setItem(key, val);
            sessionStorage.removeItem(key);

            return true;
        } catch(e) {
            return false;
        }
    }

}(window.app));
