/**
 * jQuery Box for jQuery 1.8.2, Underscore.js 1.3.3<br />
 * <br />
 * Requirements:
 * <ul>
 *      <li>jQuery  1.8.2 (http://code.jquery.com/jquery-1.8.2.js)</li>
 *      <li>Underscore.js  1.3.3 (http://underscorejs.org/underscore.js)</li>
 *      <li>JSON-js (IE < 8) (https://github.com/douglascrockford/JSON-js/blob/master/json2.js)</li>
 * </ul>
 * Recommended:
 * <ul>
 *      <li>Underscore.string 2.3.0 (http://epeli.github.com/underscore.string/lib/underscore.string.js)</li>
 * </ul>
 *
 * @deprecated
 * @author Christoph Mayerhofer
 * @author Martin Reiterer
 * @author Conrad Wandl
 * @module rise.BoxWorld
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 * ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise) {
    "use strict";

    var self, htmlElements, boxElements;

    /**
     * The famous BoxWorld Library
     *
     * @class BoxWorld
     * @constructor
     */
    function BoxWorld () {
        self = this;

        /**
         * Global Plug-in settings
         *
         * @property {Object}   settings
         */
        this.settings = {

            /**
             * Global attributes defined in:
             * http://dev.w3.org/html5/spec/single-page.html#global-attributes - Editor's Draft 18 September 2012 -
             *
             * @attribute   {Array}     coreattrs
             */
            coreattrs: [
                "accesskey", "class", "contenteditable", "contextmenu", "dir", "draggable", "dropzone",
                "hidden", "id", "lang", "spellcheck", "style", "tabindex", "title", "translate", "role"
            ],

            /**
             * Global events defined in:
             * http://dev.w3.org/html5/spec/single-page.html#global-attributes - Editor's Draft 18 September 2012 -
             *
             * Special meaning is assigned to the event-type 'create'. Event handlers of
             * this type are called after creation of the corresponding DOM element.
             * Attention: 'create'-events are not propagated!
             *
             * @attribute   {Array}     coreevents
             */
            coreevents: [
                "abort", "blur", "cancel", "canplay", "canplaythrough", "change", "click", "close", "contextmenu",
                "cuechange", "dblclick", "drag", "dragend", "dragenter", "dragleave", "dragover", "dragstart",
                "drop", "durationchange", "emptied", "ended", "error", "focus", "input", "invalid", "keydown",
                "keypress", "keyup", "load", "loadeddata", "loadedmetadata", "loadstart", "mousedown", "mousemove",
                "mouseout", "mouseover", "mouseup", "mousewheel", "pause", "play", "playing", "progress", "ratechange",
                "reset", "scroll", "seeked", "seeking", "select", "show", "stalled", "submit", "suspend", "timeupdate",
                "volumechange", "waiting",

                // custom events
                "create"    // {Function} invoked after DOM element creation
            ],

            /**
             * Custom data-* attributes defined in:
             * http://dev.w3.org/html5/spec/single-page.html#attr-data-* - Editor's Draft 18 September 2012 -
             *
             * @attribute   {String}    dataattr
             */
            dataattr: "data-",

            compatibility: true
        };

        this.elements = {};

        // transforms element configs to HTMLElements
        createBoxElements(htmlElements);
        if(this.settings.compatibility) {
            createBoxElements(boxElements);
        }
    }

    /**
     * BoxWorld-Element creator function.
     *
     * This is a convenience function to provide better readability. div(div()) vs. new div(new div())
     *
     * @param   {Object}                    [parms]     Dict with parameters.
     * @param   {Array|Box|String|Function} children    children as Array, BoxElement, number, string or DOMElement.
     * @constructor
     */
    function box(parms, childs) {
        // transforms BoxElements to HtmlElements
        return new this(parms, childs);
    }

    function createBoxElements(htmlElements) {
        _.each(htmlElements, function(config, name) {
            var htmlElement;

            // without create function the name of the element will be used e.g. "div"
            config.create = config.create || (name.charAt(0) === "_" ? name.substring(1) : name);

            htmlElement = createBoxElement(config, name);

            // save a copy of the constructor to elements object
            self.elements[name] = htmlElement;

            // add a constructor function to window
            window[name] = _.bind(box, htmlElement);
        });
    }

    /**
     * Returns a BoxElement-class constructor.
     *
     * The create-option must be provided!
     *
     * @method  createBoxElement
     * @for     BoxWorld
     * @param   {Object}    options         allowed options are: events, attributes, properties, create
     * @return  {BoxElement}               BoxElement-Class constructor with attached 'options'
     */
     function createBoxElement(options, name) {

        /**
         * Base-class for every BoxWorld-Element
         *
         * @param   {Object}                    [parms]     Dict with parameters.
         * @param   {Array|Box|String|Function} children    children as Array, BoxElement, number, string or DOMElement.
         * @constructor
         */
        function BoxElement(parms, children) {
            var args0 = parms,
                args1 = children;
            this.parms = {};
            this.children = undefined;

            if(args0) {
                if($.isPlainObject(args0)) {
                    this.parms = args0;
                } else {
                    this.children = args0;
                }
            }

            if(args1) {
                this.children = args1;
            }
        }

        // merge in events, attributes, properties
        $.extend(BoxElement.prototype, options);

        BoxElement.prototype.domElementName = name;

        var createFunc;
        // add default create method if options.create is a string rather than a function
        if(_.isString(options.create)) {
            createFunc = function(document) {
                var elem = document.createElement(options.create);
                self.initElement (this, elem);

                return [ elem ];
            };
        } else if(_.isFunction(options.create)) {
            createFunc = options.create;
        } else {
            assert(false, "BoxElement.prototype.create is missing or has wrong type!");
        }

        BoxElement.prototype.create = createFunc;

        BoxElement.prototype.createElement = function(document) {
            var elem = this.create(document);
            addChildren(elem, this.children);
            return elem;
        };

        return BoxElement;
    }

/*******************************************************************************************************
 Public functions
 *******************************************************************************************************/

    /**
     * Initializes an instance of type BoxElement with arguments and event handlers.
     *
     * @method  initElement
     * @param   {Box}   de      The DOM element descriptor of type BoxElement
     * @param   {DOM}   elem    The corresponding DOM element, which needs to be initialized
     */
    BoxWorld.prototype.initElement = function (de, elem) {
        var attr, dataattr, prop, events, parms, $elem, i, event, handler;

        attr   = (de.attributes || []).concat(this.settings.coreattrs);
        dataattr = this.settings.dataattr;
        events = (de.events || []).concat(this.settings.coreevents);
        parms  = de.parms;

        $elem = $(elem);

        _.each(parms, function(value, name) {
            var attrIndex, eventIndex;

            // if current parameter is an attribute
            if(_.indexOf(attr, name) !== -1) {
                // add attribute

                if(name === "style") {
                    elem.style = value;
                } else {
                    $elem.attr(name, value);
                }

            // if current parameter is an event
            } else if(_.indexOf(events, name) !== -1) {
                // add event
                if(name === "create") {
                    value = eventHandlerFunc(value);
                }

                // to guarantee compatibility with jQuery < 1.7
                if($.fn.on) {
                    $elem.on(name, value);
                } else {
                    $elem.bind(name, value);
                }

            // if current parameter is a data-* attribute
            } else if(name.indexOf(dataattr) === 0) {
                // add data-* attribute
                $elem.attr(name, value);
            } else {
                assert(
                    false,
                    `BoxWorld.prototype.initElement: Attribute or event '${name}'
                    (value: '${value}') not valid for <${elem.nodeName.toLowerCase()}>`
                );
            }
        });
    };

    /**
     * Registers plugins
     *
     * <pre>;
     * e.g: plugin( {
     "MyBox": Box,
     *    "MyFBox": FBox
     * } )
     * </pre>;
     *
     * @method  plugin
     * @param   {Box}   plugins to add
     */
    BoxWorld.prototype.plugin = function(plugins) {
        createBoxElements(plugins);
    };

    /**
     * Generates a HTML string out of BoxWorld elements
     *
     * @method  html
     * @param   {Box}       box     BoxWorld element to HTMLify
     * @param   {String}    uid     identifier needed for caching
     * @return  {String}            HTML string
     */
    BoxWorld.prototype.html = _.memoize(toHTML, function(box) {
        return JSON.stringify(box);
    });

/*******************************************************************************************************
 Private functions
 *******************************************************************************************************/

    function toHTML(box) {
        var $temp, html;

        var tmpid = _.uniqueId();
        $("body").appendElement(new self.elements.div({ id:tmpid, visible:false }, box));
        $temp = $("#"+tmpid);

        html = $temp.html();
        $temp.detach();

        return html;
    }

    function eventHandlerFunc(handler) {
        return function(event) {
            handler.call(this, event);
            // only send for current item, no bubbling to parent elements
            event.stopPropagation();
        };
    }

    // adds the elements of the array to the parent
    function addChildren(parent, children) {
        var $parent = $(parent),
            document = $parent.prop('ownerDocument');

        if(!_.isArray(children)) {
            children = [ children ];
        }

        _.each(children, function(elem, idx) {
            var dom, event;

            if(elem === undefined) {
                return;
            }

            if(_.isFunction(elem)) {
                // call generator function with parent as first parm
                elem = elem($parent);
                // for dom manipulation stop here
                if(elem === undefined) {
                    return;
                } else if(_.isArray(elem)) {
                    addChildren(parent, elem, 'append');
                    return;
                }
            }

            if(elem.create) {
                dom = elem.create(document);
            } else if(_.isString(elem)) {
                dom = document.createTextNode(elem);
            } else if(_.isNumber(elem)) {
                dom = document.createTextNode(elem.toString());
            } else {
                // type unknown, return
                return;
            }

            if(_.isArray(parent)) {
                parent = parent[0];
            }

            if(dom.jquery) {
                $parent.append(dom);
            } else {
                _.each(_.isArray(dom) ? dom : [ dom ], function(value, idx) {
                    try {
                        parent.appendChild(value);
                    } catch (e) {
                        console.log(e);
                    }
                });
            }

            // add children
            if(elem.children !== undefined) {
                addChildren(dom, elem.children);
            }

            // trigger create event to dom elements
            if(elem.parms && elem.parms.create) {
                event = $.Event("create");
                $(dom).trigger(event);
            }
        });
    }

/*******************************************************************************************************
 HTML5 Elements

 based on - Editor's Draft 18 September 2012 - http://dev.w3.org/html5/spec/single-page.html
 *******************************************************************************************************/

    htmlElements = {
    /******************************
     The root Element (http://dev.w3.org/html5/spec/single-page.html#the-root-element)
     ******************************/
        html: {
            attributes: [ "manifest" ]
        },
    /******************************
     Document metadata (http://dev.w3.org/html5/spec/single-page.html#document-metadata)
     ******************************/
        head: {},
        title: {},
        base: {
            attributes: [ "href", "target" ]
        },
        link: {
            attributes: [ "href", "rel", "media", "hreflang", "type", "sizes" ]
        },
        meta: {
            attributes: [ "name", "http-equiv", "content", "charset" ]
        },
        style: {
            attributes: [ "media", "type", "scoped" ]
        },
    /******************************
     Scripting (http://dev.w3.org/html5/spec/single-page.html#scripting-1)
     ******************************/
        script: {
            attributes: [ "src", "async", "defer", "type", "charset" ]
        },
        noscript: {},
    /******************************
     Sections (http://dev.w3.org/html5/spec/single-page.html#sections)
     ******************************/
        body: {
            events: [
                "afterprint", "beforeprint", "beforeunload", "blur", "error", "focus", "hashchange",
                "load", "message", "offline", "online", "pagehide", "pageshow", "popstate", "resize",
                "scroll", "storage", "unload"
            ]
        },
        section: {},
        nav: {},
        article: {},
        aside: {},
        h1: {},
        h2: {},
        h3: {},
        h4: {},
        h5: {},
        h6: {},
        hgroup: {},
        header: {},
        footer: {},
        address: {},
    /******************************
     Sections (http://dev.w3.org/html5/spec/single-page.html#grouping-content)
     ******************************/
        p: {},
        hr: {},
        pre: {},
        blockquote: {
            attributes: [ "cite" ]
        },
        ol: {
            attributes: [ "reversed", "start", "type" ]
        },
        ul: {},
        li: {
            attributes: [ "value" ]
        },
        dl: {},
        dt: {},
        dd: {},
        figure: {},
        figcaption: {},
        div: {
            attributes: [
                // custom attributes
                "t",        // {Number}  sets css property 'top'
                "b",        // {Number}  sets css property 'bottom'
                "l",        // {Number}  sets css property 'left'
                "r",        // {Number}  sets css property 'right'
                "w",        // {Number}  sets css property 'width'
                "h",        // {Number}  sets css property 'height'
                "visible"   // {Boolean} sets css property 'display' to 'none' if true
            ],
            create: createDiv
        },
    /******************************
     Text-level semantics (http://dev.w3.org/html5/spec/single-page.html#text-level-semantics)
     ******************************/
        a: {
            attributes: [
                "href", "target", "rel", "media", "hreflang", "type",

                // custom attributes
                "value"     // {String} sets the anchors display text
            ],
            create: createAnchor
        },
        em: {},
        strong: {},
        small: {},
        s: {},
        cite: {},
        q: {
            attributes: [ "cite" ]
        },
        dfn: {},
        abbr: {},
        time: {
            attributes: [ "datetime" ]
        },
        code: {},
        _var: {}, // reserved word
        samp: {},
        kbd: {},
        sub: {},
        sup: {},
        i: {},
        b: {},
        u: {},
        mark: {},
        ruby: {},
        rt: {},
        rp: {},
        bdi: {
            attributes: [ "dir" ]
        },
        bdo: {
            attributes: [ "dir" ]
        },
        span: {},
        br: {},
        wbr: {},
    /******************************
     Edits (http://dev.w3.org/html5/spec/single-page.html#edits)
     ******************************/
        ins: {
            attributes: [ "cite", "datetime" ]
        },
        del: {
            attributes: [ "cite", "datetime" ]
        },
    /******************************
     Embedded content (http://dev.w3.org/html5/spec/single-page.html#embedded-content-0)
     ******************************/
        img: {
            attributes: [ "alt", "src", "crossorigin", "usemap", "ismap", "width", "height" ],
            create: createImage
        },
        iframe: {
            attributes: [ "src", "srcdoc", "name", "sandbox", "seamless", "width", "height" ]
        },
        embed: {
            attributes: [ "src", "type", "width", "height" ]
        },
        object: {
            attributes: [ "data", "type", "typemustmatch", "name", "usemap", "form", "width", "height" ]
        },
        param: {
            attributes: [ "name", "value" ]
        },
        video: {
            attributes: [
                "src", "crossorigin", "poster", "preload", "autoplay", "mediagroup", "loop", "muted",
                "controls", "width", "height"
            ]
        },
        audio: {
            attributes: [ "src", "crossorigin", "preload", "autoplay", "mediagroup", "loop", "muted", "controls" ]
        },
        source: {
            attributes: [ "src", "type", "media" ]
        },
        track: {
            attributes: [ "kind", "src", "srclang", "label", "default" ]
        },
        canvas: {
            attributes: [ "width", "height" ]
        },
        map: {
            attributes: [ "name" ]
        },
        area: {
            attributes: [ "alt", "coords", "shape", "href", "target", "rel", "media", "hreflang", "type" ]
        },
    /******************************
     Tabular data (http://dev.w3.org/html5/spec/single-page.html#tabular-data)
     ******************************/
        table:{
            attributes: [ "border" ]
        },
        caption: {},
        colgroup: {
            attributes: [ "span" ]
        },
        col: {
            attributes: [ "span" ]
        },
        tbody: {},
        thead: {},
        tfoot: {},
        tr: {},
        td: {
            attributes: [ "colspan", "rowspan", "headers" ]
        },
        th: {
            attributes: [ "colspan", "rowspan", "headers", "scope" ]
        },
    /******************************
     Forms (http://dev.w3.org/html5/spec/single-page.html#forms)
     ******************************/
        form: {
            attributes: [ "accept-charset", "action", "autocomplete", "enctype", "method", "name", "novalid", "target" ]
        },
        fieldset: {
            attributes: [
                "disabled", "form", "name",

                // custom attributes
                "legend"    // {String} renders an additional <legend> element
            ],
            create: createFieldset
        },
        legend:{},
        label: {
            attributes: [ "form", "for" ]
        },
        input: {
            attributes: [
                "accept", "alt", "autocomplete", "autofocus", "checked", "dirname", "disabled", "form", "formaction",
                "formenctype", "formmethod", "formnovalidate", "formtarget", "height", "list", "max", "maxlength",
                "min", "multiple", "name", "pattern", "placeholder", "readonly", "required", "size", "src", "step",
                "type", "value", "width",

                // custom attributes
                "label"     // {String} renders an additional <label> element
            ],
            create: createInput
        },
        button:{
            attributes: [
                "autofocus", "disabled", "form", "formaction", "formenctype", "formmethod", "formnovalidate",
                "formtarget", "name", "type", "value"
            ]
        },
        select:{
            attributes: [
                "autofocus", "disabled", "form", "multiple", "name", "required", "size",

                // custom attributes
                "options",  // {Array|Object} creates <option> elements
                "selected"  // {String}       sets 'selected' attribute for <option> element
            ],
            create: createSelect
        },
        datalist: {},
        optgroup: {
            attributes: [ "disabled", "label" ]
        },
        option: {
            attributes: [ "disabled", "label", "selected", "value" ]
        },
        textarea: {
            attributes: [
                "autofocus", "cols", "dirname", "disabled", "form", "maxlength", "name", "placeholder", "readonly",
                "required", "rows", "wrap"
            ]
        },
        keygen: {
            attributes: [ "autofocus", "challenge", "disabled", "form", "keytype", "name" ]
        },
        output: {
            attributes: [ "for", "form", "name" ]
        },
        progress: {
            attributes: [ "value", "max" ]
        },
        meter: {
            attributes: [ "value", "min", "max", "low", "high", "optimum" ]
        },
    /******************************
     Interactive elements (http://dev.w3.org/html5/spec/single-page.html#interactive-elements)
     ******************************/
        details: {
            attributes: [ "open" ]
        },
        summary: {},
        command: {
            attributes: [ "type", "label", "icon", "disabled", "checked", "radiogroup", "command" ]
        },
        menu: {
            attributes: [ "type", "label" ]
        },
        dialog: {
            attributes: [ "open" ]
        }
    };

/*******************************************************************************************************
 BoxWorld Elements for maintaining backwards compatibility
 *******************************************************************************************************/

    boxElements = {

        /**
         * Creates a &lt;div&gt; element with the specified parameters and children.
         *
         * @deprecated
         * @class  UBox
         * @constructor
         * @extends BoxElement
         */
        UBox: htmlElements.div,
        /**
         * Creates a relative positioned &lt;div&gt; element with the specified parameters and children.
         *
         * @deprecated
         * @class   FBox
         * @constructor
         * @extends BoxElement
         */
        FBox: htmlElements.div,
        /**
         * Creates an absolute positioned &lt;div&gt; element with the specified parameters and children.
         *
         * @deprecated
         * @class   Box
         * @constructor
         * @extends BoxElement
         */
        Box: htmlElements.div,
        /**
         * Creates a new &lt;link&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   ResourceLink
         * @constructor
         * @extends BoxElement
         */
        ResourceLink: htmlElements.link,
        /**
         * Creates a new &lt;span&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   Span
         * @constructor
         * @extends BoxElement
         */
        Span: htmlElements.span,
        /**
         * Creates a new &lt;input&gt; element with the specified parameters.
         *
         * @deprecated
         * @class  Input
         * @constructor
         * @extends BoxElement
         */
        Input: htmlElements.input,
        /**
         * Creates a new &lt;a&gt; element with the specified parameters.
         *
         * @deprecated
         * @class  Link
         * @constructor
         * @extends BoxElement
         */
        Link: htmlElements.a,
        /**
         * Creates a new &lt;textarea&gt; element with the specified parameters.
         *
         * @deprecated
         * @class  Textarea
         * @constructor
         * @extends BoxElement
         */
        Textarea: htmlElements.textarea,
        /**
         * Creates a new &lt;select&gt; element with the specified parameters.
         *
         * <pre>
         * Allowed values for 'options' is a Dict with key/value pairs
         * { 1:"a", 2:"b", 3:"c" }
         *
         * Array with values
         * [ [1,"a"], [2,"b"], [3,"c"] ]
         *
         * or Array with key/value tuples.
         * { 1: { ... }, 2: [ ... ] } for optgroups
         * </pre>
         *
         * @deprecated
         * @class  Select
         * @constructor
         * @extends BoxElement
         */
        Select: htmlElements.select,
        /**
         * Creates a new &lt;option&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   SelectOption
         * @constructor
         * @extends BoxElement
         */
        SelectOption: htmlElements.option,
        /**
         * Creates a new &lt;label&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   Label
         * @constructor
         * @extends BoxElement
         */
        Label: {
            attributes: [
                // custom attributes (deprecated)
                "value"
            ].concat(htmlElements.label.attributes),
            events: htmlElements.label.events,
            create: createLabel
        },
        /**
         * Creates a new &lt;button&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   Button
         * @constructor
         * @extends BoxElement
         */
        Button: {
            attributes: htmlElements.button.attributes,
            events: htmlElements.button.events,
            create: createButton
        },
        /**
         * Creates a new &lt;img&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   Img
         * @constructor
         * @extends BoxElement
         */
        Img: {
            // name and longdesc are obsolete, hence they are not available for htmlElements.img
            // (see http://www.w3.org/TR/html5/obsolete.html)
            attributes: [ "longdesc", "name" ].concat(htmlElements.img.attributes),
            events: htmlElements.img.events,
            create: createImage
        },
        /**
         * Creates a new &lt;h1&gt;..&lt;h6&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   Header
         * @constructor
         * @extends BoxElement
         */
        Header: {
            attributes: [
                // custom attributes
                "value", "level"
            ].concat(htmlElements.h1.attributes),
            events: htmlElements.h1.events,
            create: createHeader
        },
        /**
         * Creates a new &lt;li&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   ListItem
         * @constructor
         * @extends BoxElement
         */
        ListItem: htmlElements.li,
        /**
         * Creates a new &lt;ol&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   OList
         * @constructor
         * @extends BoxElement
         */
        OList: htmlElements.ol,
        /**
         * Creates a new &lt;ul&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   UList
         * @constructor
         * @extends BoxElement
         */
        UList: htmlElements.ul,
        /**
         * Creates a new &lt;table&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   Table
         * @constructor
         * @extends BoxElement
         */
        Table: htmlElements.table,
        /**
         * Creates a new &lt;thead&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   THead
         * @constructor
         * @extends BoxElement
         */
        THead: htmlElements.thead,
        /**
         * Creates a new &lt;tbody&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   TBody
         * @constructor
         * @extends BoxElement
         */
        TBody: htmlElements.tbody,
        /**
         * Creates a new &lt;tfoot&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   TFoot
         * @constructor
         * @extends BoxElement
         */
        TFoot: htmlElements.tfoot,
        /**
         * Creates a new &lt;tr&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   TRow
         * @constructor
         * @extends BoxElement
         */
        TRow: htmlElements.tr,
        /**
         * Creates a new &lt;th&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   THeaderCol
         * @constructor
         * @extends BoxElement
         */
        THeaderCol: htmlElements.th,
        /**
         * Creates a new &lt;td&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   TCol
         * @constructor
         * @extends BoxElement
         */
        TCol: htmlElements.td,
        /**
         * Creates a new &lt;form&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   Form
         * @constructor
         * @extends BoxElement
         */
        Form: htmlElements.form,
        /**
         * Creates a new &lt;fieldset&gt; element with the specified parameters.
         * Use the parm 'legend' to create a legend for the fieldset
         *
         * @deprecated
         * @class   Fieldset
         * @constructor
         * @extends BoxElement
         */
        Fieldset: htmlElements.fieldset,
        /**
         * Creates a new &lt;legend&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   Legend
         * @constructor
         * @extends BoxElement
         */
        Legend: htmlElements.legend,
        /**
         * Creates a new &lt;hr&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   HLine
         * @constructor
         * @extends BoxElement
         */
        HLine: htmlElements.hr,
        /**
         * Creates a new &lt;p&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   Paragraph
         * @constructor
         * @extends BoxElement
         */
        Paragraph: htmlElements.p,
        /**
         * Creates a new &lt;br&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   Break
         * @constructor
         * @extends BoxElement
         */
        Break: htmlElements.br,
        /**
         * Creates a new &lt;i&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   Italic
         * @constructor
         * @extends BoxElement
         */
        Italic: htmlElements.i,
        /**
         * Creates a new &lt;strong&gt; element with the specified parameters.
         *
         * @deprecated
         * @class   Strong
         * @constructor
         * @extends BoxElement
         */
        Strong: htmlElements.strong
    };

/*******************************************************************************************************
 Non-default element constructor functions
 *******************************************************************************************************/

    function createDiv(document) {
        var div, elem, $elem, parms;

        div = this;

        elem = document.createElement("div");
        self.initElement (div, elem);

        $elem = $(elem);

        if(self.settings.compatibility) {
            if(div.constructor === self.elements.FBox) {
                $elem.css("position", "relative");
            } else if(div.constructor === self.elements.Box) {
                $elem.css("position", "absolute");
            }
        }

        parms = div.parms;
        if(parms.t !== undefined) {
            $elem.css("top", parms.t);
        }
        if(parms.l !== undefined) {
            $elem.css("left", parms.l);
        }
        if(parms.b !== undefined) {
            $elem.css("bottom", parms.b);
        }
        if(parms.r !== undefined) {
            $elem.css("right", parms.r);
        }
        if(parms.h !== undefined) {
            $elem.css("height", parms.h);
        }
        if(parms.w !== undefined) {
            $elem.css("width", parms.w);
        }

        // visibility
        if(parms.visible === false) {
            $elem.css("display", "none");
        }

        return [ elem ];
    }

    function createInput(document) {
        var input, elem, label, id, booleanAttributes, idx, val ;

        input = this;

        elem = document.createElement("input");

        label = undefined;
        if((input.parms.type === 'radio' || input.parms.type === 'checkbox') && input.parms.label !== undefined) {
            input.parms.label = ' '+input.parms.label;
            id = input.parms.id;
            if(id === undefined) {
                id = _.uniqueId();
                input.parms.id = id;
            }
            label = (new self.elements.label({ id:"label-"+id, "for":id }, input.parms.label)).createElement(document)[0];
        }

        booleanAttributes = [ "disabled", "checked" ];
        _.each(booleanAttributes, function(attribute) {
            if(input.parms[attribute] !== undefined) {
                if(input.parms[attribute] === true) {
                    input.parms[attribute] = attribute;
                } else {
                    delete input.parms[attribute];
                }
            }
        });

        self.initElement (input, elem);
        return ( label !== undefined ? [ elem, label ] : [ elem ] );
    }

    function createAnchor(document) {
        var link, elem, $elem;

        link = this;

        // WAI: make link accessible with keyboard
        if(!link.parms.tabindex) {
            link.parms.tabindex = "0";
        }

        // WAI: make link accessible with keyboard
        if(!link.parms.href) {
            link.parms.role = "button";
        }

        elem = document.createElement("a");
        $elem = $(elem);

        self.initElement (link, elem);

        if(link.parms && link.parms.value) {
            elem.innerHTML = _.escape(link.parms.value);
        }

        // WAI: set title to "" ifmissing
        if(!link.parms.title) {
            $elem.attr("title", "");
        }

        // WAI: make link accessible with keyboard
        if(!link.parms.href) {
            $elem.on("keypress", (e) => e.which === 13 && $elem.click());
        }

        return [ elem ];
    }

    function createSelect(document) {
        var select, elem, optionString, options, optgroup;

        select = this;

        elem = document.createElement("select");
        self.initElement(select, elem);

        function createOption(label, value) {
            var option = document.createElement("option");

            value = value || label;

            option.value     = _.isArray(value) ? value[0] : value;
            option.innerHTML = _.isArray(label) ? _.escape(label[1]) : _.escape(label);

            if(select.parms.selected  === option.value) {
                option.selected = "selected";
            }

            return option;
        }


        if(select.parms.options) {
            options = select.parms.options;
            // array
            if(_.isArray(options)) {    // [ ["a","value1"],["b","value2"] ] | [ "value1", "value2" ]
                _.each(options, function(option) {
                    elem.appendChild(createOption(option));
                });
            // object
            } else {
                _.each(options, function(group, name) {

                    // { a: { ... } } | { a: [ ... ] }
                    if(_.isObject(group)) {
                        optgroup = document.createElement("optgroup");
                        elem.appendChild(optgroup);
                        optgroup.label = name;

                        // { a: [ ["a","value1"], ["b","value2"] ] }
                        if(_.isArray(group)) {
                            _.each(group, function(array) {
                                optgroup.appendChild(createOption(array));
                            });

                        // { a: { a:"value1", b:"value2" } }
                        } else {
                            _.each(group, function(value, name) {
                                optgroup.appendChild(createOption(value, name));
                            });
                        }

                    // { a:"value1", b:"value2" }
                    } else {
                        elem.appendChild(createOption(group/* not really a optgroup here */, name));
                    }
                });
            }
        }

        return [ elem ];
    }

    function createImage(document) {
        var image, elem;

        image = this;

        // insert 1px transparent image ifsrc is missing
        if(!image.parms.src) {
            image.parms.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
        }

        elem = document.createElement("img");
        self.initElement(image, elem);

        // WAI: set alt to "" if alt is missing
        if(!image.parms.alt) {
            $(elem).attr("alt", "");
        }

        return [ elem ];
    }

    function createFieldset(document) {
        var listitem, elem, legend;

        listitem = this;

        elem = document.createElement("fieldset");
        self.initElement(listitem, elem);

        if( listitem.parms.legend !== undefined ) {
            legend = document.createElement("legend");
            legend.innerHTML = _.escape(listitem.parms.legend);
            elem.insertBefore( legend, elem.firstChild );
        }

        return [ elem ];
    }

    /**
     * keep compatibility with Button
     * @deprecated
     */
    function createButton(document) {
        var button, elem;

        button = this;

        elem = document.createElement("input");
        if(!button.parms.type) {
            button.parms.type = "button";
        }

        self.initElement(button, elem);

        return [ elem ];
    }

    /**
     * keep compatibility with Header
     * @deprecated
     */
    function createHeader(document) {
        var header, level, elem;

        header = this;

        level = (header.parms.level && header.parms.level >= 1 && header.parms.level <= 6) ? header.parms.level : 1;
        elem = document.createElement("h"+level);

        self.initElement(header, elem);
        if(header.parms && header.parms.value) {
            elem.innerHTML = _.escape(header.parms.value);
        }
        return [ elem ];
    }

    /**
     * keep compatibility with Label
     * @deprecated
     */
    function createLabel(document) {
        var label, elem;

        label = this;

        elem = document.createElement("label");
        self.initElement (label, elem);

        if(label.parms && label.parms.value) {
            elem.innerHTML = _.escape(label.parms.value);
        }
        return [ elem ];
    }

/*******************************************************************************************************
 jQuery Plugin definitions
 *******************************************************************************************************/

    /**
     * Adds the specified element, or elements with the jQuery DOM manipulation function.
     *
     * @method  addDOMElements
     * @for     BoxWorld.jQuery
     * @param   {Box|Array} elems           Single element or array of elements.
     * @param   {String}    manipulationFn  jQuery DOM manipulation function.
     * @return  {jQuery}                    The jQuery element where the new element has been added.
     */
    $.fn.addDOMElements = function(elems, manipulationFn) {
        var frag, elem, idx, self, document;

        self = this;

        if(!elems) {
            return this;
        }

        _.each(this, function(element) {
            document = $(element).prop('ownerDocument');

            // create document fragment
            frag = document.createDocumentFragment();

            addChildren(frag, elems);
            $(element)[manipulationFn](frag);
        });

        return this;
    };

    /**
     * Prepends the generated html to all matched elements.
     *
     * @method  prependElement
     * @for     BoxWorld.jQuery
     * @param   {Box|Array}       box     Single box or array of boxes.
     * @return  {jQuery}            The prepended box or the last prepended box ifarray of boxes.
     */
    $.fn.prependElement = function(box) {
        return $(this).addDOMElements(box, 'prepend').children().first();
    };

    /**
     * Appends the generated html to all matched elements.
     *
     * @method  prependElement
     * @for     BoxWorld.jQuery
     * @param   {Box|Array}     box     Single box or array of boxes.
     * @return  {jQuery}                The appended box or the last appended box ifarray of boxes.
     */
    $.fn.appendElement = function(box) {
        return $(this).addDOMElements(box, 'append').children().last();
    };

    /**
     * Adds the generated html after all matched elements.
     *
     * @method  afterElement
     * @for     BoxWorld.jQuery
     * @param   {Box|Array}       box     Single box or array of boxes.
     * @return  {jQuery}            The box next to the matched element.
     */
    $.fn.afterElement = function(box) {
        return $(this).addDOMElements(box, 'after').next();
    };

    /**
     * Adds the generated html before all matched elements.
     *
     * @method  beforeElement
     * @for     BoxWorld.jQuery
     * @param   {Box|Array}       box     Single box or array of boxes.
     * @return  {jQuery}            The box before the matched element.
     */
    $.fn.beforeElement = function(box) {
        return $(this).addDOMElements(box, 'before').prev();
    };

/*******************************************************************************************************
 Underscore.js
 *******************************************************************************************************/

    _.mixin({
        /**
         * Equal to _.union function but with additional iterator function
         * @param array     array containing arrays to create the union of
         * @param iterator  iterator function returning value for equality check
         * @return Union of all array's
         */
        unity: function(array, iterator) {
            return _.uniq(_.flatten(array, true), false, iterator);
        }
    });

/*******************************************************************************************************
 Extensions for standard Javascript types
 *******************************************************************************************************/

    if(!String.prototype.markup) {
        /**
         * Adds BoxWorld markups to string
         *
         * <pre>
         * e.g.
         * div({}, "one %stwo%s %sthree%s".markup(strong({}), option({ value:"%s" })))
         *
         * result:
         * div({}, [ "one ", strong({}, "two"), " ", option({ value:"three" }) ])
         * </pre>
         *
         * @method  markup
         * @for     BoxWorld.JavaScript.String
         * @return  {Array} input text with marked words wrapped inside provided BoxWorld elements
         */
        String.prototype.markup = function() {
            var result, markup, markups, self, match, i;

            self = this;
            markups = arguments;
            result = [];

            _.each(markups, function(markup) {

                // match[1] ... text before markup
                // %s
                // match[2] ... markup
                // %s
                // match[3] ... text after markup
                match = self.match(/^(.*?)%s(.*?)%s(.*)$/);

                if(match) {
                    // replace string with remaining unmatched text
                    self = match[3];

                    // replace all occurrences of %s in all parms
                    _.each(markup.parms, function(value, name) {
                        if(value.indexOf("%s") !== -1) {
                            markup.parms[name] = value.replace(/%s/g, match[2]);
                        }
                    });

                    // set matched text as child element
                    markup.children = match[2];

                    // add text before matched text to result
                    result.push(match[1]);
                    // add newly generated markup to result
                    result.push(markup);
                }
            });

            // add all remaining unmatched text to result
            result.push(self);

            return result;
        };
    }

/*******************************************************************************************************
 Helper functions
 *******************************************************************************************************/

    /**
     * Check if a special condition is valid.
     *
     * Functions
     * @method  assert
     * @param   {Boolean}   exp     Expression to assert
     * @param   {String}    message The message ifthe boolean is false.
     * @throws  ParamException
     */
    window.assert = function(exp, message) {
        if(!exp) {
            throw message;
        }
    };

/*******************************************************************************************************
 Create BoxWorld instance and expose as $.box
 *******************************************************************************************************/

    // export BoxWorld to window
    rise.BoxWorld = BoxWorld;

    // Define the Plug-in as a singleton instance of the type BoxWorld
    $.box = new BoxWorld();

}(window.rise));
/* @license end of code */

export const BoxWorld = $.box;
