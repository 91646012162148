/**
 * Data Access Object for the business_document endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.business_document");

    /*** Export Dao ***/
    app.dao.businessDocumentDao = new BusinessDocumentDao();

    /**
     * @constructor
     */
    function BusinessDocumentDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.BusinessDocument, new Rpc('/rpc/business_document'));
    }

    // extend the parent Dao prototype
    $.extend(BusinessDocumentDao.prototype, Object.create(rise.Dao.prototype));

    BusinessDocumentDao.prototype.setActiveState = function(id, active) {
        var funcName = active ? "activate" : "deactivate";
        var deferred = new $.Deferred();

        log.info('Set active state');

        this.rpc.invoke(funcName, {'id': id})
            .done(function(business_document) {
                log.info('business_document with id ' + id + " has been " + funcName + "d");
                deferred.resolve(business_document);
            })
            .fail(function (error) {
                log.error(funcName + ' failed. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

    BusinessDocumentDao.prototype.markRead = function(id) {
        var deferred = new $.Deferred();

        log.info('Mark business_document with id ' + id + " as read");

        this.rpc.invoke('mark_read',
                        { business_document_id: id })
            .done(function() {
                deferred.resolve();
            })
            .fail(function (error) {
                log.error('Cannot mark business_document as read. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

    BusinessDocumentDao.prototype.getAssignments = function(id) {
        var deferred = new $.Deferred();

        log.info('Get assignments for business_document with id ' + id + " as read");

        this.rpc.invoke('get_assignments', { business_document_id: id })
            .done(function(assignments) {
                deferred.resolve(assignments);
            })
            .fail(function (error) {
                log.error('Error retrieving business_document assignments. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

    BusinessDocumentDao.prototype.getBusinessDocumentAndAssignments = function(id) {
        var deferred = new $.Deferred();

        log.info('Get business_document and assignments for business_document with id ' + id);

        this.rpc.invoke('get_business_document_and_assignments', { business_document_id: id })
            .done(function(result) {
                deferred.resolve(result);
            })
            .fail(function (error) {
                log.error('Error retrieving business_document and assignments. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

    BusinessDocumentDao.prototype.update = function(id, customers, locations, objects, workplaces, category_id, customer_only) {
        var deferred = new $.Deferred();

        log.info('Update assignments for business_document with id ' + id + " as read");

        this.rpc.invoke('update', {
            business_document_id: id,
            assignments: {
                'customers': customers,
                'locations': locations,
                'objects':   objects,
                'workplaces':workplaces
            },
            category_id: category_id,
            customer_only: customer_only
        })
        .done(function(assignments) {
            deferred.resolve(assignments);
        })
        .fail(function (error) {
            log.error('Error during updating business_document assignments. %s', JSON.stringify(error));
            deferred.reject(error);
        });

        return deferred.promise();
    };

    BusinessDocumentDao.prototype.listByUser = function(params) {
        var deferred = new $.Deferred();

        log.info('Find business_documents for current shift');

        this.rpc.invoke("list_by_user", params)
            .done(function(business_documents) {
                deferred.resolve(_.map(business_documents, function(json) {
                    return new app.dto.BusinessDocumentListDto(json);
                }));
            })
            .fail(function (error) {
                log.error('List of business_documents cannot be retrieved. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

    BusinessDocumentDao.prototype.insert = function(business_document, fileContents, assignedCustomers,
        assignmentsLocations, assignedObjects, assignedWorkplaces) {
        var deferred = new $.Deferred();

        log.info('insert business_document with attachment');

        this.rpc.invoke("insert", {
            business_document:business_document,
            file_contents: fileContents,
            assignments: {
                'customers': assignedCustomers,
                'locations': assignmentsLocations,
                'objects':   assignedObjects,
                'workplaces':assignedWorkplaces
            }
        })
        .done(function(json) {
            deferred.resolve(new app.model.BusinessDocument(json));
        })
        .fail(function (error) {
            log.error('cannot create business_document! %s', JSON.stringify(error));
            deferred.reject(error);
        });

        return deferred.promise();
    };

    /**
     * Get all read confirmations for a particular business_document
     *
     * @param business_document_id The business_document id
     */
    BusinessDocumentDao.prototype.getReadConfirmations = function(business_document_id) {
        var deferred = new $.Deferred();

        log.info('Find read confirmations for the business_document with id ' + business_document_id);

        this.rpc.invoke("get_read_confirmations", { business_document_id: business_document_id })
            .done(function(readConfirmations) {
                deferred.resolve(_.map(readConfirmations, function(json) {
                    return new app.dto.BusinessDocumentReadConfirmationListDto(json);
                }));
            })
            .fail(function (error) {
                log.error('List of read confirmations cannot be retrieved. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

    BusinessDocumentDao.prototype.listAll = function(params) {
        log.info('List all BusinessDocuments');
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke('list_all', params)
            .done(function(data) {
                if (data.length === 0) {
                    log.info('No business_document data sets found');
                    deferred.resolve([]);
                } else {
                    log.info('Received %s BusinessDocumentListDto(s)', data.length);
                    deferred.resolve(data.map(function(d) {
                        return new app.dto.BusinessDocumentListDto(d);
                    }));
                }
            })
            .fail(function(error) {
                log.error('Fetching all BusinessDocuments failed. %s', error.message);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    BusinessDocumentDao.prototype.getCustomerList = function () {
        var deferred = new $.Deferred();

        this.rpc.invoke('get_customer_list')
            .done(function(rows) {
                deferred.resolve(_.map(rows, function(e) {return new app.model.Customer(e);}));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

})(window.rise, window.app);
