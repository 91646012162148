/**
 * Location detail page.
 *
 * @author Martin Reiterer (martin.reiterer@rise-world.com)
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function (rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var pages = app.pages;
    var TourTimeCForm = app.widgets.TourTimeCForm;

    /** DAOs **/
    var customerDao = app.dao.customerDao;
    var locationDao = app.dao.locationDao;
    var tourDao = app.dao.tourDao;

    var PageType = {
        VIEW: 1,
        EDIT: 2,
        NEW: 3
    };

    app.printpreview.openCustomerPrintPreview = function printCustomer(params) {
        var customer = params.customer,
            location = params.location,
            object = params.object,
            workplace = params.workplace;

        app.printpreview.openPrintPreview({
            title: "Stammdaten",
            reportClass: "customer-report",
            fnRenderHeaderAdditions: function(parent) {
                parent.appendElement(span("Kunde: " + customer.name));
            },
            fnRenderBody: function($parent) {
                var deferreds = [];

                if (workplace) {
                    deferreds.push.apply(deferreds, printWorkplace($parent, workplace));
                } else if (object) {
                    deferreds.push.apply(deferreds, printObject($parent, object));
                } else if (location) {
                    deferreds.push.apply(deferreds, printLocation($parent, location));
                } else if (customer) {
                    $parent.appendElement([
                        div(function(box) {
                            deferreds.push(printTours(box, {customer_id: customer.id}));
                        }),
                        div(function(box) {
                            _.each(_.sortBy(customer.locations, sortName), function(loc) {
                                deferreds.push.apply(deferreds, printLocation(box, loc));
                            })
                        })
                    ]);
                }

                return $.when.apply($, deferreds);
            }
        })
    };

    function sortName(a) {
        return a.name;
    }

    function printLocation($parent, location) {
        var deferreds = [];

        $parent.appendElement([
            div({'class': 'location-details' }, [
                h2(sprintf("Standort: %s", location.name)),
                pages.location.detail.createLocationDetails(location, PageType.VIEW)
            ]),
            div(function(box) {
                deferreds.push(printTours(box, {location_id: location.id}));
            }),
            div(function(box) {
                _.each(_.sortBy(location.objects, sortName), function(obj) {
                    deferreds.push.apply(deferreds, printObject(box, obj));
                })
            })
        ]);

        return deferreds;
    }

    function printObject($parent, object) {
        var deferreds = [];

        $parent.appendElement([
            div({'class': 'object-details' }, [
                h2(sprintf("Objekt: %s", object.name)),
                pages.object.detail.createObjectDetails(object, PageType.VIEW)
            ]),
            div(function(box) {
                deferreds.push(printTours(box, {object_id: object.id}));
            }),
            div(function(box) {
                _.each(_.sortBy(object.workplaces, sortName), function(workplace) {
                    deferreds.push.apply(deferreds, printWorkplace(box, workplace));
                })
            })
        ]);

        return deferreds;
    }

    function printWorkplace($parent, workplace) {
        var deferreds = [];

        $parent.appendElement([
            div({'class': 'workplace-details' }, [
                h2(sprintf("Arbeitsplatz: %s", workplace.name)),
                pages.workplace.detail.createWorkplaceDetails(workplace, PageType.VIEW)
            ]),
            div(function(box) {
                deferreds.push(printTours(box, {workplace_id: workplace.id}));
            })
        ]);

        return deferreds;
    }

    function printTours($parent, params) {
        params.transitive = false;

        return $.when(
            tourDao.findTourDetails(params, {tourTags: true, timecs:true, assignments: false, assignableTags: false}),
            tourDao.getCalendars()
        ).done(function(result, calendars) {
            _.each(result, function(tourDto) {
                var tour = tourDto.tour,
                    timecs = tourDto.timecs,
                    tourStartTag = tourDto.tourStartTag,
                    tourTags = tourDto.tourTags,
                    tourEndTag = tourDto.tourEndTag,
                    tags = []
                        .concat(tourStartTag ? tourStartTag.name + "-START" : [])
                        .concat(_.map(tourTags, function(tag) {
                            return [tag.name, tag.must_read
                                ? span(" (verpflichtend)")
                                : span({'class': 'grey'}, " (nicht verpflichtend)")];
                        }))
                        .concat(tourEndTag ? tourEndTag.name + "-END" : []);

                $parent.appendElement(
                    div({ id:"tour-" + tour.id, 'class': 'tour-details' }, [
                        h4(sprintf("Rundgang: %s", tour.name)),
                        pages.tour.detail.renderTourForm(tour, PageType.VIEW),
                        div({ "class": "tags-details" }, [
                            h4("Kontrollpunkte"),
                            // render tags
                            ul(_.map(tags, function(elem) {
                                    return li(elem);
                                })
                            ),
                            p([
                                span(sprintf("Anzahl der Kontrollpunkte: %s", tags.length)),
                                span({'class': 'grey'}, " (inkl. Start und Ende)")
                            ]),
                            timecs && timecs.length ?
                                div({'class': 'time-details'}, [
                                    h4("Zeitliche Einstellungen"),
                                    div(_.map(timecs, function(timec) {
                                        return new TourTimeCForm(calendars, timec).renderWidget(PageType.VIEW);
                                    }))
                                ])
                                : undefined
                        ])
                    ])
                );
            });
        });
    }

})(window.rise, window.app);
