/**
 * Data Access Object for the location endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.location");

    /*** Export Dao ***/
    app.dao.locationDao = new LocationDao();

    /**
     * @constructor
     */
    function LocationDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.Location, new Rpc('/rpc/location'));
    }

    // extend the parent Dao prototype
    $.extend(LocationDao.prototype, Object.create(rise.Dao.prototype));

    /**
     * Get a particular location
     */
    LocationDao.prototype.getLocationDetails = function(location_id) {
        var deferred = new $.Deferred();

        this.rpc.invoke('get_location_details', { 'id':location_id })
            .done(function(json) {
                deferred.resolve( new app.dto.LocationDetailsDto(json) );
            })
            .fail(function(error) {
                log.error('Unable to retrieve location failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Get a particular location
     */
    LocationDao.prototype.getLocationAssignments = function(location_id) {
        var deferred = new $.Deferred();

        this.rpc.invoke('get_location_details', { 'id':location_id })
            .done(function(json) {
                deferred.resolve( new app.dto.LocationAssignmentsDto(json) );
            })
            .fail(function(error) {
                log.error('Unable to retrieve location failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Insert location and assignments
     */
    LocationDao.prototype.insertLocationAndAssignments = function(location, assignedEventTypes, assignedBusinessDocuments) {
        var deferred = new $.Deferred();

        this.rpc.invoke('insert_location_and_assignments',
            { location:location, event_type_ids:assignedEventTypes, business_document_ids:assignedBusinessDocuments })
            .done(function(json) {
                var result = new app.model.Location(json);
                deferred.resolve(result);
            })
            .fail(function(error) {
                log.error('Inserting location failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Update location and assignments
     */
    LocationDao.prototype.updateLocationAndAssignments = function(location, assignedEventTypes, assignedBusinessDocuments) {
        var deferred = new $.Deferred();

        this.rpc.invoke('update_location_and_assignments',
            { location:location, event_type_ids:assignedEventTypes, business_document_ids:assignedBusinessDocuments })
            .done(function(json) {
                var result = new app.model.Location(json);
                deferred.resolve(result);
            })
            .fail(function(error) {
                log.error('Updating location failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

})(window.rise, window.app);
