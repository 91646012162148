/**
 * TaskLogbook detail page.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function (rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var tabsWidget = app.widgets.tabsWidget;
    var nav = rise.Navigation;
    var scrollTableSection = app.widgets.scrollTableSection;


    /** DAOs **/
    var LogbookEntryNote = app.model.LogbookEntryNote;
    var logbookEntryNoteDao = app.dao.logbookEntryNoteDao;
    var taskLogbookEntryDao = app.dao.taskLogbookEntryDao;
    var commandBox = app.widgets.commandBox;
    var saveIconButton = app.widgets.saveIconButton;
    var addIconButton = app.widgets.addIconButton;
    var iconButton = app.widgets.iconButton;

    /*** GLOBALS ***/
    var pages = app.pages;
    var form = app.widgets.form;

    var rotationTypes = [
        {id: "UNIQUE", name: "Einmalig"},
        {id: "DAILY", name: "Täglich"},
        {id: "WEEKLY", name: "Wöchentlich"},
        {id: "MONTHLY", name: "Monatlich"}
    ];

    var obj;

    /*** CONSTANTS ***/
    var TASK_LOGBOOK_ENTRY_NOTE_SHOW_ID = 'task-logbook-entry-notes-show';
    var TASK_LOGBOOK_ENTRY_CONTENT_ID = 'task-logbook-entry-content';

    /*** Export page ***/

    pages.logbook.task.detail.open = app.pagesByPath['/logbook/task/detail/open'] = function TaskLogbookDetailOpen() {

        obj = this;

        this.name = "/logbook/task/detail/open";

        this.taskDto = null;
        this.taskLogbookEntry = null;
        this.loggedUser = null;

        this.create = function ($parent, taskLogbookEntryId, taskLogbookEntriesById) {
            taskLogbookEntryDao.getTaskLogbookEntryDetails(taskLogbookEntryId)
                .done(function (taskLogbookEntryDetailsDto) {
                    obj.taskDto = taskLogbookEntryDetailsDto.task;
                    obj.loggedUser = taskLogbookEntryDetailsDto.logged_user;
                    obj.taskLogbookEntry = taskLogbookEntryDetailsDto.task_logbook_entry;
                    obj.taskLogbookEntryReadLog = taskLogbookEntryDetailsDto.task_logbook_entry_read_log;
                    obj.taskLogbookEntriesById = taskLogbookEntriesById;

                    obj.taskLogbookEntry.notes = obj.taskLogbookEntry.notes.sort(function (a, b) {
                        return a.created_time - b.created_time;
                    });
                    renderTaskLogbookDetailOpen($parent);
                });
        };

        this.destroy = function () {
            // called after page has been destroyed
        };

        function renderTaskLogbookDetailOpen($parent) {
            $parent.appendElement([
                renderCommandBox($parent, [true], obj.taskDto.task.label, pages.logbook.task.list.open,
                    pages.logbook.task.detail.open,
                    obj.taskLogbookEntry.id, obj.taskLogbookEntriesById),


                function ($box) {
                    var tabs = [
                            {
                                'id': 'task-logbook-entry-general-information-tab',
                                'title': 'Allgemein',
                                'fnRender': $.proxy(renderGeneralInformation, self)
                            },
                            {
                                'id': 'task-logbook-entry-comment-tab',
                                'title': 'Anmerkungen',
                                'fnRender': $.proxy(renderNotesSection, self)
                            },
                            {
                                'id': 'task-logbook-entry-read-log-tab',
                                'title': 'Protokoll',
                                'fnRender': $.proxy(renderReadConfirmations, self, obj.taskLogbookEntryReadLog)
                            }
                        ],
                        user = app.session.getSessionInfo().user;

                    if (user.type === app.model.UserType.EMPLOYEE &&
                        !(app.session.hasPermission(app.model.PermissionTypes.TASK_VIEW_ADMIN ||
                            app.session.hasPermission(app.model.PermissionTypes.TASK_VIEW_SUPERVISOR)))) {
                        tabs.length = 2;
                    }

                    $box.appendElement(tabsWidget({id: "task-logbook-entry-details"}, tabs));
                }
            ]);

            $parent.activateValidators();
        }

        function saveNote(logbookEntryNote) {

            var deferred = new $.Deferred();

            logbookEntryNoteDao.insert(logbookEntryNote)
                .done(function (newTaskLogbookEntryNote) {
                    obj.taskLogbookEntry.notes.push(newTaskLogbookEntryNote);

                    deferred.resolve(newTaskLogbookEntryNote);
                });

            return deferred.promise();
        }

        function renderNotesSection() {
            return div({}, [
                // task logbook entry notes
                renderTaskLogbookEntryNotes(obj.taskLogbookEntry.notes, obj.taskLogbookEntry.id, saveNote)
            ]);
        }

        function renderGeneralInformation() {
            return div({}, [
                // task
                renderTaskForm(obj.taskLogbookEntry, obj.taskDto, obj.loggedUser, true, true),

                // finish task
                renderTaskFinish(obj.taskLogbookEntry)
            ]);
        }

        // form to finish task
        function renderTaskFinish(taskLogbookEntry) {
            return div({id: 'task-finish'}, [
                h2({}, 'Aufgabe abschließen'),
                div({id: 'task-finish-form'}, [
                    renderTaskFinishForm(taskLogbookEntry),
                    div({"class": "action-box bottom"},
                        ul({},
                            li({}, saveIconButton({
                                id: 'task-finish-button', click: function () {
                                    if ($("#task-finish-form").validate()) {
                                        taskLogbookEntryDao.finishTask(taskLogbookEntry.id, taskLogbookEntry.comment).done(
                                            function () {
                                                nav.goToPage(pages.logbook.task.list.open, [false]);
                                                app.showSuccessMessage('Aufgabe abgeschlossen');
                                            }
                                        );
                                    }
                                }
                            }, 'Als erledigt markieren'))
                        )
                    )
                ])
            ]);
        }

        function renderTaskFinishForm(taskLogbookEntry) {
            return fieldset({id: 'task-finish-form-show'}, [
                ul({'class': 'fieldset-content'}, [
                    form.formItemTextarea({
                        id: 'task-finish-form-comment', label: 'Anmerkung',
                        required: false, value: taskLogbookEntry.comment,
                        change: function () {
                            taskLogbookEntry.comment = $(this).val();
                        }
                    })
                ])
            ]);
        }
    };

    pages.logbook.task.detail.finished = app.pagesByPath['/logbook/task/detail/finished'] = function TaskLogbookDetailFinished() {
        var obj = this;
        this.name = "/logbook/task/detail/finished";

        this.taskLogbookEntryDto = null;
        this.taskDto = null;
        this.loggedUser = null;

        this.create = function ($parent, taskLogbookEntryId, taskLogbookEntriesById) {
            taskLogbookEntryDao.getTaskLogbookEntryDetails(taskLogbookEntryId)
                .done(function (taskLogbookEntryDetailsDto) {
                    obj.taskDto = taskLogbookEntryDetailsDto.task;
                    obj.loggedUser = taskLogbookEntryDetailsDto.logged_user;
                    obj.taskLogbookEntryDto = taskLogbookEntryDetailsDto.task_logbook_entry;
                    obj.taskLogbookEntryReadLog = taskLogbookEntryDetailsDto.task_logbook_entry_read_log;
                    obj.taskLogbookEntriesById = taskLogbookEntriesById;

                    obj.taskLogbookEntryDto.notes = obj.taskLogbookEntryDto.notes.sort(function (a, b) {
                        return a.created_time - b.created_time;
                    });
                    renderTaskLogbookDetailFinished($parent);
                });
        };

        this.destroy = function () {
            // called after page has been destroyed
        };

        function renderTaskLogbookDetailFinished($parent) {
            $parent.appendElement([

                renderCommandBox($parent, [true, obj.taskLogbookEntryDto], obj.taskDto.task.label, pages.logbook.task.list.finished,
                    pages.logbook.task.detail.finished, obj.taskLogbookEntryDto.id, obj.taskLogbookEntriesById),


                function ($box) {
                    var tabs = [
                            {
                                'id': 'task-logbook-entry-general-information-tab',
                                'title': 'Allgemein',
                                'fnRender': $.proxy(renderGeneralInformation, self)
                            },
                            {
                                'id': 'task-logbook-entry-comment-tab',
                                'title': 'Anmerkungen',
                                'fnRender': $.proxy(renderNotesSection, self)
                            },
                            {
                                'id': 'task-logbook-entry-read-log-tab',
                                'title': 'Protokoll',
                                'fnRender': $.proxy(renderReadConfirmations, self, obj.taskLogbookEntryReadLog)
                            }
                        ],
                        user = app.session.getSessionInfo().user;

                    if (user.type === app.model.UserType.EMPLOYEE &&
                        !(app.session.hasPermission(app.model.PermissionTypes.TASK_VIEW_ADMIN ||
                            app.session.hasPermission(app.model.PermissionTypes.TASK_VIEW_SUPERVISOR)))) {
                        tabs.length = 2;
                    }

                    $box.appendElement(tabsWidget(
                        {id: "task-logbook-entry-details"}, tabs));
                }
            ]);

            $parent.activateValidators();

        }

        function saveNote(logbookEntryNote) {

            var deferred = new $.Deferred();

            logbookEntryNoteDao.insert(logbookEntryNote)
                .done(function (newTaskLogbookEntryNote) {
                    obj.taskLogbookEntryDto.notes.push(newTaskLogbookEntryNote);

                    deferred.resolve(newTaskLogbookEntryNote);
                });

            return deferred.promise();
        }

        function renderGeneralInformation() {
            return div({}, [
                // task logbook entry
                renderTaskForm(obj.taskLogbookEntryDto, obj.taskDto, obj.loggedUser, true, false)
            ]);
        }

        function renderNotesSection() {
            return div({}, [
                // task logbook entry notes
                renderTaskLogbookEntryNotes(obj.taskLogbookEntryDto.notes, obj.taskLogbookEntryDto.id, saveNote)
            ]);
        }

    };

    // task logbook entry notes
    function renderTaskLogbookEntryNotes(logbookEntryNotes, logbookEntryId, saveNote) {

        var logbookEntryNote = new LogbookEntryNote();
        logbookEntryNote.logbook_entry_id = logbookEntryId;
        logbookEntryNote.user_id = app.session.getSessionInfo().user.id;

        return div({id: 'task-logbook-entry-notes'}, [
            div({id: TASK_LOGBOOK_ENTRY_NOTE_SHOW_ID},
                renderTaskLogbookEntryNoteShowForms(logbookEntryNotes)
            ),
            div({id: 'task-logbook-entry-notes-new'},
                renderTaskLogbookEntryNoteAddForm(logbookEntryNote, saveNote)
            )
        ]);
    }

    function renderTaskLogbookEntryNoteShowForms(logbookEntryNotes) {
        return $.map(logbookEntryNotes, function (logbookEntryNote) {
            return renderTaskLogbookEntryNoteForm(true, logbookEntryNote);
        });
    }

    function renderTaskLogbookEntryNoteAddForm(logbookEntryNote, saveNote) {
        return div({id: 'task-logbook-entry-note-new-form'}, [
            renderTaskLogbookEntryNoteForm(false, logbookEntryNote),
            div({"class": "action-box bottom"},
                ul({},
                    li({}, addIconButton({
                        id: 'task-logbook-entry-note-save', click: function () {
                            if ($("#task-logbook-entry-note-new-form").validate()) {
                                saveNote(logbookEntryNote)
                                    .done(function (newTaskLogbookEntryNote) {
                                        // append new note
                                        $('#' + TASK_LOGBOOK_ENTRY_NOTE_SHOW_ID)
                                            .appendElement(renderTaskLogbookEntryNoteForm(true, newTaskLogbookEntryNote));

                                        // reset content field
                                        $('#' + TASK_LOGBOOK_ENTRY_CONTENT_ID).val('');

                                        app.showSuccessMessage('Anmerkung hinzugefügt');
                                    });
                            }
                        }
                    }, 'Anmerkung hinzufügen'))
                )
            )
        ]);
    }

    function renderTaskLogbookEntryNoteForm(view, logbookEntryNote) {
        return fieldset({id: 'task-logbook-entry-notes-form'}, [
            ul({'class': 'fieldset-content'}, [
                view && (logbookEntryNote._user_first_name || logbookEntryNote._user_last_name)
                    ? form.formItemText({
                        id: 'task-logbook-entry-employee', label: 'Benutzer',
                        value: logbookEntryNote._user_first_name + ' ' + logbookEntryNote._user_last_name
                    }, view)
                    : undefined,
                view
                    ? form.formItemDateTime({
                        id: 'task-logbook-entry-date', label: 'Datum',
                        date: logbookEntryNote.created_time
                    }, view)
                    : undefined,
                form.formItemTextarea({
                    id: TASK_LOGBOOK_ENTRY_CONTENT_ID + (view ? '-view' : ''), label: 'Inhalt',
                    required: true, value: logbookEntryNote.content,
                    change: function () {
                        logbookEntryNote.content = $(this).val();
                    }
                }, view)
            ])
        ]);
    }

    function renderCommandBox($parent, parameters, taskLabel, pageBack, pageNextPrev, taskLogbookEntryId, taskLogbookEntriesById) {

        var currentLogbookEntry = taskLogbookEntriesById[taskLogbookEntryId];

        function backAction() {
            if (this instanceof backAction) {
                throw new Error('not constructable');
            }
            nav.goToPage(pageBack, parameters);
        }

        function getCommandButtons() {

            function prevButton() {
                return iconButton({
                    id: 'prev-task-logbook', "class": ' button-previous',
                    click: function () {
                        nav.goToPage(pageNextPrev, [currentLogbookEntry.prev, taskLogbookEntriesById]);
                    }
                });
            }

            function nextButton() {
                return iconButton({
                    id: 'next-task-logbook', "class": 'button-foreward ',
                    click: function () {
                        nav.goToPage(pageNextPrev, [currentLogbookEntry.next, taskLogbookEntriesById]);
                    }
                });
            }

            return [
                !!currentLogbookEntry.next ? nextButton() : undefined,
                !!currentLogbookEntry.prev ? prevButton() : undefined
            ];

        }

        return commandBox({title: 'Aufgabe: ' + taskLabel, backAction: backAction}, getCommandButtons());

    }

    // task / logbook entry
    function renderTaskForm(logbookEntry, taskDto, loggedUser, view, taskView) {
        return div({}, [
            h2({}, "Details"),
            fieldset({id: 'task-form'}, [
                ul({'class': 'fieldset-content'}, [
                    form.formItemText({
                        id: 'task-event', label: 'Ereignistyp',
                        value: logbookEntry.done ? 'Abgeschlossene Aufgabe' : 'Offene Aufgabe'
                    }, view),
                    form.formItemText({
                        id: 'task-rotation', label: 'Wiederholung',
                        value: $.grep(rotationTypes, function (r) {
                            return r.id === taskDto.task.rotation_type;
                        })[0].name
                    }, view),
                    form.formItemDateTime({
                        id: 'task-rotation-info', label: 'Zu erledigen am',
                        date: logbookEntry.time_entered
                    }, view),
                    form.formItemText({
                        id: 'task-assignment', label: 'Zugewiesen an',
                        value: taskDto.workplace_name
                            ? taskDto.workplace_name
                            : taskDto.assigned_user.first_name + ' ' +
                            taskDto.assigned_user.last_name
                    }, view),
                    !taskView
                        ? form.formItemDateTime({
                            id: 'task-logbook-entry-time-entered', label: 'Abgeschlossen um',
                            date: logbookEntry.time_logged
                        }, view)
                        : undefined,
                    !taskView
                        ? form.formItemText({
                            id: 'task-logbook-entry-finished-by', label: 'Abgeschlossen von',
                            value: loggedUser ? loggedUser.first_name + ' ' + loggedUser.last_name : ""
                        }, view)
                        : undefined,
                    form.formItemDateTime({
                        id: 'task-created-time', label: 'Eingetragen am',
                        date: taskDto.task.active_since
                    }, view),
                    form.formItemText({
                        id: 'task-created-by', label: 'Eingetragen von',
                        value: taskDto.created_by.first_name + ' ' +
                            taskDto.created_by.last_name
                    }, view),
                    form.formItemTextarea({
                        id: 'task-description', label: 'Aufgabendetails',
                        value: taskDto.task.description
                    }, view),
                    !taskView
                        ? form.formItemText({
                            id: 'task-logbook-entry-comment', label: 'Anmerkung',
                            value: logbookEntry.comment
                        }, view)
                        : undefined,
                    !taskView && app.session.hasPermission(app.model.PermissionTypes.IP_READ)
                        ? form.formItemText({
                            id: 'task-logbook-entry-ip', label: 'IP-Adresse',
                            value: logbookEntry.client_ip
                        }, view)
                        : undefined
                ])
            ])
        ]);
    }

    /** READ CONFIRMATIONS TAB **/

    function renderReadConfirmations(readConfirmations) {
        return scrollTableSection({
            filter: true,
            id: "read-confirmations",
            elements: readConfirmations,
            paging: true,
            columns: [
                {
                    sTitle: "Nachname",
                    sAttrName: "user_last_name"
                },
                {
                    sTitle: "Vorname",
                    sAttrName: "user_first_name"
                },
                {
                    sTitle: "Benutzername",
                    sAttrName: "user_name"
                },
                {
                    sTitle: "Zuerst gesichtet am",
                    sAttrName: "task_logbook_entry_read_first_at",
                    mRender: function (mData, type, obj) {
                        if (obj.task_logbook_entry_read_first_at) {
                            return obj.task_logbook_entry_read_first_at.toFormatString(app.settings.dateTimeFormat);
                        } else {
                            return '-';
                        }
                    }
                }
            ],
            filters: {
                task_logbook_entry_read_first_at: {
                    labels: {
                        on: "Gesichtet",
                        off: "Nicht gesichtet"
                    },
                    values: {
                        on: "^(?!-)(.*)$",
                        off: "^-$"
                    }
                }
            },
            height: "100%",
            click: displayUser
        });
    }

    function displayUser(user) {
        if (app.session.hasPermission(app.model.PermissionTypes.EMPLOYEE_MODIFY)) {
            if (obj.name === '/logbook/task/detail/open') {
                nav.goToPage(pages.user.detail, [user.user_id, '/logbook/task/list/open']);
            } else {
                nav.goToPage(pages.user.detail, [user.user_id, '/logbook/task/list/finished']);
            }
        }
    }

})(window.rise, window.app);
