/**
 * Data Access Object for the task endpoint.
 *
 * @module app.dao
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    var Rpc = rise.Rpc;
    var log = rise.logging.getLogger("dao.task");

    /*** Export Dao ***/
    app.dao.taskDao = new TaskDao();

    /**
     * @constructor
     */
    function TaskDao() {
        // call parent constructor
        rise.Dao.call(this, app.model.Task, new Rpc('/rpc/task'));
    }

    // extend the parent Dao prototype
    $.extend(TaskDao.prototype, Object.create(rise.Dao.prototype));

    TaskDao.prototype.setActiveState = function(id, active) {
        var funcName = active ? "activate" : "deactivate";
        var deferred = new $.Deferred();

        log.info('Set active state');

        this.rpc.invoke(funcName, {'id': id})
            .done(function(task) {
                log.info('task with id ' + id + " has been " + funcName + "d");
                deferred.resolve(task);
            })
            .fail(function (error) {
                log.error(funcName + ' failed. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

    /**
     * Find all tasks with additional assignment info
     */
    TaskDao.prototype.findTasks = function(filterParams) {
        log.info('Finding tasks');
        var deferred = new $.Deferred();

        if (!filterParams) {
            filterParams = {};
        }

        this.rpc.invoke('find_tasks', { filter_params: filterParams })
            .done(function(tasks) {
                if (tasks.length === 0) {
                    log.info('No tasks found');
                    deferred.resolve([]);
                } else {
                    log.info('Received %s tasks', tasks.length);
                    deferred.resolve(_.map(tasks, function(entry) {
                        return new app.dto.TaskListDto(entry);
                    }));
                }
            })
            .fail(function(error) {
                log.error('Request for finding tasks entries failed: %s', error);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TaskDao.prototype.insert = function(task) {
        log.info('Persist %s', this.model_class.name);
        var deferred = new $.Deferred();
        var self = this;

        if (task.id) {
            log.error('Cannot insert object. ID already set');
            deferred.reject({message: 'Parameter id must no be set'});
            return deferred.promise();
        }

        if (task instanceof app.model.UniqueTask === false &&
            task instanceof app.model.DailyTask === false &&
            task instanceof app.model.WeeklyTask === false &&
            task instanceof app.model.MonthlyTask === false) {
            log.error('Cannot insert object. Not an instance of the right model class "%s"', self.model_class.name);
            deferred.reject({message: sprintf('Object is not an instance of the right class "%s"', self.model_class.name)});
            return deferred.promise();
        }

        self.rpc.invoke(self.insert_method, {document: rise.Dao.sanitizeObject(task)})
            .done(function(persisted_entity) {
                log.info('%s inserted', self.model_class.name);

                //re-add the removed fields by `sanitizeObject`
                deferred.resolve(new self.model_class(
                    $.extend({}, task, persisted_entity)
                ));
                deferred.resolve(new self.model_class(persisted_entity));
            })
            .fail(function(error) {
                log.error('Persisting %s failed. %s', self.model_class.name, error.message);
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TaskDao.prototype.findByWorkplace = function(workplaceId) {
        var deferred = new $.Deferred();

        log.info('Find all tasks that are assigned to the workplace with with id ' + workplaceId);

        this.rpc.invoke("find_by_workplace", {'workplace_id': workplaceId})
            .done(function(tasks) {
                deferred.resolve(_.map(tasks, function(json) {
                    return new app.dto.TaskListDto(json);
                }));
            })
            .fail(function (error) {
                log.error('List of tasks cannot be retrieved. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TaskDao.prototype.getTaskDetails = function(taskId) {
        var deferred = new $.Deferred();

        log.info('Get model details for task with id ' + taskId);

        this.rpc.invoke("get_task_details", {'task_id': taskId})
            .done(function(json) {
                deferred.resolve(new app.dto.TaskDetailsDto(json));
            })
            .fail(function (error) {
                log.error('Task details cannot be retrieved. %s', JSON.stringify(error));
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TaskDao.prototype.getCustomerList = function () {
        var deferred = new $.Deferred();

        this.rpc.invoke('get_customer_list')
            .done(function(rows) {
                deferred.resolve(_.map(rows, function(e) {return new app.model.Customer(e);}));
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

    TaskDao.prototype.getAssignableUsers = function(customerId) {
        var deferred = new $.Deferred();
        var self = this;

        self.rpc.invoke('get_assignable_users', {'customer_id': customerId})
            .done(function(data) {
                if (data.length === 0) {
                    deferred.resolve([]);
                } else {
                    deferred.resolve(data.map(function(e) {
                        return new app.model.Employee(e);
                    }));
                }
            })
            .fail(function(error) {
                deferred.reject(error);
            });

        return deferred.promise();
    };

})(window.rise, window.app);
