/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.dto.TourRunDto = function TourRunDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id;
        this.tour_id = obj.tour_id;
        this.info = obj.info;
        this.admin_comment = obj.admin_comment;
        this.auto_ended = obj.auto_ended;
        this.manually_started = obj.manually_started;
        this.manually_ended = obj.manually_ended;
        this.user_name = obj.user_name;
        this.customer_name = obj.customer_name;
        this.workplace_name = obj.workplace_name;
        this.tour_name = obj.tour_name;
        this.tour_description = obj.tour_description;
        this.tags_count = obj.tags_count;
        this.scanned_tags_count = obj.scanned_tags_count;
        this.incorrect_order = obj.incorrect_order;
        this.durationc_violated = obj.durationc_violated;
        this.photos_count = obj.photos_count;
        this.comments_count = obj.comments_count;
        this.progress = obj.progress;
        this.app_version = obj.app_version;

        this.customers = obj.customers;
        this.locations = obj.locations;
        this.objects = obj.objects;
        this.workplaces = obj.workplaces;

        this.tour_areas = obj.tour_areas;

        /**
         * set on server side
         * @type {JustDate}
         */
        this.start_time = obj.start_time ?
            typeof obj.start_time === 'string' ?
                Date.fromISO(obj.start_time)
                : obj.start_time
            : null;

        this.end_time = obj.end_time ?
            typeof obj.end_time === 'string' ?
                Date.fromISO(obj.end_time)
                : obj.end_time
            : null;

        var end = this.end_time === null ? new Date() : this.end_time;
        var duration = (end - this.start_time) / (1000 * 60);

        this.max_duration = obj.max_duration !== null ? parseInt(obj.max_duration, 10) : null;
        this.min_duration = obj.min_duration !== null ? parseInt(obj.min_duration, 10) : null;
        this.duration = this.start_time.difference(end, "HH Std. mm Min.");

        /* If the tour-run is not finished yet, then calculate with current timestamp
           if the duration constraint is violated or not */
        if (this.end_time === null) {
            this.durationc_violated = (obj.min_duration && duration < obj.min_duration) ||
                (obj.max_duration && duration > obj.max_duration);
        }

        this.success = !this.durationc_violated && !obj.incorrect_order &&
            (obj.scanned_tags_count >= obj.tags_count) && !obj.auto_ended;
    };

    app.dto.TourCheckpointType = {
        SCANNED: 0,
        MISSED: 1,
        INVALID: 2,
        START: 3,
        END: 4
    };

    app.dto.TourCheckpointDto = function TourCheckpointDto(json, type) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.serial_nfc = obj.serial_nfc !== null ? obj.serial_nfc : null;
        this.tag_id = obj.tag_id !== null ? obj.tag_id : null;
        this.tag_name = obj.tag_name !== null ? obj.tag_name : null;
        this.info = obj.info !== null ? obj.info : null;
        this.latitude = obj.latitude !== null ? obj.latitude : null;
        this.longitude = obj.longitude !== null ? obj.longitude : null;


        this.check_time = obj.check_time ?
            typeof obj.check_time === 'string' ?
                Date.fromISO(obj.check_time)
                : obj.check_time
            : null;

        this.type = type !== undefined ? type : app.dto.TourCheckpointType.CHECKPOINT;
    };

    app.dto.TourPhotoDto = function TourPhotoDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id !== null ? obj.id : null;
        this.tag_name = obj.tag_name !== null ? obj.tag_name : null;
        this.checkpoint_info = obj.checkpoint_info !== null ? obj.checkpoint_info : null;
        this.type = obj.type !== null ? obj.type : null;
        this.image = obj.image !== null ? obj.image : null;

        this.created_at = obj.created_at ?
                typeof obj.created_at === 'string' ?
            Date.fromISO(obj.created_at)
            : obj.created_at
            : null;

        this.check_time = obj.check_time ?
            typeof obj.check_time === 'string' ?
                Date.fromISO(obj.check_time)
                : obj.check_time
            : null;
    };

    app.dto.TourRunDetailsDto = function TourRunDetailsDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.tourRun = obj.tourrun !== null ? new app.dto.TourRunDto(obj.tourrun) : null;
        this.start = obj.start !== null ?
            new app.dto.TourCheckpointDto(obj.start, app.dto.TourCheckpointType.START) : null;
        this.end = obj.end !== null ?
            new app.dto.TourCheckpointDto(obj.end, app.dto.TourCheckpointType.END) : null;

        this.checkpoints = obj.checkpoints !== null ? _.map(obj.checkpoints, function(e) {
            return new app.dto.TourCheckpointDto(e, app.dto.TourCheckpointType.SCANNED);
        }) : null;
        this.missingCheckpoints = obj.missing_checkpoints !== null ? _.map(obj.missing_checkpoints, function(e) {
            return new app.dto.TourCheckpointDto(e, app.dto.TourCheckpointType.MISSED);
        }) : null;
        this.invalidCheckpoints = obj.invalid_checkpoints !== null ? _.map(obj.invalid_checkpoints, function(e) {
            return new app.dto.TourCheckpointDto(e, app.dto.TourCheckpointType.INVALID);
        }) : null;
        this.thumbnails  = obj.thumbnails !== null ? _.map(obj.thumbnails, function(e) {
            return new app.dto.TourPhotoDto(e);
        }) : null;
        this.timeCInfos = obj.timec_infos !== null ? _.map(obj.timec_infos, function(info) {
            return new app.dto.TourRunTimeCInfoDto(info);
        }) : null;
    };

    app.dto.TourRunTimeCInfoDto = function TourRunTimeCInfoDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.order = obj.order !== null ? parseInt(obj.order, 10) : null;
        this.quota = obj.quota !== null ? parseInt(obj.quota, 10) : null;
        this.time_from = obj.time_from
            ? typeof obj.time_from === 'string' ? new window.JustTime.fromISO(obj.time_from) : obj.time_from
            : null;
        this.time_to = obj.time_to
            ? typeof obj.time_to === 'string' ? new window.JustTime.fromISO(obj.time_to) : obj.time_to
            : null;
    };

    app.dto.TourCheckPointWithIncidentDto = function TourCheckPointWithIncidentDto(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.tour_checkpoint_id = obj.tour_checkpoint_id !== null ? parseInt(obj.tour_checkpoint_id, 10) : null;
        this.check_time = obj.check_time ? Date.fromISO(obj.check_time) : null;
        this.tag_name = obj.tag_name;
        this.user_first_name = obj.user_first_name;
        this.user_last_name = obj.user_last_name;
        this.user_name = obj.user_name;
        this.admin_comment = obj.admin_comment;
        this.tour_name = obj.tour_name;
        this.info = obj.info;
        this.customer_name = obj.customer_name;
        this.location_name = obj.location_name;
        this.object_name = obj.object_name;
        this.images = obj.images ? obj.images : [];
    }

}(window.app));

