/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.Tour = function Tour(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id != null ? obj.id : null;
        this.name = obj.name != null ? obj.name : null;
        this.description = obj.description != null ? obj.description : null;
        this.force_order = obj.force_order != null ? obj.force_order : null;
        this.active = obj.active != null ? obj.active : null;
        this.email = obj.email != null ? obj.email : null;
        this.min_duration = obj.min_duration ? obj.min_duration : null;
        this.max_duration = obj.max_duration ? obj.max_duration : null;


        /**
         * set on server side
         * @type {JustDate}
         */
        this.created_at = obj.created_at ?
            typeof obj.created_at === 'string' ?
                Date.fromISO(obj.created_at)
                : obj.created_at
            : null;
    };

    app.model.TourTag = function TourTag(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.tag_id = obj.tag_id != null ? obj.tag_id : null;
        this.order_idx = obj.order_idx != null ? obj.order_idx : 0;
        this.must_read = obj.must_read != null ? obj.must_read : true;
        this.type = obj.type != null ? obj.type : null;

        if (obj.name != null)
            this.name = obj.name;

        this.longitude = obj.longitude != null ? parseFloat(obj.longitude) : null;
        this.latitude = obj.latitude != null ? parseFloat(obj.latitude) : null;
    };

    app.model.TourTimeC = function TourTimeC(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id = obj.id != null ? parseInt(obj.id, 10) : null;
        this.timec_type = obj.timec_type != null ? obj.timec_type : null;
        this.quota = obj.quota != null ? obj.quota : null;
        this.tour_areas = _.map(obj.tour_areas || [], function (ta) {
            return new app.model.TourArea(ta);
        });

        if (obj.weekdays != null) {
            this.weekdays = obj.weekdays;
        }

        if (obj.calendar_id != null) {
            this.calendar_id = obj.calendar_id;
        }

        this.created_at = obj.created_at ?
                typeof obj.created_at === 'string' ?
            Date.fromISO(obj.created_at)
            : obj.created_at
            : null;

        this.time_from = obj.time_from ?
                typeof obj.time_from === 'string'
            ? new window.JustTime.fromISO(obj.time_from)
            : obj.time_from
            : null;

        this.time_to = obj.time_to ?
                typeof obj.time_to === 'string'
            ? new window.JustTime.fromISO(obj.time_to)
            : obj.time_to
            : null;
    };

    app.model.TourTimeC.prototype.toString = function(calendars) {
        var info = "";

        if (this.timec_type === 'DAILY') {
            info += "Täglich";
        } else if (this.timec_type === 'WEEKLY') {
            info += sprintf("Wöchentlich (%s)",
                _.map(this.weekdays, function(dayIdx) {
                    return Date.defaults.dayNamesShort[dayIdx];
                }));
        } else if (this.timec_type === 'CALENDAR') {
            info += sprintf('Kalender (%s)', _.findWhere(calendars, {id: this.calendar_id}).name);
        }

        info += sprintf(", %s bis %s, Anzahl: %s",
          window.JustTime.formatTimeString(this.time_from),
          window.JustTime.formatTimeString(this.time_to), this.quota || 0);

        return info;
    };

}(window.app));
