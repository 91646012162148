/**
 * Page for handling the shift pages. Checks if the current user has a running shift or not and
 * then redirects to respective pages.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav         = rise.Navigation;

    /** DAOs **/
    var shiftDao = app.dao.shiftDao;

    /*** GLOBALS ***/
    var pages   = app.pages;

    /*** Export page ***/
    pages.shift.check = app.pagesByPath['/shift/dist'] = ShiftCheck;

    /*** Set permission ***/
    ShiftCheck.permissions = [app.model.PermissionTypes.SHIFT_START_END_OWNED];

    function ShiftCheck() {
        this.name = "/shift/dist";

        this.create = function () {
            shiftDao.findRunning()
                .done(function(shift) {
                    if (shift) {
                        nav.goToPage(pages.shift.end, [shift]);
                    } else {
                        nav.goToPage(pages.shift.begin);
                    }
                });
        };

        this.destroy = function() {
            // called after page has been destroyed
        };
    }

})(window.rise, window.app);
