import React from 'react';

import { CommandBox as LegacyCommandBox } from 'app/legacy/widgets/commandbox';
import { cancelIconButton, editIconButton, saveIconButton } from 'app/legacy/widgets/button';
import { PageType } from 'app/report-schedule/ReportScheduleConstants';
import { ReportScheduleListPage } from 'app/report-schedule/pages/list-page/ReportScheduleListPage';
import { Box } from 'app/BoxWorld';
import { hasPermission, PermissionType } from 'app/PermissionType';

type Props = {
    pageType: PageType;
    onEdit: () => void;
    onCancel: (backToOverview: boolean) => void;
    onSave: () => Promise<void>;
};

export function CommandBox(props: Props) {
    const { pageType, onCancel, onEdit, onSave } = props;

    const saveButton = () => (hasPermission(PermissionType.REPORT_SCHEDULE_MODIFY_ADMIN)
        ? saveIconButton({
            id: 'save-report-schedule',
            click: onSave,
        })
        : null);

    const cancelButton = (backToOverview: boolean) => cancelIconButton({
        id: 'cancel-edit-report-schedule',
        click: () => onCancel(backToOverview),
    });

    const editButton = () => (hasPermission(PermissionType.REPORT_SCHEDULE_MODIFY_ADMIN)
        ? editIconButton({
            id: 'edit-report-schedule',
            click: onEdit,
        }, 'Bearbeiten')
        : null);

    const getTitle = () => {
        switch (pageType) {
            case PageType.NEW:
                return 'Neuer automatischer Bericht';
            case PageType.UPDATE:
                return 'Automatischen Bericht bearbeiten';
            case PageType.VIEW:
                return 'Automatischer Bericht';
        }
        return '';
    };

    const getCommandButtons = () => {
        switch (pageType) {
            case PageType.VIEW:
                return [
                    editButton(),
                ];

            case PageType.UPDATE:
                return [
                    saveButton(),
                    cancelButton(false),
                ];

            case PageType.NEW:
                return [
                    saveButton(),
                    cancelButton(true),
                ];
        }

        return [];
    };

    const elements = LegacyCommandBox({ title: getTitle(), backAction: ReportScheduleListPage }, getCommandButtons());

    return <Box>{elements}</Box>;
}
