/**
 * Logbook detail page.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var log         = rise.logging.getLogger('pages.logbook.create');
    var nav         = rise.Navigation;

    var saveIconButton      = app.widgets.saveIconButton;
    var commandBox          = app.widgets.commandBox;
    var NotificationManager = new window.notification.NotificationManager();

    /** DAOs **/
    var EventLogbookEntry       = app.model.EventLogbookEntry;
    var eventLogbookEntryDao    = app.dao.eventLogbookEntryDao;

    /*** GLOBALS ***/
    var pages       = app.pages;
    var formWidgets = app.widgets.form;

    var MAX_FILE_SIZE = 30 * 1024 * 1024;  //max file-size is set to 30MB


    /*** Export page ***/
    pages.logbook.create = app.pagesByPath['/logbook/create'] = LogbookCreate;

    /*** Set permission ***/
    LogbookCreate.permissions = [
        app.model.PermissionTypes.EVENT_INSERT_ADMIN,
        app.model.PermissionTypes.EVENT_INSERT_SUPERVISOR,
        app.model.PermissionTypes.EVENT_INSERT_EMPLOYEE,
        app.model.PermissionTypes.EVENT_INSERT_CUSTOMER
    ];

    function LogbookCreate() {
        var obj = this;

        this.$parent = null;

        this.name = "/logbook/create";

        this.logbookEntry = null;

        this.workplace_filter = null;
        this.customers = null;
        this.objectsByWorkplaceId = {};
        this.objectsSource = [];
        this.eventTypesSource = [];

        var attachIdx = 0;

        this.create = function ($parent, cachedLogbookEntryTemplateId) {
            var sessionInfo = app.session.getSessionInfo();

            obj.$parent = $parent;
            obj.fileContents = [];

            if (!cachedLogbookEntryTemplateId) {
                obj.logbookEntry = new EventLogbookEntry({
                    user_id: sessionInfo.user.id
                });
            } else {
                loadCachedLogbookEntryTemplate(cachedLogbookEntryTemplateId);
            }

            attachIdx = 0;

            var shift = sessionInfo.shift;
            if(!shift && app.session.hasPermission(app.model.PermissionTypes.EVENT_INSERT_EMPLOYEE)
                && !app.session.hasPermission(app.model.PermissionTypes.EVENT_INSERT_SUPERVISOR)
                && !app.session.hasPermission(app.model.PermissionTypes.EVENT_INSERT_ADMIN)
                && !app.session.hasPermission(app.model.PermissionTypes.EVENT_INSERT_CUSTOMER)) {
                app.showErrorMessage('Keine Schicht gestartet');
                return;
            }

            return (eventLogbookEntryDao.getCustomerList())
                    .done(function(customers) {
                        obj.customers = customers;

                        // init object by workplace.id mapping
                        _.each(obj.customers, function(customer) {
                            _.each(customer.locations, function(location) {
                                _.each(location.objects, function(object) {
                                    _.each(object.assigned_workplaces, function(workplace) {
                                        if (obj.objectsByWorkplaceId[workplace.id]) {
                                            obj.objectsByWorkplaceId[workplace.id].push(object);
                                        } else {
                                            obj.objectsByWorkplaceId[workplace.id] = [object];
                                        }

                                        if ((shift && shift.workplace_id === workplace.id) &&
                                            app.session.hasPermission(app.model.PermissionTypes.EVENT_INSERT_EMPLOYEE)) {
                                            obj.workplace_filter = {
                                                "customers" : [customer],
                                                "locations" : [location],
                                                "objects"   : [object],
                                                "workplaces": [workplace]
                                            };
                                        } else if (obj.logbookEntry && obj.logbookEntry.workplace_id === workplace.id) {
                                            obj.workplace_filter = {
                                                "customers" : [customer],
                                                "locations" : [location],
                                                "objects"   : [object],
                                                "workplaces": [workplace]
                                            };
                                        }
                                    });
                                });
                            });
                        });

                        renderCreateLogbookEntry($parent);
                    });
        };

        this.destroy = function() {
            // called after page has been destroyed
        };

        function saveEvent(page, params) {
            obj.logbookEntry.documents = _.filter(obj.logbookEntry.documents, function(d) {
                return d;
            });

            obj.fileContents = _.filter(obj.fileContents, function(f) {
                return f;
            });

            eventLogbookEntryDao.insert(obj.logbookEntry, obj.fileContents)
                .done(function() {
                    nav.goToPage(page, params);
                    app.showSuccessMessage('Logbucheintrag wurde erstellt');
                });
        }

        function renderCreateLogbookEntry($parent) {
            $parent.appendElement([
                renderCommandBox(),

                // logbook entry
                div({ "class":"columns-2" }, [
                    renderWorkplaceSelector(
                        obj.customers,
                        obj.workplace_filter,
                        onWorkplaceChanged,
                        obj.workplace_filter !== null),
                    renderEventSelector(false)
                ]),

                renderDocumentUploadForm(),

                div({ "id":"event-fields-container"}),

                renderActionBox($parent)
            ]);

            if (obj.objectsSource.length === 1) {
                $('#logbook-entry-for-object').combobox("setSelected", obj.objectsSource[0]);
            }

            $parent.activateValidators();
        }

        function renderCommandBox() {
            return commandBox({title:"Ereignis hinzufügen"});
        }

        function renderActionBox($parent) {
            function saveButton() {
                return saveIconButton({id: 'save-event',
                    click: function () {
                        if ($parent.validate()) {
                            saveEvent(pages.logbook.entries.list);
                        }
                    }
                }, 'Hinzufügen');
            }
            function saveAndContinueButton() {
                return saveIconButton({id: 'save-continue-event',
                    click: function () {
                        if ($parent.validate()) {
                            saveEvent(pages.logbook.create);
                        }
                    }
                }, 'Hinzufügen und fortfahren');
            }
            function saveAndContinueWithDataButton() {
                return saveIconButton({id: 'save-continue-with-data-event',
                    click: function () {
                        if ($parent.validate()) {
                            var cachedLogbookEntryTemplateId = createCachedLogbookEntryTemplateFromModel();
                            saveEvent(pages.logbook.create, [cachedLogbookEntryTemplateId]);
                        }
                    }
                }, 'Hinzufügen und fortfahren mit Daten');
            }

            return div({'class': 'action-box bottom', style:"display:none"}, [
                saveButton(),
                saveAndContinueButton(),
                saveAndContinueWithDataButton()
            ]);
        }

        function createCachedLogbookEntryTemplateFromModel() {
            var cacheId = uuidv4();
            window.sessionStorage.setItem(cacheId, JSON.stringify(obj.logbookEntry));
            window.sessionStorage.setItem(cacheId + "-fc", JSON.stringify(obj.fileContents));
            return cacheId;
        }

        function loadCachedLogbookEntryTemplate(cacheId) {
            var templateString = window.sessionStorage.getItem(cacheId);
            var fileContentsString = window.sessionStorage.getItem(cacheId + '-fc');

            var template;
            if (!!templateString) {
                template = new EventLogbookEntry(JSON.parse(templateString));
                template.time_entered = new Date();
            } else {
                template = new EventLogbookEntry({
                    user_id: sessionInfo.user.id
                });
            }

            if (fileContentsString) {
                obj.fileContents = JSON.parse(fileContentsString);
            }

            window.sessionStorage.removeItem(cacheId);
            window.sessionStorage.removeItem(cacheId + "-fc");

            obj.logbookEntry = template;
        }

        function renderEventSelector(view) {
            var selectedObject = !!obj.logbookEntry.object_id
                ? _.findWhere(obj.objectsSource, {id: obj.logbookEntry.object_id})
                : null;

            return fieldset({id: 'workplace-selector', 'class':'column'}, [
                legend({}, "Ereignisauswahl"),
                ul({"class":"fieldset-content"}, [
                    formWidgets.formItemSelect({id: 'logbook-entry-for-object', label: 'Objekt', required: false ,
                        source: obj.objectsSource, valueProp: 'name',
                        value: selectedObject,
                        selected: function(e, object) {
                            try {
                                if (object.id) {
                                    eventLogbookEntryDao.getEventTypes(object.id, obj.logbookEntry.workplace_id)
                                        .done(function(eventTypes) {
                                            obj.logbookEntry.object_id = object.id;
                                            obj.eventTypesSource.length = 0;
                                            _.each(eventTypes, function(object) {
                                                obj.eventTypesSource.push(object);
                                            });

                                            obj.eventTypesSource.sort(function (a, b) {
                                                return a.label.localeCompare(b.label);
                                            });

                                            var selectedEventType = !!obj.logbookEntry.event_type_id
                                                ? _.findWhere(obj.eventTypesSource, {id: obj.logbookEntry.event_type_id})
                                                : null;

                                            if (obj.eventTypesSource.length === 1) {
                                                $('#logbook-entry-event').combobox("setSelected", obj.eventTypesSource[0]);
                                            } else if (!!selectedEventType) {
                                                $('#logbook-entry-event').combobox("setSelected", selectedEventType);
                                            } else {
                                                $('#logbook-entry-event').val("").change();
                                            }
                                        });
                                }
                            } catch (e) {
                                log.error(e);
                            }
                        }
                    }, view),
                    formWidgets.formItemSelect({id: 'logbook-entry-event', label: 'Ereignis', required: false ,
                        source: obj.eventTypesSource, valueProp: 'label',
                        value: null,
                        selected: function(e, eventType) {
                            function _renderLogbookEntryForm() {
                                if (eventFieldsContainer.children().length > 0 && eventType && eventType.id !== obj.logbookEntry.event_type_id) {
                                    obj.logbookEntry.event_fields.length = 0;
                                }

                                eventFieldsContainer.empty();
                                if (eventType.id) {
                                    obj.logbookEntry.event_type_id = eventType.id;

                                    eventFieldsContainer.show().appendElement(
                                        renderLogbookEntryForm(eventType, obj.logbookEntry, view));

                                    // show save action
                                    obj.$parent.find(".action-box.bottom").show();
                                }
                            }

                            try {
                                var eventFieldsContainer = obj.$parent.find("#event-fields-container");

                                if (eventType && eventType.id && eventType.id !== obj.logbookEntry.event_type_id) {
                                    // remove event field definitions
                                    obj.logbookEntry.event_fields.length = 0;
                                }

                                // hide save action
                                obj.$parent.find(".action-box.bottom").hide();

                                eventFieldsContainer.fadeOut("fast", _renderLogbookEntryForm);
                            } catch (e) {
                                log.error(e);
                            }
                        }
                    }, view),
                    formWidgets.formItemDateTime({id: 'logbook-entry-time', label: 'Datum und Uhrzeit', required: true,
                        date: new Date(), change: function() {
                            obj.logbookEntry.time_entered = $(this).datetimepicker('getDate');
                        }
                    }, view)
                ])
            ]);
        }

        function onWorkplaceChanged(hierarchy, workplace) {
            if (hierarchy < 3) {
                // reset object select and eventType select
                obj.objectsSource.length = obj.eventTypesSource.length = 0;
            } else {
                obj.logbookEntry.workplace_id = workplace;
                obj.objectsSource.length = 0;
                _.each(obj.objectsByWorkplaceId[workplace], function(object) {
                    obj.objectsSource.push(object);
                });
            }

            obj.objectsSource.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });

            $('#logbook-entry-event').val("").change();
            if (obj.objectsSource.length === 1) {
                $('#logbook-entry-for-object').combobox("setSelected", obj.objectsSource[0]);
            } else {
                $('#logbook-entry-for-object').combobox("setSelected", null);
            }
        }

        function checkFileMaxSize(file, fileInput) {
            if (file.size > MAX_FILE_SIZE) {
                $(fileInput).val('');
                app.showErrorMessage("Die max. Dateigröße beträgt 30 MB");
                return false;
            }

            return true;
        }

        function renderDocumentUploadForm() {
            return fieldset({id: 'event-document-upload', 'class': 'column '}, [
                legend({}, "Anhang"),
                ul({id: 'attachment-list', "class":"fieldset-content"},
                    _.flatten(_.map(obj.logbookEntry.documents || [],
                        function(ele, idx) {
                            return createUploadForm(idx);
                        }).concat([
                        createUploadForm((obj.logbookEntry.documents || []).length),
                        createAddUploadFormButton()
                    ]))
                )
            ]);
        }

        function createAddUploadFormButton() {
            return [
                li({id:"add-attachment-button", class:"fieldset-item" }, [
                    a({ title:"Weiteren Anhang hinzufügen",
                            "click": function(e, $elem) {
                                addUploadForm();
                            }},
                        img({ src:"style/images/content/plus.png" })
                    )
                ])
            ];
        }

        function addUploadForm() {
            $("#add-attachment-button").remove();

            attachIdx++;

            var $attachmentList = $("#attachment-list");
            $attachmentList.appendElement(
                _.flatten([
                    createUploadForm(attachIdx),
                    createAddUploadFormButton()
                ])
            );
        }

        function createUploadForm(idx) {
            return [
                li({ "class":"fieldset-item", "style": !!obj.logbookEntry.documents[idx] ? "display: none;": undefined }, [
                    label({ "for":"event-document-upload" }, "Anhang"),
                    span({}, [
                        form({ id: "form-upload"+idx, "class":'file_upload' }, [
                            input({ id: "event-document-upload", type:"file", name: "file",
                                change: function() {
                                    var fileInput = this;

                                    app.removeErrorMessages();

                                    fileReaderUpload(fileInput, idx);
                                }
                            })
                        ])
                    ])
                ]),
                !!obj.logbookEntry.documents[idx]
                    ? li({ "class":"fieldset-item" }, [
                        label({ "for": "event-document-filesize" + idx}, "Dateiname"),
                        span({ id:"event-document-filename" + idx }, obj.logbookEntry.documents[idx].filename )
                    ])
                    : undefined,
                li({ "class":"fieldset-item", "style": "display:none;" }, [
                    label({ "for": "event-document-filesize" + idx}, "Dateigröße"),
                    span({ id:"event-document-filesize" + idx }, "-")
                ]),
                li({ "class":"fieldset-item", "style": "display:none;" }, [
                    label({ "for": "event-document-last-modified" + idx}, "Zuletzt verändert"),
                    span({ id:"event-document-last-modified" + idx}, "-")
                ])
            ];
        }

        function fileReaderUpload(fileInput, idx) {
            var file;

            if (fileInput.files.length > 0 && checkFileMaxSize(fileInput.files[0], fileInput)) {
                file = fileInput.files[0];

                loadFile(file, idx);

                // update file stats
                var size = file.size;
                var units = ['Bytes', 'KB', 'MB'];
                var unit = units[0];

                while (units.indexOf(unit) < units.length && size > 1024) {
                    size /= 1024;
                    unit = units[units.indexOf(unit) + 1];
                }
                $("#event-document-filesize"+idx)
                    .html(Math.round(size * 100)/100 + " " + unit)
                    .parent().show();
                $("#event-document-last-modified"+idx)
                    .html(file.lastModifiedDate.toFormatString(app.settings.dateTimeFormat))
                    .parent().show();
            } else {
                loadFile(null, idx);

                // update file stats
                $("#event-document-filesize"+idx).empty().parent().hide();
                $("#event-document-last-modified"+idx).empty().parent().hide();
            }
        }

        function loadFile(file, idx) {
            var reader = new FileReader();

            reader.onload =  function (e) {
                obj.fileContents[idx] = e.target.result;

                var document = new app.model.Document();
                document.filename = file.name;
                obj.logbookEntry.documents[idx] = document;
            };

            if (file) {
                // read file content as base64 string
                reader.readAsDataURL(file);
            } else {
                delete obj.fileContents[idx];
                delete obj.logbookEntry.documents[idx];
            }
        }
    }

    function renderWorkplaceSelector(customers, workplaceFilter, onChange, view) {
        if (view && workplaceFilter.workplaces) {
            onChange(3, workplaceFilter.workplaces[0].id);
        }

        return fieldset({id: 'workplace-selector', 'class':'column'}, [
            legend({}, "Arbeitsplatz"),
            ul({"class":"fieldset-content"}, [
                formWidgets.formItemWorkplaceSelect({
                    id: "workplace-select",
                    customers: customers,
                    selected: workplaceFilter,
                    change: onChange,
                    attributeNameWorkplaces: "workplaces"
                }, view)
            ])
        ]);
    }

    // logbook entry events
    function renderLogbookEntryForm(eventType, logbookEntry, view) {
        return fieldset({id: 'event-custom-fields', 'class': 'column'}, [
            legend({}, 'Ereignisdaten'),
            ul({"class":"fieldset-content"},
                formWidgets.formItemsForEvent(eventType, logbookEntry, view)
            )
        ]);
    }

    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8); // NOSONAR
            return v.toString(16);
        });
    }


})(window.rise, window.app);
