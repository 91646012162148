/**
 * Note: properties starting with a '_' are removed before sending
 *
 * @module app.model
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(app) {
    "use strict";

    /*** IMPORTS ***/
    var Workplace    = app.model.Workplace;

    // Models _need_ to be a function with a name (NOT an anonymous function!)
    app.model.Object = function Object(json) {
        var obj = (typeof json === 'string') ? JSON.parse(json) : json;

        if (!obj) {
            obj = {};
        }

        this.id =               obj.id != null ? parseInt(obj.id) : null;
        this.location_id =      obj.location_id != null ? parseInt(obj.location_id) : null;

        this.name =             obj.name != null ? obj.name : null;
        this.active =           (obj.active === true || obj.active === false) ? obj.active : null;

        this.event_types = obj.event_types || [];

        // map workplace instances
        this.workplaces = _.map(obj.workplaces || [], function(workplace) {
            return new Workplace(workplace);
        });

        // map assigned workplaces
        this.assigned_workplaces = _.map(obj.assigned_workplaces || [], function(workplace) {
            return new Workplace(workplace);
        });
    };

}(window.app));
