/**
 * Logger module.
 *
 * @module rise.Logging.Logger
 * @author Christoph Mayerhofer
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 * ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise) {
    "use strict";

    var logging = rise.logging;
    var logLevel = logging.logLevel;

    /**
     * Logger instance.
     * @param {Number} [level] The log level.
     * @param {Object} [appender] An appender instance.
     * @constructor
     */
    function Logger(level, appender) {
        this.level = level || logLevel.INFO;
        this.appenders = [];

        if (appender) {
            this.appenders.push(appender);
        }
    }

    // add to logging module
    logging.Logger = Logger;

    /**
     * Log a message with severity DEBUG. First parameter should be the
     * message, followed by message substitution parameters.
     * For example: log.debug("foo %s", "bar");
     */
    Logger.prototype.debug = function() {
        if (this.level <= logLevel.DEBUG) {
            var args = [].slice.call(arguments);
            this.log.apply(this, args.concat(logLevel.DEBUG));
        }
    };

    /**
     * Log a message with severity INFO. First parameter should be the
     * message, followed by message substitution parameters.
     * For example: log.info("foo %s", "bar");
     */
    Logger.prototype.info = function() {
        if (this.level <= logLevel.INFO) {
            var args = [].slice.call(arguments);
            this.log.apply(this, args.concat(logLevel.INFO));
        }
    };

    /**
     * Log a message with severity WARN. First parameter should be the
     * message, followed by message substitution parameters.
     * For example: log.warn("foo %s", "bar");
     */
    Logger.prototype.warn = function() {
        if (this.level <= logLevel.WARN) {
            var args = [].slice.call(arguments);
            this.log.apply(this, args.concat(logLevel.WARN));
        }
    };

    /**
     * Log a message with severity ERROR. First parameter should be the
     * message, followed by message substitution parameters.
     * For example: log.error("foo %s", "bar");
     */
    Logger.prototype.error = function() {
        if (this.level <= logLevel.ERROR) {
            var args = [].slice.call(arguments);
            this.log.apply(this, args.concat(logLevel.ERROR));
        }
    };

    /**
     * Log a message with severity FATAL. First parameter should be the
     * message, followed by message substitution parameters.
     * For example: log.fatal("foo %s", "bar");
     */
    Logger.prototype.fatal = function() {
        if (this.level <= logLevel.FATAL) {
            var args = [].slice.call(arguments);
            this.log.apply(this, args.concat(logLevel.FATAL));
        }
    };

    /**
     * Log the message with the specified arguments and the given log level.
     * First parameter is the message, followed by a variable number of arguments
     * for the message, and the log level.
     */
    Logger.prototype.log = function() {
        var args = [].slice.call(arguments);
        if (args.length >= 2) {
            var msg = sprintf.apply(null, convertComplex(args.slice(0, args.length - 1))),
                level = args[args.length - 1];

            _.each(this.appenders, function(appender) {
                if (appender && appender.append) {
                    appender.append(msg, level, new Date());
                }
            });
        }
    };

    /**
     * converts all Objects to a string
     *
     * @param {Array} objList
     */
    function convertComplex(objList) {
        return _.map(objList, function(obj) {
            if (_.isObject(obj)) {
                return JSON.stringify(obj).replace(',', ', ');
            }
            return obj;
        })
    }

    /**
     * Adds an appender to the logger.
     * @param {Object} appender The appender instance.
     */
    Logger.prototype.addAppender = function(appender) {
        if (appender) {
            this.appenders.push(appender);
        }
    };

    /**
     * Get all appenders of the logger.
     * @return {Array} List containing all appender instances.
     */
    Logger.prototype.getAppenders = function() {
        return this.appenders;
    };

    /**
     * Set the level of the logger.
     * @param {Number} level The logging level.
     */
    Logger.prototype.setLevel = function(level) {
        if (level && logging.levelName[level]) {
            this.level = level;
        }
    };

    /**
     * Get the level of the logger.
     * @return {*} The logging level.
     */
    Logger.prototype.getLevel = function() {
        return this.level;
    };

}(window.rise));
