import keycode from "keycode";

/**
 * Tag detail page.
 *
 * @module app.pages
 * @license Copyright 2013 (c) RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
(function(rise, app) {
    "use strict";

    /*** IMPORTS ***/
    var nav     = rise.Navigation;
    var pages   = app.pages;

    var form = app.widgets.form;
    var TimeCFormList = app.widgets.TourTimeCFormList;
    var textButton = app.widgets.textButton;
    var Map = app.widgets.Map;

    var editIconButton = app.widgets.editIconButton;
    var cancelIconButton = app.widgets.cancelIconButton;
    var saveIconButton = app.widgets.saveIconButton;
    var commandBox = app.widgets.commandBox;
    var checkpointType = app.dto.TourCheckpointType;

    /** DAOs **/
    var Tour = app.model.Tour;
    var TourTag = app.model.TourTag;
    var tourDao = app.dao.tourDao;
    var customerDao = app.dao.customerDao;
    var Tag = app.model.Tag;

    /** CONSTANTS **/


    /** GLOBALS **/
    var PageType = {
        VIEW: 1,
        EDIT: 2,
        NEW: 3
    };

    /*** Set permission ***/
    TourDetail.permissions = [app.model.PermissionTypes.TOUR_MODIFY];

    /*** Export page ***/
    pages.tour.detail = app.pagesByPath['/tour/detail'] = TourDetail;

    function TourDetail() {
        var self = this;
        self.name = "/tour/detail";
        self.type = PageType.VIEW;
        self.goToPage = null;

        /* assignable logical tags */
        self.checkpointTags = [];
        self.startTags = [];
        self.endTags = [];

        self.calendars = [];

        /* assigned tour tags */
        self.tourTags = [];
        self.tourStartTag = new TourTag({ type: checkpointType.START });
        self.tourEndTag = new TourTag({ type: checkpointType.END });

        self.timecs = [];
        self.assignments = {
            customers: [],
            locations: [],
            objects: [],
            workplaces: []
        };

        self.tagsById = {};
        self.map = null;

        this.create = function ($parent, tour, goToPage, goToPageArgs) {
            self.type = tour ? PageType.VIEW : PageType.NEW;
            self.goToPage = app.pagesByPath[goToPage];
            self.goToPageArgs = goToPageArgs;

            if (tour) {
                return $.when(
                    tourDao.getTourDetails(tour.id),
                    tourDao.getCalendars(),
                    customerDao.findAll(true)
                ).done(function(tourDetails, calendars, customers) {
                    self = $.extend(self, tourDetails);
                    self.customers = customers;
                    self.calendars = calendars;

                    self.timecFormList = new TimeCFormList(self.timecs, self.calendars);
                    renderTour($parent);
                });
            } else {
                self.tour = new Tour();
                return $.when(
                    tourDao.getCalendars(),
                    customerDao.findAll(true)
                ).done(function(calendars, customers) {
                    self.customers = customers;
                    self.calendars = calendars;

                    self.timecFormList = new TimeCFormList(self.timecs, self.calendars);

                    renderTour($parent);
                });
            }
        };

        this.destroy = function() {
            // called after page has been destroyed
        };

        function reorderTourTags() {
            _.each(self.tourTags, function(tour_tag, idx) {
                tour_tag.order_idx = idx;
            });
        }

        function saveTour($parent) {
            var newTourTags = _.filter(self.tourTags, function(tourTag) { return tourTag.tag_id !== undefined; }),
                newTimecs =  self.timecFormList.getTimeConstraints();

            if (self.assignments.customers.length === 0 &&
                self.assignments.locations.length === 0 &&
                self.assignments.objects.length === 0 &&
                self.assignments.workplaces.length === 0) {
                app.showErrorMessage('Der Rundgang hat noch keine Zuweisung');
                return;

            }

            if (!self.tourStartTag.tag_id) {
                app.showErrorMessage('Der Rundgang braucht einen Start-Tag');
                return;
            }

            if (!self.tourEndTag.tag_id) {
                app.showErrorMessage('Der Rundgang braucht einen End-Tag');
                return;
            }

            //validate min duration and max duration values
            if (self.tour.max_duration !== null && self.tour.max_duration <= 0) {
                app.showErrorMessage('Die Dauereinschränkung muss positiv sein');
                return;
            }

            if (self.tour.min_duration !== null && self.tour.min_duration <= 0) {
                app.showErrorMessage('Die Dauereinschränkung muss positiv sein');
                return;
            }

            if (self.tour.max_duration && self.tour.min_duration && self.tour.min_duration >= self.tour.max_duration) {
                app.showErrorMessage('Die maximale Dauer muss größer als die minimale Dauer sein');
                return;
            }

            if (!self.timecFormList.validate()) {
                return;
            }

            self.tourTags = newTourTags;
            self.tour.email = $("#tour-email").tokenlist("value") || null;

            if (self.type === PageType.NEW) {
                tourDao.insert(self.tour, self.tourStartTag, self.tourEndTag, self.tourTags,
                    self.assignments, newTimecs).done(insertDoneMethod);
            } else if (self.type === PageType.EDIT) {
                tourDao.update(self.tour, self.tourStartTag, self.tourEndTag, self.tourTags,
                    self.assignments, newTimecs).done(updateDoneMethod);
            }

            function insertDoneMethod(result) {
                self.tour = result.tour;
                self.timecs = result.timecs;
                self.assignments = result.assignments;

                self.timecFormList = new TimeCFormList(self.timecs, self.calendars);
                self.type = PageType.VIEW;
                $parent.empty();

                renderTour($parent);
                app.showSuccessMessage('Rundgang gespeichert');
            }

            function updateDoneMethod(result) {
                self.tour = result.tour;
                self.timecs = result.timecs;
                self.assignments = result.assignments;

                self.timecFormList = new TimeCFormList(self.timecs, self.calendars);
                self.type = PageType.VIEW;
                $parent.empty();

                renderTour($parent);
                app.showSuccessMessage('Rundgang aktualisiert');
            }
        }

        function filterAssignedCustomers() {
            if (self.assignments.customers.length === 0 &&
                self.assignments.locations.length === 0 &&
                self.assignments.objects.length === 0 &&
                self.assignments.workplaces.length === 0) {
                return self.customers;
            }

            var result = [];
            _.each(self.customers, function(customer) {
                if (_.indexOf(self.assignments.customers, customer.id) !== -1) {
                    result.push(customer);
                    return;
                }

                for (var i = 0; i < customer.locations.length; i++) {
                    var location = customer.locations[i];
                    if (_.indexOf(self.assignments.locations, location.id) !== -1) {
                        result.push(customer);
                        return;
                    }

                    for (var j = 0; j < location.objects.length; j++) {
                        var object = location.objects[j];
                        if (_.indexOf(self.assignments.objects, object.id) !== -1) {
                            result.push(customer);
                            return;
                        }

                        for (var k = 0; k < object.workplaces.length; k++) {
                            var workplace = object.workplaces[k];
                            if (_.indexOf(self.assignments.workplaces, workplace.id) !== -1) {
                                result.push(customer);
                                return;
                            }
                        }
                    }
                }
            });

            return result;
        }

        function openCreateTagDialog($parent, type, title) {
            var assignedCustomers = filterAssignedCustomers(),
                defaultName = self.tour.name ? self.tour.name + "-" + type : "";

            var createTagDialog = new CreateTagDialog({
                id: "create-tag-dialog",
                parent: $parent,
                type: type,
                title: title,
                defaultName: defaultName,
                customers: assignedCustomers,
                createCallback: function() {
                    updateAssignableTags($parent, false);
                },
                cancelCallback: function() {
                    $parent.empty();
                    renderTour($parent);
                }
            });

            createTagDialog.open();
        }

        function updateAssignableTags($parent, view) {
            // save the email string before redrawing the tour-form
            if (!view) {
                self.tour.email = $("#tour-email").tokenlist("value") || null;
            }

            $.when(
                    tourDao.getAssignableTags(self.assignments, self.tour.id)
                ).done(function(assignableTags) {
                    $parent.empty();

                    self.checkpointTags = mergeAssignableTags(self.checkpointTags,
                        assignableTags.checkpointTags, self.tourTags);
                    self.startTags = mergeAssignableTags(self.startTags,
                        assignableTags.startTags, [self.tourStartTag]);
                    self.endTags = mergeAssignableTags(self.endTags,
                        assignableTags.endTags, [self.tourEndTag]);

                    if (self.startTags.length > 0) {
                        self.tourStartTag.tag_id = self.startTags[0].id;
                        self.tourStartTag.name = self.startTags[0].name;
                        self.tourStartTag.longitude = self.startTags[0].longitude;
                        self.tourStartTag.latitude = self.startTags[0].latitude;
                    }

                    if (self.endTags.length > 0) {
                        self.tourEndTag.tag_id = self.endTags[0].id;
                        self.tourEndTag.name = self.endTags[0].name;
                        self.tourEndTag.longitude = self.endTags[0].longitude;
                        self.tourEndTag.latitude = self.endTags[0].latitude;
                    }
                    renderTour($parent);
                });
        }

        function mergeAssignableTags(assignableTags, newAssignableTags, assignedTags) {
            var notToReplace = _.filter(assignableTags, function(t) {
                return _.find(assignedTags, function(tt) {
                    return tt.tag_id === t.id;
                }) !== undefined;
            });

            var merged = newAssignableTags.concat(notToReplace);
            var mergedDupFree = [],
                alreadyMerged = {};

            _.each(merged, function(t) {
                if (alreadyMerged[t.id] !== true) {
                    alreadyMerged[t.id] = true;
                    mergedDupFree.push(t);
                }
            });

            return mergedDupFree;
        }

        /*** render functions *****************************************************/

        function renderTour($parent) {

            var _render = function() {

                $parent.appendElement([
                    renderCommandBox($parent),
                    renderTourForm(self.tour, self.type === PageType.VIEW),
                    renderDurationConstraints(self.type === PageType.VIEW),
                    renderTourAssignmentForm($parent, self.type === PageType.VIEW),
                    div({ "class": "columns-2" }, [
                        div({ "class": "column" }, [
                            renderAssignmentStartTag($parent, self.type === PageType.VIEW),
                            div({ id: "assignmentTagFormDiv" }, [
                                renderAssignmentTagsForm(self.type === PageType.VIEW)
                            ]),
                            renderAssignmentEndTag($parent, self.type === PageType.VIEW)
                        ]),
                        div({ "class": "column" }, renderMapWidget())
                    ])
                ]);

                if(self.type !== PageType.VIEW) {
                    $( "#tour-tag-table-body" ).sortable({
                        scroll: false,
                        placeholder: "ui-state-highlight",
                        sort: function(event, ui) {
                            ui.helper.css({'top' : ui.position.top + $(window).scrollTop() + 'px'});
                        },
                        start: function(event, ui) {
                            var start_pos = ui.item.index();
                            ui.item.data('start_pos', start_pos);
                        },
                        update: function(event, ui) {
                            var start_pos = ui.item.data('start_pos');
                            var end_pos = ui.item.index();

                            var elem = self.tourTags.splice(start_pos, 1);
                            self.tourTags.splice(end_pos, 0, elem[0]);

                            reorderTourTags();
                        }
                    });
                }

                self.timecFormList.render($parent, self.type === PageType.VIEW);

                $parent.activateValidators();
            };

            _render();
        }

        function renderCommandBox($parent) {
            function saveButton() {
                return saveIconButton({id: 'save-tag',
                    click: function () {
                        if ($parent.validate()) {
                            saveTour($parent);
                        }
                    }
                });
            }

            function cancelButton(backToOverview) {
                return cancelIconButton({id: 'cancel-edit-tag',
                    click: function () {
                        if (backToOverview) {
                            nav.goToPage(pages.tour.list);
                        } else {
                            $.validation.reset();
                            $parent.empty();

                            self.tour = self.savedTour;
                            self.tourStartTag = self.savedTourStarTag;
                            self.tourEndTag = self.savedTourEndTag;
                            self.tourTags = self.savedTourTags;
                            self.checkpointTags = self.savedCheckpointTags;
                            self.startTags = self.savedStartTags;
                            self.endTags = self.savedEndTags;
                            self.assignments = self.savedAssignments;
                            self.type = PageType.VIEW;
                            renderTour($parent);

                        }
                    }
                });
            }

            function editTourButton() {
                return editIconButton({id: 'edit-event-type',
                    click: function () {
                        self.savedTour = new Tour(self.tour);
                        self.savedTourStarTag = new TourTag(self.tourStartTag);
                        self.savedTourEndTag = new TourTag(self.tourEndTag);
                        self.savedTourTags = _.map(self.tourTags, function(t) {return new TourTag(t);});
                        self.savedCheckpointTags = self.checkpointTags.slice();
                        self.savedStartTags = self.startTags.slice();
                        self.savedEndTags = self.endTags.slice();
                        self.savedAssignments = $.extend(true, {}, self.assignments);

                        self.type = PageType.EDIT;

                        $parent.empty();
                        renderTour($parent);
                    }
                }, 'Rundgang bearbeiten');
            }

            function getHeader () {
                /* jshint indent:false */
                switch (self.type) {
                    case PageType.NEW:  return 'Neuer Rundgang';
                    case PageType.EDIT: return 'Rundgang bearbeiten';
                    case PageType.VIEW: return 'Rundgang ' + self.tour.name;
                }
                return '';
            }

            function getCommandButtons() {
                /* jshint indent:false */
                switch (self.type) {
                    case PageType.VIEW:
                        return [
                            editTourButton()
                        ];
                    case PageType.EDIT:
                        return [
                            saveButton(),
                            cancelButton()
                        ];
                    case PageType.NEW:
                        return [
                            saveButton(),
                            cancelButton(true)
                        ];
                }

                return [];
            }

            function backAction() {
                return function navigateBack() {
                    if (this instanceof navigateBack) {
                        throw new Error('not constructable');
                    }
                    if (self.goToPage) {
                        rise.Navigation.goToPage(self.goToPage, self.goToPageArgs ?? [true]);
                    } else {
                        return rise.Navigation.goToPage(pages.tour.list);
                    }
                };
            }

            return commandBox({backAction:backAction(), title: getHeader()},getCommandButtons());

        }

        function renderTourAssignmentForm($parent, view) {
            return fieldset({id: 'tour-assignment-edit'}, [
                legend({}, "Gültigkeitsbereich festlegen"),
                ul({'class':'fieldset-content'}, [
                    form.formHierarchicalWorkplaceSelect({
                        id: "customer-select",
                        customers: self.customers,
                        assignments: self.assignments,
                        change: function(level, instances) {
                            self.assignments[{
                                0: 'customers',
                                1: 'locations',
                                2: 'objects',
                                3: 'workplaces'
                            }[level]] = instances;

                            updateAssignableTags($parent, view);
                        },
                        noAssignAllCheckbox: true
                    }, view)
                ])
            ]);
        }

        function renderAssignmentStartTag($parent, view) {
            return fieldset({id: 'tour-assignment-start-tag-edit'}, [
                legend({}, "Startpunkt zuweisen"),
                ul({'class':'fieldset-content'}, [
                    form.formItemSelect({id: 'tour-start-tag-select', label: 'Startpunkt',
                        required: false,
                        source: self.startTags,
                        value: _.findWhere(self.startTags, {id: self.tourStartTag.tag_id}),
                        valueProp: "name",
                        selected: function(e, selection) {
                            self.tourStartTag.tag_id = selection.id;
                            self.tourStartTag.type = checkpointType.START;
                            self.tourStartTag.name = selection.name;
                            self.tourStartTag.longitude = selection.longitude;
                            self.tourStartTag.latitude = selection.latitude;
                            updateMarkers();
                        }
                    }, view),
                    !view ? li({'class': 'fieldset-item'}, textButton({id: 'create-start-tag-button',
                        click: function() {
                            openCreateTagDialog($parent, 'START', "Neuen Startpunkt erstellen");
                        }
                    }, 'Neuen Startpunkt erstellen')) : undefined
                ])
            ]);
        }

        function renderAssignmentEndTag($parent, view) {
            return fieldset({id: 'tour-assignment-end-tag-edit'}, [
                legend({}, "Endpunkt zuweisen"),
                ul({'class':'fieldset-content'}, [
                    form.formItemSelect({id: 'tour-end-tag-select', label: 'Endpunkt',
                        required: false,
                        source: self.endTags,
                        value: _.findWhere(self.endTags, {id: self.tourEndTag.tag_id}),
                        valueProp: "name",
                        selected: function(e, selection) {
                            self.tourEndTag.tag_id = selection.id;
                            self.tourEndTag.type = checkpointType.END;
                            self.tourEndTag.name = selection.name;
                            self.tourEndTag.longitude = selection.longitude;
                            self.tourEndTag.latitude = selection.latitude;
                            updateMarkers();
                        }
                    }, view),
                    !view ? li({'class': 'fieldset-item'}, textButton({id: 'create-end-tag-button',
                        click: function() {
                            openCreateTagDialog($parent, 'END', "Neuen Endpunkt erstellen");
                        }
                    }, 'Neuen Endpunkt erstellen')) : undefined
                ])
            ]);
        }

        function renderDurationConstraints(view) {
            if (view && !self.tour.max_duration && !self.tour.min_duration) {
                return undefined;
            }

            return fieldset({id: 'tour-duration-constraints-edit'}, [
                legend({}, "Dauer Einschränkungen"),
                ul({'class':'fieldset-content'}, [
                    !(view && !self.tour.min_duration) ?
                        form.formItemText({id: "min-duration-constraint", label: 'Minimum Dauer',
                            style: "width:100px",
                            labelSuffix: "(HH:MM)",
                            validation: "valid-duration",
                            value: durationToString(self.tour.min_duration),
                            change: function () {
                                self.tour.min_duration = parseDuration($(this).val());
                            }
                        }, view) : undefined,
                    !(view && !self.tour.max_duration) ?
                        form.formItemText({id: "max-duration-constraint", label: 'Maximum Dauer',
                            style: "width:100px",
                            labelSuffix: "(HH:MM)",
                            validation: "valid-duration",
                            value: durationToString(self.tour.max_duration),
                            change: function () {
                                self.tour.max_duration = parseDuration($(this).val());
                            }
                        }, view) : undefined
                ])
            ]);
        }

        function reRenderAssignmentTagsForm(view) {
            var $assignmentTagFormDiv = $("#assignmentTagFormDiv");
            $assignmentTagFormDiv.empty();
            $assignmentTagFormDiv.appendElement(renderAssignmentTagsForm(view));

            if(self.type !== PageType.VIEW) {
                $( "#tour-tag-table-body" ).sortable({
                    scroll: false,
                    placeholder: "ui-state-highlight",
                    sort: function(event, ui) {
                        ui.helper.css({'top' : ui.position.top + $(window).scrollTop() + 'px'});
                    },
                    start: function(event, ui) {
                        var start_pos = ui.item.index();
                        ui.item.data('start_pos', start_pos);
                    },
                    update: function(event, ui) {
                        var start_pos = ui.item.data('start_pos');
                        var end_pos = ui.item.index();

                        var elem = self.tourTags.splice(start_pos, 1);
                        self.tourTags.splice(end_pos, 0, elem[0]);

                        reorderTourTags();
                    }
                });
            }
        }

        function renderMapWidget() {
            var defaultLocation = {lat: 48.21, lng: 16.370000000000005};

            function renderMap($mapOuterDiv) {
                var mapHeight = 550,
                    location = null,
                    centerTag = null;

                if (self.tourStartTag.tag_id) {
                    centerTag = self.tourStartTag;
                } else if (self.tourEndTag.tag_id) {
                    centerTag = self.tourEndTag;
                } else if (self.tourTags.length > 0) {
                    centerTag = self.tourTags[0];
                }

                function onClick(map, event) {
                    map.setMapOptions({scrollwheel: true});
                }

                function onMouseOut(map, event) {
                    map.setMapOptions({scrollwheel: false});
                }


                self.map = new Map($mapOuterDiv, {
                    'location': (centerTag && centerTag.tag_id) ? undefined : defaultLocation,
                    'zoom': (centerTag && centerTag.tag_id) ? undefined : 17,
                    'scrollwheel': false,
                    'onClick': onClick,
                    'onMouseOut': onMouseOut,
                    'onMarkerDrag': onMarkerDrag,
                    'showPrintButton': false
                });

                self.map.render("height: " + mapHeight + "px").done(function () {
                    setTimeout(function() {
                        updateMarkers();
                    });
                });

                setTimeout(function() {
                    updateMarkers();
                    google.maps.event.trigger(this.map, 'resize');
                    //self.map.update();
                }, 0);
            }

            function onMarkerDrag(tagId, location) {
                app.dao.tagDao.updateTagCoordinates(tagId, location.longitude, location.latitude)
                    .done(function() {
                        app.showSuccessMessage('Positionsänderung gespeichert');

                        self.tagsById[tagId].longitude = location.longitude;
                        self.tagsById[tagId].latitude = location.latitude;
                    })
                    .fail(function(e) {
                        app.showErrorMessage('Positionsänderung konnte nicht gespeichert werden');
                    })
            }

            return fieldset({id: 'tour-tags-map', 'class':'fieldset-content'}, [
                legend({}, "Karte"),
                div({ "class":"whiteboard" }, [
                    renderMap
                ])
            ]);
        }

        function renderAssignmentTagsForm(view) {
            function createRow(tourTag, idx) {

                return tr([
                    td(span({ "class": view ? "" : "ui-icon ui-icon-arrowthick-2-n-s" })),
                    td(form.formItemSelect({ id: 'tag-'+idx, label: "Name",
                        source: _.filter(self.checkpointTags, function(cp) {
                            return _.find(self.tourTags, function(t) {
                                return t.tag_id === cp.id && cp.id !== tourTag.tag_id;
                            }) === undefined;
                        }),
                        value: _.findWhere(self.checkpointTags, {id: tourTag.tag_id}),
                        valueProp: "name",
                        selected: function(e, selection) {
                            if (tourTag.tag_id !== selection.id) {
                                tourTag.tag_id = selection.id;
                                tourTag.name = selection.name;
                                tourTag.longitude = selection.longitude;
                                tourTag.latitude = selection.latitude;
                                reRenderAssignmentTagsForm(view);
                                updateMarkers();
                            }
                        }
                    }, view, true)),
                    td(form.formItemCheckbox({ id: 'tour-tag-must-read-'+idx, label: "Pflicht",
                        checked: tourTag.must_read,
                        change: function () {
                            tourTag.must_read = $(this).prop("checked");
                        }
                    }, view, true)),
                    td(view ? undefined :
                        a({ title:"Kontrollpunkt löschen", "class":"button delete image-only",
                            "click": $.proxy(removeTag, self, tourTag, idx) },
                            img({ src:"style/images/content/cross.png" })
                        )
                    ),
                    td(view ? undefined :
                        a({ title:"Kontrollpunkt klonen", "id":"clone-button-"+idx,
                                "class":"button " + (tourTag.tag_id ? "" : " disabled") ,
                            "click": function(e, $elem) {
                                cloneTag(e, $elem, tourTag);
                            }},
                            img({ src:"style/images/content/plus.png" })
                        )
                    )
                ]);
            }

            function addTag(e, $elem) {
                var tourTag = new TourTag();
                self.tourTags.push(tourTag);
                reorderTourTags();

                $elem = $elem ? $elem : $("#tour-tag-table-body");
                $elem.appendElement(createRow(tourTag, self.tourTags.length));
                unHideDeleteButtons($elem);
                updateMarkers();
            }

            function cloneTag(e, $elem, tourTag) {
                if($(e.currentTarget).hasClass("disabled")) {
                    return;
                }

                var tourTagClone = new TourTag(tourTag);
                self.tourTags.push(tourTagClone);
                reorderTourTags();

                $elem = $elem ? $elem : $("#tour-tag-table-body");
                $elem.appendElement(createRow(tourTagClone, self.tourTags.length));
                unHideDeleteButtons($elem);
                updateMarkers();
            }

            function removeTag(tag, idx, e) {
                if($(e.currentTarget).hasClass("disabled")) {
                    return;
                }

                var tourTags = self.tourTags;
                var fieldIdx = _.indexOf(tourTags, tag);

                if(fieldIdx >= 0) {
                    tourTags.splice(fieldIdx, 1);
                    reorderTourTags();
                    $(e.target).closest("tr").remove();
                }

                unHideDeleteButtons();
                reRenderAssignmentTagsForm(view);
                updateMarkers();
            }

            function unHideDeleteButtons($parent) {
                var buttons = $parent ? $parent.find(".button.delete") : $(".button.delete");

                if(buttons.length === 1) {
                    buttons.addClass("disabled");
                } else {
                    buttons.removeClass("disabled");
                }
            }

            function renderCheckpoints() {
                return [
                    div({ "class":"dataTables_wrapper" },
                        table({ "class":"display dataTable" }, [
                            thead([
                                tr([
                                    th(""),
                                    th("Name"),
                                    th("Pflicht"),
                                    th("Löschen"),
                                    th("Klonen")
                                ])
                            ]),
                            tbody({ id:"tour-tag-table-body", "class":"event-type-table-body",
                                    create: function(e) {
                                        if(self.tourTags.length === 0 && !view) {
                                            var $this = $(this);

                                            addTag(e, $this);
                                        }
                                        unHideDeleteButtons($(this));
                                    }
                                },
                                _.map(self.tourTags, createRow)
                            )
                        ])
                    ),
                    view ? undefined :
                        a({ title:"Tag hinzufügen", "class":"button add", "click": addTag },
                            img({ src:"style/images/content/plus.png" })
                        )
                ];
            }


            return fieldset({id: 'tour-tags', 'class':'fieldset-content sort-table'}, [
                /* jshint indent:false */
                legend({}, "Kontrollpunkte zuweisen"),
                div({ "class":"whiteboard" },
                    renderCheckpoints()
                )
            ])
        }

        function tagToLocation(tag) {
            self.tagsById[tag.tag_id] = tag;

            return {
                id: tag.tag_id,
                title: tag.name,
                coordinates: new google.maps.LatLng(tag.latitude, tag.longitude),
                infoText: tag.name,
                draggable: self.type !== PageType.VIEW,
                type: tag.type
            }
        }

        function updateMarkers() {
            var locations = [];

            if (!self.map) {
                return;
            }

            if (self.tourStartTag.tag_id && self.tourStartTag.longitude) {
                locations.push(self.tourStartTag);
            }

            if (self.tourEndTag.tag_id && self.tourEndTag.longitude) {
                locations.push(self.tourEndTag);
            }

            if (self.tourTags.length > 0) {
                locations = locations.concat(
                    _.filter(self.tourTags, function(tt) {
                        return tt.tag_id && tt.longitude;
                    })
                );
            }

            self.map.removeAllMarkers();
            self.map.markLocations(_.map(locations, tagToLocation));
        }

        /*** helper functions ****************************************************/

        function parseDuration(val) {
            if ($.trim(val) === '') {
                return null;
            }

            var re = /^((\d+):)?(\d+)$/;
            var matches = val.match(re);

            if (!matches) {
                return undefined;
            }

            var hours = matches[2] ? parseInt(matches[2], 10) : 0;
            var mins = parseInt(matches[3], 10);

            return hours*60 + mins;
        }

        function durationToString(val) {
            if (!val) {
                return "";
            }

            var hours = Math.floor(val / 60);
            var mins = val % 60;

            if (mins < 10) {
                return hours + ":0" + mins;
            } else {
                return hours + ":" + mins;
            }
        }

    }

    /**
     * Renders the tour form overview fieldset. Also used for the customer print preview.
     */
    const renderTourForm = pages.tour.detail.renderTourForm = function (tour, view) {
        return fieldset({id: 'tour-new'}, [
            ul({'class': 'fieldset-content'}, [
                form.formItemText({id: 'tour-name', label: 'Name', required: true,
                    value: tour.name,
                    change: function () {
                        tour.name = $(this).val();
                    }
                }, view),
                view ? form.formItemText({id: 'tour-reference', label: 'Referenz',
                    value: tour.id
                }, view) : undefined,
                form.formItemText({id: 'tour-description', label: 'Beschreibung',
                    value: tour.description,
                    change: function () {
                        tour.description = $(this).val();
                    }
                }, view),
                form.formItemCheckbox({id: 'tour-force-order', label: 'Reihenfolge einhalten',
                    checked: tour.force_order !== null ? tour.force_order : tour.force_order=false,
                    change: function () {
                        tour.force_order = $(this).prop("checked");
                    }
                }, view),
                form.formItemCheckbox({id: 'tour-active', label: 'aktiv',
                    checked: tour.active !== null ? tour.active : tour.active=true,
                    change: function () {
                        tour.active = $(this).prop("checked");
                    }
                }, view),
                form.formItemText({id: 'tour-email', label: 'E-Mail Benachrichtigung bei nicht korrekter Ausführung',
                    value: view ? tour.email && tour.email.split(';').join('; ') : tour.email,
                    create: function() {
                        $(this).tokenlist({
                            tokenDelimiter: [keycode('space'), keycode(',')],
                            split: /\s*;\s*/,
                            join: ';',
                            acceptCustomInput: true,
                            validate: function(labelValue) {
                                const val = labelValue.value;
                                if(val !== undefined) {
                                    const pattern = new RegExp(/^([a-zA-Z0-9._\\+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})?$/);
                                    return pattern.test(val);
                                }

                                return true;
                            }
                        });
                    }
                }, view)
            ])
        ]);
    };

    /***************************************************************************/

    /*** CONFIRMATION DIALOG ***/

    function CreateTagDialog(options) {
        if (!options.parent) {
            throw "No parent element provided!";
        }

        this.options = $.extend({
            id: "create-tag-dialog",
            click: null
        }, options);

        this._renderDialog();
    }

    CreateTagDialog.prototype.open = function() {
        this.$dialog.dialog("open");
    };

    CreateTagDialog.prototype.close = function() {
        this.$dialog.dialog("close").remove();
    };

    CreateTagDialog.prototype._renderDialog = function () {
        var self = this,
            tagDao = app.dao.tagDao,
            tag = new Tag();

        tag.name = self.options.defaultName;
        tag.tag_type = self.options.type;

        this.options.parent.appendElement(
            div({ id:this.options.id + "-wrapper" }, [
                div({'class': 'create-tag-dialog-content'}, [
                    fieldset({id: 'tag-new'}, [
                        ul({'class': 'fieldset-content'}, [
                            form.formItemText({id: 'tag-name', label: 'Name', required: true,
                                value: tag.name,
                                change: function () {
                                    tag.name = $(this).val();
                                }
                            }, false),
                            form.formItemText({id: 'tag-description', label: 'Beschreibung',
                                value: tag.description,
                                change: function () {
                                    tag.description = $(this).val();
                                }
                            }, false)
                        ]),
                        ul({},
                            form.formItemWorkplaceSelect({
                                id: "select-workplace",
                                customers: self.options.customers,
                                depth: 3,
                                required: true,
                                change: function(level, instance) {
                                    if (level === 2) {
                                        tag.object_id = instance;
                                    }
                                }
                            }, false)
                        )
                    ])
                ]),
                div({ "class": "buttonpane" }, [
                    div({ "class": "three-column" }, [
                        div({ "class":"legt-column" }),
                        div({ "class":"center-column" }),
                        div({"class":"right-column"}, [
                            cancelIconButton({"click": function() {
                                self.close();
                                self.options.cancelCallback();
                            }}),
                            saveIconButton({ "click": function() {
                                if (tag.name === null || tag.object_id === null) {
                                    //TODO: show error message
                                    return;
                                }

                                tagDao.insert(tag).done(function() {
                                    self.close();
                                    self.options.createCallback();
                                });

                            }})
                        ])
                    ])
                ])
        ]));

        // init dialog
        this.$dialog = $("#" + this.options.id + "-wrapper").first();
        this.$dialog.dialog({
            modal: true,
            autoOpen: false,
            width: 650,
            close: function(e) {
                $(e.target).dialog("close").remove();
            },
            title:this.options.title
        });

    };

}) (window.rise, window.app);
